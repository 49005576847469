import axios from "axios";
import CraftApiModel from "../models/CraftApiModel";
import ActionTypes from "../constants/ActionTypes";
import { getCookie } from "../lib/manageCookies";
import Config from "../config/Config";
// import { getApiPathWithLocale } from "../lib/urlUtils";
import { getSession, setSession } from '../models/PageSession';
import { isHpPlusCountry } from "../utils/helperApi";

/**
 * This function uses axious to get the current location of the user.
 *
 * Since axious returns promises, we can dispatch our start event and
 * update it to complete or complete with error whe the promise returns.
 *
 * @returns {function(*)}
 */
export function getLocation() {
    let start = () => ({
        type: ActionTypes.START_LOCATION_FETCH
    }),
        complete = (city) => ({
            type: ActionTypes.COMPLETE_LOCATION_FETCH,
            city
        }),
        error = (err) => ({
            type: ActionTypes.COMPLETE_LOCATION_WITH_ERROR,
            err
        });
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(start());

            axios.get("http://ipinfo.io", { timeout: 5000 })
                .then((res) => {
                    dispatch(complete(res.data.city));
                    return resolve(res.data.city);
                })
                .catch((err) => {
                    if (err.response && err.response.status === 403) {
                        dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT })
                    }
                    dispatch(error(err));
                    reject(err);
                });
        });
    }
}

export function getIsFetchHPPlusCountry(country = "") {
    let start = () => ({
        type: ActionTypes.FETCH_IS_HP_PLUS_COUNTRY
    }),
        complete = (response) => ({
            type: ActionTypes.FETCH_IS_HP_PLUS_COUNTRY_SUCCESS,
            response
        }),
        error = (err) => ({
            type: ActionTypes.FETCH_IS_HP_PLUS_COUNTRY_FAILURE,
            err
        });
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(start());

            isHpPlusCountry(country)
                .then((res) => {
                    dispatch(complete(res.data.isHpPlusCountry));
                    return resolve(res.data.isHpPlusCountry);
                })
                .catch((err) => {
                    dispatch(error(err));
                    reject(err);
                });
        });
    }
}

/**
 * This function uses axious to get the current location of the user.
 *
 * Since axious returns promises, we can dispatch our start event and
 * update it to complete or complete with error whe the promise returns.
 *
 * @returns {function(*)}
 */
//////////////////////////////////////////////////////////////// THIS SHOULD BE REMOVED ... FOR 1107 | FIRST CHECK TO BE SURE THIS IS NOT USED FOR ANYTHING ELSE
export function getLocales(country = "") {
    let start = () => ({
        type: ActionTypes.START_LOCALES_FETCH
    }),
        complete = (locales) => ({
            type: ActionTypes.COMPLETE_LOCALES_FETCH,
            locales
        }),
        error = (err) => ({
            type: ActionTypes.COMPLETE_LOCALES_FETCH_WITH_ERROR,
            err
        });
    // WHEN WE ARE LOGGED IN WE WILL HAVE A LOCALE
    // WHEN WE ARE NOT LOGGED IN country = ""
    country = country === "" ? "" : `?country=${country}`;
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(start());
            // NO LONGER RETRIEVE PARTIAL LIST OF COUNTRIES
            // SHORTCIRCUIT HERE WHEN COUNTRY IS NOT BLANK
            if (country !== "") {
                return dispatch(complete(""));
            }
            // Function to handle creating of craft model and dispatching action
            let dispatchAndResolveData = (data) => {
                let locale = new CraftApiModel(data);
                dispatch(complete(locale));
                return resolve(locale);
            }
            //////////////////////////////////////////////////////////////// THIS SHOULD BE REMOVED
            let url = `/api/globals/locales.json${country}`
            let sessionObject = getSession(url);
            if (!sessionObject) {
                axios.get(url)
                    .then((res) => {
                        let received = !!res.data && !!res.data.data ? res.data.data.length > 0 ? res.data.data[0] : {} : {};
                        setSession(url, received);
                        dispatchAndResolveData(received);
                    })
                    .catch((err) => {
                        if (err.response && err.response.status === 403) {
                            dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT })
                        }
                        dispatch(error(err));
                        reject(err);
                    });
            } else {
                // Page session exists, load page from session object.
                dispatchAndResolveData(sessionObject);
            }

        });
    }
}

export const UPDATE_LOCALE = "UPDATE_LOCALE"
export function setLocale({ locale, country, language }) {
    return {
        type: UPDATE_LOCALE,
        locale,
        country,
        language
    };
};


export function updateLocale(locale, callback = () => {}) {
    let start = () => ({
        type: ActionTypes.START_LOCALE_UPDATE
    }),
        complete = (data) => ({
            type: ActionTypes.COMPLETE_LOCALE_UPDATE,
            data
        }),
        error = (err) => ({
            type: ActionTypes.COMPLETE_LOCALE_UPDATE_WITH_ERROR,
            err
        });
    return dispatch => {
        return new Promise((resolve, reject) => {
            let config = new Config();
            dispatch(start());
            /*
              If we have a locale currently selected, append a period to the beginning,
              if we don't currently have a locale
            */
            let csrfToken = getCookie('hpc3-csrf-token');
            if (!csrfToken) {
                callback();
                dispatch(complete());
                return;
            }
            // axios.post(`${config.Get('HPC_GATEWAY_URL')}/api/1/user/current/locale`, { locale }, {
            axios.post(`${config.Get('HPC_GATEWAY_URL')}/user/current/locale`, { locale }, {
                withCredentials: true,
                headers: {
                    'x-hpc3-csrf-token': csrfToken
                }
            })
                .then((res) => {
                    let localeData = res.data;
                    callback();
                    dispatch(complete(localeData));
                    return resolve(localeData);
                })
                .catch((err) => {
                    if (err.response && err.response.status === 403) {
                        dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT })
                    }
                    dispatch(error(err));
                    reject(err);
                });
        });
    };
};
