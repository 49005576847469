import styled from 'styled-components';
import { theme, breakpoints, smb_breakpoints } from '../../../../utils/styleHelpers';

export const ExperienceContainer = styled.div`
  ${(props) => (props.isRTL ? 'margin: 50px 118px 30px auto' : 'margin: 50px auto 30px 118px')};
`;

export const Introduction = styled.div`
  font-size: 21px;
  margin-bottom: 40px;
  width: 934px;

  h3 {
    font-family: ${theme.fonts.family};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    max-width: 434px;
  }
  @media (min-width: ${breakpoints.bigtablet}) {
    max-width: 584px;
  }
  @media (min-width: ${smb_breakpoints.desktop}) {
    max-width: 734px;
  }
  @media (min-width: ${smb_breakpoints.large}) {
    max-width: 884px;
  }
`;

export const AppStoreText = styled.h5`
  font-family: ${theme.fonts.familyLight};
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 23.18px;
`;

export const QRData = styled.div`
  display: flex;
  flex-direction: row;
`;

export const QRCode = styled.img`
  border: 1px solid #adadad;
`;

export const QRCodeText = styled.p`
  font-family: ${theme.fonts.familyLight};
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  height: 45px;
  line-height: 23.18px;
  width: 304px;
  ${(props) => (props.isRTL ? 'margin-right: 18px' : 'margin-left: 18px')};
`;

export const Line = styled.hr`
  border: 1px solid #027aae;
  margin-top: 35px;
  width: 954px;
`;
