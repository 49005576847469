import React, { useState, useEffect } from 'react';
import { IconPerson } from '@veneer/core';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  display: -ms-flex;
  justify-content:center;
  align-items: center;
  color: rgb(2, 122, 174);
`;

const Circle = styled.div`
  background: ${props => props.backgroundColor};
  opacity: ${props => props.backgroundOpacity};
  width: ${props => props.backgroundSize}px;
  height: ${props => props.backgroundSize}px;
  border-radius: 50%;
`;

const Text = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  line-height: ${props => props.backgroundSize}px;
  width: ${props => props.backgroundSize}px;
  height: ${props => props.backgroundSize}px;
  font-family: "HP Simplified";
  text-align: center;
  display: flex;
    justify-content: center;
  align-items: center;
    @media (max-width: 600px) {
        font-size: 16px;
    }
  }
`;

function formatText(userName) {
  const splitted = (userName.indexOf(' ') === 0) ? userName : userName.split(' ');
  const len = splitted.length;

  if (len === 1) {
    return splitted[0].substring(0, 1).toUpperCase();
  }

  return splitted[0].substring(0, 1).concat(splitted[len - 1].substring(0, 1)).toUpperCase();
}
const UserProfile = ({
  userName, backgroundColor, backgroundOpacity, backgroundSize, fontColor, fontSize,
}) => {
  const [initials, setInitials] = useState();
  useEffect(() => {
    async function loadInitials() {
      if (userName) {
        setInitials(formatText(userName));
      }
    }
    loadInitials();
  }, [userName]);

  return (
    <Container>
      <Circle
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundSize={backgroundSize}
      />
      <Text
        fontColor={fontColor}
        fontSize={fontSize}
        backgroundSize={backgroundSize}
      >
        { initials || <IconPerson /> }
      </Text>
    </Container>
  );
};

UserProfile.defaultProps = {
  text: '',
  backgroundColor: '#027AAE',
  backgroundOpacity: '0.2',
  backgroundSize: '36',
  fontColor: '#027AAE',
  fontSize: '25px',
};

export default UserProfile;
