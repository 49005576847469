import React from 'react';
import styled from 'styled-components';
import { useSelector } from '../../hooks';
import { selectMobileFaxTestimonialData } from '../../selectors/mobileFax';
import Carousel from '../wrapped/Carousel';

const TestimonialsContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1.5rem;
    height: 32rem;
    width: 100%;
    @media (min-width: 40rem) {
    }
    @media (min-width: 64rem) {
      padding: 3rem;
      margin-top: 3rem;
      height: 100%;
    }
  }
`;

const StyledCarousel = styled(Carousel)`
  && {
    max-width: 100%;
    margin: 0 auto;
    .carousel.carousel-slider {
      height: 26rem;
      @media (min-width: 64rem) {
        height: 28rem;
      }
    }
    .slide {
      background-color: #F6F6F6;
    }
    @media (min-width: 64rem) {
      max-width: 80%;
    }
  }
`;

const QuoteCardContainer = styled.div`
  width: 100%;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  height: 23rem;
  padding: 1rem 0 0 0;
  ::before {
    content: '';
    box-sizing: border-box;
    height: 1px;
    width: 5%;
    margin: 0 auto;
    border-bottom: 2px solid #BCBCBC;
    order: 2;
  }
`;

const MainTextContainer = styled.div`
  height: 85%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const MainText = styled.h3`
  && {
    margin: 0;
    font-size: 1.5rem;
    width: 100%;
    color: #4E4E4E;
    order: 1;
    @media (max-width: 340px) {
      font-size: 1.25rem;
    }
    @media (min-width: 40rem) {
      font-size: 2rem;
    }
    @media (min-width: 64rem) {
      font-size: 2.5rem;
    }
  }
`;
const AuthorText = styled.div`
  && {
    font-size: 1rem;
    font-size: 1.125rem;
    color: #027AAE;
    order: 3;
  }
`;

const PagingDot = styled.div`
  cursor: pointer;
  display: inline-block;
  background-color: #BCBCBC;
  margin: 0 0.5rem;
  box-shadow: none;
  opacity: 1;
  height: 2rem;
  width: 2rem;
  max-height: 8px;
  max-width: 8px;
  border-radius: 15px;
  &.active {
    max-width: 15px;
    max-height: 15px;
    background-color: #0171AD;
    position: relative;
    top: 4px;
  }
  transition: all .25s ease-in;
`;

const CarouselContainer = styled.div`
  height: 25rem;
  width: 100%;

   & .slider-list {
    display: flex;
    align-items: center;
  }

  & .slider-control-bottomcenter {
    bottom: auto !important;
  }

`;


const QuoteCard = ({ testimonial, testimonial_author }) => (
  <QuoteCardContainer>
    <MainTextContainer>
      <MainText>
        {`"${testimonial}"`}
      </MainText>
    </MainTextContainer>
    <AuthorText>
      {testimonial_author}
    </AuthorText>
  </QuoteCardContainer>
);

const Testimonials = () => {
  const testimonials = useSelector(selectMobileFaxTestimonialData);
  if (!testimonials) {
    return null;
  }

  return (
    <TestimonialsContainer>
      <img
        src="/assets/landing-page/icon_quote.svg"
        alt="stylistic quote line art"
      />
      <CarouselContainer>
        <StyledCarousel
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          renderBottomCenterControls={({ goToSlide, currentSlide }) => {
            return testimonials.map(({ testimonials }, index) => (
              <PagingDot
                key={testimonials.testimonial_author}
                onClick={() => goToSlide(index)}
                className={index === currentSlide ? 'active' : ''}
              />
            ));
          }}
          autoplay
          autoplayInterval={2000}
          pauseOnHover
          wrapAround
        >
          {
            testimonials.map(({testimonials}) => <QuoteCard key={testimonials.testimonial_author} {...testimonials} />)
          }
        </StyledCarousel>
      </CarouselContainer>
    </TestimonialsContainer>
  );
};

export default Testimonials;