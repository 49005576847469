import styled from 'styled-components';
import { breakpoints} from '../../../utils/styleHelpers';

export const FootnotesSection = styled.div`
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px auto;
  @media (max-width: ${breakpoints.bigmobile}){
    margin: 0 15px;
  }
`;

export const FootnotesContent = styled.div`
  width: 65vw;
  margin-top: 20px;
  @media(max-width: ${breakpoints.bigmobile}){
    margin-bottom: auto;
  }
  p {
    font-family: 'Forma DJR', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #212121;
  }
`;
