import ActionTypes from "../constants/ActionTypes";

const locationDefault = {
    isFetching: false,
    city: "",
    isError: false,
    errorMsg: "",
    isHPPlusCountry: "",
    isFetchingHPPlusCountry: false,
};

/**
 * The reducer controls the state of the axios or ajax call.
 * returning the current state with any changes from the
 * actions dispatchers
 *
 *
 * @param state
 * @param action
 * @returns {*}
 */
function location(state = locationDefault, action) {

    switch (action.type) {
        case ActionTypes.START_LOCATION_FETCH:
            return { ...locationDefault, isFetching: true };

        case ActionTypes.COMPLETE_LOCATION_FETCH:
            return { ...locationDefault, city: action.city };

        case ActionTypes.COMPLETE_LOCATION_WITH_ERROR:
            return { ...locationDefault, isError: action.err.message };

        case ActionTypes.START_LOCALE_UPDATE:
            return { ...state, isComplete: false };
        case ActionTypes.COMPLETE_LOCALE_UPDATE:
            return { ...state, isComplete: true };
        case ActionTypes.COMPLETE_LOCALE_UPDATE_WITH_ERROR:
            if (action.err && action.err.message) {
                return { ...state, isError: true, errorMsg: action.err.message };
            } else {
                return { ...state, isError: true, errorMsg: action.type };
            }
        case ActionTypes.FETCH_IS_HP_PLUS_COUNTRY: 
            return { ...state, isHPPlusCountry: false, isFetchingHPPlusCountry: true  };
        case ActionTypes.FETCH_IS_HP_PLUS_COUNTRY_SUCCESS: 
            return { ...state, isHPPlusCountry: action.response, isFetchingHPPlusCountry: false  };
        case ActionTypes.FETCH_IS_HP_PLUS_COUNTRY_FAILURE: 
            return { ...state, isHPPlusCountry: false, isError: action.err.message, isFetchingHPPlusCountry: false  };
    }

    return state;
}

// usage: this.props.location
export default location;