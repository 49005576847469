import React from 'react';
import styled from 'styled-components';

const Loader = styled.div`
  @keyframes textLoader {
    0% {
      opacity: 1;
    }
    50% { 
      opacity: 0.42;
    }
    100% {
      opacity: 1;
    }
  }
  margin-left: ${props => props.left ? "inherit" : "auto"};
  svg {
    width: auto;
    line-height: 1;
    circle {
      fill: #0171AD;
      opacity: 0;
      animation: textLoader 0.8s ease infinite;
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
      &:nth-child(4) {
        animation-delay: 0.6s;
      }
      &:nth-child(5) {
        animation-delay: 0.8s;
      }
    }
  }
`

export default function LoaderElement(props) {
  return (
    <Loader left={props.left}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="67px" height="12px" viewBox="0 0 67 12">
        <circle cx="7" cy="6" r="6" ></circle>
        <circle cx="25" cy="6" r="6" ></circle>
        <circle cx="43" cy="6" r="6" ></circle>
        <circle cx="61" cy="6" r="6" ></circle>
      </svg>
    </Loader>
  );
}
