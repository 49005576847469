import { takeEvery, put, call, } from 'redux-saga/effects';
import ucdeUserActions from '../components/Dashboard/store/modules/ucdeUser/actions';
import actionTypes from '../constants/ActionTypes';
import {
  getMetaTagByName,
  setTagAttribute,
} from '../utils/globals';
import { saveUDLParams } from '../actions/analytics';
import { encrypt } from '../services/encryption';

function* handleUpdateAppFromUserInfo({ payload: { data: userInfo } }) {
  const {
    country,
    externalIdMap,
  } = userInfo;

  if (country) {
    const tag = yield getMetaTagByName('target_country');
    yield setTagAttribute(tag, 'content', country);
  }
  if (externalIdMap) {
    const { data: { userID } } = yield call(encrypt, { userID: externalIdMap.hpid });

    yield put(saveUDLParams({ userID }));
  }
  return null;
}

export default function* watchCompleteUserInfo() {
  yield takeEvery(
    [actionTypes.COMPLETE_USER_INFO, ucdeUserActions.loadUcdeUserSuccess.toString()],
    handleUpdateAppFromUserInfo,
  );
}
