import React, { Component } from 'react';
import bind from './../../bind';
import styled from 'styled-components';
import Accordion from './Accordion';
import { TweenLite, Power2, Power4, ScrollToPlugin } from 'gsap/all';
import { refreshToTop } from './../../../lib/refreshToTop';

const plugins = [ ScrollToPlugin ];
class InPageMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navIsSticky: false,
      dropDownOpen: false,
      groupControl: { identifier: "", group: "" }
    };
    this.gnbHeight = 100
    this.elementData = []
    this.scroll = 0
    this.dropDownHeight = 500
    this.currentSection = "none"
    this.currentDeepLinkSection = ""
    this.sectionList = []
    this.deeplinkList = []
    this.mobileMenuTitle = ""
    this.handleScroll = this.handleScroll.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.changeSticky = this.changeSticky.bind(this);
    this.updateScroll = this.updateScroll.bind(this);
    this.checkChangeSelection = this.checkChangeSelection.bind(this);
    this.updateAddressBar = this.updateAddressBar.bind(this)
    this.deeplinkURLSegment = Number(this.props.deeplinkURLSegment)
    this.deeplink_destination = ""
    this.deeplinkingInProgress = false
  }

  componentDidMount() {
    refreshToTop()
    let window_location_pathname = window.location.pathname.split("/")
    const deeplinkCondition = window_location_pathname.length === (this.deeplinkURLSegment + 1) && window_location_pathname[this.deeplinkURLSegment] !== ""
    window.addEventListener('scroll', this.handleScroll);
    this.gnbHeight = (document.getElementsByClassName('gnb') === void 0) ? document.getElementsByClassName('gnb')[0].offsetHeight : 0;
    this.dropDownHeight = (this.props.sectionList.length + 1) * 49
    if (deeplinkCondition) {
      this.deeplink_destination = window_location_pathname[this.deeplinkURLSegment]
      setTimeout(
        this.deepLink
        ,
        2340
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  checkChangeSelection() {
    this.sectionList = this.props.sectionList
    if (this.sectionList.length > 0) {
      let newCurentSection = this.currentSection
      let newIndex = null
      const buffer = Math.round(window.innerHeight * .17)
      this.sectionList.map((section, i) => {
        if (i == 0 && (!!document.getElementById(section.sectionID) && document.getElementById(section.sectionID).offsetTop > this.scroll + buffer) && (this.currentSection != "none")) {
          newCurentSection = "none"
        } else if (
          (!!document.getElementById(section.sectionID) && document.getElementById(section.sectionID).offsetTop <= this.scroll + buffer) &&
          ((!!document.getElementById(section.sectionID) && document.getElementById(section.sectionID).offsetTop + document.getElementById(section.sectionID).offsetHeight) > this.scroll + buffer) &&
          (this.currentSection != section.sectionID)
        ) {
          newIndex = i
          newCurentSection = section.sectionID
        }
      })
      if (this.currentSection !== newCurentSection) {
        this.mobileMenuTitle = (newIndex === null) ? this.sectionList[0].sectionTitle : this.sectionList[newIndex].sectionTitle
        this.currentSection = newCurentSection
        this.forceUpdate()
      }
    }
  }

  updateAddressBar() {
    this.deeplinkList = this.props.deeplinkList
    if (this.deeplinkList.length > 0) {
      let newCurentSection = this.currentDeepLinkSection
      // let newIndex = null
      const buffer = Math.round(window.innerHeight * .17)
      this.deeplinkList.map((section, i) => {
        if (i == 0 && (!!document.getElementById(section.sectionID) && document.getElementById(section.sectionID).offsetTop > this.scroll + buffer) && (this.currentDeepLinkSection != "")) {
          newCurentSection = ""
        } else if (
          (!!document.getElementById(section.sectionID) && document.getElementById(section.sectionID).offsetTop <= this.scroll + buffer) &&
          ((!!document.getElementById(section.sectionID) && document.getElementById(section.sectionID).offsetTop + document.getElementById(section.sectionID).offsetHeight) > this.scroll + buffer) &&
          (this.currentDeepLinkSection != section.sectionID)
        ) {
          newCurentSection = section.sectionID
        }
      })
      if (this.currentDeepLinkSection !== newCurentSection) {
        this.currentDeepLinkSection = newCurentSection
        const search = window.location.search ? window.location.search : ""
        const window_location_array = window.location.href.split("?")[0].toLowerCase().split("/");
        window_location_array.length = this.deeplinkURLSegment + 2
        window_location_array.push(this.currentDeepLinkSection)
        const strNewPath = window_location_array.join("/") + search
        if (window.history.pushState) {
          window.history.replaceState({ foo: "bar" }, "page two", strNewPath)
        }
      }
    }
  }

  deepLink = () => {
    let anchor = this.deeplink_destination
    if (!!document.getElementById(anchor)) {
      this.deeplinkingInProgress = true
      const completeFn = () => {
        this.deeplinkingInProgress = false
        this.checkChangeSelection()
        this.updateAddressBar()
      }
      const finalizeFn = () => {
        TweenLite.to(window, 1.8169275, {
          scrollTo: `#${anchor}`,
          ease: Power4.easeOut,
          autoKill: false,
          onComplete: completeFn
        });
      }

      const isiOS = navigator.platform.indexOf('iP') > -1
      if (isiOS) {
        anchor = anchor.indexOf("#") === 0 ? anchor.substr(1) : anchor
        const element = document.getElementById(anchor);
        element.scrollIntoView({ behavior: "smooth", block: "start" });
        setTimeout(
          () => {
            completeFn()
          }
          ,
          600
        )
      } else {
        TweenLite.to(window, 2.1, {
          scrollTo: `#${anchor}`,
          ease: Power2.easeInOut,
          autoKill: false,
          onComplete: finalizeFn
        });
      }
    }
  }

  handleScroll = () => {
    this.scroll = window.pageYOffset
    let debounceFn = function (func, wait, immediate = false) {
      return function () {
        let context = this, args = arguments;
        let later = function () {
          _GLOBAL_TIMEOUT = null;
          if (!immediate) func.apply(context, args);
        };
        clearTimeout(_GLOBAL_TIMEOUT);
        _GLOBAL_TIMEOUT = setTimeout(later, wait);
      };
    };
    debounceFn(this.updateScroll, 11)()
  }

  updateScroll() {
    this.changeSticky()
    if (!this.deeplinkingInProgress) {
      this.checkChangeSelection()
      this.updateAddressBar()
    }
  }

  changeSticky() {
    if ((this.scroll > this.gnbHeight) && (this.state.navIsSticky == false)) {
      this.setState({ navIsSticky: true })
    }
    if ((this.scroll <= this.gnbHeight) && (this.state.navIsSticky == true)) {
      this.setState({ navIsSticky: false })
    }
  }

  handleToggle(e) {
    this.setState({ dropDownOpen: !this.state.dropDownOpen })
    e.preventDefault()
  }

  handleClick(ID) {
    this.deeplinkingInProgress = true
    this.setState({
      navIsSticky: true,
      groupControl: { identifier: "NONE", group: "inmenu" }
    })
    const completeFn = () => {
      this.deeplinkingInProgress = false
      this.checkChangeSelection()
      this.updateAddressBar()
    }

    const isiOS = navigator.platform.indexOf('iP') > -1
    if (isiOS) {
      ID = ID.indexOf("#") === 0 ? ID.substr(1) : ID
      const element = document.getElementById(ID);
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      setTimeout(
        () => {
          completeFn()
        }
        ,
        600
      )
    } else {
      TweenLite.to(window, 1.25, {
        scrollTo: ID,
        autoKill: false,
        ease: Power2.easeOut,
        onComplete: completeFn
      });
    }
  }

  groupFn = o => {
    this.setState({
      groupControl: { identifier: o.myID, group: o.groupID }
    });
  };

  callbackFn = (isOpen, thisID) => {
    this.setState({ dropDownOpen: isOpen })
  };

  getMenuItems = () => (
    <ul>
      {this.props.sectionList.map((section, i) =>
        <li key={i}><a id={`inPageMenu_${section.sectionID}`} data-link-id={section.sectionID} onClick={() => this.handleClick(`#${section.sectionID}`)} ref={`#${section.sectionID}`}><span>{section.sectionTitle}</span></a></li>
      )}
    </ul>
  )

  render() {
    const { device } = this.props;
    if (device.isComplete && device.isError) {
      headline = <p>{device.errorMsg}</p>;
    }
    this.mobileMenuTitle = this.mobileMenuTitle === "" ? this.props.sectionList[0].sectionTitle : this.mobileMenuTitle

    return (
      <div>
        <StyledMenu id="inPageNav"
          navIsSticky={this.state.navIsSticky}
          gnbHeight={this.gnbHeight}
          dropDownOpen={this.state.dropDownOpen}
          dropDownHeight={this.dropDownHeight}
          currentSection={this.currentSection}
        >
          {
            this.props.gnb.isTablet && <div>
              <div id="mobileMenuTrigger" className="mobileMenu"><span>{this.mobileMenuTitle}</span></div>
              <Accordion ID="inPageMenuAccordion" actionID="mobileMenuTrigger" groupID="inmenu" groupFn={this.groupFn} controler={this.state.groupControl} callbackFn={this.callbackFn} invertEasing={false} addSeconds={-.15} offsetSeconds={-.0} >
                {this.getMenuItems()}
              </Accordion>
            </div>
          }
          {this.props.gnb.isTablet || this.getMenuItems()}
        </StyledMenu >

      </div>
    );
  }
}

export default bind(InPageMenu);


let _GLOBAL_TIMEOUT = 9; // necessary for debounce


const StyledMenu = styled.menu`
  position: ${props => props.navIsSticky ? 'fixed' : 'absolute'};
  top: 0;
  z-index: 3;
  width: 100%;
  padding-inline-start: 0;
  padding: 0;
  margin: 0;
  @media (min-width: 1024px) {
    border-bottom: 1px #F6F6F6 solid;
    background-color: #fcfcfc;
  }
  div div {
    display: block;
    @media (min-width: 1024px) {
      display: none;
    }
  }
  .mobileMenu {
    background-color: #fcfcfc;
    border-bottom: 1px #F6F6F6 solid;
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    padding: .75rem 40px .75rem 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    line-height: 1.5265rem;
    color: black;
    &:hover, &:active, &:focus {
      color: black;
      text-decoration: none;
    }
    span {
      background-color: #fcfcfc;
      display: flex;
      &:after {
        content: '';
        display: flex;
        border-top: ${props => props.dropDownOpen ? '1px solid #767676' : 'none'};
        border-right: ${props => props.dropDownOpen ? 'none' : '1px solid #767676'};
        border-bottom: ${props => props.dropDownOpen ? 'none' : '1px solid #767676'};
        border-left: ${props => props.dropDownOpen ? '1px solid #767676' : 'none'};
        margin: ${props => props.dropDownOpen ? '.5625rem .625rem 0 1rem' : '.0625rem .625rem 0 1rem'};
        width: .75rem;
        height: .75rem;
        -webkit-transform: rotate(45deg); 
        transform: rotate(45deg); 
      }
    }
  }
  a {
    background-color: #fcfcfc;
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    padding: .75rem 40px .75rem 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    line-height: 1.5265rem;
    color: black;
    &:hover, &:active, &:focus {
      color: black;
      text-decoration: none;
    }
  }
  div a span {
    background-color: #fcfcfc;
    display: flex;
  }
  ul {
    max-width: 80rem;
    list-style-type: none;
    margin-block-start: 0;
    background-color: #fcfcfc;
    margin: 0;
    display: flex;
    height: ${props => props.dropDownOpen ? `${props.dropDownHeight}px` : `${props.dropDownHeight}px`};
    /* transition: height 0.425s ease-out; */
    overflow: hidden;
    flex-direction: column;
    box-shadow: 0 3px 7px 0 rgba(0,0,0,0.1);
    @media (min-width: 1024px) {
      margin: 0 auto;
      height: auto;
      flex-direction: row;
      justify-content: space-evenly;
      box-shadow: none;
    }
  }
  li {
    border-bottom: 1px #F6F6F6 solid;
    position: relative;
    display: flex;
    flex: 1 1 auto;
    @media (min-width: 1024px) {
      border-bottom: none;
      &:not(:first-child):before {
        content: '|';
        color: #DFDFDF;
        display: inline-block;
        position: absolute;
        align-self: center;
        line-height: 1.5625;
        width: 0;
        height: 1.5625rem;
        top: 1.5rem;
        left: 0;
      }
    }
    a {
      color: black;
      font-size: .875rem;
      width: 100%;
      &[data-link-id=${props => props.currentSection ? `${props.currentSection}` : 'none'}] {
        color: #0171ad;
      }
      @media (min-width: 1024px) {
        justify-content: center;
        font-size: 1rem;
        height: 4.6875rem;
        padding: 0 1.5625rem;
        text-align: center;
        line-height: 1.5625;
        white-space: nowrap;
        span:after {
          content: '';
          display: block;
          height: 2px;
          width: 0;
          left: 0;
          position: absolute;
          right: 0;
          bottom: 1.0625rem;
          margin: 0 auto;
          background: #0096d6;

        }
        &[data-link-id=${props => props.currentSection ? `${props.currentSection}` : 'none'}] {
          color: black;
          span:after {
            transition: all .3s ease;
            background: #191919;
            width: 40px;
          }
        }
        &:not([data-link-id=${props => props.currentSection ? `${props.currentSection}` : 'none'}]):hover {
          span:after {
            transition: all .3s ease;
            background: #0096d6;
            width: 40px;
          }
        }
      }
    }
  }
`

