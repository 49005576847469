import React from 'react';
import { TweenLite, Power2 } from 'gsap';
import { prop } from 'ramda';
import styled, { ThemeProvider } from 'styled-components';
import bind from '../bind';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';
import MenuItem from './MenuItem';
import { getInstantInkSupportedCountries } from '../../utils/helperApi';
import {
  libraryTheme,
  MainContent,
  LibraryContainer,
  MenuSection,
  MenuSubSection,
  Widescreen,
  SectionTitle,
  StyledLink
} from '../shared-components/library-css/LibraryCss';
import MenuContent from './MenuContent';
import { smartHelpTracking } from '../../lib/analyticsTracking';
import { refreshToTop } from '../../lib/refreshToTop';
import Accordion from '../shared-components/organisms/Accordion';
import { get_hpsmartCustom } from '../../lib/smartAppIntegrations';
import { setCookie, getConsentCookie } from '../../lib/manageCookies';
import { VA_LINK_SUPPORTED_LANGS } from '../../constants/i18n';
import { getBrowserLocale } from '../../utils/globals';
import { splitLocaleIntoCountryAndLanguage } from '../../utils/routing';
import { isUserLoggedIn } from '../../lib/userUtils';
import { HPC3_SESSION_TYPE } from '../../constants/cookieNames';
import { getCookie } from '../../lib/manageCookies';
import Config from '../../config/Config';
import HelpAssistant from '../../images/misc/help-assistant.svg';
import { userOSDetector } from '../../utils/userOSDetector';

const MENU_CONTENT = 'menuContent';
const ACCORDION_INITIALS = 'accordion_';

const analyticsIds = [
  "About HP Smart",
  "hpPlus",
  "HP Smart Advance",
  "HP Instant Ink",
  "Printer and Connection Information",
  "printScanAndShare",
  "Additional Help and Support"
];

const sortSmartHelpSections = (sections) => {
  return analyticsIds.map(analyticsId => (
    (sections.filter(section => section.analytics_id === analyticsId))[0]
  ))
}

class SmartHelp extends React.Component {
  constructor(props) {
    super(props);

    this.breakpoint = 768;

    this.state = {
      groupControl: { identifier: '', group: '' },
      activeItem: '',
      is_mobile: window.innerWidth < this.breakpoint,
      myHeight: window.innerHeight,
      geoCountry: null,
      fetchingUserData: false,
      InstantInkCountries: []
    };

    this.isUserLoggedIn = isUserLoggedIn();
    this.acceptedPlatforms = ['web', 'android', 'desktop', 'ios'];
    this.platform = props.match.params.platform
      ? this.acceptedPlatforms.indexOf(props.match.params.platform.toLowerCase()) != -1
        ? props.match.params.platform.toLowerCase()
        : 'android'
      : 'web';
    this.trackingHandler = this.trackingHandler.bind(this);
    this.resizeFn = this.resizeFn.bind(this);
    window.addEventListener('resize', this.resizeFn);
    this.deepLink = this.deepLink.bind(this);
    this.deeplinkPath = props.match.params.topic ? props.match.params.topic.toString().toLowerCase() : false;

    this.deeplinkPath = !this.deeplinkPath ? 'getting-to-know-hp-smart-app' : this.deeplinkPath;
    this.linkTracking = this.linkTracking.bind(this);
    this.updateAddressBar = this.updateAddressBar.bind(this);
    this.domOverlay = this.domOverlay.bind(this);
    this.do_once = 0;
    this.onChatBotClick = this.onChatBotClick.bind(this);
    this.onContactHPClick = this.onContactHPClick.bind(this);
    this.overlayIdWhiteList = [
      'printerSupport',
      'SupportLink',
      'SoftwareLink',
      'psdr1',
      'psdr2',
      'chatbotLink',
      'goToYourPrintersPrintQualityTools'
    ];
    this.overlayPropertyWhiteList = ['innerText', 'href', 'style.display', 'removeElement'];
    this.domOverlayPending = true;
    this.isInApp =
      window.location.href.split('?')[0].toLowerCase().split('/').indexOf('in-app-help') > -1;

    this.shouldShowForAndroid = this.shouldShowForAndroid.bind(this);
    this.shouldShowForIOS = this.shouldShowForIOS.bind(this);
    this.shouldShowForDesktop = this.shouldShowForDesktop.bind(this);
    this.handleInstantInkDisplay = this.handleInstantInkDisplay.bind(this);
    this.handleSmartAdvanceDisplay = this.handleSmartAdvanceDisplay.bind(this);
  }

  componentDidUpdate(prevProps) {
    const exists = document.getElementById(this.deeplinkPath);
    if (exists !== null) {
      if (this.do_once < 1) {
        this.do_once++;
        refreshToTop();
        setTimeout(this.deepLink, 1000);
      }
    }
    if (this.domOverlayPending) {
      this.domOverlay();
    }
  }

  componentDidMount() {
    const { userInfo, getUser, getUserPrograms } = this.props;
    const { fetchingUserData } = this.state;
    getInstantInkSupportedCountries().then((responce) => {
      const lowerCasedValues = responce.data.instantInkSupportedRegions.map((countries) =>
        countries.toLowerCase()
      );
      this.setState({ InstantInkCountries: lowerCasedValues });
    });

    const setConsent = () => {
      const cname = 'OptanonConsent';
      const cvalue = 'groups=1:1,2:1';
      const exdays = 365;
      setCookie(cname, cvalue, exdays);
      const cname2 = 'OptanonAlertBoxClosed';
      const cvalue2 = '[date]';
      const exdays2 = 365;
      setCookie(cname2, cvalue2, exdays2);

      // get platform
      // https://stackoverflow.com/questions/10527983/best-way-to-detect-mac-os-x-or-windows-computers-with-javascript-or-jquery
      const isMAC = navigator.platform.indexOf('Mac') > -1 || navigator.platform.indexOf('iP') > -1;
      const platform = isMAC ? 'mac' : 'win';
      const window_location_array = window.location.href.split('?')[0].toLowerCase().split('/');
      const indexOfInAppSegmentRoot = window_location_array.indexOf('in-app-help');
      if (indexOfInAppSegmentRoot > -1) {
        if (platform === 'mac') {
          window.location.href = 'hpsmart-mac://callback/?Action=acceptedCookies';
        } else if (platform === 'win') {
          window.location.href = 'hpsmart-win://callback/?Action=acceptedCookies';
        }
      }
    };

    const search = window.location.search.substring(1).split('&');

    const acceptedCookies = search.indexOf('acceptedCookies=true') > -1;
    const alreadyAccepted = getConsentCookie()[2] === 1;
    if (acceptedCookies === true && !alreadyAccepted) {
      setConsent();
    } else {
      // attach listener to the consent banner action so as to send an action callback to app
      setTimeout(() => {
        const consentButton = document.getElementsByClassName('accept-cookies-button');
        if (consentButton[0]) {
          consentButton[0].addEventListener('click', setConsent, false);
        }
      }, 3300);
    }

    window.scrollTo(0, 0);
    const browserLocale = getBrowserLocale();
    const { country } = splitLocaleIntoCountryAndLanguage(browserLocale);
    this.setState({ geoCountry: country });

    if (
      userInfo.userInfoData === null &&
      !fetchingUserData &&
      getCookie(HPC3_SESSION_TYPE) === 'user'
    ) {
      this.setState({ fetchingUserData: true });
      getUser(() => this.setState({ fetchingUserData: false }));

      getUserPrograms();
    }
  }

  componentWillUnmount() {
    const setConsent = () => {};
    window.scrollTo(0, 0);
    window.removeEventListener('resize', this.resizeFn);
    const consentButton = document.getElementsByClassName('accept-cookies-button');
    if (consentButton[0]) {
      consentButton[0].removeEventListener('click', setConsent, false);
    }
  }

  deepLink() {
    if (typeof this.deeplinkPath === 'string' && this.deeplinkPath.length > 0) {
      const should_scroll = this.state.is_mobile; // / how to tell if we need to scroll?
      const clickit = (x) => {
        document.getElementById(this.deeplinkPath).click();
      };
      const delayed_click = (o) => {
        this.groupFn({ myID: 'anystring', groupID: 'underAccordion' });
        setTimeout(clickit, 10);
      };
      const delayed_scroll = (o) => {
        TweenLite.to(window, 1.275, {
          scrollTo: `#${this.deeplinkPath}`,
          autoKill: false
        });
        setTimeout(delayed_click, 600);
      };

      if (should_scroll) {
        const delayed_act = (o) => {
          const gottenElement = document.getElementById(
            `accordion_${this.deeplinkPath}_inner_lining`
          );
          const gottenHeight = gottenElement.getBoundingClientRect().height;

          const under_accordion = document.getElementById('accordion_under_animator');

          TweenLite.set(under_accordion, { height: gottenHeight });

          setTimeout(delayed_scroll, 100);
        };
        setTimeout(delayed_act, 20);
      } else {
        clickit();
      }
    }
  }

  resizeFn = () => {
    const debounce = (func, delay) => {
      let inDebounce;
      return (function () {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), delay);
      })();
    };
    const resizeLayout = () => {
      if (this.state.is_mobile !== window.innerWidth < this.breakpoint) {
        this.domOverlayPending = true;
        this.setState({
          is_mobile: window.innerWidth < this.breakpoint
        });
      }
      if (this.state.myHeight !== window.innerHeight) {
        this.setState({
          myHeight: window.innerHeight
        });
      }
    };
    debounce(resizeLayout, 250);
  };

  groupFn = (o) => {
    this.setState({
      groupControl: { identifier: o.myID, group: o.groupID }
    });
  };

  updateAddressBar(thisID) {
    const { search } = window.location;
    const window_location_array = window.location.href.split('?')[0].toLowerCase().split('/');
    const indexOfHelpSegmentRoot = window_location_array.indexOf('smart-help');
    const indexOfInAppSegmentRoot = window_location_array.indexOf('in-app-help');
    if (indexOfHelpSegmentRoot != -1) {
      window_location_array.length = indexOfHelpSegmentRoot + 1;
    } else if (indexOfInAppSegmentRoot != -1) {
      window_location_array.length = indexOfInAppSegmentRoot + 2;
    }
    window_location_array.push(thisID);
    let strNewPath = window_location_array.join('/') + search;

    // REMOVE TRAILING
    if (strNewPath.substr(-1) === '/') {
      strNewPath = strNewPath.substr(0, strNewPath.length - 1);
    }

    if (window.history.pushState) {
      window.history.replaceState({ foo: 'bar' }, 'page two', strNewPath);
    }
  }

  callbackFn = (isOpen, thisID) => {
    if (isOpen) {
      smartHelpTracking(thisID);
      if (this.is_now_in_mobile()) {
        setTimeout(() => {
          TweenLite.to(window, 1.275, {
            scrollTo: `#${thisID}`,
            ease: Power2.easeOut,
            autoKill: true
          });
        }, 503);
      }
    }

    const oneZero = isOpen ? 1 : 0;
    const icn = `#${thisID}_arrow`;
    TweenLite.to(icn, 0.41, { rotationX: -180 * oneZero });

    if (isOpen) {
      this.setState({ activeItem: thisID });
      this.updateAddressBar(thisID);
      this.groupFn({
        groupID: MENU_CONTENT,
        myID: `${ACCORDION_INITIALS}${thisID}`
      });
    } else {
      this.setState({ activeItem: 'none' });
      this.updateAddressBar('');
    }
  };

  trackingHandler(ID) {
    document.getElementById(ID) && document.getElementById(ID).click();
  }

  linkTracking(thisID) {
    const doNotTrackPageView = true;
    smartHelpTracking(thisID, doNotTrackPageView);
  }

  slugify(str = '') {
    return str
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-');
  }

  camelize(str) {
    return str.replace(/[^a-z ]/gi, '').replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
      return index == 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  onChatBotClick() {
    const customObject = get_hpsmartCustom();
    const { country, language } = this.props.match.params;
    const config = new Config();
    const botClient = config.Get('BOT_CLIENT');
    const botSubClient = config.Get('BOT_SUB_CLIENT');
     
    const chatbotLink =
      customObject && customObject.chatbotLink && customObject.chatbotLink.href
        ? customObject.chatbotLink.href
        : '';
    if (chatbotLink) {
      window.open(chatbotLink);
    } else {
      // GENERIC LINK -- IS THERE A DEFAULT ?
      window.open(`https://virtualagent.hpcloud.hp.com/?BotClient=${botClient}&cc=${country}&lc=${language}&BotSubClient=${botSubClient}`);
    }
  }

  onContactHPClick(contactLink) {
    window.open(contactLink);
  }

  domOverlay() {
    const smartCustomObj = get_hpsmartCustom();
    if (smartCustomObj) {
      const objKeys = Object.keys(smartCustomObj);
      if (objKeys.indexOf('psdrLink') > -1) {
        const psdr_ids = ['psdr1', 'psdr2']; // ids of duplications of psdrLink
        psdr_ids.forEach((ID) => {
          const element_ = document.getElementById(ID);
          if (element_) {
            const element_a = element_.getElementsByTagName('a')[0];
            element_a.href = smartCustomObj.psdrLink.href;
            element_a.id = `${ID}a`;
          }
        });
      }
      objKeys.forEach((key) => {
        if (this.overlayIdWhiteList.indexOf(key) !== -1) {
          const elem = document.getElementById(key);
          if (elem) {
            const props = Object.keys(smartCustomObj[key]);
            const obj = smartCustomObj[key];
            props.forEach((prop) => {
              if (this.overlayPropertyWhiteList.indexOf(prop) !== -1) {
                if (prop.indexOf('.') !== -1) {
                  const propArr = prop.split('.');
                  elem[propArr[0]][propArr[1]] = obj[prop];
                } else if (prop === 'removeElement') {
                  elem.parentNode.removeChild(elem);
                } else if (!!elem.querySelector('.overlay-text') && prop == 'innerText') {
                  elem.querySelector('.overlay-text')[prop] = obj[prop];
                } else {
                  elem[prop] = obj[prop];
                }
              }
            });
          }
        }
      });
    }
  }

  is_now_in_mobile = () => this.state.is_mobile;

  isLanguageSupported = () => {
    return VA_LINK_SUPPORTED_LANGS.has(this.props.locales.languageCode);
  };

  shouldShowForIOS = (section) => {
    return (
      this.platform == 'ios' &&
      ((!!section.helpSection.help_for_ios && section.helpSection.help_for_ios.length > 10) ||
        (!!section.helpSection.help_for_mobile &&
          section.helpSection.help_for_mobile.length > 10) ||
        (!!section.helpSection.help_for_all_apps &&
          section.helpSection.help_for_all_apps.length > 10))
    );
  };

  shouldShowForAndroid = (section) => {
    return (
      this.platform == 'android' &&
      ((!!section.helpSection.help_for_android &&
        section.helpSection.help_for_android.length > 10) ||
        (!!section.helpSection.help_for_mobile &&
          section.helpSection.help_for_mobile.length > 10) ||
        (!!section.helpSection.help_for_all_apps &&
          section.helpSection.help_for_all_apps.length > 10))
    );
  };

  shouldShowForDesktop = (section) => {
    return (
      this.platform == 'desktop' &&
      ((!!section.helpSection.help_for_desktop &&
        section.helpSection.help_for_desktop.length > 10) ||
        (!!section.helpSection.help_for_all_apps &&
          section.helpSection.help_for_all_apps.length > 10))
    );
  };

  handleInstantInkDisplay = (section) => {
    const INSTANT_INK_ANALYTICS_ID = 'HP Instant Ink';

    return (
      section.analytics_id !== INSTANT_INK_ANALYTICS_ID ||
      (section.analytics_id === INSTANT_INK_ANALYTICS_ID &&
        this.state.InstantInkCountries.includes(this.props.locales.countryCode))
    );
  };

  handleSmartAdvanceDisplay = (section) => {
    const SMART_ADVANCE_ANALYTICS_ID = 'HP Smart Advance';
    const ENTITLEMENT_TO_MATCH = 'Smart Advance';

    let validEntitlement = [];

    if (this.props.userPrograms.data !== null) {
      const entitlements = this.props.userPrograms.data.entitlements;
      validEntitlement = entitlements.filter(
        (entitlement) =>
          entitlement.title === ENTITLEMENT_TO_MATCH && entitlement.state === 'ENABLED'
      );
    }

    return (
      section.analytics_id !== SMART_ADVANCE_ANALYTICS_ID ||
      (section.analytics_id === SMART_ADVANCE_ANALYTICS_ID && validEntitlement.length > 0)
    );
  };

  checkDataWithCorrectLocal = (currentLocale='', dataLocale='') => {
    const currentLang = currentLocale.split('-')[1];
    const dataLang = dataLocale.split('-')[0];
    return (currentLang === dataLang);
  }

  render() {
    const {
      contentStack: { smart_help, smart_help_sections },
      locales: { alt2 }
    } = this.props;
    let smartHelpSectionContentStackData = smart_help_sections?.data?.entries;
    const smartHelpContentData = smart_help?.data?.entries[0];
    const contentStackLocale = smartHelpSectionContentStackData?.[0]?.['locale']

    if (
      smartHelpSectionContentStackData &&
      smartHelpContentData &&
      !this.props.location.isFetchingHPPlusCountry && this.checkDataWithCorrectLocal(alt2, contentStackLocale)
    ) {
      // remove the userProgram.data!==null because the userProgram.data is null, blocking the following redering of the smart-help page
      const defaultPlatforms = {
        desktopApp: 'Desktop',
        mobileApp: 'Mobile',
        iosApp: 'iOS',
        androidApp: 'Android'
      };
      const userOperatingSystem = userOSDetector();
      const mobileOperatingSystems = new Set([defaultPlatforms.androidApp]);
      const filteredSections = sortSmartHelpSections(smartHelpSectionContentStackData);
      smartHelpSectionContentStackData = filteredSections;
      const platformTitles =
        smartHelpContentData?.smart_help_app_section_titles[0]?.smart_titles || defaultPlatforms;
      const smartHelpData = smartHelpContentData?.smart_help_hero?.[0];
      const { contact_hp_button_link:hpContactBtnLink, contact_hp_button_text:contactHpBtnText } = smartHelpData?.smart_hero_block;
      return (
        <ThemeProvider theme={libraryTheme}>
          <MainContent id="main-content" currentPlatform={this.platform}>
            <LibraryContainer currentPlatform={this.platform}>
              <MenuSection
                currentPlatform={this.platform}
                isMobile={this.state.is_mobile}
                isInApp={this.isInApp}
                myHeight={this.state.myHeight}
              >
                {this.isLanguageSupported() && (
                  <StyledHero isMobile={this.state.is_mobile}>
                    <img src={HelpAssistant} alt="help-assistant" />
                    <h1>{smartHelpData?.smart_hero_block?.smart_help_hero_title}</h1>
                    <p>{smartHelpData?.smart_hero_block?.smart_help_subheader}</p>
                    <StyledButton id="chatbotLink" onClick={this.onChatBotClick} type="button">
                      {smartHelpData?.smart_hero_block?.agent_button_text}
                    </StyledButton>
                    <a
                      id="SupportLink"
                      onClick={this.deepLink}
                      style={{ visibility: 'hidden' }}
                      target="_blank"
                    >
                      curently being used as a spacer
                    </a>
                  </StyledHero>
                )}
                {contactHpBtnText &&
                  <StyledButton id="contacthpLink" onClick={() => this.onContactHPClick(hpContactBtnLink)} type="button">
                    {contactHpBtnText}
                  </StyledButton>
                }
                {smartHelpSectionContentStackData.map(
                  (helpSection, index) =>
                    this.handleInstantInkDisplay(helpSection) && (
                      <MenuSubSection
                        key={index}
                        isMobile={this.state.is_mobile}
                        currentPlatform={this.platform}
                      >
                        <SectionTitle
                          currentPlatform={this.platform}
                          isMobile={this.state.is_mobile}
                        >
                          {helpSection.title}
                        </SectionTitle>
                        {helpSection.app_help_section &&
                          helpSection.app_help_section.map((section, appHelpSectionIndex) => (
                            <div key={appHelpSectionIndex}>
                              {this.handleSmartAdvanceDisplay(section) &&
                                section?.helpSection?.help_topic &&
                                (this.shouldShowForIOS(section) ||
                                  this.shouldShowForAndroid(section) ||
                                  this.shouldShowForDesktop(section) ||
                                  this.platform == 'web') && (
                                  <MenuItem
                                    isMobile={this.state.is_mobile}
                                    key={appHelpSectionIndex}
                                    ID={this.slugify(
                                      section.helpSection.analytics_id
                                        ? section.helpSection.analytics_id
                                        : section.helpSection.help_topic
                                    )}
                                    accordionTitle={section.helpSection.help_topic}
                                    trackingHandler={this.trackingHandler}
                                    active={
                                      this.state.activeItem ==
                                      this.slugify(
                                        section.helpSection.analytics_id
                                          ? section.helpSection.analytics_id
                                          : section.helpSection.help_topic
                                      )
                                    }
                                    currentPlatform={this.platform}
                                    section={section}
                                  />
                                )}

                              {section?.Help_link?.help_link_address && (
                                <StyledLink
                                  id={this.camelize(
                                    !!section.Help_link.analytics_id &&
                                      section.Help_link.analytics_id
                                  )}
                                  target="_blank"
                                  href={section.Help_link.help_link_address}
                                  onClick={() =>
                                    this.linkTracking(
                                      this.slugify(section.Help_link.analytics_id)
                                    )
                                  }
                                  currentPlatform={this.platform}
                                  isMobile={this.state.is_mobile}
                                >
                                  <span className="overlay-text">
                                    {section.Help_link.help_link_text}
                                  </span>
                                  <img alt="" src="/assets/img/exitLink.svg" />
                                </StyledLink>
                              )}
                              {/* Content sections for mobile devices and web below breakpoint. Help links do not have corresponding content */}
                              {this.state.is_mobile &&
                                section?.helpSection?.help_topic &&
                                !this.props.location.isFetchingHPPlusCountry && (
                                  <MenuContent
                                    is_in_MobileFn={this.is_now_in_mobile}
                                    isMobile={this.state.is_mobile}
                                    platformTitles={platformTitles}
                                    section={section}
                                    ID={this.slugify(
                                      section.helpSection.analytics_id
                                        ? section.helpSection.analytics_id
                                        : section.helpSection.help_topic
                                    )}
                                    actionID={this.slugify(
                                      section.helpSection.analytics_id
                                        ? section.helpSection.analytics_id
                                        : section.helpSection.help_topic
                                    )}
                                    groupID="menuContent"
                                    groupFn={this.groupFn}
                                    controler={this.state.groupControl}
                                    callbackFn={this.callbackFn}
                                    currentPlatform={this.platform}
                                    isOpenOnLoad={!appHelpSectionIndex && !index}
                                    isHPPlusCountry={this.props.location.isHPPlusCountry}
                                    isFetchingHPPlusCountry={
                                      this.props.location.isFetchingHPPlusCountry
                                    }
                                  />
                                )}
                            </div>
                          ))}
                      </MenuSubSection>
                    )
                )}
                {this.state.is_mobile && (
                  <Accordion
                    ID="under_animator"
                    groupID="underAccordion"
                    groupFn={this.groupFn}
                    controler={this.state.groupControl}
                    callbackFn={this.callbackFn}
                    addSeconds={0.25}
                    isSubAccordionOpen
                  />
                )}
              </MenuSection>

              {this.state.is_mobile === false && (
                <Widescreen isInApp={this.isInApp} myHeight={this.state.myHeight}>
                  {smartHelpSectionContentStackData.map((helpSection, mainIndex) => (
                    <div key={mainIndex}>
                      {helpSection.app_help_section &&
                        !this.props.location.isFetchingHPPlusCountry &&
                        helpSection.app_help_section.map((section, subIndex) => (
                          <div key={subIndex}>
                            {section?.helpSection?.help_topic && (
                              <MenuContent
                                is_in_MobileFn={this.is_now_in_mobile}
                                isMobile={this.state.is_mobile}
                                platformTitles={platformTitles}
                                section={section}
                                ID={this.slugify(
                                  section.helpSection.analytics_id
                                    ? section.helpSection.analytics_id
                                    : section.helpSection.help_topic
                                )}
                                actionID={this.slugify(
                                  section.helpSection.analytics_id
                                    ? section.helpSection.analytics_id
                                    : section.helpSection.help_topic
                                )}
                                groupID="menuContent"
                                groupFn={this.groupFn}
                                controler={this.state.groupControl}
                                currentPlatform={this.platform}
                                callbackFn={this.callbackFn}
                                isOpenOnLoad={!subIndex && !mainIndex}
                                isHPPlusCountry={this.props.location.isHPPlusCountry}
                                isFetchingHPPlusCountry={
                                  this.props.location.isFetchingHPPlusCountry
                                }
                              />
                            )}
                          </div>
                        ))}
                    </div>
                  ))}
                </Widescreen>
              )}
            </LibraryContainer>
          </MainContent>
        </ThemeProvider>
      );
    }
    return (
      <div className="contentLoading">
        <LoaderBlock />
      </div>
    );
  }
}

export default bind(SmartHelp);

const StyledHero = styled.div`
  img {
    margin: 0 auto 1rem;
    width: 5rem;
    height: 5rem;
  }
  h1 {
    font-size: 1.125rem;
  }
  p {
    font-size: 1rem;
  }
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: ${(props) => (!props.isMobile ? '1.8125rem 1rem 0.6875rem' : '1rem 1rem 0')};
`;

const StyledButton = styled.button`
  background-color: ${(props) => (props.theme.hpBlue ? props.theme.hpBlue : 'red')};
  border-radius: 0.25rem;
  display: flex;
  margin: auto;
  color: white;
  padding: 0.5rem 1rem;
  align-self: center;
  text-align: center;
  cursor: pointer;
`;