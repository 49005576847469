import React from 'react';
import PropTypes from 'prop-types';
import {
  ValuePropSection,
  ValuePropText,
  ValuePropFreeMonthText,
  ValuePropEnrollContentBox,
  ValuePropTextField
} from './styles/StyleOrigamiValueProp';

const propTypes = {
  valuePropText: PropTypes.string.isRequired,
  valuePropFreeMonthText: PropTypes.string.isRequired,
  addPaperButtonText: PropTypes.string.isRequired,
  enrollLinkText: PropTypes.string.isRequired,
  enrollLinkChevronTitle: PropTypes.string.isRequired,
  enrollLinkChevronUrl: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleEnrollLink: PropTypes.func.isRequired,
  valuePropTextField : PropTypes.string.isRequired
};

const defaultProps = {
  valuePropText: '',
  valuePropFreeMonthText: '',
  addPaperButtonText: '',
  enrollLinkText: '',
  enrollLinkChevronTitle: '',
  enrollLinkChevronUrl: '',
  valuePropTextField: ''
};

const OrigamiValueProp = (props) => {
  const {
    valuePropText,
    valuePropFreeMonthText,
    addPaperButtonText,
    enrollLinkText,
    enrollLinkChevronTitle,
    enrollLinkChevronUrl,
    handleClick,
    handleEnrollLink,
    valuePropTextField
  } = props;

  return (
    <ValuePropSection data-testid="value-prop-section">
      <ValuePropText data-testid="value-prop-text">{valuePropText}</ValuePropText> 
      <ValuePropFreeMonthText data-testid="value-prop-free-month-text">{valuePropFreeMonthText}</ValuePropFreeMonthText> 
      <ValuePropEnrollContentBox
        data-testid="value-prop-content-box"
        addPaperButtonText={addPaperButtonText}
        enrollLinkText={enrollLinkText}
        enrollLinkChevronTitle={enrollLinkChevronTitle}
        enrollLinkChevronUrl={enrollLinkChevronUrl}
        handleClick={handleClick}
        handleEnrollLink={handleEnrollLink}
        className="valuePropEnroll"
        isFlex={true}
      />
      <ValuePropTextField data-testid="value-prop-text-field" dangerouslySetInnerHTML={{ __html: valuePropTextField }}/>
    </ValuePropSection>
  );
};

OrigamiValueProp.propTypes = propTypes;
OrigamiValueProp.defaultProps = defaultProps;
export default OrigamiValueProp;
