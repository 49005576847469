import { values } from 'ramda';
import * as PRINTERIDS from './printerIds';

export const TOU = '/tou';
export const SMB_ADMIN_TOU = '/admin-tou';
export const HOME = '/';
export const GET_SOFTWARE = '/get-software';
export const UNSUPPORTED_OS = '/unsupported-os';

export const PRINTER_ESSENTIALS = values(PRINTERIDS);

export const UCDE_HELP_CENTER = '/ucde/help';

export const CONSENTS_WELCOME = '/welcome';
export const CONSENTS_PRINTER = '/printer-data';
export const NEW_HOME_PAGE = '/super-secret-new-homepage';

export const MY_PRINTERS = '/myprinters';
export const NEW_PRINTER = '/newprinter';
export const PRINTER_CONNECTION_PAGE_PREFIX = '/support/printer-connection';

export const NEW_HOME = '/';
