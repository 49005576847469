import {
    countryVariants,
    languageVariants,
    COUNTRY_DOMINANT_LANGUAGE,
    LANGUAGES as supportedLanguages,
} from '../constants/i18n';


var userUtils = require('./userUtils');
export function getUrlPath() {
    return window.location.pathname;
};


// Here and in services/craft.js getCraftData(): In order to
// get Craft's preview to work, we need to get the preview
// token from the url, and then pass it back to element api.
// Here is an overview: https://nystudio107.com/blog/headless-preview-in-craft-cms
// Code to do it looks like this:
//
// export function getApiPathWithLocale(url, locale = "") {
//     // Get the preview token from the URL
//     let m = document.location.href.match(/\btoken=([^&]+)/);
//     let token = m ? m[1] : '';
//     let currentLocale = locale ? '.' + locale : "";
//     return url + currentLocale + '?token=' + token;
// }
export function getApiPathWithLocale(url, locale = "") {
    let currentLocale = locale ? '.' + locale : "";
    return url + currentLocale;
}
function getJumpId(currentLocale, currentCloudPrinterId = "") {
  let jump = {
      hpc: "?hpc=true",
      id: "&jumpid=in_r11549_",
      // locale: this.props.locales.alt3,
      locale: currentLocale,
      site: "_hpc_ii2_",
      page: "myprinter",
      date: "_020118", // static date -- not dynamically generated
  }
  let jumpid = jump.hpc + jump.id + jump.locale + jump.site + jump.page + jump.date;
  if (currentCloudPrinterId !== "") {
      jump.cpid = "&cpid=" + currentCloudPrinterId;
      jumpid += jump.cpid;
  }
  return jumpid
}
/**
 * Until we have constructs/models to put this logic, we will put it here
 */
export function getInstantInkUrl(iiBaseUrl, printerId, locale) {
    // return iiBaseUrl; 
    return `${iiBaseUrl}/users/signin${getJumpId(locale, printerId)}`; // 6/15
}


export function getInstantInkHomepageUrl(iiBaseUrl, printerId, locale, configStack) {
    const today = new Date()
    const mm = (1 + today.getMonth()).toString().length === 1 ? '0' + (1 + today.getMonth()) : today.getMonth()
    const dd = today.getDate().toString().length === 1 ? '0' + today.getDate() : today.getDate()
    const yy = today.getFullYear() - 2000
    const stamp =  mm + dd + yy
    const retunrValue = `${iiBaseUrl}?jumpid=in_r11549_${locale}_hpc_ii2_homepage_${stamp}`
    return retunrValue
}

export function getRootPathWithLocale(locale) {
    if (!locale.countryCode || !locale.languageCode) {
        return '/';
    }
    return `/${locale.countryCode}/${locale.languageCode}/`;
}

export function getFullPathWithLocale(locale, path) {
    if (path.charAt(0) === '/') {
        path = path.substring(1);
    }
    return getRootPathWithLocale(locale) + path;
}

export function redirectToDefault(locale = '') {
    if (!userUtils.isUserLoggedIn()) {
        location.href = getRootPathWithLocale(locale);
    } else {
        location.href = getFullPathWithLocale(locale, 'myprinters');
    }
}

export function redirectToHome(locale = '') {
    location.href = getRootPathWithLocale(locale);
}
