import ActionTypes from "../constants/ActionTypes";
import fetchStatus from "./fetchStatus";
const deviceDefault = {
    ...fetchStatus(),
    data: {}
};

/**
 * The reducer controls the state of the axios or ajax call.
 * returning the current state with any changes from the
 * actions dispatchers
 *
 *
 * @param state
 * @param action
 * @returns {*}
 */
function device(state = deviceDefault, action) {
    switch (action.type) {
        case ActionTypes.START_DEVICE_FETCH:
            return {...fetchStatus(state, Object.assign({}, action, {type: ActionTypes.START_FETCH}))};

        case ActionTypes.COMPLETE_DEVICE_FETCH:
            return {
                ...fetchStatus(state, Object.assign({}, action, {type: ActionTypes.COMPLETE_FETCH})), 
                data: action.device
            };

        case ActionTypes.COMPLETE_DEVICE_WITH_ERROR:
            return {
                ...fetchStatus(state, Object.assign({}, action, {type: ActionTypes.COMPLETE_FETCH_WITH_ERROR})), 
                errorMsg: action.err
            };
    }

    return state;
}

// usage: this.props.device
export default device;