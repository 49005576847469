import ActionTypes from "../constants/ActionTypes";
import fetchStatus from "./fetchStatus";
import { setCookie } from "../lib/manageCookies";

const gnbDefault = {
    ...fetchStatus(),
    gnbData: null,
    isDesktop: window.innerWidth > 639,
    isSearch: false,
    isLocaleMenu: false,
    isMobileMenu: false,
    isSettings: false,
    isNotificationBanner: true,
    isArrow: false,
    isTablet: window.innerWidth < 1023,

};

/**
 * The reducer controls the state of the axios or ajax call.
 * returning the current state with any changes from the
 * actions dispatchers
 *
 *
 * @param state
 * @param action
 * @returns {*}
 */
function gnb(state = gnbDefault, action) {

    switch (action.type) {
        case ActionTypes.OPEN_LOCALE_DROPDOWN:
            return {
                ...state,
                ...{ isLocaleMenu: true, isSearch: false, isSettings: false, isArrow: state.isDesktop }
            };
        case ActionTypes.CLOSE_LOCALE_DROPDOWN:
            return {
                ...state,
                ...{ isLocaleMenu: false, isArrow: false }
            };


        case ActionTypes.OPEN_SEARCH_DROPDOWN:
            return {
                ...state,
                ...{ isSearch: true, isMobileMenu: false, isLocaleMenu: false, isSettings: false, isArrow: true }
            };
        case ActionTypes.CLOSE_SEARCH_DROPDOWN:
            return {
                ...state,
                ...{ isSearch: false, isArrow: false }
            };
        case ActionTypes.OPEN_SETTINGS_DROPDOWN:
            return {
                ...state,
                ...{ isSettings: true, isMobileMenu: false, isLocaleMenu: false, isSearch: false, isArrow: true }
            };
        case ActionTypes.CLOSE_SETTINGS_DROPDOWN:
            return {
                ...state,
                ...{ isSettings: false, isArrow: false }
            };
        case ActionTypes.DROPDOWNS_OFF:
            return {
                ...state,
                ...{ isSearch: false, isLocaleMenu: false, isMobileMenu: false, isSettings: false, isArrow: false }
            };



        case ActionTypes.OPEN_MOBILE_MENU:
            return {
                ...state,
                ...{ isMobileMenu: true, isSearch: false }
            };
        case ActionTypes.CLOSE_MOBILE_MENU:
            return {
                ...state,
                ...{ isLocaleMenu: false, isMobileMenu: false }
            };


        case ActionTypes.TO_DESKTOP:
            return {
                ...state,
                ...{ isDesktop: true, isSearch: false, isLocaleMenu: false, isMobileMenu: false, isSettings: false }
            };


        case ActionTypes.TO_MOBILE:
            return {
                ...state,
                ...{ isDesktop: false, isSearch: false, isLocaleMenu: false, isMobileMenu: false, isSettings: false }
            };

        case ActionTypes.TO_TABLET:
            return {
                ...state,
                ...{ isTablet: true, isSearch: false, isLocaleMenu: false, isMobileMenu: false, isSettings: false }
            };

        case ActionTypes.TO_NOT_TABLET:
            return {
                ...state,
                ...{ isTablet: false, isSearch: false, isLocaleMenu: false, isMobileMenu: false, isSettings: false }
            };


        case ActionTypes.NOTIFICATIONS_BANNER_ON:
            return {
                ...state,
                ...{ isNotificationBanner: true }
            };

        case ActionTypes.NOTIFICATIONS_BANNER_OFF:
            // IF IN THE FUTURE WE WANT TO POP A NEW NOTIFICATION ALL WE HAVE TO DO IS CHANGE THE VALUE OF THE notificationClosed COOKIE FROM "true" TO SOME OTHER STRING
            // THEN CHANGE THE CONDITION IN components/global/NotificationBanner.js TO THE NEW STRING
            // EXAMPLE: 
            // CHANGE this setCookie TO: setCookie("notificationClosed", "NEXT_VALUE", 60)
            // in NotificationBanner.js CHANGE LINE 17 TO:
            // const notificationClosed = getCookie("notificationClosed") === "NEXT_VALUE"
            setCookie("notificationClosed", "true", 60)
            return {
                ...state,
                ...{ isNotificationBanner: false }
            };
    }

    return state;
}

// usage: this.props.gnb
export default gnb;
