import { isJWebAuthPluginValid, loginUrlLBuilder } from '../utils/auth';

export const forceLogin = async url => {
  if (isJWebAuthPluginValid()) {
    // TODO [SDASH-571]: call the relogin API on the JWeb Auth plugin when that becomes available,
    // to force a relogin and gain the missing critical scopes.
  } else {
    // Web flow:
    window.location.href = loginUrlLBuilder(false, true, url);
  }
};

export const initializePortalInterface = () => {
  window.Portal = {
    forceLogin,
  };
};
