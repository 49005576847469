import {
  IconPrinterEnabled,
  IconPrinterWarning,
  IconPrinterDisabled,
  IconPrinterError,
  IconSecurityEnabled,
  IconSecurityWarning,
  IconSecurityDisabled,
  IconShieldGreen,
  IconShieldGrey,
  IconShieldOrange,
  IconWarningAltOrange,
  IconSecurityUnavailable,
  IconMinusCircleRed,
  IconInformation,
} from './styles';

export const stateReason = {
  none: undefined,
  media_needed: {
    Icon: IconWarningAltOrange,
    title: 'myPrinters.stateReason.mediaNeeded.shortMessage',
    message: 'myPrinters.stateReason.mediaNeeded.longMessage',
  },
  media_jam: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.stateReason.mediaJam.shortMessage',
    message: 'myPrinters.stateReason.mediaJam.longMessage',
  },
  paused: {
    Icon: IconInformation,
    title: 'myPrinters.stateReason.paused.shortMessage',
    message: 'myPrinters.stateReason.paused.longMessage',
  },
  shutdown: {
    Icon: IconWarningAltOrange,
    title: 'myPrinters.stateReason.shutdown.shortMessage',
    message: 'myPrinters.stateReason.shutdown.longMessage',
  },
  toner_low: {
    Icon: IconInformation,
    title: 'myPrinters.stateReason.tonerLow.shortMessage',
    message: 'myPrinters.stateReason.tonerLow.longMessage',
  },
  toner_empty: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.stateReason.tonerEmpty.shortMessage',
    message: 'myPrinters.stateReason.tonerEmpty.longMessage',
  },
  spool_full: {
    Icon: IconInformation,
    title: 'myPrinters.stateReason.spoolFull.shortMessage',
    message: 'myPrinters.stateReason.spoolFull.longMessage',
  },
  door_open: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.stateReason.doorOpen.shortMessage',
    message: 'myPrinters.stateReason.doorOpen.longMessage',
  },
  cover_open: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.stateReason.coverOpen.shortMessage',
    message: 'myPrinters.stateReason.coverOpen.longMessage',
  },
  media_empty: {
    Icon: IconWarningAltOrange,
    title: 'myPrinters.stateReason.mediaEmpty.shortMessage',
    message: 'myPrinters.stateReason.mediaEmpty.longMessage',
  },
  marker_supply_low: {
    Icon: IconInformation,
    title: 'myPrinters.stateReason.markerSupplyLow.shortMessage',
    message: 'myPrinters.stateReason.markerSupplyLow.longMessage',
  },
  marker_supply_empty: {
    Icon: IconWarningAltOrange,
    title: 'myPrinters.stateReason.markerSupplyEmpty.shortMessage',
    message: 'myPrinters.stateReason.markerSupplyEmpty.longMessage',
  },
  media_low: {
    Icon: IconInformation,
    title: 'myPrinters.stateReason.mediaLow.shortMessage',
    message: 'myPrinters.stateReason.mediaLow.longMessage',
  },
};

export const statusHealth = {
  stopped: {
    Icon: IconPrinterDisabled,
    title: 'home.status.item3.state.red',
  },
  needs_attention: {
    Icon: IconPrinterWarning,
    title: 'home.status.item3.state.yellow',
  },
  good: {
    Icon: IconPrinterEnabled,
    title: 'home.status.item3.state.green',
  },
  unknown: {
    Icon: IconWarningAltOrange,
    title: 'home.status.unknown.title',
  },
};

export const connectionState = {
  online: {
    Icon: IconPrinterEnabled,
    title: 'home.myPrintersCard.item1.state.green',
  },
  offline: {
    Icon: IconMinusCircleRed,
    title: 'home.myPrintersCard.item1.state.red',
  },
};

export const statusList = {
  subscribed: {
    Icon: IconPrinterEnabled,
    title: 'myPrinters.subscribed',
  },
  'not-original-inks': {
    Icon: IconInformation,
    title: 'myPrinters.printerDetails.nonHpCartridge.title',
    message: 'myPrinters.printerDetails.nonHpCartridge.message',
    url: 'myPrinters.printerDetails.nonHpCartridge.url',
  },
  idle: {
    Icon: IconPrinterEnabled,
    title: 'Ready to print',
  },
  notProtected: {
    Icon: IconShieldOrange,
    title: 'Needs Attention',
  },
  protected: {
    Icon: IconShieldGreen,
    title: 'Secured',
  },
  securityDisabled: {
    Icon: IconShieldGrey,
    title: 'Security Disabled',
  },
  'printer-ready': {
    Icon: IconPrinterEnabled,
    title: 'Ready to print',
  },
  'printer-low': {
    Icon: IconPrinterWarning,
    title: 'Low on ink',
  },
  'printer-out': {
    Icon: IconPrinterDisabled,
    title: 'Out of ink',
  },
  'security-on': {
    Icon: IconSecurityEnabled,
    title: 'Secured',
  },
  'security-risk': {
    Icon: IconSecurityWarning,
    title: 'Needs attention',
  },
  'security-off': {
    Icon: IconSecurityDisabled,
    title: 'Security disabled',
  },
  'security-unavailable': {
    Icon: IconSecurityUnavailable,
    title: 'Security unavailable',
  },
  'printer-error': {
    Icon: IconPrinterError,
    title: 'Unavailable',
  },
};

const lists = {
  ...stateReason,
  ...statusHealth,
  ...connectionState,
  ...statusList,
};

export const getStatusWithId = id => lists[id && id.toLowerCase()] || statusList['printer-error'];
