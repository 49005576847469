import styled from 'styled-components';
import { breakpoints, smb_breakpoints, theme } from '../../../utils/styleHelpers';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubHeaderContainer = styled.div`
  margin: 10px 20px;
  @media only screen and (min-width: ${breakpoints.desktop}) {
    margin-left: 16%;
    margin-right: 10%;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${(props) => props.desktopHeaderImageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: ${breakpoints.desktop}) {
    background-image: url(${(props) => props.mobileHeaderImageUrl});
  }

  button {
    color: #027aae;
    border: 1px solid #027aae;
    box-sizing: border-box;
    border-radius: 12px;
    font-family: ${theme.fonts.family};
    mix-blend-mode: normal;
    width: 10rem;
    margin-bottom: 50px;

    @media screen and (max-width: ${breakpoints.desktop}) {
      ${(props) => (props.isRTL ? 'right: 8%' : 'left: 8%')};
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      ${(props) => (props.isRTL ? 'right: 16.5%' : 'left: 16.5%')};
    }
  }
`;

export const HeaderText = styled.div`
  display: flex;
  color: #fff;
  @media screen and (min-width: ${breakpoints.xSmall}) {
    width: 90%;
    margin-top: 25%;
    margin-left: 4%;
    margin-right: ${(props) => props.isRTL && '4%'};
    font-size: 28px;
  }
  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 70%;
    margin-top: 12%;
    margin-left: 14%;
    margin-right: ${(props) => props.isRTL && '14%'};
    font-size: 28px;
  }
  @media screen and (min-width: ${smb_breakpoints.desktop}) {
    width: 70%;
    margin-top: 12%;
    margin-left: 15%;
    margin-right: ${(props) => props.isRTL && '15%'};
    font-size: 2rem;
  }
  @media screen and (min-width: 1024px) {
    width: 60%;
    margin-top: 8%;
    margin-left: 15%;
    margin-right: ${(props) => props.isRTL && '15%'};
    font-size: 2.8rem;
  }
`;

export const Text = styled.p`
  line-height: 1;
`;

export const DownloadHeaderText = styled.div`
  color: #fff;

  @media screen and (min-width: ${breakpoints.xSmall}) {
    width: 90%;
    margin-top: 22%;
    margin-left: 5%;
    font-size: 30px;
    padding: 5px 0;
    margin-bottom: 3%;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 70%;
    margin-top: 18%;
    margin-left: 16%;
    font-size: 30px;
    padding: 5px 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 12%;
    padding: 15px 0;
    margin-bottom: 3%;
  }

  @media screen and (min-width: ${breakpoints.wide}) {
    font-size: 2.8rem;
    margin-top: 7%;
    margin-bottom: 3%;
    padding: 0;
  }
`;

export const SubHeaderText = styled.h3`
  font-weight: 600;
  color: #000;
  margin-top: 1rem;
`;

export const AppPromoText = styled.div`
  font-size: 20px;
  line-height: 34.22px;
  color: #000;
  padding: 10px 0;
`;

export const InstallSteps = styled.ol`
  display: flex;
  width: 38%;
  color: #fff;
  flex-direction: column;
  font-size: 1.5rem;
  @media screen and (min-width: ${breakpoints.xSmall}) {
    font-size: 21px;
    width: 80%;
    margin-left: 10%;
    margin-right: ${(props) => props.isRTL && '8%'};
    margin-top: 2%;
  }
  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 50%;
    margin-left: 16.5%;
    margin-right: ${(props) => props.isRTL && '16.5%'};
  }
`;

export const InstallStep = styled.li``;

export const AppStoreContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  @media only screen and (min-width: ${breakpoints.xSmall}) {
    margin: 10px 20px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    margin: 10px 20px;
    margin-left: 16%;
    margin-right: ${(props) => (props.isRTL ? '16%' : '10%')};
  }
`;

export const AppStoreText = styled.p`
  display: flex;
  color: #000;
  margin-bottom: 0;
`;

export const AppStoreBadgesContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  div {
    margin: 0;
  }
  @media only screen and (max-width: ${breakpoints.desktop}) {
    padding: 20px 0;
  }
`;

export const QRCodeImage = styled.img`
  width: 100px;
  height: 100px;
  border: 1px solid #adadad;
  padding: 10px;
`;

export const QRCodeText = styled.span`
  font-size: 1.125rem;
  margin-left: 30px;
  align-self: center;
  width: 18.375rem;
  height: 6.25rem;
  color: #000;
  line-height: 21px;
  @media only screen and (max-width: ${breakpoints.smalldesktop}) {
    font-size: 1rem;
    margin-left: 20px;
    margin-right: ${(props) => props.isRTL && '20px'};
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    margin-right: ${(props) => props.isRTL && '20px'};
  }
`;

export const SmartAppHelpText = styled.div`
  color: #000;
  width: 90%;
  > h3 {
    font-weight: 600;
  }
  > p:first-child {
    font-size: 22px;
    font-weight: 600;
  }
  > p:last-child {
    margin-bottom: 0;
    font-size: 14px;
  }
  > ul {
    margin-left: 1.5rem;
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    padding: 20px 0 0;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    border: 2px solid #027aae;
    border-radius: 10px;
    margin-top: 40px;
    width: 85%;
    padding: 20px;
    padding-right: ${(props) => props.isRTL && '30px'};
  }
`;

export const StraightLine = styled.div`
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #027aae;
  margin-top: 4%;
  width: 100%;
  @media only screen and (min-width: ${breakpoints.xSmall}) {
    margin-top: 20px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    margin-top: 62.5px;
  }
`;

export const SupportIconImg = styled.img`
  width: 46px;
  height: 45px;
  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin-top: -5%;
  }
`;

export const SupportText = styled.span`
  font-size: 20px;
  margin-left: 15px;
  margin-top: 2%;
  margin-right: ${(props) => props.isRTL && '2%'};
`;
