import React from 'react';
import {
  IconChevronRight,
} from '@veneer/core';
import {
  SubtopicContainer,
  Nav,
  StyledLink,
  SubtopicTitle,
  SubtopicContent,
} from './styles';

const HelpCenterSubtopic = ({ title, content }) => {
  const {
    text,
    to,
    destination,
  } = title;
  return (
    <SubtopicContainer content={Boolean(content)}>
      {
        to
          ? <StyledLink to={to}>{text}</StyledLink>
          : (
            <React.Fragment>
              <SubtopicTitle content={Boolean(content)}>
                {text}
              </SubtopicTitle>
              {
                destination && (
                  <Nav to={destination}>
                    <IconChevronRight />
                  </Nav>
                )
              }
            </React.Fragment>
          )
      }
      {
        content && (
          content.match(/<[a-z]+>/)
            ? <SubtopicContent dangerouslySetInnerHTML={{ __html: content }} />
            : <SubtopicContent>{content}</SubtopicContent>
        )
      }
    </SubtopicContainer>
  );
};

export default HelpCenterSubtopic;
