import React, { Component } from 'react';
import { prop, pathOr, path, find, either, propEq, pathEq } from 'ramda';
import BreadCrumbs from '../shared-components/atoms/BreadCrumbs';
import HollowButton from '../shared-components/atoms/hollowButton';
import AddPrinterButton from '../my-printers/AddPrinterButton';
import EprintMainInfo from './eprint-main-info/EprintMainInfo';
import RemotePrinting from './RemotePrinting';
import PrintAnywhereWeb from '../print-anywhere/PrintAnywhereWeb';
import EprintInk from './eprint-ink/EprintInk';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';
import { getCookie, setOnboardingCookie } from '../../lib/manageCookies';
import { getHistory } from '../../index';
import { redirectToDefault } from '../../lib/urlUtils';
import { refreshToTop } from '../../lib/refreshToTop';
import AreaTypes from '../../constants/AreaTypes';
import bind from '../bind';
import OnboardingModal from '../shared-components/organisms/OnboardingModal';
import EprintAddressSettings from './EprintAddressSettings';
import { MYPRINTER_CONSTANT } from '../../constants/craftResources';
import { USER_TYPE, HPC3_SESSION_TYPE } from '../../constants/cookieNames';
import checkEntitlement from '../../utils/entitlement';
import { GLOBAL_NAV_BAR_RESOURCE_NAME } from '../../constants/contentStackResources';
import { getLocaleForTranslation } from '../../utils/routing';

const safeGetNestedProperty = (propName, object) =>
  pathOr(prop(propName)(object), ['WPPData', propName])(object);

class MyPrinter extends Component {
  constructor(props) {
    super(props);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.RemoveModal = null;
    this.handleRemoveDialogCancel = this.handleRemoveDialogCancel.bind(this);
    this.handleRemoveDialogConfirm = this.handleRemoveDialogConfirm.bind(this);
    this.generateDirections = this.generateDirections.bind(this);
    this.numberOfPrinters = null; // use to show or hide breadcrumb :  {this.numberOfPrinters > 1 && breadcrumb}
    this.initializeWhitelistWithUser = this.initializeWhitelistWithUser.bind(this);
    this.checkWhitelist = this.checkWhitelist.bind(this);
    this.showOnboarding = this.showOnboarding.bind(this);
    this.onboarding = this.onboarding.bind(this);
    this.trackingHandler = this.trackingHandler.bind(this);
    this.closeOnboardingModal = this.closeOnboardingModal.bind(this);
    this.cancelOnboardingModal = this.cancelOnboardingModal.bind(this);
    this.counter = 0;
    this.doOnce = 0;
    this.showOnboardingModal = false;
    this.onboardingTriggerAnimateCancel = false;
    this.showRemoveModal = false;
    this.printerChangedState = 'Default';
    this.state = { fetchingUserData: false };
  }

  componentDidMount() {
    const { userInfo, getUser } = this.props;
    const { fetchingUserData } = this.state;
    if (!userInfo?.userInfoData && !fetchingUserData && getCookie(HPC3_SESSION_TYPE) === 'user') {
      this.setState({ fetchingUserData: true });
      getUser(() => this.setState({ fetchingUserData: false }));
    }
  }

  changePrinterState = (state) => {
    this.printerChangedState = state;
    const match = this.props?.match;
    const matchId = path(['params', 'id'], match);
    const { currentLocale, alt4 } = this.props?.locales || {};
    const locale = getLocaleForTranslation({ currentLocale, match, alt4 }).toUpperCase();
    this.props.getMyPrinter(matchId, locale, path(['location', 'query', 'generation'], this.props.router));
  }

  componentDidUpdate() {
    const printer = !!this.props.myPrinter.data.WPPData;
    const user = !!this.props.userInfo.userInfoData;
    if (printer && user && !this.props.config.data.Get('ENABLE_DEMO')) {
      this.onboarding();
      this.checkWhitelist();
    }
  }

  componentWillMount() {
    const {
      match,
      myPrinter: { data: myPrinterData },
      myPrinters,
      getMyPrinter,
      getPrinterCommon,
      push,
      updateMyPrinterWithAlreadyFetchedData,
      router,
      locales,
    } = this.props;
    const matchId = path(['params', 'id'], match);
    const myPrinterOwnershipId = safeGetNestedProperty('ownershipId', myPrinterData);
    const myPrintersList = pathOr([], ['data', 'WPPData'], myPrinters);
    const country = path(['params', 'country'], match);
    const language = path(['params', 'language'], match);
    const locale = getLocaleForTranslation({ currentLocale: locales?.currentLocale, match, alt4: locales?.alt4 }).toUpperCase();
    refreshToTop();
    const loggedInCookie = getCookie('hpc3-session-type');
    const isLoggedIn = loggedInCookie === 'user';
    this.loggedOut = !isLoggedIn;
    if (!this.loggedOut && matchId) {
      if (!myPrinterOwnershipId) {
        getMyPrinter(matchId, locale, path(['location', 'query', 'generation'], router));
        getPrinterCommon(locale.replace('_', '-').toLowerCase());
        return;
      }
      if (matchId !== myPrinterOwnershipId) {
        const foundPrinter = find(
          either(propEq('ownershipId', matchId), pathEq(['WPPData', 'ownershipId'], matchId)),
          myPrintersList
        );
        if (foundPrinter && foundPrinter.detailsData) {
          updateMyPrinterWithAlreadyFetchedData(foundPrinter.detailsData);
          return;
        }
        getMyPrinter(matchId, locale, path(['location', 'query', 'generation'], router));
        getPrinterCommon(locale.replace('_', '-').toLowerCase());
      }
    } else {
      push(`/${country}/${language}/myprinters`);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.myPrinter !== this.props.myPrinter) {
      if (nextProps.myPrinter.isComplete) {
        if (nextProps.myPrinter.data !== undefined) {
          if (nextProps.myPrinter.data.WPPData !== undefined) {
            if (nextProps.myPrinter.data.WPPData.printerModelNumber !== undefined) {
              const printerInfoObj = {
                mod: nextProps.myPrinter.data.WPPData.printerModelNumber,
                name: nextProps.myPrinter.data.WPPData.printerName
              };
              this.props.changeUserPrinterModel(printerInfoObj);
            }
          }
        }
      }
    }
  }

  onboarding() {
    if (
      this.props.myPrinter.data.WPPData.printerModelNumber !== '' &&
      this.props.myPrinter.data.WPPData.printerSerialNumber !== ''
    ) {
      const printer = this.props.myPrinter.data.WPPData
        ? this.props.myPrinter.data.WPPData
        : { printerId: '' };
      const onboardingFn = this.showOnboarding;
      setOnboardingCookie(printer.printerId, onboardingFn);
    }
  }

  cancelOnboardingModal() {
    // set variable to trigger animation(off) in onboarding modal
    this.onboardingTriggerAnimateCancel = true;
    this.forceUpdate();
  }

  closeOnboardingModal() {
    this.trackingHandler('onboarding-modal-close');
    this.showOnboardingModal = false;
    this.forceUpdate();
  }

  closeRemovePrinterModal = () => {
    this.trackingHandler('remove-printer-modal-cancel');
    this.showRemoveModal = false;
    this.forceUpdate();
  };

  showOnboarding() {
    const debounce = (func, delay) => {
      let inDebounce;
      return (function () {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), delay);
      })();
    };
    const showOnbordingNow = () => {
      this.doOnce++;
      if (this.doOnce < 2) {
        if (this.props.gnb.isTablet) {
          const clickOpen_onComplete = () => {
            document.getElementById('eprint-email').click();
            this.trackingHandler('onboarding-modal-open');
          };
          const oTween = TweenLite.to(window, 1.3, {
            scrollTo: '#ePrintSettings',
            onComplete: clickOpen_onComplete,
            ease: Power2.easeOut
          });
        } else {
          this.trackingHandler('onboarding-modal-open');
          this.showOnboardingModal = true;
          this.forceUpdate();
        }
      }
    };
    debounce(showOnbordingNow, 1300);
  }

  checkWhitelist() {
    const printer = this.props.myPrinter.data.WPPData;
    const user = this.props.userInfo.userInfoData;
    if (printer.whitelist) {
      const printer_whitelist = Array.isArray(printer.whitelist) ? printer.whitelist.slice(0) : [];
      const isGen1 = printer.generation == 1;
      let obji = null;
      if (!Array.isArray(printer_whitelist)) {
        // do nothing
      } else if (printer_whitelist.length > 0) {
        if (isGen1) {
          obji = printer_whitelist.findIndex((obj) => obj.emailAddress == user.email);
        } else {
          obji = printer_whitelist.findIndex((obj) => obj.emailAddress == user.email);
        }
        if (obji === -1) {
          if (printer.printerIsProtected) {
            this.initializeWhitelistWithUser(printer.printerId, user.email);
          }
        }
      } else if (printer.printerIsProtected) {
        this.initializeWhitelistWithUser(printer.printerId, user.email);
      }
    }
  }

  initializeWhitelistWithUser(printerId, userEmail) {
    // this function needs to be fixed
    this.counter++;
    if (this.counter == 1) {
      this.props.addMyPrinterAccessAllowedEmails(printerId, userEmail);
    } else if (this.counter == 2) {
      // DO NOTHING
    } else if (this.counter == 3) {
      this.props.addMyPrinterAccessAllowedEmails(printerId, userEmail);
    } else {
      // DO NOTHING
    }
  }

  trackingHandler(ID) {
    if (document.getElementById(ID) !== null) {
      document.getElementById(ID).click();
    }
  }

  handleRemoveClick() {
    // show modal
    this.showRemoveModal = true;
    this.forceUpdate();
  }

  handleRemoveDialogCancel() {
    this.trackingHandler('remove-printer-modal-cancel');
    this.showRemoveModal = false;
    this.forceUpdate();
  }

  handleRemoveDialogConfirm() {
    this.props.myPrintersAltered();
    this.trackingHandler('remove-printer-modal-remove');
    this.showRemoveModal = false;
    this.forceUpdate();
    const redirectUrl = '/myprinters';
    this.props.unclaimPrinter(this.props.myPrinter.data.WPPData.ownershipId, () => {
      const history = getHistory();
      history.push(redirectUrl);
    });
  }

  isEligibleToRemove() {
    if (!this.props.myPrinter.data.WPPData) {
      return false;
    }
    return !this.props.myPrinter.data.WPPData.isInstantInkSubscribed;
  }

  // Used for unregistered printers
  generateDirections() {
    const {
      craft,
      myPrinter: {
        data: {
          csData,
          unregisteredData
        },
      },
    } = this.props;
    if (csData) {
      const directions = unregisteredData.unregistered_printer_directions;
      return directions.length > 0 ?
        directions.map((directionItem, i) => {
          const { unregistered_printer_directions_text_block } = directionItem
          return (
            <div
              key={i}
              className="myPrinterDetails--directionBlock"
              dangerouslySetInnerHTML={{ __html: unregistered_printer_directions_text_block.unregistered_directions_text }}
            />
          );
        }) : (
          <div key={i} className="myPrinterDetails--directionBlock">
            <div className="myPrinterDetails--directionIcon">
              <img
                src={unregisteredData.unregistered_printer_graphic.url}
                alt={unregisteredData.unregistered_printer_graphic.title}
              />
            </div>
          </div>
        )
    }
  }

  getLoadingBlock() {
    return (
      <div className="contentLoading">
        <LoaderBlock />
      </div>
    );
  }

  render() {
    const { config, craft } = this.props;
    const hideAddPrinter = path(['application', 'jweb', 'isNative'], this.props);
    if (config != null && !config.isComplete) {
      return this.getLoadingBlock();
    }
    const isBaseUser = getCookie(USER_TYPE).toLowerCase() === 'base';
    const isUCDEUser = getCookie(USER_TYPE).toLowerCase() === 'ucde';
    const printAnywhereWebIsEnabled = config.data._configs.FEATURE_FLAGS.ff_printAnywhereWeb_enabled
      ? this.props.config.data.GetFeatureFlag('ff_printAnywhereWeb_enabled').toLowerCase() ===
      'true' && isBaseUser
      : false;
    const printAnywhereWebIsEnabledForUCDEUser = config.data._configs.FEATURE_FLAGS
      .ff_printAnywhereWebForUCDE_enabled
      ? this.props.config.data
        .GetFeatureFlag('ff_printAnywhereWebForUCDE_enabled')
        .toLowerCase() === 'true'
      : false;
    if (
      this.props.myPrinter != null &&
      this.props.myPrinter.isError &&
      this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_PAGE
    ) {
      redirectToDefault(this.props.locales);
      return null;
    }
    const myPrinter = this.props.myPrinter.data.WPPData;
    const removalData = this.props.myPrinter.data.removalData;
    const printerCommon = this.props.printerCommon.data;
    const { locales } = this.props;
    const myprintersPath = '/myprinters';
    const newprinterPath = '/newprinter';
    const gnbData = this.props.contentStack[GLOBAL_NAV_BAR_RESOURCE_NAME];
    const isGen2 = !!myPrinter && myPrinter.generation == '2';
    if (!myPrinter || !removalData || !printerCommon || !locales || !gnbData || this.state.fetchingUserData) {
      return this.getLoadingBlock();
    }
    const myPrintersTxt = path(['data', 'entries', 0, 'my_printers_link', 0, 'printer_block', 'my_printers_links_text'], gnbData);
    const obj =
      !!this.props.myPrinters.data &&
        !!this.props.myPrinters.data.WPPData &&
        this.props.myPrinters.data.WPPData.length < 1
        ? { section: myPrintersTxt }
        : { section: myPrintersTxt, sectionLink: myprintersPath };
    // TODO: Figure out a way to make this link smart without maybe pulling up all printers at once
    const removeModalDescription = this.isEligibleToRemove()
      ? removalData.remove_printer_modal_description
      : removalData.remove_printer_modal_subscribed_description;
    const isPendingFn =
      this.props.myPrinter.isFetching &&
      (this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_PAGE ||
        this.props.myPrinter.currentArea === null);
    let ePrintSupport = true;
    let isConnectAnywhereEntitlement = false;
    let isPotgEntitlement = false;
    let isEPrintShow = true;

    if (!isBaseUser && myPrinter.generation === 2 && myPrinter.programInfo) {
      const { entitlementList } = myPrinter.programInfo;
      ePrintSupport = checkEntitlement(entitlementList, 'ws-hp.com/eprint');
      isConnectAnywhereEntitlement = checkEntitlement(entitlementList, 'ws-hp.com/connectanywhere');
      isPotgEntitlement = checkEntitlement(entitlementList, 'ws-hp.com/potg');
      isEPrintShow = entitlementList ? true : false;
    }

    const getPrinterContent = (isPending) => {
      if (isPending && this.printerChangedState === 'Default') {
        return this.getLoadingBlock();
      }

      return (
        <div className="myPrinterDetails">
          <EprintMainInfo
            ePrintSupport={ePrintSupport}
            handleRemoveClick={this.handleRemoveClick}
          />
          <EprintInk printAnywhereWebIsEnabled={printAnywhereWebIsEnabled && isGen2} />
          {(printAnywhereWebIsEnabled && isGen2) ||
            (isUCDEUser &&
              isConnectAnywhereEntitlement &&
              !isPotgEntitlement &&
              printAnywhereWebIsEnabledForUCDEUser) ? (
            <PrintAnywhereWeb ePrintSupport={ePrintSupport} />
          ) : (
            <RemotePrinting ePrintSupport={ePrintSupport || isEPrintShow} changePrinterState={this.changePrinterState} />
          )}
          <a name="tracking-hook" id="remove-printer-modal-cancel" />
          <a name="tracking-hook" id="remove-printer-modal-remove" />
          <a name="tracking-hook" id="onboarding-modal-open" />
          <a name="tracking-hook" id="onboarding-modal-close" />
          <a name="tracking-hook" id="onboarding-modal-submit" />
          {this.showRemoveModal && (
            <OnboardingModal
              ID="remove-printer"
              closeHandler={this.closeRemovePrinterModal}
              cancelOnboarding={this.onboardingTriggerAnimateCancel}
            >
              <div className="modalWindow--container">
                <h3 className="modalWindow--title">{removalData.remove_printer_modal_title}</h3>
                {this.isEligibleToRemove() && <p>{removalData.remove_printer_modal_message}</p>}
                <h4>{myPrinter.printerName}</h4>
                <p
                  className="remove-printer-description"
                  dangerouslySetInnerHTML={{ __html: removeModalDescription }}
                />
                {this.isEligibleToRemove() && (
                  <div className="modalWindow--buttonPair">
                    <HollowButton
                      buttonID="remove-printer-modal-cancel-btn"
                      buttonText={removalData.remove_printer_modal_cancel_text}
                      onClick={this.handleRemoveDialogCancel}
                    />
                    <HollowButton
                      buttonID="remove-printer-modal-remove-btn"
                      buttonText={removalData.remove_printer_modal_remove_text}
                      onClick={this.handleRemoveDialogConfirm}
                    />
                  </div>
                )}
              </div>
            </OnboardingModal>
          )}
        </div>
      );
    };

    if (this.loggedOut) {
      this.props.history.push(myprintersPath);
    }

    return (
      <div>
        <div className="myPrinters--meta">
          <BreadCrumbs ID="breadcrumb-printer" {...obj} />
          {!hideAddPrinter && (
            <AddPrinterButton
              buttonID="AddPrinter-btn"
              addPrinterSubmitLink={newprinterPath}
              addPrinterButtonText={printerCommon.add_printer_text}
              addPrinterIcon="/assets/img/add_printer_icon.svg"
              addPrinterIconTitle=""
            />
          )}
        </div>
        {getPrinterContent(isPendingFn)}
        {this.showOnboardingModal && ePrintSupport && (
          <OnboardingModal
            ID="onboarding"
            closeHandler={this.closeOnboardingModal}
            cancelOnboarding={this.onboardingTriggerAnimateCancel}
          >
            {/* <EprintAddressSettings ID="onboarding_modal" loading={isPendingFn} trackingHook={this.trackingHandler} printer={myPrinter} handleToggle={this.cancelOnboardingModal} inModal={true} /> */}
            <EprintAddressSettings
              ePrintSupport={ePrintSupport}
              ID="onboarding_modal"
              loading={isPendingFn}
              trackingHandler={this.trackingHandler}
              printer={myPrinter}
              handleToggle={this.cancelOnboardingModal}
              inModal
            />
          </OnboardingModal>
        )}
        <div id="carepack-footnotes" className="footnote" style={{ margin: '1.25rem' }} />
      </div>
    );
  }
}
export default bind(MyPrinter);
