import styled from 'styled-components';
import { breakpoints, theme, venBreaks } from '../../../utils/styleHelpers';

export const HeaderContainer = styled.div`
  background-color: #027aae;
  height: auto;
  margin-top: 70px;

  @media (min-width: ${breakpoints.bigtablet}) {
    height: 200px;
  }
`;

export const HeaderText = styled.h1`
  color: #ffffff;
  margin: 20px auto 20px auto;
  font-family: ${theme.fonts.familyLight};
  font-style: normal;
  font-size: 40px;
  font-weight: 300;
  height: auto;
  line-height: 48px;
  text-align: center;
  width: 311px;

  @media (min-width: ${breakpoints.bigtablet}) {
    height: auto;
    margin: 45px auto 0 auto;
    width: auto;
  }

  @media (min-width: ${venBreaks.desktop}) {
    font-size: 44px;
  }
`;
