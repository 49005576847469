import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  text-align: center;
`;

export const Title = styled.text`
  visibility: hidden;
  height: 48px;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  line-height: 24px;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    color: #666;
    visibility: visible;
    font-size: max(min(4.35vw,16px), 12px);
  }
`;
