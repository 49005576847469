import Types from './types';
import { makeActionCreator } from '../../../../../utils/redux';
import { makeApiCall } from '../../../../../actions/application';
import { getUserPortalElementsData } from '../../../api/UCDEGateway';

export const getUcdePortalElementsRequest = makeActionCreator(
  Types.UCDE_PORTAL_ELEMENTS_REQUEST,
  'payload',
);

const getUcdePortalElementsSuccess = makeActionCreator(
  Types.UCDE_PORTAL_ELEMENTS_SUCCESS,
  'payload',
);

const getUcdePortalElementsFailed = makeActionCreator(
  Types.UCDE_PORTAL_ELEMENTS_FAILED,
  'payload',
);

export const getUcdeUserPortalElements = makeApiCall({
  asyncFn: getUserPortalElementsData,
  successActionCreator: getUcdePortalElementsSuccess,
  failureActionCreator: getUcdePortalElementsFailed,
});

const Actions = {
  getUcdeUserPortalElements,
  getUcdePortalElementsRequest,
  getUcdePortalElementsSuccess,
  getUcdePortalElementsFailed,
};

export default Actions;
