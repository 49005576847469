import ActionTypes from "../constants/ActionTypes";

const notificationsDefault = {
    isComplete: false,
    isError: false,
    errorMsg: "",
    notificationData: []
};

/**
 * The reducer controls the state of the axios or ajax call.
 * returning the current state with any changes from the
 * actions dispatchers
 *
 *
 * @param state
 * @param action
 * @returns {*}
 */
function notifications(state = notificationsDefault, action) {

    switch (action.type) {
        case ActionTypes.START_NOTIFICATIONS_FETCH:
            return {...notificationsDefault};

        case ActionTypes.COMPLETE_NOTIFICATIONS_FETCH:
            return {...notificationsDefault, isComplete:true, notificationData: action.notificationData};

        case ActionTypes.COMPLETE_NOTIFICATIONS_WITH_ERROR:
            return {...notificationsDefault, isError: true, errorMsg: action.err.message};
    }

    return state;
}

// usage: this.props.home
export default notifications;
