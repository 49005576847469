import React from 'react';
import { prop } from 'ramda';
import { useSelector } from '../../hooks';
import { selectContentStackDataByResource } from '../../selectors/craft';
import {
  StyledContainer,
  StyledContentSMBAdmin,
  StyledTitle,
  StyledDate,
  StyledIndex,
  StyledSectionsSMBAdminTOU
} from './Terms.styles.js';
import NavBar from '../revised/Global/Navbar';
import Footer from '../revised/Global/Footer';
import { SMB_ADMIN_TOU_RESOURCE_NAME } from '../../constants/contentStackResources';

const isAdminPage = true;

const slugify = (str = '') =>
  str
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');

const SMBAdminTou = (props) => {
  const { terms_of_use } = useSelector(selectContentStackDataByResource, [SMB_ADMIN_TOU_RESOURCE_NAME]);
  const smbAdminTou = terms_of_use?.data?.entry;

  if (smbAdminTou && !smbAdminTou.isError) {
    const { indexed_sections, title, last_updated, intro_section, index_title, copyright } = smbAdminTou;

    return (
      <div data-testid="smb-admin-tou">
      <NavBar minimal isAdminPage={isAdminPage} />
      <StyledContainer>
        <StyledContentSMBAdmin>
          <StyledTitle>{title}</StyledTitle>
          <StyledDate>{last_updated}</StyledDate>
          <div dangerouslySetInnerHTML={{ __html: intro_section }} />
          <strong>{index_title}</strong>
          <StyledIndex>
              {indexed_sections.map((section) => {
                const { section_block } = section;
                const { section_title, _metadata } = section_block;
                const { uid } = _metadata;

                return (
                  <li key={`title${slugify(uid)}`}>
                    <a href={`#${slugify(section_title)}`}>{section_title}</a>
                  </li>
                );
              })}
          </StyledIndex>
          <StyledSectionsSMBAdminTOU>
            {indexed_sections.map((section, i) => {
              const { section_block } = section;
              const { section_title, section_content, _metadata } = section_block;
              const { uid } = _metadata;

              return (
                <li
                  key={`content${slugify(uid)}`}
                  id={slugify(section_title)}
                  dangerouslySetInnerHTML={{ __html: `<em>${section_title}${i === 0 ? '<br/>' : '' }</em> ${section_content}`  }}
                />
              );
            })}
          </StyledSectionsSMBAdminTOU>
          <strong>{copyright}</strong>
        </StyledContentSMBAdmin>
      </StyledContainer>
      <Footer minimal isAdminPage={isAdminPage} />
      </div>
    );
  }

  if (prop('isError', props.terms)) {
    return <p>{prop('errorMsg', props.terms)}</p>;
  } // Case: We're loading content
  return <div />;
};

export default SMBAdminTou;
