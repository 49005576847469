import axios from "axios";
import { getApiPathWithLocale } from "../lib/urlUtils";
import ActionTypes from "../constants/ActionTypes";

/**
 * This function uses axios to get printer list.
 *
 * Since axios returns promises, we can dispatch our start event and
 * update it to complete or complete with error whe the promise returns.
 *
 * @returns {function(*)}
 */
export function getTermsOfUse(currentLocale = "") {
    let start = () => ({
        type: ActionTypes.START_TERMSOFUSE_FETCH
    }),
        complete = (termsOfUseData) => ({
            type: ActionTypes.COMPLETE_TERMSOFUSE_FETCH,
            termsOfUseData
        }),
        error = (err) => ({
            type: ActionTypes.COMPLETE_TERMSOFUSE_FETCH_WITH_ERROR,
            err
        });
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(start());
            axios.get(getApiPathWithLocale("/api/terms-of-use.json", currentLocale)
                // , { timeout: 30000 }
            )
                .then((res) => {
                    let termsOfUseData = res.data.data[0];
                    dispatch(complete(termsOfUseData));
                    return resolve(termsOfUseData);
                })
                .catch((err) => {
                    dispatch(error(err));
                    reject(err);
                });
        });
    }
}
