import React from 'react';
import styled from 'styled-components';

const StyledSubheader = styled.header`
  text-align: center;
  h3 {
    font-size: 1.75rem;
    @media screen and (min-width: 40rem) {
      font-size: 2.25rem;
    }
    @media screen and (min-width: 64rem) {
      font-size: 2.75rem;
    }
  }
  padding-bottom: 1.25rem;
  @media screen and (min-width: 40rem) {
    padding-bottom: 1.5rem;
  }
`;
export const subSectionHeadCss = 
`text-align: center;
h3 {
  font-size: 1.75rem;
  @media screen and (min-width: 40rem) {
    font-size: 2.25rem;
  }
  @media screen and (min-width: 64rem) {
    font-size: 2.75rem;
  }
}
padding-bottom: 1.25rem;
@media screen and (min-width: 40rem) {
  padding-bottom: 1.5rem;
}
`

const SubSectionHead = (props) => (
  <StyledSubheader>
    <h3>{props.text ? props.text : "Subsection Head"}</h3>
  </StyledSubheader>
);

export default SubSectionHead;