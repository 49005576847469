/* eslint-disable */
// TODO: disable eslint for the default export rule. reenable when more methods are put in this file
import { gateway } from '../utils/request';

export const putPrintAnywhereMode = ({ printerId, newMode }) => {
  return gateway({
    method: 'put',
    url: `/print-anywhere/printers/${printerId}/share`,
    data: {
      mode: newMode,
    },
  });
};
