import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../../../../routing';
import i18n from '../../../../utils/i18n';
import {
  Container,
  Content,
  LeftSection,
} from './styles';

const statusService = (status, link) => {
  if (link) {
    if ((/^https?:\/\/|^\/\//i).test(link)) {
      return <a href={link}>{i18n.t(status)}</a>;
    }
    return <Link to={link}>{i18n.t(status)}</Link>;
  }
  return <span>{i18n.t(status)}</span>;
};

const ServiceItem = ({
  name, status, link,
}) => (
  <Container>
    <Content>
      <LeftSection>
        <span>{i18n.t(name)}</span>
        {statusService(status, link)}
      </LeftSection>
    </Content>
  </Container>
);

ServiceItem.defaultProps = {
  link: '',
};

ServiceItem.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  link: PropTypes.string,
};

export default ServiceItem;
