import styled from 'styled-components';
import { stylesIf } from '../../../../utils/styleHelpers';
import { UCDEMainTheme } from '../../pages/Dashboard/styles';
import { CardContainer } from '../shared-components/styles';

export const ContentStatus = styled.div`
  > * {
    &:not(:last-child)  {
      padding-bottom: 32px;
    }
  }
`;

export const ContentUsage = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${UCDEMainTheme.breakPoints.md}) {
    border-left: 1px solid #ebebeb;
    padding-left: 16px;
  }

  @media (max-width: ${UCDEMainTheme.breakPoints.md}) {
    border-top: 1px solid #ebebeb;
    padding-top: 32px;
    margin-top: 20px;
    padding-bottom: 12px;
  }
`;

export const Container = styled(CardContainer)`
  min-height: 210px;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  display: grid;
  padding-top: 32px;

  @media (min-width: ${UCDEMainTheme.breakPoints.md}) {
    grid-template-columns: ${stylesIf('gridMode', '60% 40%', '100%')};

    ${ContentStatus} {
      ${stylesIf('gridMode', 'padding-right: 16px;')}
    }
  }

  @media (max-width: ${UCDEMainTheme.breakPoints.md}) {
    flex-direction: column;

    ${ContentStatus} {
      padding-bottom: 12px;
    }
  }
`;

export const Refresh = styled.button`
  background: transparent;
  color:  ${UCDEMainTheme.main.hp.color};
  font-family: ${UCDEMainTheme.main.hp.fontFamily};
  border: 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const LoaderContent = styled.div`
  flex: 1;
  display: flex;
  
  div {
    margin-top: 0;
  }  
`;
