import React from 'react';
import NukaCarousel from 'nuka-carousel';
import PropTypes from 'prop-types';
import {
  ArrowIcon,
  ArrowNext,
  ArrowPrev,
} from './styles';

function hasManyChildren(children) {
  return React.Children.count(children) > 1;
}

export const Carousel = ({ children, swiping }) => (
  <NukaCarousel
    dragging={false}
    swiping={swiping}
    wrapAround={hasManyChildren(children)}
    renderCenterLeftControls={({ previousSlide }) => (
      hasManyChildren(children) ? (
        <ArrowPrev onClick={previousSlide}>
          <ArrowIcon />
        </ArrowPrev>
      ) : null
    )}
    renderCenterRightControls={({ nextSlide }) => (
      hasManyChildren(children) ? (
        <ArrowNext onClick={nextSlide}>
          <ArrowIcon />
        </ArrowNext>
      ) : null
    )}
    renderBottomCenterControls={() => (<div />)}
  >
    {children}
  </NukaCarousel>
);

Carousel.defaultProps = {
  swiping: true,
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  swiping: PropTypes.bool,
};

export default Carousel;
