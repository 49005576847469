import {
  pathOr,
  propEq,
  propOr,
  compose,
  find,
  prop,
  either,
  pathEq,
} from 'ramda';
import ActionTypes from '../constants/ActionTypes';
import fetchStatus from './fetchStatus';

const myPrintersDefault = {
  ...fetchStatus(),
  data: null,
};

/**
 * The reducer controls the state of the axios or ajax call.
 * returning the current state with any changes from the
 * actions dispatchers
 *
 *
 * @param state
 * @param action
 * @returns {*}
 */

function nestPrinterDataForSomeReason(printerDetails) {
  return {
    ...printerDetails,
    WPPData: {
      ...propOr({}, 'WPPData', printerDetails),
      detailsData: printerDetails,
    },
  };
}

const getCurrentPrinterList = pathOr([], ['data', 'WPPData']);
const safeGetNestedOnwershipId = arg => pathOr(
  prop('ownershipId')(arg),
  ['WPPData', 'ownershipId'],
)(arg);

const findPrinterInState = (printerData, state) => compose(
  find(
    either(
      propEq('ownershipId', safeGetNestedOnwershipId(printerData)),
      pathEq(['WPPData', 'ownershipId'], safeGetNestedOnwershipId(printerData)),
    ),
  ),
  getCurrentPrinterList,
)(state);

function handleCompleteMyPrinterFetch(state, action) {
  const nestedPrinterData = nestPrinterDataForSomeReason(action.myPrinter);
  const printerInState = findPrinterInState(nestedPrinterData, state);
  const {
    data = {},
  } = state;
  const currentPrintersList = getCurrentPrinterList(state);
  if (printerInState) {
    return {
      ...state,
      data: {
        ...data,
        WPPData: currentPrintersList.map(printer => {
          if (safeGetNestedOnwershipId(printer) === safeGetNestedOnwershipId(nestedPrinterData)) {
            return nestedPrinterData;
          }
          return printer;
        }),
      },
    };
  }
  return {
    ...state,
    data: {
      ...data,
      WPPData: [...currentPrintersList, nestedPrinterData],
    },
  };
}

function myPrinters(state = myPrintersDefault, action) {
  switch (action.type) {
    case ActionTypes.START_MYPRINTERS_FETCH:
      return {
        ...state,
        ...fetchStatus(state, { type: ActionTypes.START_FETCH }),
      };

    case ActionTypes.COMPLETE_MYPRINTERS_WITH_ERROR:
      return {
        ...state,
        ...fetchStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH_WITH_ERROR }),
      };

    case ActionTypes.MYPRINTERS_ALTERED:
      return {
        ...state,
        ...{ integrity_sustained: true },
      };
    case ActionTypes.COMPLETE_UPDATE_MYPRINTER_ACCESS_OPTION:/** handle holding state here for open to anyone */
      if (!!state.data && !!state.data.WPPData && state.data.WPPData.length === 0) { // THERE ARE NO PRINTERS IN  MYPRINTERS
        return {
          ...state,
          ...{ integrity_sustained: false },
        };
      } if (!!state.data && !!state.data.WPPData && state.data.WPPData.length > 0) { // THERE IS ONE OR MORE PRINTERS IN  MYPRINTERS
        let foundIndex = -1;
        for (let i = 0; i < state.data.WPPData.length; i++) {
          if (state.data.WPPData[i].ownershipId === state.myPrinter.data.WPPData.ownershipId) {
            foundIndex = i;
            break;
          }
        }
        if (foundIndex === -1) { // not found // this should not happen // or could happen if this printer never loaded into list
          return {
            ...state,
            ...{ integrity_sustained: false },
          };
        } if (foundIndex > -1) { // found
          state.data.WPPData[foundIndex].printerIsProtected = action.newPrinter.printerIsProtected;
          state.data.WPPData[foundIndex].printerCurrentAccess = action.newPrinter.printerCurrentAccess;
          state.data.WPPData[foundIndex].detailsData.WPPData.printerIsProtected = action.newPrinter.printerIsProtected;
          state.data.WPPData[foundIndex].detailsData.WPPData.printerCurrentAccess = action.newPrinter.printerCurrentAccess;
        }
      }
      return { ...state, data: state.data };

    case ActionTypes.COMPLETE_MYPRINTER_UNCLAIM:
      if (!!state.data && !!state.data.WPPData && state.data.WPPData.length === 0) { // THERE ARE NO PRINTERS IN  MYPRINTERS
        return {
          ...state,
          ...{ integrity_sustained: false },
        };
      } if (!!state.data && !!state.data.WPPData && state.data.WPPData.length > 0) { // THERE IS ONE OR MORE PRINTERS IN  MYPRINTERS
        let foundIndex = -1;
        for (let i = 0; i < state.data.WPPData.length; i++) {
          if (state.data.WPPData[i].ownershipId === state.myPrinter.data.WPPData.ownershipId) {
            foundIndex = i;
            break;
          }
        }
        if (foundIndex === -1) { // not found // this should not happen // or could happen if this printer never loaded into list
          return {
            ...state,
            ...{ integrity_sustained: false },
          };
        } if (foundIndex > -1) { // found
          state.data.WPPData.splice(foundIndex, 1);
        }
      }
      return { ...state, data: state.data };
    case ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_EMAIL:
    case ActionTypes.COMPLETE_UPDATE_MYPRINTER_ADDRESS: {
      const { ownershipId, printerEmail, data } = action;
      const printerInState = findPrinterInState({ ownershipId }, state);
      if (printerInState) {
        const currentPrintersList = getCurrentPrinterList(state);
        return {
          ...state,
          data: {
            ...state.data,
            WPPData: currentPrintersList.map(printer => {
              if (safeGetNestedOnwershipId(printer) === ownershipId) {
                const updatedPrinterData = {
                  ...printer,
                  printerEmail: printerEmail || data.printerEmail,
                  WPPData: {
                    ...printer.WPPData,
                    printerEmail: printerEmail || data.printerEmail,
                  },
                };
                return updatedPrinterData;
              }
              return printer;
            }),
          },
        };
      }
      return state;
    }
    case ActionTypes.COMPLETE_MYPRINTERS_FETCH:
      return {
        ...state,
        ...fetchStatus(state, { type: ActionTypes.COMPLETE_FETCH }),
        ...{ integrity_sustained: true, data: action.myPrintersData },
      };

    case ActionTypes.COMPLETE_MYPRINTER_FETCH:
      return handleCompleteMyPrinterFetch(state, action);
    default:
      return state;
  }
}

export default myPrinters;
