import React from 'react';
import { useSelector } from 'react-redux';
import { JarvisAtlasInbox } from '@jarvis/react-atlas-notifications';
import { getStratusAccessToken } from '../../utils/auth';
import { getExchangeToken } from '../../api/HPCSession';
import i18n from '../../utils/i18n';
import {
  selectQueryParameters,
} from '../../../../selectors/routing';
import {
  Container,
  Title,
  Description,
} from '../../components/shared-components/styles';
import { selectNotificationProps } from '../../store/modules/notification/selector';
import {
  onClickActionFunction,
  shouldShowAction,
} from '../../components/shared-components/NotificationActionComponent';

const AtlasInboxNotificationsPage = () => {
  const {
    subtype,
    count,
  } = useSelector(selectQueryParameters);

  const {
    country,
    language,
    baseUrl,
  } = useSelector(selectNotificationProps);

  const authProvider = {
    getAccessToken: async forceRefresh => {
      const token = getStratusAccessToken();
      if (forceRefresh) {
        await getExchangeToken(token);
        return getStratusAccessToken();
      }
      return token;
    },
  };

  return (
    <Container>
      <Title>{i18n.t('notifications.headers.mainHeader')}</Title>
      <Description>{i18n.t('notifications.headers.description')}</Description>
      <JarvisAtlasInbox
        country={country.toUpperCase()}
        language={language}
        subtype={subtype}
        displayMode="inbox"
        count={count}
        baseUrl={baseUrl}
        authProvider={authProvider}
        shouldShowAction={shouldShowAction()}
        onClickActionFunction={onClickActionFunction()}
      />
    </Container>
  );
};

export default AtlasInboxNotificationsPage;
