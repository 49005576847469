import styled from 'styled-components';
import {
  prop,
} from 'ramda';
import { Card } from '@veneer/core';
import { Link } from '../../../../../routing';
import { Content as MainTopicContent } from '../styles';

export const stylesIf = (propName, trueValue = '', falseValue = '') => props => (
  prop(propName, props)
    ? trueValue
    : falseValue
);

export const SubtopicContainer = styled.div`
  padding: 24px;
  display: flex;
  ${
  stylesIf(
    'content',
    `
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 24px;
    `,
    `
      flex-direction: row;
      align-items: center;
    `,
  )
}
  justify-content: space-between;
  border-bottom: 1px solid #E2E2E2;
`;

export const Nav = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  font-size: 20px;
`;

export const SubtopicContent = styled(MainTopicContent)`
  > h3 {
    font-size: 16px;
  }
  > h5 {
    font-size: 20px;
  }
`;

export const SubtopicTitle = styled.h5`
  ${stylesIf('content', 'margin-bottom: 20px;')}
`;

export const HelpCenterMobileCard = styled(Card)`
  ${SubtopicContainer}:last-of-type {
    border-bottom: none;
  }
`;