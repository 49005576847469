import React, { Component } from 'react';
import {
  identity,
  path,
  prop,
} from 'ramda';
import styled from 'styled-components';
import { TweenLite } from 'gsap';
import bind from '../bind';
import GlobalSharing from './accessOptions/GlobalSharing';
import AddNewEmail from './accessOptions/AddNewEmail';
import AccessListItem from './accessOptions/AccessListItem';
import Accordion from '../shared-components/organisms/Accordion';

const getWPPData = path(['printAnywhere', 'data', 'WPPData']);
const getprinterId = path(['printAnywhere', 'data', 'WPPData', 'printerId']);

class AccessOptions extends Component {
  constructor(props) {
    super(props);
    const { platform } = props;
    this.state = {
      groupControl: { identifier: '', group: '' },
      deactivate: false,
      currentPage: 0,
      loadingSwitch: '',
    };
    this.pendingOption = -1;
    this.knownList = 0;
    this.spinningID = '';
    this.concurrentClicks = 0;
    this.pagePosition = 0;
    this.pages = 0;
    this.platform = platform;
  }

  componentDidMount() {
    const { platform } = this.props;
    this.platform = platform;

    // THIS CIRCUMVENTS AN INITIAL PAGE BLINK ON FIRST INTERACTION
    this.setState({ deactivate: true });
    setTimeout(() => { this.setState({ deactivate: false }); }, 300);
  }

  componentDidUpdate(prevProps) {
    if (getWPPData(this.props) !== getWPPData(prevProps)) {
      setTimeout(() => { this.setState({ deactivate: false, loadingSwitch: '' }); }, 300);
      this.showSpinner(false);
    }
  }

  groupFn = o => {
    this.setState({
      groupControl: {
        identifier: o.myID,
        group: o.groupID,
      },
    });
  };

  callbackFn = (isOpen, thisID) => {
    const { trackingHook } = this.props;
    if (thisID === 'accessOptionsMoreAccordion') {
      trackingHook(`more_accordion_${isOpen ? 'open' : 'close'}`);
      const element = '#printAnywhereMore_arrow';
      const rotate = isOpen ? 180 : 0;
      TweenLite.to(element, 0.5, { rotationX: rotate });
    } else if (thisID.indexOf('accessListItem') === 0) {
      if (isOpen) {
        trackingHook('user_access_added');
      } else {
        trackingHook('user_access_deleted');
      }
    }
  };

  shareOnChangeHandler = e => {
    const {
      trackingHook,
      changePrintAnywhereMode,
    } = this.props;
    this.setState({ deactivate: true });
    this.spinningID = '#printAnywhere_share_spinner';
    this.showSpinner(true, e.target.checked);
    const newmode = e.target.checked ? 'auto' : 'manual';

    trackingHook(`share_mode_${e.target.checked ? 'on' : 'off'}`);

    changePrintAnywhereMode(getprinterId(this.props), newmode);
  }

  showSpinner = (ON, offset) => {
    const oneZero = ON ? 1 : 0;
    if (this.spinningID === null) {
      return null;
    }
    if (offset !== undefined) {
      if (this.platform === 'desktop') {
        TweenLite.set(this.spinningID, { left: offset ? '46px' : '21px' });
      } else {
        TweenLite.set(this.spinningID, { left: offset ? '46px' : '26px' });
      }
    }
    TweenLite.to(this.spinningID, 0.5, { autoAlpha: oneZero }).delay(oneZero * 0.215);

    if (!ON) {
      this.concurrentClicks = (this.concurrentClicks <= 0) ? 0 : this.concurrentClicks - 1;
      if (this.concurrentClicks > 0) {
        for (let i = 0; i < this.knownList; i++) {
          TweenLite
            .to(
              `#printAnywhere_spinner_${i}`,
              0.5,
              { autoAlpha: oneZero },
            )
            .delay(oneZero * 0.215);
        }
        this.concurrentClicks = 0;
      }
      this.spinningID = null;
    }
  }

  nextPage = (dir, gotoPage = null) => {
    const divide = Math.ceil((this.knownList - 1) / 10);
    if (gotoPage === null) {
      const nextPage = this.pagePosition + dir;
      this.pagePosition = nextPage > 0
        ? 0
        : Math.abs(nextPage) < divide
          ? nextPage
          : this.pagePosition;
    } else {
      this.pagePosition = gotoPage;
    }
    const sliderElement = document.getElementById('printAnywhere_slider');
    const pos = `${this.pagePosition * 100}%`;
    TweenLite.to(sliderElement, 0.5, { left: pos });
    this.setState({ currentPage: this.pagePosition });
  }

  getIdentifier = (user, i) => {
    if (prop('firstName', user)) {
      return `accessListItem_action_${user.firstName}${prop('lastName', user) ? `${i}${prop('lastName', user)}` : ''}`;
    }
    if (prop('userEmail', user)) {
      return user.userEmail.includes('@')
        ? `accessListItem_action_${`${user.userEmail.split('@')[0]}_${i}_${user.userEmail.split('@')[1]}`}`
        : `${user.userEmail}${i}`;
    }
    if (prop('userID', user)) {
      return `accessListItem_action_${user.userID}_${i}`;
    }
    return '';
  }

  getName = user => {
    if (prop('firstName', user)) {
      return `${user.firstName}${prop('lastName', user) ? ` ${user.lastName}` : ''}`;
    }
    if (prop('userEmail', user)) {
      return user.userEmail;
    }
    if (prop('userID', user)) {
      return `userID:${user.userID}`;
    }
    return '';
  }


  removeOnClickHandler = (e, email, actionID) => {
    const { removePrintAnywherePermissions } = this.props;
    this.knownList -= 1;
    this.setState({ deactivate: true });
    if (
      this.pages > 1
      && this.knownList % 10 === 1
      && this.pagePosition === -(this.pages - 1)
    ) {
      this.nextPage(null, -(this.pages - 2));
    }
    const postBody = {
      resourceType: 'email',
      resource: email,
      action: 'revoke_permissions',
    };
    removePrintAnywherePermissions(getprinterId(this.props), postBody);
    document.getElementById(actionID).click();
  }

  permissionsOnChangeHandler(e, user) {
    const {
      trackingHook,
      changePrintAnywherePermissions,
    } = this.props;
    this.setState({ deactivate: true, loadingSwitch: user.userEmail || user.userID });

    const iam = e.target.id.split('_')[1];
    this.concurrentClicks++;
    this.spinningID = `#printAnywhere_spinner_${iam}`;
    this.showSpinner(true, e.target.checked);
    const newPermissions = e.target.checked ? 'eprintuser' : 'blockeduser';
    let postBody = {};
    if (prop('userEmail', user)) {
      postBody = {
        resourceType: 'email',
        resource: user.userEmail,
        action: 'update_permissions',
        role: newPermissions,
      };
    } else if (prop('userID', user)) {
      postBody = {
        resourceType: 'hpid',
        resource: user.userID,
        action: 'update_permissions',
        role: newPermissions,
      };
    }

    trackingHook(`user_access_toggle_${e.target.checked ? 'allow' : 'block'}`);

    changePrintAnywherePermissions(getprinterId(this.props), postBody);
  }

  render() {
    const {
      printAnywhere: {
        data: {
          WPPData: myPrinter,
          csData,
        },
      },
    } = this.props;
    const {
      deactivate,
      groupControl,
      currentPage,
      loadingSwitch,
    } = this.state;

    if (!csData || !myPrinter || !myPrinter.accessList) { return null; }

    const changeHappen = this.knownList > 0 ? this.knownList < myPrinter.accessList.length : false;
    this.knownList = myPrinter.accessList ? myPrinter.accessList.length : 0;
    this.pages = Math.ceil((this.knownList - 1) / 10);

    return (
      <StyledAccess id="openToAnyone">
        <Disactivate
          ON={deactivate}
          onClick={identity}
          onTouchStart={identity}
          onTouchEnd={identity}
        />

        <a name="tracking-hook" id="user_access_toggle_allow" />
        <a name="tracking-hook" id="user_access_toggle_block" />
        <a name="tracking-hook" id="more_accordion_open" />
        <a name="tracking-hook" id="more_accordion_close" />
        <a name="tracking-hook" id="user_access_deleted" />
        <a name="tracking-hook" id="user_access_added" />
        <a name="tracking-hook" id="share_mode_on" />
        <a name="tracking-hook" id="share_mode_off" />

        <GlobalSharing
          platform={this.platform}
          printerId={getprinterId(this.props)}
        />
        <Accordion
          startOpen={this.platform === 'desktop'}
          ID="accessOptionsMoreAccordion"
          actionID="accessOptionsMoreAnchor"
          groupID="moreOptions"
          groupFn={this.groupFn}
          controler={groupControl}
          callbackFn={this.callbackFn}
        >
          <AutoManualDetails
            platform={this.platform}
            dangerouslySetInnerHTML={{ __html: csData.print_anywhere_details_text }}
          />
        </Accordion>
        <AddNewEmail
          platform={this.platform}
          printerId={myPrinter.printerId}
        />
        <TitleRow platform={this.platform}>
          <div>
            <strong>
              {csData.print_anywhere_users_text}
            </strong>
          </div>
          <div>
            <strong>
              {csData.print_anywhere_access_text}
            </strong>
          </div>
        </TitleRow>
        {
          myPrinter.accessList.map(user => {
            if (user.roles.includes('owner')) {
              return (
                <OwnerRow platform={this.platform} key="OWNER">
                  <div>
                    <span>
                      <img src="/assets/img/icon_avatar.svg" alt="" />
                    </span>
                    {`${user.userEmail} (${csData.print_anywhere_owner_text})`}
                  </div>
                </OwnerRow>
              );
            }
            return null;
          })
        }
        <div style={{ overflow: 'hidden' }}>
          <Slider
            id="printAnywhere_slider"
            platform={this.platform}
            listLength={this.knownList}
          >
            {
              myPrinter.accessList.map((user, i) => {
                const actionId = this.getIdentifier(user, i);
                if (user.roles.includes('owner')) {
                  return null;
                }
                return (
                  <EmailItem listLength={this.knownList} key={actionId}>
                    <AccessListItem
                      actionId={actionId}
                      platform={this.platform}
                      onRemoveClick={e => this.removeOnClickHandler(e, user.userEmail, actionId)}
                      userName={this.getName(user)}
                      handleToggleClick={e => this.permissionsOnChangeHandler(e, user)}
                      toggleChecked={!(user.roles.includes('blockeduser'))}
                      toggleLoading={Boolean(
                        loadingSwitch && (
                          loadingSwitch === user.userEmail
                          || loadingSwitch === user.userID
                        ),
                      )}
                    />
                  </EmailItem>
                );
              })
            }
            <EmailItem name="straggler" listLength={this.knownList}>
              <div>&nbsp;</div>
            </EmailItem>
          </Slider>
        </div>

        {
          Math.ceil((this.knownList - 1) / 10) > 1 && (
            <PaginationControls platform={this.platform}>
              <Numeral>
                {
                  (this.pages - (this.pages + currentPage)) === 0
                    ? (
                      <span style={{ color: 'white' }}>
                        0
                      </span>
                    )
                    : (
                      <span style={{ color: '#e2e2e2' }}>
                        {this.pages - (this.pages + currentPage)}
                      </span>
                    )
                }
              </Numeral>
              <PageArrow
                activeColor={!((this.pages - (this.pages + currentPage)) === 0)}
                onClick={() => this.nextPage(1)}
              >
                <svg
                  width="12"
                  height="20.5"
                  viewBox="0 0 12 20.5"
                >
                  <g>
                    <path d="M9.537,20.083.292,10.946a.975.975,0,0,1,0-1.392L9.537.417a1.456,1.456,0,0,1,2.041,0,1.415,1.415,0,0,1,0,2.016L3.669,10.25l7.909,7.815a1.417,1.417,0,0,1,0,2.017,1.456,1.456,0,0,1-2.041,0" />
                  </g>
                </svg>
              </PageArrow>
              <Numeral>
                {(this.pages - (this.pages + currentPage)) + 1}
              </Numeral>
              <PageArrow
                activeColor={!((this.pages - (this.pages + currentPage) + 2) > this.pages)}
                style={{ transform: 'rotateY(180deg)' }}
                onClick={() => this.nextPage(-1)}
              >
                <svg
                  width="12"
                  height="20.5"
                  viewBox="0 0 12 20.5"
                >
                  <g>
                    <path d="M9.537,20.083.292,10.946a.975.975,0,0,1,0-1.392L9.537.417a1.456,1.456,0,0,1,2.041,0,1.415,1.415,0,0,1,0,2.016L3.669,10.25l7.909,7.815a1.417,1.417,0,0,1,0,2.017,1.456,1.456,0,0,1-2.041,0" />
                  </g>
                </svg>
              </PageArrow>
              <Numeral>
                {
                  (this.pages - (this.pages + currentPage) + 2) > this.pages
                    ? (
                      <span style={{ color: 'white' }}>
                        0
                      </span>
                    )
                    : (
                      <span style={{ color: '#e2e2e2' }}>
                        {this.pages - (this.pages + currentPage) + 2}
                      </span>
                    )
                }
              </Numeral>
            </PaginationControls>
          )
        }
      </StyledAccess>
    );
  }
}

export default bind(AccessOptions);

const Slider = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 30rem;
  width: ${props => `${Math.ceil((props.listLength - 1) / 10) * 100}%`};
  height: ${props => `${((props.listLength > 10 ? 10 : props.listLength) - 1) * 48}px`};
  position: relative;   
`;
const EmailItem = styled.div`
  width: ${props => `${100 / (Math.ceil((props.listLength - 1) / 10))}%`};
  flex: 0 1 ${props => ((1 / (props.listLength > 10 ? 10 : props.listLength)).toFixed(2) * 100)}%;
  padding: 0 1rem;
  @media (max-width: 340px) {
    padding: 0 0.5rem;
  }
`;
const PageArrow = styled.div`
  padding: 1rem;
  svg path {
      fill: ${props => (props.activeColor ? '#0171ad' : '#E2E2E2')};
  }
`;
const PaginationControls = styled.div`
  height: 4rem;
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-around;
  ${props => (props.platform === 'desktop' ? '' : 'box-shadow: 0 -8px 16px -1px #8080801a')}
  border: 1px solid white;
  border-top: ${props => `1px solid ${props.platform === 'desktop' ? '#E3E3E3' : 'white'}`}
`;
const Numeral = styled.div`
  font-size: 1rem;
  margin: 1rem;
  user-select: none;
`;
const Disactivate = styled.div`
  visibility: ${props => (props.ON ? 'visible' : 'hidden')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 99.9%;
  height: 99.9%;
  background-color: #ffffff20;
`;

const Spinner = styled.img`
  visibility: hidden;
  position: relative;
  z-index: 30;
  width: ${props => (props.platform === 'desktop' ? '21px' : '26px')};
  height: ${props => (props.platform === 'desktop' ? '21px' : '26px')};
  left: ${props => (props.platform === 'desktop' ? '21px' : '26px')};
  top: ${props => {
    if (props.platform === 'desktop') {
      return '4px';
    }
    return '0';
  }};
  @keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }
  animation: spin .5s linear infinite;
`;
const StyledAccess = styled.div`
  @media screen and (min-width: 64rem) {
   /*  border: 1px solid #E3E3E3;*/
  }
  display: flex;
  flex-direction: column;
  padding-bottom: 1.25rem;
`;
const AutoManualDetails = styled.div`
  padding: ${props => (props.platform === 'desktop' ? '3rem 4.5rem 2.5rem' : '0 1rem 1.25rem')};
  @media (max-width: 640px) {
    h4 {
      margin-top: 0;
    }
  }
  @media (max-width: 340px) {
    padding: 0 0.5rem 0.55rem;
  }
  p { margin-top: 0; }
`;
const UserRow = styled.div`
  display: flex;
  padding: ${props => (props.platform === 'desktop' ? '0 4.5rem 0' : '0 1rem')};
  min-height: 3rem;
  @media (max-width: 340px) {
    padding: 0 0.5rem;
  }
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    a, button, span {
      word-break: break-all;
      flex: 1 1 auto;
      padding-right: 0.5rem;
      display: inline-flex;
      align-content: center;
    }
  }
`;
const TitleRow = styled(UserRow)`
  border-bottom: 1px solid #E3E3E3;
  padding: 0;
  margin: ${props => (props.platform === 'desktop' ? '3rem 4.5rem 2.5rem' : '0 1rem 1.5rem')};
  @media (max-width: 340px) {
    margin: 0 0.5rem 0.6rem;
  }
`;
const OwnerRow = styled(UserRow)`
  min-height: 1rem;
  margin-bottom: 1rem;
`;
