import { pathOr } from 'ramda';

export const parseUcdeGatewayApiError = response => {
  const errorData = JSON.parse(JSON.stringify(response));

  const ucdeApiError = errorData.error;
  const genericAxiosError = errorData.message;

  if (ucdeApiError || genericAxiosError) {
    let source = {};

    if (ucdeApiError) {
      source = ucdeApiError.source;
    }

    if (genericAxiosError) {
      const { config } = response;
      const baseUrl = new URL(config.baseURL);

      source = {
        host: baseUrl.host,
        path: baseUrl.pathname,
        method: pathOr('', ['method'], config),
        code: pathOr('', ['status'], config),
        payload: { message: errorData.message, stack: errorData.stack },
      };
    }

    const { requestId, timestamp } = errorData;

    const errorString = `API: ${pathOr('', ['api'], source)}
      Host: ${pathOr('', ['host'], source)}
      Path: ${pathOr('', ['path'], source)}
      Method: ${pathOr('', ['method'], source)}
      Status Code: ${pathOr('', ['code'], source)}
      Latency: ${pathOr('', ['latency'], source)}
      Message: ${JSON.stringify(pathOr('', ['payload'], source))}
      Timestamp: ${timestamp}
      RequestId: ${requestId}`;

    return errorString;
  }

  return `Unexpected error data. ${JSON.stringify(errorData)}`;
};

export default parseUcdeGatewayApiError;
