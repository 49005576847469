import React, { useEffect, useState } from 'react';
import { Select } from '@veneer/core';
import { useHistory } from 'react-router';
import { useSelector } from '../../hooks';
import {
  selectPrinterListPageContentStackData,
  selectPrinterListTextPageContentStackData
} from '../../selectors/craft';
import { Header, Title, SubTitle, FooterButton, DropDownParent } from './styles/SupportStyles';
import {
  Container,
  Content,
  SelectPrinterModel,
  Footer,
  SelectPrinter
} from './styles/PrinterSelectListDropdownStyles';

const sortPrinterLists = (sections) => {
  return  sections.sort((firstItem, secondItem) => firstItem.index - secondItem.index)
}

const PrinterSelectListDropdown = () => {
  const [isMobile, setMobile] = useState(false);
  const history = useHistory();

  const checkHPSmartApp = async () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      setMobile(true);
    }

    if (/android/i.test(userAgent)) {
      setMobile(true);
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setMobile(true);
    }
    return 'unknown';
  };

  useEffect(() => {
    checkHPSmartApp();
  }, []);

  const csContent = useSelector(selectPrinterListPageContentStackData);
  const csDataContent = useSelector(selectPrinterListTextPageContentStackData);
  const csListContent = csContent?.data?.entries;
  const csDataTextContent = csDataContent?.data?.entries[0];

  var dropDownOptions = [];

  if (csListContent) {
    const csDataListContent = sortPrinterLists(csListContent);
    var printerDetails = [];
    var slugDetails = [];
    csDataListContent.map((data) => {
      printerDetails.push(data.supported_printer_names);
      slugDetails.push(data.slug);
      if (data.supported_printer_names === undefined) {
        slugDetails.pop();
        printerDetails.pop();
      }
    });
    if (printerDetails !== undefined) {
      printerDetails.map((printerDetail, key) => {
        if (printerDetail !== undefined) {
          for (let k = 0; k < printerDetail.length; k++) {
            const { supported_names } = printerDetail[k];
            const { printer_name, printer_id } = supported_names;

            let PrinterValue = printer_id;
            let PrinterLabel = printer_name;
            let PrinterUrl = slugDetails[key];
            let Option = { value: PrinterValue, label: PrinterLabel, url: PrinterUrl };
            dropDownOptions.push(Option);
          }
        }
      });
    }
  }

  const [value, setValue] = useState(null);
  const [url, seturl] = useState(null);
  const [enabled, setenabled] = useState(true);
  function handleChange(e) {
    setValue([e.value]);
    seturl([e.url]);
    setenabled(false);
  }
  function handleClear(e) {
    setValue([null]);
    seturl([null]);
    setenabled(true);
  }
  if (csDataTextContent) {
    const {
      support_headline,
      support_headline_mobile,
      support_subhead,
      support_subhead_mobile,
      select_printer_model,
      continue_button_text,
      support_graphic
    } = csDataTextContent;
    const { url : supportGraphicUrl } = support_graphic || {};

    const isDesktop = !isMobile;
    const supportHeadline = isDesktop ? support_headline : support_headline_mobile;
    const supportSubheadTextData = isDesktop ? support_subhead : support_subhead_mobile;

    const hostUrl = window.location.hostname;
    const port = window.location.port;
    const baseUrl = port ? `https://${hostUrl}:${port}/` : `https://${hostUrl}/`;

    const sortByLabel = (arr) => {
      return arr.sort((a, b) => {
        if (!a?.label) return 1;
        if (!b?.label) return -1;
        return a.label.localeCompare(b.label, 'en', { sensitivity: 'base' });
      });
    };

    return (
      <Container>
        <Header>
          <Title>{supportHeadline}</Title>
          <SubTitle>{supportSubheadTextData}</SubTitle>
        </Header>
        <Content>
          <img alt={supportHeadline} src={supportGraphicUrl} />
          <SelectPrinter>
            <SelectPrinterModel>{select_printer_model}</SelectPrinterModel>
            <DropDownParent>
              <Select
                options={sortByLabel(dropDownOptions)}
                id="select-usage"
                label="Select Printer"
                value={value}
                onClear={(e) => handleClear(e)}
                onChange={(e) => handleChange(e)}
                visibleOptions={5}
              />
            </DropDownParent>
          </SelectPrinter>
        </Content>
        <Footer>
          <a className="backbutton" onClick={() => history.goBack()}>
            <FooterButton appearance="secondary">Back</FooterButton>
          </a>
          <a
            className="continuebutton"
            href={`${baseUrl}support/printer-connection/` + url}
          >
            <FooterButton disabled={enabled}>{continue_button_text}</FooterButton>
          </a>
        </Footer>
      </Container>
    );
  }
  return null;
};

export default PrinterSelectListDropdown;
