import styled from 'styled-components';
import { paths, breakpoints, stylesIf } from '../../../utils/styleHelpers';
import Button from '@veneer/core/dist/scripts/button';
import LogoHp from '@veneer/core/dist/scripts/logos/logo_hp';

export const Container = styled.div`
  font-family: 'hp-simplified', Arial, sans-serif;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  box-sizing: border-box;
  max-width: 90vw;
  padding-top: 1rem;
  padding-bottom: 0;
  margin: 0 5vw;
  @media screen and (min-width: ${breakpoints.mobile}) {
    margin: auto;
  }
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 4rem;
  }
  > * {
    text-align: center;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.wide}) {
    font-size: 0.875rem;
    padding: 0 1rem;
    max-width: 64rem;
    margin-top: 4vh;
    margin-bottom: 0;
  }
`;

export const Title = styled.h1`
  font-family: 'hp-simplified', Arial, sans-serif;
  width: auto;
  padding-top: 1rem;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: 100%;
    padding-top: 5rem;
  }

  && {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }
    line-height: 1.4;
    font-weight: 500;
    font-size: 1.375rem;
    color: #4e4e4e;
  }
`;

export const SubTitle = styled.h2`
  font-family: 'hp-simplified', Arial, sans-serif;
  width: auto;
  padding-top: 1rem;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: 100%;
    padding-top: 2rem;
  }

  && {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }
    line-height: 1.4;
    font-weight: 300;
    font-size: 1.175rem;
    color: #4e4e4e;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding-bottom: 1rem;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${breakpoints.tablet}) {
    padding-top: 1rem;
  }
  
  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 4rem;
    width: 100%;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding-top: 10px;
  }

  @media screen and (min-width: 500px) and (max-width: 500px) and (min-height: 530px ) and (max-height: 530px) {    
    width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    padding-top: 4rem;
  }

  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    padding-top: 4rem;
  }
}
`;

export const StyledButton = styled(Button)`
  && {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }
    min-height: ${stylesIf('small', '2.25', '2.875')}rem;
    ${stylesIf('small', 'border-radius: 0.5rem;')}
    ${stylesIf(
      'hasBody',
      `
        @media screen and (min-width: 25.875rem) {
          margin-top: 1rem;
        }
      `
    )}
  }
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledHPLogo = styled(LogoHp)`
  height: ${stylesIf('$small', '32px', '40px')};
  width: ${stylesIf('$small', '32px', '40px')};
  color: ${paths.colorsHpBlue};
  margin-right: 10px;
`;

export const BrandText = styled.h4`
  font-size: ${paths.fontsLg};
  font-family: ${paths.fontsFamily};
  color: ${paths.colorsBlack};
  margin-bottom: 0;
  white-space: nowrap;
`;

export const AppBadgesContainer = styled.div`
  && {
    display: flex;
    justify-content: center;
    width: 225px;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 40px;
    .app-store {
      width: 96px;
    }
    .google-play {
      width: 117px;
    }
    .ms {
      width: 108px;
    }
    .mac-app-store {
      width: 150px;
    }
    .store {
      margin: 2px 4px;
    }
    @media (min-width: ${breakpoints.desktop}) {
      margin-bottom: initial;
    }
    @media (min-width: ${breakpoints.desktop}) {
      width: auto;
      margin-bottom: initial;
    }
  }
`;
