import axios from 'axios';
import Config from '../config/Config';
const base = '/api';

const config = new Config();
const { hostname, port } = window.location;
const api_host_helper = config.Get('API_HOST_HELPER')[hostname];
const baseUrl = `//${api_host_helper}${port ? `:${port}` : ''}`;

export function helperapi(requestObj, version = 2, NON_AUTH='') {
  const { url, method = 'GET', data = '', ...options } = requestObj;
  const consentBase = `${base}/${version}`;
  const fullUrl = NON_AUTH ? `${consentBase}/${NON_AUTH}${url}` : `${consentBase}/helper_nonAuth${url}`;

  try {
    const response = axios({
      baseURL: baseUrl,
      url: fullUrl,
      method,
      data,
      ...options
    });
    return response;
  } catch (e) {
    e.method = method;
    e.url = fullUrl;
    throw e;
  }
}

export const isHpPlusCountry = async (country) => {
  const url = `/hpPlus?countryCode=${country}`;
  return await helperapi({ url });
};

export const getInstantInkSupportedCountries= () => {
  const url = `/instantInk`;
  return helperapi({ url });
};

export const getInstantInkPlanPrice= (locale) => {
  const url = `/instant-ink-plans?locale=${locale}`;
  const NON_AUTH = 'nonAuth';
  return helperapi({ url }, 1, NON_AUTH);
};