import React from 'react';
import { getHistory } from "../../../index";
import classNames from 'classnames';
import styled from 'styled-components';


export default function HollowButton(props) {
  let addClassNames = props.classes || {};
  if (props.hasOwnProperty('disabled')) {
    addClassNames['button-inactive'] = props.disabled == true;
  }
  if (props.hasOwnProperty('isRTLSupport')) {
    addClassNames['rtl-button'] = props.isRTLSupport == true;
  }
  let classes = classNames("button", "hollow", addClassNames)
  function onClick(e) {
    if (props.hasOwnProperty('onClick')) {
      props.onClick(e);
    }
    if (props.disabled == true) return false; // if button disabled do nothing
    let linkTo = (arg) => {
      if (arg.indexOf("http") !== -1) {  // For in case link is full url
        let win = window.open(arg, '_blank');
        win.focus();
      } else {
        let history = getHistory();
        history.push(arg);
      }
    }
    if (props.buttonLink !== undefined) linkTo(props.buttonLink);
  }
  return <Btn onClick={onClick} className={classes} data-tracking={props.datatracking} id={props.buttonID} >{props.buttonText}</Btn>
}

const Btn = styled.a`
.button {
	font-family: 'hp-simplified', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-size: rem-calc(18);
  @include breakpoint(medium down) {
    border-radius: 0;
  }
	&:hover, &:active, &:focus {
		text-decoration: none;
	}
}
.button.hollow {
  color: $primary-color;
  border-color: $primary-color;
	&:hover {
		color: $anchor-color;
		border-color: $anchor-color;
	}
}
.button-inactive.hollow {
  color: $medium-gray;
  border-color: $medium-gray;
	&:hover, &:active {
		color: $medium-gray;
    border-color: $medium-gray;
    cursor: default;
	}
}
.button--primary.hollow {
  border: solid 2px;
}
.button.rtl {
  margin-right:1.25rem;
  margin-left: 0px;
}
`