import styled from 'styled-components';
import { breakpoints, smb_breakpoints, venBreaks, theme } from '../../../../utils/styleHelpers';

export const PrinterContainer = styled.div`
  font-size: 21px;
  width: 827px;
  ${(props) => (props.isRTL ? 'margin: 40px 131px 0 auto' : 'margin: 40px auto 0 131px')};

  h3 {
    color: #404040;
    font-family: ${theme.fonts.family};
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
  }

  .addPrinterSteps {
    width: 387px;
    @media (min-width: ${smb_breakpoints.mobile}) {
      width: 458px;
    }

    @media (min-width: ${breakpoints.bigtablet}) {
      width: 571px;
    }

    @media (min-width: 800px) {
      width: 650px;
    }

    @media (min-width: ${smb_breakpoints.desktop}) {
      width: 760px;
    }

    @media (min-width: 930px) {
      width: 790px;
    }

    @media (min-width: 961px) {
      width: 830px;
    }
  }

  .addPrinterFixes {
    width: 280px;
    ${(props) => (props.isRTL ? 'margin-right: 27px' : 'margin-left: 27px')};

    @media (min-width: ${breakpoints.desktop}) {
      width: 319px;
    }

    @media (min-width: ${smb_breakpoints.mobile}) {
      width: 381px;
    }

    @media (min-width: ${breakpoints.tablet}) {
      width: 412px;
    }

    @media (min-width: ${breakpoints.bigtablet}) {
      width: 490px;
    }

    @media (min-width: 800px) {
      width: 586px;
    }

    @media (min-width: ${smb_breakpoints.desktop}) {
      width: 591px;
    }

    @media (min-width: 930px) {
      width: 689px;
    }

    @media (min-width: ${venBreaks.desktop}) {
      width: 759px;
    }
  }

  .printStepsHeader {
    margin-top: 25px;
    width: 302px;

    @media (min-width: ${breakpoints.desktop}) {
      width: 402px;
    }

    @media (min-width: ${smb_breakpoints.mobile}) {
      width: 403px;
    }

    @media (min-width: ${breakpoints.tablet}) {
      width: 412px;
    }

    @media (min-width: ${breakpoints.bigtablet}) {
      width: 490px;
    }

    @media (min-width: 800px) {
      width: 586px;
    }

    @media (min-width: ${smb_breakpoints.desktop}) {
      width: 591px;
    }

    @media (min-width: 930px) {
      width: 689px;
    }

    @media (min-width: ${venBreaks.desktop}) {
      width: 840px;
    }
  }

  .printSteps {
    width: 302px;
    ${(props) => (props.isRTL ? 'margin-right: 5px' : 'margin-left: 5px')};

    @media (min-width: ${breakpoints.desktop}) {
      width: 319px;
    }

    @media (min-width: ${smb_breakpoints.mobile}) {
      width: 381px;
    }

    @media (min-width: ${breakpoints.tablet}) {
      width: 412px;
    }

    @media (min-width: ${breakpoints.bigtablet}) {
      width: 490px;
    }

    @media (min-width: 800px) {
      width: 586px;
    }
  }
`;

export const Line = styled.hr`
  border: 1px solid #027aae;
  margin-top: 35px;
  width: 954px;
`;
