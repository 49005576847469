import React from 'react';
import { PrinterContainer, Line } from './styles';

const Printers = (props) => {
  const addPrinterSteps = props.steps[0].install_step.step;
  const addPrinterFixes = props.steps[1].install_step.step;
  const printStepsHeader = props.steps[2].install_step.step;
  const printSteps = props.steps[3].install_step.step;

  return (
    <PrinterContainer isRTL={props.isRTL}>
      <div className="addPrinterSteps" dangerouslySetInnerHTML={{ __html: addPrinterSteps }} />
      <div className="addPrinterFixes" dangerouslySetInnerHTML={{ __html: addPrinterFixes }} />
      <div className="printStepsHeader" dangerouslySetInnerHTML={{ __html: printStepsHeader }} />
      <div className="printSteps" dangerouslySetInnerHTML={{ __html: printSteps }} />
      <Line />
    </PrinterContainer>
  );
};

export default Printers;
