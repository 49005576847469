import React from 'react';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import {
  IconChevronLeft,
  Button,
} from '@veneer/core';
import { useSelector } from '../../../hooks';
import { selectPortalTakeoverProps } from '../../Dashboard/store/modules/portal/selectors'
import { StyledHeader } from './styles';

const PortalTakeover = () => {
  const takeoverProps = useSelector(selectPortalTakeoverProps);
  const dispatch = useDispatch();
  if (!takeoverProps) {
    return null;
  }

  return (
    <StyledHeader
      position="fixed"
      leftArea={(
        <Button
          onClick={() => dispatch(goBack())}
          leadingIcon={<IconChevronLeft />}
          negative
        />
      )}
      centerArea={(
        <h4>
          {takeoverProps}
        </h4>
      )}
      rightArea={<div />}
    />
  );
};

export default PortalTakeover;
