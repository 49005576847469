import { reverseCountryVariants, COUNTRY_DOMINANT_LANGUAGE } from '../constants/i18n';
export function appendLocaleToWebauthUrl(url, localeProp){
    let cc = localeProp.countryCode    
    if (!COUNTRY_DOMINANT_LANGUAGE.hasOwnProperty(cc)) {
        cc = "us"
    }
    if(reverseCountryVariants.hasOwnProperty(cc)){
        cc = reverseCountryVariants[cc];
    }
    const _CC = cc.toUpperCase()
    return `${url}&country=${_CC}&lc=${localeProp.languageCode}-${_CC}`;
}