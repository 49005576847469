import React from 'react';
import { identity } from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Switch = styled.label`
  flex: 0 0 auto;
  display: inline-block;
  margin-left: .5rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  span {
    position: relative;
    display: inline-block;
    width: 46px;
    border: ${props => (props.platform === 'desktop' ? '1px solid #4E4E4E' : 'none')};
    height: ${props => (props.platform === 'desktop' ? '20px' : '26px')};
    background-color: ${props => (props.platform === 'desktop' ? 'transparent' : '#e6e6e6')};
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
  }
  img {
    vertical-align: top;
  }
  span::before {
    ${props => (props.platform === 'desktop' ? "content: ''" : '')}
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    transform: translate3d(0.125rem, 0.125rem, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
  }
  span::after {
    content: "";
    position: absolute;
    left: 0;
    width: ${props => (props.platform === 'desktop' ? '10px' : '22px')};
    height: ${props => (props.platform === 'desktop' ? '10px' : '22px')};
    margin: ${props => (props.platform === 'desktop' ? '3px' : '0px')};
    margin-left: ${props => (props.platform === 'desktop' ? '3px' : '0px')};
    background-color: #fff;
    border-radius: 11px;
    ${props => (props.platform === 'desktop' ? '' : 'box-shadow: 0 0.125rem rgba(0, 0, 0, 0.24);')}
    transform: translate3d(0.125rem, 0.125rem, 0);
    transition: all 0.2s ease-in-out;
  }
  &:active span::after {
    transform: translate3d(0.125rem, 0.125rem, 0);
  }
  input { 
    display: none; 
  }
  input:checked + span { 
    background-color: ${props => props.theme.hpBlue};
    border: ${props => (props.platform === 'desktop' ? `0.125rem solid ${props.theme.hpBlue}` : 'none')};
  }
  input:not(checked) + span {
    border: ${props => (props.platform === 'desktop' ? '0.125rem solid #4E4E4E' : 'none')};
  }
  input:checked + span::before { transform: translate3d(18px, 0.125rem, 0) scale3d(0, 0, 0); }
  input:checked + span::after {
    ${props => (props.platform === 'desktop' ? 'transform: translate3d(26px, 0, 0)' : 'transform: translate3d(22px, 0.125rem, 0)')};
    background-color: #fff;
  }
  input:not(checked) + span::after {
    ${props => (props.platform === 'desktop' ? 'transform: translate3d(1px, 0, 0);' : 'transform: translate3d(2px, 0.125rem, 0);')};
    background-color: ${props => (props.platform === 'desktop' ? '#4E4E4E' : '#fff')};
  }
`;

const Spinner = styled.img`
  opacity: 0;
  position: relative;
  z-index: 30;
  width: ${props => (props.platform === 'desktop' ? '10px' : '26px')};
  height: ${props => (props.platform === 'desktop' ? '10px' : '26px')};
  top: ${props => {
    if (props.platform === 'desktop') {
      return '6px';
    }
    return '0';
  }};
  left: ${({ isOffset, platform }) => {
    if (isOffset) {
      return platform === 'desktop' ? '41px;' : '46px;';
    }
    return platform === 'desktop' ? '16px;' : '26px;';
  }}
  transition: all 0.18s ease-in-out;
  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }
  ${props => (
    props.isLoading
      ? `animation: spin .5s linear infinite,
        appear .5s ease 0s 1 normal forwards;`
      : ''
  )}
`;

const ToggleSwitch = props => {
  const {
    platform,
    handleChange,
    checked,
    loading,
  } = props;

  return (
    <Switch platform={platform}>
      <Spinner
        platform={platform}
        isLoading={loading}
        src="/assets/print-anywhere/spinner.svg"
        isOffset={checked}
      />
      <input
        onChange={loading ? identity : handleChange}
        type="checkbox"
        checked={checked}
      />
      <span />
    </Switch>
  );
};

ToggleSwitch.defaultProps = {
  platform: 'web',
  checked: false,
  loading: false,
};

ToggleSwitch.propTypes = {
  platform: PropTypes.oneOf(['desktop', 'mobile', 'web']),
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ToggleSwitch;
