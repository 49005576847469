import React, { Component } from 'react';
import {
  pathOr,
  prop,
  head,
  path,
} from 'ramda';
import Styled from 'styled-components';
import BreadCrumbs from '../shared-components/atoms/BreadCrumbs';
import AddPrinterButton from './AddPrinterButton';
import MyPrinterViews from './MyPrinterViews';
import AppPromo from '../shared-components/organisms/AppPromo';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';
import { getHistory } from '../../index';
import { getCookie } from '../../lib/manageCookies';
import { refreshToTop } from '../../lib/refreshToTop';
import bind from '../bind';
import { Redirect } from '../routing';
import { HPC3_SESSION_TYPE } from '../../constants/cookieNames';
import { getLocaleForTranslation } from '../../utils/routing';
import Config from '../../config/Config';
import { determineDest } from '../../selectors/global'

const safeGetNestedProperty = (propName, object) => pathOr(
  prop(propName)(object),
  ['WPPData', propName],
)(object);

class MyPrinters extends Component {
  constructor(props) {
    super(props);
    this.renderJSX = this.renderJSX.bind(this);
    const dim = this.getDimensions();
    this.state = { dimensions: dim, fetchingUserData: false };
  }

  componentWillUnmount() {
    refreshToTop();
  }

  componentDidMount() {
    const {
      match,
      userInfo: {
        userInfoData,
      },
      locales,
      getUser,
      getMyPrinters,
      getPrinterCommon
    } = this.props;

    const locale = getLocaleForTranslation({ currentLocale: locales?.currentLocale, match, alt4: locales?.alt4 }).toLowerCase();

    getMyPrinters(locale);
    getPrinterCommon(locale.replace('_', '-'));

    const { fetchingUserData } = this.state;

    if (userInfoData === null && !fetchingUserData && getCookie(HPC3_SESSION_TYPE) === 'user') { // and not fetching userInfoData
      this.setState({ fetchingUserData: true });
      getUser(() => this.setState({ fetchingUserData: false }));
    }
  }

  componentDidUpdate() {
    const testExistence = document.getElementById('addEvent1');
    if (testExistence !== null) {
      document.getElementById('addEvent1').addEventListener('click', this.createAccountClick);
      document.getElementById('addEvent2').addEventListener('click', this.signInClick);
    }
  }

  getNavigationAuthzURL = (destination) => {
    const config = new Config();

    return determineDest({
      signInURL: config.Get('AUTHZ_OPENID_LOGIN'),
      createUrl: config.Get('AUTHZ_OPENID_CREATE'),
      redirectTo: this.props.match.url,
      analyticsData: null,
      destination
    })
  }

  navigateTo = (destination) => {
    window.location.href = destination;
  }

  createAccountClick = () => {
    this.navigateTo(this.getNavigationAuthzURL('createaccount'));
  }

  signInClick = () => {
    this.navigateTo(this.getNavigationAuthzURL('signin'));
  }

  retrySelf = () => {
    this.navigateTo(this.getNavigationAuthzURL('signin'));
  }

  getDimensions = () => {
    const wide = window.innerWidth || 800;
    let dim = [1269, 765];
    if (wide > 1024) {
      dim = [1269, 765];
    } else if (wide >= 510) {
      dim = [1023, 742];
    } else if (wide < 510) {
      dim = [509, 614];
    }
    return dim;
  }

  loadedFn = () => {
    const dim = this.getDimensions();
    this.setState({ dimensions: dim });
  }

  renderJSX(csData, WPPdata) {
    const {
      myPrinters,
      printerCommon
    } = this.props;
    const hideAddPrinter = path(['application', 'jweb', 'isNative'], this.props);
    const newPrinterPath = '/newprinter';
    const myPrintersTxt = csData.details.my_printers_title;
    if (myPrinters.isComplete !== true || !WPPdata || !printerCommon) {
      return (
        <div className="contentLoading">
          <LoaderBlock />
        </div>
      );
    }

    return (
      <div className="myPrintersList">
        <div className="myPrinters--meta">
          <BreadCrumbs section={myPrintersTxt} />
          {
            !hideAddPrinter && (
              <AddPrinterButton
                buttonID="myPrinters-addPrinter-btn"
                addPrinterSubmitLink={newPrinterPath}
                addPrinterButtonText={csData.details.add_printer_button_text}
                addPrinterIcon="/assets/img/add_printer_icon.svg"
                addPrinterIconTitle=""
              />
            )
          }
        </div>
        <MyPrinterViews
          history={getHistory()}
          numberOfCards={WPPdata.length}
          csData={csData}
          WPPdata={WPPdata}
        />
        <AppPromo ID="myPrintersAppPromo" printerCommon={printerCommon} />
      </div>
    );
  }

  render() {
    const { myPrinters, config } = this.props;
    if (myPrinters !== null && (myPrinters.isFetching || !myPrinters.isComplete || myPrinters.isError || !myPrinters.data || !myPrinters.data.WPPData)) {
      return (
        <div className="contentLoading">
          <LoaderBlock />
        </div>
      );
    }
    const csrftoken = getCookie('hpc3-session-type') === 'user';
    if (myPrinters.isComplete && !myPrinters.isError && !!myPrinters && !!myPrinters.data) {
      const { WPPData, csData } = myPrinters.data;
      /// //////////////////////////// SIGN IN PAGE ///////////////////////////////////////////////////////////////
      if (!csrftoken && !config.data.Get('ENABLE_DEMO')) {
        const csData = (myPrinters.data !== null) ? myPrinters.data.csData : [];
        const imgStyle = {
          position: 'absolute',
          top: 0,
          left: 0,
          maxWidth: '100%',
          height: 'auto',
        };
        return (
          <div className="addPrinter">
            <div className="myPrintersloggedOut">
              <PictureWrapper W={this.state.dimensions[0]} H={this.state.dimensions[1]}>
                <picture className="myPrintersLoggedOut--background">
                  <source media="(min-width: 1024px)" srcSet="/assets/img/logged_out_bg_1269.jpg" />
                  <source media="(min-width: 510px)" srcSet="/assets/img/logged_out_bg_1023.jpg" />
                  <source media="(max-width: 509px)" srcSet="/assets/img/logged_out_bg_509.jpg" />
                  <img
                    src="/assets/img/logged_out_bg_1269.jpg" alt="1328 printer"
                    style={imgStyle} onLoad={this.loadedFn}
                  />
                </picture>
                <div className="myPrintersloggedOut--text">
                  <div className="myPrintersloggedOut--textLinks"
                    dangerouslySetInnerHTML={{ __html: csData.logged_out.create_an_account }}
                  />
                  <p className="myPrintersloggedOut--subhead">{csData.logged_out.requires_account}</p>
                </div>
              </PictureWrapper>
            </div>
          </div>
        );
      }

      if (WPPData && !WPPData.statusCode >= 200 && !WPPData.statusCode < 300) {
        if (WPPData.statusCode === 404) {
          return (
            <div className="contentLoading">
              <LoaderBlock />
            </div>
          );
        }
        if (WPPData.statusCode === 500) {
          return (
            <div className="contentLoading">
              <LoaderBlock />
            </div>
          );
        }
      }

      if (WPPData && typeof WPPData !== 'string') {
        if (WPPData.length > 1) {
          return this.renderJSX(csData, WPPData);
        } if (WPPData.length === 1) {
          const printer = head(WPPData);
          const ownershipId = safeGetNestedProperty('ownershipId', printer);
          const generation = safeGetNestedProperty('generation', printer);
          const newPath = `/myprinter/${ownershipId}?generation=${generation}`;
          return <Redirect to={newPath} />;
        } if (WPPData.length === 0) {
          // IF USER HAS NO PRINTERS THIS PAGE SHOULD REDIRECT TO THE ADD PRINTERS PAGE.
          const newPath = '/newprinter';
          return <Redirect to={newPath} />;
        }
        return (
          <div className="contentLoading">
            <LoaderBlock />
          </div>
        );
      }
      if (WPPData === 'Receive printer error') {
        console.error('WPPData === Receive printer error WPPData', WPPData);
        this.retrySelf();
      }
      return (
        <div className="contentLoading">
          <LoaderBlock />
        </div>
      );
    } if (myPrinters.isError) {
      return (
        <div className="contentLoading">
          <LoaderBlock />
        </div>
      );
    } // Case: We're loading content
    return (
      <div className="contentLoading">
        <LoaderBlock />
      </div>
    );
  }
}

export default bind(MyPrinters);

const PictureWrapper = Styled.section`
  position: relative;
  padding-top: calc(${props => props.H} / ${props => props.W} * 100%);
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
`;
