import React from 'react';
import { Redirect, Route } from 'react-router';
import { useSelector, usePathCorrection } from '../../hooks';
import { selectFeatureFlagEnabled } from '../../selectors/config';

const FeatureFlagRoute = props => {
    const { featureKey, path, ...restProps } = props;

    const isEnabled = useSelector(selectFeatureFlagEnabled, featureKey);
    const homePathWithLocale = usePathCorrection('');
    if (!isEnabled) {
        return <Redirect from={path} to={homePathWithLocale} {...restProps} />
    }

    return <Route {...props} />
}

export default FeatureFlagRoute;