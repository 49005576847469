import React from 'react';
import { prop, propOr } from 'ramda';
import {
  ListContainer,
  ContactEntry,
} from './plain.styles';

const KEYS = [
  'country',
  'language',
  'hp_contact',
  'hp_entity',
  'street_address',
  'support_contact',
  'support_phone',
  'vat_id',
]

const defaultContactLabels = {
  page_title: "Contact",
  country: "Country",
  language: "Language",
  hp_contact: "HP Contact",
  hp_entity: "HP Entity",
  street_address: "Street Address",
  support_contact: "Support Contact",
  support_phone: "Support Phone",
  vat_id: "VAT ID",
  title: null,
  id: "8556",
}

const sortLists = (sections) => {
  return  sections.sort((firstItem, secondItem) => firstItem.index - secondItem.index)
}

const PlainContact = ({ contactEntries, contactLabelData }) => {
  const contactEntriesSort = sortLists(contactEntries);
  return (
    <ListContainer>
      {
        contactEntriesSort.map((contactEntry) => (
          <ContactEntry key={`${contactEntry.country}${contactEntry.language}`}>
            {
              KEYS.map(key => {
                const label = propOr(prop(key, defaultContactLabels), key, contactLabelData);
                const data = prop(key, contactEntry)
                const markdown = (
                  <div key={`${data}${label}`}>
                    <span>
                      {`${label}:`}
                    </span>
                    <span>
                      {data}
                    </span>
                  </div>
                );
                return data !== null && data !== '' && markdown;
              })
            }
          </ContactEntry>
        ))
      }
    </ListContainer>
  )
}

export default PlainContact;