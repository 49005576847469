import { createSelector } from 'reselect';
import {
  pick,
  prop,
  path,
  isEmpty,
  pathOr,
  propEq,
  flip,
  compose,
  trim,
  __,
  curry,
  head,
  split,
  cond,
  where,
  pathSatisfies,
  includes,
  equals,
  concat,
  propSatisfies,
  complement,
  isNil,
  propOr,
  T,
  always,
  identity
} from 'ramda';
import {
  PRINTER_ESSENTIALS_PREFIX,
  DEVICE_LIST_RESOURCE_NAME
} from '../constants/craftResources';

import {
  SMART_ADVANCE_RESOURCE_NAME,
  APP_BADGES_RESOURCE_NAME,
  TOU_RESOURCE_NAME,
  GLOBAL_NAV_RESOURCE_NAME,
  DATA_SHARING_NOTICE_RESOURCE_NAME,
  GENERIC_SUPPORT_RESOURCE_NAME,
  PRINTER_LIST_RESOURCE_NAME,
  PRINTER_LIST_TEXT_RESOURCE_NAME,
  PRINTER_CONNECTION_RESOURCE_NAME,
  SMB_DOWNLOAD_RESOURCE_NAME,
  SMB_NO_SETUP_CHOICE_RESOURCE_NAME,
  SMB_SETUP_CHOICE_RESOURCE_NAME,
  NEW_LOCALE_SELECTOR_RESOURCE_NAME,
  PHOTO_PAPER_RESOURCE_NAME,
  MOBILE_FAX_RESOURCE_NAME,
  ERROR_PAGE_RESOURCE_NAME,
  SURE_SUPPLY_LEARN_RESOURCE_NAME,
  PRINTERS_COMMON_RESOURCE_NAME,
  PRINTERS_LIST_RESOURCE_NAME
} from '../constants/contentStackResources';

import {
  ENTRYID_GLOBAL_NAV_LINKS,
  ENTRYID_GLOBAL_NAV_ACCOUNT_LINKS
} from '../constants/common';

import { INSTANT_INK_COUNTRIES } from '../constants/i18n';
import {
  selectPrinterEssentialsMatch,
  selectPrinterConnectionErrorPageMatch,
  selectErrorPageMatch,
  selectPathname,
  selectRootMatch
} from './routing';
import { determineDest } from './utils';
import { getSupportedLanguagesDictionary, getDefaultCountryCode } from '../utils/routing';
import { selectAuthZCreateUrl, selectAuthZLoginUrl } from './config';
import { selectSignInProps } from '../components/Dashboard/store/modules/portal/selectors';

const SELECTORS_BASE = {
  selectCraftDictionary: prop('craft')
  //(key, dictionary => dictionary[key])
  // The prop function takes key, a string and dictionary, an object, an returns as mentioned.
};

const SELECTORS_BASE_CONTENT_STACK = {
  selectContentStackDictionary: prop('contentStack')
  //(key, dictionary => dictionary[key])
  // The prop function takes key, a string and dictionary, an object, an returns as mentioned.
};

export const selectCraftDictionary = createSelector(
  SELECTORS_BASE.selectCraftDictionary,
  identity
);

export const selectContentStackDictionary = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  identity
);

export const selectCraftDataByResource = createSelector(
  SELECTORS_BASE.selectCraftDictionary,
  (_, resources) => resources,
  flip(pick)
);

export const selectContentStackDataByResource = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  (_, resources) => resources,
  flip(pick)
);

const selectMyPrinterCraftData = createSelector(
  SELECTORS_BASE.selectCraftDictionary,
  (dictionary) => prop('my-printer', dictionary)
);

export const selectEprintAccessOptions = createSelector(selectMyPrinterCraftData, (craftData) =>
  !craftData
    ? null
    : [
      {
        label: prop('eprintAccessOtaLabel', craftData),
        description: prop('eprintAccessOtaDescription', craftData),
        id: 'eprint-access-open',
        key: 'open'
      },
      {
        label: prop('eprintAccessBlockedLabel', craftData),
        description: prop('eprintAccessBlockedDescription', craftData),
        id: 'eprint-access-blocked',
        key: 'blocked'
      },
      {
        label: prop('eprintAccessAllowedLabel', craftData),
        description: prop('eprintAccessAllowedDescription', craftData),
        id: 'eprint-access-allowed',
        key: 'allowed'
      }
    ]
);

export const selectMobileFaxContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(MOBILE_FAX_RESOURCE_NAME)
);

export const selectGenericSupportContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(GENERIC_SUPPORT_RESOURCE_NAME)
);

export const selectDeviceListContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(PRINTER_LIST_TEXT_RESOURCE_NAME)
);

export const selectAppBadgesContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(APP_BADGES_RESOURCE_NAME)
);

export const selectSmartAdvanceContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(SMART_ADVANCE_RESOURCE_NAME)
);

export const selectTOUContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(TOU_RESOURCE_NAME)
);

export const selectSMBNoSetupContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(SMB_NO_SETUP_CHOICE_RESOURCE_NAME)
);

export const selectInstallDriversContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(SMB_DOWNLOAD_RESOURCE_NAME)
);

export const selectAddPrintersContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(SMB_DOWNLOAD_RESOURCE_NAME)
);

export const selectSMBSetupChoiceContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(SMB_SETUP_CHOICE_RESOURCE_NAME)
);

export const selectPhotoPaperContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(PHOTO_PAPER_RESOURCE_NAME)
);

export const selectSureSupplyLearnContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(SURE_SUPPLY_LEARN_RESOURCE_NAME)
);

export const selectMyPrintersContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(PRINTERS_LIST_RESOURCE_NAME)
);
//change names
export const selectPrinterListPageContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(PRINTER_LIST_RESOURCE_NAME)
);
export const selectPrinterListTextPageContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(PRINTER_LIST_TEXT_RESOURCE_NAME)
);

export const selectErrorPageContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  prop(ERROR_PAGE_RESOURCE_NAME)
);

export const selectDataSharingNoticeContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  (dictionary) => {
    if (!dictionary) {
      return null;
    }
    return prop(DATA_SHARING_NOTICE_RESOURCE_NAME, dictionary);
  }
);

export const selectPrinterConnetionErrorPageContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  selectPrinterConnectionErrorPageMatch,
  (dictionary, match) => {
    // The status 422 indicates that the ContentStack didn't find the entryId
    if (!dictionary || dictionary?.support_pages?.response?.status === 422) {
      return null;
    }
    let csResult = {};
    const checkContentStackData = path([PRINTER_CONNECTION_RESOURCE_NAME, 'data', 'entry'], dictionary);
    if (checkContentStackData !== undefined) {
      csResult.isFetching = false;
      csResult.csData = checkContentStackData;
    } else {
      const checkContentStackDatas = path([PRINTER_CONNECTION_RESOURCE_NAME, 'data', 'entries'], dictionary);
      csResult.isFetching = checkContentStackDatas !== undefined ? undefined : true;
    }
    return csResult;
  }
);

export const selectNewLocalesContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  path([NEW_LOCALE_SELECTOR_RESOURCE_NAME, 'data', 'entries', 0, 'locales'])
);

export const selectNewNavBarContentStackData = createSelector(
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  (dictionary) => {
    const entries = dictionary?.[GLOBAL_NAV_RESOURCE_NAME]?.data?.entries || [];
    return {
      links: entries.find(d => d.uid === ENTRYID_GLOBAL_NAV_LINKS)?.global_nav,
      accountLinks: entries.find(d => d.uid === ENTRYID_GLOBAL_NAV_ACCOUNT_LINKS)?.global_nav,
    };
  }
);

const pickLocaleProps = (localeObj) => ({
  localeText: prop('displayName', localeObj),
  flagIconTitle: prop('flagTitle', localeObj),
  flagIconUrl: prop('flagUrl', localeObj)
});

const protectSelectorCompose = curry((test, composeFn) =>
  cond([
    [test, composeFn],
    [T, always(null)]
  ])
);

export const selectProductCraftData = createSelector(
  selectPrinterEssentialsMatch,
  SELECTORS_BASE.selectCraftDictionary,
  (match, dictionary) => {
    if (!match || !match.params || isEmpty(dictionary)) {
      return {};
    }
    const { id: verboseId, country } = match.params;
    const id = verboseId.replace('-info', '');
    const device = prop(`${PRINTER_ESSENTIALS_PREFIX}${id}`, dictionary);

    return {
      id,
      country,
      device
    };
  }
);

export const selectPrinterCommonContentstackData = createSelector(
  selectPrinterEssentialsMatch,
  SELECTORS_BASE_CONTENT_STACK.selectContentStackDictionary,
  (match, dictionary) => {
    if (!match || !match.params || isEmpty(dictionary)) {
      return {};
    }
    const printerCommon = path([PRINTERS_COMMON_RESOURCE_NAME, 'data', 'entries', 0], dictionary);
    return printerCommon;
  }
);

export const selectProductCreateAccountSectionProps = createSelector(
  selectPrinterEssentialsMatch,
  SELECTORS_BASE.selectCraftDictionary,
  selectPrinterCommonContentstackData,
  selectAuthZLoginUrl,
  selectAuthZCreateUrl,
  selectSignInProps,
  (match, dictionary, printer_common, signinUrl, createUrl, { encodedState }) => {
    if (!match || !match.params || isEmpty(dictionary)) {
      return {};
    }
    const showIILine = pathSatisfies(
      includes(__, INSTANT_INK_COUNTRIES),
      ['params', 'country'],
      match
    );
    const createAccountSection = path(['create_account_section', 0, 'account_section_block'], printer_common);
    if (!createAccountSection) {
      return null;
    }
    const createAcctBtnLink = prop('create_account_button_link', printer_common);
    return {
      title: {
        text: prop('create_account_section_title_text', createAccountSection)
      },
      preamble: prop('enjoy_the_benefits_text', createAccountSection),
      left: {
        source: path(['create_account_icon', 'url'], createAccountSection),
        alt: path(['create_account_icon', 'title'], createAccountSection)
      },
      right: [
        ...(showIILine ? [prop('benefit_one_instant_supplies', createAccountSection)] : []),
        prop('benefit_two', createAccountSection),
        prop('benefit_three', createAccountSection)
      ],
      buttonText: prop('button_text', createAccountSection),
      buttonDestination: determineDest({
        signinUrl,
        createUrl,
        encodedState,
        destination: createAcctBtnLink
      })
    };
  }
);