import React from 'react';
import { HeaderContainer, HeaderContent } from './styles';

const Header = (props) => {
  return (
    <HeaderContainer>
      <HeaderContent isRTL={props.isRTL}>
        <h1>{props.text}</h1>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
