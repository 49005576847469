import React from 'react';
import { AboutSmartAdvanceContainer, AboutSmartAdvanceContent, FeatureTile } from './styles';
import Button from '@veneer/core/dist/scripts/button';
import { Link } from 'react-scroll';

const AboutSmartAdvance = (props) => {
  const { aboutSmartAdvanceData } = props;
  const { aboutSectionBody, aboutSectionTitle, aboutSectionFeature } = aboutSmartAdvanceData;
  const advancedScanFeature = aboutSectionFeature[0]?.feature;
  const shortcutsFeature = aboutSectionFeature[1].feature;

  return (
    <AboutSmartAdvanceContainer id="about-smart-advance" className="about-smart-advance">
      <AboutSmartAdvanceContent>
        <div className="about-section-title">{aboutSectionTitle}</div>
        <div className="about-section-description">{aboutSectionBody}</div>
        <div className="features">
          <FeatureTile>
            <img
              alt={advancedScanFeature.feature_icon.title}
              src={advancedScanFeature.feature_icon.url}
              className="feature-image"
            />
            <div className="feature-title">{advancedScanFeature.feature_title}</div>
            <div className="feature-description">{advancedScanFeature.feature_body}</div>
            <Link
              to={advancedScanFeature.learn_more_destination}
              spy={true}
              smooth={true}
              duration={500}
            >
              <Button appearance="tertiary" className="learn-more">
                {advancedScanFeature.learn_more_link_text}
              </Button>
            </Link>
          </FeatureTile>
          <div className="vector">
            <img alt="" src="/assets/smart-advance/vector.png" />
          </div>
          <FeatureTile>
            <img
              alt={advancedScanFeature.feature_icon.title}
              src={advancedScanFeature.feature_icon.url}
              className="feature-image"
            />
            <div className="feature-title">{shortcutsFeature.feature_title}</div>
            <div className="feature-description">{shortcutsFeature.feature_body}</div>
            <Link
              to={shortcutsFeature.learn_more_destination}
              spy={true}
              smooth={true}
              duration={500}
            >
              <Button appearance="tertiary" className="learn-more">
                {shortcutsFeature.learn_more_link_text}
              </Button>
            </Link>
          </FeatureTile>
        </div>
      </AboutSmartAdvanceContent>
    </AboutSmartAdvanceContainer>
  );
};

export default AboutSmartAdvance;
