import React from 'react';
import {
  ModalContainer,
} from './styles';

export const Modal = ({id, onClose, show, title, footer, children}) => (
  <ModalContainer
    density="high"
    id={id}
    onClose={onClose}
    show={show}
    title={title}
    footer={footer}
  > 
  {children}
  </ModalContainer>    
);

export default Modal;
