import React, { useState } from 'react';
import { PDF_SUPPORTED_LANGUAGES_ARRAY } from '../../constants/i18n';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { IconDownload } from '@veneer/core';
import { LEBI_ST580, LEBI_ST520, LEBI_ST210 } from '../../constants/common';

const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;
const IconStyle = styled.div`
  top: 60%;
  position: fixed;
  padding: 0.7rem;
  background: rgba(0, 150, 214, 0.5);
  border-radius: 0rem 1rem 1rem 0rem;
  box-shadow: 0rem 0.25rem 1rem rgb(34 34 34 / 15%);
`;

const StoreSetup = (props) => {
  const { country, language, product } = props;
  let pdfSrc = `../../assets/pdfs/retailer-setup-${language}.pdf`;
  if (product) {
    if (product == LEBI_ST580) {
      pdfSrc = `../../../assets/pdfs/${LEBI_ST580}-RetailSetup-${language}.pdf`;
    } else if (product == LEBI_ST520) {
      pdfSrc = `../../../assets/pdfs/${LEBI_ST520}-RetailSetup-${language}.pdf`;
    } else if (product == LEBI_ST210) {
      pdfSrc = `../../../assets/pdfs/${LEBI_ST210}-RetailSetup-${language}.pdf`;
    } else {
      const redirect = `/${country}/${language}/`;
      window.location = redirect;
    }
  }

  const [numPages, setNumPages] = useState(null);
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    return 'unknown';
  };
  if (!PDF_SUPPORTED_LANGUAGES_ARRAY.includes(language)) {
    const defaultCountry = 'us';
    const defaultLanguage = 'en';
    const redirect = `/${defaultCountry}/${defaultLanguage}/store-setup`;
    window.location = redirect;
  } else {
    if (getMobileOperatingSystem() === 'Android') {
      {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

        /*When document gets loaded successfully*/
        function onDocumentLoadSuccess({ numPages }) {
          setNumPages(numPages);
        }
        return (
          <>
            <PDFDocumentWrapper>
              <Document file={pdfSrc} loading={false} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page) => (
                    <Page pageNumber={page} loading={false} />
                  ))}
              </Document>
              <a href={pdfSrc}>
                <IconStyle>
                  <IconDownload size={32} />
                </IconStyle>
              </a>
            </PDFDocumentWrapper>
          </>
        );
      }
    } else {
      window.location = pdfSrc;
      return '';
    }
  }
};

export default StoreSetup;
