import { TextBox } from '@veneer/core';
import styled from 'styled-components';

export const FormInline = styled.div`
  display: flex;  
`;

export const EmailItemContainer = styled.div`
  margin: 0;
`;

export const EmailItem = styled(TextBox)`
  margin: 6px auto;

  && {
    border: none;
  }

  .added {
    /* this color and format will be aligned with designed team after */
    color: green;
    font-weight: bold;
  }
`;

export const Header = styled.h4`
  margin-bottom: 8px;
`;

export const HeaderUsers = styled.h4`
  font-size: 18px;
  margin: 10px 0;
  font-weight: bold;
`;
