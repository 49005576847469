import { createSelector } from 'reselect';
import {
  path,
  either,
  pathOr,
  compose,
  map,
  prop,
  head,
  not,
  is,
  pick,
} from 'ramda';

import {
  selectContentStackDictionary,
} from './craft';
import {
  selectAuthZLoginUrl,
  selectAuthZCreateUrl,
  selectHPPlusFeatureFlagEnabled,
} from './config';
import {
  HOME_RESOURCE_NAME
} from '../constants/contentStackResources';
import {
  selectWPPData,
} from './myPrinters';
import { selectSignInProps } from '../components/Dashboard/store/modules/portal/selectors';
import { getCookie } from '../lib/manageCookies';
import { HPC3_SESSION_TYPE } from '../constants/cookieNames';
import { determineDest } from './utils';

const valueInCollection = (collection, val) => {
  if (!val) {
    return false;
  }
  const safeCollection = is(Array, collection)
    ? collection
    : [collection];

  const simplifiedCollection = safeCollection.map(({ value }) => value);
  return simplifiedCollection.includes(val);
};

export const showElement = (entryCollectionKey, geoCountry, obj) => {
  const countrySelector = either(
    path([entryCollectionKey, 0, 'countryFilter']),
    path([entryCollectionKey, 'countryFilter']),
  )(obj);
  if (!countrySelector) {
    return true;
  }
  if (!geoCountry) {
    return false;
  }
  return valueInCollection(countrySelector, geoCountry);
};

export const selectHeroCarouselSlides = createSelector(
  selectContentStackDictionary,
  selectAuthZLoginUrl,
  selectAuthZCreateUrl,
  selectSignInProps,
  (_, prop) => prop, // the selector function is passed `geoCountry` as a prop, so this passes it through to the function below
  (dict, signInURL, createUrl, { encodedState }, { geoCountry, instantInkSupportedCountries }) => {
    const content = path([HOME_RESOURCE_NAME, 'data', 'entries', 0, 'hero_carousel'], dict);
    if (!content) {
      return [];
    }
    return content.reduce((list, slide, index) => {
      const {
        badges = [],
        button_destination,
      } = slide;
      if (!instantInkSupportedCountries.includes(geoCountry) && index === 1) {
        return list;
      }
      const dest = determineDest({
        signInURL,
        createUrl,
        encodedState,
        destination: button_destination,
      });
      return [
        ...list,
        {
          ...slide,
          badges: pathOr(undefined, [0, 'landingPageAppBadges'], badges),
          button_destination: dest,
        },
      ];
    }, []);
  },
);

export const selectVisualNavElements = createSelector(
  selectContentStackDictionary,
  selectAuthZLoginUrl,
  selectAuthZCreateUrl,
  selectSignInProps,
  (dict, signInURL, createUrl, { encodedState }) => {
    const content = pathOr([], [HOME_RESOURCE_NAME, 'data', 'entries', 0, 'visual_navigation'], dict);
    return content.map(ele => {
      const {
        nav_section,
      } = ele;

      const {
        button_destination,
      } = nav_section;

      const dest = determineDest({
        signInURL,
        createUrl,
        encodedState,
        destination: button_destination,
      });

      return {
        ...ele,
        button_destination: dest,
      };
    });
  },
);

export const selectServiceExplainerFeatures = createSelector(
  selectContentStackDictionary,
  dictionary => {
    if (!dictionary || !prop(HOME_RESOURCE_NAME, dictionary)) {
      return null;
    }
    return {
      features: compose(
        map(feature => {
          const {
            feature_details,
          } = feature;

          const {
            header,
            body,
            phone_image,
            phone_image_2x,
          } = feature_details;

          const {
            title: phoneImageTitle,
            url: phoneImageUrl,
          } = phone_image;

          const {
            title: phoneImage2xTitle,
            url: phoneImage2xUrl,
          } = phone_image_2x;

          return {
            header,
            body,
            alt: phoneImageTitle,
            src: [phoneImageUrl, phoneImage2xUrl],
          };
        }),
        path([HOME_RESOURCE_NAME, 'data', 'entries', 0, 'feature']),
      )(dictionary),
      headline: path([HOME_RESOURCE_NAME, 'data', 'entries', 0, 'feature_headline'], dictionary),
    };
  },
);

export const selectFootnotes = createSelector(
  selectContentStackDictionary,
  pathOr('', [HOME_RESOURCE_NAME, 'data', 'entries', 0, 'home_footnote']),
);

export const selectServiceDetails = createSelector(
  selectContentStackDictionary,
  (_, prop) => prop,
  (dictionary) => {
    const home = prop(HOME_RESOURCE_NAME, dictionary);
    if (!home) {
      return null;
    }

    const {
      service,
      service_headline,
      service_subhead,
    } = pick([
      'service_headline',
      'service_subhead',
      'service',
    ], home?.data?.entries[0]);

    const safe_service = not(is(Array, service))
      ? [service]
      : service;

    return {
      service_headline,
      service_subhead,
      safe_service,
    };
  },
);

const safeGetNestedProperty = (propName, object) => pathOr(
  prop(propName)(object),
  ['WPPData', propName],
)(object);

export const selectHomePageProps = createSelector(
  selectWPPData,
  (_, prop) => prop,
  (printers = [], { countryCode, languageCode }) => {
    const userLoggedIn = (getCookie(HPC3_SESSION_TYPE) || '') === 'user';
    if (userLoggedIn) {
      /**
      * Commented for testing purpose on pie. Will revisit on this.
      */
      // if (!printers.length) {
      //   return '/newprinter';
      // }
      // if (printers.length === 1) {
      //   const printer = head(printers);
      //   const ownershipId = safeGetNestedProperty('ownershipId', printer);
      //   const generation = safeGetNestedProperty('generation', printer);
      //   return `/myprinter/${ownershipId}?generation=${generation}`;
      // }
      // if (printers.length) {
      //   return '/myprinters';
      // }

      const hostname = window.location.hostname;
      const port = window.location.port;
      const country = countryCode;
      const language = languageCode;
      window.location.href = `//${hostname}${port ? ':' + port : ''}/${country}/${language}/ucde`;
    }
    return null;
  },
);

export const selectHPPlusProps = createSelector(
  selectContentStackDictionary,
  selectHPPlusFeatureFlagEnabled,
  (_, prop) => prop,
  (dict, isEnabled, geoCountry) => {
    const home = prop(HOME_RESOURCE_NAME, dict);
    if (!isEnabled || !home) {
      // when we want to hide the element, we have to pass an empty object
      // the component cannot early eject on null props because it has hooks that need to fire
      return {};
    }
    const props = pick(
      [
        'hp_plus_button_destination',
        'hp_plus_button_text',
        'hp_plus_headline',
        'hp_plus_services',
        'hp_plus_subhead',
        'hp_plus_countries',
      ],
      home?.data?.entries[0],
    );
    return showElement('hp_plus_countries', geoCountry, props)
      ? props
      : {};
  },
);

export const selectTestimonialsSectionProps = createSelector(
  selectContentStackDictionary,
  pathOr([], [HOME_RESOURCE_NAME, 'data', 'entries', 0, 'testimonials']),
);

export const selectFAQProps = createSelector(
  selectContentStackDictionary,
  (_, props) => props,
  (dict, geoCountry) => {
    const home = prop(HOME_RESOURCE_NAME, dict);
    if (!home) {
      return {
        faq_button_text: '',
        faq_header: '',
        faq_entry: [],
      };
    }
    const props = pick(
      [
        'faq_button_destination',
        'faq_button_text',
        'faq_header',
        'faq_entry',
      ],
      home?.data?.entries[0],
    );

    return {
      ...props,
      faq_entry: props.faq_entry.reduce((accum, entry) => (
        showElement('countries_available', geoCountry, entry)
          ? [...accum, entry]
          : accum
      ),
        []),
    };
  },
);
