import React, { useEffect } from 'react';
import {
  isEmpty,
  not,
} from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import {
  Card,
} from '@veneer/core';
import i18n from '../../utils/i18n';
import Carousel from '../shared-components/Carousel';
import UserDevice from './UserDevice';
import { getDetailedDevices } from '../../store/modules/devices/actions';
import { selectMyPrintersProps } from '../../store/modules/devices/selectors';
import {
  Body,
  LoadingContainer,
  Spinner,
  NoPrinters,
} from './styles';
import {
  CardContainer,
  CardHeader,
} from '../shared-components/styles';
import ServerError from '../shared-components/ServerError';

const DashboardUserDevices = () => {
  const {
    devices,
    lastFetch,
    isFetching,
    isError,
  } = useSelector(selectMyPrintersProps);
  const dispatch = useDispatch();

  async function loadUserDevices() {
    dispatch(getDetailedDevices());
  }

  useEffect(() => {
    if (!lastFetch) {
      loadUserDevices();
    }
  }, [devices]);

  const selectPrinter = event => {
    event.preventDefault();
    dispatch(push('/ucde/my-printers'));
  };

  const loading = (
    <LoadingContainer>
      <Spinner
        id="userDevices_page_spinner"
        src="/assets/print-anywhere/spinner.svg"
      />
    </LoadingContainer>
  );

  const hasPrinters = devices && not(isEmpty(devices));

  const userDevices = hasPrinters
    ? (
      <Carousel swiping={isMobile}>
        {devices.map(device => (
          <UserDevice
            key={`${device.serialNumber}device`}
            device={device}
            selectPrinter={selectPrinter}
          />
        ))}
      </Carousel>
    )
    : (
      <NoPrinters>
        <img
          src="/assets/img/dashboard/generic-printer.svg"
          alt={i18n.t('myPrinters.noPrintersInList.message')}
        />
        {i18n.t('myPrinters.noPrintersInList.message')}
      </NoPrinters>
    );

  const CardContent = () => {
    let content = userDevices;

    if (isFetching) {
      content = loading;
    } else if (isError) {
      content = <ServerError direction="column" />;
    }

    return (
      <CardContainer data-testid="user-devices-card">
        <CardHeader>
          <h5>{i18n.t('home.myPrintersCard.header')}</h5>
          <Link to="/ucde/my-printers">{i18n.t('home.myPrintersCard.viewPrinters')}</Link>
        </CardHeader>
        <Body>
          {content}
        </Body>
      </CardContainer>
    );
  };

  return (
    <Card
      appearance="dropShadow"
      content={CardContent()}
    />
  );
};

export default DashboardUserDevices;
