import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import OrigamiFootnotes from '../OrigamiFootnotes';
import SkeletonFootnotes from '../Skeleton/SkeletonFootnotes';
import { StyledSection } from '../styles/StyleOrigami';
import { SkeletonContainer } from '../styles/StyleSkeleton';

const propTypes = {
  footnotesText: PropTypes.string.isRequired
};

const defaultProps = {
  footnotesText: ''
};

const OrigamiFootNotesContent = (props) => {
  const { footnotesText } = props;
  return (
    <StyledSection>
      <OrigamiFootnotes footnotesText={footnotesText} />
    </StyledSection>
  );
};

const OrigamiFootNotesPromise = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          default: (props) => {
            const { footnotesText } = props;
            return <OrigamiFootNotesContent footnotesText={footnotesText} />;
          }
        });
      }, 1000);
    })
);

const OrigamiLazyFootNotes = (props) => {
  const { footnotesText } = props;
  return (
    <Suspense
      fallback={
        <SkeletonContainer>
          <SkeletonFootnotes />
        </SkeletonContainer>
      }
    >
      <OrigamiFootNotesPromise footnotesText={footnotesText} />
    </Suspense>
  );
};

OrigamiLazyFootNotes.propTypes = propTypes;

OrigamiLazyFootNotes.defaultProps = defaultProps;

export default OrigamiLazyFootNotes;
