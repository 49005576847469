import React, { Component } from 'react';
import CarePack from './CarePack';
import InstantInkEligible from './InstantInkEligible';
import InstantInkSubscription from './InstantInkSubscription';
import ARSSubscription from './ARSSubscription';
import HPSureSupply from './HPSureSupply';
import bind from './../../bind';
import { filter_ii_countries } from '../../../lib/filter_ii_countries';
import { getLocale } from '../../../utils/globals';
import { CAREPACK_SUPPORTED_COUNTRIES } from '../../../constants/i18n';

class EprintInk extends Component {
  constructor(props) {
    super(props);
    this.state = { groupControl: { identifier: '', group: '' } };
    const { country } = getLocale();
    this.country = country;
  }

  showCarePack() {
    const REFURBISHED_CODE = 457;
    const EOSL_CODE = 460;
    // const START_DATE_CODE = 316

    const isFeatureFlag =
      this.props.config.data.GetFeatureFlag('ff_carepack_enabled').toLowerCase() === 'true';
    const carepackLogic = !!this.props.myPrinter.data.WPPData.carepack
      ? this.props.myPrinter.data.WPPData.carepack
      : false;

    const carepackScenario =
      !!this.props.myPrinter.data.carepack.scenarios &&
      Array.isArray(this.props.myPrinter.data.carepack.scenarios) &&
      this.props.myPrinter.data.carepack.scenarios.length === 1;
    // Error codes coming through carepackLogic.error.code are not exhaustively known, therefore we cannot reliably whitelist all of them, thus disabling this condition:
    const errorCodeException =
      !!carepackLogic && !!carepackLogic.error && !!carepackLogic.error.code
        ? carepackLogic.error.code === EOSL_CODE || carepackLogic.error.code === REFURBISHED_CODE
        : false;
    const isCarePack = !!carepackLogic || carepackScenario || errorCodeException;
    const isCarepackSupportedCountries = CAREPACK_SUPPORTED_COUNTRIES.includes(this.country);

    return isFeatureFlag && isCarePack && isCarepackSupportedCountries && !!carepackScenario;
  }

  isLocaleFiltered_ii = () => {
    return !filter_ii_countries(this.props.locales.countryCode);
  };

  showInstantInkEligible() {
    const localeOK = this.isLocaleFiltered_ii();
    let printer = this.props.myPrinter.data.WPPData;
    return (
      !printer.isInstantInkSubscribed &&
      !printer.subscribedInARS &&
      printer.isInstantInkCapable &&
      localeOK
    );
  }

  showInstantInkManagement() {
    const localeOK = this.isLocaleFiltered_ii();
    return this.props.myPrinter.data.WPPData.isInstantInkSubscribed && localeOK;
  }

  showARSManagement() {
    ////////// STILL NEEDS TO BE TESTED WHEN DATA IS IN PLACE
    return this.props.myPrinter.data.WPPData.subscribedInARS;
  }

  showHPSureSupply() {
    return false;
  }

  trackingHandler(ID) {
    document.getElementById(ID).click();
  }

  groupFn = (o) => {
    this.setState({ groupControl: { identifier: o.myID, group: o.groupID } });
  };

  render() {
    const { printAnywhereWebIsEnabled } = this.props;
    const miscellaneousData = this.props.myPrinter.data.miscellaneousData;
    const accordionAttrs = {
      printer: this.props.myPrinter.data,
      groupFn: this.groupFn,
      controler: this.state.groupControl,
      trackingHandler: this.trackingHandler
    };
    const showCarePack = this.showCarePack();
    const showHPSureSupply = this.showHPSureSupply();
    const showARSManagement = this.showARSManagement();
    const showInstantInkEligible = this.showInstantInkEligible();
    const showInstantInkManagement = this.showInstantInkManagement();
    let containsItems =
      showCarePack ||
      showHPSureSupply ||
      showARSManagement ||
      showInstantInkEligible ||
      showInstantInkManagement;
    if (containsItems) {
      return (
        <div className="myPrinterSection myPrinterSection--ink">
          <h3 className="myPrinterSection--title">{miscellaneousData.ink_settings_title}</h3>
          {showCarePack && (
            <CarePack {...accordionAttrs} printAnywhereWebIsEnabled={printAnywhereWebIsEnabled} />
          )}
          {showInstantInkEligible && (
            <InstantInkEligible
              {...accordionAttrs}
              printAnywhereWebIsEnabled={printAnywhereWebIsEnabled}
            />
          )}
          {showInstantInkManagement && <InstantInkSubscription {...accordionAttrs} />}
          {showARSManagement && <ARSSubscription {...accordionAttrs} />}
          {showHPSureSupply && <HPSureSupply {...accordionAttrs} />}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default bind(EprintInk);
