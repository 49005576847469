import * as location from './location';
import * as device from './device';
import * as eprintLanding from './eprintLanding';
import * as home from './home';
import * as gnb from './gnb';
import * as userInfo from './userInfo';
import * as config from './config';
import * as termsOfUse from './termsOfUse';
import * as terms from './terms';
import * as contact from './contact';
import * as contactEntries from './contactEntries';
import * as libraryLanding from './libraryLanding';
import * as libraryVisualNav from './libraryVisualNav';
import * as librarySection from './librarySection';
import * as libraryTopic from './libraryTopic';
import * as librarySubject from './librarySubject';
import * as libraryLink from './libraryLink';
import * as mobileFax from './mobileFax';
import * as printerCommon from './printerCommon';
import * as myPrinters from './myPrinters';
import * as myPrinter from './myPrinter';
import * as notifications from './notificationsAction';
import * as printAnywhereActions from './printAnywhereActions';
import * as userPrograms from './userPrograms';

let deviceActions = {
    ...location,
    ...device,
    ...eprintLanding,
    ...home,
    ...userInfo,
    ...contact,
    ...contactEntries,
    ...config,
    ...termsOfUse,
    ...terms,
    ...libraryLanding,
    ...libraryVisualNav,
    ...librarySection,
    ...libraryTopic,
    ...librarySubject,
    ...libraryLink,
    ...mobileFax,
    ...printerCommon,
    ...myPrinters,
    ...myPrinter,
    ...gnb,
    ...notifications,
    ...printAnywhereActions,
    ...userPrograms
};

export { deviceActions };
