import styled from 'styled-components';
import { Link } from '../../../routing';
import { UCDEMainTheme } from '../../pages/Dashboard/styles';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    color: #464D50;
    font-weight: bold;
    font-size: 24px;
  }
`;

export const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #027AAE;
  font-weight: bold;
  font-size: 14px;
`;

export const Body = styled.div`
  margin: 0;
  text-align: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Spinner = styled.img`
  width: 48px;
  height: 48px;
  @keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }
  animation: spin .5s linear infinite;
`;

export const NoPrinters = styled.div`
  && {
    font-family: ${UCDEMainTheme.main.hp.fontFamily};
    font-size: 12px;
    padding: 0;
    text-align: center;

    > img {
      display: block;
      margin: auto auto 32px;
    }

    @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
      font-family: ${UCDEMainTheme.main.hp.fontFamilyLight};
      font-size: 16px;
    }
  }
`;
