import React from 'react';
import {
  is,
} from 'ramda'
import {
  Body,
  ImageWrapper,
  ImageCaption,
  Image,
} from './PictureAndText.styles';

const Side = ({
  text,
  alt,
  source,
  main,
  caption,
}) => {
  if (text) {
    return (
      <Body main={main}>
        {
          (is(String, text) && text.match(/<[a-z]+>/))
            ? <span dangerouslySetInnerHTML={{ __html: text }} />
            : text
        }
      </Body>
    );
  }

  return (
    <ImageWrapper main={main}>
      {
        caption && (
          <ImageCaption>
            {caption}
          </ImageCaption>
        )
      }
      <Image
        src={source}
        alt={alt}
        main={main}
      />
    </ImageWrapper>
  );
};

export default Side;
