import React from 'react';

import {
  AnimatedSection,
  PricingLoadingSection,
  PricingBlueContainerLoadingSection,
  PricingValueContainerLoadingSection,
  PricingSingleContainerLoadingSection
} from '../styles/StyleSkeleton';

const SkeletonPricing = () => {
  return (
    <PricingLoadingSection>
      <PricingBlueContainerLoadingSection>
        <AnimatedSection height="50px" width="150px" margin="15px" />
        <AnimatedSection height="20px" width="350px" margin="10px" />
      </PricingBlueContainerLoadingSection>
      <PricingValueContainerLoadingSection>
        <PricingSingleContainerLoadingSection>
          <AnimatedSection height="0px" margin="5px" />
          <AnimatedSection height="20px" width="60%" margin="5px" />
          <AnimatedSection height="15px" width="80%" margin="10px" />
          <AnimatedSection height="40px" margin="20px" />
          <AnimatedSection height="15px" width="85%" margin="5px" />
        </PricingSingleContainerLoadingSection>
        <PricingSingleContainerLoadingSection>
          <AnimatedSection height="0px" margin="5px" />
          <AnimatedSection height="20px" width="60%" margin="5px" />
          <AnimatedSection height="15px" width="80%" margin="10px" />
          <AnimatedSection height="40px" margin="20px" />
          <AnimatedSection height="15px" width="85%" margin="5px" />
        </PricingSingleContainerLoadingSection>
        <PricingSingleContainerLoadingSection>
          <AnimatedSection height="0px" margin="5px" />
          <AnimatedSection height="20px" width="60%" margin="5px" />
          <AnimatedSection height="15px" width="80%" margin="10px" />
          <AnimatedSection height="40px" margin="20px" />
          <AnimatedSection height="15px" width="85%" margin="5px" />
        </PricingSingleContainerLoadingSection>
        <PricingSingleContainerLoadingSection>
          <AnimatedSection height="0px" margin="5px" />
          <AnimatedSection height="20px" width="60%" margin="5px" />
          <AnimatedSection height="15px" width="80%" margin="10px" />
          <AnimatedSection height="40px" margin="20px" />
          <AnimatedSection height="15px" width="85%" margin="5px" />
        </PricingSingleContainerLoadingSection>
        <PricingSingleContainerLoadingSection>
          <AnimatedSection height="0px" margin="5px" />
          <AnimatedSection height="20px" width="60%" margin="5px" />
          <AnimatedSection height="15px" width="80%" margin="10px" />
          <AnimatedSection height="40px" margin="20px" />
          <AnimatedSection height="15px" width="85%" margin="5px" />
        </PricingSingleContainerLoadingSection>
      </PricingValueContainerLoadingSection>
    </PricingLoadingSection>
  );
};

export default SkeletonPricing;
