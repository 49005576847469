export const dispatchJWebClose = () => {
  try {
    const { Eventing } = JWeb.Plugins;
    Eventing.dispatchEvent({ name: 'Close', data: {} });
  } catch (err) {
    console.error(`Error dispatching Close event to JWeb:\n${err}`);
  }
};

export default dispatchJWebClose;
