import React, { Component } from 'react';
import { TweenLite } from 'gsap';
import Accordion from './../shared-components/organisms/Accordion'
import { SubAccordion, StyledSection, SubArrows} from '../shared-components/library-css/LibraryCss';
import { wirelessPrintingTracking } from './../../lib/analyticsTracking'
import bind from "../bind";

class WirelessMenuContent extends Component {
  constructor(props) {
    super(props)
    this.deeplinkTopic = !!props.deeplinkTopic ? props.deeplinkTopic.toLowerCase() : false
    this.myID = `accordion_${this.props.ID}`
    this.section_id = this.props.ID
    this.isOpen = ''
    this.state = {
      groupControl: { identifier: "", group: "" },
    }
    this.groupFunction = this.groupFunction.bind(this)
    this.callbackFn = this.callbackFn.bind(this)
    this.deepLinkFn = this.deepLinkFn.bind(this)
    this.updateAddressBar = this.updateAddressBar.bind(this)
    this.do_once = [0]
  }

  initDeepLink = () => {
    const deeplink = this.deeplinkTopic + "_subheader"
    let do_once = this.do_once
    const deepLinkFn = this.deepLinkFn
    let DOIT = false
    for (let i = 0; i < this.props.section.length; i++) {
      if (this.props.section[i].uid === this.deeplinkTopic) {
        DOIT = true
      }
    }
    function tryDeeplink() {
      let exists = document.getElementById(deeplink)
      if (!!exists) {
        if (do_once[0] < 1) {
          do_once[0]++
          setTimeout(
            deepLinkFn,
            600
          )
        }
      }
    }
    if (DOIT) {
      setTimeout(
        tryDeeplink,
        1200
      )
    }
  }

  componentDidMount() {
    this.initDeepLink()
  }

  componentDidUpdate() {
    this.initDeepLink()
  }

  deepLinkFn() {
    const deeplink = this.deeplinkTopic + "_subheader"
    const deeplink_exists = document.getElementById(deeplink)
    if (deeplink_exists) {
      deeplink_exists.click()
    }
  }

  groupFunction = o => {
    this.setState({
      groupControl: { identifier: o.myID, group: o.groupID }
    });
  };

  updateAddressBar(thisID) {
    const search = window.location.search
    const window_location_array = window.location.href.split("?")[0].toLowerCase().split("/");
    const indexOfWirelesPrintingSegmentRoot = window_location_array.indexOf("wireless-printing");
    window_location_array.length = indexOfWirelesPrintingSegmentRoot + 2
    window_location_array.push(thisID)
    const strNewPath = window_location_array.join("/") + search
    if (window.history.pushState) {
      window.history.replaceState({ foo: "bar" }, "page two", strNewPath)
    }
  }

  callbackFn = (isOpen, thisID) => {
    if (isOpen) {
      wirelessPrintingTracking(thisID)
    }
    const oneZero = isOpen ? 1 : 0
    const icn = `#${thisID}_arrow`;
    TweenLite.to(icn, .41, { rotationX: -180 * oneZero });
    if (isOpen) {
      this.setState({ activeItem: thisID });
      this.updateAddressBar(thisID)
    } else {
      this.setState({ activeItem: "none" });
      this.updateAddressBar("")
    }
  };

  slugify(str = "") {
    if (typeof str === "string") {
      str = str.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')
      return str
    }
  }

  render() {
    let subAccordionsOpen = false;

    return (
      <div>
        {this.props.section &&
          this.props.section.map?.(section => {
            const { uid, _content_type_uid } = section;
            const library_subject = this.props.subjects.filter((data) => data.uid.includes(uid));
            const {
              uid: subjectUid,
              title: subjectTitle,
              library_content: subjectContent
            } = _content_type_uid == "library_subject" && library_subject[0];
            return (
              <StyledSection isMobile={this.props.isMobile} key={"key" + uid}>

                {!!subjectContent && subjectContent.length > 0 && (
                  <SubAccordion isMobile={this.props.isMobile} currentPlatform={this.props.currentPlatform}>
                    <a id={`${subjectUid}_subheader`}>
                      <SubArrows>
                        <img id={`${subjectUid}_arrow`} src="/assets/img/open_closed_arrow.svg" alt="" />
                      </SubArrows>
                      {subjectTitle}
                    </a>

                    <Accordion
                      ID={`${subjectUid}`}
                      actionID={`${subjectUid}_subheader`}
                      groupID={"subGroups"}
                      groupFn={this.groupFunction}
                      controler={this.state.groupControl}
                      callbackFn={this.callbackFn}
                      isSubAccordionOpen={subAccordionsOpen}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${subjectContent[0]?.library_content_text_block?.library_content_text}`
                        }}
                      />
                    </Accordion>
                  </SubAccordion>
                )}

              </StyledSection>
            );
          })
        }
      </div>
    )
  }
}

export default bind(WirelessMenuContent)