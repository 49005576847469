import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import OrigamiHero from '../OrigamiHero';
import SkeletonHero from '../Skeleton/SkeletonHero';
import { StyledSection } from '../styles/StyleOrigami';
import { SkeletonContainer } from '../styles/StyleSkeleton';

const propTypes = {
  widescreenPosterTitle: PropTypes.string.isRequired,
  widescreenPosterUrl: PropTypes.string.isRequired,
  mobilePosterTitle: PropTypes.string.isRequired,
  mobilePosterUrl: PropTypes.string.isRequired,
  heroHeading: PropTypes.string.isRequired,
  heroSubheading: PropTypes.string.isRequired,
  addPaperButtonText: PropTypes.string.isRequired,
  enrollLinkText: PropTypes.string.isRequired,
  enrollLinkChevronTitle: PropTypes.string.isRequired,
  enrollLinkChevronUrl: PropTypes.string.isRequired,
  changeEnrollText: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleEnrollLink: PropTypes.func.isRequired
};

const defaultProps = {
  widescreenPosterTitle: '',
  widescreenPosterUrl: '',
  mobilePosterTitle: '',
  mobilePosterUrl: '',
  heroHeading: '',
  heroSubheading: '',
  addPaperButtonText: '',
  enrollLinkText: '',
  enrollLinkChevronTitle: '',
  enrollLinkChevronUrl: '',
  changeEnrollText: ''
};

const OrigamiBannerContent = (props) => {
  const {
    widescreenPosterTitle,
    widescreenPosterUrl,
    mobilePosterTitle,
    mobilePosterUrl,
    heroHeading,
    heroSubheading,
    addPaperButtonText,
    enrollLinkText,
    enrollLinkChevronTitle,
    enrollLinkChevronUrl,
    changeEnrollText,
    handleClick,
    handleEnrollLink
  } = props;
  return (
    <StyledSection className="mobile">
      <OrigamiHero
        widescreenPosterTitle={widescreenPosterTitle}
        widescreenPosterUrl={widescreenPosterUrl}
        mobilePosterTitle={mobilePosterTitle}
        mobilePosterUrl={mobilePosterUrl}
        heroHeading={heroHeading}
        heroSubheading={heroSubheading}
        addPaperButtonText={addPaperButtonText}
        enrollLinkText={enrollLinkText}
        enrollLinkChevronTitle={enrollLinkChevronTitle}
        enrollLinkChevronUrl={enrollLinkChevronUrl}
        changeEnrollText={changeEnrollText}
        handleClick={handleClick}
        handleEnrollLink={handleEnrollLink}
      />
    </StyledSection>
  );
};

const OrigamiBannerPromise = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          default: (props) => {
            const {
              widescreenPosterTitle,
              widescreenPosterUrl,
              mobilePosterTitle,
              mobilePosterUrl,
              heroHeading,
              heroSubheading,
              addPaperButtonText,
              enrollLinkText,
              enrollLinkChevronTitle,
              enrollLinkChevronUrl,
              changeEnrollText,
              handleClick,
              handleEnrollLink
            } = props;
            return (
              <OrigamiBannerContent
                widescreenPosterTitle={widescreenPosterTitle}
                widescreenPosterUrl={widescreenPosterUrl}
                mobilePosterTitle={mobilePosterTitle}
                mobilePosterUrl={mobilePosterUrl}
                heroHeading={heroHeading}
                heroSubheading={heroSubheading}
                addPaperButtonText={addPaperButtonText}
                enrollLinkText={enrollLinkText}
                enrollLinkChevronTitle={enrollLinkChevronTitle}
                enrollLinkChevronUrl={enrollLinkChevronUrl}
                changeEnrollText={changeEnrollText}
                handleClick={handleClick}
                handleEnrollLink={handleEnrollLink}
              />
            );
          }
        });
      }, 1000);
    })
);

const OrigamiLazyBanner = (props) => {
  const {
    widescreenPosterTitle,
    widescreenPosterUrl,
    mobilePosterTitle,
    mobilePosterUrl,
    heroHeading,
    heroSubheading,
    addPaperButtonText,
    enrollLinkText,
    enrollLinkChevronTitle,
    enrollLinkChevronUrl,
    changeEnrollText,
    handleClick,
    handleEnrollLink
  } = props;
  return (
    <Suspense
      fallback={
        <SkeletonContainer>
          <SkeletonHero />
        </SkeletonContainer>
      }
    >
      <OrigamiBannerPromise
        widescreenPosterTitle={widescreenPosterTitle}
        widescreenPosterUrl={widescreenPosterUrl}
        mobilePosterTitle={mobilePosterTitle}
        mobilePosterUrl={mobilePosterUrl}
        heroHeading={heroHeading}
        heroSubheading={heroSubheading}
        addPaperButtonText={addPaperButtonText}
        enrollLinkText={enrollLinkText}
        enrollLinkChevronTitle={enrollLinkChevronTitle}
        enrollLinkChevronUrl={enrollLinkChevronUrl}
        changeEnrollText={changeEnrollText}
        handleClick={handleClick}
        handleEnrollLink={handleEnrollLink}
      />
    </Suspense>
  );
};

OrigamiLazyBanner.propTypes = propTypes;

OrigamiLazyBanner.defaultProps = defaultProps;

export default OrigamiLazyBanner;
