import React, { useState } from 'react';
import styled from 'styled-components';
import { ContextualMenu, Button, IconChevronUp, IconChevronDown } from '@veneer/core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useSelector } from '../../../hooks';
import { selectLocaleSelectorProps } from '../../../selectors/global';
import { determineLocalePath } from '../../../utils/routing';
import { updateLocale } from '../../../actions/location';
import { paths, stylesIf } from '../../../utils/styleHelpers';
import { getIsFetchHPPlusCountry } from '../../../actions/location';
import { RTL } from '../../../constants/i18n';

const StyledSelect = styled(ContextualMenu)`
  &&&& {
    div {
      div {
        ul {
          li {
            ${props => props.direction === RTL && 'direction: rtl;'}
            ${props => props.direction === RTL && 'unicode-bidi: plaintext;'}
          }
        }
      }
    }
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    border: none;
    color: ${paths.colorsNearDark};
    font-family: ${paths.fontsFamily};
    padding: ${stylesIf('mobile', '11px 11px 11px 0', '11px')};
    height: 2.875rem;
    min-width: 10rem;
    width: 100%;
    justify-content: space-between;
    :hover {
      background-color: transparent;
    }
    svg {
      fill: currentColor;
    }
    @media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-right: ${props => props.direction === RTL && '56rem'};
    }
  }
`;

const LocaleSelector = ({ mobile, direction }) => {
  const { languages, currentLanguage, location, selectedValue } = useSelector(selectLocaleSelectorProps);
  const [open, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  if (!languages.length) {
    return null;
  }

  const handleChange = (event, option) => {
    const { value } = option;
    const newPath = determineLocalePath({
      location,
      externalLocale: value
    });
    dispatch(updateLocale(value)); // TODO: not sure if this is needed :| it relates to legacy locale logic
    dispatch(push(newPath));
    const countryCode = value.split('_')[1];
    dispatch(getIsFetchHPPlusCountry(countryCode));
    setIsOpen(false);
  };

  const handleOpenClose = (event) => {
    setIsOpen(!open);
  };

  return (
    <StyledSelect
      id={`locale-selector${open ? '--open' : ''}`}
      open={open}
      selectedValue={selectedValue}
      options={languages}
      onClick={handleChange}
      onClose={handleOpenClose}
      portal={false}
      placement="bottom-end"
      direction={direction}
    >
      <StyledButton
        direction={direction}
        mobile={mobile}
        onClick={handleOpenClose}
        trailingIcon={open ? <IconChevronUp /> : <IconChevronDown />}
        appearance="secondary"
      >
        {currentLanguage}
      </StyledButton>
    </StyledSelect>
  );
};

export default LocaleSelector;