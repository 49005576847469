import React from 'react';
import { SupportContainer, PairingURL, SupportText } from './styles';

const Support = (props) => {
  return (
    <React.Fragment>
      <SupportContainer>
        <img alt={props.pairingImageAlt} src={props.pairingImage} />
        <PairingURL dangerouslySetInnerHTML={{ __html: props.pairingURL }} />
      </SupportContainer>
      <SupportText dangerouslySetInnerHTML={{ __html: props.supportText }} />
    </React.Fragment>
  );
};

export default Support;
