import styled from 'styled-components';
import { breakpoints } from '../../../../utils/styleHelpers';

export const SACContainerDesktop = styled.div`
  background: #f4f4f4;
  color: #212121;
  padding: 2rem 7rem 10rem 5rem;
  margin: -2.25rem;
  width: 100vw;
  @media (min-width: ${breakpoints.immensewide}) {
    background: #f4f4f4;
    color: #212121;
    padding: 2rem 7rem 10rem 7rem;
    width: 100vw;
    margin-bottom: 0rem;
    margin-left: calc((100vw - 163rem) / 2);
    margin-right: ${props => props.isRTLSupport && '0px'};
  }
`;

export const SACContainerMobile = styled.div`
  padding-top: 1.25rem;
  color: #212121;
`;

export const FootnoteMob = styled.div`
  text-align: center;
  margin: 10%;
  font-size: 0.875rem;
  color: #212121;
`;

export const FootnoteDesktop = styled.div`
  text-align: center;
  font-size: 0.875rem;
  padding-top: 1.5rem;
  padding-bottom: 6.25rem;
  color: #212121;
`;
