import React from 'react';
import { useSelector } from 'react-redux';
import { MobileView } from 'react-device-detect';
import { JarvisInboxNotifications } from '@jarvis/react-atlas-notifications';
import i18n from '../../Dashboard/utils/i18n';
import {
  CloseButton,
  HeaderContainer,
  RightArea,
  CenterArea,
} from './styles';
import ProfileMenu from './ProfileMenu';
import PageTitle from '../../Dashboard/components/PageTitle';
import {
  dispatchJWebCloseEvent,
  getStratusAccessToken,
  isJWebAndroidApp,
  isJWebiOSApp,
} from '../../Dashboard/utils/auth';
import { getExchangeToken } from '../../Dashboard/api/HPCSession';
import { selectNotificationProps } from '../../Dashboard/store/modules/notification/selector';
import FeatureFlag from '../../Dashboard/components/FeatureFlag';
import {
  onClickActionFunction,
  shouldShowAction,
} from '../../Dashboard/components/shared-components/NotificationActionComponent';

const TopNav = () => {
  const {
    country,
    language,
    baseUrl,
  } = useSelector(selectNotificationProps);

  const authProvider = {
    getAccessToken: async forceRefresh => {
      const token = getStratusAccessToken();
      if (forceRefresh) {
        await getExchangeToken(token);
        return getStratusAccessToken();
      }
      return token;
    },
  };

  const headerRightContent = () => {
    if (isJWebiOSApp()) {
      return (
        <>
          <CloseButton
            onClick={dispatchJWebCloseEvent}
          >
            {i18n.t('home.topNav.buttons.close')}
          </CloseButton>
        </>
      );
    }

    if (isJWebAndroidApp()) {
      return (
        <>
        </>
      );
    }

    return (
      <>
        <FeatureFlag featureFlagKey="ff_ucde_portal_notifications">
          <JarvisInboxNotifications
            country={country}
            language={language}
            authProvider={authProvider}
            baseUrl={baseUrl}
            shouldShowAction={shouldShowAction()}
            onClickActionFunction={onClickActionFunction()}
          />
        </FeatureFlag>
        <div
          data-testid="avatar-container"
        >
          <ProfileMenu />
        </div>
      </>
    );
  };

  return (
    <HeaderContainer
      data-testid="portal-top-menu"
      position="fixed"
      centerArea={(
        <CenterArea>
          <MobileView>
            <PageTitle />
          </MobileView>
        </CenterArea>
      )}
      rightArea={(
        <RightArea>
          {headerRightContent()}
        </RightArea>
      )}
    />
  );
};

export default TopNav;
