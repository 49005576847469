import {
  takeEvery,
  put,
  call,
  select,
} from 'redux-saga/effects';
import { pathEq } from 'ramda';
import ActionTypes from '../constants/ActionTypes';
import {
  addToAsyncArray,
  removeFromAsyncArray,
  apiCallFailed,
} from '../actions/application';
import {
  selectSignalIsInSet,
} from '../selectors/application';

// callback moved out of `finally` block to control it _not_ being called
// in the case of a 403

function* makeApiCall(action) {
  const {
    asyncFn,
    args,
    callback,
    successActionCreator,
    failureActionCreator,
  } = action;
  yield put(addToAsyncArray(successActionCreator.toString()));

  try {
    const { data } = yield call(asyncFn, args);
    yield put(successActionCreator({ data, ...args }));
    if (callback) {
      callback();
    }
  } catch (err) {
    if (pathEq(['response', 'status'], 403, err)) {
      yield put({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
    } else {
      if (failureActionCreator) {
        yield put(failureActionCreator(err));
      }
      yield put(apiCallFailed(action, err));
      if (callback) {
        callback();
      }
    }
  } finally {
    yield put(removeFromAsyncArray(successActionCreator.toString()));
  }
}

function* preventRedundantCalls(action) {
  const { successActionCreator } = action;

  const signalIsInSet = yield select(selectSignalIsInSet, successActionCreator.toString());

  if (!signalIsInSet) {
    yield makeApiCall(action);
  }
}

export default function* watchApiCallActions() {
  yield takeEvery(ActionTypes.API_CALL, preventRedundantCalls);
}
