/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  path,
} from 'ramda';
import { Footer } from '@veneer/core';
import { Link } from '../../routing';

export const StyledLink = styled(Link)`
  color: #404040;
  text-decoration: none;
  :hover {
    color: #404040;
    text-decoration: none;
  }
`;

export const StyledFooter = styled(Footer)`
  &&&& {
    > div {
      background-color: transparent;
      border: none;
      flex-wrap: wrap;
      > div {
        :last-child {
          display: none;
        }
        :first-child {
          order: 2;
        }
        display: flex;
        order: 1;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
    }
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 14px;
    color: #464D50;
    .vn-footer__link {
      color: #464D50;
      font-size: 14px;
    }
    ul {
      margin-bottom: 0;
    }

    ${props => (props.theme.hostIsDesktopApp ? 'margin-left: 262px;' : '')};

    @media (min-width: 1200px) {
      margin-left: 262px;
    }

    @media (min-width: ${path(['theme', 'breakPoints', 'xg'])}) {
      margin-left: 262px;
      > div > div {
        width: initial;
        :last-child {
          display: flex;
        }
        :first-child {
          order: 0;
        }
      }
    }
  }
`;
