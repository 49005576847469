import React from 'react';
import { prop } from 'ramda';
import { useSelector } from '../../hooks';
import {
  selectContentStackDataByResource,
} from '../../selectors/craft';
import {
  StyledContainer,
  StyledContent,
  StyledTitle,
  PsTelemetryDiv
} from './PsTelemetry.styles.js';
import { RTL_LANGUAGES } from '../../constants/i18n';
import { getLocale } from '../../utils/globals';
import { PS_TELEMETRY_RESOURCE_NAME } from '../../constants/contentStackResources';
import NavBar from '../revised/Global/Navbar';
import Footer from '../revised/Global/Footer';
import './PsTelemetry.css';

const PsTelemetry = () => {
  const { hp_telemetry } = useSelector(selectContentStackDataByResource, [PS_TELEMETRY_RESOURCE_NAME]);
  const hpTelemetry = hp_telemetry?.data?.entry;
  
  const { language } = getLocale();
  const isRTL = RTL_LANGUAGES.includes(language);

  if (hpTelemetry && !hpTelemetry.isError) {
    const { headline, document_body } = hpTelemetry;

    return (
      <PsTelemetryDiv data-testid="ps-telemetry">
        <NavBar isDataNotice={true} />
        <StyledContainer isRTL={isRTL}>
          <StyledContent>
            <StyledTitle>{headline}</StyledTitle>
            <div dangerouslySetInnerHTML={{ __html: document_body }} />
          </StyledContent>
        </StyledContainer>
        <Footer isDataNotice={true} />
      </PsTelemetryDiv>
    );
  }

  if (prop('isError', hpTelemetry)) {
    return <p>{prop('errorMsg', hpTelemetry)}</p>;
  } // Case: We're loading content
  return <div />;
};

export default PsTelemetry;
