import React from 'react';
import styled from 'styled-components';

const Footnotes = styled.div`
  width: 100%;
  ul {
    max-width: 80rem;
    list-style-type: none;
    padding: 1rem 1rem 0;
    > * + * { padding-top: 1.5rem; }
    @media screen and (min-width:${props => props.theme.medium}) {
      padding: 2.625rem 1rem 0;
    }
  }
  li {
    ${props => props.theme.boldFontFamilyAndWeight}
    font-size: .75rem;
    color: #4e4e4e;
    line-height: 1.83;
    word-wrap: break-word;
    a { 
      text-decoration: none;
      &:hover, &:focus {
        color: ${props => props.theme.hpBlue};
        text-decoration: none;
      }
    }
    @media screen and (min-width:${props => props.theme.medium}) {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
  margin: ${props => props.theme.standardSectionMargins};
`
export default function LandingPageFootnotes(props) {
  return (
    <Footnotes dangerouslySetInnerHTML={{ __html: props.footnotes }} />
  )
}
