import React, { Component } from 'react';
import { path, propOr } from 'ramda';
import styled, { ThemeProvider } from 'styled-components';
import AccessOptions from './AccessOptions';
import { MainContent } from '../shared-components/library-css/LibraryCss';
import bind from '../bind';
import NewAccordion from '../shared-components/organisms/NewAccordion';
import PrinterAddressSettings from './PrinterAddressSettings';
import ToastMessage from './ToastMessage';
// import deBounce from '../../lib/deBounce'

class PrintAnwhereWeb extends Component {
  constructor(props) {
    super(props);
    this.breakpoint = 1024;
    this.state = {
      isMobile: window.innerWidth < this.breakpoint,
      activeOption: 0,
      triggerToast: 0,
    };
    this.handleChoice = this.handleChoice.bind(this);
    this.accordionContainer_manage = React.createRef();
    this.accordionContainer_email = React.createRef();
    this.isSummary = false;
    this.isManageEmail = false;
    this.isPending = true;
    this.doneOnce = false;
    this.getOwnershipId = this.getOwnershipId.bind(this);
    this.ownershipIdFromUrl = this.getOwnershipId();
    this.platform = 'web';
    this.accessList_snapshot = 'init';
    this.accessList_snapshot_length = 'init';
    this.showModal = false;
    this.toastMessage = '';
    this.triggerAnimateCancel = false;
    this.resizeLayout = this.resizeLayout.bind(this);
    window.addEventListener('resize', this.resizeLayout);
  }

  componentDidMount() {
    const {
      router,
      locales,
      getMyPrintAnywherePrinter,
    } = this.props;
    getMyPrintAnywherePrinter(
      this.ownershipIdFromUrl,
      path(['location', 'query', 'generation'], router),
      propOr('', 'currentLocale', locales),
    );
  }

  resizeLayout = () => {
    if (this.state.isMobile !== window.innerWidth < this.breakpoint) {
      this.setState({
        isMobile: window.innerWidth < this.breakpoint,
      });
      if (this.state.isMobile === true) {
        document.getElementById('printAnywhere_Summary').click();
        document.getElementById('manageEmail_Summary').click();
      }
    }
  }

  componentDidUpdate() {
    if (!this.isPending && !this.doneOnce) {
      this.doneOnce = true;
    }

    if (!!this.props.printAnywhere && this.props.printAnywhere.isComplete && !!this.props.printAnywhere.data && !!this.props.printAnywhere.data.WPPData && !!this.props.printAnywhere.data.WPPData.accessList) {
      if (this.accessList_snapshot !== 'init' && this.accessList_snapshot !== JSON.stringify(this.props.printAnywhere.data.WPPData.accessList)) {
        if (this.accessList_snapshot_length !== 'init' && this.props.printAnywhere.data.WPPData.accessList.length < this.accessList_snapshot_length) {
          this.toastMessage = 'User Deleted';
        } else {
          this.toastMessage = 'User Access Updated';
        }
        this.setState({ triggerToast: this.state.triggerToast + 1 });
      } else {
      }
      this.accessList_snapshot = JSON.stringify(this.props.printAnywhere.data.WPPData.accessList);
      this.accessList_snapshot_length = this.props.printAnywhere.data.WPPData.accessList.length;
    }
  }

  getOwnershipId() {
    let ownershipId = '6ba9680b-4b5e-43ea-89ea-b0a041b1cad8';
    const returnObj = {
      error: 'Something went wrong getting ownership ID from URL. Perhaps it was not properly formed, or was absent. A default has been inserted: 6ba9680b-4b5e-43ea-89ea-b0a041b1cad8',
    };
    const thisPathname = document.location.pathname;
    const pathArray = thisPathname.split('/');
    if (pathArray[4] !== null) {
      ownershipId = pathArray[4];
    }
    return ownershipId;
  }

  handleChoice(value) {
    const myPrinter = this.props.myPrinter.data.WPPData;
    this.props.updateMyPrinterAccessOption(myPrinter.ownershipId, myPrinter.accessOptions[value].Key);
  }

  trackingHookFn(ID) {
    document.getElementById(ID).click();
  }

  groupFn = o => {
    this.setState({
      groupControl: { identifier: o.myID, group: o.groupID },
    });
  };

  callbackFn = (isOpen, thisID) => {
    let element = '';
    if (thisID.indexOf('manageEmail') > 0) {
      element = '#AccordionTitle_manageEmail_arrow';
      this.trackingHookFn(`manage_printers_email_accordion_${isOpen ? 'open' : 'close'}`);
    } else if (thisID.indexOf('AccessOptions') > 0) {
      element = '#AccordionTitle_PrintAnywhere_arrow';
      this.trackingHookFn(`manage_print_anywhere_accordion_${isOpen ? 'open' : 'close'}`);
    }
    const rotate = isOpen ? 180 : 0;
    TweenLite.to(element, 0.5, { rotationX: rotate });
  };

  getLoadingBlock = () => (
    <Loading>
      <PageSpinner
        id="printAnywhere_page_spinner" src="/assets/print-anywhere/spinner.svg"
        alt=""
      />
    </Loading>
  )

  handleCopyCancel = () => {
    this.showModal = false;
    this.forceUpdate();
  }

  triggerCopyCancel = () => {
    this.triggerAnimateCancel = true;
    this.forceUpdate();
  }

  showCopyModal = () => {
    this.showModal = true;
    this.forceUpdate();
  }

  render() {
    const myPrinter = this.props.printAnywhere.data.WPPData;
    const { csData } = this.props.printAnywhere.data;
    this.isPending = this.props.printAnywhere.isFetching && !this.props.printAnywhere.isComplete || this.props.printAnywhere.isError;
    const { ePrintSupport } = this.props;
    if (this.isPending) {
      return this.getLoadingBlock();
    }
    if (!csData || !myPrinter) {
      return null;
    }
    return (
      <ThemeProvider theme={PrintAnywhereTheme}>
        <AugmentedMainContent currentPlatform={this.platform}>
          <ToastMessage triggerToast={this.state.triggerToast} message={this.toastMessage} />
          <a name="tracking-hook" id="manage_print_anywhere_accordion_open" />
          <a name="tracking-hook" id="manage_print_anywhere_accordion_close" />
          <a name="tracking-hook" id="manage_printers_email_accordion_open" />
          <a name="tracking-hook" id="manage_printers_email_accordion_close" />

          <LayoutContainer platform={this.platform}>
            <NewAccordion
              noCollapse={!this.state.isMobile}
              title={csData.print_anywhere_title_text}
              preamble={csData.print_anywhere_summary_text}
              platform={this.platform}
            >
              <a id="printAnywhere_Summary" />
              <a id="printAnywhere_Options" />
              <AccessOptions
                trackingHook={this.trackingHookFn}
                printer={this.props.printer}
                currentOption={this.state.activeOption}
                handleChoice={this.handleChoice}
                active={this.props.active}
                platform={this.platform}
              />
            </NewAccordion>
            {ePrintSupport && (
              <NewAccordion
                noCollapse={!this.state.isMobile}
                platform={this.platform}
                title={csData.print_anywhere_email_title_text}
                preamble={csData.print_anywhere_email_summary_text}
              >
                <PrinterAddressSettings
                  platform={this.platform}
                  trackingHandler={this.trackingHookFn}
                />
              </NewAccordion>
            )}
          </LayoutContainer>
        </AugmentedMainContent>
      </ThemeProvider>
    );
  }
}

export default bind(PrintAnwhereWeb);

const PrintAnywhereTheme = {
  hpBlue: '#0171AD', // Web accessible 'brand blue' from the HP.com design system
  textColor: '#4e4e4e',
  hpSimplifiedLight: "font-family: 'hp-simplified-light', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';",
  hpSimplifiedNormal: "font-family: 'hp-simplified', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';",
};
const Loading = styled.div`
  min-height: 10rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  > * {
    flex: 1 1 auto;
  }
`;
// A shimmering block:
const AugmentedMainContent = styled(MainContent)`
`;
const PageSpinner = styled.img`
  width: 50px;
  height: 50px;
  position:absolute;
  top: 50%;
  left: 50%;
  margin: -2.125rem 0 0 -2.125rem;    
  @keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }
  animation: spin .5s linear infinite;
`;
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props => (props.platform === 'web')
    && `@media (min-width: 1024px) {
      flex-direction: row;
      align-items: flex-start;
      &:first-child {
        margin-right: .625rem;
      }
      &:last-child {
        margin-left: .625rem;
      }
    }`
  }
  ${props => (props.platform === 'ios' || props.platform === 'android')
    && `@media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
      &:first-child {
        margin-right: .625rem;
      }
      &:last-child {
        margin-left: .625rem;
      }
    }`
  }
`;
