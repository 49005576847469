import styled from 'styled-components';
import { breakpoints, smb_breakpoints } from '../../../utils/styleHelpers';

export const PairingContainer = styled.div`
  && {
    display: flex;
    flex-direction: row;
    ${(props) => (props.isRTL ? 'margin-right: 18px' : 'margin-left: 18px')};
    @media (min-width: ${smb_breakpoints.desktop}) {
      ${(props) => (props.isRTL ? 'margin-right: 81px' : 'margin-left: 81px')};
    }
    @media (min-width: ${smb_breakpoints.wide}) {
      ${(props) => (props.isRTL ? 'margin-right: 341px' : 'margin-left: 341px')};
    }
  }
`;

export const ImageContent = styled.div``;

export const TextContent = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
  }
  h3 {
    font-size: 21px;
    margin-top: 25px;
    ${(props) => (props.isRTL ? 'margin-left: 14px' : 'margin-right: 14px')};

    @media (min-width: ${breakpoints.mobile}) {
      font-size: 20px;
    }
  }
`;
