import React from 'react';

export default function MyPrinterCardViewBlank(props) {
  return (
    <div className="_slide" >
      <div id={props.myid} className="myPrinter myPrinterCardView-blank">
      </div>
    </div>
  )
}
