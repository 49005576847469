import styled from 'styled-components';

export const StyledContainer = styled.div`
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  a {
    color: #0171ad;
  }
  em {
    font-style: normal;
    text-decoration: underline;
  }
  ol {
    margin-bottom: 1rem;
  }
  ol.letters {
    list-style-type: lower-latin;
    margin-left: 0;
    ${(props) => (props.isRTL ? 'margin-right: 2.5rem' : 'margin-left: 2.5rem')};
  }
  ol.roman {
    list-style-type: lower-roman;
    margin-left: 0;
    ${(props) => (props.isRTL ? 'margin-right: 2.5rem' : 'margin-left: 2.5rem')};
  }
  ul {
    margin-left: 0;
    ${(props) => (props.isRTL ? 'margin-right: 2.5rem' : 'margin-left: 2.5rem')};
    margin-bottom: 1rem;
  }
  small {
    ${(props) => (props.isRTL ? 'margin-right: 2.5rem' : 'margin-left: 2.5rem')};
  }
`;

export const StyledContent = styled.div`
  ${(props) => props.fontFlag && `font-family: 'Forma DJR Micro', Arial, sans-serif`};
  margin: 1.25rem;
  @media screen and (min-width: 48rem) {
    margin: 1.875rem;
  }
`;

export const StyledListItem = styled.li`
  scroll-margin-top: 85px;
`;

export const StyledContentSMBAdmin = styled.div`
  margin-top: 1.25rem;
  margin-inline-start: 1.25rem;
  margin-bottom: 1.25rem;
  margin-inline-end: 1.25rem;
  @media screen and (min-width: 48rem) {
    margin-top: 4.875rem;
    margin-inline-start: 1.875rem;
    margin-bottom: 1.875rem;
    margin-inline-end: 1.875rem;
  }
`;

export const StyledTitle = styled.h2`
  ${(props) => props.fontFlag && `font-family: 'Forma DJR Micro', Arial, sans-serif`};
  text-align: center;
  @media screen and (max-width: 32rem) {
    font-size: 1.65rem;
    margin-top: 60px;
  }
  font-size: 2rem;
`;

export const StyledDate = styled.p`
  text-align: center;
  font-size: 1.5rem;
`;

export const StyledIndex = styled.ol`
  margin-left: 0;
  ${(props) => (props.isRTL ? 'margin-right: 2.5rem' : 'margin-left: 2.5rem')};
`;

export const StyledSections = styled.ol`
  margin-left: 0;
  ${(props) => (props.isRTL ? 'margin-right: 1.25rem' : 'margin-left: 1.25rem')};

  li:nth-child(22) {
    p:nth-child(2) {
      margin-left: 0;
      ${(props) => (props.isRTL ? 'margin-right: 2.5rem' : 'margin-left: 2.5rem')};
    }
  }
`;

export const StyledSectionsSMBAdminTOU = styled.ol`
  margin-left: 0;
  ${(props) => (props.isRTL ? 'margin-right: 1.25rem' : 'margin-left: 1.25rem')};

  li:nth-child(22) {
    p:nth-child(3) {
      margin-left: 0;
      ${(props) => (props.isRTL ? 'margin-right: 2.5rem' : 'margin-left: 2.5rem')};
    }
  }

  li {
    scroll-margin-top: 75px;
    margin-bottom: 10px;
    p:nth-child(2) {
      display: inline;
    }
  }
`;
