import React from 'react';
import {
  IconChat,
  SideMenu,
  SideMenuItem,
  IconHelp,
} from '@veneer/core';
import {
  useSelector,
} from '../../../hooks';
import { Link } from '../../routing';
import { makePathRelative } from '../../../utils/routing';
import {
  ChatMenuItem,
  Container,
  Separator,
} from './styles';
import i18n from '../../Dashboard/utils/i18n';
import selectUcdePortalElements, {
  selectHasPrintPlansActiveSubscription,
} from '../../Dashboard/store/modules/portalElements/selectors';
import {
  selectLocation,
} from '../../../selectors/routing';
import {
  collapseSideMenu,
} from './collapseSideMenu';
import { selectHelpLinks } from '../../Dashboard/store/modules/helpCenter/selector';
import createVALink from '../../Dashboard/utils/createVALink';
import getMenuItemsObject from './menuItems';

const SideNav = ({ match }) => {
  const ucdePortalElements = useSelector(selectUcdePortalElements());
  const helpLinks = useSelector(selectHelpLinks);
  const location = useSelector(selectLocation);
  const printPlansActiveSubscription = useSelector(selectHasPrintPlansActiveSubscription);

  const vaLink = createVALink(match, location);
  const relPath = makePathRelative(match);

  const menuItems = getMenuItemsObject({
    portalElements: ucdePortalElements,
    printPlansActiveSubscription,
  });

  function getUserSideMenuItem() {
    const getSubMenus = subMenuItem => {
      const {
        pathname, labelId, dataTestId, onClick,
      } = subMenuItem;
      const label = i18n.t(labelId);

      return (
        <SideMenuItem
          key={`${pathname}-${label}`}
          label={label}
          link={(
            <Link
              to={relPath(pathname)}
              onClick={onClick}
            />
          )}
          selected={relPath(pathname) === location.pathname}
          {...(dataTestId ? { 'data-testid': dataTestId } : {})}
        />
      );
    };

    const menuObject = Object.keys(ucdePortalElements)
      .map(item => menuItems[item])
      .filter(item => item);

    return menuObject.map(menuItem => {
      const {
        pathname, labelId, Icon, dataTestId, onClick,
      } = menuItem;

      const label = i18n.t(labelId);
      const relativePath = relPath(pathname || '');
      const subMenus = menuItem.subMenus || [];
      const expanded = subMenus.some(subMenu => relPath(subMenu.pathname) === location.pathname);

      return (
        <SideMenuItem
          key={`${pathname}-${label}`}
          label={label}
          icon={Icon && <Icon />}
          selected={relativePath === location.pathname}
          expanded={expanded}
          {...(dataTestId ? { 'data-testid': dataTestId } : {})}
          {...(pathname ? {
            link:
            <Link
              to={relPath(relativePath)}
              onClick={onClick}
            />,
          } : {})}
        >
          {
            subMenus.length > 0 && subMenus.map(subMenuItem => getSubMenus(subMenuItem))
          }
        </SideMenuItem>
      );
    });
  }

  function buildSideMenu() {
    return (
      <SideMenu
        data-testid="portal-side-menu"
        showIcons logoImagePath="/assets/img/hp_logo.svg"
        label={i18n.t('utils.urls.hpSmart')}
      >
        {getUserSideMenuItem()}
        <SideMenuItem
          label={i18n.t('helpCenter.headers.mainHeader')}
          icon={<IconHelp />}
        >
          {
            helpLinks.map(navLink => (
              <SideMenuItem
                key={navLink.pathname}
                label={navLink.label}
                selected={navLink.pathname === location.pathname}
                link={(
                  <Link
                    smart
                    to={navLink.pathname}
                    onClick={collapseSideMenu}
                  />
                )}
              />
            ))
          }
        </SideMenuItem>

        <Separator />
        {
          (match && match.params && match.params.language === 'en'
            ? (
              <ChatMenuItem
                label={i18n.t('virtualAgent.headers.mainHeader')}
                icon={<IconChat />}
                url={vaLink}
                urlTarget="_blank"
              />
            )
            : <React.Fragment />)
        }
      </SideMenu>
    );
  }

  return (
    <Container>
      {buildSideMenu()}
    </Container>
  );
};

export default SideNav;
