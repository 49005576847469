import React, { Component } from 'react';
import styled from 'styled-components'
import bind from './../../bind';

const Overlay = styled.div`
    opacity: 0;
    display: flex;
    position: fixed;
    overflow: auto;
    text-align: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 900;
    background-color: rgba(0,0,0,.8);
    padding: 0;
    margin: 0;
    border: 0;
`
const Modal = styled.div`
    position: relative;
    display: flex;
    align-self: center;
    margin: auto;
    width: ${props => props.isMobile ? '100%' : '60%'};
    padding: .5rem 1rem 0;
    max-width: 40rem;
    overflow: hidden;
    z-index: 1111;
    text-align: center;
    background-color: #fff;
    .ePrintAddressChange--addressLabel {
        font-size: 1.5rem;
    }
    .ePrintAddressChange--address {
        font-size: 1.25rem;
    }
    p {
        font-size: 1.25rem;
        color: #767676;
    }
`
// const CloseButton = styled.button`
//     position: relative;
//     overflow: auto;
//     text-align: right;
// `

class OnboardingModal extends Component {
    constructor(props) {
        super(props);
        this.close = this.close.bind(this)
        this.animateOnOff = this.animateOnOff.bind(this)
        this.overlayID = this.props.ID ? this.props.ID+'-overlay'  : 'onboarding-modal-overlay'
        this.modalID = this.props.ID ? this.props.ID+'-modal' : 'onbooarding-modal'
    }

    componentDidUpdate() {
        if (this.props.gnb.isTablet) {
            this.close()
        }
        if (this.props.cancelOnboarding) {
            this.animateOnOff(false)
        }
    }
    
    componentDidMount() {
        this.animateOnOff(true)
    }
    
    close() {
        this.props.closeHandler()
    }
    
    animateOnOff = (ON) => {
        let num = ON ? 1 : 0
        const completed = () => { if (!ON) this.close() }
        let timing = .6  // + (1 - num)
        TweenLite.to("#"+this.overlayID, timing, { alpha: num, onComplete: completed })
        if (ON) {
            TweenLite.from("#"+this.modalID, timing, { top: 700, alpha: 0, scaleX: 0.5, scaleY: 0.5, ease: Power2.easeOut })
        } else {
            TweenLite.to("#"+this.modalID, timing, { top: 800, alpha: 0, scaleX: 0.2, scaleY: 0.2, ease: Power2.easeIn })
        }
    }

    render() {
        const inMobile = window.innerWidth < 639
        return (
            <Overlay id={this.overlayID} onClick={() => this.animateOnOff(false)}>
                <Modal id={this.modalID} onClick={(event) => { event.stopPropagation() }} isMobile={inMobile} >
                    <a onClick={() => this.animateOnOff(false)} className="modalWindow--close close-button" data-close="" aria-label="Close">&#215;</a>
                    {this.props.children}
                </Modal>
            </Overlay>
        )
    }
}
export default bind(OnboardingModal);

