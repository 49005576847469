import styled from 'styled-components';
import { IconChevronDown } from '@veneer/core';
import { Link } from '../../../../routing';

export const ArrowIcon = styled(IconChevronDown)``;

const ArrowButton = styled(Link)`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  box-shadow: 0 5px 12px #00000014;
  position: absolute;
  z-index: 1;
  top: 50%;
  margin-top: -25px;
  background-color: #ffffff;
  text-align: center;

  :hover {
    background-color: #f2f2f2;
  }

  ${ArrowIcon} {
    margin-top: 13px;
    fill: #737373;
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    background: rgba(0, 0, 0, 0);
    border: none;
    box-shadow: none;
    &&:hover{
      background-color: rgba(0, 0, 0, 0);
    }
  }
`;

export const ArrowPrev = styled(ArrowButton)`
  left: 0;

  ${ArrowIcon} {
    transform: rotate(90deg);
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    left: -30px;
    ${ArrowIcon} {
      margin-top: 0;
    }
  }
`;

export const ArrowNext = styled(ArrowButton)`
  right: 0;

  ${ArrowIcon} {
    transform: rotate(270deg);
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    right: -30px;
    ${ArrowIcon} {
      margin-top: 0;
    }
  }
`;
