import ActionTypes from '../constants/ActionTypes';
import { makeActionCreator } from '../utils/redux';

export const gotContentStackData = makeActionCreator(
  ActionTypes.CONTENTSTACK_CONTENT,
  'resource',
  'data'
);

export const contentStackDataFailue = makeActionCreator(
  ActionTypes.CONTENTSTACK_CONTENT_FAILUE,
  'resource',
  'error'
);
