import React, { useMemo } from 'react';
import { Route, Redirect } from 'react-router';
import { selectLocation, } from '../../selectors/routing';
import { determineLocalePath } from '../../utils/routing';
import { useSelector } from '../../hooks';

const LocaleInjectionRedirect = ({ dropSuffix }) => {
  const location = useSelector(selectLocation);

  const correctedPath = useMemo(() => determineLocalePath({
    location,
    dropSuffix,
  }), [location, dropSuffix]);

  return (
    <Route>
      {
        // this is here to catch anything that doesn't match the /:country/:language
        // pattern with _valid_ country/language combination
      }
      <Redirect to={correctedPath} />
    </Route>
  );
};

export default LocaleInjectionRedirect;
