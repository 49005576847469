/* eslint-disable import/prefer-default-export */
import { api, contentStack } from '../utils/request';
import { updateImageUrlToProxy } from '../utils/globals';

// Here and in [deprecated]lib/urlUtils.js getApiPathWithLocale: In order to
// get Craft's preview to work, we need to get the preview
// token from the url, and then pass it back to element api.
// Here is an overview: https://nystudio107.com/blog/headless-preview-in-craft-cms
// Code to do it looks like this:
//
// export const getCraftData = async ({ resource, lang = 'en', notLocalized }) => {
//   // Get the preview token from the URL
//   let m = document.location.href.match(/\btoken=([^&]+)/);
//   let token = m ? m[1] : '';
//
//   const url = `/api/${resource}.json${notLocalized ? '' : `.${lang}`}?token=${token}`;
//
//   return api({ url });
// };
//
// Additionally, in the Craft control panel, the Base URL for the sites
// needs to be set correctly: @siteURL for en_us, @siteURL/it/it for Italy, etc.
// and the section settings need to have the correct uri, and template of 'index'

const defaultLanguage = '.en_US';

const buildUrl = (resource, lang, notLocalized) => {
  return `/api/${resource}.json${notLocalized ? `${defaultLanguage}` : `.${lang}`}`;
};

export const getCraftData = async ({ resource, lang = 'en', notLocalized }) => {
  const specifiedVersionBuildUrl = buildUrl(resource, lang, notLocalized);
  const specifiedVersionApiResponse = await api({
    url: specifiedVersionBuildUrl
  });

  if (specifiedVersionApiResponse === false) {
    const baseResource = resource.substring(0, resource.lastIndexOf('/'));

    const latestVerionBuildUrl = buildUrl(`${baseResource}/latest`, lang, notLocalized);

    const latestVersionApiResponse = await api({
      url: latestVerionBuildUrl
    });
    return latestVersionApiResponse;
  } else {
    return specifiedVersionApiResponse;
  }
};

export const getContentStackData = async (resource, contentStackLocaleCode, uuid) => {
  const response = await contentStack(resource, contentStackLocaleCode, uuid);
  return updateImageUrlToProxy(response);
};
