import react from 'react';
import React from 'react';
import { IconCheckmark, IconInfo } from '@veneer/core';
import {
  TableContainer,
  TableConatinerTable,
  ExtraBenifits,
  Tooltip,
  TooltipText,
  ExclusiveFeatures,
  CellContent,
  CellContainer
} from './SACompareStyles';

const SACompareTable = (props) => {
  let tableData = props.featureRow.map((data) => {
    const { features } = data;

    const {
      feature_title,
      coachmark,
      hpplus_feature_text,
      ii_feature_text,
      with_acc_feature_text,
      no_acc_feature_text,
      visibility_limitation,
      _metadata
    } = features;

    const { uid } = _metadata;

    if (visibility_limitation) {
      if (visibility_limitation === 'Desktop only') {
        return (
          <react.Fragment key={uid}>
            <tr>
              <td dangerouslySetInnerHTML={{ __html: feature_title }}></td>
              <td dangerouslySetInnerHTML={{ __html: hpplus_feature_text }}></td>
              <td dangerouslySetInnerHTML={{ __html: ii_feature_text }}></td>
              <td dangerouslySetInnerHTML={{ __html: with_acc_feature_text }}></td>
              <td dangerouslySetInnerHTML={{ __html: no_acc_feature_text }}></td>
            </tr>
          </react.Fragment>
        );
      } else {
        return null;
      }
    } else {
      return (
        <react.Fragment key={uid}>
          <tr>
            <td>
                <span dangerouslySetInnerHTML={{ __html: feature_title }} />
                <Tooltip id="Tooltip-Icon-Desk">
                  <IconInfo disabled />
                  <TooltipText id="TooltipText-Icon-Desk" dangerouslySetInnerHTML={{ __html: coachmark }} />
                </Tooltip>
            </td>
            <td>
              <CellContainer>
                <IconCheckmark color="colorOrange6" filled />
                <span dangerouslySetInnerHTML={{ __html: hpplus_feature_text }} />
              </CellContainer>
            </td>
            {ii_feature_text === '' ? (
              <react.Fragment>
                {ii_feature_text === '' && hpplus_feature_text === '' ? (
                  <td>
                    <span dangerouslySetInnerHTML={{ __html: ii_feature_text }}></span>
                  </td>
                ) : (
                  <td>
                    <CellContainer>
                      <IconCheckmark color="colorHpBlue6" filled />
                      <span dangerouslySetInnerHTML={{ __html: ii_feature_text }} />
                    </CellContainer>
                  </td>
                )}
              </react.Fragment>
            ) : (
              <td>
                <CellContainer>
                  <IconCheckmark color="colorHpBlue6" filled />
                  <span dangerouslySetInnerHTML={{ __html: ii_feature_text }}></span>
                </CellContainer>
              </td>
            )}
            {with_acc_feature_text === '' ? (
              <react.Fragment>
                {with_acc_feature_text === '' && hpplus_feature_text === '' ? (
                  <td>
                    <span dangerouslySetInnerHTML={{ __html: with_acc_feature_text }}></span>
                  </td>
                ) : (
                  <td>
                    <CellContainer>
                      <IconCheckmark color="colorHpBlue6" filled />
                      <span dangerouslySetInnerHTML={{ __html: with_acc_feature_text }}></span>
                    </CellContainer>
                  </td>
                )}
              </react.Fragment>
            ) : (
              <td>
                <CellContainer>
                  <IconCheckmark color="colorHpBlue6" filled />
                  <span dangerouslySetInnerHTML={{ __html: with_acc_feature_text }}></span>
                  </CellContainer>
              </td>
            )}
            {no_acc_feature_text === '' ? (
              <td dangerouslySetInnerHTML={{ __html: no_acc_feature_text }}></td>
            ) : (
              <td>
                <CellContainer>
                  <IconCheckmark color="colorHpBlue6" filled />
                  <span dangerouslySetInnerHTML={{ __html: no_acc_feature_text }}></span>
                </CellContainer>
              </td>
            )}
          </tr>
        </react.Fragment>
      );
    }
  });

  let exclusiveFeatures = props.headerDescriptionRow.map((data) => {
    const { header_description } = data;
    const { description } = header_description;
    let newDescription = description.replace("+[instantInkPrice]", props?.instantInkPrice);

    return (
      <ExclusiveFeatures
        key={description}
        id="ExclusiveFeatures-Desk"
        dangerouslySetInnerHTML={{ __html: newDescription }}
      ></ExclusiveFeatures>
    );
  });

  let headerRow = props.headerRow.map((data) => {
    const { header_title } = data;
    const { column_title, column_title_bonus } = header_title;
    if (column_title_bonus !== null) {
      return (
        <th key={column_title}>
          <CellContent>
            <span dangerouslySetInnerHTML={{ __html: column_title }}></span>
            <ExtraBenifits id="ExtraBenifits-Desk">{column_title_bonus}</ExtraBenifits>
          </CellContent>
        </th>
      );
    } else {
      return (
        <th key={column_title} dangerouslySetInnerHTML={{ __html: column_title }}></th>
      );
    }
  });

  return (
    <react.Fragment>
      <TableContainer id="TableContainer-Desk">
        <TableConatinerTable id="TableConatiner-Table-Desk">
          <thead>
            <tr>{headerRow}</tr>
          </thead>
          <tbody>
            <tr>{exclusiveFeatures}</tr>
            {tableData}
          </tbody>
        </TableConatinerTable>
      </TableContainer>
    </react.Fragment>
  );
};

export default SACompareTable;
