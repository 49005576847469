import { useMemo } from "react";
import React from 'react';
import styled from 'styled-components';
import Button from '../shared-components/atoms/Button';
import { getHtmlDir } from '../../utils/globals';
import { RTL } from '../../constants/i18n';
import { RIGHT, LEFT } from '../../constants/common';
import Config from '../../config/Config';
import {
  STACK_DEV,
  STACK_PIE,
  STACK_STAGE,
  STACK_PROD
} from '../../constants/common';
import {
  REGEX_STAGE,
  REGEX_PROD,
  REGEX_DEV_AND_PIE
} from '../../constants/MobileFaxRedirectRegex';


const AppSection = styled.div`
  background-image: url(${props => props.sectionData.app_section_background_small?.url}) ;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  @media screen and (min-width:${props => props.theme.small}) {
    background-image: url(${props => props.sectionData.app_section_background_medium?.url});
  }
  @media screen and (min-width:${props => props.theme.large}) {
    background-image: url(${props => props.sectionData.app_section_background_large?.url});
  }
  color: white;
  position: relative;
`;
const AppSectionContent = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 5rem 2rem;
  @media screen and (min-width:${props => props.theme.small}) {
    margin: 9.25rem 0 10.5rem 36%;
    align-items: flex-start;
    background-color: white;
    padding: 3.125rem 2.375rem 3.5rem 3.25rem;
  }
  @media screen and (min-width:${props => props.theme.medium}) {
    margin: 9.25rem 0 10.5rem 50%;
  }
  @media screen and (min-width:${props => props.theme.large}) {
    padding: 4.5rem 1.875rem 3.75rem 5rem;
    margin: 7.5rem 0 9.75rem 48%;
  }
  @media screen and (min-width:${props => props.theme.xlarge}) {
    padding: 5rem 2.375rem 4.25rem 5.5rem;
    margin: 6.875rem 0 9rem 56.5%;
  }
  h2 {
    /* Mobile */
    margin-bottom: 1rem;
    margin-top: 0;
    color: white;
    width: 100%;
    @media screen and (max-width:${props => props.theme.small}) {
      font-size: 2rem;
      line-height: 1.125;
    }
    @media screen and (min-width:${props => props.theme.small}) {
      margin-bottom: .875rem;
      color: #313131;
      text-align: ${ props => props.direction === RTL ? RIGHT : LEFT };
    }
  }
  p { 
    font-size: 1.125rem;
    margin-top: 0;
    margin-bottom: 2rem;
    color: white;
    width: 100%;
    @media screen and (min-width:${props => props.theme.small}) {
      margin-bottom: 1.75rem;
      color: #313131;
      font-size: 1.5rem;
      text-align: ${ props => props.direction === RTL ? RIGHT : LEFT };
      line-height: 1.325rem;
    }
  }
`;
const Badges = styled.div`
  width: 11rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.25rem;
  > * + * {
    margin-top: 1rem;
  }
  @media screen and (min-width:${props => props.theme.small}) {
    > * + * {
      margin-top: 0;
      margin-left: .75rem;
    }
    ${ props => props.direction === RTL ? `
    > *:first-child {
      margin-top: 0;
      margin-left: .75rem;
    }
    ` : '' };
    flex-direction: row;
    margin-bottom: 0;
    width: 100%;
  }
  a { 
    height: 3.375rem;
    @media screen and (min-width:${props => props.theme.small}) {
      height: 2.5rem;
    }
    @media screen and (min-width:${props => props.theme.large}) {
      height: 3.25rem;
    }
  }
  img {
    margin: 0;
    line-height: 1;
    height: 100%;
  }
`;
const TermsLink = styled.a`
  text-decoration: none;
  color: ${props => (props.isInApp ? 'white' : 'inherit')};
  &:hover, &:focus {
    color: ${props => (props.isInApp ? 'white' : 'inherit')};
    text-decoration: none;
  }
  ${props => props.theme.boldFontFamilyAndWeight}
  margin-top: ${props => (props.isInApp ? '1.75rem' : '0')};
  @media screen and (min-width:${props => props.theme.small}) {
    margin-top: 1.75rem;
    font-size: 1.25rem;
    color: ${props => props.theme.hpBlue};
    &:hover, &:focus {
      color: ${props => props.theme.hpBlue};
      text-decoration: none;
    }
  }
`;
export default function LandingPageAppSection(props) {
  const direction = getHtmlDir();

  const buttonDestination = useMemo(() => {
    const buttonDestination = props?.buttonDestination;
    const config = new Config();
    const currentStack = config.Get('CURRENT_STACK');
  
    const stackDestinations = {
      [STACK_DEV]: REGEX_DEV_AND_PIE.test(buttonDestination) ? buttonDestination : null,
      [STACK_PIE]: REGEX_DEV_AND_PIE.test(buttonDestination) ? buttonDestination : null,
      [STACK_STAGE]: REGEX_STAGE.test(buttonDestination) ? buttonDestination : null,
      [STACK_PROD]: REGEX_PROD.test(buttonDestination) ? buttonDestination : null,
    };
    
    return stackDestinations[currentStack];
  }, [props?.buttonDestination]);
  
  return (
    <AppSection sectionData={props.sectionData}>
      <AppSectionContent direction={direction}>
        <h2>{props.sectionData.app_section_headline_text}</h2>
        <p>{props.sectionData.app_section_description_text}</p>
        {props.isInApp && props.isMobile
          && (
          <Button
            buttonID="mobilefax_getStarted_cta" buttonText={props.buttonText}
            buttonType="primaryDarkBg" buttonLink={buttonDestination}
          />
          )}
        {props.isInApp && !props.isMobile
          && (
          <Button
            buttonID="mobilefax_getStarted_cta" buttonText={props.buttonText}
            buttonType="primary" buttonLink={buttonDestination}
          />
          )}
        {!props.isInApp && (
          <Badges direction={direction}>
            {props.appBadges.map(({ app_badge }, j) => {
              const testIDs = ['appleBadge', 'googleBadge', 'microsoftBadge'];
              const resolvedID =
                (app_badge?.app_badge_id === undefined)
                  ? `mobilefax_tracking_${testIDs[j]}`
                  : `mobilefax_tracking_${app_badge?.app_badge_id}`;
              return (
                <a
                  key={j} href={app_badge?.app_badge_link}
                  target="_blank" id={resolvedID}
                  tabIndex="0"
                >
                  <img
                    src={app_badge?.app_badge_graphic?.url} 
                    // alt={app_badge?.app_badge_graphic.title} 
                  />
                </a>
              );
            })}
          </Badges>
        )}
        <TermsLink
          id="termsAndConditions"
          target="_blank" isInApp={props.isInApp}
          href={props.sectionData.terms_conditions_link_address}
          tabIndex="0"
        >
          {props.sectionData.terms_conditions_link_text}
        </TermsLink>
      </AppSectionContent>
    </AppSection>
  );
}