import React from 'react';
import PropTypes from 'prop-types';
import ImageContentBox from '../shared-components/atoms/ImageContentBox';
import {
  HowItWorksSection,
  HowItWorksContainer,
  Heading,
  Subheading,
  DetailsContainer,
  HowItWorksVideoContainer
} from './styles/StyleOrigamiHowItWorks';
import OrigamiHowItWorksYoutubeVideo from './OrigamiHowItWorksYoutubeVideo';

const propTypes = {
  dashedSeparatorTitle: PropTypes.string.isRequired,
  dashedSeparatorUrl: PropTypes.string.isRequired,
  howItWorksHeading: PropTypes.string.isRequired,
  howItWorksSubhead: PropTypes.string.isRequired,
  howItWorksVideoCode: PropTypes.string.isRequired,
  howItWorksSection: PropTypes.array.isRequired
};

const defaultProps = {
  dashedSeparatorTitle: '',
  dashedSeparatorUrl: '',
  howItWorksHeading: '',
  howItWorksSubhead: '',
  howItWorksVideoCode: '',
  howItWorksSection: []
};

const OrigamiHowItWorks = (props) => {
  const {
    howItWorksHeading,
    howItWorksSubhead,
    howItWorksVideoCode,
    howItWorksSection
  } = props;
  let bindArr = [];
  let countArr = howItWorksSection.length;
  for (let inc = 0; inc < countArr; inc++) {
    bindArr[bindArr.length] = howItWorksSection[inc];
  }

  return (
    <HowItWorksSection data-testid="how-it-works">
      <HowItWorksContainer>
        <Heading data-testid="how-it-works-heading">{howItWorksHeading}</Heading>
        <Subheading data-testid="how-it-works-subheading">{howItWorksSubhead}</Subheading>
        <DetailsContainer data-testid="how-it-works-details">
          {bindArr.map(function (item, i) {
            const {
              detail: {
                detail_image: { title: detailImageTitle, url: detailImageUrl },
                detail_header: detailHeader,
                detail_text: detailText,
                _metadata: { uid: id }
              }
            } = item;
            return (
                <ImageContentBox
                  titleText={detailHeader}
                  descriptionText={detailText}
                  imageTitle={detailImageTitle}
                  imageUrl={detailImageUrl}
                  width={'100%'}
                  key={id}
                />
            );
          })}
        </DetailsContainer>
      </HowItWorksContainer>
      {howItWorksVideoCode && (
      <HowItWorksVideoContainer>
        <OrigamiHowItWorksYoutubeVideo youtubeVideoCode={howItWorksVideoCode}/>
      </HowItWorksVideoContainer>
      )}
    </HowItWorksSection>
  );
};

OrigamiHowItWorks.propTypes = propTypes;
OrigamiHowItWorks.defaultProps = defaultProps;
export default OrigamiHowItWorks;
