import React from 'react';
import { ContextualMenu } from '@veneer/core';
import styled from 'styled-components';
import UserProfile from './UserProfile';

const UserDiv = styled.div`
  position: relative;
  margin-top: 6px;
  margin-left: 10px;
  cursor: pointer;
  color: rgb(2, 122, 174);
`;
// overiding the global css property
const SignOut = styled.span`
  ul {
    margin-left:0;
    margin-bottom:0;
  }
`;

const UserAvatar = props => {
  const { userName, signOutLinkText } = props;
  const signOutOption = {
    value: 0,
    label: signOutLinkText,
  };
  return (
    <SignOut>
      <ContextualMenu
        options={[signOutOption]}
        placement="bottom-end"
        onClick={() => {
          window.location.href = '/signout';
        }}
      >
        <UserDiv>
          <UserProfile
            userName={userName}
            backgroundOpacity="0.2"
            fontSize="16px"
          />
        </UserDiv>
      </ContextualMenu>
    </SignOut>
  );
};

export default UserAvatar;
