import React from 'react';
import { useSelector } from '../../../hooks';
import FeatureDisplay from '../../shared-components/FeatureDisplay';
import { selectServiceExplainerFeatures } from '../../../selectors/home';

const ServiceExplainers = ({ isRTLSupport }) => {
  const props = useSelector(selectServiceExplainerFeatures);
  if (!props) {
    return null;
  }
  const {
    features = [],
    headline,
  } = props;

  return <FeatureDisplay features={features} headline={headline} isRTLSupport={isRTLSupport} />;
};

export default ServiceExplainers;
