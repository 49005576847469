import React, { useEffect, useState } from 'react';
import svgPrinterIcon from '../../assets/essentials/smart-welcome-icon.svg';
import { useSelector } from '../../hooks';
import { selectDataSharingNoticeContentStackData } from '../../selectors/craft';
import { Accordion } from '@veneer/core';
import {
    Graph,
    MarketingPartnersContainer,
    Title,
    Body,
    AccordionBody,
    AccordionHeader
} from './styledDataSharingNotice';
import { DATA_SHARING_NOTICE_RESOURCE_NAME } from '../../constants/contentStackResources';

const DataSharingNotice = () => {
    const data_sharing_notice = useSelector(selectDataSharingNoticeContentStackData, [DATA_SHARING_NOTICE_RESOURCE_NAME]);
    const csData = data_sharing_notice?.data?.entries[0];
    const {
        accordion = [],
        intro_section: introSection = '',
        title: accordionTitle = ''
    } = csData || {
        accordion: [],
        introSection: '',
        title: ''
    };

    const [openItems, setOpentItems] = useState([]);

    useEffect(() => {
        const accordionItems = [];
        accordion.forEach((acc) => {
            accordionItems.push({
                content: (
                    <AccordionBody
                        dangerouslySetInnerHTML={{
                            __html: acc.accordion_item.accordion_content_text
                        }}
                    ></AccordionBody>
                ),
                header: {
                    centralArea: (
                        <AccordionHeader>
                            {acc.accordion_item.accordion_title_text}
                        </AccordionHeader>
                    )
                },
                id: acc.accordion_item._metadata.uid
            });
        });
        setOpentItems(accordionItems);
    }, [accordion]);
    return (
        <MarketingPartnersContainer>
            <Graph
                id="mdata-sharing-notice-printer-logo"
                src={svgPrinterIcon}
                alt="data sharing notice printer logo"
            />
            <Title>{accordionTitle}</Title>

            <Body
                id="data-sharing-notice-introsection"
                dangerouslySetInnerHTML={{ __html: introSection }}
            />

            {openItems.length > 0 && (
                <Accordion
                    behavior="singleExpand"
                    items={openItems}
                    id="data-sharing-notice-accordion"
                />
            )}
        </MarketingPartnersContainer>
    );
};

export default DataSharingNotice;