import React from 'react';
import styled from 'styled-components';
import dateFormat from 'dateformat';
import Media from 'react-media';
import { Link } from '../../routing';
import MyPrinterImage from '../MyPrinterImage';
import TextButton from '../../shared-components/atoms/textButton';
import bind from '../../bind';

const StyledLink = styled.span`
    a {
        text-align: center;
        margin: 0 0 0.4375rem 0;
        @media screen and (min-width: 64rem) {
            margin: 0.4375rem 0;
        }
        display: block;
    }
`;
const CarePackMsg = styled.span`
    margin-bottom: 0.4375rem;
    text-align: center;
`;
const VisualSummary = ({ printer }) => (
  <div className="myPrinter--visualSummary">
    <MyPrinterImage
      mainImageSmall={printer?.printerImageUrl300N245}
      mainImageMedium={printer?.printerImageUrl300N245}
      mainImageLarge={printer?.printerImageUrl300N245}
      mainImageAlt={printer?.printerName}
    />
  </div>
);

const PrinterDetailsMeta = ({
  supportLinkText,
  supportLinkUrl,
  removeLinkText,
  removeLinkAction,
}) => (
  <div className="myPrinterDetails--meta">
    <TextButton
      ID="support-docs-link"
      buttonText={supportLinkText}
      buttonLink={supportLinkUrl}
      buttonClass="myPrinterDetails--metaLink"
    />
    <TextButton
      ID="remove-printer-link"
      buttonText={removeLinkText}
      onClick={removeLinkAction}
      buttonClass="myPrinterDetails--metaLink"
    />
  </div>
);
const PrinterDetailsTitle = ({ printer }) => (
  <h3 className="myPrinterTitle">
    <span className="myPrinterTitle--status">
      <img
        src={printer?.printerStatusIcon}
        alt={printer?.printerStatus}
      />
    </span>
    {' '}
    {printer?.printerName ? printer.printerName : printer?.printerModel ? printer.printerModel : 'My HP Printer'}
  </h3>
);
const EssentialsLink = ({ essentialsLink, printer }) => {
  const thisPrinterName = printer?.printerName?.toLowerCase();
  if (thisPrinterName?.includes('tango')) {
    return (
      <StyledLink><Link id="printer-essentials-link" to={essentialsLink?.[0]?.linkUrl}>{essentialsLink?.[0]?.linkText}</Link></StyledLink>
    );
  }
  return null;
};

const Identifiers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1024px) {
    padding-left: 1.25rem;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Summary = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    padding-right: 1.25rem;
  }
`;

const MyPrinterMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  #printer-email-bottom {
    display: none;
  }
  .myPrinterDetails--meta {
    width: 90%;
  }
  @media (min-width: 1024px) {
    #printer-email-bottom {
      display: block;
    }
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding-bottom: 3rem;
    border-bottom: 1px solid $layout-border-colour;
    .myPrinterDetails--meta {
      width: 25rem;
      align-self: baseline;
    }
    .myPrinter--headerB {
      text-align: center;
    }
    .myPrinter--nextSteps p {
      margin-bottom: 0;
    }
    #printer-email, ${CarePackMsg} {
      display: none;
    }
    border-bottom: 1px solid #B9B8BB;
  }
`;
const EprintMainInfo = props => {
  const {
    locales,
    config,
    myPrinter: {
      data: {
        WPPData: myPrinter,
        miscellaneousData,
      },
    },
    printerCommon,
    gnb: { gnbData },
    handleRemoveClick,
    ePrintSupport,
  } = props;

  const cpScenarios = !!props?.myPrinter?.data?.carepack && Array.isArray(props?.myPrinter?.data?.carepack?.scenarios) ? props?.myPrinter?.data?.carepack?.scenarios?.[0] : false;
  // /////////////////////////////////////////////////// REFACTOR

  let cpWarrantyStatus = '';
  let expirationDate = null;
  let yourWarrantyExpiresOn = null;
  const { carepack } = myPrinter;
  const isFeatureFlag = props?.config?.data?.GetFeatureFlag('ff_carepack_enabled')?.toLowerCase() === 'true';
  // const isCarePack = isFeatureFlag && ( carepack.firstNinety !== undefined || cpScenarios !== false )
  const isCarePack = isFeatureFlag && ((carepack !== null && carepack !== undefined && carepack?.firstNinety !== undefined) || cpScenarios !== false);

  let cpData = null;
  if (isCarePack) {
    cpData = cpScenarios;

    if (cpData === null) {
      // DO NOTHING
    }
  }

  if (isCarePack && !!cpData && cpData.cpWarrantyStatusSelection !== undefined && cpData.cpWarrantyStatusSelection['0'] !== undefined) {
    cpWarrantyStatus = cpData.cpWarrantyStatusSelection['0'].cpWarrantyStatus ? cpData.cpWarrantyStatusSelection['0'].cpWarrantyStatus : '';
    expirationDate = (carepack.endDate) ? dateFormat(new Date(carepack.endDate), 'mm/dd/yyyy') : ''; // carepack.endDate
    yourWarrantyExpiresOn = cpWarrantyStatus; // "Your warranty expires on"
  }
  const supportLink = config?.data?.Get('SEARCH_URL');
  const isRemoveLinkExist = !(myPrinter?.platformIdentifier === 'gen2Yeti' || myPrinter?.platformIdentifier === 'gen2WFH');
  return (
    <MyPrinterMainInfo>
      <Main>
        <Summary>
          <a
            id="printer-email" href={`mailto:${myPrinter?.printerEmail}`}
            className="myPrinter--ePrintAddress" data-cd1={myPrinter?.printerModelNumber}
            data-cd2={myPrinter?.printerName}
          >
            {myPrinter?.printerEmail}
          </a>
          <VisualSummary printer={myPrinter} />
          <Media query="(max-width: 1023px)">
            <PrinterDetailsTitle printer={myPrinter} />
          </Media>
          <PrinterDetailsMeta
            supportLinkText={miscellaneousData?.support_docs_link}
            supportLinkUrl={supportLink}
            removeLinkText={isRemoveLinkExist ? miscellaneousData?.remove_printer_link : ''}
            removeLinkAction={isRemoveLinkExist ? handleRemoveClick : ''}
          />
        </Summary>
        <Identifiers>

          <Media query="(min-width: 1024px)">
            <PrinterDetailsTitle printer={myPrinter} />
          </Media>
          {isCarePack && (
            <CarePackMsg>
              {yourWarrantyExpiresOn}
              {' '}
              {expirationDate}
            </CarePackMsg>
          )}
          <EssentialsLink essentialsLink={miscellaneousData?.essentials_link} printer={myPrinter} />
          <a
            id="printer-email-bottom" href={`mailto:${myPrinter?.printerEmail}`}
            className="myPrinter--ePrintAddress" data-cd1={myPrinter?.printerModelNumber}
            data-cd2={myPrinter?.printerName}
          >
            {myPrinter?.printerEmail}
          </a>
        </Identifiers>
      </Main>
    </MyPrinterMainInfo>
  );
};

export default bind(EprintMainInfo);
