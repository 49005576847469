import { takeEvery, select, put, call } from 'redux-saga/effects';
import uuid from 'uuid-random';
import ActionTypes from '../constants/ActionTypes';
import { selectUDLQueryParams } from '../selectors/routing';
import { saveUDLParams } from '../actions/analytics';
import { encrypt } from '../services/encryption';

function* handleManageUDLParams() {
  const queryParams = yield select(selectUDLQueryParams);

  if (queryParams.appInstanceID) {
    yield put(saveUDLParams(queryParams));
  } else {
    try {
      const unEncodedAppInstanceID = uuid();
      const { data: { appInstanceID } } = yield call(encrypt, { appInstanceID: unEncodedAppInstanceID });

      yield put(saveUDLParams({ appInstanceID }));
    } catch (e) {
      console.error('Could not encrypt parameter');
      console.error(e);
    }
  }
}

export default function* watchCaptureUDLParams() {
  yield takeEvery(ActionTypes.CAPTURE_UDL_PARAMS, handleManageUDLParams);
}
