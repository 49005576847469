import React, { useState, useLayoutEffect } from 'react';
import { prop } from 'ramda';
import { useSelector } from '../../../hooks';
import { selectContentStackDataByResource, selectAppBadgesContentStackData } from '../../../selectors/craft';
import { SMB_DOWNLOAD_RESOURCE_NAME } from '../../../constants/contentStackResources';
import Navbar from '../../revised/Global/Navbar';
import Footer from '../../revised/Global/Footer';
import LoaderBlock from '../../shared-components/molecules/LoaderBlock';
import {
  Container,
  HeaderContent,
  SubHeaderText,
  AppPromoText,
  AppStoreContent,
  AppStoreText,
  AppStoreBadgesContainer,
  QRCodeImage,
  QRCodeText,
  StraightLine,
  SmartAppHelpText,
  SupportIconImg,
  SupportText,
  DownloadHeaderText,
  SubHeaderContainer
} from '../Styles/styles';
import { CS_SMB_DOWNLOAD_UNSUPPORTEDOS_ENTRY_ID } from '../../../constants/common';

export const UnsupportedOS = (props) => {
  // Getting Content Stack Data
  const contentStackData = useSelector(selectContentStackDataByResource, [SMB_DOWNLOAD_RESOURCE_NAME]);
  // Extracting Content Stack Data
  const getUnsupportedOsData = prop(SMB_DOWNLOAD_RESOURCE_NAME, contentStackData );
  const unsupportedOSCSData = getUnsupportedOsData?.data?.entries.filter(section => section.uid === CS_SMB_DOWNLOAD_UNSUPPORTEDOS_ENTRY_ID);

  const appBadgesContent = useSelector(selectAppBadgesContentStackData)?.data?.entries[0];

  const { isRTLSupport, geoCountry } = props;
  const breakpoint = 768;

  // Mobile View
  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);

  const resizeLayout = () => {
    setIsMobile(window.innerWidth < breakpoint);
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', resizeLayout);
  });

  if (unsupportedOSCSData && appBadgesContent) {
    const {
      desktop_header_image,
      mobile_header_image,
      download_header,
      download_sub_header,
      app_store_text,
      qr_code,
      qr_code_text,
      smart_app_help_text,
      app_promo_text,
      support_icon,
      support_icon_text
    } = unsupportedOSCSData[0];

    const { landing_page_app_badges } = appBadgesContent;

    return (
      <div>
        <Navbar geoCountry={geoCountry} minimal={true} smbDownloadHeader={true} />
        <Container>
          <HeaderContent
            isRTL={isRTLSupport}
            desktopHeaderImageUrl={desktop_header_image?.url}
            mobileHeaderImageUrl={mobile_header_image?.url}
          >
            <DownloadHeaderText isRTL={isRTLSupport}>{download_header}</DownloadHeaderText>
          </HeaderContent>
          <SubHeaderContainer>
            <SubHeaderText>{download_sub_header}</SubHeaderText>
            <AppPromoText dangerouslySetInnerHTML={{ __html: app_promo_text }} />
          </SubHeaderContainer>
          <AppStoreContent isRTL={isRTLSupport}>
            <AppStoreText>{app_store_text}</AppStoreText>
            <AppStoreBadgesContainer>
              <QRCodeImage src={qr_code?.url} alt={qr_code?.title} />
              <QRCodeText isRTL={isRTLSupport}>{qr_code_text}</QRCodeText>
            </AppStoreBadgesContainer>
            {isMobile && <StraightLine />}
            <SmartAppHelpText
              isRTL={isRTLSupport}
              dangerouslySetInnerHTML={{ __html: smart_app_help_text }}
            />
            <StraightLine />
            <AppStoreBadgesContainer>
              <SupportIconImg src={support_icon?.url} alt={support_icon?.title} />
              <SupportText
                isRTL={isRTLSupport}
                dangerouslySetInnerHTML={{ __html: support_icon_text }}
              />
            </AppStoreBadgesContainer>
          </AppStoreContent>
        </Container>
        <Footer />
      </div>
    );
  }
  return (
    <div className="contentLoading">
      <LoaderBlock />
    </div>
  );
};

export default UnsupportedOS;
