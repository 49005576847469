import styled from 'styled-components';
import { breakpoints, theme } from '../../../../utils/styleHelpers';

export const HeaderContainer = styled.div`
  background-image: url(${(props) => props.background});
`;

export const HeaderText = styled.h1`
  color: #ffffff;
  font-family: ${theme.fonts.familyLight};
  font-size: 58px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  width: 748px;
  ${(props) => (props.isRTL ? 'margin: 106px 131px 47px auto' : 'margin: 106px auto 47px 131px')};

  @media (max-width: ${breakpoints.bigtablet}) {
    line-height: 62px;
    width: 548px;
    ${(props) => (props.isRTL ? 'margin: 106px 131px 50px auto' : 'margin: 106px auto 50px 131px')};
  }
`;
