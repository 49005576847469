import React from 'react';
import PropTypes from 'prop-types';
import { ContentContainer, TitleText, DescriptionText } from './styles/StyleContentBox';

const propTypes = {
  titleText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired
};

const defaultProps = {
  titleText: '',
  descriptionText: ''
};

const ContentBox = ({ titleText, descriptionText }) => {
  return (
    <ContentContainer data-testid="content-box">
      <TitleText data-testid="contentbox-title">{titleText}</TitleText>
      <DescriptionText
        data-testid="contentbox-description"
        dangerouslySetInnerHTML={{ __html: descriptionText }}
      />
    </ContentContainer>
  );
};

ContentBox.propTypes = propTypes;
ContentBox.defaultProps = defaultProps;

export default ContentBox;
