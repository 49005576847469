import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getUserIISubscription } from '../../api/UCDEGateway';
import { selectUcdeUser } from '../../store/modules/ucdeUser/selectors';
import i18n from '../../utils/i18n';
import {
  initFacebookSDK,
  loadFacebookSDK,
  shareUI,
} from './Facebook/index';
import {
  RaFTooltip,
} from './Tooltip';
import {
  Card,
  CopyLink,
  EmailLink,
  FacebookLink,
  RaFClipBoardArea,
  RaFContainer,
  RaFShareSection,
  TwitterLink,
} from './styles';
import {
  CardContainer,
  CardHeader,
} from '../shared-components/styles';

const ShareSection = ({ shareURL, givenName }) => {
  const getEmailLink = () => {
    const subject = `mailto:?subject=${i18n.t('home.ReferAFriend.shareButtons.email.subject')}`;
    const body = `&body=${i18n.t('home.ReferAFriend.shareButtons.email.bodyLine1')}`
      + `${shareURL}`
      + `${i18n.t('home.ReferAFriend.shareButtons.email.bodyLine2', { accountFirstName: givenName })}`;

    return `${subject}${body}`;
  };

  const copyToClipBoard = evt => {
    evt.preventDefault();

    const copyText = document.getElementById('raf-share-url');
    copyText.select();
    copyText.setSelectionRange(0, 99999);

    document.execCommand('copy');
  };

  const twitterFullShareURL = `${i18n.t('home.ReferAFriend.shareButtons.twitter.url')}${shareURL}`;

  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <RaFShareSection>
      <RaFClipBoardArea
        type="text"
        readOnly
        value={shareURL}
        id="raf-share-url"
      />
      <TwitterLink
        href={`https:${twitterFullShareURL}`}
        alt={i18n.t('home.ReferAFriend.shareButtons.twitter.alt')}
        target="_blank"
        rel="noopener noreferrer"
      />
      <FacebookLink
        href="#"
        alt={i18n.t('home.ReferAFriend.shareButtons.facebook.alt')}
        rel="noopener noreferrer"
        onClick={evt => {
          shareUI(evt, shareURL);
        }}
      />
      <CopyLink
        href="#"
        alt={i18n.t('home.ReferAFriend.shareButtons.copyLink.alt')}
        rel="noopener noreferrer"
        onClick={evt => {
          copyToClipBoard(evt);
          setShowTooltip(true);
        }}
      >
        <RaFTooltip
          visible={showTooltip}
          onVisible={setShowTooltip}
        />
      </CopyLink>
      <EmailLink
        href={getEmailLink()}
        alt={i18n.t('home.ReferAFriend.shareButtons.email.alt')}
        rel="noopener noreferrer"
      />
    </RaFShareSection>
  );
};

const RaFContent = ({ shareURL, givenName }) => (
  <RaFContainer>
    <img src="/assets/img/dashboard/raf-top-balloon.svg" alt="RaF Icon" />
    <ol>
      <li>
        {i18n.t('home.ReferAFriend.contentLine1Start')}
        &nbsp;
        <a
          href={shareURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {shareURL}
        </a>
        &nbsp;
        {i18n.t('home.ReferAFriend.contentLine1Ending')}
        <ShareSection
          shareURL={shareURL}
          givenName={givenName}
        />
      </li>
      <li>{i18n.t('home.ReferAFriend.contentLine2')}</li>
      <li>{i18n.t('home.ReferAFriend.contentLine3')}</li>
    </ol>
    <a
      href={i18n.t('home.ReferAFriend.termsAndLinkURL')}
      target="_blank"
      rel="noopener noreferrer"
    >
      {i18n.t('home.ReferAFriend.termsAndLink')}
    </a>
  </RaFContainer>
);

const ReferAFriend = () => {
  const user = useSelector(selectUcdeUser);
  const [shareURL, setShareURL] = useState();
  const [showCard, setShowCard] = useState(false);

  async function loadUserSubscription() {
    try {
      const subscription = await getUserIISubscription({ hpId: user.externalIdMap.hpid });
      const { facebookAppId, purl, shortCode } = subscription.preferredReferAFriend;

      if (!shortCode) {
        setShowCard(false);
        return;
      }
      setShareURL(purl);
      setShowCard(true);
      loadFacebookSDK();
      initFacebookSDK({
        appId: facebookAppId,
      });
    } catch (e) {
      setShowCard(false);
    }
  }

  useEffect(() => {
    loadUserSubscription();
  }, []);

  const CardContent = () => (
    <CardContainer
      data-testid="refer-a-friend-card"
    >
      <CardHeader>
        <h5>{i18n.t('home.ReferAFriend.header')}</h5>
      </CardHeader>
      <RaFContent
        shareURL={shareURL}
        givenName={user.givenName}
      />
    </CardContainer>
  );

  return (
    <Card
      isVisible={showCard}
      appearance="dropShadow"
      content={CardContent()}
    />
  );
};

ShareSection.propTypes = {
  shareURL: PropTypes.string.isRequired,
  givenName: PropTypes.string.isRequired,
};

RaFContent.propTypes = {
  shareURL: PropTypes.string.isRequired,
  givenName: PropTypes.string.isRequired,
};

export default ReferAFriend;
