import base64url from 'base64url';
import { matchPath } from 'react-router';
import {
  GATEWAY_PATHS_ENABLED_FOR_BASE_USERS,
  PATHS,
} from './constants';

export const encodeRedirect = url => base64url.encode(JSON.stringify({
  redirectTo: url,
}));

export const encodeAuthState = (csrfToken, url, analyticsData = {}, tenantId = '', redirect_uri = '') => base64url.encode(JSON.stringify({
  ...(tenantId.trim() && { tenantId }),
  ...(redirect_uri.trim() && { redirect_uri }),
  redirectTo: url,
  csrfToken,
  ...analyticsData,
}));

export const removeCountryAndLanguage = (path = '') => path.replace(/^\/..\/../, '');

export const gatewayRouteEnabledForBaseUser = (path = '') => (
  GATEWAY_PATHS_ENABLED_FOR_BASE_USERS.some(match => matchPath(path, `/(ucde|gateway)${match}`))
);

export const signInOrOnboardingRoute = path => (
  [PATHS.ONBOARDING, PATHS.SIGNIN].includes(path.replace(/\/(ucde|gateway)/, ''))
);
