import React from 'react';
// import ReactGA from 'react-ga';

export default function HollowButtonPrimary(props) {

    function clickFn(e) {
        e.preventDefault();
        if (props.hasOwnProperty('onClick')) {
            props.onClick(e);
        }
    }
    let linkProps = {
        "className": "button hollow button--primary",
        "data-linkid": props.buttonID
    };
    if(props.hasOwnProperty('buttonLink')){
       linkProps.href = props.buttonLink;
    }else{
        linkProps.onClick = (e) => clickFn(e);
    }
    return <a id={props.ID} {...linkProps}>{props.buttonText}</a>

}