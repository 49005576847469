/**
 * This library is a wrapper to handle the Window sessionStorage object which handles the page session.
 * Should eventually handle more than just craft data by storing/handling a more complete storage object.
 * It is cleared when the page session is cleared.
 * 
 * TODO: Eventually we should put this into a class implementation and called at some higher data layer level.
 */

/**
 * Add some data to the page session. 
 * If the data is an object, stringify it and store it. Otherwise, just store it.
 * 
 * @param {*} key 
 * @param {*} data
 */
export function setSession(key, data){
    
    if(!key){
        return null;
    }
    let converted = data;
    
    if(typeof data === 'object' && data !== null){
        converted = JSON.stringify(converted);
    }
    sessionStorage.setItem(key, converted);
    return true;
};

/**
 * Get an object from the session based on the key.
 * If the data is parseable from JSON, do so and return.
 * @param {*} key 
 */
export function getSession(key) {
    
    let data = sessionStorage.getItem(key);
    
    if(!data){
        return null;
    }
    let converted = JSON.parse(data);
    
    if(!converted){
        return data;
    }
    return converted;
}