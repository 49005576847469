import React, { Component } from 'react';
import PrinterAddressChange from './PrinterAddressChange';
import classNames from 'classnames';
import bind from "./../bind";


class PrinterAddressSettings extends Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
    this.stateactive = this.props.active || false
    this.toggleAccordion = this.toggleAccordion.bind(this)
    this.isReset = false
    this.platform = this.props.platform
  }

  toggleAccordion() {
    this.$container = this.refs.accordionContainer;
    if (this.$container === undefined) return false //// necessary for cases where this is used outside of accordion - widescreen
    this.$content = this.$container.querySelector('.accordionContent');
    let animationSpeed = 0.5,
      getContentHeight = () => {
        return this.$content.scrollHeight + "px";
      };
    if (this.stateactive) {
      this.$content.style.maxHeight = getContentHeight();
      TweenLite.to(this.$content, animationSpeed, { ease: Power3.easeOut, maxHeight: "0px" });
      this.stateactive = false;
    } else {
      TweenLite.to(this.$content, animationSpeed, {
        ease: Power3.easeOut, maxHeight: getContentHeight(), onComplete: () => {
          this.$content.style.maxHeight = 'none';
        }
      });
      this.stateactive = true;
    }
  }

  handleToggle() {
    this.props.handleToggle("address");
  }

  trackingHookFn(ID) {
    document.getElementById(ID).click()
  }

  render() {
    let myPrinter = this.props.printAnywhere.data.WPPData;
    let csData = this.props.printAnywhere.data.csData;
    if (!csData || !myPrinter) { return null }

    let containerClass = classNames(
      "accordion",
      "ePrintSettingsAddress",
      { "accordion-active": this.stateactive }
    );
    // let isPending = this.props.myPrinter.isFetching && !this.props.myPrinter.isComplete && this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_EPRINT_SETTINGS_EMAIL;
    let isPending = this.props.printAnywhere.isFetching && !this.props.printAnywhere.isComplete

    return (
      <div className="printerGrid--printerCell ePrintSection--address">
        <a name="tracking-hook" id="eprint-email-cancel"></a>
        <a name="tracking-hook" id="eprint-email-submit"></a>
        <a name="tracking-hook" id="eprint-email-send-now"></a>
        <div id="accordion_container" ref="accordionContainer" className={containerClass}>
          <div>
            <PrinterAddressChange platform={this.platform} trackingHook={this.props.trackingHandler} printer={myPrinter} handleToggle={this.handleToggle} />
          </div>
        </div>
      </div>
    );
  }
}

export default bind(PrinterAddressSettings);
