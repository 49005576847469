export function refreshToTop(arg = "init") {
    let int = setInterval(
        () => {
            var thisScrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
            if (thisScrollTop == 0) {
                clearInterval(int)
            } else {
                window.scrollTo(0, 0)
            }
        }
        ,
        16
    )
}
