/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from './redux';
import { selectPageViewEventInfo } from '../selectors/analytics';
import { updateShortTitleMeta, encryptThenPageView } from '../actions/analytics';
import { usePrevious } from './utility';
import injectUDL from '../utils/injectUDL';

export const useHandlePageViewTracking = () => {
  const dispatch = useDispatch();
  const [initialPathname, setInitialPathname] = useState(null);
  const {
    pathname,
    eventInfo,
    unEncryptedDeviceID,
    ...rest
  } = useSelector(selectPageViewEventInfo);
  const prevPath = usePrevious(pathname);
  const pageViewEvent = {
    event: 'e_pageView',
    ...eventInfo,
    ...rest,
  };

  if (!initialPathname) {
    injectUDL({
      callback: () => setInitialPathname(pathname),
    });
  }
  if (window.dataLayer && eventInfo && pathname !== prevPath) {
    if (unEncryptedDeviceID) {
      dispatch(encryptThenPageView(pageViewEvent, unEncryptedDeviceID));
    } else {
      window.dataLayer.push(pageViewEvent);
      dispatch(updateShortTitleMeta(pageViewEvent.pageNameL8));
    }
  }
};
