import React from 'react';
import styled from 'styled-components';
import { useSelector } from '../../../hooks';
import { selectFootnotes } from '../../../selectors/home';
import { getLocaleFromPath } from '../../../utils/routing';
import { INSTANT_INK_URL } from '../../../constants/common';
import {
  paths,
  fontPacks,
} from '../../../utils/styleHelpers';

const Text = styled.div`
  &&&& {
    text-align: left;
    margin-top: 3%;
    ${fontPacks.caption}
    > p {
      text-align: ${props => props.isRTLSupport ? 'right' : 'left'};
      ${fontPacks.caption}
    }
  }
  margin-right: ${props => props.isRTLSupport && '1rem'};
  width: ${props => props.isRTLSupport ? '90%' : '90.5%'};

  @media screen and (min-width: 360px) {
    margin-right: ${props => props.isRTLSupport && '2rem'};
    width: ${props => !props.isRTLSupport ? '100%' : '86%'};
  }

  @media screen and (min-width: ${paths.breakTablet}) {
    margin-right: ${props => props.isRTLSupport && '2rem'};
    width: ${props => !props.isRTLSupport && '100%'};
  }

  @media screen and (min-width: ${paths.breakWide}) {
    margin-left: 5rem;
    margin-right: ${props => props.isRTLSupport && '5rem'};
    padding-right: ${props => props.isRTLSupport ? '0rem' : '8rem'};
  }
  @media screen and (min-width: ${paths.breakImmense}) {
    width: ${props => props.isRTLSupport && '93.75%'};
    margin-right: ${props => props.isRTLSupport && '5rem'};
    padding-right: ${props => props.isRTLSupport ? '0rem' : '10rem'};
  }
`;

const Footnotes = ({ isRTLSupport, isInstantInkSupported }) => {
  const body = useSelector(selectFootnotes);
  const { country, language } = getLocaleFromPath();
  const replacedBody = body.replace(`${INSTANT_INK_URL}/terms`, `${INSTANT_INK_URL}/${country}/${language}/terms`);
  return isInstantInkSupported ? <Text dangerouslySetInnerHTML={{ __html: replacedBody }} isRTLSupport={isRTLSupport} /> : null;
}

export default Footnotes;
