import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { fontPacks } from '../../utils/styleHelpers';
import { useSelector } from '../../hooks';
import {
  selectAppBadgesContentStackData,
  selectSmartAdvanceContentStackData
} from '../../selectors/craft';
import NavBar from '../revised/Global/Navbar';
import SmartAdvanceHero from './SmartAdvanceHero/SmartAdvanceHero';
import AboutSmartAdvance from './AboutSmartAdvance/AboutSmartAdvance';
import AdvancedScan from './AdvancedScan/AdvancedScan';
import Shortcuts from './shortcuts/shortcuts';
import GetStarted from './getStarted/getStarted';
import FAQs from './faqs/faqs';
import Footer from '../revised/Global/Footer';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.h3`
  ${fontPacks.titleRegularLight}
`;

export const Body = styled.div`
  ${fontPacks.bodyLarge}
  padding-left: 1.375rem;
  padding-right: 1.375rem;
`;
const SmartAdvance = (props) => {
  const { geoCountry, language } = props;
  let contentStackContent = useSelector(selectSmartAdvanceContentStackData)?.data?.entries[0];
  let appBadgesContent = useSelector(selectAppBadgesContentStackData)?.data?.entries[0];
  const isGNBLocaleToLoad = `${contentStackContent?.locale}`.split('-')[0] === `${language}`;

  if (contentStackContent && appBadgesContent && isGNBLocaleToLoad) {
    contentStackContent = contentStackContent;
    appBadgesContent = appBadgesContent;
    const {
      smart_advance_hero,
      about_section_body,
      about_section_title,
      about_section_feature,
      feature_details,
      smart_adv_app_promo,
      title,
      faq,
      faq: { faq_heading, faq_section, show_more_button_text, show_less_button_text },
      legal_disclaimer_text
    } = contentStackContent;

    const aboutSmartAdvanceData = {
      aboutSectionBody: about_section_body,
      aboutSectionTitle: about_section_title,
      aboutSectionFeature: about_section_feature
    };

    const { landing_page_app_badges } = appBadgesContent;

    return (
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <NavBar geoCountry={geoCountry} />
        <Container>
          <SmartAdvanceHero smartAdvanceHeroData={smart_advance_hero} />
          <AboutSmartAdvance aboutSmartAdvanceData={aboutSmartAdvanceData} />
          <AdvancedScan featureDetails={feature_details} />
          <Shortcuts featureDetails={feature_details} />
          <GetStarted
            smartAdvAppPromoData={smart_adv_app_promo}
            appBadges={landing_page_app_badges}
          />
          <FAQs
            heading={faq_heading}
            faqs={faq_section}
            showMore={show_more_button_text}
            showLess={show_less_button_text}
            disclaimer={legal_disclaimer_text}
          />
        </Container>
        <Footer />
      </div>
    );
  }

  return <LoaderBlock />;
};

export default SmartAdvance;
