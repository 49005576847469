import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import { selectRootMatch } from '../../../../../selectors/routing';
import { makePathRelative } from '../../../../../utils/routing';
import { getCookie } from '../../../../../utils/globals';
import { USER_TYPE } from '../../../../../constants/cookieNames';
import { selectPortalElementsProgramLevel } from '../../../store/modules/portalElements/selectors';
import { NOTIFICATION_ACTIONS_LINK_MAP } from '../../../utils/constants';

const HP_SMART_APP_SCHEMA = 'hp-smart-app';

const isUserLevelBase = () => {
  const userType = useSelector(selectPortalElementsProgramLevel) || getCookie(USER_TYPE);
  return userType.toLowerCase() === 'base';
};

export const buildLink = ({
  schema,
  key,
  path,
  parameters,
  fragment,
  link,
}) => {
  let resultLink = link;
  if (schema === HP_SMART_APP_SCHEMA) {
    const base = NOTIFICATION_ACTIONS_LINK_MAP[key];
    if (base) {
      resultLink = `${base}${path ? `/${path}` : ''}${parameters ? `?${parameters}` : ''}${fragment ? `#${fragment}` : ''}`;
    } else {
      console.error('Invalid action link key');
      resultLink = '';
    }
  }
  return resultLink;
};

export const onClickActionFunction = () => {
  const dispatch = useDispatch();
  const match = useSelector(selectRootMatch);
  const buildRelativePath = makePathRelative(match);
  const relativePath = buildRelativePath('/');
  return ({
    schema,
    key,
    path,
    parameters,
    fragment,
    link,
  }) => {
    const resultLink = buildLink({
      schema,
      key,
      path,
      parameters,
      fragment,
      link,
    });
    if (!resultLink) {
      return;
    }

    if (schema === HP_SMART_APP_SCHEMA) {
      dispatch(push(relativePath + resultLink));
    } else {
      window.open(resultLink, '_blank');
    }
  };
};

export const shouldShowAction = () => {
  const showAction = !isUserLevelBase();
  return ({
    schema,
    key,
  }) => {
    if (showAction && schema === HP_SMART_APP_SCHEMA) {
      // show only known key to link mappings
      return NOTIFICATION_ACTIONS_LINK_MAP[key];
    }
    //will hide unknown schemas - besides hp-smart-app, only http and https are valid for now
    return showAction && schema && schema.startsWith('http');
  };
};
