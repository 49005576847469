import React from 'react';
import bind from '../bind';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';
import styled, { ThemeProvider } from 'styled-components';
import {
  libraryTheme,
  MainContent,
  StyledSection,
  VisualNav,
  LibraryContainer,
  MenuSection,
  MenuSubSection,
  Widescreen,
  SectionTitle,
  StyledLink
} from '../shared-components/library-css/LibraryCss';
import Accordion from './../shared-components/organisms/Accordion';
import MenuItem from '../smart-help/MenuItem';
import WirelessMenuContent from './WirelessMenuContent';
import { wirelessPrintingTracking } from './../../lib/analyticsTracking';
import { refreshToTop } from './../../lib/refreshToTop';
import { determineDefaultLocale } from '../../sagas/watchLocationChange';

const WirelessWidescreen = styled(Widescreen)`
  padding-top: 0;
  > div:not(#accordion_home) {
    padding-top: 1rem;
  }
`;
export const StyledHeader = styled.div`
  position: relative;
  @media screen and (min-width: 64rem) {
    margin-bottom: 3rem;
  }
  picture {
    display: none;
    @media screen and (min-width: 32rem) {
      display: block;
      height: 100%;
    }
  }
  h1 {
    font-size: 1.375rem;
    @media screen and (min-width: 48rem) {
      font-size: 1.75rem;
    }
    @media screen and (min-width: 64rem) {
      font-size: 2.125rem;
    }
  }
  p {
    font-size: 1rem;
    @media screen and (min-width: 32rem) {
      width: 70%;
    }
    @media screen and (min-width: 48rem) {
      font-size: 1.375;
    }
    @media screen and (min-width: 64rem) {
      font-size: 1.75;
      width: 50%;
    }
  }
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  div {
    padding: 1rem;
    @media screen and (min-width: 32rem) {
      position: absolute;
      text-align: left;
      padding: 1rem 0 1rem 1rem;
      left: 0;
      top: 0;
    }
    @media screen and (min-width: 64rem) {
      left: 3.5rem;
      top: 50%;
      transform: translatey(-55%);
    }
  }
`;

const sortLists = (sections) => {
  return  sections.sort((firstItem, secondItem) => firstItem.index - secondItem.index)
}

class WirelessPrinting extends React.Component {
  constructor(props) {
    super(props);
    this.deeplink = !!props.match.params.topic ? props.match.params.topic.toLowerCase() : false;
    this.deeplinkTopic = !!props.match.params.subTopic
      ? props.match.params.subTopic.toLowerCase()
      : false;
    this.platform = 'web';
    this.breakpoint = 768;
    this.state = {
      groupControl: { identifier: '', group: '' },
      activeItem: '',
      is_mobile: window.innerWidth < this.breakpoint,
      is_landing: document.location.href.lastIndexOf('/') + 1 == 'wireless-printing',
      myHeight: window.innerHeight
    };
    this.trackingHandler = this.trackingHandler.bind(this);
    this.resizeFn = this.resizeFn.bind(this);
    this.groupFn = this.groupFn.bind(this);
    window.addEventListener('resize', this.resizeFn);
    this.deepLinkFn = this.deepLinkFn.bind(this);
    this.innerNavigation = this.innerNavigation.bind(this);
    this.updateAddressBar = this.updateAddressBar.bind(this);
    this.linkTracking = this.linkTracking.bind(this);
    this.new_slugify = this.new_slugify.bind(this);
    this.do_once = 0;
    this.counter = 0;
    this.initialized = 0;
    this.deeplinkingInProgress = false;
    this.homeID = 'home';
  }

  componentDidUpdate() {
    let exists = document.getElementById(this.deeplink);
    let home_exists = document.getElementById(this.homeID);
    if (this.props.wirelessSection.isComplete && exists !== null) {
      if (this.do_once < 1) {
        this.do_once++;
        refreshToTop();
        setTimeout(() => {
          window.scrollTo(0, 0);
          this.deepLinkFn();
        }, 1000);
      }
    } else if (this.props.wirelessSection.isComplete && home_exists !== null) {
      if (this.do_once < 1) {
        this.do_once++;
        setTimeout(() => {
          home_exists.click();
        }, 500);
      }
    }
  }

  componentWillMount() {
    const {
      match: {
        params: {
          country = 'us',
          language = 'en'
        }
      }
    } = this.props;
    const locale = `${language}_${country}`.toLowerCase();
    if (locale === 'ja_jp') {
      window.location = 'http://jp.ext.hp.com/printers/personal/inkjet/special/';
    } else if (
      locale === 'no_no' ||
      locale === 'da_dk' ||
      locale === 'fi_fi' ||
      locale === 'sv_se' ||
      locale === 'sv_fi'
    ) {
      // REDIRECT TO US/EN
      const page = window.location.pathname;
      const { search } = window.location;
      let pageAndQuery = '';
      if (page.length > 6 || search.length > 0) {
        pageAndQuery = page.substr(6) + search;
      }
      const defaultCountry = 'us';
      const defaultLanguage = 'en';
      const redirect = `/${defaultCountry}/${defaultLanguage}${pageAndQuery}`;
      this.props.updateLocale(`${defaultCountry}-${defaultLanguage}`, () => {
        window.location = redirect;
      });
    } else {
      const localeCS = locale.replace('_', '-');
      this.props.getLibraryLanding(localeCS);
      this.props.getLibraryVisualNav(localeCS);
      this.props.getLibrarySection(localeCS);
      this.props.getLibraryTopic(localeCS);
      this.props.getLibrarySubject(localeCS);
      this.props.getLibraryLink(localeCS);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFn);
  }

  innerNavigation(path) {
    let paths = path.split('/');
    for (let i = 0; i < paths.length; i++) {
      if (paths[i] == '') {
        paths.splice(i, 1);
      }
    }
    this.deeplink = paths[0];
    this.deeplinkTopic = paths.length > 1 ? paths[1] : false;
    setTimeout(() => {
      const deeplink_exists = !!document.getElementById(this.deeplink)
        ? document.getElementById(this.deeplink)
        : false;
      if (deeplink_exists) {
        deeplink_exists.click();
      }
      if (this.deeplinkTopic) {
        const deeplinkTopic_exists = !!document.getElementById(this.deeplinkTopic + '_subheader')
          ? document.getElementById(this.deeplinkTopic + '_subheader')
          : false;
        if (deeplinkTopic_exists) {
          deeplinkTopic_exists.click();
        }
      }
    }, 100);
  }

  deepLinkFn() {
    if (typeof this.deeplink == 'string' && this.deeplink.length > 0) {
      const should_scroll = this.state.is_mobile; /// how to tell if we need to scroll?
      const clickit = (x) => {
        this.deeplinkingInProgress = true;
        document.getElementById(this.deeplink).click();
      };
      const delayed_click = (o) => {
        this.groupFn({ myID: 'anystring', groupID: 'underAccordion' });
        setTimeout(clickit, 10);
      };
      const delayed_scroll = (o) => {
        const oTween = TweenLite.to(window, 1.275, {
          scrollTo: `#${this.deeplink}`,
          ease: Power2.easeOut
        });
        setTimeout(delayed_click, 600);
      };
      if (should_scroll) {
        const delayed_act = (o) => {
          const gottenElement = document.getElementById(`accordion_${this.deeplink}_inner_lining`);
          const gottenHeight = gottenElement.getBoundingClientRect().height;
          const under_accordion = document.getElementById('accordion_under_animator');
          TweenLite.set(under_accordion, { height: gottenHeight });
          setTimeout(delayed_scroll, 100);
        };
        setTimeout(delayed_act, 20);
      } else {
        clickit();
      }
    }
  }

  resizeFn = () => {
    const debounce = (func, delay) => {
      let inDebounce;
      return (function () {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), delay);
      })();
    };
    const resizeLayout = () => {
      if (this.state.is_mobile !== window.innerWidth < this.breakpoint) {
        this.setState({
          is_mobile: window.innerWidth < this.breakpoint
        });
        if (this.state.is_mobile == false) {
          this.innerNavigation(this.homeID);
        }
      }
      if (this.state.myHeight !== window.innerHeight) {
        this.setState({
          myHeight: window.innerHeight
        });
      }
    };
    debounce(resizeLayout, 700);
  };

  groupFn = (o) => {
    if (`accordion_${this.state.activeItem}` === o.myID) {
      const home = document.getElementById(this.homeID);
      if (!!home) {
        home.click();
      } else {
        wirelessPrintingTracking(this.homeID);
      }
    } else {
      if (this.state.activeItem === this.homeID) {
        const home = document.getElementById(this.homeID);
        if (!!home) {
          home.click();
        }
      }
      this.setState({
        groupControl: { identifier: o.myID, group: o.groupID }
      });
    }
  };

  updateAddressBar(thisID) {
    if (thisID === this.homeID) {
      return;
    }
    const search = window.location.search;
    const window_location_array = window.location.href.split('?')[0].toLowerCase().split('/');
    const indexOfWirelesPrintingSegmentRoot = window_location_array.indexOf('wireless-printing');
    window_location_array.length = indexOfWirelesPrintingSegmentRoot + 1;
    window_location_array.push(thisID);
    let strNewPath = window_location_array.join('/') + search;
    //// REMOVE TRAILING /
    if (strNewPath.substr(-1) === '/') {
      strNewPath = strNewPath.substr(0, strNewPath.length - 1);
    }
    if (window.history.pushState) {
      window.history.replaceState({ foo: 'bar' }, 'page two', strNewPath);
    }
  }

  callbackFn = (isOpen, thisID) => {
    if (isOpen) {
      wirelessPrintingTracking(thisID);
    }
    const oneZero = isOpen ? 1 : 0;
    const icn = `#${thisID}_arrow`;
    TweenLite.to(icn, 0.41, { rotationX: -180 * oneZero });
    if (isOpen) {
      this.setState({ activeItem: thisID });
      if (this.deeplinkingInProgress) {
        this.deeplinkingInProgress = false;
      } else {
        this.updateAddressBar(thisID);
      }
    } else {
      if (thisID !== 'under_animator') {
        this.updateAddressBar('');
      }
    }
  };

  trackingHandler(ID) {
    document.getElementById(ID).click();
  }

  linkTracking(thisID) {
    const doNotTrackPageView = true;
    wirelessPrintingTracking(thisID, doNotTrackPageView);
  }

  slugify(str = '') {
    if (typeof str === 'string') {
      // bypass:
      // str = str.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')
      return str;
    }
  }

  new_slugify(str = '') {
    if (typeof str === 'string') {
      str = str
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
      return str;
    }
  }

  render() {
    let { wirelessLanding, wirelessVisualNav, wirelessSection, wirelessTopic, wirelessSubject, wirelessInk } = this.props;
    
    if (
      wirelessLanding.isComplete &&
      !wirelessLanding.isError && 
      wirelessVisualNav.isComplete &&
      !wirelessVisualNav.isError &&
      wirelessSection.isComplete &&
      !wirelessSection.isError &&
      wirelessTopic.isComplete &&
      !wirelessTopic.isError &&
      wirelessSubject.isComplete &&
      !wirelessSubject.isError &&
      wirelessInk.isComplete &&
      !wirelessInk.isError
    ) {
      
      let wirelessLandingData = wirelessLanding.data;
      let wirelessVisualNavData = sortLists(wirelessVisualNav.data);
      let wirelessSectionData = sortLists(wirelessSection.data);
      let wirelessTopicData = sortLists(wirelessTopic.data);
      let wirelessSubjectData = sortLists(wirelessSubject.data);
      let wirelessInkData = wirelessInk.data;
    
      let libraryHeader =
        wirelessLandingData.library_heading && wirelessLandingData.library_description && (
          <StyledHeader>
            <picture>
              <source srcSet="/assets/img/wireless_hero.webp" type="image/webp" />
              <source srcSet="/assets/img/wireless_hero.jpg" type="image/jpeg" />
              <img src="/assets/img/wireless_hero.jpg" alt="" />
            </picture>
            <div>
              <h1>{wirelessLandingData.library_heading}</h1>
              <p>{wirelessLandingData.library_description}</p>
            </div>
          </StyledHeader>
        );
      return (
        <ThemeProvider theme={libraryTheme}>
          <MainContent id="main-content" currentPlatform={this.platform}>
            <LibraryContainer currentPlatform={this.platform}>
              {this.state.is_mobile && libraryHeader}
              <MenuSection
                currentPlatform={this.platform}
                isMobile={this.state.is_mobile}
                isInApp={false}
                myHeight={this.state.myHeight}
              >
                {wirelessSectionData.map((wirelessSection) => {
                  const { uid, title } = wirelessSection;
                  return (
                    <MenuSubSection key={wirelessSection.id} isMobile={this.state.is_mobile}>
                      {wirelessSection.hasOwnProperty('library_section_reference') && (
                        <SectionTitle
                          id={`${this.slugify(uid)}_title`}
                          currentPlatform={this.platform}
                          isMobile={this.state.is_mobile}
                        >
                          {title}
                        </SectionTitle>
                      )}
                      {wirelessSection.hasOwnProperty('library_section_reference') &&
                        wirelessSection.library_section_reference.map((section) => {
                          const { uid, _content_type_uid } = section;
                          const library_topic = wirelessTopicData.filter((data) => data.uid.includes(uid));
                          const library_link = wirelessInkData.filter((data) => data.uid.includes(uid));
                          const {
                            uid: topicId,
                            slug: topicUid,
                            title: topicTitle,
                            library_content: topicContent,
                            library_content_reference: topicContentReference
                          } = _content_type_uid == "library_topic" && library_topic[0];
                          const {
                            uid: linkUid,
                            title: linkTitle,
                            library_link: linkLink
                          } = _content_type_uid == "library_link" && library_link[0];
                          
                          return (
                            <div key={uid}>
                              {_content_type_uid == "library_topic" && (
                                <MenuItem
                                  isMobile={this.state.is_mobile}
                                  key={section.id}
                                  ID={this.slugify(topicUid)}
                                  active={this.state.activeItem == this.slugify(topicUid)}
                                  accordionTitle={topicTitle}
                                  trackingHandler={this.trackingHandler}
                                  currentPlatform={this.platform}
                                />
                              )}
                              {_content_type_uid == "library_link" && 
                                linkLink.map((section) => {
                                  const {
                                    link_components
                                  } = section;
                                  const {
                                    analytics_id: analyticsID,
                                    link_address: linkAddress,
                                    link_text: linkText
                                  } = link_components;
                                  return (
                                    <StyledLink
                                      href={linkAddress}
                                      onClick={() =>
                                        this.linkTracking(this.new_slugify(analyticsID))
                                      }
                                      isMobile={this.state.is_mobile}
                                    >
                                      {linkText}
                                      <img alt="" src="/assets/img/exitLink.svg" />
                                    </StyledLink>
                                  );
                                }
                              )}

                              {this.state.is_mobile &&
                                _content_type_uid == "library_topic" && (
                                  <Accordion
                                    ID={this.slugify(topicUid)}
                                    actionID={this.slugify(topicUid)}
                                    groupID={'MAIN_groupID'}
                                    groupFn={this.groupFn}
                                    controler={this.state.groupControl}
                                    callbackFn={this.callbackFn}
                                    addSeconds={0.25}
                                    offsetSeconds={0.13}
                                    isSubAccordionOpen={false}
                                  >
                                    {topicContent && topicContent.length > 0 &&  (
                                      <StyledSection
                                        isMobile={this.state.is_mobile}
                                        dangerouslySetInnerHTML={{
                                          __html: `${topicContent[0]?.library_content_text_block?.library_content_text}`
                                        }}
                                      />
                                    )}
                                    {topicContentReference && (
                                      <WirelessMenuContent
                                        deeplinkTopic={this.deeplinkTopic}
                                        isMobile={this.state.is_mobile}
                                        section={topicContentReference}
                                        subjects={wirelessSubjectData}
                                        ID={this.slugify(topicUid)}
                                      />
                                    )}
                                  </Accordion>
                                )
                              }
                            </div>
                          )
                        })}
                    </MenuSubSection>
                  );
                })}
                {this.state.is_mobile && (
                  <Accordion
                    ID="under_animator"
                    groupID="underAccordion"
                    groupFn={this.groupFn}
                    controler={this.state.groupControl}
                    callbackFn={this.callbackFn}
                    addSeconds={0.25}
                    isSubAccordionOpen={true}
                  >
                    {' '}
                  </Accordion>
                )}
              </MenuSection>

              {this.state.is_mobile || (
                <WirelessWidescreen isInApp={false} myHeight={this.state.myHeight}>
                  <a id={this.homeID}></a>
                  <Accordion
                    ID={this.homeID}
                    actionID={this.homeID}
                    groupID={'HOME_groupID'}
                    groupFn={this.groupFn}
                    controler={this.state.groupControl}
                    callbackFn={this.callbackFn}
                    addSeconds={0.25}
                    offsetSeconds={0.13}
                    isSubAccordionOpen={false}
                  >
                    {libraryHeader}

                    <VisualNav>
                      {wirelessLandingData.library_nav_reference
                        ? wirelessLandingData.library_nav_reference.map((section, i) => {
                          const { uid, _content_type_uid } = section;
                          const library_visualnav = wirelessVisualNavData.filter((data) => data.uid.includes(uid));
                          const {
                            uid: visualnavUid,
                            title: visualnavTitle,
                            library_visual_nav: visualnavGroup
                          } = library_visualnav[0];
                          return (
                            <div key={i}>
                              <h3>{visualnavTitle}</h3>
                              {visualnavGroup.map((visualnavData, n) => {
                                const {
                                  visual_nav_group
                                } = visualnavData;
                                const {
                                  visual_nav_link,
                                  visual_nav_link_text
                                } = visual_nav_group;
                                return (
                                  <a
                                    key={n}
                                    onClick={() => this.innerNavigation(visual_nav_link)}
                                  >
                                    {visual_nav_link_text}
                                  </a>
                                );
                              })}
                            </div>
                          );
                        })
                        : console.log('Missing Library Landing data')}
                    </VisualNav>
                  </Accordion>

                  {wirelessSectionData.map((wirelessSection) => (
                    <div key={wirelessSection.id}>
                      {wirelessSection.hasOwnProperty('library_section_reference') &&
                        wirelessSection.library_section_reference.map((section) => {
                          const { uid, _content_type_uid } = section;
                          const library_topic = wirelessTopicData.filter((data) => data.uid.includes(uid));
                          const {
                            uid: topicId,
                            slug: topicUid,
                            title: topicTitle,
                            library_content: topicContent,
                            library_content_reference: topicContentReference
                          } = _content_type_uid == "library_topic" && library_topic[0];
                          
                          return (
                            <div key={uid}>
                              <Accordion
                                ID={this.slugify(topicUid)}
                                actionID={this.slugify(topicUid)}
                                groupID={'MAIN_groupID'}
                                groupFn={this.groupFn}
                                controler={this.state.groupControl}
                                callbackFn={this.callbackFn}
                                addSeconds={0.25}
                                offsetSeconds={0.13}
                                isSubAccordionOpen={false}
                              >
                                {topicContent && topicContent.length > 0 &&  (
                                  <StyledSection
                                    dangerouslySetInnerHTML={{
                                      __html: `${topicContent[0]?.library_content_text_block?.library_content_text}`
                                    }}
                                  />
                                )}

                                {topicContentReference && (
                                  <WirelessMenuContent
                                    deeplinkTopic={this.deeplinkTopic}
                                    isMobile={this.state.is_mobile}
                                    section={topicContentReference}
                                    subjects={wirelessSubjectData}
                                    ID={this.slugify(topicUid)}
                                  />
                                )}
                              </Accordion>
                            </div>
                          );
                        })}
                    </div>
                  ))}
                </WirelessWidescreen>
              )}
            </LibraryContainer>
          </MainContent>
        </ThemeProvider>
      );
    } else if (wirelessSection.isError) {
      return <p>{wirelessSection.errorMsg}</p>;
    } else {
      return (
        <div className="contentLoading">
          <LoaderBlock />
        </div>
      );
    }
  }
}

export default bind(WirelessPrinting);
