import React, { Component } from 'react';
import HollowButton from '../shared-components/atoms/hollowButton';
import { setOnboardingCookie } from '../../lib/manageCookies';
import AreaTypes from "../../constants/AreaTypes";
import classNames from 'classnames';
import bind from "../bind";
import LoaderElement from "../shared-components/molecules/LoaderElement";
import { RTL_LANGUAGES } from '../../constants/i18n';

class EprintAddressChange extends Component {
  constructor(props) {
    super(props);
    this.stateObj = {
      addressValue: '',
      valid: true,
      validMessage: "",
      dirty: false,
    };
    this.isPending = this.isPending.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAddressKeyUp = this.onAddressKeyUp.bind(this);
    this.input_ID = this.props.ID ? this.props.ID + "_email_input" : "email_input"
    this.track_submit_signature = this.props.ID === "onboarding_modal" ? "onboarding-modal-submit" : "eprint-email-submit"
  }

  isPending() {
    return this.props.myPrinter.isFetching && this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_EPRINT_SETTINGS_EMAIL;
  }

  getValidationGauntlet = function (addressSettingData) {
    return [
      {
        message: addressSettingData.eprint_email_change_error_consecutive_dots,
        validate: (value) => {
          let regex = /.*\.\..*/;
          return regex.test(value);
        }
      },
      {
        message: addressSettingData.eprint_email_change_error_more_than_two_dots,
        validate: (value) => {
          let regex = /.*\..*\..*\..*/;
          return regex.test(value);
        }
      },
      {
        message: addressSettingData.eprint_email_change_error_more_underscores,
        validate: (value) => {
          // let regex = /[_]{2,}/;
          let regex = /.*\_.*\_.*/;
          return regex.test(value);
        }
      },
      {
        message: addressSettingData.eprint_email_change_error_character_length, //TODO: Move this to submit action!
        validate: (value) => {
          return value.length > 30;
        }
      },
      {
        message: addressSettingData.eprint_email_change_error_invalid_characters,
        validate: (value) => {
          let regex = /[^_A-Za-z0-9\-\.]/;
          return regex.test(value);
        }
      },
      {
        message: addressSettingData.eprint_email_change_error_invalid_characters, //////////// NEEDS NEW ERROR MESSAGE FOR RESERVED WORDS
        validate: (value) => {
          let reservedWords = [
            "Noc",
            "www",
            "hp.domains",
            "Webmaster",
            "Marketing",
            "Admin",
            "News",
            "Sales",
            "Uucp",
            "administrator",
            "Info",
            "Postmaster",
            "Clamav",
            "Abuse",
            "Hostmaster",
            "Donotreply",
            "Usenet",
            "Security",
            "Support",
            "ftp",
            "Root"
          ]
          for (let i = reservedWords.length - 1; i >= 0; i--) {
            // if (value.toLowerCase().includes(reservedWords[i].toLowerCase())) { //// return if reserved included within string
            if (value.toLowerCase() === reservedWords[i].toLowerCase()) { //// return if value IS reserved
              return true
            }
          }
          return false
        }
      }
    ];
  };

  validateValue = (value, printerData) => {
    let validationGauntlet = this.getValidationGauntlet(printerData);
    for (let i = 0, len = validationGauntlet.length; i < len; i++) {
      let currConfig = validationGauntlet[i];
      if (currConfig.validate(value)) {
        return currConfig.message;
      }
    }
    return true;
  };

  validateAddress() {
    let message = this.validateValue(this.stateObj.addressValue, this.props.myPrinter.data.addressSettingData);
    if (message === true) {
      this.removeErrorMessage()
      return true;
    } else {
      this.removeErrorMessage()
      this.stateObj.validMessage = message
      this.stateObj.valid = false
      this.forceUpdate()
      return false;
    }
  }

  removeErrorMessage = () => {
    this.stateObj.validMessage = null
    this.stateObj.valid = true
    this.stateObj.dirty = this.stateObj.addressValue != ""
    this.props.clearUpdateMyPrinterAddressStatus()
    this.forceUpdate()
  }

  onFormSubmit($event) {
    $event.preventDefault();
    $event.stopPropagation();
    return true;
  }

  handleCancel(e) {
    e.preventDefault();
    if (this.isPending()) {
      return false;
    }
    if (this.props.ID !== "onboarding_modal") {
      this.props.trackingHook("eprint-email-cancel");
    }
    this.removeErrorMessage()
    document.getElementById(this.input_ID).value = ""
    this.stateObj.addressValue = ""
    this.stateObj.valid = true
    this.stateObj.dirty = false
    this.stateObj.validMessage = ''

    if (this.props.gnb.isTablet || this.props.inModal) {
      this.props.handleToggle();
    }
  }

  handleSubmit(e) {
    this.props.myPrintersAltered()
    e.preventDefault();
    this.props.trackingHook(this.track_submit_signature);
    if (this.isPending()) {
      return false;
    }
    if (!this.stateObj.dirty || this.stateObj.addressValue.length < 6) {
      if (!this.stateObj.dirty) {//Input contains an empty string
        this.stateObj.validMessage = this.props.myPrinter.data.addressSettingData.eprint_email_change_error_character_length
      } else {//Input contains a string less than 6 characters
        this.stateObj.validMessage = this.props.myPrinter.data.addressSettingData.eprint_email_change_error_character_length
      }
      this.stateObj.valid = false
      this.stateObj.dirty = true
      this.forceUpdate()
      return;
    }

    if (!this.validateAddress()) {
      return;
    }

    // so that we don't show onboarding after address change:
    setOnboardingCookie(this.props.myPrinter.data.WPPData.printerId)
    //// CALL REDUX
    this.props.updateMyPrinterAddress(this.props.myPrinter.data.WPPData.ownershipId, this.props.myPrinter.data.WPPData.printerId, this.stateObj.addressValue, () => {
      document.getElementById(this.input_ID).value = ""
      this.stateObj.addressValue = ""
      this.stateObj.dirty = false
      this.forceUpdate()
    });
  }

  onAddressKeyUp(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.keyCode === 13) {
      this.handleSubmit(e);
      return true;
    }
    this.stateObj.addressValue = e.target.value
    this.validateAddress();
    this.stateObj.dirty = true
  }

  ///////////////////////////////////////////////////////////////////////////////
  render() {
    const language = window.location.pathname.split('/')[2];
    const isRTLSupport = RTL_LANGUAGES.includes(language);
    let printer = this.props.myPrinter.data.WPPData;
    let addressSettingData = this.props.myPrinter.data.addressSettingData;
    let miscellaneousData = this.props.myPrinter.data.miscellaneousData;
    let inputValidationClasses = classNames({
      'feedbackMessage--error': this.stateObj.valid || !this.stateObj.dirty,
      'feedbackMessage--error-visible': !this.stateObj.valid && this.stateObj.dirty
    });
    let formSuccess = this.stateObj.valid &&
      this.stateObj.validMessage != "" &&
      !this.stateObj.dirty &&
      this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_EPRINT_SETTINGS_EMAIL;
    let formSuccessClasses = classNames({
      'feedbackMessage--success': !formSuccess,
      'feedbackMessage--success-visible': formSuccess
    });
    let formErrorClasses = classNames({
      'feedbackMessage--error': !this.props.myPrinter.isError || this.props.myPrinter.currentArea !== "MYPRINTER_EPRINT_SETTINGS_EMAIL",
      'feedbackMessage--error-visible': this.props.myPrinter.isError && this.props.myPrinter.currentArea === "MYPRINTER_EPRINT_SETTINGS_EMAIL"
    });
    let cancelButtonAttrs = {
      buttonText: addressSettingData.eprint_email_change_cancel_action,
      onClick: this.handleCancel,
      buttonLink: '#'
    };
    let submitButtonAttrs = {
      buttonText: addressSettingData.eprint_email_change_submit_action,
      onClick: this.handleSubmit,
      buttonLink: '#'
    };
    if (this.isPending()) {
      cancelButtonAttrs.disabled = true;
      submitButtonAttrs.disabled = true;
    }
    if (isRTLSupport) {
      submitButtonAttrs.isRTLSupport = true;
    } else {
      submitButtonAttrs.isRTLSupport = false;
    }
    let isPending = !this.props.inTablet && this.props.myPrinter.isFetching && !this.props.myPrinter.isComplete && this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_EPRINT_SETTINGS_EMAIL;
    return (
      <form className='ePrintAddressChange' onSubmit={this.onFormSubmit}>
        <h4 className="setting--title" >{addressSettingData.eprint_email_change_title}</h4>
        {/* TODO: Uncomment the address and addressLabel below and connect the addressLable to Craft once the change has been approved and translated */}
        <div className="ePrintAddressChange--addressLabel">{miscellaneousData.eprint_email_current_description}</div>
        <div className="ePrintAddressChange--address">
          {isPending ? <LoaderElement /> : this.props.printer.printerEmail}
        </div>

        <div className={`${isRTLSupport ? "ePrintAddressChange--inputArea-rtl " : ""}ePrintAddressChange--inputArea`}>
          <input id={this.input_ID} type="text" placeholder={addressSettingData.eprint_email_change_placeholder} onKeyUp={this.onAddressKeyUp} disabled={isPending} />
          <span className="ePrintAddressChange--domain">@{printer.emailDomain}</span>
        </div>

        <span className={inputValidationClasses}>{this.stateObj.validMessage}</span>
        <div className="ePrintAddressChange--buttonPair">
          <HollowButton {...cancelButtonAttrs} />
          <HollowButton {...submitButtonAttrs} />
        </div>
        <span className={formSuccessClasses}>{addressSettingData.eprint_email_change_success}</span>
        <span className={formErrorClasses}>{addressSettingData.eprint_email_change_duplicate}</span>
        <p>{addressSettingData.eprint_email_change_description}</p>
      </form>
    )
  };
}

export default bind(EprintAddressChange);