import React from 'react';
import {
  SmartAdvanceHeroContainer,
  SmartAdvanceHeroContent,
  AdvButton,
  Heading,
  Body
} from './styles';
import { getHtmlDir } from '../../../utils/globals';

const SmartAdvanceHero = (props) => {
  const { smartAdvanceHeroData } = props;
  const smartAdvanceData = smartAdvanceHeroData.length && (smartAdvanceHeroData[0])?.carousel_panel;
  const { body, text_icon, heading } = smartAdvanceData;
  const lastIndex = heading.lastIndexOf(' ');
  const firstHeading = heading.substring(0, lastIndex);
  const lastHeadingWord = heading.split(' ').pop();

  const direction = getHtmlDir();

  return (
    <SmartAdvanceHeroContainer direction = { direction } smartAdvanceHeroData={smartAdvanceData}>
      <SmartAdvanceHeroContent direction = { direction }>
        <AdvButton>{text_icon}</AdvButton>
        <Heading>
          {`${firstHeading} `}
          <span className="yellow-font">{lastHeadingWord}</span>
        </Heading>
        <Body dangerouslySetInnerHTML={{ __html: body }} />
      </SmartAdvanceHeroContent>
    </SmartAdvanceHeroContainer>
  );
};

export default SmartAdvanceHero;
