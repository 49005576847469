import styled from 'styled-components';
import { breakpoints } from '../../../../../utils/styleHelpers';

export const Header = styled.header`
  text-align: center;
`;

export const SubTitle = styled.h2`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
`;
export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: #027aae;
`;

export const MobileConatiner = styled.div`
  overflow: auto;
  display: inline;
  font-size: 0.75rem;
  padding: 0rem;
`;

export const MobCols = styled.div`
  float: left;
  width: 25%;
  text-align: center;
  margin: 0.3125rem 0rem 0.3125rem 0rem;
  border-left: 0.0625rem solid #dbdbdb;
  font-size: 0.75rem;
  font-weight: bold;
  vertical-align: middle;
  padding: 0rem;
  p {
    margin: 0.3125rem;
  }
  &:nth-child(1) {
    border-left: none;
    color: #e87722;
  }
`;

export const ColHeader = styled.div`
  font-size: 0.875rem;
`;

export const HeaderBonus = styled.div`
  padding: 0.25rem;
  color: #f4f4f4;
  width: 25%;
  text-align: center;
  border-radius: 0.9375rem 0.9375rem 0rem 0rem;
  background-color: #e87722;
`;

export const MobileHeader = styled.div`
  background: #f4f4f4;
  display: flex;
  align-items: center;
  box-shadow: 0rem 0.25rem 1rem rgba(34, 34, 34, 0.15);
`;
export const FeatureTitle = styled.div`
  text-align: center;
  margin-top: 1.25rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
`;

export const MobileSection = styled.div`
    display: flex;
    border-bottom: 0.0625rem solid #dbdbdb;
    &:last-child{
      > #Mob-Cols {
        p{
          font-size: 0.75rem;
          @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 0.625rem;
          }
        }
      }
`;

export const Tooltip = styled.div`
  position: relative;
  font-size: 1.25rem;
  padding-left: 0.3125rem;
  color: #cdcdcd;
  &:hover > span {
    visibility: visible;
  }
`;

export const TooltipText = styled.span`
  visibility: hidden;
  width: 12.5rem;
  font-size: 0.75rem;
  background-color: #404040;
  color: #fff;
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  top: 130%;
  right: -1.063rem;
  &&:after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 1.25rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: transparent transparent #404040 transparent;
  }
`;
