import React, { Component } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import SubscriptionSection from './SubscriptionSection';
import AccordionSubscriptionContent from './AccordionSubscriptionContent';
import bind from '../../bind';
import { getInstantInkUrl } from '../../../lib/urlUtils';
import NewAccordion from '../../shared-components/organisms/NewAccordion';
import Accordion from '../../shared-components/organisms/Accordion';
import AccordionTitle from "./../../shared-components/molecules/AccordionTitle";
import BlueCheckmark from "./../../shared-components/atoms/BlueCheckmark";

class InstantInkEligible extends Component {
  constructor(props) {
    super(props);
    this.keyID = 'ink-eligible';
    this.onActionClicked = this.onActionClicked.bind(this);
  }

  onActionClicked() {
    const navLink = document.createElement("a");
    navLink.setAttribute('href', this.getIIUrl());
    navLink.setAttribute('target', '_blank');
    navLink.click();
  }

  getIIUrl() {
    return getInstantInkUrl(this.props.config.data.Get('II_BASE_URL'), this.props.myPrinter.data.WPPData.printerId, this.props.locales.alt3);
  }

  render() {
    const {
      printAnywhereWebIsEnabled,
      gnb,
      myPrinter,
    } = this.props;
    const subscriptionData = myPrinter.data.subscriptionData;
    const { isTablet } = gnb;
    const OldContent = isTablet
      ? (
        <div
          className={classNames('accordion', 'inkSettingsInstant', 'myPrinterSetting')}
          id={`container_${this.keyID}`}
        >
          <AccordionTitle
            loading={false}
            ID={this.keyID}
            accordionTitle={subscriptionData.instant_ink_eligibility_title}
          >
            <span className="accordionTitle--status accordionTitle--status-checked">
              <BlueCheckmark />
            </span>
          </AccordionTitle>
          <Accordion
            ID={this.keyID}
            actionID={this.keyID}
            groupID="InkSettings"
            groupFn={this.props.groupFn}
            controler={this.props.controler}
            callbackFn={this.callbackFn}
          >
            <AccordionSubscriptionContent
              ID="ink-eligible-learn-more-content"
              description={subscriptionData.instant_ink_eligibility_description}
              actionText={subscriptionData.instant_ink_eligibility_action_text}
              onActionClicked={this.onActionClicked}
            />
          </Accordion>
        </div>
      )
      : (
        <SubscriptionSection
          sectionHeader={subscriptionData.instant_ink_eligibility_title}
          showCheckmark
          sectionDescription={subscriptionData.instant_ink_eligibility_description}
          sectionActionText={subscriptionData.instant_ink_eligibility_action_text}
          sectionActionId="ink-eligible-learn-more-btn"
          onActionClicked={this.onActionClicked}
        />
      );

    return (
      <div className="printerGrid--printerCell promoSection--iiEligible">
        <a name="tracking-hook" id="ink-eligible-learn-more" />
        {
          printAnywhereWebIsEnabled
            ? (
              <NewAccordion
                title={(
                  <IITitle>
                    {subscriptionData.instant_ink_eligibility_title}
                    <BlueCheckmark />
                  </IITitle>
                )}
                noCollapse={!isTablet}
              >
                <AccordionSubscriptionContent
                  ID="ink-eligible-learn-more-content"
                  description={subscriptionData.instant_ink_eligibility_description}
                  actionText={subscriptionData.instant_ink_eligibility_action_text}
                  onActionClicked={this.onActionClicked}
                />
              </NewAccordion>
            )
            : OldContent
        }
      </div>
    );
  }
}

export default bind(InstantInkEligible);

const IITitle = styled.div`
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  svg {
    margin-left: .5rem;
  }
`;
