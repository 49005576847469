import React from 'react';
import { isEmpty } from 'ramda';
import styled from 'styled-components';
import { TemplateSection } from '../shared-components/shared-styles/TemplateStyles';

const HeroSection = styled(TemplateSection)`
padding-top: 3rem;
@media screen and (min-width: 40rem) {
  padding-top: 4rem;
}
`;
const HeroHeadline = styled.div`
  text-align: center;
  margin-top: 3.125rem;
  padding: 0 1.25rem;
  max-width: 52rem;
  @media screen and (min-width: 40rem) {
    margin-top: 3.75rem;
  }
  h1 {
    font-size: 1.555rem;
    font-weight: 600;
    line-height: 1;
    @media screen and (min-width: 40rem) {
      font-weight: 300;
      font-size: 3rem;
    }
    @media screen and (min-width: 64rem) {
      font-size: 3.75rem;
    }
  }
`;
const HeroImage = styled.picture`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
max-width: 50rem;
margin: auto;
padding: 1rem;
@media screen and (min-width: 40rem) {
  padding: 2rem;
}
height: 100%;
  img {
  flex: 1 1 0px;
}
`;
export default function PrinterHero(props) {
  const {
    bannerImages,
    printerName,
  } = props;
  if (!bannerImages || isEmpty(bannerImages)) {
    return null;
  }

  const {
    printerImageLargeTitle,
    printerImageLargeUrl,
    printerImageMediumUrl,
    printerImageSmallUrl,
  } = bannerImages;

  return (
    <HeroSection id="bannerSection">
      <HeroHeadline>
        <h1>{printerName}</h1>
      </HeroHeadline>
      <HeroImage>
        <source
          media="(min-width: 800px)"
          srcSet={printerImageLargeUrl}
        />
        <source
          media="(min-width: 480px)"
          srcSet={printerImageMediumUrl}
        />
        <source
          media="(max-width: 479px)"
          srcSet={printerImageSmallUrl}
        />
        <img
          src={printerImageLargeUrl}
          alt={printerImageLargeTitle}
        />
      </HeroImage>
    </HeroSection>
  );
}
