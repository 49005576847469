import React from 'react';
import PropTypes from 'prop-types';
import {
  PagesWrapper,
  PagesText,
  PagesPie,
  PagesPie180,
  PagesPie90,
  PagesGauge180,
  PagesGauge90,
} from './styles';

const DevicePaperGauge = ({ level, maxLevel }) => {
  const max = Math.max(1, maxLevel);
  const percent = (100 * level) / max;
  return (
    <PagesWrapper level={percent}>
      <PagesPie>
        <PagesPie90>
          <PagesGauge90 />
        </PagesPie90>
        <PagesPie180>
          <PagesGauge180 />
        </PagesPie180>
      </PagesPie>
      <PagesText>
        {level}
        /
        {max}
      </PagesText>
    </PagesWrapper>
  );
};

DevicePaperGauge.defaultProps = {
  maxLevel: 100,
};

DevicePaperGauge.propTypes = {
  level: PropTypes.number.isRequired,
  maxLevel: PropTypes.number,
};

export default DevicePaperGauge;
