import styled from 'styled-components';

export const LoaderContent = styled.div`
  display: flex;
  min-height: calc(100vh - 190px);
  
  div {
    margin-top: 0;
  }  
`;

export default LoaderContent;
