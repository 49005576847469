import {
  curry,
  prop,
  is,
  has,
  both,
  isNil,
} from 'ramda';
import { useSelector } from '.';
import { selectRootMatch } from '../selectors/routing';
import { createPathWithLocalePrefix } from '../utils/routing';

const isObjectToWithPathname = both(is(Object), has('pathname'));
const rewriteToProp = curry((match, to) => {
  const appendPrefix = createPathWithLocalePrefix(prop('params', match));
  if (is(String, to)) {
    return appendPrefix(to);
  }
  if (isObjectToWithPathname(to)) {
    return {
      ...to,
      pathname: appendPrefix(to.pathname),
    };
  }
  return to;
});

export const usePathCorrection = initialPath => {
  const match = useSelector(selectRootMatch);
  if (isNil(initialPath)) { // use `isNil` to allow "" but not null or undefined
    return initialPath;
  }
  return rewriteToProp(match, initialPath);
};
