import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import {
  prop,
  path,
  identity,
  map,
  find,
  propEq,
  curry,
} from 'ramda';
import { selectRootMatch } from '../../../../../selectors/routing';

const selectHelpCenterMatch = createMatchSelector('/:country/:language/ucde/help/:topic?/:subtopic?');

const selectHelpCenterSectionsCraftData = createSelector(
  path(['craft', 'smart-help-sections']),
  identity,
);
const selectHelpCenterMainCraftData = createSelector(
  path(['craft', 'smart-help']),
  identity,
);

const findTopicContentFromSections = curry((sections, topic) => find(
  propEq('slug', topic),
  sections,
));

export const selectHelpCenterPageProps = createSelector(
  selectHelpCenterMatch,
  selectHelpCenterSectionsCraftData,
  (match, sections) => {
    if (!match || !match.params || !sections) {
      return {};
    }
    const {
      params,
    } = match;

    const {
      topic,
      country,
      language,
    } = params;

    const findTopicContent = findTopicContentFromSections(sections);

    const validTopics = map(prop('slug'), sections);
    const topicIsValid = topic && validTopics.includes(topic);

    const { title } = topicIsValid
      ? findTopicContent(topic)
      : {};
    return {
      topicIsValid,
      topic,
      title,
      country,
      language,
      rightColumnLinks: [{
        title: 'My Account',
        links: [{
          to: '#',
          text: 'Can\'t access your account',
        }, {
          to: '#',
          text: 'Learn how to change your password and fix login issues.',
        }],
      }, {
        title: 'Billing',
        links: [{
          to: '#',
          text: 'Resolve issues when credit card failed?',
        }, {
          to: '#',
          text: 'Change the credit card on file for your account?',
        }],
      }, {
        title: 'Plans & Pricing',
        links: [{
          to: '#',
          text: 'Update your Instant Supplies plan',
        }, {
          to: '#',
          text: 'How to log into the account at hpinstantink.com',
        }],
      }],
    };
  },
);

const getTitles = data => ({
  helpForAndroid: path(['smartHelpTitles', 'androidApp'], data),
  helpForDesktop: path(['smartHelpTitles', 'desktopApp'], data),
  helpForIos: path(['smartHelpTitles', 'iosApp'], data),
  helpForMobile: path(['smartHelpTitles', 'mobileApp'], data),
});

const contentKeys = [
  'helpForAllApps',
  'helpForAndroid',
  'helpForDesktop',
  'helpForIos',
  'helpForMobile',
];

const siftData = (titles, data) => contentKeys.reduce((accum, key) => {
  if (data[key]) {
    return key === 'helpForAllApps'
      ? [
        {
          id: key,
          content: data[key],
        },
        ...accum,
      ]
      : [
        ...accum,
        {
          title: prop(key, titles),
          id: key,
          content: data[key],
        },
      ];
  }
  return accum;
}, []);

const kebabCase = str => str
  .split(' ')
  .map(word => word.toLowerCase())
  .join('-');

export const selectHelpCenterMainProps = createSelector(
  selectHelpCenterMatch,
  selectHelpCenterMainCraftData,
  selectHelpCenterSectionsCraftData,
  (match, main, sections) => {
    if (!match || !match.params || !main || !sections) {
      return [];
    }
    const topic = path(['params', 'topic'], match);
    const titles = getTitles(main);
    const findTopicContent = findTopicContentFromSections(sections);

    const { appHelpSection = [] } = (findTopicContent(topic) || {});

    return appHelpSection.map(section => {
      if (section.helpLinkText) {
        return {
          title: {
            text: section.helpLinkText,
            to: section.helpLinkAddress,
          },
          dataAnalyticsTag: section.analyticsID,
          id: kebabCase(section.analyticsID),
        };
      }
      const {
        helpTopic: title,
        analyticsID: dataAnalyticsTag,
      } = section;
      return {
        title,
        dataAnalyticsTag,
        id: kebabCase(dataAnalyticsTag),
        content: siftData(titles, section),
      };
    });
  },
);

export const selectHelpCenterMobileProps = createSelector(
  selectHelpCenterMatch,
  selectHelpCenterMainCraftData,
  selectHelpCenterSectionsCraftData,
  (match, main, sections) => {
    if (!match || !match.params || !sections) {
      return [];
    }
    const {
      topic,
      subtopic,
      country,
      language,
    } = match.params;
    if (subtopic && !main) {
      return [];
    }
    const findTopicContent = findTopicContentFromSections(sections);

    const { appHelpSection = [] } = (findTopicContent(topic) || {});

    if (subtopic) {
      const subSection = appHelpSection.find(({ analyticsID }) => subtopic === kebabCase(analyticsID))
      if (subSection) {
        const titles = getTitles(main);
        return siftData(titles, subSection).map(packet => {
          return {
            ...packet,
            title: {
              text: packet.title,
            },
          };
        });
      }
    }
    return appHelpSection.map(section => {
      const {
        analyticsID,
        helpLinkText,
        helpLinkAddress,
        helpTopic,
      } = section;

      const kebabbedId = kebabCase(analyticsID);
      const sectionMeta = {
        id: kebabbedId,
        dataAnalyticsTag: analyticsID,
      }
      if (helpLinkText) {
        return {
          ...sectionMeta,
          title: {
            text: helpLinkText,
            to: helpLinkAddress,
          },
        };
      }
      const pathname = `/${country}/${language}/ucde/help/${topic}/${kebabbedId}`;
      return {
        ...sectionMeta,
        title: {
          text: helpTopic,
          destination: {
            pathname,
            state: {
              takeover: helpTopic,
            },
          },
        },
      };
    });
  }
);

export const selectHelpLinks = createSelector(
  selectRootMatch,
  selectHelpCenterSectionsCraftData,
  (match = {}, sections) => {
    const {
      params = {},
    } = match;

    const {
      country = 'us',
      language = 'en',
    } = params;

    const defaultLinks = [{
      label: 'About HP Smart App',
      pathname: `/${country}/${language}/ucde/help/about-hp-smart`,
    }, {
      label: 'Printer and Connection Information',
      pathname: `/${country}/${language}/ucde/help/printer-and-connection-information`,
    }, {
      label: 'Printing and Scanning',
      pathname: `/${country}/${language}/ucde/help/printing-and-scanning`,
    }, {
      label: 'Additional Help and Support',
      pathname: `/${country}/${language}/ucde/help/additional-help-and-support`,
    }]
    if (!sections) {
      return defaultLinks;
    }

    const helpTopicLinks = defaultLinks.map(defaultLink => {
      const { pathname } = defaultLink;
      const translatedSection = sections.find(sect => pathname.includes(sect.slug));
      if (!translatedSection) {
        return defaultLink;
      }
      const {
        analyticsID,
        title,
      } = translatedSection;

      return {
        ...defaultLink,
        label: title,
        analyticsID,
      };
    });

    return helpTopicLinks;
  },
);
