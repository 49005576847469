
//// ALL CHANGES MADE TO THIS PAGE REQUIRES A STORY SO THAT QA CAN TRACK CHANGES
//// ALL CHANGES MADE TO THIS PAGE REQUIRES A STORY SO THAT QA CAN TRACK CHANGES
//// ALL CHANGES MADE TO THIS PAGE REQUIRES A STORY SO THAT QA CAN TRACK CHANGES
// import React from 'react';
import { log } from "./logger"
import { getConsentCookie } from "./manageCookies"
import { DOWNLOAD, GET_SOFTWARE, SETUP } from "../constants/common"

export const prepareGATracking = (n, m, p) => {
    setTimeout(
        () => {
            commonEventTracking(n, m, p);
        }
        ,
        750
    )
    // setTimeout
};

export const prepareGATrackingForSMB = (page) => {
    setTimeout(
        () => {
            GAEventTracking(page);
        },
        750
    )
    // setTimeout
};

export const searchBarTracking = (terms, pm, category, CD1, CD2) => {
    let consentCookie = getConsentCookie()
    let additionalParameters = (CD1 != "") ? { dimension1: CD1, dimension2: CD2 } : {};
    if (window.ga !== undefined && consentCookie[2] === "1") {

        let pageNow = window.location.pathname + "?search=" + terms
        window.ga('set', 'page', pageNow);
        window.ga('send', {
            hitType: 'pageview',
            page: pageNow,
            location: window.location.href + "?search=" + terms,
            ...additionalParameters
        });
        // reset page for subsequent event tracking:
        pageNow = window.location.pathname
        window.ga('set', 'page', pageNow);

        // log("**** GA event tracking data sent, on EnterKey event triggered on Search: " + terms + pm + " ****");
    } else {
        // log("**** GA event tracking data !!! NOT !!! sent, on EnterKey event triggered on Search: " + terms + pm + " ****");
    }
}

export const sliderPanelTracking = (details) => {
    let consentCookie = getConsentCookie()
    if (window.ga !== undefined && consentCookie[2] === "1") {
        window.ga('send', {
            hitType: 'event',
            eventCategory: "Printer Essentials",
            eventAction: "click",
            eventLabel: "" + details,
            location: window.location.href
        });
        // log("**** GA event tracking data sent, on EnterKey event triggered on Search: " + details + " ****");
    } else {
        // log("**** GA event tracking data !!! NOT !!! sent, on EnterKey event triggered on Search: " + details + " ****");
    }
}

export const featureDetailTracking = (details) => {
    let consentCookie = getConsentCookie()
    if (window.ga !== undefined && consentCookie[2] === "1") {
        window.ga('send', {
            hitType: 'event',
            eventCategory: "Home",
            eventAction: "click",
            eventLabel: "Feature link to: " + details,
            location: window.location.href
        });
        // log("**** GA event tracking data sent, on EnterKey event triggered on Search: " + details + " ****");
    } else {
        // log("**** GA event tracking data !!! NOT !!! sent, on EnterKey event triggered on Search: " + details + " ****");
    }
}

export const wirelessPrintingTracking = (details, noPageView = false) => {
    let consentCookie = getConsentCookie()
    if (window.ga !== undefined && consentCookie[2] === "1") {
        window.ga('send', {
            hitType: 'event',
            eventCategory: "Wireless Printing Center",
            eventAction: "click",
            eventLabel: "" + details,
            location: window.location.href
        });
        if (!noPageView) {
            setTimeout(function () { pageViewTracking(); }, 1000);
        }
        log("**** GA event tracking data sent, on EnterKey event triggered on Search: " + details + " ****");
    } else {
        log("**** GA event tracking data !!! NOT !!! sent, on EnterKey event triggered on Search: " + details + " ****");
    }
}

export const smartHelpTracking = (details, noPageView = false) => {
    let consentCookie = getConsentCookie()
    if (window.ga !== undefined && consentCookie[2] === "1") {
        window.ga('send', {
            hitType: 'event',
            eventCategory: "Smart Help",
            eventAction: "click",
            eventLabel: "" + details,
            location: window.location.href
        });
        if (!noPageView) {
            setTimeout(function () { pageViewTracking(); }, 1000);
        }
        log("**** GA event tracking data sent, on EnterKey event triggered on Search: " + details + " ****");
    } else {
        // log("**** GA event tracking data !!! NOT !!! sent, on EnterKey event triggered on Search: " + details + " ****");
    }
}

export const pageViewTracking = (pageNow = window.location.pathname, _cd1 = "none", _cd2 = "none") => {
    //// REMOVE TRAILING SLASH ////////////////////////////////////////////////////////////////////
    let window_location_href = window.location.href
    if (window_location_href.substr(-1) === "/") {
        window_location_href = window_location_href.substr(0, window_location_href.length - 1)
    }
    if (window.history.pushState) {
        window.history.replaceState({ foo: "bar" }, "page two", window_location_href)
    }
    if (pageNow.substr(-1) === "/") {
        pageNow = pageNow.substr(0, pageNow.length - 1)
    }
    //// END REMOVE TRAILING ////////////////////////////////////////////////////////////////////
    
    let consentCookie = getConsentCookie()
    let element = document.getElementById("printer-email")
    if ((window.location.href.indexOf("/myprinter/") != -1) != (!!element)) {
        setTimeout(function () { pageViewTracking(pageNow); }, 2000);
        return;
    }
    let CD1 = _cd1 == "none" ? (!!element) ? (element.getAttribute("data-cd1") !== undefined) ? element.getAttribute("data-cd1") : "" : "" : _cd1;
    let CD2 = _cd2 == "none" ? (!!element) ? (element.getAttribute("data-cd2") !== undefined) ? element.getAttribute("data-cd2") : "" : "" : _cd2;
    let additionalParameters = (!!element) ? { dimension1: CD1, dimension2: CD2 } : {};
    
    if (window.ga !== undefined && consentCookie[2] === "1") {
        window.ga('set', 'page', pageNow);
        window.ga('send', {
            hitType: 'pageview',
            page: pageNow,
            location: window_location_href,
            ...additionalParameters
        });
        // log("**** GA event tracking data sent, on PageView : " + pageNow + " ****");
    } else {
        // log("**** GA event tracking data !!! NOT !!! sent, on PageView : " + pageNow + " ****");
    }
}


function trackEvent(selectorId, page, eventType, elementDescription, currentPrinterModel = "", currentPrinterName = "", directElement='', eventText='') {
    if (document.getElementById(selectorId) === null && directElement === '') {
        return;
    }
    let element = document.getElementById(selectorId) ?? directElement;
    let trackingData = (element.getAttribute("data-tracking") !== undefined) ? (element.getAttribute("data-tracking") !== null) ? " " + element.getAttribute("data-tracking") : "" : "";
    //  let trackingData = (element.getAttribute("data-tracking") !== undefined && element.getAttribute("data-tracking") !== null) ? " " + element.getAttribute("data-tracking") : "";

    // let trackingData = (element.dataset.tracking !== undefined) ? " " + element.dataset.tracking : "";
    let handle = (e) => {
        let consentCookie = getConsentCookie()
        if (window.ga !== undefined && consentCookie[2] === "1") { // condition based on privacy cookie        
            let additionalParameters = currentPrinterModel !== "" ? { location: window.location.href, dimension1: currentPrinterModel, dimension2: currentPrinterName } : { location: window.location.href };
            window.ga('send', {
                hitType: 'event',
                eventCategory: page,
                eventAction: eventText ? eventText : e.type,
                eventLabel: elementDescription + trackingData,
                ...additionalParameters
            });
            // THESE LOGS ARE LEFT ON SO THAT WE CAN CONFIRM THAT THE TRACKING CONTINUES TO WORK CORRECTLY.
            log("**** GA event tracking data sent, " + e.type + " event triggered on " + page + ":" + elementDescription + " | printerModel: " + currentPrinterModel + " - " + currentPrinterName + " |  " + trackingData + " ****");
        } else {
            // THESE LOGS ARE LEFT ON SO THAT WE CAN CONFIRM THAT THE TRACKING CONTINUES TO WORK CORRECTLY.
            log("**** GA event tracking data !!! NOT !!! sent, " + e.type + " event triggered on " + page + ":" + elementDescription + " | printerModel: " + currentPrinterModel + " - " + currentPrinterName + " |   " + trackingData + " ****");
        }

    }
    function addEvent(el, type, handler) {
        el["on" + type] = handler
        ///// https://stackoverflow.com/questions/6348494/addeventlistener-vs-onclick
    }
    addEvent(element, eventType, handle)
}

function trackEventByAttribute(cssQuery, page, eventType, elementDescription, eventText) {
    let element = document.querySelector(cssQuery);
    if(!element) {
        return
    }
    trackEvent("", page, eventType, elementDescription,'', '', element,eventText);
}

export const GAEventTracking = (page) => {
    switch (page) {
        case SETUP : 
            trackEvent("begin-setup-button", "OOBE-Setup", "click", "Download-Easy-Start",'', '', '', "Begin-Setup");
            trackEvent("download-driver-button", "OOBE-Setup", "click", "123-hp-com",'', '', '', "Download-HpSmart");
            trackEventByAttribute("a[href='/connect']", "OOBE-Setup", "click", "Reach-Smb-Portal", "Pin-Pair-Link");
            trackEventByAttribute("a[href='http://support.hp.com']", "OOBE-Setup", "click", "Reach-Hp-Support", "Hp-Support-Link");
        break;
        case DOWNLOAD : 
            trackEvent("install-HpSmart-btn-downloadPage", "Download-Page", "click", "App-Store",'', '', '', "Install-Hp-Smart");
            trackEventByAttribute("a[href='http://support.hp.com']", "Download-Page", "click", "Reach-Hp-Support", "HP-Support-link");
        break;
        case GET_SOFTWARE : 
            trackEvent("install-HpSmart-btn-getSoftwarePage", "Get-Software", "click", "App-Store",'', '', '', "Install-Hp-Smart");
            trackEventByAttribute("a[href='https://support.hp.com']", "Get-Software", "click", "Reach-Hp-Support*", "HP-Support-link");
        break;
    }
}
//// ALL CHANGES MADE TO THIS PAGE REQUIRES A STORY SO THAT QA CAN TRACK CHANGES
function commonEventTracking(numberOfPrinters, CD1, CD2) {
    /////////////////////////////// GNB ////////////////////////////////////////////////////////////////// 8
    trackEvent("MainLogo", "GNB", "click", "HPC logo");
    trackEvent("search-svg-a", "GNB", "click", "Search dropdown link");
    trackEvent("GNB-Help-link", "GNB", "click", "Help link");
    trackEvent("locale", "GNB", "click", "Locale dropdown button");
    trackEvent("GNB-Sign-In-link", "GNB", "click", "Sign In link");
    trackEvent("mobile-icon-button", "GNB", "click", "Hamburger button");
    trackEvent("gnb-settingsDropDown-settings", "GNB", "click", "Settings dropdown link");
    trackEvent("mySettingsID", "GNB", "click", "Mobile menu: settings");
    trackEvent("settings-link-text", "GNB", "click", "Person name");
    trackEvent("settings-link-icon", "GNB", "click", "Person image");

    trackEvent("gnb-settingsDropDown-signout", "GNB", "click", "Sign Out link");
    trackEvent("GNB-My-Printers-Link", "GNB", "click", "My Printers link");
    // trackEvent("search-btn-gnb", "GNB", "click", "Search button"); ///////////////////////////////////// SEARCH moved to search-bar
    // trackEvent("mobile-icon", "GNB", "click", "Hamburger button"); //redundant
    // trackEvent("gnb-settingsDropDown-notifications", "GNB", "click", "Settings dropdown notifications");
    // trackEvent("notificationsID", "GNB", "click", "Mobile menu: notifications");

    /////////////////////////////// NOTIFICATIONS BANNER ////////////////////////////////////////////////////////////////// 
    trackEvent("notificationBannerLink1", "Notifications Banner", "click", "Banner link 1"); // switch back
    trackEvent("notificationBannerLink2", "Notifications Banner", "click", "Banner link 2"); // Survey
    trackEvent("notificationBannerClose", "Notifications Banner", "click", "Banner close X");

    /////////////////////////////// FOOTER ////////////////////////////////////////////////////////////////// 5
    trackEvent("HP-com-home", "Footer", "click", "HP.com link");
    trackEvent("terms-of-use-footer", "Footer", "click", "TOU link");
    trackEvent("privacy", "Footer", "click", "Privacy link"); // added 2/5/18
    trackEvent("footer-switch-back", "Footer", "click", "Switch back link");
    // trackEvent("Privacy-statement", "Footer", "click", "Footer Privacy statement link"); // REMOVED FROM SITE 2/5/18
    // trackEvent("copyright", "Footer", "click", "Copyright clicked");
    // trackEvent("footer-user-email", "Footer", "click", "User email link");
    // trackEvent("notifications", "Footer", "click", "Footer Settings link", m); // REMOVED from site footer

    ///////////////////////////// MOBILE FAX //////////////////////////////////////////////////////////////////
    ///////// 
    trackEvent("mobilefax_getStarted_cta", "Mobile Fax", "click", "Get Started cta");
    trackEvent("mobilefax_tracking_inPageMenu_get-the-app", "Mobile Fax", "click", "Get Started (in-page menu)");
    trackEvent("mobilefax_tracking_inPageMenu_benefits", "Mobile Fax", "click", "Benefits link (in-page menu)");
    trackEvent("mobilefax_tracking_inPageMenu_features", "Mobile Fax", "click", "Features link (in-page menu)");
    trackEvent("mobilefax_tracking_inPageMenu_faq", "Mobile Fax", "click", "FAQ link (in-page menu)");
    trackEvent("mobilefax_tracking_inPageMenu_forwardButton", "Mobile Fax", "click", "Get Started (in-page menu) Outbound");
    trackEvent("mobilefax_tracking_inPageMenu_backLink", "Mobile Fax", "click", "Close link (in-page menu)");
    
    trackEvent("mobilefax_tracking_hero_noThanks", "Mobile Fax", "click", "No Thanks close link");
    trackEvent("mobilefax_tracking_hero_getStarted", "Mobile Fax", "click", "Get Started Hero");
    
    trackEvent("mobilefax_tracking_appleBadge", "Mobile Fax", "click", "Apple App Store badge");
    trackEvent("mobilefax_tracking_googleBadge", "Mobile Fax", "click", "Google Play badge");
    trackEvent("mobilefax_tracking_microsoftBadge", "Mobile Fax", "click", "Microsoft App Store badge");
    

    trackEvent("mobilefax_faq_accordion_0", "Mobile Fax", "click", "FAQ:");
    trackEvent("mobilefax_faq_accordion_1", "Mobile Fax", "click", "FAQ:");
    trackEvent("mobilefax_faq_accordion_2", "Mobile Fax", "click", "FAQ:");
    trackEvent("mobilefax_faq_accordion_3", "Mobile Fax", "click", "FAQ:");
    trackEvent("mobilefax_faq_accordion_4", "Mobile Fax", "click", "FAQ:");
    trackEvent("mobilefax_faq_accordion_5", "Mobile Fax", "click", "FAQ:");
    trackEvent("mobilefax_faq_accordion_6", "Mobile Fax", "click", "FAQ:");
    trackEvent("mobilefax_faq_accordion_7", "Mobile Fax", "click", "FAQ:");
    trackEvent("mobilefax_faq_accordion_8", "Mobile Fax", "click", "FAQ:");
    trackEvent("mobilefax_faq_accordion_9", "Mobile Fax", "click", "FAQ:");
    
    trackEvent("mobilefax_feature_slide_0", "Mobile Fax", "click", "Mobilefax Feature slide 0");
    trackEvent("mobilefax_feature_slide_1", "Mobile Fax", "click", "Mobilefax Feature slide 1");
    trackEvent("mobilefax_feature_slide_2", "Mobile Fax", "click", "Mobilefax Feature slide 2");
    
    trackEvent("mobilefax_feature_number_0", "Mobile Fax", "click", "Mobilefax Feature screen");
    trackEvent("mobilefax_feature_number_1", "Mobile Fax", "click", "Mobilefax Feature screen");
    trackEvent("mobilefax_feature_number_2", "Mobile Fax", "click", "Mobilefax Feature screen");
    
    trackEvent("termsAndConditions", "Mobile Fax", "click", "Terms & Conditions link");
    trackEvent("moreFaqs", "Mobile Fax", "click", "More FAQs link");
    trackEvent("faqSupportLink", "Mobile Fax", "click", "FAQ support link");
    

    // trackEvent("getStartedHero", "Mobile Fax", "click", "Get Started Hero");
    // trackEvent("noThanksHero", "Mobile Fax", "click", "No Thanks close link");

    // trackEvent("appleBadge", "Mobile Fax", "click", "Apple App Store badge");
    // trackEvent("microsoftBadge", "Mobile Fax", "click", "Microsoft App Store badge");
    // trackEvent("googleBadge", "Mobile Fax", "click", "Google Play badge");

    trackEvent("footnoteLink1", "Mobile Fax", "click", "footnote link Mobile Printing");
    trackEvent("footnoteLink2", "Mobile Fax", "click", "footnote link study");
    
    /////////////////////////////// PRINT ANYWHERE PAGE ////////////////////////////////////////////////////////////////// 7
    trackEvent("newShareButton", "Print Anywhere", "click", "Add users button");
    trackEvent("manage_print_anywhere_accordion_open", "Print Anywhere", "click", "Open Manage Print Anywhere accordion");
    trackEvent("manage_print_anywhere_accordion_close", "Print Anywhere", "click", "Close Manage Print Anywhere accordion");
    trackEvent("manage_printers_email_accordion_open", "Print Anywhere", "click", "Open Manage Printer's Email accordion");
    trackEvent("manage_printers_email_accordion_close", "Print Anywhere", "click", "Close Manage Printer's Email accordion");
    trackEvent(`user_access_toggle_allow`, "Print Anywhere", "click", "Allow user access toggle");
    trackEvent(`user_access_toggle_block`, "Print Anywhere", "click", "Block user access toggle");
    trackEvent(`more_accordion_open`, "Print Anywhere", "click", "Open More accordion");
    trackEvent(`more_accordion_close`, "Print Anywhere", "click", "Close More accordion");
    trackEvent(`user_access_deleted`, "Print Anywhere", "click", "Deleted user access");
    trackEvent(`user_access_added`, "Print Anywhere", "click", "Added user access");
    trackEvent(`share_mode_on`, "Print Anywhere", "click", "Share mode enabled");
    trackEvent(`share_mode_off`, "Print Anywhere", "click", "Share mode disabled");
    trackEvent(`duplicate_share_settings_link`, "Print Anywhere", "click", "Duplicate settings link");

    /////////////////////////////// PRODUCT PAGE ////////////////////////////////////////////////////////////////// 7
    ///////// 
    trackEvent("AppleBadge", "Printer Essentials", "click", "Apple App Store badge");
    trackEvent("MicrosoftBadge", "Printer Essentials", "click", "Microsoft App Store badge");
    trackEvent("GoogleBadge", "Printer Essentials", "click", "Google Play badge");
    trackEvent("BaiduBadge", "Printer Essentials", "click", "Baidu App Store badge");
    ///////// 
    trackEvent("HP-Sure-Supply-Button", "Printer Essentials", "click", "HP Sure Supply button");
    trackEvent("HP-Instant-Ink-Button", "Printer Essentials", "click", "HP Instant Ink button");
    trackEvent("blueButton", "Printer Essentials", "click", "Blue Edge Lighting Button");
    trackEvent("amberButton", "Printer Essentials", "click", "Amber Edge Lighting Button");
    trackEvent("support-page-link", "Printer Essentials", "click", "Support page link");
    trackEvent("user-guide-link", "Printer Essentials", "click", "User guide link");
    trackEvent("product-final-cta", "Printer Essentials", "click", "Get Started button");
    // trackEvent("search-btn-product", "Printer Essentials", "click", "Search: ", CD1, CD2); // magnifying glass bottom of page /////////// SEARCH moved to search-bar

    /////////////////////////////// HOME PAGE ////////////////////////////////////////////////////////////////// 12
    trackEvent("hero-button", "Home", "click", "Create Account top button");
    ///////// loop: handles up to 6 features
    trackEvent("FeatureDetails0", "Home", "click", "Feature learn more:");
    trackEvent("FeatureDetails1", "Home", "click", "Feature learn more:");
    trackEvent("FeatureDetails2", "Home", "click", "Feature learn more:");
    trackEvent("FeatureDetails3", "Home", "click", "Feature learn more:");
    trackEvent("FeatureDetails4", "Home", "click", "Feature learn more:");
    trackEvent("FeatureDetails5", "Home", "click", "Feature learn more:");
    trackEvent("FeatureLink", "Home", "click", "Feature learn more link:");
    /////////
    trackEvent("feature-cta", "Home", "click", "Create Account bottom button");
    trackEvent("home-promo-learn-more", "Home", "click", "Promo learn more:");
    // trackEvent("search-btn-home", "Home", "click", "Search: "); ///////////////////////////////////// SEARCH moved to search-bar
    // trackEvent("driver-download-helplink", "Home", "click", "driver-download-helplink");
    // trackEvent("setup-printer-helplink", "Home", "click", "setup-printer-helplink");
    // trackEvent("wireless-connection-helplink", "Home", "click", "wireless-connection-helplink");
    // trackEvent("instantink-helplink", "Home", "click", "instantink-helplink");
    // trackEvent("printjob-stuck-helplink", "Home", "click", "printjob-stuck-helplink");

    /////////////////////////////// ADD PRINTER PAGE ////////////////////////////////////////////////////////////////// 3
    trackEvent("Breadcrumb-AddPrinter", "AddPrinter", "click", "Breadcrumb link");
    trackEvent("AddPrinter-cancel-btn", "AddPrinter", "click", "Cancel button");
    trackEvent("AddPrinter-add-btn", "AddPrinter", "click", "Add button");
    trackEvent("AddPrinter-try-tour", "AddPrinter", "click", "Guided tour link");
    trackEvent("AddPrinter-123", "AddPrinter", "click", "123.hp.com link");
    trackEvent("AddPrinter-hp-smart", "AddPrinter", "click", "HP Smart link Android");
    trackEvent("AddPrinter-hp-smart-apple", "AddPrinter", "click", "HP Smart link Apple");
    trackEvent("AddPrinter-hp-support", "AddPrinter", "click", "HP Support link");

    /////////////////////////////// NOTIFICATIONS PAGE ////////////////////////////////////////////////////////////////// 3
    // trackEvent("checkbox0", "Notifications", "click", "HP Communication Preferences changed", "");
    // trackEvent("checkbox1", "Notifications", "click", "ePrint Communication Preferences changed", "");
    // trackEvent("input0anchor", "Notifications", "click", "HP Communication Preferences changed", "");
    // trackEvent("input1anchor", "Notifications", "click", "ePrint Communication Preferences changed", "");
    // trackEvent("cancel-notifications-button", "Notifications", "click", "Cancel button", "");
    // trackEvent("save-notifications-button", "Notifications", "click", "Save Changes button", "");

    /////////////////////////////// PRINTER SETTINGS PAGE ////////////////////////////////////////////////////////////////// 35 + 20
    trackEvent("breadcrumb-printer", "PrinterSettings", "click", "Breadcrumb link", CD1, CD2);
    trackEvent("AddPrinter-btn", "PrinterSettings", "click", "Add Printer link", CD1, CD2);
    trackEvent("printer-email", "PrinterSettings", "click", "Printer email link", CD1, CD2);
    trackEvent("printer-essentials-link", "PrinterSettings", "click", "Printer essentials link", CD1, CD2);
    trackEvent("support-docs-link", "PrinterSettings", "click", "Support docs link", CD1, CD2);
    trackEvent("remove-printer-link", "PrinterSettings", "click", "Remove printer link", CD1, CD2);
    trackEvent("ink-eligible", "PrinterSettings", "click", "Eligible for II accordion", CD1, CD2);
    trackEvent("ink-eligible-learn-more", "PrinterSettings", "click", "Eligible for II: learn more button", CD1, CD2);
    trackEvent("II", "PrinterSettings", "click", "Manage II accordion", CD1, CD2); // added 3/7/18
    trackEvent("II-manage-subscription", "PrinterSettings", "click", "Manage II: subscription link", CD1, CD2); // added 3/7/18
    trackEvent("find-ink-toner", "PrinterSettings", "click", "Find ink or toner accordion", CD1, CD2);
    trackEvent("find-ink-toner-buy-cartridges", "PrinterSettings", "click", "Find ink or toner: buy cartridges", CD1, CD2);
    trackEvent("auto-replenishment", "PrinterSettings", "click", "Manage ARS accordion", CD1, CD2);
    trackEvent("auto-replenishment-manage-subscription", "PrinterSettings", "click", "Manage ARS: subscription link", CD1, CD2);
    trackEvent("auto-replenishment-tou-link", "PrinterSettings", "click", "Manage ARS: TOU link", CD1, CD2);
    trackEvent("eprint-email", "PrinterSettings", "click", "Printer email accordion", CD1, CD2);
    trackEvent("eprint-email-send-now", "PrinterSettings", "click", "Send email now", CD1, CD2);
    trackEvent("eprint-email-submit", "PrinterSettings", "click", "ePrint email submit", CD1, CD2);
    trackEvent("eprint-email-cancel", "PrinterSettings", "click", "ePrint email cancel", CD1, CD2);
    trackEvent("eprint-access", "PrinterSettings", "click", "ePrint access accordion", CD1, CD2);
    trackEvent("eprint-access-open", "PrinterSettings", "click", "ePrint access open", CD1, CD2);
    trackEvent("eprint-access-blocked", "PrinterSettings", "click", "ePrint access blocked", CD1, CD2);
    trackEvent("eprint-access-allowed", "PrinterSettings", "click", "ePrint access allowed", CD1, CD2);
    trackEvent("eprint-access-add", "PrinterSettings", "click", "ePrint access add", CD1, CD2);
    trackEvent("eprint-preferences", "PrinterSettings", "click", "ePrint preferences accordion", CD1, CD2);
    trackEvent("eprint-preferences-monochrome", "PrinterSettings", "click", "ePrint preferences monochrome", CD1, CD2);
    trackEvent("eprint-preferences-color", "PrinterSettings", "click", "ePrint preferences color", CD1, CD2);
    trackEvent("eprint-preferences-plain", "PrinterSettings", "click", "ePrint preferences plain", CD1, CD2);
    trackEvent("eprint-preferences-photo", "PrinterSettings", "click", "ePrint preferences photo", CD1, CD2);
    trackEvent("eprint-preferences-1-sided", "PrinterSettings", "click", "ePrint preferences 1-sided", CD1, CD2);
    trackEvent("eprint-preferences-2-sided", "PrinterSettings", "click", "ePrint preferences 2-sided", CD1, CD2);

    /////////////////////////////// PRINTER SETTINGS PAGE INK SETTINGS //////////////////////////////////////////////////////////////////
    trackEvent("care-pack", "PrinterSettings", "click", "Care Pack accordion", CD1, CD2);
    
    trackEvent("care-pack-extend-now-tracking", "PrinterSettings", "click", "Care Pack extend now button", CD1, CD2);
    trackEvent("care-pack-learn-more-tracking", "PrinterSettings", "click", "Care Pack learn more button", CD1, CD2);
    trackEvent("care-pack-terms-and-conditions-tracking", "PrinterSettings", "click", "Care Pack terms and conditions button", CD1, CD2);
    trackEvent("care-pack-explore-new-printers-tracking", "PrinterSettings", "click", "Care Pack explore new HP printers button", CD1, CD2);
    trackEvent("care-pack-explore-accessories-tracking", "PrinterSettings", "click", "Care Pack explore accessories button", CD1, CD2);

    trackEvent("sure-supply-buy-cartridges-accordion", "PrinterSettings", "click", "Sure Supply accordion", CD1, CD2);
    trackEvent("sure-supply-buy-cartridges", "PrinterSettings", "click", "Sure Supply buy cartriges button", CD1, CD2);


    /////////////////////////////// REMOVE PRINTER MODAL ////////////////////////////////////////////////////////////////// 2
    trackEvent("remove-printer-modal-cancel", "Remove Printer Modal", "click", "Cancel button", CD1, CD2);
    trackEvent("remove-printer-modal-remove", "Remove Printer Modal", "click", "Remove button", CD1, CD2);


    /////////////////////////////// ONBOARDING MODAL ////////////////////////////////////////////////////////////////// 2
    trackEvent("onboarding-modal-open", "Onboarding Modal", "click", "Open onboarding modal", CD1, CD2);
    trackEvent("onboarding-modal-close", "Onboarding Modal", "click", "Close onboarding modal", CD1, CD2);
    trackEvent("onboarding-modal-submit", "Onboarding Modal", "click", "Submit new address in onboarding modal", CD1, CD2);

    /////////////////////////////// My PRINTERs ////////////////////////////////////////////////////////////////// 3 5 7 9 11 13
    trackEvent("myPrinters-addPrinter-btn", "MyPrinters", "click", "Add printer");
    trackEvent("myPrintersAppPromo0", "MyPrinters", "click", "App Store badge");
    trackEvent("myPrintersAppPromo1", "MyPrinters", "click", "Google Play badge");

    if (numberOfPrinters > 0) {
        let n = numberOfPrinters;
        for (let i = n - 1; i >= 0; i--) {
            let element = (numberOfPrinters > 12) ? document.getElementById(`card_${i}_changeSettings`) : document.getElementById(`card_${i}`)
            if (!!element) {
                let CD1 = (element.getAttribute("data-cd1") !== undefined) ? element.getAttribute("data-cd1") : "";
                let CD2 = (element.getAttribute("data-cd2") !== undefined) ? element.getAttribute("data-cd2") : "";
                trackEvent(`card_${i}_email`, "MyPrinters", "click", "Printer email link", CD1, CD2) // add printer
                trackEvent(`card_${i}_changeSettings`, "MyPrinters", "click", "Change Settings button", CD1, CD2)  // add model number
            }
        }
    }

}


