import React, { Component } from 'react';
import AccordionTitle from "./../../shared-components/molecules/AccordionTitle";
import AccordionSubscriptionContent from './AccordionSubscriptionContent';
import SubscriptionSection from './SubscriptionSection';
import classNames from 'classnames';
import bind from './../../bind';
import Accordion from "./../../shared-components/organisms/Accordion";

class HPSureSupply extends Component {
  constructor(props) {
    super(props);
    this.keyID = "sure-supply-buy-cartridges-accordion"
    this.onActionClicked = this.onActionClicked.bind(this);
  }
  onActionClicked() {
    document.getElementById("sure-supply-buy-cartridges").click();
    // let win = window.open(this.getIIUrl(), '_blank');
    // win.focus();
  }
  callbackFn = (bool) => {
    const containerClass = classNames('accordion', 'inkSettingsInstant', 'myPrinterSetting', { 'accordion-active': bool });
    document.getElementById(`container_${this.keyID}`).className = containerClass;
  }
  render() {
    const containerClass = classNames('accordion', 'inkSettingsInstant', 'myPrinterSetting');

    return (
      <div className="printerGrid--printerCell promoSection--sureSupply">
        <a name="tracking-hook" id="sure-supply-buy-cartridges"></a>

        {this.props.gnb.isTablet ||
          <div>
            <SubscriptionSection
              sectionHeader="Find Ink or Toner"
              showCheckmark={false}
              sectionDescription="<p>HP SureSupply is the most convenient way to shop online for your cartridges.</p>"
              sectionActionText="Buy Cartridges"
              sectionActionId="sure-supply-buy-cartridges-btn"
              onActionClicked={this.onActionClicked}
            />
          </div>
        }

        {this.props.gnb.isTablet &&
          <div id={`container_${this.keyID}`} className={containerClass}>
            <AccordionTitle loading={false} ID={this.keyID} accordionTitle="Find Ink or Toner" />
            <Accordion ID={this.keyID} actionID={this.keyID} groupID="InkSettings" groupFn={this.props.groupFn} controler={this.props.controler} callbackFn={this.callbackFn}>
              <AccordionSubscriptionContent
                ID="sure-supply-buy-cartridges-content"
                description="HP SureSupply is the most convenient way to shop online for your cartridges."
                actionText="Buy Cartridges"
                onActionClicked={this.onActionClicked}
              />
            </Accordion>
          </div>
        }

      </div>
    );
  }
}

export default bind(HPSureSupply);