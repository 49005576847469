import { useLocation } from 'react-router-dom';
import { head, curry, compose, always } from 'ramda';
import { RTL, LTR, SUPPORTED_LANGUAGES } from '../constants/i18n';

const getTags = (tagName) => always(document.getElementsByTagName(tagName));

export const getMetaTagByName = (name) => {
  const tags = getTags('meta')();
  return tags[name];
};

export const setTagAttribute = (tag, attributeName, attributeValue) => {
  if (tag) {
    tag.setAttribute(attributeName, attributeValue);
  }
};

const getHtmlAttribute = curry((attribute) => {
  const htmlTag = compose(head, getTags('html'))();
  return htmlTag.getAttribute(attribute);
});

const setHtmlAttribute = curry((attribute, value) => {
  const htmlTag = compose(head, getTags('html'))();
  return htmlTag.setAttribute(attribute, value);
});

export const getHtmlDir = () => getHtmlAttribute('dir');

export const getHtmlLang = () => getHtmlAttribute('lang');

export const setHtmlDirRtl = () => setHtmlAttribute('dir', RTL);

export const setHtmlDirLtr = () => setHtmlAttribute('dir', LTR);

export const setHtmlLang = setHtmlAttribute('lang');

const NOT_SUPPORTED_LANG = '';

// Method to return supported language and if language is not supported it will return empty string
const getSupportedLanguage = (languages) =>
  head(languages) && SUPPORTED_LANGUAGES[head(languages)]
    ? `${head(languages)}-${head(SUPPORTED_LANGUAGES[head(languages)]) && head(SUPPORTED_LANGUAGES[head(languages)]).toUpperCase()}`
    : NOT_SUPPORTED_LANG;

//getBroserLocale is responsible to get the locale information from the setting and return in a default format of lang-country
export const getBrowserLocale = () => {
  const {
    language,
    browserLanguage,
    systemLanguage,
    userLanguage,
    languages = []
  } = window.navigator;
  return language.split('-').length === 2
    ? language
    : browserLanguage || systemLanguage || userLanguage || getSupportedLanguage(languages);
};

export const splitLocaleIntoCountryAndLanguage = (locale = '') => ({
  language: locale.slice(0, 2),
  country: locale.slice(-2, locale.length).toLowerCase()
});

export const getLocale = () => {
  const browserLang = getBrowserLocale();
  const { country, language } = splitLocaleIntoCountryAndLanguage(browserLang);
  return { country, language };
};

const encodeProperty = (property) => encodeURIComponent(property).replace(/[\-\.\+\*]/g, '\\$&');

export const getCookie = (cookieName) => {
  const { cookie = '' } = document;
  // From https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
  // the craft routing stuff references the "gnb_locale" cookie, that will be our cookie-source of language truth
  const cookieNameEncoded = encodeProperty(cookieName);
  const contentLanguage =
    decodeURIComponent(
      cookie.replace(
        new RegExp(`(?:(?:^|.*;)\\s*${cookieNameEncoded}\\s*\\=\\s*([^;]*).*$)|^.*$`),
        '$1'
      )
    ) || '';
  const cookieContent =
    Array.isArray(contentLanguage) && contentLanguage.includes(',')
      ? contentLanguage.split(',')[0]
      : contentLanguage;
  return cookieContent;
};

function deleteCookie(cookieName) {
  document.cookie = `${encodeProperty(cookieName)}=""; max-age=-1;`;
}

export const setCookie = (cookieName, cookieValue, age = 31536000) => {
  if (getCookie(cookieName)) {
    deleteCookie(cookieName);
  }
  document.cookie = `${encodeProperty(cookieName)}=${encodeProperty(
    cookieValue
  )}; max-age=${age}; path=/;`;
};

export const setCsrfState = (value) => sessionStorage.setItem('csrfState', value);
export const getCsrfState = () => sessionStorage.getItem('csrfState');

export const getQueryParams = () => {
  const queryString = useLocation().search;
  return queryString;
};

export const getURLWithQueryParams = (baseUrl, queryString) => {
  const urlWithQueryParams = `${baseUrl}${queryString}`;
  return urlWithQueryParams;
};

export const imageProxyURL = (url) => {
  if (url) {
    const { host } = window.location;
    const formatedUrl = new URL(url);
    const path = formatedUrl.pathname;
    return `https://${host}/content/images${path}`;
  }
};

export const updateImageUrlToProxy = (obj = {}, oldKey = 'url', newKey = 'url') => (
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (key === oldKey) {
      const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
      const { host } = window.location;
      const formatedUrl = regex.test(value) ? new URL(value) : value;
      const path = formatedUrl.pathname;
      acc[newKey] = `https://${host}/content/images${path}`;
      return acc;
    }
    if (value !== null && typeof value === "object") {
      if (value.length) {
        acc[key] = value.map((cur_val) => {
          return updateImageUrlToProxy(cur_val, oldKey, newKey);
        });
        return acc;
      } else {
        acc[key] = updateImageUrlToProxy(value, oldKey, newKey);
        return acc;
      }
    }
    acc[key] = value;
    return acc;
  }, {})); 

export const buildingUCDELinkWithCountryAndLanguage = (url, country, language) => {
  let parts = url.split('/ucde');
  return parts[0] + "/" + country.toLocaleLowerCase()  + '/' + language + '/ucde' + parts[1];
}
