import React from 'react';
import { ShortcutsContainer, ShortcutsContent, ImageTile, ContentTile, Feature } from './styles';

const Shortcuts = (props) => {
  const { featureDetails } = props;
  const shortcutsFeatureData = featureDetails[7]?.details;
  const multiItemRecognitionData = featureDetails[8]?.detail_description;
  const textExtractData = featureDetails[9]?.detail_description;

  return (
    <ShortcutsContainer id={'shortcuts'} className="shortcuts">
      <ShortcutsContent>
        <div className="image-content">
          <ImageTile>
            <img
              alt={shortcutsFeatureData.mobile.title}
              src={shortcutsFeatureData.mobile.url}
              className="feature-image feature-image_375"
            />
            <img
              alt={shortcutsFeatureData.tablet.title}
              src={shortcutsFeatureData.tablet.url}
              className="feature-image feature-image_540"
            />
            <img
              alt={shortcutsFeatureData.widescreen.title}
              src={shortcutsFeatureData.widescreen.url}
              className="feature-image feature-image_720"
            />
            <img
              alt={shortcutsFeatureData.extrawide.title}
              src={shortcutsFeatureData.extrawide.url}
              className="feature-image feature-image_1280"
            />
          </ImageTile>
          <ContentTile>
            <div className="title">{shortcutsFeatureData.section_title}</div>
            <div className="description">{shortcutsFeatureData.section_heading}</div>
            <div className="text-content">{shortcutsFeatureData.section_body}</div>
            <div className="features">
              <Feature>
                <img
                  alt={multiItemRecognitionData.detail_icon.title}
                  src={multiItemRecognitionData.detail_icon.url}
                  className="feature-image feature-logo"
                />
                <div>
                  <div className="feature-title">{multiItemRecognitionData.detail_heading}</div>
                  <div className="feature-description">{multiItemRecognitionData.detail_body}</div>
                </div>
              </Feature>

              <Feature className="last-feature">
                <img
                  alt={textExtractData.detail_icon.title}
                  src={textExtractData.detail_icon.url}
                  className="feature-image feature-logo"
                />
                <div>
                  <div className="feature-title">{textExtractData.detail_heading}</div>
                  <div className="feature-description">{textExtractData.detail_body}</div>
                </div>
              </Feature>
            </div>
          </ContentTile>
        </div>
      </ShortcutsContent>
    </ShortcutsContainer>
  );
};

export default Shortcuts;
