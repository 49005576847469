import React from 'react';

import {
  AnimatedSection,
  FAQsLoadingSection,
  FAQsContentLoadingSection
} from '../styles/StyleSkeleton';

const SkeletonFaqs = () => {
  return (
    <FAQsLoadingSection>
      <AnimatedSection height="50px" width="200px" margin="30px" />
      <FAQsContentLoadingSection>
        <AnimatedSection height="25px" margin="10px" />
        <AnimatedSection height="25px" margin="10px" />
        <AnimatedSection height="25px" margin="10px" />
        <AnimatedSection height="25px" margin="10px" />
        <AnimatedSection height="25px" margin="10px" />
        <AnimatedSection height="25px" margin="10px" />
        <AnimatedSection height="25px" margin="10px" />
        <AnimatedSection height="25px" margin="10px" />
        <AnimatedSection height="25px" margin="10px" />
        <AnimatedSection height="25px" />
      </FAQsContentLoadingSection>
    </FAQsLoadingSection>
  );
};

export default SkeletonFaqs;
