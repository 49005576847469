import {
  path,
  identity,
} from 'ramda';
import { createSelector } from 'reselect';
import { selectPageViewPathInfo } from './routing';

const SELECTORS_BASE = {
  selectAnalytics: path(['analytics']),
};

export const selectAnalyticsData = createSelector(
  SELECTORS_BASE.selectAnalytics,
  identity,
);

export const selectPageViewEventInfo = createSelector(
  SELECTORS_BASE.selectAnalytics,
  selectPageViewPathInfo,
  (analyticsObj, { pathname, eventInfo, deviceID, unEncryptedDeviceID }) => {
    const {
      rawDeviceID,
      ...restAnalyticsData
    } = analyticsObj;
    if (unEncryptedDeviceID) {
      if (rawDeviceID !== unEncryptedDeviceID) {
        return {
          ...restAnalyticsData,
          unEncryptedDeviceID,
          deviceID: undefined,
          pathname,
          eventInfo,
        };
      }
      return {
        ...restAnalyticsData,
        pathname,
        eventInfo,
      };
    }
    return {
      ...restAnalyticsData,
      deviceID,
      pathname,
      eventInfo,
    };
  },
);
