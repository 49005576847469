import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import DeviceSupply from './supply';
import SupplyError from './SupplyError';
import i18n from '../../../../utils/i18n';
import {
  getSupplyNamesOrder,
  SuppliesTricolor,
  SupplyNames,
} from './supplyNames';
import {
  Supplies,
  SuppliesLabel,
} from './styles';

const getSupplyStringId = levels => {
  if (path([0, 'type'], levels) === 'pages') {
    return i18n.t('pagesRemaining');
  }
  return i18n.t('home.myPrintersCard.label');
};

const getSupplies = (levels, supplyType) => {
  let supplies = levels;
  if (supplyType && supplyType.toLowerCase() === 'ink_tricolor') {
    supplies = levels.filter(level => level.supplyName === SupplyNames.K);
    const tricolor = {
      supplyName: SupplyNames.TRICOLOR,
      level: 100,
      levelState: 'OK',
    };
    levels.forEach(level => {
      if (SuppliesTricolor.has(level.supplyName) && level.level < tricolor.level) {
        tricolor.level = level.level;
        tricolor.levelState = level.levelState;
      }
    });
    supplies.push(tricolor);
  }
  const supplyNamesOrder = getSupplyNamesOrder(supplyType);
  const supplyOrder = (a, b) => (supplyNamesOrder.indexOf(a.supplyName) - supplyNamesOrder.indexOf(b.supplyName));
  return supplies.sort(supplyOrder);
};

const DeviceSupplies = ({
  levels,
  supplyType,
  doRefresh,
  printersPage,
}) => {
  if (!levels || !supplyType) {
    return (
      <SupplyError printersPage={printersPage} doRefresh={doRefresh} />
    );
  }
  const supplies = getSupplies(levels, supplyType);
  return (
    <React.Fragment>
      <Supplies printersPage={printersPage}>
        {supplies.map((supply => (<DeviceSupply key={`supply_${supply.supplyName}_${supply.level}`} supply={supply} />)))}
      </Supplies>
      <SuppliesLabel printersPage={printersPage}>
        {getSupplyStringId(levels)}
      </SuppliesLabel>
    </React.Fragment>
  );
};

DeviceSupplies.defaultProps = {
  levels: undefined,
  supplyType: undefined,
  printersPage: false,
};

DeviceSupplies.propTypes = {
  levels: PropTypes.arrayOf(DeviceSupply.propTypes.supply),
  supplyType: PropTypes.string,
  doRefresh: PropTypes.func.isRequired,
  printersPage: PropTypes.bool,
};

export default DeviceSupplies;
