import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@veneer/core/dist/scripts/button';
import { userOSDetector } from '../../../utils/userOSDetector';
import { BROWSER_TYPE } from '../../Dashboard/utils/constants';

export const AppStoreBadges = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${(props) => (props.isRTL ? '20px 0 25px auto' : '20px auto 25px 0')};
`;

const AppStoreBadge = styled.a`
  height: 40px;
  display: flex;
  align-items: baseline;
  margin: 5px;
`;

const AppStoreBadgeImage = styled.img`
  &.microsoftStoreBadge {
    border-radius: 7px;
    width: 6rem;
  }

  &.playStoreBadge {
    border-radius: 7px;
    width: ${(props) => ['tr/tr', 'hu/hu'].includes(props.lang) ? '5.5rem' : 'cn/zh' === props.lang ? '2rem' : '7rem'};
  }

  &.iOSAppStoreBadge {
    width: 6rem;
  }

  &.macAppStoreBadge {
    width: 8rem;
  }
`;

const ButtonContainer = styled(Button)`
  && {
    width: fit-content;
    @media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: inline-table;
    }
  }
`;

let userOperatingSystem = userOSDetector();

if (userOperatingSystem.indexOf('Windows 10') !== -1) {
  userOperatingSystem = 'Windows 10';
} else if (userOperatingSystem.indexOf('Windows 7') !== -1) {
  userOperatingSystem = 'Windows 7';
} else if (userOperatingSystem.indexOf('Mac') !== -1) {
  userOperatingSystem = 'Mac';
} else if (userOperatingSystem.indexOf('iOS') !== -1) {
  userOperatingSystem = 'iOS';
} else if (userOperatingSystem.indexOf('Android') !== -1) {
  userOperatingSystem = 'Android';
}

export const InstallHPSmartButton = (props) => {
  const [buttonDestination, setButtonDestination] = useState('');
  const buttonDestinationURLs = props.buttonDestination;
  const appStoreURL = buttonDestinationURLs[0]?.app_badge?.app_badge_link;
  const playStoreURL = buttonDestinationURLs[1]?.app_badge?.app_badge_link;
  const microsoftStoreURL = buttonDestinationURLs[2]?.app_badge?.desktop_app_link ?? buttonDestinationURLs[2].app_badge.app_badge_link;
  const macAppStoreURL = buttonDestinationURLs[3]?.app_badge?.desktop_app_link ?? buttonDestinationURLs[3].app_badge.app_badge_link;

  useEffect(() => {
    if (userOperatingSystem === 'Windows 10' || userOperatingSystem === 'Windows 7') {
      setButtonDestination(microsoftStoreURL);
    } else if (userOperatingSystem === 'Mac') {
      setButtonDestination(macAppStoreURL);
    } else if (userOperatingSystem === 'iOS') {
      setButtonDestination(appStoreURL);
    } else if (userOperatingSystem === 'Android') {
      setButtonDestination(playStoreURL);
    }
  }, [userOperatingSystem])

  const onInstallHPSmartButtonClick = () => {
    window.location.replace(buttonDestination);

    setTimeout(() => {
      window.open('', '_self', '').close();
    }, 10000);
  };

  return (
    <ButtonContainer appearance="primary" id={props.id} negative onClick={onInstallHPSmartButtonClick}>
      {props.buttonText}
    </ButtonContainer>
  );
};

export const AppBadges = (props) => {
  const appStoreBadge = props.badges[0].app_badge;
  const playStoreBadge = props.badges[1].app_badge;
  const microsoftStoreBadge = props.badges[2].app_badge;
  const macAppStoreBadge = props.badges[3].app_badge;

  const [, country, language] = window.location.pathname.split('/');
  return (
    <AppStoreBadges isRTL={props.isRTL}>
      {userOperatingSystem !== 'Windows 10' && (
        <AppStoreBadge id={microsoftStoreBadge.app_badge_id} href={microsoftStoreBadge.app_badge_link}>
          <AppStoreBadgeImage
            alt={microsoftStoreBadge.app_badge_graphic.title}
            className="microsoftStoreBadge"
            src={microsoftStoreBadge.app_badge_graphic.url}
          />
        </AppStoreBadge>
      )}
      {userOperatingSystem !== 'Android' && (
        <AppStoreBadge id={playStoreBadge.app_badge_id} href={playStoreBadge.app_badge_link}>
          <AppStoreBadgeImage
            alt={playStoreBadge.app_badge_graphic.title}
            className="playStoreBadge"
            src={playStoreBadge.app_badge_graphic.url}
            lang={`${country}/${language}`}
          />
        </AppStoreBadge>
      )}
      {userOperatingSystem !== 'iOS' && (
        <AppStoreBadge id={appStoreBadge.app_badge_id} href={appStoreBadge.app_badge_link}>
          <AppStoreBadgeImage
            alt={appStoreBadge.app_badge_graphic.title}
            className="iOSAppStoreBadge"
            src={appStoreBadge.app_badge_graphic.url}
          />
        </AppStoreBadge>
      )}
      {userOperatingSystem.indexOf('Mac') === -1 && (
        <AppStoreBadge id={macAppStoreBadge.app_badge_id} href={macAppStoreBadge.app_badge_link}>
          <AppStoreBadgeImage
            alt={macAppStoreBadge.app_badge_graphic.title}
            className="macAppStoreBadge"
            src={macAppStoreBadge.app_badge_graphic.url}
          />
        </AppStoreBadge>
      )}
    </AppStoreBadges>
  );
};
