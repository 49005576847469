import React from 'react';
import PropTypes from 'prop-types';
import { IconMinusCircle } from '@veneer/core';

import {
  ErrorContainer,
  ErrorMessage,
  ErrorTitle,
} from './styles';
import i18n from '../../../utils/i18n';

const ServerError = ({ direction, largerText, title, message }) => (
  <ErrorContainer direction={direction} largerText={largerText}>
    <IconMinusCircle color="colorRed6" size={40} />
    <div>
      <ErrorTitle largerText={largerText}>{i18n.t(title)}</ErrorTitle>
      <ErrorMessage largerText={largerText}>{i18n.t(message)}</ErrorMessage>
    </div>
  </ErrorContainer>
);

ServerError.defaultProps = {
  largerText: false,
  direction: 'column',
  title: 'home.myPrintersCard.error.general.title',
  message: 'home.myPrintersCard.error.general.message',
};

ServerError.propTypes = {
  largerText: PropTypes.bool,
  direction: PropTypes.oneOf(['row', 'column']),
  title: PropTypes.string,
  message: PropTypes.string,
};

export default ServerError;
