export const loadFacebookSDK = () => {
  if (!document.getElementById('facebook-sdk')) {
    const script = document.createElement('script');

    script.id = 'facebook-sdk';
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = 'async';
    script.defer = 'defer';
    script.crossorigin = 'crossorigin';

    document.querySelectorAll('head')[0].appendChild(script);
  }
};

export const initFacebookSDK = ({ appId }) => {
  window.fbAsyncInit = function () {
    FB.init({
      appId,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v8.0'
    });
  };
};

export const shareUI = (evt, url) => {
  evt.preventDefault();

  FB.ui({
    method: 'share',
    href: url
  }, () => { });
}
