import SplunkOtelWeb, { SessionBasedSampler } from '@splunk/otel-web';
import Config from './config/Config';

const config = new Config();
const rumToken = config.Get('HPSMART_RUM_TOKEN');
const currentStack = config.Get('CURRENT_STACK');

SplunkOtelWeb.init({
    beaconUrl: 'https://rum-ingest.us1.signalfx.com/v1/rum',
    rumAuth: rumToken,
    app: 'print_hpsmart_rum',
    environment: currentStack,
    instrumentations: {
		  errors: false,
		  xhr: false
    },
    tracer: {
      sampler: new SessionBasedSampler({
        ratio: 0.25,
      }),
    },
});