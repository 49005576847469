import {
  NotificationSettingsCategories,
  NotificationCategory,
  NotificationTransport,
  Stack,
} from '@jarvis/react-notification-settings';
import React from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../utils/i18n';
import {
  Container,
  Description,
  Grid,
  GridSeparator,
  MainContent,
  Title,
} from '../../components/shared-components/styles';
import selectNotificationSettingsProps from './selector';
import { getStratusAccessToken } from '../../utils/auth';
import { refreshToken } from '../../api/HPCSession';

const NotificationSettingsPage = () => {
  const { language, country, stack, baseUrl } = useSelector(selectNotificationSettingsProps);

  // Copied directly from Privacy Settings
  const authProvider = {
    getAccessToken: async forceRefresh => {
      if (forceRefresh) {
        await refreshToken();
      }
      return getStratusAccessToken();
    },
  };

  const notificationCategories = [
    NotificationCategory.account, 
    NotificationCategory.promotions
  ];
  const notificationTransports = [NotificationTransport.email, NotificationTransport.sms];

  return (
    <Container>
      <GridSeparator>
        <Title>{i18n.t('myAccount.notificationSettings.mainHeader')}</Title>
        <Description>{i18n.t('myAccount.notificationSettings.description')}</Description>
      </GridSeparator>
      <Grid>
        <MainContent>
          <NotificationSettingsCategories
            stack={Stack[stack]}
            language={language}
            country={country}
            transactionalBaseUrl={baseUrl}
            authProvider={authProvider}
            categories={notificationCategories}
            transports={notificationTransports}
          />
        </MainContent>
      </Grid>
    </Container>
  );
}

export default NotificationSettingsPage;
