import React from 'react';
import { IconChevronRight } from '@veneer/core';
import PropTypes from 'prop-types';
import i18n from '../../../../utils/i18n';
import {
  BenefitItemContainer,
  IconBenefit,
  InfoBenefit,
  LinkButton,
} from './styles';

const BenefitItem = ({
  name, detail, icon, toggleLink,
}) => (
  <BenefitItemContainer>
    <IconBenefit>
      <img
        src={icon}
        alt={i18n.t(name)}
      />
    </IconBenefit>
    <InfoBenefit>
      <span>{i18n.t(name)}</span>
      <p>{i18n.t(detail)}</p>
    </InfoBenefit>

    <LinkButton
      onClick={toggleLink}
      leadingIcon={<IconChevronRight />}
      appearance="tertiary"
    />

  </BenefitItemContainer>
);

BenefitItem.propTypes = {
  name: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Object).isRequired,
  toggleLink: PropTypes.func.isRequired,
};

export default BenefitItem;
