import React, { Component } from 'react';
import {
  pathOr,
  path,
} from 'ramda';
import BreadCrumbs from '../shared-components/atoms/BreadCrumbs';
import HollowButton from '../shared-components/atoms/hollowButton';
import PageHeadIcon from '../shared-components/molecules/PageHeadIcon';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';
import bind from '../bind';
import { getHistory } from '../../index';
import { Redirect } from '../routing';
import PrinterClaimError from '../errors/PrinterClaimError';
import { determineDefaultLocale } from '../../sagas/watchLocationChange';
import { RTL_LANGUAGES } from '../../constants/i18n';
import { GLOBAL_NAV_BAR_RESOURCE_NAME } from '../../constants/contentStackResources';
import { getLocaleForTranslation } from '../../utils/routing';

class NewPrinter extends Component {
  constructor(props) {
    super(props);
    this.state_claimNumber = '';
    this.state_pending = false; // Loading flag so that the page won't show up again right away after ajax call to claim printer
    this.handleAddPrinter = this.handleAddPrinter.bind(this);
    this.handleClaimChange = this.handleClaimChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentWillMount() {
    const {
      myPrinters,
      printerCommon,
      getMyPrinters,
      getPrinterCommon,
      locales,
      match
    } = this.props;

    const locale = getLocaleForTranslation({ currentLocale: locales?.currentLocale, match, alt4: locales?.alt4 }).toLowerCase();

    if (!myPrinters.isComplete) {
      getMyPrinters(locale);
    }

    if (!printerCommon.isComplete) {
      getPrinterCommon(locale.replace('_', '-'));
    }
  }

  componentWillUnmount() {
    this.props.clearClaimError();
  }

  onFormSubmit(event) {
    event.preventDefault();
    this.handleAddPrinter();
  }

  goBack = () => {
    const history = getHistory();
    const nop = this.props.myPrinters.data.WPPData.length;
    if (nop === 0) {
      this.props.push('/home');
    }
    history.goBack();
  }

  handleAddPrinter() {
    const {
      match,
      push,
      myPrintersAltered,
      claimPrinter,
    } = this.props;
    myPrintersAltered();
    // Turn on pending and leave it even after successful to account for time browser takes to redirect
    this.state_pending = true;
    claimPrinter(this.state_claimNumber, printer => {
      const url = `/${pathOr('us', ['params', 'country'], match)}/${pathOr('us', ['params', 'language'], match)}/myprinter/${printer.ownershipId}${printer.generation ? `?generation=${printer.generation}` : ''}`;
      push(url);
    });
    return true;
  }

  handleClaimChange(event) {
    this.state_claimNumber = event.target.value;
  }

  generateClaimCodeExamples() {
    const { myPrinters } = this.props;
    if (myPrinters.isComplete) {
      const claimCodeExamples = myPrinters.data.csData.add_printer.claim_code_location_graphic;
      return claimCodeExamples.map((printerSheets, i) => {
        const {
          title: claimCodeLocationGraphicTitle,
          url: claimCodeLocationGraphicUrl
        } = printerSheets;
        return <div key={i} className="addPrinter--claimCodeThumb">
          <img src={claimCodeLocationGraphicUrl} alt={claimCodeLocationGraphicTitle} />
        </div>
      });
    } if (myprinters.isError) {
      return <p>{myprinters.errorMessage}</p>;
    }
  }

  render() {
    const {
      myPrinters,
      printerCommon,
      contentStack,
    } = this.props;
    const isNative = path(['application', 'jweb', 'isNative'], this.props);
    const gnb = contentStack[GLOBAL_NAV_BAR_RESOURCE_NAME];
    const language = window.location.pathname.split('/')[2];
    const isRTLSupport = RTL_LANGUAGES.includes(language);

    // Check to see if we are in pending state and an error has occurred. Turn pending off if so.
    if (this.state_pending && printerCommon.isComplete && printerCommon.isError) {
      this.state_pending = false; // // REMOVE setState
    }
    // Make sure that content does not load if necessary data isn't complete
    if (!myPrinters.isComplete || !printerCommon.isComplete || !gnb || this.state_pending) {
      return (
        <div className="contentLoading">
          <LoaderBlock />
        </div>
      );
    }
    const { WPPData, csData } = myPrinters.data;
    const myprintersPath = '/myprinters';
    const hasAtleastOnePrinter = WPPData && WPPData.length > 0;

    if (isNative && hasAtleastOnePrinter) {
      return <Redirect to="/myprinters" />;
    }
    return (
      <div className="addPrinter">
        <div className="myPrinters--meta">
          {hasAtleastOnePrinter && (
            <BreadCrumbs
              section={path(['data', 'entries', 0, 'my_printers_link', 0, 'printer_block', 'my_printers_links_text'], gnb)}
              sectionLink={myprintersPath}
              ID="Breadcrumb-AddPrinter"
            />
          )}
        </div>
        <PageHeadIcon
          pageHeading={csData.add_printer.add_printer_heading}
          pageIcon="/assets/img/menu_icon_my_printers.svg"
          pageIconAlt="printer icon"
        />
        <form className="addPrinter--form" onSubmit={this.onFormSubmit}>
          <div className="addPrinter--intro" dangerouslySetInnerHTML={{ __html: csData.add_printer.add_printer_onboarding }} />
          <fieldset className="addPrinter--fieldset">
            <label className="addPrinter--fieldLable" htmlFor="claim-code-input">
              <input
                id="claim-code-input" type="text"
                className="input-group-field"
                placeholder={csData.add_printer.add_printer_placeholder}
                onChange={this.handleClaimChange}
                autoFocus
                required
              />
            </label>
            {/* TODO: THIS SHOULD MOVE OUT OF PRINTER COMMON OR BE MORE SPECIFIC WITHIN PRINTER COMMON */}
            {printerCommon.isError && (
              <PrinterClaimError
                errorDetail={printerCommon.errorDetail}
                errorMsg={printerCommon.errorMsg}
              />
            )}
            <div className="addPrinter--buttonPair">
              <HollowButton
                buttonID="AddPrinter-cancel-btn"
                buttonText={csData.add_printer.button_cancel}
                onClick={this.goBack}
                disabled={WPPData.length < 1}
              />
              <HollowButton
                buttonID="AddPrinter-add-btn"
                buttonText={csData.add_printer.button_add}
                classes={isRTLSupport ? "rtl-button" : ""}
                onClick={this.handleAddPrinter}
              />
            </div>
          </fieldset>
          <div id="claimCodeDirections" className="addPrinter--directions">
            <h3 className="directions--header">{csData.add_printer.add_printer_directions_heading}</h3>
            <div className="directions--content" dangerouslySetInnerHTML={{ __html: csData.add_printer.add_printer_directions }} />
            <div className="addPrinter--claimCodeExamples">
              {this.generateClaimCodeExamples()}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default bind(NewPrinter);
