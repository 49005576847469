import React from 'react';
import bind from '../bind';
import SimplePage from './../shared-components/organisms/SimplePage';

class EprintLanding extends React.Component {

  componentWillMount() {
    let locale = this.props.locales.currentLocale;
    this.props.getEprintLanding(locale);
  }

  render () {
    let { eprintLanding } = this.props;
    if (eprintLanding.isComplete) {
      return (
        <SimplePage title={eprintLanding.data.titleText} date={eprintLanding.data.dateText} body={{ __html : eprintLanding.data.body }} />
      );
    } else if (eprintLanding.isError) {
      return (
        <p>{eprintLanding.errorMsg}</p>
      )
    } else { // Case: We're loading content
      return <div></div>;
    }
  }
}

export default bind(EprintLanding);
