import React from 'react';
import { Route, Switch } from 'react-router';
import styled from 'styled-components';
import { values, join, compose, map, prop } from 'ramda';
import classNames from 'classnames';
import { DirectionProvider } from '@veneer/core';

// Nav/Home/Layout components
import Navbar from './revised/Global/Navbar';
import NewFooter from './revised/Global/Footer';
import Home from './revised/Home';

// Page Components
import NotificationBanner from './global/NotificationBanner';
import EprintLanding from './eprint-landing/EprintLanding';
import ProductPage from './product-page/ProductPage';
import DataCollectionNotice from './DataCollectionNotice';
import PIPLConsents from './PIPLConsents';
import MyPrinter from './my-printer/MyPrinter';
import MyPrinters from './my-printers/MyPrinters';
import NewPrinter from './my-printers/NewPrinter';
import Notifications from './settings/Notifications';
import PrintAnywhereAppView from './print-anywhere/PrintAnywhereAppView';
import SignIn from './global/signin';
import SignOut from './global/signout';
import CreateAccount from './global/createAccount';
import Terms from './terms-of-use/Terms';
import SMBAdminTou from './terms-of-use/SMBAdminTou';
import PlainTOU from './terms-of-use/PlainTOU';
import HpAppTou from './terms-of-use/HpAppTou';
import Contact from './terms-of-use/Contact';
import LoaderBlock from './shared-components/molecules/LoaderBlock';
import SmartHelp from './smart-help/SmartHelp';
import WirelessPrinting from './wireless-printing/WirelessPrinting';
import ErrorBoundary from './shared-components/atoms/ErrorBoundary';
import MobileFax from './mobile-fax/MobileFax';
import HpPlusSecondOffer from './hp-plus-seccond-offer';
import SMBDownload from './smb-download/index';
import Origami from './origami/Origami';
import PsTelemetry from './ps-telemetry/PsTelemetry';

// UCDE components
import UCDESignOut from './Dashboard/components/SignOut';
import Onboarding from './Dashboard/pages/Onboarding';
import UCDERoutes from './UCDERoutes';

// Utility Components
import {
  LocaleInjectionRedirect,
  FeatureFlagRoute,
  ProtectedRoute,
  Redirect,
  EssentialsRedirect
} from './routing';
import HtmlHead from './global/html-head/HtmlHead';
import ExternalRedirect from './global/redirect/ExternalRedirect';

//  Constants
import { GLOBAL_NAV_BAR_RESOURCE_NAME } from '../constants/contentStackResources';
import * as FEATURE_FLAGS from '../constants/featureFlags';
import * as PRINTERIDS from '../constants/printerIds';

// Utilities
import { prepareGATracking, pageViewTracking } from '../lib/analyticsTracking';
import { appendLocaleToWebauthUrl } from '../lib/webauthUtils';
import bind from './bind';
import { LTR, RTL, RTL_LANGUAGES } from '../constants/i18n';

const MainContainer = styled.main`
  margin-top: ${(props) => (props.inapp ? '' : '68px')};
  ${({ topMargin, errorPage }) => `min-height: calc(100vh - ${
    topMargin ? '62px' : errorPage ? '135px' : '116px'
  });
      scroll-behavior: smooth;
      ${topMargin ? 'margin-top: 62px;' : ''}
      position: relative;
    `}
`;

const printerIdRegex = compose(join('|'), values)(PRINTERIDS);

const legacyPrinterIdRegex = compose(
  join('|'),
  map((str) => `${str}-info`),
  values
)(PRINTERIDS);

const validRoutePrefixes = [
  'loggedin',
  'in-app',
  'in-app-help',
  'plain',
  'ucde',
  'essentials',
  'gateway',
  'data-notice',
  'hpapp-tou',
  'qrc'
];

const pathnameShouldHaveHeaderFooter = (pathname) =>
  !validRoutePrefixes.find((featureString) => pathname.toLowerCase().includes(featureString));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.trackPageChangeConditional = '';
  }

  componentDidUpdate() {
    // THE FOLLOWING IF BLOCK IS THE ENTIRE GA PAGEVIEW TRACKING
    const currentPrinterModel =
      this.props.userInfo.userPrinterModelLastTouched != null
        ? this.props.userInfo.userPrinterModelLastTouched
        : '';
    const currentPrinterName =
      this.props.userInfo.userPrinterName != null ? this.props.userInfo.userPrinterName : '';
    if (this.trackPageChangeConditional !== this.props.history.location.pathname) {
      this.trackPageChangeConditional = this.props.history.location.pathname;
      const pageNow = this.props.history.location.pathname;
      pageViewTracking(pageNow, currentPrinterModel, currentPrinterName);
    }
    const numberOfPrinters =
      !!this.props.myPrinters.data && !!this.props.myPrinters.data.WPPData
        ? this.props.myPrinters.data.WPPData.length
        : 0;
    prepareGATracking(numberOfPrinters, currentPrinterModel, currentPrinterName);
  }

  render() {
    const { match, config, contentStack, userInfo, locales, geoCountry, flags } = this.props;

    const { secondOfferShellRedirect } = flags;

    const { userInfoData } = userInfo;

    const currentDirection = RTL_LANGUAGES.includes(locales.languageCode) ? RTL : LTR;

    const {
      url,
      params: { country, language }
    } = match;

    if (!config.isComplete) {
      return (
        <div className="pageContent-loading">
          <LoaderBlock />
        </div>
      );
    }

    // Appending country to login and create account urls, because we don't know country at config load time.
    const signInLink = appendLocaleToWebauthUrl(config.data.Get('LOGIN_URL'), locales);
    const createAccountLink = appendLocaleToWebauthUrl(
      config.data.Get('CREATE_ACCOUNT_URL'),
      locales
    );
    const pageHasHeader = pathnameShouldHaveHeaderFooter(url);

    const pageContentContainer = classNames('pageContent', {
      loading: pageHasHeader && !prop(GLOBAL_NAV_BAR_RESOURCE_NAME, contentStack)
    });

    return (
      <DirectionProvider direction={currentDirection}>
        <div id="pageContentContainer" className={pageContentContainer}>
          <HtmlHead />
          <Route>
            {({ location: { pathname } }) => {
              const shouldHaveHeader = pathnameShouldHaveHeaderFooter(pathname);
              if (!shouldHaveHeader) {
                return <React.Fragment></React.Fragment>;
              }
              return <Navbar geoCountry={geoCountry} userInfoData={userInfoData} />;
            }}
          </Route>
          <ErrorBoundary>
            <NotificationBanner
              ON={this.props.gnb.isNotificationBanner}
              closeBannerFn={this.props.closeNotificationsBanner}
            />
          </ErrorBoundary>
          {this.props.gnb.isMobileMenu && (
            <div id="background" style={{ height: document.body.scrollHeight }} />
          )}
          <MainContainer
            className="main--container"
            topMargin={url === `/${country}/${language}` || url === `/${country}/${language}/`}
            errorPage={url.includes('error-invitation')}
            inapp={url.includes('in-app')}
          >
            <div className="pageContent">
              <ErrorBoundary>
                <Switch>
                  {/* pages that aren't supposed to be styled */}
                  <Route
                    exact
                    path="/:country/:language/qrc/:cartridgepayload"
                    render={(props) => (
                      <ExternalRedirect 
                        path={props.match.params.cartridgepayload} 
                        baseUrl={config.data.Get('BRANCH_IO_REDIRECT_BASE_URL')}
                        queryParams={props.location.search}
                      />
                    )}
                  />
                  {/* just reserving the /01 and /gtin URLs for future uses */}
                  <Route
                    exact
                    path="/:country/:language/01"
                  />
                  <Route
                    exact
                    path="/:country/:language/gtin"
                  />
                  <Route
                    path="/:country/:language/in-app-help/:platform/:topic"
                    component={SmartHelp}
                  />
                  <Route path="/:country/:language/in-app-help/:platform" component={SmartHelp} />
                  <Route path="/:country/:language/in-app-help" component={SmartHelp} />
                  <Route path="/:country/:language/plain/tou" component={PlainTOU} />
                  <Route
                    path="/:country/:language/plain/pipl-consent"
                    component={PIPLConsents}
                  />
                  <Route
                    path="/:country/:language/plain/data-collection-notice/:version?"
                    component={DataCollectionNotice}
                  />
                  <Route
                    path="/:country/:language/plain/printer-data-collection-notice/:version?"
                    component={DataCollectionNotice}
                  />
                  <Route
                    path="/:country/:language/plain/services-data-collection-notice/:version?"
                    component={DataCollectionNotice}
                  />
                  <Route
                    path="/:country/:language/plain/windows-printer-data-collection-notice/:version?"
                    component={DataCollectionNotice}
                  />
                  <Route path="/:country/:language/in-app/mobile-fax" component={MobileFax} />
                  <FeatureFlagRoute
                    featureKey={FEATURE_FLAGS.PRINTANYWHERE}
                    path="/:country/:language/in-app/print-anywhere"
                    component={PrintAnywhereAppView}
                  />
                  <FeatureFlagRoute
                    featureKey={FEATURE_FLAGS.PRINTANYWHERE}
                    path="/:country/:language/in-app/:platform/print-anywhere"
                    component={PrintAnywhereAppView}
                  />
                  <Route
                    path="/:country/:language/createaccount"
                    render={(routeProps) => (
                      <CreateAccount loc={createAccountLink} {...routeProps} />
                    )}
                  />
                  <Route
                    path="/:country/:language/signin"
                    render={() => <SignIn loc={signInLink} />}
                  />
                  <Route
                    path="/:country/:language/signout"
                    render={(routeProps) => (
                      <SignOut loc={config.data.Get('LOGOUT_URL')} {...routeProps} />
                    )}
                  />
                  <Route path="/:country/:language/loggedin">
                    <Onboarding />
                  </Route>
                  <FeatureFlagRoute
                    featureKey={FEATURE_FLAGS.UCDE}
                    path="/:country/:language/(ucde|gateway)/signout"
                  >
                    <UCDESignOut />
                  </FeatureFlagRoute>
                  <FeatureFlagRoute
                    featureKey={FEATURE_FLAGS.UCDE}
                    path="/:country/:language/(ucde|gateway)"
                  >
                    {({ match: routeMatch }) => (
                      <UCDERoutes match={routeMatch} gateway={url.includes('gateway')} />
                    )}
                  </FeatureFlagRoute>
                  <Route path={`/:country/:language/:id(${printerIdRegex})`}>
                    <EssentialsRedirect />
                  </Route>

                  {/* regular, styled pages */}
                  <Route path="/:country/:language/smart-help/:topic?" component={SmartHelp} />
                  <ProtectedRoute
                    path="/:country/:language/myprinter/:id?"
                    render={(props) => <MyPrinter featureFlags={flags} {...props} />}
                  />
                  <ProtectedRoute path="/:country/:language/myprinters/:limit?" component={MyPrinters} />
                  <Route path="/:country/:language/mobile-fax">
                    <MobileFax />
                  </Route>
                  <Route path="/:country/:language/paper-subscription">
                    <Origami {...this.props} />
                  </Route>
                  <Route path="/:country/:language/activate-hp-plus">
                    {secondOfferShellRedirect ? (
                      <ExternalRedirect
                        path={'/activate-hp-plus'}
                        baseUrl={ country && language ? `/${country}/${language}/ucde` : `/ucde` }
                        queryParams={window.location.search}
                      />
                    ) : (
                      (routeProps) => <HpPlusSecondOffer {...routeProps} />
                    )}
                  </Route>
                  <Route path="/:country/:language/essentials">
                    <EssentialsRedirect />
                  </Route>
                  <ProtectedRoute path="/:country/:language/newprinter" component={NewPrinter} />
                  <Route path="/:country/:language/notifications" component={Notifications} />
                  <Route path="/:country/:language/contact" component={Contact} />

                  <Route
                    path="/:country/:language/data-collection-notice/:version?"
                    render={() => <Redirect to="/plain/data-collection-notice" />}
                  />
                  <Route
                    path="/:country/:language/printer-data-collection-notice/:version?"
                    render={() => <Redirect to="/plain/printer-data-collection-notice" />}
                  />
                  <Route
                    path="/:country/:language/services-data-collection-notice/:version?"
                    render={() => <Redirect to="/plain/services-data-collection-notice" />}
                  />
                  <Route
                    path="/:country/:language/gnb/hpctou"
                    render={() => <Redirect to="/plain/tou" />}
                  />
                  <Route path="/:country/:language/hpctou" render={() => <Redirect to="/tou" />} />
                  <Route path="/:country/:language/tou" component={Terms} />
                  <Route path="/:country/:language/admin-tou" component={SMBAdminTou} />
                  <Route path="/:country/:language/smb-admin-tou">
                    {({
                      match: {
                        params: { country: coun, language: lang }
                      }
                    }) => <Redirect to={`/${coun}/${lang}/admin-tou`} />}
                  </Route>
                  <Route path="/:country/:language/hpapp-tou-redirect" component={HpAppTou} />
                  <Route path="/:country/:language/hpapp-tou" component={HpAppTou} />
                  <Route path="/:country/:language/download" component={SMBDownload} />
                  <Route path="/:country/:language/cms/HPC/ePrint" component={EprintLanding} />
                  <Route path="/:country/:language/help" component={ProductPage} /> {/* legacy - not used anymore */}
                  <Route
                    path="/:country/:language/wireless-printing/:topic/:subTopic"
                    component={WirelessPrinting}
                  />
                  <Route
                    path="/:country/:language/wireless-printing/:topic"
                    component={WirelessPrinting}
                  />
                  <Route
                    path="/:country/:language/wireless-printing"
                    component={WirelessPrinting}
                  />
                  <Route path="/:country/:language/data-notice" component={PsTelemetry} />

                  <Route path="/:country/:language/home" component={Home} />
                  <Route path={`/:country/:language/:id(${legacyPrinterIdRegex})`}>
                    {(routeProps) => <ProductPage {...routeProps} />}  {/* legacy - not used anymore */}
                  </Route>
                  <Route exact path="/:country/:language">
                    {(routeProps) => <Home geoCountry={geoCountry} {...routeProps} locales={locales} />}
                  </Route>
                  <LocaleInjectionRedirect dropSuffix />
                </Switch>
              </ErrorBoundary>
            </div>
          </MainContainer>
          <Route>
            {({ location: { pathname } }) => {
              const shouldHaveFooter = pathnameShouldHaveHeaderFooter(pathname);
              if (!shouldHaveFooter) {
                return <React.Fragment></React.Fragment>;
              }
              return <NewFooter userInfoData={userInfoData} />;
            }}
          </Route>
        </div>
      </DirectionProvider>
    );
  }
}

export default bind(App);
