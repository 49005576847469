import styled, { keyframes } from 'styled-components';
import { breakpoints } from '../../../utils/styleHelpers';

const PlaceHolderShimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const AnimatedSection = styled.div`
  height: ${(props) => props.height || '15px'};
  width: ${(props) => props.width || '100%'};
  margin-bottom: ${(props) => props.margin || '0px'};
  animation: ${PlaceHolderShimmer} 1300ms ease-in-out infinite;
  background-color: #e6e6e6;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
`;

export const GapLoadingSection = styled.div`
  height: 2px;
  background-color: #fff;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
`;

export const BannerLoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 685px;
  @media (max-width: ${breakpoints.bigmobile}) {
    // height: 445px;
  }
`;

export const BannerContentLoadingSection = styled.div`
  align-items: center;
  background: #fff;
  text-align: start;
  display: flex;
  flex-direction: column;
  margin: 10rem 2rem;
  width: 40%;
  padding: 20px;
  align-items: flex-start;
  @media (max-width: ${breakpoints.bigmobile}) {
    width: 100%;
    margin: 0;
  }
`;

export const HowItWorksLoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 10%;
  align-items: center;
`;

export const HowItWorksContentContainerLoadingSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  @media (max-width: ${breakpoints.bigmobile}) {
    flex-direction: column;
  }
`;

export const HowItWorksContentLoadingSection = styled.div`
  align-items: center;
  background: #fff;
  text-align: start;
  display: flex;
  flex-direction: column;
  margin: 2rem 2rem;
  width: 30%;
  padding: 20px;
  @media (max-width: ${breakpoints.bigmobile}) {
    width: 100%;
  }
`;

export const PricingLoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PricingBlueContainerLoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ddd;
  width: 100%;
  padding: 30px 0 70px;
`;

export const PricingValueContainerLoadingSection = styled.div`
  display: flex;
  flex-direction: row;
  margin: -68px 0 20px;
  width: 60%;
  padding: 20px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 1px 1px 10px -1px #ddd;
  @media (max-width: ${breakpoints.bigmobile}) {
    width: 100%;
    padding: 10px;
  }
`;

export const PricingSingleContainerLoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 5px;
  align-items: center;
`;

export const PropsLoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PropsContainerLoadingSection = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 20px;
  @media (max-width: ${breakpoints.bigmobile}) {
    flex-direction: column;
  }
`;

export const AdditionalFeaturesLoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const AdditionalFeaturesContentLoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  text-align: start;
  width: 25%;
  margin: 100px;
  padding: 20px;
  margin-left: ${(props) => props.paddingMargin};
  @media (max-width: ${breakpoints.bigmobile}) {
    width: 100%;
    margin: 150px 0 0;
  }
`;

export const FAQsLoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 25% 50px;
  align-items: center;
  background: #f9f9f9;
  @media (max-width: ${breakpoints.bigmobile}) {
    padding: 40px 5% 50px;
  }
`;

export const FAQsContentLoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  background: #fff;
`;

export const NotesContainerLoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 20%;
  @media (max-width: ${breakpoints.bigmobile}) {
    margin: 30px 5%;
  }
`;
