import keyMirror from '../lib/keyMirror';

export default keyMirror({
    MYPRINTER_PAGE: 'page',
    MYPRINTER_HERO: 'hero',
    MYPRINTER_INK_SETTINGS: 'ink-settings',
    MYPRINTER_EPRINT_SETTINGS_EMAIL: 'eprint-settings-email',
    MYPRINTER_EPRINT_SETTINGS_ACCESS: 'eprint-settings-access',
    MYPRINTER_EPRINT_SETTINGS_ACCESS_BLACKLIST: 'eprint-settings-access-blacklist',
    MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST: 'eprint-settings-access-whitelist',
    MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST_SEND_TOGGLE: 'eprint-settings-access-whitelist-send-toggle',
    MYPRINTER_EPRINT_SETTINGS_PREFERENCES_COLOUR: 'eprint-settings-preferences-colour',
    MYPRINTER_EPRINT_SETTINGS_PREFERENCES_PAPER: 'eprint-settings-preferences-paper',
    MYPRINTER_EPRINT_SETTINGS_PREFERENCES_SIDES: 'eprint-settings-preferences-sides',
    MYPRINTER_FACEBOOK: 'facebook'
});
