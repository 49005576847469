import React from 'react';
import { Container } from '../../../../components/shared-components/styles';
import BenefitsList from '../../../../components/Services/Benefits';
import i18n from '../../../../utils/i18n';
import {
  Content,
  Description,
  Title,
} from './styles';

const ServicesSummaryPage = () => (
  <Container data-testid="benefits-list">
    <Content>
      <Title>{i18n.t('manageHPBenefits.overview.topOfPage.title')}</Title>
      <Description>
        {i18n.t('manageHPBenefits.overview.topOfPage.description')}
      </Description>
      <BenefitsList />
    </Content>
  </Container>
);

export default ServicesSummaryPage;
