import React, { Component } from 'react';
import classNames from 'classnames';
import dateFormat from 'dateformat';
import BlueCheckmark from '../../shared-components/atoms/BlueCheckmark';
import ExclamationMark from '../../shared-components/atoms/ExclamationMark';
import bind from '../../bind';
import Accordion from '../../shared-components/organisms/Accordion';
import NewAccordion from '../../shared-components/organisms/NewAccordion';
import styled from 'styled-components';

const PromoWarrantyRemaining = styled.span`
  display: none;
  font-size: ${14 / 16}rem;
  text-align: center;
  margin-bottom: ${14 / 16}rem;
  font-family: $body-font-normal;
  @media (min-width:1024px) {
    display: block;
  }
  @media (min-width:640px) {
    font-size: ${16 / 16}rem;
    margin-bottom: ${16 / 16}rem;
  }
`
class CarePack extends Component {
  constructor(props) {
    super(props);
    this.keyID = 'care-pack';
    this.onActionClicked = this.onActionClicked.bind(this);
    this.getCarepackContent = this.getCarepackContent.bind(this);
    this.getAccordionIcon = this.getAccordionIcon.bind(this);
    this.carePackTitle = '...';
    this.cpTitleGraphic = '';
    this.cp_data = null;
    this.switchUponChange = null;
    this.counter = 0;
    this.do_once_done = false;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active) {
      this.toggleAccordion();
    }
  }

  getAccordionIcon(option) {
    if (option.toLowerCase() == 'checkmark') {
      return <BlueCheckmark />;
    } if (option.toLowerCase() == 'exclamationmark') {
      return <ExclamationMark />;
    }
    return null;
  }

  onActionClicked(URL, trackScenario) {
    // tracking
    switch (trackScenario) {
      case 'scenario-1':
      case 'scenario-2c':
      case 'scenario-4':
        document.getElementById('care-pack-extend-now-tracking').click();
        break;

      case 'scenario-1b':
        document.getElementById('care-pack-learn-more-tracking').click();
        break;

      case 'scenario-2':
      case 'scenario-2b':
        document.getElementById('care-pack-terms-and-conditions-tracking').click();
        break;

      case 'scenario-5':
      case 'scenario-7':
        document.getElementById('care-pack-explore-new-printers-tracking').click();
        break;

      case 'scenario-2d':
        document.getElementById('care-pack-explore-accessories-tracking').click();
        break;

      default:
        // console.warn("carepack tracking failed")
        // attempt to track anyway
        document.getElementById('care-pack-extend-now-tracking').click();
    }

    const win = window.open(URL, '_blank');
    win.focus();
  }

  getCarepackContent = (includeHeader, clickHandler) => {
    const carepackLogic = this.props.myPrinter.data.WPPData.carepack;
    const cpScenario = this.props.myPrinter.data.carepack.scenarios[0];
    const configData = !!this.props.config.isComplete && !this.props.config.isError ? this.props.config.data : '';
    const current_stack = configData ? configData.Get('CURRENT_STACK') : '';

    if (this.cp_data !== null && this.switchUponChange === includeHeader) {
      return this.cp_data;
    }
    this.switchUponChange = includeHeader;
    const cpData = cpScenario;

    if (!!cpData === false) {
      return null;
    }
    const printer = this.props.myPrinter.data.WPPData;

    const daysRemaining = carepackLogic.remainingDays;
    const { descriptionText } = carepackLogic;
    const expirationDate = dateFormat(new Date(carepackLogic.endDate), 'mm/dd/yyyy');
    let cpWarrantyStatus = '';
    let cpTimeRemaining = '';
    let cpDetailsMessage = '';
    let cpContactInfo = '';
    let cpAvailability = '';
    const cpDetailsSectionArr = [];
    let modelText = '';
    let serialText = '';
    let serialNumberFiltered = '';
    let cpButtonText = '';
    let cpButtonAddress = '';
    const cpTitleGraphic = '';
    const detailsClassDict = {
      cpDetailsMessage: 'promoSection--details',
      cpObligationServicesMsg: 'promoSection--serviceName',
    };
    if (!!cpData && !!configData) {
      if (!!cpData.cpTitleSelection && !!cpData.cpTitleSelection['0']) {
        this.carePackTitle = cpData.cpTitleSelection['0'].cpTitle ? cpData.cpTitleSelection['0'].cpTitle : 'HP Coverage';
      }
      if (cpData.cpWarrantyStatusSelection !== undefined && cpData.cpWarrantyStatusSelection['0'] !== undefined) {
        cpWarrantyStatus = cpData.cpWarrantyStatusSelection['0'].cpWarrantyStatus ? cpData.cpWarrantyStatusSelection['0'].cpWarrantyStatus : '';
      }

      if (cpData.cpTimeRemainingSelection !== undefined && cpData.cpTimeRemainingSelection['0'] !== undefined) {
        cpTimeRemaining = cpData.cpTimeRemainingSelection['0'].cpTimeRemaining ? cpData.cpTimeRemainingSelection['0'].cpTimeRemaining : '';
      }

      if (cpData.cpDetailsSelection !== undefined) {
        cpData.cpDetailsSelection.map(x => cpDetailsSectionArr.push(x[Object.keys(x)[0]]));
        cpDetailsMessage = !!cpData.cpDetailsSelection[0] && !!cpData.cpDetailsSelection[0].cpDetailsMessage ? cpData.cpDetailsSelection[0].cpDetailsMessage : !!cpData.cpDetailsSelection[1] && !!cpData.cpDetailsSelection[1].cpDetailsMessage ? cpData.cpDetailsSelection[1].cpDetailsMessage : '';

      }
      if (cpData.cpContactInfoSelection !== undefined) {
        cpContactInfo = cpData.cpContactInfoSelection[0] ? cpData.cpContactInfoSelection[0].cpContactInfo ? cpData.cpContactInfoSelection[0].cpContactInfo : '' : '';
      }
      if (cpData.cpContactAvailabilitySelection !== undefined) {
        cpAvailability = cpData.cpContactAvailabilitySelection[0] ? cpData.cpContactAvailabilitySelection[0].cpContactAvailability ? cpData.cpContactAvailabilitySelection[0].cpContactAvailability : '' : '';
      }

      if (cpData.cpModelSerialButtonSelection !== undefined) {
        if (cpData.cpModelSerialButtonSelection['0'] !== undefined) {
          modelText = cpData.cpModelSerialButtonSelection['0'].cpModelSerialNumberText['0'].modelText;
          serialText = cpData.cpModelSerialButtonSelection['0'].cpModelSerialNumberText['0'].serialText;
        }
        cpButtonText = cpData.cpModelSerialButtonSelection[1] ? cpData.cpModelSerialButtonSelection[1].cpButtonText ? cpData.cpModelSerialButtonSelection[1].cpButtonText : '' : '';
      }
      if (cpData.cpButtonAddressSelection) {
        if (printer.printerSerialNumber.indexOf('SIM') === 0 && printer.printerSerialNumber.indexOf('MIS') !== -1) {
          serialNumberFiltered = printer.printerSerialNumber.substring(3, printer.printerSerialNumber.length - 3);
        } else {
          serialNumberFiltered = printer.printerSerialNumber;
        }
        const multipleAddresses = !!cpData.cpButtonAddressSelection['0'] && !!cpData.cpButtonAddressSelection['0'].cpButtonAddresses && cpData.cpButtonAddressSelection['0'].cpButtonAddresses.length > 1;
        const stacks = ['productionAddress', 'testAddress'];
        const stack_key = (multipleAddresses && current_stack.toLowerCase() !== 'prod') ? 1 : 0;
        cpButtonAddress = cpData.cpButtonAddressSelection['0'] ? cpData.cpButtonAddressSelection['0'].cpButtonAddresses ? cpData.cpButtonAddressSelection['0'].cpButtonAddresses[stack_key][stacks[stack_key]] : '' : '';

        const isGB = this.props.locales.countryCode === 'gb';

        let addQueryString;
        if (isGB) {
          addQueryString = ['scenario-1', 'scenario-1b'].indexOf(cpScenario.slug.toLowerCase()) > -1;
        } else {
          // US
          addQueryString = ['scenario-1', 'scenario-1b', 'scenario-2c', 'scenario-4'].indexOf(cpScenario.slug.toLowerCase()) > -1;
        }


        if (addQueryString) {
          // 1365
          const truncatedSerialNumer = serialNumberFiltered.substring(0, 10);
          cpButtonAddress = cpButtonAddress !== '' ? cpButtonAddress.indexOf('?') !== -1 ? `${cpButtonAddress}&mno=${printer.printerModelNumber}&sno=${truncatedSerialNumer}` : `${cpButtonAddress}?mno=${printer.printerModelNumber}&sno=${truncatedSerialNumer}` : '';
        }
      }
      if (cpData.cpTitleGraphic) {
        this.cpTitleGraphic = cpData.cpTitleGraphic.value ? cpData.cpTitleGraphic.value : false;
      }
    }

    // return (
    const classname = classNames(
      {
        accordionContentCarePack: !includeHeader,
        'printerCell--promotion': includeHeader,
        noWarrantySubhead: !cpTimeRemaining || !cpWarrantyStatus,
      },
    );
    // return (
    const promoDetails = classNames(
      {
        'promoSection--details': true,
        serviceNameBelow: cpDetailsSectionArr[1] === '1',
      },
    );

    this.cp_data = (
      <div className={classname}>
        {
          includeHeader && (
            <div className="promoSection--title">
              <h4 dangerouslySetInnerHTML={{ __html: this.carePackTitle }} />
              {
                this.cpTitleGraphic && (
                  <span className="promoSection--animatedIcon">
                    {this.getAccordionIcon(this.cpTitleGraphic)}
                  </span>
                )
              }
            </div>
          )
        }
        {!!cpWarrantyStatus && !this.props.gnb.isTablet && (
        <PromoWarrantyRemaining>
          {cpWarrantyStatus}
          {' '}
          {expirationDate}
        </PromoWarrantyRemaining>
        )}
        {!!cpTimeRemaining && this.props.gnb.isTablet && (
        <PromoWarrantyRemaining>
          {cpTimeRemaining}
          {' '}
          {daysRemaining > 0 ? daysRemaining : ''}
        </PromoWarrantyRemaining>
        )}
        <div className="promoSection--contents">
          {cpDetailsSectionArr[0] === '1' && (
          <span className="promoSection--serviceName">
            {' '}
            {descriptionText}
            {' '}
          </span>
          )}
          {!!cpDetailsMessage && <span className={promoDetails} dangerouslySetInnerHTML={{ __html: cpDetailsMessage }} />}
          {cpDetailsSectionArr[1] === '1' && (
          <span className="promoSection--serviceName">
            {' '}
            {descriptionText}
            {' '}
          </span>
          )}
          {!!cpContactInfo && <span className="promoSection--contactInfo" dangerouslySetInnerHTML={{ __html: cpContactInfo }} />}
          {!!cpAvailability && <span className="promoSection--availability" dangerouslySetInnerHTML={{ __html: cpAvailability }} />}
          {!!modelText && (
          <span className="promoSection--modelSerial">
            {modelText}
            :
            {' '}
            {printer.printerModelNumber}
            {' '}
            /
            {' '}
            {serialText}
            :
            {' '}
            {printer.printerSerialNumber}
          </span>
          )}
          {!!cpButtonAddress && !!cpButtonText && (
          <button
            onClick={() => clickHandler(cpButtonAddress, cpScenario.slug.toLowerCase())} type="button"
            id="care-pack-extend-now" className="button hollow promoSection--button"
          >
            {cpButtonText}
          </button>
          )}
        </div>
      </div>
    );

    if (!!cpData.carePackFootnoteSelection && !this.props.myPrinter.isFetching) {
      setTimeout(
        () => {
          const para = document.getElementById('carepack-footnotes');
          const content_node = document.createElement('p');
          content_node.innerHTML = cpData.carePackFootnoteSelection[0].carePackFootnote;
          if (para !== null && !this.do_once_done) {
            para.appendChild(content_node);
            this.do_once_done = true;
          } else {
          }
        },
        1000,
      );
    }

    return this.cp_data;
  }

  // ///////////////////////////////////////////////////////////////////////////////////////////
  render() {
    const {
      printAnywhereWebIsEnabled,
      gnb,
    } = this.props;
    const {
      isTablet,
    } = gnb;

    const OldContent = isTablet
      ? (
        <div
          id={`container_${this.keyID}`}
          className={classNames('accordion', 'myPrinterSetting')}
        >
          <a id={this.keyID} className="accordionTitle" >
            <span className="accordionTitle--icon">
              <img src="/assets/img/accordion_icon.svg" alt="accordion icon" />
            </span>
            <span
              className="accordionTitle--title"
              dangerouslySetInnerHTML={{ __html: this.carePackTitle }}
            />
            {
              this.cpTitleGraphic && (
                <span className="accordionTitle--status accordionTitle--status-checked">
                  {this.getAccordionIcon(this.cpTitleGraphic)}
                </span>
              )
            }
          </a>
          <Accordion
            ID={this.keyID}
            actionID={this.keyID}
            groupID="InkSettings"
            groupFn={this.props.groupFn}
            controler={this.props.controler}
            callbackFn={this.callbackFn}
          >
            {this.getCarepackContent(false, this.onActionClicked)}
          </Accordion>
        </div>
      )
      : (this.getCarepackContent(true, this.onActionClicked));

    return (
      <div className="printerGrid--printerCell">
        <a name="tracking-hook" id="care-pack-extend-now-tracking" />
        <a name="tracking-hook" id="care-pack-learn-more-tracking" />
        <a name="tracking-hook" id="care-pack-terms-and-conditions-tracking" />
        <a name="tracking-hook" id="care-pack-explore-new-printers-tracking" />
        <a name="tracking-hook" id="care-pack-explore-accessories-tracking" />
        {
          printAnywhereWebIsEnabled
            ? (
              <NewAccordion
                title={(
                  <CarePackTitle
                    titleText={this.carePackTitle}
                    icon={this.getAccordionIcon(this.cpTitleGraphic)}
                  />
                )}
                noCollapse={!isTablet}
              >
                {this.getCarepackContent(false, this.onActionClicked)}
              </NewAccordion>
            )
            : OldContent
        }
      </div>
    );
  }
}

export default bind(CarePack);

const CarePackTitle = ({ titleText, icon }) => (
  <React.Fragment>
    <span
      className="accordionTitle--title"
      dangerouslySetInnerHTML={{ __html: titleText }}
    />
    {
      icon && (
        <span className="accordionTitle--status accordionTitle--status-checked">
          {icon}
        </span>
      )
    }
  </React.Fragment>
);
