import React from 'react';
import styled from 'styled-components';
import {
  path,
  prop,
} from 'ramda';

export const stylesIf = (propName, trueValue = '', falseValue = '') => props => (
  prop(propName, props)
    ? trueValue
    : falseValue
);


export const Content = styled.div`
  color: #212121;
  p, strong, li {
    font-size: 16px;
  }
  p {
    margin-bottom: 12px;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  ol {
    list-style: decimal;
    > li > ol {
      list-style: upper-alpha;
    }
  }
  ul {
    list-style: disc;
  }
  li {
    margin-left: 16px;
  }
  h3, h4 {
    font-size: 18px;
    margin-bottom: 12px;
  }
`;

export const ContentContainer = styled.div`
  margin: 32px 0;
  h5 {
    margin-bottom: 16px;
    font-weight: 600;
  }
  @media (max-width: 1200px) {
    border-top: 1px solid #E2E2E2;
  }
`;

export const AccordionContent = styled.div`
  ${ContentContainer}:first-of-type {
    margin-top: 0;
    border: none;
  }
`;

export const Page = styled.div`
  background: ${path(['theme', 'main', 'content', 'background'])};
  color: ${path(['theme', 'main', 'hp', 'fontColor'])};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 70px min-content;
  grid-auto-rows: min-content;
  grid-template-areas:
    "header header header"
    "none none none";
  justify-items: center;
  justify-content: stretch;
  align-content: stretch;
  grid-auto-flow: dense;
  gap: 32px;
  margin-top: 72px;
  font-size: ${path(['theme', 'text', 'mobile', 'fontSize'])};
  padding: 12px;
  @media (min-width: 1200px) {
    padding: 30px;
    margin-left: 260px;
    font-size: ${path(['theme', 'text', 'desktop', 'fontSize'])};
  }
`;

export const Header = styled.header`
  grid-area: header;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: ${({ children }) => (
    React.Children.count(children) > 1
      ? 'space-between'
      : 'flex-end'
  )};
  h1 {
    font-size: ${path(['theme', 'text', 'mobile', 'h1'])};
  }
  @media (min-width: 1200px) {
    ${stylesIf('narrow', 'grid-column: 1 / span 2; grid-row: 1;', 'grid-area: header;')}
    h1 {
      font-size: ${path(['theme', 'text', 'desktop', 'h1'])};
    }
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Main = styled.div`
  grid-column: 1 / span 3;
  @media (min-width: 1200px) {
    grid-column: 1 / span 2;
  }
  width: 100%;
`;

export const Side = styled.aside`
  grid-column: 1 / span 3;
  @media (min-width: 1200px) {
    grid-column: 3;
    :first-of-type {
      grid-row: 2;
    }
  }
  width: 100%;
`;
