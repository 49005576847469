import { createSelector } from 'reselect';
import { selectLocaleParamsFromRoute } from '../../../../selectors/routing';
import selectCurrentStack from '../../store/modules/environment/selectors';
import { isEmpty, path } from 'ramda';

const selectStratusBaseUrlFromConfig = createSelector(
  path(['config', 'data']),
  configClass => isEmpty(configClass) ? null : configClass.Get('STRATUS_BASE_URL'),
);


const selectNotificationSettingsProps = createSelector(
  selectLocaleParamsFromRoute,
  selectCurrentStack,
  selectStratusBaseUrlFromConfig,
  (locale, stack, baseUrl) => ({
    language: locale.language,
    country: locale.country,
    stack: stack,
    baseUrl: baseUrl
  })
);

export default selectNotificationSettingsProps
