import React from 'react';
import Carousel from 'nuka-carousel';
import SliderPanel from '../shared-components/molecules/SliderPanel';
import PagingDots from '../shared-components/molecules/PagingDots';
import SubsectionHead from './../shared-components/molecules/SubsectionHead';
import { GrayTemplateSection, ContentWrapper } from '../shared-components/shared-styles/TemplateStyles';
import { ArrowButtonLeft, ArrowButtonRight } from '../shared-components/atoms/ArrowButtons';
import { sliderPanelTracking } from '../../lib/analyticsTracking';

class InterfaceTour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0
    }
    this.panelTracking = this.panelTracking.bind(this)
    this.analyticsKey = []
  }

  componentDidUpdate(prevProps, prevState) {
    const { device } = this.props;
    let erroneous_nuka_element = document.getElementById("omit_parent_1").parentNode
    if (!!erroneous_nuka_element) {
      erroneous_nuka_element.style.display = "none"
    }

    if (device && this.analyticsKey.length === 0) {
      const { interfaceTour } = device.interfaceTourSelector;
      for (let i = 0; i < interfaceTour.length; i++) {
        this.analyticsKey[i] = interfaceTour[i].tourPanelTitle;
      }
    }
    if (this.state.slideIndex !== prevState.slideIndex) {
      this.panelTracking();
    }
  }

  panelTracking() {
    sliderPanelTracking(`Tango Tour: slide(${this.state.slideIndex}): ` + this.analyticsKey[this.state.slideIndex])
  }

  handleStateChange = (preSlide, slideIndex) => {
    this.setState({ slideIndex: slideIndex })
  }

  render() {
    const {
      device,
      printer_common,
    } = this.props;
    if (!device || !printer_common) {
      return null;
    }
    const { tour_title: tourTitle } = printer_common;
    const { interfaceTour } = device.interfaceTourSelector;

    return (
      <GrayTemplateSection id="my-printer">
        <ContentWrapper>
          <SubsectionHead text={tourTitle} />
          <div dir="ltr" >
            <Carousel id="productTourCarousel"
              renderAnnounceSlideMessage={() => <span id="omit_parent_1"></span>}
              slideIndex={this.state.slideIndex}
              beforeSlide={this.handleStateChange}
              renderCenterLeftControls={({ previousSlide, currentSlide }) => (
                <ArrowButtonLeft
                  id="productTourLeftArrow"
                  disabled={currentSlide === 0}
                  onClick={previousSlide}
                >
                  &lt;
                </ArrowButtonLeft>
              )}
              renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => (
                <ArrowButtonRight
                  id="productTourRightArrow"
                  disabled={currentSlide === (slideCount - 1)}
                  className={slideCount}
                  onClick={nextSlide}
                >
                  &gt;
                </ArrowButtonRight>
              )}
              renderBottomCenterControls={props => <PagingDots prefix="tour" {...props} />}
            >
              {
                interfaceTour.map(panel => (
                  <SliderPanel
                    key={panel.id}
                    imgSrc={panel.tourGraphicUrl}
                    imgAlt={panel.tourGraphicTitle}
                    header={panel.tourPanelTitleText}
                    desc={panel.tourPanelDescriptionText}
                  />
                ))
              }
            </Carousel>
          </div>
        </ContentWrapper>
      </GrayTemplateSection>
    )
  }
};

export default InterfaceTour;
