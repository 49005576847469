import React from 'react';
import PropTypes from 'prop-types';
import { Container, TitleComponent, DescriptionComponent, TextContainer } from './styles/StyleImageContentBox';

const propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  separator: PropTypes.bool.isRequired
};

const defaultProps = {
  imageUrl: '',
  imageTitle: '',
  titleText: '',
  descriptionText: '',
  width: '',
  separator: false
};

const ImageContentBox = (props) => {
  const { imageUrl, imageTitle, titleText, descriptionText, width } = props;
  return (
    <Container data-testid="image-content-box" width={width}>
      <img src={imageUrl} alt={imageTitle} title={imageTitle} />
      <TextContainer>
        {titleText && (
          <TitleComponent
            data-testid="image-content-box-title"
            dangerouslySetInnerHTML={{ __html: titleText }}
          />
        )}

        {descriptionText && (
          <DescriptionComponent
            data-testid="image-content-box-description"
            dangerouslySetInnerHTML={{ __html: descriptionText }}
          />
        )}
      </TextContainer>
    </Container>
  );
};

ImageContentBox.propTypes = propTypes;
ImageContentBox.defaultProps = defaultProps;
export default ImageContentBox;
