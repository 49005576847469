import {
  pathOr,
} from 'ramda';

function determineOS() {
  const { navigator } = window;
  if (navigator.appVersion.indexOf('Win') !== -1) return 'Windows';
  if (navigator.appVersion.indexOf('Mac') !== -1) return 'MacOS';
  if (navigator.appVersion.indexOf('X11') !== -1) return 'UNIX';
  if (navigator.appVersion.indexOf('Linux') !== -1) return 'Linux';

  return 'UnknownOS';
}

const vaLinkBase = 'https://virtualagent-dev.hpcloud.hp.com/';

function findVALaunchPoint(match, location) {
  const ucdeBase = `/${pathOr('us', ['params', 'country'], match)}/${pathOr('en', ['params', 'language'], match)}/ucde`;
  if (location.pathname === ucdeBase) {
    return 'UCDEHome';
  }

  return location.pathname.slice(12);
}

export default function createVALink(match, location) {
  const { country, language } = match.params;

  const vaLink = `${vaLinkBase}?BotClient=HPSmart&BotSubClient=UCDEPortal&lc=${language}&cc=${country}&LaunchPoint=${findVALaunchPoint(match, location)}&DevOS=${determineOS()}`;

  return vaLink;
}
