import React, { useEffect, useState } from 'react';
import Support from './Support';
import base64url from 'base64url';
import GenericSupport from './GenericSupport';
import UpgradeSupport from './UpgradeSupport';
import DeviceSupportList from './DeviceSupportList';
import PrinterSelectListDropdown from '../support-page/PrinterSelectListDropdown';
import { useSelector } from '../../hooks';
import { BEACON_RESET_SUPPORTED_SKUS } from '../../constants/printerConnection';
import { selectQueryParameters } from '../../selectors/routing';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

const SupportContainer = () => {
  const ldFlags = useFlags();
  const { ffSupportUpdate } = ldFlags;
  const queryParams = useSelector(selectQueryParameters);
  const { deviceid, platform = 'windows' } = queryParams;
  const [base64DecodedData, setBase64DecodedData] = useState(null);
  const [deviceModels, setDeviceModels] = useState([]);

  try {
    if (deviceid) {
      const decodeURIComponentData = decodeURIComponent(deviceid);
      JSON.parse(base64url.decode(decodeURIComponentData));
    }
  } catch (error) {
    return <Redirect to={`/support/generic?platform=${platform}`} />;
  }

  useEffect(() => {
    if (deviceid) {
      try {
        const decodeURIComponentData = decodeURIComponent(deviceid);
        const base64DecodedParsedData = JSON.parse(base64url.decode(decodeURIComponentData));
        setBase64DecodedData(base64DecodedParsedData);

        if (base64DecodedParsedData) {
          const length = base64DecodedParsedData && Object.keys(base64DecodedParsedData).length;

          if (length > 0) {
            const deviceModels = base64DecodedParsedData.map((item) => {
              return item.links && item.links[0].href && item.links[0].href.split('/').slice(-1)[0];
            });

            const supportedDeviceModels = deviceModels.filter((deviceModel) => {
              return deviceModel && BEACON_RESET_SUPPORTED_SKUS.includes(deviceModel.toUpperCase());
            });

            const unique = (value, index, self) => {
              return self.indexOf(value) === index;
            };

            const uniqueDeviceModels = supportedDeviceModels.filter(unique);
            setDeviceModels(uniqueDeviceModels);
          }
        }
      } catch (error) {
        <Redirect to={`/support/generic?platform=${platform}`} />;
      }
    } else {
      <Redirect to={`/support/generic?platform=${platform}`} />;
    }
  }, []);

  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/printer-connection`}>
        <Support base64DecodedData={base64DecodedData} deviceModels={deviceModels} />
      </Route>
      <Route path={`${path}/generic`}>
        <GenericSupport />
      </Route>
      <Route path={`${path}/upgrade`}>
        {ffSupportUpdate ? (
          <UpgradeSupport />
        ) : (
          ffSupportUpdate !== undefined && <Redirect to={`/`} />
        )}
      </Route>
      <Route path={`${path}/list`}>
        <DeviceSupportList />
      </Route>
      <Route path={`${path}/printer-list`}>
        <PrinterSelectListDropdown />
      </Route>
    </Switch>
  );
};

export default SupportContainer;
