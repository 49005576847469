//This variable is being refered in products.js and ProductPage.js
//This variable can be removed once productpage.js and products.js is tested and we know that its not being used anywhere
export const INSTANT_INK_COUNTRIES = [
  US,
  GB,
  CA,
  IE,
  ES,
  BE,
  DE,
  AT,
  FR,
  CH,
  LU,
  IT,
  NL,
  NO,
  DK,
  SE,
  PT,
  FI,
  AU,
  NZ,
  HU,
  PL,
  CZ,
  GR,
  RO,
  SK,
  HR,
  BG,
  LV,
  LT,
  EE,
  MT,
  CY,
  IL,
  SI,
  PR,
  HK,
  SG,
  TW,
  CN
];

const AE = 'ae';
const AF = 'af';
const AG = 'ag';
const AI = 'ai';
const AL = 'al';
const AO = 'ao';
const AR = 'ar';
const AT = 'at';
const AU = 'au';
const AW = 'aw';
const AZ = 'az';
const BA = 'ba';
const BB = 'bb';
const BD = 'bd';
const BE = 'be';
const BG = 'bg';
const BH = 'bh';
const BJ = 'bj';
const BL = 'bl';
const BN = 'bn';
const BO = 'bo';
const BQ = 'bq';
const BR = 'br';
const BS = 'bs';
const BT = 'bt';
const BW = 'bw';
const BY = 'by';
const BZ = 'bz';
const CA = 'ca';
const CH = 'ch';
const CI = 'ci';
const CL = 'cl';
const CM = 'cm';
const CN = 'cn';
const CO = 'co';
const CR = 'cr';
const CS = 'cs';
const CU = 'cu';
const CW = 'cw';
const CY = 'cy';
const CZ = 'cz';
const DA = 'da';
const DE = 'de';
const DK = 'dk';
const DO = 'do';
const DZ = 'dz';
const EC = 'ec';
const EE = 'ee';
const EG = 'eg';
const EL = 'el';
const EN = 'en';
const ES = 'es';
const ET = 'et';
const FI = 'fi';
const FR = 'fr';
const GB = 'gb';
const GD = 'gd';
const GE = 'ge';
const GF = 'gf';
const GH = 'gh';
const GP = 'gp';
const GR = 'gr';
const GT = 'gt';
const HE = 'he';
const HK = 'hk';
const HR = 'hr';
const HN = 'hn';
const HT = 'ht';
const HU = 'hu';
const HW = 'hw';
const ID = 'id';
const IE = 'ie';
const IL = 'il';
const IN = 'in';
const IR = 'ir';
const IS = 'is';
const IT = 'it';
const JA = 'ja';
const JM = 'jm';
const JO = 'jo';
const JP = 'jp';
const KE = 'ke';
const KH = 'kh';
const KN = 'kn';
const KO = 'ko';
const KR = 'kr';
const KW = 'kw';
const KY = 'ky';
const KZ = 'kz';
const LA = 'la';
const LB = 'lb';
const LC = 'lc';
const LK = 'lk';
const LT = 'lt';
const LI = 'li';
const LU = 'lu';
const LV = 'lv';
const LY = 'ly';
const MA = 'ma';
const MD = 'md';
const ME = 'me';
const MF = 'mf';
const MG = 'mg';
const MK = 'mk';
const MM = 'mm';
const MN = 'mn';
const MQ = 'mq';
const MS = 'ms';
const MT = 'mt';
const MU = 'mu';
const MV = 'mv';
const MX = 'mx';
const MY = 'my';
const MZ = 'mz';
const NA = 'na';
const NB = 'nb';
const NC = 'nc';
const NG = 'ng';
const NI = 'ni';
const NL = 'nl';
const NO = 'no';
const NP = 'np';
const NZ = 'nz';
const OM = 'om';
const PA = 'pa';
const PE = 'pe';
const PF = 'pf';
const PH = 'ph';
const PK = 'pk';
const PL = 'pl';
const PR = 'pr';
const PT = 'pt';
const PY = 'py';
const QA = 'qa';
const RE = 're';
const RO = 'ro';
const RS = 'rs';
const RU = 'ru';
const RW = 'rw';
const SA = 'sa';
const SE = 'se';
const SG = 'sg';
const SI = 'si';
const SK = 'sk';
const SL = 'sl';
const SN = 'sn';
const SV = 'sv';
const SX = 'sx';
const TC = 'tc';
const TH = 'th';
const TL = 'tl';
const TN = 'tn';
const TR = 'tr';
const TT = 'tt';
const TW = 'tw';
const TZ = 'tz';
const UA = 'ua';
const UG = 'ug';
const US = 'us';
const UY = 'uy';
const UZ = 'uz';
const VC = 'vc';
const VE = 've';
const VG = 'vg';
const VI = 'vi';
const VN = 'vn';
const YT = 'yt';
const ZA = 'za';
const ZM = 'zm';
const ZH = 'zh';

export const CONTENT_STACK_SUPPORTED_LOCALES = {
  'us': [EN],
  'sa': [AR],
  'bg': [BG],
  'cn': [ZH],
  'hk': [ZH],
  'tw': [ZH],
  'hr': [HR],
  'cz': [CS],
  'dk': [DA],
  'be': [NL],
  'au': [EN],
  'gb': [EN],
  'ee': [ET],
  'fi': [FI],
  'fr': [FR],
  'de': [DE],
  'gr': [EL],
  'il': [HE],
  'hu': [HU],
  'id': [ID],
  'it': [IT],
  'jp': [JA],
  'kr': [KO],
  'lv': [LV],
  'lt': [LT],
  'mt': [MT],
  'no': [NB],
  'pl': [PL],
  'br': [PT],
  'pt': [PT],
  'ro': [RO],
  'ru': [RU],
  'sk': [SK],
  'si': [SL],
  'es': [ES, CA],
  'se': [SV],
  'th': [TH],
  'tr': [TR]
}

export const LANGUAGES_ARRAY = [
  AR,
  BG,
  CA,
  CS,
  DA,
  DE,
  EL,
  EN,
  ES,
  ET,
  FI,
  FR,
  HE,
  HR,
  HU,
  ID,
  JA,
  KO,
  LT,
  LV,
  IT,
  // MT,
  NB,
  NL,
  NO,
  PL,
  PT,
  RO,
  RU,
  SI,
  SK,
  SL,
  SV,
  TH,
  TR,
  ZH
];
export const LANGUAGES_SET = new Set(LANGUAGES_ARRAY);

export const LANGUAGES = {
  AR,
  BG,
  CA,
  CS,
  DA,
  DE,
  EL,
  EN,
  ES,
  ET,
  FI,
  FR,
  HE,
  HR,
  HU,
  ID,
  JA,
  KO,
  LT,
  LV,
  IT,
  // MT,
  NB,
  NL,
  NO,
  PL,
  PT,
  RO,
  RU,
  SI,
  SK,
  SL,
  SV,
  TH,
  TR,
  ZH
};

export const PDF_SUPPORTED_LANGUAGES_ARRAY = [EN, ES, ID, TH, TR, ZH];
export const COUNTRIES_ARRAY = [
  AE,
  AF,
  AG,
  AI,
  AL,
  AO,
  AR,
  AT,
  AU,
  AW,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BG,
  BH,
  BJ,
  BL,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BW,
  BY,
  BZ,
  CA,
  CH,
  CI,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CW,
  CY,
  CZ,
  DE,
  DK,
  DO,
  DZ,
  EC,
  EE,
  EG,
  ES,
  ET,
  FI,
  FR,
  GB,
  GD,
  GE,
  GF,
  GH,
  GP,
  GR,
  GT,
  HK,
  HN,
  HR,
  HT,
  HU,
  HW,
  UA,
  UZ,
  ID,
  IE,
  IL,
  IN,
  IR,
  IS,
  IT,
  JM,
  JO,
  JP,
  KE,
  KH,
  KN,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LT,
  LU,
  LV,
  LY,
  MA,
  MD,
  ME,
  MF,
  MG,
  MK,
  MM,
  MQ,
  MS,
  MT,
  MU,
  MV,
  MX,
  MY,
  MZ,
  NA,
  NA,
  NC,
  NI,
  NL,
  NO,
  NP,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PH,
  PK,
  PL,
  PR,
  PT,
  PY,
  QA,
  RE,
  RO,
  RS,
  RW,
  SA,
  SE,
  SG,
  SI,
  SK,
  SN,
  SV,
  SX,
  TC,
  TH,
  TL,
  TN,
  TR,
  TT,
  TW,
  TZ,
  US,
  UY,
  VC,
  VE,
  VG,
  VI,
  VN,
  YT,
  ZA,
  ZM
];

export const COUNTRIES_SET = new Set(COUNTRIES_ARRAY);

export const COUNTRIES = {
  AE,
  AF,
  AG,
  AI,
  AL,
  AO,
  AR,
  AT,
  AU,
  AW,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BG,
  BH,
  BJ,
  BL,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BW,
  BY,
  BZ,
  CA,
  CH,
  CI,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CW,
  CY,
  CZ,
  DE,
  DK,
  DO,
  DZ,
  EC,
  EE,
  EG,
  ES,
  ET,
  FI,
  FR,
  GB,
  GD,
  GE,
  GF,
  GH,
  GP,
  GR,
  GT,
  HK,
  HN,
  HR,
  HT,
  HU,
  HW,
  UA,
  UZ,
  ID,
  IE,
  IL,
  IN,
  IR,
  IS,
  IT,
  JM,
  JO,
  JP,
  KE,
  KH,
  KN,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LT,
  LU,
  LV,
  LY,
  MA,
  MD,
  ME,
  MF,
  MG,
  MK,
  MM,
  MQ,
  MS,
  MT,
  MU,
  MV,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NI,
  NL,
  NO,
  NP,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PH,
  PK,
  PL,
  PR,
  PT,
  PY,
  QA,
  RE,
  RO,
  RS,
  RW,
  SA,
  SE,
  SG,
  SI,
  SK,
  SN,
  SV,
  SX,
  TC,
  TH,
  TL,
  TN,
  TR,
  TT,
  TW,
  TZ,
  US,
  UY,
  VC,
  VE,
  VG,
  VI,
  VN,
  YT,
  ZA,
  ZM
};

export const SUPPORTED_LANGUAGES = {
  [AR]: [SA, AE, BH, EG, JO, KW, LB, LY, MA, OM, QA, TN],
  [BG]: [BG],
  [CA]: [ES],
  [CS]: [CZ],
  [DA]: [DK],
  [DE]: [DE, AT, CH, LI, LU],
  [EL]: [GR, CY],
  [EN]: [
    US,
    CM,
    AE,
    AF,
    AG,
    AI,
    AL,
    AU,
    AW,
    AZ,
    BA,
    BB,
    BD,
    BH,
    BN,
    BS,
    BT,
    BW,
    BZ,
    CA,
    CR,
    CY,
    DZ,
    EG,
    ET,
    GB,
    GD,
    GE,
    GH,
    HK,
    ID,
    IE,
    IL,
    IN,
    IR,
    IS,
    JM,
    JO,
    JP,
    KE,
    KH,
    KN,
    KY,
    KW,
    LA,
    LB,
    LC,
    LK,
    LY,
    ME,
    MK,
    MM,
    MN,
    MS,
    MT,
    MU,
    MY,
    MV,
    NA,
    NG,
    NP,
    NZ,
    OM,
    PH,
    PK,
    PR,
    QA,
    RS,
    RW,
    SA,
    SG,
    SX,
    TC,
    TH,
    TL,
    TN,
    TT,
    TZ,
    UG,
    VC,
    VG,
    VI,
    VN,
    ZA,
    ZM
  ],
  [ES]: [ES, AR, BO, CL, CO, CU, DO, EC, GT, HN, HW, MX, NI, PE, PR, PA, PY, SV, UY, VE],
  [ET]: [EE],
  [FI]: [FI],
  [FR]: [FR, BE, BJ, BL, CA, CH, GF, GP, HT, LC, LU, MF, MG, MQ, NC, PF, RE, SN, YT],
  [HE]: [IL],
  [HU]: [HU, UA, UZ],
  [HR]: [HR],
  [ID]: [ID],
  [IT]: [IT, CH],
  [JA]: [JP],
  [KO]: [KR],
  [LT]: [LT],
  [LV]: [LV],
  [MT]: [MT],
  [NL]: [BE, BQ, CW, FR, NL],
  [NB]: [NO],
  [PL]: [PL],
  [PT]: [BR, PT, AO, MZ],
  [RO]: [RO, MD],
  [RU]: [RU, BY, KZ, UA, UZ],
  [SK]: [SK],
  [SL]: [SI],
  [SV]: [SE, FI],
  [TH]: [TH],
  [TR]: [TR],
  [ZH]: [CN, HK, SG, TW]
};

export const RTL_LOCALES = {
  [AR]: [SA],
  [HE]: [IL]
};

export const LTR_LOCALES = {
  [BG]: [BG],
  [CA]: [ES],
  [CS]: [CZ],
  [DA]: [DK],
  [DE]: [DE, AT, CH, LI, LU],
  [EL]: [GR, CY],
  [EN]: [
    US,
    CM,
    AE,
    AF,
    AG,
    AI,
    AL,
    AU,
    AW,
    AZ,
    BA,
    BB,
    BD,
    BH,
    BN,
    BS,
    BT,
    BW,
    BZ,
    CA,
    CR,
    CY,
    DZ,
    EG,
    ET,
    GB,
    GD,
    GE,
    GH,
    HK,
    ID,
    IE,
    IL,
    IN,
    IR,
    IS,
    JM,
    JO,
    JP,
    KE,
    KH,
    KN,
    KY,
    KW,
    LA,
    LB,
    LC,
    LK,
    LY,
    ME,
    MK,
    MM,
    MN,
    MS,
    MT,
    MU,
    MY,
    MV,
    NA,
    NG,
    NP,
    NZ,
    OM,
    PH,
    PK,
    PR,
    QA,
    RS,
    RW,
    SG,
    SX,
    TC,
    TH,
    TL,
    TN,
    TT,
    TZ,
    UG,
    VC,
    VG,
    VI,
    VN,
    ZA,
    ZM
  ],
  [ES]: [ES, AR, BO, CL, CO, CU, DO, EC, GT, HN, HW, MX, NI, PE, PR, PA, PY, SV, UY, VE],
  [ET]: [EE],
  [FI]: [FI],
  [FR]: [FR, BE, BJ, BL, CA, CH, GF, GP, HT, LC, LU, MF, MG, MQ, NC, PF, RE, SN, YT],
  [HU]: [HU, UA, UZ],
  [HR]: [HR],
  [ID]: [ID],
  [IT]: [IT, CH],
  [JA]: [JP],
  [KO]: [KR],
  [LT]: [LT],
  [LV]: [LV],
  // [MT]: [MT],
  [NL]: [BE, BQ, CW, FR, NL],
  [NB]: [NO],
  [PL]: [PL],
  [PT]: [BR, PT, AO, MZ],
  [RO]: [RO, MD],
  [RU]: [RU, BY, KZ, UA, UZ],
  [SK]: [SK],
  [SL]: [SI],
  [SV]: [SE, FI],
  [TH]: [TH],
  [TR]: [TR],
  [ZH]: [CN, HK, SG, TW]
};

export const TONER_RECALL_LOCALES = {
  [EN]: [US, GB],
  [KO]: [KR]
};

export const ORIGAMI_LOCALES = {
  [EN]: [US, GB],
  [FR]: [FR],
  [DE]: [DE]
};

export const WIRELESS_PRINTING_LOCALES = {
  [DE]: [DE],
  [EN]: [US, GB],
  [ES]: [ES],
  [FR]: [FR],
  [IT]: [IT],
  [PT]: [BR, PT],
  [RU]: [RU],
  [ZH]: [CN]
};

export const COUNTRIES_AND_LANGUAGES_SET = new Set([...COUNTRIES_ARRAY, ...LANGUAGES_ARRAY]);

export const COUNTRY_DOMINANT_LANGUAGE = {
  [AE]: [AR, EN],
  [AF]: [EN],
  [AG]: [EN],
  [AI]: [EN],
  [AL]: [EN],
  [AO]: [PT],
  [AR]: [ES],
  [AT]: [DE],
  [AU]: [EN],
  [AW]: [EN],
  [AZ]: [EN],
  [BA]: [EN],
  [BB]: [EN],
  [BD]: [EN],
  [BE]: [FR, NL],
  [BG]: [BG],
  [BH]: [AR, EN],
  [BJ]: [FR],
  [BL]: [FR],
  [BN]: [EN],
  [BQ]: [NL],
  [BR]: [ES],
  [BR]: [PT],
  [BS]: [EN],
  [BT]: [EN],
  [BW]: [EN],
  [BY]: [RU],
  [BZ]: [EN],
  [CA]: [EN, FR],
  [CH]: [FR, DE, IT],
  [CI]: [FR],
  [CL]: [ES],
  [CM]: [EN],
  [CN]: [ZH],
  [CO]: [ES],
  [CR]: [EN],
  [CU]: [ES],
  [CW]: [NL],
  [CY]: [EL, EN],
  [CZ]: [CS],
  [DE]: [DE],
  [DK]: [DA],
  [DO]: [ES],
  [DZ]: [AR, EN],
  [EC]: [ES],
  [EE]: [ET],
  [EG]: [AR, EN],
  [ES]: [ES, CA],
  [ET]: [EN],
  [FI]: [FI],
  [FR]: [FR],
  [GB]: [EN],
  [GD]: [EN],
  [GE]: [EN],
  [GF]: [FR],
  [GH]: [EN],
  [GP]: [FR],
  [GR]: [EL],
  [GT]: [ES],
  [HK]: [ZH, EN],
  [HN]: [ES],
  [HR]: [HR],
  [HT]: [FR],
  [HU]: [HU],
  [HW]: [ES],
  [UA]: [RU],
  [UZ]: [RU],
  [ID]: [ID, EN],
  [IE]: [EN],
  [IL]: [HE],
  [IN]: [EN],
  [IR]: [EN],
  [IS]: [EN],
  [IT]: [IT],
  [JM]: [EN],
  [JO]: [AR, EN],
  [JP]: [JA, EN],
  [KE]: [EN],
  [KH]: [EN],
  [KN]: [EN],
  [KR]: [KO],
  [KW]: [AR, EN],
  [KY]: [EN],
  [KZ]: [RU],
  [LA]: [EN],
  [LB]: [AR, EN],
  [LC]: [EN, FR],
  [LI]: [DE],
  [LK]: [EN],
  [LT]: [IT],
  [LU]: [FR, DE],
  [LV]: [AR, EN],
  [LV]: [LV],
  [LY]: [AR, EN],
  [MA]: [AR, EN],
  [MD]: [RO],
  [ME]: [EN],
  [MF]: [FR],
  [MG]: [FR],
  [MK]: [EN],
  [MM]: [EN],
  [MN]: [EN],
  [MQ]: [FR],
  [MS]: [EN],
  // [MT]: [EN, MT],
  [MU]: [EN],
  [MV]: [EN],
  [MX]: [ES],
  [MY]: [EN],
  [MZ]: [PT],
  [NA]: [EN],
  [NC]: [FR],
  [NG]: [EN],
  [NI]: [ES],
  [NL]: [NL],
  [NO]: [NO, NB],
  [NP]: [EN],
  [NZ]: [EN],
  [OM]: [AR, EN],
  [PA]: [ES],
  [PE]: [ES],
  [PF]: [FR],
  [PH]: [EN],
  [PK]: [EN],
  [PL]: [PL],
  [PR]: [EN, ES],
  [PT]: [PT],
  [PY]: [ES],
  [QA]: [AR, EN],
  [RE]: [FR],
  [RO]: [RO],
  [RS]: [EN],
  [RU]: [RU],
  [RW]: [EN],
  [SE]: [SV],
  [SG]: [EN],
  [SI]: [SL],
  [SK]: [SK],
  [SN]: [FR],
  [SV]: [ES],
  [SX]: [EN],
  [TC]: [EN],
  [TH]: [EN],
  [TL]: [EN],
  [TN]: [AR, EN],
  [TR]: [TR],
  [TT]: [EN],
  [TW]: [ZH, EN],
  [TZ]: [EN],
  [UG]: [EN],
  [UZ]: [RU, HU],
  [US]: [EN],
  [UY]: [ES],
  [VC]: [EN],
  [VE]: [ES],
  [VG]: [EN],
  [VI]: [EN],
  [VN]: [EN],
  [YT]: [FR],
  [ZA]: [EN],
  [ZM]: [EN],
  [SA]: [AR]
};

// Below line of code needs to revisted when we support right to left languages and any locale expansion
export const DEFAULT_COUNTRY_LOCALE_LANGUAGE = {
  [`${EL}_${CY}`]: [`${EL}_${GR}`],
  [`${EN}_${AE}`]: [`${EN}_${US}`],
  [`${EN}_${AF}`]: [`${EN}_${US}`],
  [`${EN}_${AI}`]: [`${EN}_${US}`],
  [`${EN}_${AL}`]: [`${EN}_${US}`],
  [`${EN}_${AU}`]: [`${EN}_${US}`],
  [`${EN}_${AW}`]: [`${EN}_${US}`],
  [`${EN}_${BA}`]: [`${EN}_${US}`],
  [`${EN}_${BB}`]: [`${EN}_${US}`],
  [`${EN}_${BD}`]: [`${EN}_${US}`],
  [`${EN}_${BH}`]: [`${EN}_${US}`],
  [`${EN}_${BN}`]: [`${EN}_${US}`],
  [`${EN}_${BS}`]: [`${EN}_${US}`],
  [`${EN}_${BT}`]: [`${EN}_${US}`],
  [`${EN}_${BW}`]: [`${EN}_${US}`],
  [`${EN}_${BZ}`]: [`${EN}_${US}`],
  [`${EN}_${CA}`]: [`${EN}_${US}`],
  [`${EN}_${CM}`]: [`${EN}_${US}`],
  [`${EN}_${CR}`]: [`${EN}_${US}`],
  [`${EN}_${CY}`]: [`${EN}_${US}`],
  [`${EN}_${DZ}`]: [`${EN}_${US}`],
  [`${EN}_${EG}`]: [`${EN}_${US}`],
  [`${EN}_${ET}`]: [`${EN}_${US}`],
  [`${EN}_${GD}`]: [`${EN}_${US}`],
  [`${EN}_${GE}`]: [`${EN}_${US}`],
  [`${EN}_${GH}`]: [`${EN}_${US}`],
  [`${EN}_${HK}`]: [`${EN}_${US}`],
  [`${EN}_${ID}`]: [`${EN}_${US}`],
  [`${EN}_${IE}`]: [`${EN}_${US}`],
  [`${EN}_${IL}`]: [`${EN}_${US}`],
  [`${EN}_${IR}`]: [`${EN}_${US}`],
  [`${EN}_${IS}`]: [`${EN}_${US}`],
  [`${EN}_${IN}`]: [`${EN}_${US}`],
  [`${EN}_${JM}`]: [`${EN}_${US}`],
  [`${EN}_${JO}`]: [`${EN}_${US}`],
  [`${EN}_${JP}`]: [`${EN}_${US}`],
  [`${EN}_${KE}`]: [`${EN}_${US}`],
  [`${EN}_${KH}`]: [`${EN}_${US}`],
  [`${EN}_${KN}`]: [`${EN}_${US}`],
  [`${EN}_${KW}`]: [`${EN}_${US}`],
  [`${EN}_${KY}`]: [`${EN}_${US}`],
  [`${EN}_${LA}`]: [`${EN}_${US}`],
  [`${EN}_${LB}`]: [`${EN}_${US}`],
  [`${EN}_${LC}`]: [`${EN}_${US}`],
  [`${EN}_${LK}`]: [`${EN}_${US}`],
  [`${EN}_${LY}`]: [`${EN}_${US}`],
  [`${EN}_${MA}`]: [`${EN}_${US}`],
  [`${EN}_${ME}`]: [`${EN}_${US}`],
  [`${EN}_${MK}`]: [`${EN}_${US}`],
  [`${EN}_${MM}`]: [`${EN}_${US}`],
  [`${EN}_${MN}`]: [`${EN}_${US}`],
  [`${EN}_${MS}`]: [`${EN}_${US}`],
  [`${EN}_${MT}`]: [`${EN}_${US}`],
  [`${EN}_${MU}`]: [`${EN}_${US}`],
  [`${EN}_${MY}`]: [`${EN}_${US}`],
  [`${EN}_${NA}`]: [`${EN}_${US}`],
  [`${EN}_${NG}`]: [`${EN}_${US}`],
  [`${EN}_${NP}`]: [`${EN}_${US}`],
  [`${EN}_${NZ}`]: [`${EN}_${US}`],
  [`${EN}_${OM}`]: [`${EN}_${US}`],
  [`${EN}_${PH}`]: [`${EN}_${US}`],
  [`${EN}_${PK}`]: [`${EN}_${US}`],
  [`${EN}_${PR}`]: [`${EN}_${US}`],
  [`${EN}_${QA}`]: [`${EN}_${US}`],
  [`${EN}_${RS}`]: [`${EN}_${US}`],
  [`${EN}_${RW}`]: [`${EN}_${US}`],
  [`${EN}_${SA}`]: [`${EN}_${US}`],
  [`${EN}_${SG}`]: [`${EN}_${US}`],
  [`${EN}_${SX}`]: [`${EN}_${US}`],
  [`${EN}_${TC}`]: [`${EN}_${US}`],
  [`${EN}_${TH}`]: [`${EN}_${US}`],
  [`${EN}_${TL}`]: [`${EN}_${US}`],
  [`${EN}_${TN}`]: [`${EN}_${US}`],
  [`${EN}_${TT}`]: [`${EN}_${US}`],
  [`${EN}_${TZ}`]: [`${EN}_${US}`],
  [`${EN}_${UG}`]: [`${EN}_${US}`],
  [`${EN}_${VC}`]: [`${EN}_${US}`],
  [`${EN}_${VG}`]: [`${EN}_${US}`],
  [`${EN}_${VI}`]: [`${EN}_${US}`],
  [`${EN}_${VN}`]: [`${EN}_${US}`],
  [`${EN}_${ZA}`]: [`${EN}_${US}`],
  [`${EN}_${ZM}`]: [`${EN}_${US}`],
  [`${ES}_${AR}`]: [`${ES}_${ES}`],
  [`${ES}_${BO}`]: [`${ES}_${ES}`],
  [`${ES}_${CL}`]: [`${ES}_${ES}`],
  [`${ES}_${CO}`]: [`${ES}_${ES}`],
  [`${ES}_${CU}`]: [`${ES}_${ES}`],
  [`${ES}_${DO}`]: [`${ES}_${ES}`],
  [`${ES}_${EC}`]: [`${ES}_${ES}`],
  [`${ES}_${GT}`]: [`${ES}_${ES}`],
  [`${ES}_${HW}`]: [`${ES}_${ES}`],
  [`${ES}_${MX}`]: [`${ES}_${ES}`],
  [`${ES}_${NI}`]: [`${ES}_${ES}`],
  [`${ES}_${PE}`]: [`${ES}_${ES}`],
  [`${ES}_${PR}`]: [`${ES}_${ES}`],
  [`${ES}_${PA}`]: [`${ES}_${ES}`],
  [`${ES}_${PY}`]: [`${ES}_${ES}`],
  [`${ES}_${SV}`]: [`${ES}_${ES}`],
  [`${ES}_${UY}`]: [`${ES}_${ES}`],
  [`${ES}_${VE}`]: [`${ES}_${ES}`],
  [`${DE}_${LU}`]: [`${DE}_${DE}`],
  [`${DE}_${LI}`]: [`${DE}_${DE}`],
  [`${DE}_${AT}`]: [`${DE}_${DE}`],
  [`${DE}_${CH}`]: [`${DE}_${DE}`],
  [`${SV}_${FI}`]: [`${FI}_${FI}`],
  [`${FR}_${BE}`]: [`${FR}_${FR}`],
  [`${FR}_${BJ}`]: [`${FR}_${FR}`],
  [`${FR}_${BL}`]: [`${FR}_${FR}`],
  [`${FR}_${CA}`]: [`${FR}_${FR}`],
  [`${FR}_${CH}`]: [`${FR}_${FR}`],
  [`${FR}_${GF}`]: [`${FR}_${FR}`],
  [`${FR}_${GP}`]: [`${FR}_${FR}`],
  [`${FR}_${HT}`]: [`${FR}_${FR}`],
  [`${FR}_${LC}`]: [`${FR}_${FR}`],
  [`${FR}_${LU}`]: [`${FR}_${FR}`],
  [`${FR}_${MG}`]: [`${FR}_${FR}`],
  [`${FR}_${MQ}`]: [`${FR}_${FR}`],
  [`${FR}_${MF}`]: [`${FR}_${FR}`],
  [`${FR}_${NC}`]: [`${FR}_${FR}`],
  [`${FR}_${PF}`]: [`${FR}_${FR}`],
  [`${FR}_${RE}`]: [`${FR}_${FR}`],
  [`${FR}_${SN}`]: [`${FR}_${FR}`],
  [`${FR}_${YT}`]: [`${FR}_${FR}`],
  [`${HU}_${UA}`]: [`${HU}_${HU}`],
  [`${HU}_${UZ}`]: [`${HU}_${HU}`],
  [`${NL}_${BQ}`]: [`${NL}_${NL}`],
  [`${NL}_${CW}`]: [`${NL}_${NL}`],
  [`${NL}_${FR}`]: [`${NL}_${NL}`],
  [`${NL}_${BE}`]: [`${NL}_${NL}`],
  [`${PT}_${AO}`]: [`${PT}_${BR}`],
  [`${PT}_${PT}`]: [`${PT}_${BR}`],
  [`${PT}_${MZ}`]: [`${PT}_${BR}`],
  [`${RU}_${BY}`]: [`${RU}_${RU}`],
  [`${RU}_${KZ}`]: [`${RU}_${RU}`],
  [`${RU}_${UA}`]: [`${RU}_${RU}`],
  [`${RU}_${UZ}`]: [`${RU}_${RU}`],
  [`${FI}_${SE}`]: [`${SE}_${SE}`],
  [`${IT}_${CH}`]: [`${IT}_${IT}`]
};

export const RTL = 'rtl';
export const LTR = 'ltr';
export const SIMPLIFIED_CHINESE_COUNTRIES = new Set([CN, SG]);
export const VA_LINK_SUPPORTED_LANGS = new Set(['en', 'es', 'de', 'zh', 'nl', 'fr', 'ja', 'pt', 'it', 'tr', 'pl', 'sv', 'ko']);

export const reverseCountryVariants = {
  uk: 'gb'
};

export const CAREPACK_SUPPORTED_COUNTRIES = [US, GB];

export const RTL_LANGUAGES = [AR, HE];