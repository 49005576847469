import axios from "axios";
import CraftApiModel from "../models/CraftApiModel";
import { getApiPathWithLocale } from "../lib/urlUtils";
import ActionTypes from "../constants/ActionTypes";

/**
 * This function uses axios to get smart help data.
 *
 * Since axios returns promises, we can dispatch our start event and
 * update it to complete or complete with error whe the promise returns.
 *
 * @returns {function(*)}
 */
export function getTerms(currentLocale = "") {
  let start = () => ({
      type: ActionTypes.START_TERMS_FETCH
    }),
    complete = (termsData) => ({
      type: ActionTypes.COMPLETE_TERMS_FETCH,
      termsData
    }),
    error = err => ({
      type: ActionTypes.COMPLETE_TERMS_FETCH_WITH_ERROR,
      err
    });
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(start());
      axios
        .get(
          getApiPathWithLocale("/api/tou.json", currentLocale)
        )
        .then(res => {
          let termsData = (res.data && res.data.data) ? new CraftApiModel(res.data.data.length > 0 ? res.data.data : []) : new CraftApiModel({});
          dispatch(complete(termsData));
          return resolve(termsData);
        })
        .catch(err => {
          dispatch(error(err));
          reject(err);
        });
    });
  };
}