import { prop, path } from 'ramda';

export const stylesIf =
  (propName, trueValue = '', falseValue = '') =>
  (props) =>
    prop(propName, props) ? trueValue : falseValue;

export const breakpoints = {
  xSmall: '18.75rem', // 300px
  small: '25.8125rem', // 413px
  mobile: '25.875rem', // 414px
  bigmobile: '28.125rem', // 450px
  smalldesktop: '31.25rem', // 500px
  desktop: '33.75rem', // 540px
  tablet: '40rem', // 640px
  bigtablet: '45rem', // 720px
  medium: '55rem', //880px
  wide: '80rem', // 1280px
  large: '100rem', // 1600px
  immense: '120rem', // 1920px
  immensewide: '160rem' // 4K
};

export const smb_breakpoints = {
  mobile: '38rem', //608px
  desktop: '55rem', //880px
  large: '73.625rem', //1178px
  wide: '120rem' //1920px
};

export const venBreaks = {
  tablet: '36rem', //576px
  desktop: '62rem' //992px
};

export const theme = {
  fonts: {
    familyLight: '"hp-simplified-light", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
    family: '"hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
    gotham_ultra: 'Gotham Ultra',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.25rem',
    xlg: '1.5rem',
    xxlg: '2rem',
    xxxlg: '3rem',
    godzilla: '3.75rem'
  },
  colors: {
    hpBlue: '#0096d5',
    black: '#000000',
    dark: '#212121',
    nearDark: '#313131',
    darkGrey: '#404040',
    white: '#FFFFFF',
    lightBackgroundGrey: '#F4F4F4',
    extralightBackgroundGrey: '#F6F6F6',
    inactiveGrey: '#BCBCBC'
  },
  breakpoints,
  venBreaks
};

export const paths = {
  colorsDarkGrey: path(['theme', 'colors', 'darkGrey']),
  colorsInactiveGrey: path(['theme', 'colors', 'inactiveGrey']),
  colorsWhite: path(['theme', 'colors', 'white']),
  colorsNearDark: path(['theme', 'colors', 'nearDark']),
  colorsDark: path(['theme', 'colors', 'dark']),
  colorsBlack: path(['theme', 'colors', 'black']),
  colorsHpBlue: path(['theme', 'colors', 'hpBlue']),
  colorsLightBackgroundGrey: path(['theme', 'colors', 'lightBackgroundGrey']),
  colorsExtraLightBackgroundGrey: path(['theme', 'colors', 'extralightBackgroundGrey']),
  fontsSm: path(['theme', 'fonts', 'sm']),
  fontsMd: path(['theme', 'fonts', 'md']),
  fontsLg: path(['theme', 'fonts', 'lg']),
  fontsXLg: path(['theme', 'fonts', 'xlg']),
  fontsXXLg: path(['theme', 'fonts', 'xxlg']),
  fontsXXXLg: path(['theme', 'fonts', 'xxxlg']),
  fontsGodzilla: path(['theme', 'fonts', 'godzilla']),
  fontsFamily: path(['theme', 'fonts', 'family']),
  fontsFamilyLight: path(['theme', 'fonts', 'familyLight']),
  breakSmall: path(['theme', 'breakpoints', 'small']),
  breakMobile: path(['theme', 'breakpoints', 'mobile']),
  breakTablet: path(['theme', 'breakpoints', 'tablet']),
  breakWide: path(['theme', 'breakpoints', 'wide']),
  breakImmense: path(['theme', 'breakpoints', 'immense']),
  breakImmensewide: path(['theme', 'breakpoints', 'immensewide']),
  venTablet: path(['theme', 'venBreaks', 'tablet']),
  venDesk: path(['theme', 'venBreaks', 'desktop'])
};

const titleRegularLight = `
  font-family: ${theme.fonts.familyLight};
  font-size: ${theme.fonts.xlg};
  line-height: 1.3;
  @media screen and (min-width: ${venBreaks.tablet}) {
    font-size: ${theme.fonts.xxlg};
    line-height: 1.25;
  }
  @media screen and (min-width: ${venBreaks.desktop}) and (max-resolution: 1dppx) {
    font-size: 2.5rem;
    line-height: 1.2;
  }
`;

const titleMediumRegular = `
  font-family: ${theme.fonts.family};
  font-size: 1.75rem;
  line-height: 1.3;
  @media screen and (min-width: ${venBreaks.tablet}) {
    font-size: 2.5rem;
    line-height: 1.2;
  }
  @media screen and (min-width: ${venBreaks.desktop}) {
    font-size: 3.5rem;
    line-height: 1.15;
  }
`;

const subtitleLarge = `
  font-family: ${theme.fonts.family};
  font-size: 1.125rem;
  line-height: 1.3;
  @media screen and (min-width: ${venBreaks.tablet}) {
    font-size: 1.25rem;
    line-height: 1.4;
  }
  @media screen and (min-width: ${venBreaks.desktop}) and (max-resolution: 1dppx) {
    font-size: ${theme.fonts.xlg};
    line-height: 1.3;
  }
`;

const bodyLarge = `
  font-family: ${theme.fonts.familyLight};
  font-size: 1.125rem;
  line-height: 1.3;
`;

const caption = `
  font-family: ${theme.fonts.familyLight};
  font-size: 0.875rem;
  line-height: 1.4;
`;

const overlineLarge = `
  font-family: ${theme.fonts.family};
  text-transform: uppercase;
  font-size: ${theme.fonts.md};
  line-spacing: 1.25;
  letter-spacing: 3.3px;
  @media screen and (min-width: ${venBreaks.tablet}) {
    font-size: 1.125rem;
    line-spacing: 1.3;
  }
  @media screen and (min-width: ${venBreaks.desktop}) {
    font-size: ${theme.fonts.lg};
    line-spacing: 1.4;
  }
`;

export const fontPacks = {
  titleRegularLight,
  titleMediumRegular,
  subtitleLarge,
  bodyLarge,
  caption,
  overlineLarge
};

export default {
  stylesIf
};
