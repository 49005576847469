import React from 'react';
import { useSelector } from 'react-redux';
import { JarvisPrivacySettingsComponent, Platform } from '@jarvis/react-privacy-settings';
import { getStratusAccessToken } from '../../../../utils/auth';
import { refreshToken } from '../../../../api/HPCSession';
import { selectPrivacyProps } from './selector';
import {
  Container,
  Title,
  Description,
  Grid,
  GridSeparator,
  MainContent,
} from '../../../../components/shared-components/styles';
import i18n from '../../../../utils/i18n';

const PrivacySettings = () => {
  const { baseUrl, country, language } = useSelector(selectPrivacyProps);
  const authProvider = {
    getAccessToken: async forceRefresh => {
      if (forceRefresh) {
        await refreshToken();
      }
      return getStratusAccessToken();
    },
  };

  return (
    <Container>
      <GridSeparator>
        <Title>{i18n.t('myAccount.privacySettings.mainHeader')}</Title>
        <Description>{i18n.t('myAccount.privacySettings.description')}</Description>
      </GridSeparator>
      <Grid>
        <MainContent>
          <JarvisPrivacySettingsComponent
            baseUrl={baseUrl}
            language={language}
            country={country.toUpperCase()}
            platform={Platform.Web}
            authProvider={authProvider}
          />
        </MainContent>
      </Grid>
    </Container>
  );
};

export default PrivacySettings;
