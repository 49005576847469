import styled from 'styled-components';

export const HeroContainer = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  margin-top: 4.25rem;
  > video {
    @media (min-width: 160rem) {
      width: 100%;
      height: 100%;
      margin 0;
    }
    @media (min-width: 120rem) and (max-width: 159rem) {
      width: 100rem;
      height: 56.25rem;
      margin: 0rem 10rem;
    }
  }
`;

export const NovelliDesc = styled.div`
  display: block;
  max-width: 30.4375rem;
  margin: 3rem auto;
  color: #313131;
  width: 100%;
  @media (min-width: 160rem) {
    max-width: 37.75rem;
  }
  @media (min-width: 120rem) and (max-width: 159rem) {
    margin: 2rem auto;
    max-width: 34rem;
  }
  @media (min-width: 23rem) and (max-width: 29rem) {
    margin: 0rem auto;
  }
  h2 {
    font-size: 2.25rem;
    font-weight: bold;
    text-align: center;
    color: #313131;
    @media (min-width: 160rem) {
      font-size: 3rem;
    }
    @media (min-width: 120rem) and (max-width: 159rem) {
      font-size: 2.25rem;
    }
    @media (min-width: 80rem) and (max-width: 119rem) {
      font-size: 2.25rem;
    }
    @media (min-width: 40rem) and (max-width: 79rem) {
      font-size: 1.625rem;
      text-align: center;
      color: #313131;
    }
    @media (min-width: 30rem) and (max-width: 39rem) {
      font-size: 1.625rem;
    }
    @media (min-width: 23rem) and (max-width: 29rem) {
      font-size: 1.625rem;
    }
    @media (max-width: 22rem) {
      font-size: 1.625rem;
    }
  }
  ul {
    margin: 2rem 8rem;
    @media (min-width: 120rem) and (max-width: 159rem) {
      margin: 2rem 10rem;
    }
    @media (min-width: 23rem) and (max-width: 29rem) {
      margin: 2rem 5rem;
    }
    @media (max-width: 23rem) {
      margin: 2rem 4rem;
    }
  }
  li {
    font-size: 1.125rem;
    color: #313131;
    @media (min-width: 160rem) {
      font-size: 1.5rem;
    }
  }
`;

export const NovelliSubDes = styled.div`
  display: flex;
  color: #313131;
  margin: 1rem 3rem;
  @media (min-width: 120rem) and (max-width: 159rem) {
    margin: 1rem 5rem;
  }
  @media (min-width: 23rem) and (max-width: 29rem) {
    margin: 1rem 0rem;
  }
  @media (max-width: 23rem) {
    margin: 1rem 0rem;
  }
  img {
    max-width: 50%;
    padding: 0rem;
    margin: 0rem;
    @media (min-width: 120rem) and (max-width: 159rem) {
      margin: 0;
    }
  }
  p {
    margin: 1rem;
    color: #313131;
    @media (min-width: 160rem) {
      font-size: 1.25rem;
    }
    @media (max-width: 23rem) {
      margin: 0.6rem;
    }
  }
`;

export const ButtonCenter = styled.div`
  text-align: center;
`;