import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import { identity, is, prop } from 'ramda';
import { usePathCorrection } from '../../hooks/routeCorrections';
import { useSelector } from '../../hooks';
import { selectPathname } from '../../selectors/routing';

const toContainsCurrentLocation = (currentPath, target) => {
  if (is(String, target)) {
    return currentPath === target;
  }
  const toProp = prop('to', target);
  if (toProp) {
    return currentPath === toProp;
  }

  return false;
};

const Link = props => {
  const {
    to,
    target,
    children,
    onClick = identity,
    smart,
    className,
    ...restProps
  } = props;
  const correctedTo = usePathCorrection(to);
  const currentPath = useSelector(selectPathname);
  const dispatch = useDispatch();

  const toIsAbsoluteURL = is(String, to) && (to.startsWith('https://') || to.startsWith('http://'));

  const handleClick = e => {
    if (!toIsAbsoluteURL) {
      e.nativeEvent && e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
      dispatch(push(correctedTo));
    }
    onClick();
  };

  return (
    <a
      className={classNames(
        className,
        {
          active: smart && toContainsCurrentLocation(currentPath, to),
        },
      )}
      {...restProps}
      href={is(String, to) ? to : prop('pathname', to)}
      onClick={handleClick}
      target={target ? target : '_self'}
    >
      {children}
    </a>
  );
};

export default Link;
