import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .pageContent {
    .pageContent-loading{
        text-align: center;
        margin: 10em 0;
        display: none;
    }
    .pageContent{
        display: block;
        height: 100%;
    }
    &.loading{
        .pageContent-loading{
            display: block;
        }
        .pageContent{
            display: none;
        }
    }
  }
  .contentLoading {
      text-align: center;
      margin: 10em 0;
  }
`
const PageLoader = styled.div`
  @keyframes pageLoader {
    0% { 
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% { 
      opacity: 1;
    }
    80% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  margin: auto;
  text-align: center;
  svg {
    width: auto;
    line-height: 1;
    margin-bottom: rem-calc(10);
    circle {
      animation: pageLoader 3.2s ease infinite;
      fill: #0096D6;
      opacity: 0;
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
      &:nth-child(4) {
        animation-delay: 0.6s;
      }
      &:nth-child(5) {
        animation-delay: 0.8s;
      }
      &:nth-child(6) {
        animation-delay: 1s;
      }
      &:nth-child(7) {
        animation-delay: 1.2s;
      }
      &:nth-child(8) {
        animation-delay: 1.4s;
      }
      &:nth-child(9) {
        animation-delay: 1.6s;
      }
      &:nth-child(10) {
        animation-delay: 1.8s;
      }
      &:nth-child(11) {
        animation-delay: 2s;
      }
      &:nth-child(12) {
        animation-delay: 2.2s;
      }
      &:nth-child(13) {
        animation-delay: 2.4s;
      }
      &:nth-child(14) {
        animation-delay: 2.6s;
      }
      &:nth-child(15) {
        animation-delay: 2.8s;
      }
      &:nth-child(16) {
        animation-delay: 3s;
      }
    }
  }
`;
const PageSpinner = styled.img`
  width: 3.125rem;
  height: 3.125rem;
  position:absolute;
  top: 50%;
  left: 50%;
  margin: -2.125rem 0 0 -2.125rem;  
  @keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }
  animation: spin .5s linear infinite;
`;
export default function LoaderBlock() {
  return (
    <PageLoader>
      <GlobalStyle />
      <PageSpinner
        id="load_page_spinner" src="/assets/shared/spinner.svg"
        alt=""
      />
    </PageLoader>
  );
}
