import React from 'react';
import {
  prop,
  head,
  isEmpty,
} from 'ramda';
import { useSelector } from '../../hooks';
import { selectCraftDataByResource, selectContentStackDataByResource } from '../../selectors/craft';
import PlainContact from './PlainContact';
import {
  StyledContainer,
  StyledContent,
  StyledTitle,
  StyledDate,
  StyledIndex,
  StyledSections,
  SectionTitle,
} from './plain.styles';
import {
  CONTACT_ENTRIES_RESOURCE_NAME,
  CONTACT_RESOURCE_NAME,
  TOU_RESOURCE_NAME
} from '../../constants/contentStackResources';

const slugify = (str = '') => str.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');
const PlainTOU = () => {
  const { terms_of_use } = useSelector(selectContentStackDataByResource, [TOU_RESOURCE_NAME]);
  const tou = terms_of_use?.data?.entry;

  const { contact } = useSelector(selectContentStackDataByResource, [CONTACT_RESOURCE_NAME]);
  const contactData = contact?.data?.entries[0];

  const { contact_entries } = useSelector(selectContentStackDataByResource, [CONTACT_ENTRIES_RESOURCE_NAME]);
  const contactEntriesData = contact_entries?.data?.entries;

  if (tou && contactData) {
    const {
      indexed_sections,
      title,
      last_updated,
      intro_section,
      index_title,
      copyright,
    } = tou;

    const {
      title: pageTitle = '',
      contact_labels = [],
    } = contactData;

    const contactEntries = contactEntriesData !== undefined ? contactEntriesData : [];
    const contactLabelData = head(contact_labels.length > 0 ? [contact_labels[0]?.label_block] : []);

    return (
      <StyledContainer>
        <StyledContent>
          <StyledTitle>
            HP Smart
          </StyledTitle>
          <StyledTitle>
            {title}
          </StyledTitle>
          <StyledDate>
            {last_updated}
          </StyledDate>
          <div dangerouslySetInnerHTML={{ __html: intro_section }} />
          <strong>
            {index_title}
          </strong>
          <StyledIndex>
            {
              indexed_sections.map((section, i) => {
                const { section_block } = section;
                const { section_title, _metadata } = section_block;
                const { uid } = _metadata;

                return (
                  <li key={`index${i}${slugify(uid)}`}>
                    {section_title}
                  </li>
                )
              })
            }
          </StyledIndex>
          <StyledSections>
            {
              indexed_sections.map((section, i) => {
                const { section_block } = section;
                const { section_title, section_content, _metadata } = section_block;
                const { uid } = _metadata;

                return (
                  <li
                    key={`content${slugify(uid)}${i}`}
                    id={slugify(section_title)}
                    dangerouslySetInnerHTML={{ __html: section_content }}
                  />
                )
              })
            }
          </StyledSections>
          {
            !isEmpty(contact)
              && !contact.isError
              && (
                <React.Fragment>
                  <SectionTitle>
                    {pageTitle}
                  </SectionTitle>
                  <PlainContact
                    contactEntries={contactEntries}
                    contactLabelData={contactLabelData}
                  />
                </React.Fragment>
              )
          }
          <strong>
            {copyright}
          </strong>
        </StyledContent>
      </StyledContainer>
    );
  }
  if (prop('isError', tou)) {
    return (
      <p>{tou.errorMsg}</p>
    );
  }
  if (prop('isError', contact)) {
    return (
      <p>{contact.errorMsg}</p>
    );
  }
  // Case: We're loading content
  return <div />;
};

export default PlainTOU;
