import React from 'react';

export default function ExclamationMark(){
    return (
    <svg className="exclamationIcon" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <g fill="none" fillRule="evenodd">
            <circle id="disc" fill="#0096D6" cx="13" cy="13" r="11" />
            <path className="exclamationMark--mark" d="M13,5 L13,20" stroke="#FFFFFF" />
            <rect fill="#0096D6" x="11" y="14" width="4" height="3" />
        </g>
    </svg>);
}