import styled from 'styled-components';
import { breakpoints, theme } from '../../../utils/styleHelpers';

export const GetStartedContainer = styled.div`
  && {
  }
`;

export const GetStartedContent = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: ${breakpoints.desktop}) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 52px;
    }
    @media (min-width: ${breakpoints.bigtablet}) {
      margin: 52px 0;
    }
  }
`;

export const ImageTile = styled.div`
  && {
    .feature-image_375 {
      display: block;
      margin-bottom: 24px;
    }
    .feature-image_720 {
      display: none;
    }
    .feature-image_1280 {
      display: none;
    }
    @media (min-width: ${breakpoints.bigtablet}) {
      .feature-image_375 {
        display: none;
      }
      .feature-image_720 {
        display: block;
        width: 228px;
      }
      .feature-image_1280 {
        display: none;
      }
    }
    @media (min-width: ${breakpoints.wide}) {
      .feature-image_375 {
        display: none;
      }
      .feature-image_720 {
        display: none;
      }
      .feature-image_1280 {
        display: block;
      }
    }
  }
`;

export const ContentTile = styled.div`
  && {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 375px;
    @media (min-width: ${breakpoints.desktop}) {
      width: 264px;
      ${ props => props.direction === 'rtl' ? `margin-right: 36px` : `margin-left: 36px`};
      justify-content: flex-start;
    }
    @media (min-width: ${breakpoints.bigtablet}) {
      width: 360px;
    }
    @media (min-width: ${breakpoints.wide}) {
      ${ props => props.direction === 'rtl' ? `margin-right: 60px` : `margin-left: 60px` };
      width: 520px;
    }
    .title {
      font-family: ${theme.fonts.gotham_ultra};
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;
      @media (min-width: ${breakpoints.desktop}) {
        display: block;
        width: 196px;
      }
      @media (min-width: ${breakpoints.bigtablet}) {
        width: 290px;
        margin-bottom: 10px;
        margin-top: 60px;
      }
      @media (min-width: ${breakpoints.wide}) {
        margin-top: 72px;
      }
      .green-text {
        font-weight: 800;
        font-size: 40px;
        line-height: 40px;
        color: #009f86;
      }
      .yellow-text {
        font-weight: 800;
        font-size: 40px;
        line-height: 40px;
        color: #fcbe2a;
      }
    }
    .description {
      width: 284px;
      color: #4e4e4e;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      @media (min-width: ${breakpoints.desktop}) {
        text-align: ${ props => props.direction === 'rtl' ? 'right' : 'left' };
      }
      @media (min-width: ${breakpoints.desktop}) {
        width: 360px;
      }
      @media (min-width: ${breakpoints.desktop}) {
        width: 100%;
      }
    }
  }
`;

export const SocialMedia = styled.div`
  && {
    display: flex;
    justify-content: center;
    width: 252px;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 40px;
    .app-store {
      width: 96px;
    }
    .google-play {
      width: 117px;
    }
    .baidu-badge {
      width: 36px;
    }
    .ms {
      width: 93px;
    }
    .mac-app-store {
      width: 140px;
    }
    .store {
      margin: 2px 4px;
    }
    @media (min-width: ${breakpoints.desktop}) {
      justify-content: flex-start;
      margin-bottom: initial;
    }
  }
`;
