import React from 'react';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import PictureAndText from './PictureAndText';
import { useSelector } from '../../hooks';
import Button from '../wrapped/Button';
import { Link } from '../routing';
import { selectProductCreateAccountSectionProps } from '../../selectors/craft';
import { SectionWrapper } from './ProductSection';
import { Image, ImageWrapper, Body, Footer, Container } from './PictureAndText/PictureAndText.styles';

const ButtonWrap = styled(Link)`
  border: 1px solid white;
  border-radius: 0.75rem;
  display: inline-block;
`;

const Wrapper = styled.div`
  && {
    width: 100%;
    background-color: #027aae;
    color: white;
    ${Container} {
      margin-top: 2rem;
      padding-bottom: 0;
    }
    ${SectionWrapper} {
      padding-top: 0;
    }
    h4, p {
      color: white;
    }
    ${Image} {
      max-width: 7rem;
    }
    ${ImageWrapper} {
      align-items: center;
    }
    ${Footer} {
      margin-bottom: 2rem;
    }
    @media (min-width: 40rem) {
      ${ImageWrapper} {
        flex: 1 1 40%;
        align-items: flex-end;
      }
      ${Body} {
        flex: 1 1 60%;
      }
    }
  }
`;

const CreateAccountSection = () => {
  const props = useSelector(selectProductCreateAccountSectionProps);
  const dispatch = useDispatch();
  if (!props) {
    return null;
  }
  const {
    buttonText,
    buttonDestination,
    right,
    ...restProps
  } = props;

  return (
    <Wrapper>
      <SectionWrapper>

        <PictureAndText
          {...restProps}
          right={{
            main: true,
            text: (
              <ul>
                {
                  right.map(txt => (<li key={txt}>{txt}</li>))
                }
              </ul>
            ),
          }}
          footer={(
            <ButtonWrap to={buttonDestination}>
              <Button
                onClick={() => {
                  if (buttonDestination) {
                    if (buttonDestination.includes('http')) {
                      window.location.href = buttonDestination;
                    } else {
                      dispatch(push(buttonDestination));
                    }
                  }
                }}
              >
                {buttonText}
              </Button>
            </ButtonWrap>
          )}
        />
      </SectionWrapper>
    </Wrapper>
  );
};

export default CreateAccountSection;
