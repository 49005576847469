import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { path } from 'ramda';
import {
  BrowserView,
} from 'react-device-detect';
import {
  Container,
  ExternalLink,
  Form,
  FormButton,
  FormInput,
  FormSelect,
  PaddedDiv,
  Title,
} from './styles';
import {
  Description,
} from '../../shared-components/styles';
import {
  loadUcdeUserProfile,
  saveUcdeUserProfile,
} from '../../../store/modules/ucdeUser/actions';
import { selectUcdeUser } from '../../../store/modules/ucdeUser/selectors';
import i18n from '../../../utils/i18n';
import { getCountriesInfo } from '../../../api/UCDEGateway';
import { renderErrorModal } from '../../ErrorModal';
import Loader from '../../shared-components/Loader';
import FeatureFlag from '../../FeatureFlag';
import selectCurrentStack from '../../../store/modules/environment/selectors';
import { loginUrlLBuilder } from '../../../utils/auth';
import { selectRootMatch } from '../../../../../selectors/routing';
import { EMAIL_REGEX, PATHS } from '../../../utils/constants';

const maxLengthChar = 256;

const testNamePattern = value => RegExp('^[a-zA-Z0-9\u00C0-\u00FF_]+( [a-zA-Z0-9\u00C0-\u00FF_]+)*$').test(value);
const testEmailPattern = value => EMAIL_REGEX.test(value);

const formValidations = {
  givenName: value => {
    if (!value) {
      return true;
    }
    const hasSpecialCharError = !testNamePattern(value);
    if (hasSpecialCharError) {
      return i18n.t('myAccount.profile.constraints.specialChar');
    }
  },
  familyName: value => {
    if (!value) {
      return true;
    }
    const hasSpecialCharError = !testNamePattern(value);
    if (hasSpecialCharError) {
      return i18n.t('myAccount.profile.constraints.specialChar');
    }
  },
  country: value => !value,
  language: value => !value,
  phoneNumber: value => !value,
  email: value => {
    if (!value) {
      return true;
    }
    const hasEmailError = !testEmailPattern(value);
    if (hasEmailError) {
      return i18n.t('myAccount.profile.constraints.invalidEmail');
    }
  },
};

const defaultFormState = userProfile => ({
  resourceId: {
    value: userProfile.resourceId,
    error: false,
  },
  givenName: {
    value: userProfile.givenName,
    error: formValidations.givenName(userProfile.givenName),
  },
  familyName: {
    value: userProfile.familyName,
    error: formValidations.familyName(userProfile.familyName),
  },
  country: {
    value: userProfile.country,
    error: formValidations.country(userProfile.country),
  },
  language: {
    value: userProfile.language,
    error: formValidations.language(userProfile.language),
  },
  email: {
    value: userProfile.email,
    error: formValidations.email(userProfile.email),
  },
  phoneNumber: {
    value: userProfile.phoneNumber,
    error: formValidations.phoneNumber(userProfile.phoneNumber),
  },
  hasChanges: false,
  isSaving: userProfile.saving || false,
  isLoading: userProfile.isLoading || false,
});

// country and language helpers
const extractRegionInfo = (arrayInfo, localizationPrefix) => arrayInfo.map((value, index) => ({
  value: index,
  label: i18n.t(`myAccount.profile.${localizationPrefix}.${value.countryCode || value}`),
  stringValue: value.countryCode || value,
  languageCodeList: value.languageCodeList || value,
}));

const extractCountriesInfo = countries => extractRegionInfo(countries, 'countries');

const extractLanguagesInfo = languages => extractRegionInfo(languages, 'languages');

const findStringValue = (array, value = '') => (
  array.findIndex(item => item.stringValue.toUpperCase() === value.toUpperCase())
);
// end of country and language helpers

const checkForErrors = formState => (
  Object.values(formState).some(obj => obj.error && obj.error !== false)
);

const isUserOwner = userProfile => userProfile.ownerTenant;

const changePasswordUrl = {
  dev: 'https://myaccount.stg.cd.id.hp.com/uaa/change-password',
  pie: 'https://myaccount.stg.cd.id.hp.com/uaa/change-password',
  stage: 'https://myaccount.stg.cd.id.hp.com/uaa/change-password',
  prod: 'https://myaccount.id.hp.com/uaa/change-password',
};

export const ProfileForm = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector(selectUcdeUser);

  const [formState, setFormState] = useState(defaultFormState(userProfile));
  const [countries, setCountries] = useState([]);
  const [countriesInfoApiError, setCountriesInfoApiError] = useState(false);
  const [languages, setLanguages] = useState([]);

  const stack = useSelector(selectCurrentStack);

  const updateLanguages = () => {
    const value = findStringValue(countries, formState.country.value || userProfile.country);
    const country = countries[value];
    if (country) {
      setLanguages(extractLanguagesInfo(country.languageCodeList));
    }
  };

  const { url } = useSelector(selectRootMatch);

  const applyChanges = async event => {
    event.preventDefault();

    setFormState({ ...formState, isSaving: true });
    dispatch(saveUcdeUserProfile({
      resourceId: formState.resourceId.value,
      givenName: formState.givenName.value,
      familyName: formState.familyName.value,
      country: isUserOwner(userProfile) ? formState.country.value : undefined,
      language: formState.language.value,
      phoneNumber: formState.phoneNumber.value,
      email: formState.email.value,
    }));
  };

  const setField = (fieldName, value) => ({
    ...formState,
    [fieldName]: { value, error: formValidations[fieldName](value) },
  });

  const handleChange = (fieldName, value) => {
    formState.hasChanges = userProfile[fieldName] !== value || formState.hasChanges;
    const updatedFormState = setField(fieldName, value);
    const hasErrors = checkForErrors(updatedFormState);
    setFormState({ ...updatedFormState, hasErrors });
    if (formState.country.value) {
      updateLanguages();
    }
  };

  const resetHandler = () => {
    setFormState({ ...defaultFormState(userProfile) });
  };

  const fetchCountriesInfo = async () => {
    try {
      const countriesInfo = await getCountriesInfo();

      setCountries(extractCountriesInfo(countriesInfo));
      updateLanguages();
    } catch (error) {
      setCountriesInfoApiError(true);
    }
  };

  const getUserProfile = () => {
    dispatch(loadUcdeUserProfile('ucde'));
  };

  const formatProfileName = () => {
    if (!formState.givenName.value) {
      return null;
    }
    return formState.givenName.error || i18n.t('myAccount.profile.constraints.alphaChar');
  };

  const formatFamilyName = () => {
    if (!formState.familyName.value) {
      return null;
    }
    return formState.familyName.error || i18n.t('myAccount.profile.constraints.alphaChar');
  };

  const formatEmail = () => {
    if (!formState.email.value) {
      return null;
    }
    return formState.email.error;
  };

  useEffect(() => {
    getUserProfile();
    fetchCountriesInfo();
  }, []);

  useEffect(() => {
    async function validateEmailAndPhoneChange() {
      const requiresAccountValidation = userProfile.email !== formState.email.value
      || userProfile.phoneNumber !== formState.phoneNumber.value;
      if (formState.isSaving && requiresAccountValidation && !userProfile.profileApiError) {
        window.location.href = await loginUrlLBuilder(true, false, url, PATHS.ACCOUNT_DETAILS.PROFILE);
      }
    }

    validateEmailAndPhoneChange();
  }, [userProfile.profileApiError, formState.isSaving]);

  useEffect(() => {
    setFormState(defaultFormState(userProfile));
    updateLanguages();
  }, [userProfile]);

  useEffect(() => {
    if (userProfile.profileApiError && formState.isSaving) {
      const defaultModalErrorOptions = {
        errorMessage: i18n.t('myAccount.profile.error.save.body'),
        title: i18n.t('myAccount.profile.error.save.header'),
        actionLabel: i18n.t('myAccount.profile.error.save.buttons.refresh'),
        actionCallback: () => window.location.reload(),
        dismissLabel: i18n.t('myAccount.profile.error.save.buttons.cancel'),
      };

      renderErrorModal(defaultModalErrorOptions);
    }

    if ((userProfile.profileApiError || countriesInfoApiError) && !formState.isSaving) {
      const defaultModalErrorOptions = {
        title: i18n.t('myAccount.profile.error.retrieve.header'),
        errorMessage: i18n.t('myAccount.profile.error.retrieve.body'),
        actionLabel: i18n.t('myAccount.profile.error.retrieve.buttons.retry'),
        actionCallback: () => window.location.reload(),
        dismissLabel: i18n.t('myAccount.profile.error.retrieve.buttons.cancel'),
      };

      renderErrorModal(defaultModalErrorOptions);
    }
  }, [userProfile.profileApiError, countriesInfoApiError]);

  return (
    <Container
      data-testid="user-profile"
    >
      <Title>{i18n.t('myAccount.profile.mainHeader')}</Title>
      <BrowserView>
        <Description>{i18n.t('myAccount.profile.description')}</Description>
      </BrowserView>
      {userProfile.isLoading ? <Loader /> : (
        <Form
          id="profile-form"
        >
          <FormInput
            id="profile-username"
            name="userName"
            label={i18n.t('myAccount.profile.userName')}
            value={userProfile.userName}
            defaultValue={userProfile.userName}
            disabled
          />
          <FormInput
            id="profile-name"
            name="givenName"
            label={i18n.t('myAccount.profile.firstName')}
            defaultValue={userProfile.givenName}
            value={formState.givenName.value}
            disabled={formState.isSaving || formState.isLoading}
            onChange={value => handleChange('givenName', value)}
            error={formState.givenName.error}
            helperText={formatProfileName()}
            maxLength={maxLengthChar}
          />
          <FormInput
            id="family-name"
            name="familyName"
            label={i18n.t('myAccount.profile.familyName')}
            value={formState.familyName.value}
            defaultValue={userProfile.familyName}
            onChange={value => handleChange('familyName', value)}
            disabled={formState.isSaving || formState.isLoading}
            error={formState.familyName.error}
            helperText={formatFamilyName()}
            maxLength={maxLengthChar}
          />
          <FormInput
            id="profile-email"
            name="email"
            label={i18n.t('myAccount.profile.email')}
            value={formState.email.value}
            defaultValue={userProfile.email}
            onChange={value => handleChange('email', value)}
            disabled={formState.isSaving || formState.isLoading}
            error={formState.email.error}
            helperText={formatEmail()}
            maxLength={maxLengthChar}
          />
          <FormInput
            id="phone-number"
            name="phoneNumber"
            label={i18n.t('myAccount.profile.phoneNumber')}
            value={formState.phoneNumber.value}
            defaultValue={userProfile.phoneNumber}
            onChange={value => handleChange('phoneNumber', value)}
            disabled={formState.isSaving || formState.isLoading}
            error={formState.phoneNumber.error}
            maxLength={maxLengthChar}
          />
          <FormSelect
            name="country"
            options={countries} id="profile-country"
            label={i18n.t('myAccount.profile.selectCountry')}
            defaultValue={[findStringValue(countries, userProfile.country)]}
            value={[findStringValue(countries, path(['country', 'value'], formState))]}
            disabled={formState.isSaving || formState.isLoading || !isUserOwner(userProfile)}
            onChange={v => handleChange('country', v.stringValue)}
            clearIcon={false}
          />
          <FormSelect
            name="language"
            options={languages} id="profile-language"
            label={i18n.t('myAccount.profile.language')}
            defaultValue={[findStringValue(languages, userProfile.language)]}
            value={[findStringValue(languages, path(['language', 'value'], formState))]}
            onChange={value => handleChange('language', value.stringValue)}
            disabled={formState.isSaving || formState.isLoading}
            clearIcon={false}
          />

          <FeatureFlag featureFlagKey="ff_ucde_portal_change_password">
            <PaddedDiv>
              <ExternalLink
                href={changePasswordUrl[stack]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {i18n.t('myAccount.profile.link.changePassword')}
              </ExternalLink>
            </PaddedDiv>
          </FeatureFlag>

          <FormButton
            ariaLabel="Apply Changes"
            type="submit"
            loading={formState.isSaving}
            onClick={applyChanges}
            disabled={!formState.hasChanges || formState.hasErrors}
          >
            {i18n.t('myAccount.profile.button.applyChanges')}
          </FormButton>
          <span />
          {!formState.isSaving && formState.hasChanges && (
          <FormButton
            ariaLabel="Cancel"
            appearance="tertiary"
            type="reset"
            onClick={resetHandler}
          >
            {i18n.t('myAccount.profile.button.cancel')}
          </FormButton>
          )}
        </Form>
      )}
    </Container>
  );
};

export default ProfileForm;
