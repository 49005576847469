/* eslint-disable no-use-before-define */
import ActionTypes from '../constants/ActionTypes';
import fetchStatus from './fetchStatus';
import { createReducer } from '../utils/redux';

const initialState = {
    ...fetchStatus(),
    userInfoData: null,
    userPrinterModelLastTouched: null, // FOR ANALYTICS TRACKING
    userPrinterName: null
};

const handlers = {
    [ActionTypes.START_USER_INFO]: startUserInfo,
    [ActionTypes.COMPLETE_USER_INFO_WITH_ERROR]: completeUserInfoWithError,
    [ActionTypes.COMPLETE_USER_INFO]: completeUserInfo,
    [ActionTypes.SET_USER_PRINTER_MODEL]: setUserPrinterModel
};

function startUserInfo(state, action) {
    return {
        ...fetchStatus(state, { type: ActionTypes.START_FETCH })
    };
}

function completeUserInfoWithError(state, action) {
    return {
        ...fetchStatus(state, {
            ...action,
            type: ActionTypes.COMPLETE_WITH_ERROR
        })
    };
}

function completeUserInfo(state, action) {
    return {
        ...fetchStatus(state, { type: ActionTypes.COMPLETE_FETCH }),
        ...{ userInfoData: action.payload.data }
    };
}

function setUserPrinterModel(state, action) {
    return {
        ...state,
        ...{
            userPrinterModelLastTouched: action.model.mod,
            userPrinterName: action.model.name
        }
    };
}

export default createReducer(initialState, handlers);
