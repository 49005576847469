const checkEntitlement = (entitlementList, serviceId) => {
  let entitlement = true;
  if (entitlementList) {
    const serviceID = entitlementList.find(
      (entitlementListItem) => entitlementListItem.serviceId === serviceId
    );
    if (!serviceID) {
      entitlement = false;
    }
  }
  return entitlement;
};

export default checkEntitlement;
