import ActionTypes from '../constants/ActionTypes';

const origamiDefault = {
  isFetching: false,
  isFetched: false,
  isError: false,
  errorMsg: '',
  data: null
};

/**
 * The reducer controls the state of the axios or ajax call.
 * returning the current state with any changes from the
 * actions dispatchers
 *
 *
 * @param state
 * @param action
 * @returns {*}
 */

function origami(state = origamiDefault, action) {
  switch (action.type) {
    case ActionTypes.START_ORIGAMI_FETCH:
      return { ...origamiDefault, isFetching: true, isFetched: false };

    case ActionTypes.COMPLETE_ORIGAMI_FETCH_SUCCESS:
      return { ...origamiDefault, data: action.response, isFetching: false, isFetched: true };

    case ActionTypes.COMPLETE_ORIGAMI_FETCH_WITH_ERROR:
      return { ...origamiDefault, isError: true, isFetching: false, isFetched: false };

    default:
      return state;
  }
}

export default origami;
