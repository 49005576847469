import styled from 'styled-components';
import List from '@veneer/core/dist/scripts/list';
import ListItem from '@veneer/core/dist/scripts/list_item';
import Button from '@veneer/core/dist/scripts/button';
import { breakpoints, stylesIf } from '../../../utils/styleHelpers';

export const Container = styled.div`
  display: flex;
  font-family: 'hp-simplified', Arial, sans-serif;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  max-height: auto;
  min-height: auto;
  overflow-y: hidden;
  box-sizing: border-box;
  max-width: 90vw;
  padding-top: 1rem;
  padding-bottom: 0;
  margin: 0 5vw;

  @media screen and (min-width: ${breakpoints.mobile}) {
    margin: auto;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 35vw;
    overflow: hidden;
    min-height: ;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    margin: 0 auto;
  }

  @media screen and (min-width: ${breakpoints.wide}) {
    font-size: 0.875rem;
    max-width: 64rem;
    margin-bottom: 0;
    min-height: 0;
  }
`;

export const Title = styled.h4`
  font-family: 'hp-simplified', Arial, sans-serif;

  color: #313131;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375;
  text-align: center;

  && {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }
  }
`;

export const SubTitle = styled.h4`
  font-family: 'hp-simplified', Arial, sans-serif;

  font-style: normal;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.625;
  color: #4e4e4e;
  && {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }
  }
`;

export const Graphic = styled.img`
  @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
    margin-left: 50%;
    transform: translate(-50%);
  }
  width: 1.563rem;
  height: 1.563rem;
`;

export const StyledList = styled(List)`
  font-family: 'hp-simplified', Arial, sans-serif;

  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 1rem;
    flex-direction: row;
    flex-wrap: row;
  }
`;

export const StyledListItem = styled(ListItem)`
  && {
    @media only screen and (min-width: ${breakpoints.tablet}) {
      border: 1px solid black;
      border-top: 1px solid black !important;
      border-bottom: 1px solid black !important;
      border-radius: 0.75rem;
      margin-right: 0.625rem;
    }
  }
  flex: 0 0 32%;
  margin-bottom: 0.625rem;
`;

export const ButtonContainer = styled.div`
  display:flex;
  padding-bottom: 1rem;
  padding-top: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  @media screen and (min-width:${breakpoints.mobile}) {
    flex-direction:row-reverse;
  }
  @media screen and (min-width:${breakpoints.tablet}) {  
    flex-direction:column;
    margin-left: 0;
      margin-bottom:0.625rem;
    }

  @media screen and (min-width:500px) and (max-width:500px) and (min-height:530px ) and (max-height:530px) {    
    font-size: 1rem;
    line-height: 1.25rem;
    align-items: center;
    flex-direction:row-reverse;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  @media only screen and (min-device-width: 768px), (min-device-width: 1024px) 
  and (max-device-width: 1024px), (max-device-width: 1366px)  
  and (-webkit-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 2) {
    /* iPad and iPadPro only with Desktop exception */
    flex-direction: ${(props) => (props.mobile ? 'row-reverse' : 'column')};
    margin-right: 0;
  }

}
`;
export const StyledButton = styled(Button)`
  && {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }
    min-height: ${stylesIf('small', '2.25', '2.875')}rem;
    ${stylesIf('small', 'border-radius: 0.5rem;')}
    ${stylesIf(
      'hasBody',
      `
        @media screen and (min-width: 25.875rem) {
          margin-top: 1rem;
        }
      `
    )}
  }
`;
