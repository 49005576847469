import styled from 'styled-components';
import { path } from 'ramda';

const isBorderVisible = level => level < 50;
const getBorderVisibleColor = level => (level > 0 ? '#e8701a' : '#d0021b');
const getBorderColor = level => (isBorderVisible(level) ? getBorderVisibleColor(level) : 'transparent');

export const LargeWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: 94px;
  width: 94px;
  border: 2px solid ${({ level }) => getBorderColor(level)};
  border-radius: 14px;
`;

export const LargeRow = styled.div`
  white-space: nowrap;
  height: 30px;
  margin: 0;
`;

export const LargeDot = styled.div`
  width: 20px;
  height: 20px;
  border: 0.5px solid ${({ type = 'white' }) => props => path(['theme', 'main', 'cartridgeLarge', type, 'borderColor'], props)};
  border-radius: 50%;
  background-color: ${({ type = 'white' }) => props => path(['theme', 'main', 'cartridgeLarge', type, 'color'], props)};
  display: inline-block;
  margin: 5px;
  float: left;
`;
