import { Service } from 'axios-middleware';
import createAxiosInstance from './api';
import Config from '../../../config/Config';
import { setAuthorizationHeader } from './UCDEGateway';
import Unauthorized from '../exceptions/customErrors';
import {
  getStratusAccessToken,
  isStratusAccessTokenExpired,
  isStratusAccessTokenRefreshable,
  getDecodedStratusAccessToken,
} from '../utils/auth';
import { parseUcdeGatewayApiError } from '../utils/apiErrorHandling';

const config = new Config();

const sessionApi = createAxiosInstance(config.Get('SESSION_SERVICE_BASE_URL'));

const service = new Service(sessionApi);

service.register({
  onResponseError(error) {
    const currentStack = config.Get('CURRENT_STACK');

    if (currentStack === 'dev') {
      console.error(parseUcdeGatewayApiError(error));
    }
  },
});

const getExchangeToken = async accessToken => {
  const authorizationHeader = `Bearer ${accessToken}`;
  const options = {
    headers: {
      authorization: authorizationHeader,
    },
    withCredentials: true,
  };
  const exchangeToken = await sessionApi.put('/exchange', null, options);
  return exchangeToken;
};

// TODO: change this to a observer logic
async function refreshToken() {
  const options = {
    withCredentials: true,
  };

  const decodedToken = getDecodedStratusAccessToken();
  const isTokenExpired = isStratusAccessTokenExpired(decodedToken);
  const isTokenRefreshable = isStratusAccessTokenRefreshable(decodedToken);

  if (isTokenRefreshable && !isTokenExpired) {
    // there's no need to pass auth as a header since it get the cookies
    await sessionApi.put('/refresh', null, options);
    setAuthorizationHeader(getStratusAccessToken());
  } else if (isTokenExpired) {
    throw new Unauthorized('Expired token');
  }
}

export default getExchangeToken;
export { getExchangeToken, refreshToken };
