import axios from 'axios';
import ActionTypes from '../constants/ActionTypes';
import { getCookie } from '../lib/manageCookies';

export function getUserPrograms() {
    const start = () => ({
        type: ActionTypes.START_USER_PROGRAMS_FETCH
    });

    const complete = (userProgramsData) => ({
        type: ActionTypes.COMPLETE_USER_PROGRAMS_FETCH,
        userProgramsData
    });

    const error = (err) => ({
        type: ActionTypes.COMPLETE_USER_PROGRAMS_FETCH_WITH_ERROR,
        err
    });

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(start());
            axios
                .request({
                    url: `/api/2/mosaic/v2/services`,
                    headers: {
                        Authorization: `Bearer ${getCookie(
                            'stratus-access-token'
                        )}`
                    }
                })
                .then((res) => {
                    const userProgramsData = res.data;
                    dispatch(complete(userProgramsData));
                    return resolve(userProgramsData);
                })
                .catch((err) => {
                    dispatch(error(err));
                    reject(err);
                });
        });
    };
}
