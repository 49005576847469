import styled from 'styled-components';
import { breakpoints } from '../../../utils/styleHelpers';

export const FAQsContainer = styled.div`
  && {
    font-family: 'HP Simplified';
    background: #f9f9f9;
    justify-content: center;
    width: 100% initial;
    @media (min-width: ${breakpoints.bigtablet}) {
      display: flex;
    }
    .vn-animated-height__container {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: height 0.5s ease;
      visibility: hidden;
    }
    .vn-animated-height__container--entering,
    .vn-animated-height__container--exiting {
      opacity: 1;
      visibility: inherit;
    }
    .vn-animated-height__container--entered {
      height: auto;
      opacity: 1;
      overflow: visible;
      visibility: inherit;
    }
  }
`;

export const FAQsContent = styled.div`
  && {
    width: auto;
    margin: 10px 0 80px;
    @media (min-width: ${breakpoints.bigtablet}) {
      width: 852px;
      left: calc(50% - 852px / 2);
    }
    .title {
      font-size: 32px;
      font-weight: 400;
      line-height: 36px;
      color: #4e4e4e;
      margin-bottom: 20px;
      margin-top: 75px;
      margin-left: 12px;
      @media (min-width: ${breakpoints.bigtablet}) {
        margin-left: 0;
      }
    }
    .accordion-title {
      font-family: 'HP Simplified';
      font-weight: 400;
    }
  }
`;

export const AccordionBody = styled.div`
  > p {
    line-height: 1.5;
    margin-top: 1em;
  }
`;
export const AccordionHeader = styled.span`
  font-family: 'HP Simplified', Arial, sans-serif;
  color: #4e4e4e;
  font-weight: 400;
  font-size: 1rem;
`;
