import React from 'react';

import RRCarousel from 'nuka-carousel';

const Carousel = props => {


  return (
    <RRCarousel
      {...props}
    />
  );
};

export default Carousel;
