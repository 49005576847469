import React, { useEffect, useState } from 'react';
import { WhenJWebReady } from '@jweb/core';
import {
  Container,
  Title,
  SubTitle,
  ButtonContainer,
  StyledButton,
  Brand,
  BrandText,
  StyledHPLogo,
  AppBadgesContainer
} from './styles/UpgradeSupportStyles';
import { selectQueryParameters } from '../../selectors/routing';
import {
  selectAppBadgesContentStackData, selectContentStackDataByResource
} from '../../selectors/craft';
import { useSelector } from '../../hooks';
import { UPGRADE_SUPPORT_RESOURCE_NAME } from '../../constants/contentStackResources';

const UpgradeSupport = () => {
  const [isIOS, setIOS] = useState(false);
  const [isAndroid, setAndroid] = useState(false);
  const queryParams = useSelector(selectQueryParameters);
  const { platform } = queryParams;

  const checkHPSmartApp = async () => {
    const res = await WhenJWebReady;
    if (res) {
      setIOS(!!JWeb.isIOS);
      setAndroid(!!JWeb.isAndroid);
    }
  };

  useEffect(() => {
    // checks Jweb only when the platform value is not present in the query
    if (!platform) {
      checkHPSmartApp();
    }
  }, []);

  const isMobile = ['ios', 'android'].includes(platform) || isIOS || isAndroid;
  const appBadgesContent = useSelector(selectAppBadgesContentStackData)?.data?.entries[0];
  const { support_upgrade } = useSelector(selectContentStackDataByResource, [UPGRADE_SUPPORT_RESOURCE_NAME]);
  const support_upgrade_data = support_upgrade?.data?.entries[0];

  if (appBadgesContent && support_upgrade_data) {
    const { landing_page_app_badges } = appBadgesContent;
    const microsoftBadgeData = landing_page_app_badges[2].app_badge;
    const macBadgeData = landing_page_app_badges[3].app_badge;

    const {
      hpsmart_heading,
      upgrade_headline,
      upgrade_os_message,
      button_text,
      button_destination
    } = support_upgrade_data;

    return (
      <Container>
        <Brand>
          <StyledHPLogo appearance="multicolor" size={40} />
          <BrandText>{hpsmart_heading}</BrandText>
        </Brand>

        <Title
          dangerouslySetInnerHTML={{
            __html: upgrade_headline
          }}
        />

        <SubTitle
          dangerouslySetInnerHTML={{
            __html: upgrade_os_message
          }}
        />

        <ButtonContainer>
          <StyledButton
            onClick={async () => {
              window.location.assign(button_destination);
            }}
            appearance="primary"
            id="find-all-printers"
            small={isMobile}
          >
            {button_text}
          </StyledButton>
        </ButtonContainer>

        <AppBadgesContainer>
          <img
            alt={microsoftBadgeData.app_badge_graphic.title}
            src={microsoftBadgeData.app_badge_graphic.url}
            className="store ms"
          />
          <img
            alt={macBadgeData.app_badge_graphic.title}
            src={macBadgeData.app_badge_graphic.url}
            className="store mac-app-store"
          />
        </AppBadgesContainer>
      </Container>
    );
  }

  return null;
};

export default UpgradeSupport;
