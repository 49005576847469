import React from 'react';
import { getHtmlDir } from '../../../utils/globals';
import { useSelector } from '../../../hooks';
import { selectAddPrintersContentStackData, selectAppBadgesContentStackData } from '../../../selectors/craft';
import Navbar from '../../revised/Global/Navbar';
import Header from './Header/Header';
import Printers from './Printers/Printer';
import Experience from '../InstallDrivers/Experience/Experience';
import Support from '../InstallDrivers/Support/Support';
import Footer from '../../revised/Global/Footer';
import { CS_SMB_DOWNLOAD_CHROMEOS_ENTRY_ID } from '../../../constants/common';

const AddPrinters = (props) => {
  const direction = getHtmlDir();
  const { geoCountry } = props;
  const isRTL = direction === 'rtl';
  const contentStackContent = useSelector(selectAddPrintersContentStackData)?.data?.entries.filter(section => section.uid === CS_SMB_DOWNLOAD_CHROMEOS_ENTRY_ID);
  const appBadgesContent = useSelector(selectAppBadgesContentStackData)?.data?.entries[0];

  if (contentStackContent && appBadgesContent) {
    const {
      app_promo_text,
      app_store_text,
      chromeos_header_image,
      download_header,
      install_steps,
      qr_code,
      qr_code_text,
      support_icon,
      support_icon_text
    } = contentStackContent[0];
    const { landing_page_app_badges } = appBadgesContent;
    return (
      <div>
        <Navbar
          geoCountry={geoCountry}
          minimal={true}
          smbDownloadHeader={true}
        />
        {download_header && (
          <Header
            alt={chromeos_header_image?.title}
            image={chromeos_header_image?.url}
            heading={download_header}
            isRTL={isRTL}
          />
        )}
        {install_steps && <Printers steps={install_steps} isRTL={isRTL} />}
        {app_promo_text && (
          <Experience
            heading={app_promo_text}
            appStoreText={app_store_text}
            appStoreBadges={landing_page_app_badges}
            showMacAppStoreBadge
            qrCodeTitle={qr_code?.title}
            qrCodeUrl={qr_code?.url}
            qrCodeText={qr_code_text}
            isRTL={isRTL}
          />
        )}
        {support_icon_text && (
          <Support
            alt={support_icon?.title}
            icon={support_icon?.url}
            text={support_icon_text}
            isRTL={isRTL}
          />
        )}
        <Footer />
      </div>
    );
  }

  return null;
};

export default AddPrinters;
