import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import MicroFrontend from '../../../../components/MicroFrontend';
import selectCurrentStack from '../../../../store/modules/environment/selectors';
import { Container } from '../../../../components/shared-components/styles';

// TODO - update URLs by stack
const manifestUrls = {
  dev: 'https://api-rsantos2.goblin.hpalpine.com/potg/asset-manifest.json',
  pie: 'https://api-rsantos2.goblin.hpalpine.com/potg/asset-manifest.json',
  stage: 'https://api-staging.ogre.hpalpine.com/potg/asset-manifest.json',
  prod: 'https://roam.api.hp.com/potg/asset-manifest.json',
};

const PrintOnTheGo = ({ history }) => {
  const stack = useSelector(selectCurrentStack);

  return (
    <Container>
      <MicroFrontend
        history={history}
        appName="potg"
        globalFunction="renderPotg"
        manifest={manifestUrls[stack]}
      />
    </Container>
  );
};

PrintOnTheGo.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PrintOnTheGo;
