import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  path,
} from 'ramda';
import PropTypes from 'prop-types';
import base64url from 'base64url';
import DeviceSupplies from './DeviceSupplies';
import {
  LoadingContainer,
  PrinterCard,
  PrinterCardFooter,
  PrinterGroupImage,
  PrinterGroupSupplies,
  PrinterGroupText,
  PrinterMeta,
  PrinterName,
  Spinner,
  StyledLink,
} from './styles';
import i18n from '../../../utils/i18n';
import MyPrinterStatus from './UserDeviceStatus/MyPrinterStatus';
import PrinterStateDrawer from './UserDeviceStatus/PrinterStateDrawer';
import { getDeviceSupplies } from '../../../store/modules/devices/actions';
import { connectionState } from './UserDeviceStatus/statusDictionary';
import selectCurrentStack from '../../../store/modules/environment/selectors';
import UserDeviceStatus from './UserDeviceStatus';
import ServerError from '../../shared-components/ServerError';

const UserDevice = ({ device, printersPage, selectPrinter }) => {
  const genericImage = '/assets/img/dashboard/generic-printer.svg';
  const [printerImage, setPrinterImage] = useState(genericImage);
  const printerStateReasons = path(['status', 'printerStateReasons'], device);
  const printerSupplyType = path(['supplies', 'supplyType'], device);
  const printerSupplyStates = path(['supplies', 'supplyStates'], device);
  const printerSubscriptionState = path(['subscription', 'state'], device);
  const dispatch = useDispatch();

  const parseDate = dateString => {
    if (!dateString) {
      return null;
    }
    const date = new Date(dateString.replace(' ', ''));
    return date.toLocaleString();
  };

  const printerLastSeenTime = parseDate(path(['connectivityStatus', 'lastSeenTime'], device));

  useEffect(() => {
    if (device.images && device.images.length > 0) {
      setPrinterImage(device.images[device.images.length - 1]);
    } else {
      setPrinterImage(genericImage);
    }
  }, []);

  const doRefreshDeviceSupplies = event => {
    event.preventDefault();
    dispatch(getDeviceSupplies(device));
  };

  const hasNonOriginalSupply = supplies => supplies && supplies.some(search => search.deviceAssessment === 'notHp');

  const filterStateReason = reasons => reasons
    && reasons.filter(reason => reason.toLowerCase() !== 'none'
    && reason.toLowerCase() !== 'other');

  const hasStateReason = reasons => reasons
    && !reasons.some(reason => reason.toLowerCase() === 'none')
    && filterStateReason(reasons).length > 0;

  const stack = useSelector(selectCurrentStack);
  const printerOptionsUrl = {
    dev: 'https://hpsmartdev.com/api/1/helper/print-anywhere/manage?email=',
    pie: 'https://hpsmartpie.com/api/1/helper/print-anywhere/manage?email=',
    stage: 'https://hpsmartstage.com/api/1/helper/print-anywhere/manage?email=',
    prod: 'https://hpsmart.com/api/1/helper/print-anywhere/manage?email=',
  };
  const getPrinterOptionsUrl = () => `${printerOptionsUrl[stack]}${base64url.encode(device.eprintEmailAddress)}`;
  const getPrinterOptionsBlock = () => (
    <StyledLink to={getPrinterOptionsUrl()}>
      {i18n.t('myPrinters.printerOptions')}
    </StyledLink>
  );

  const loading = (
    <LoadingContainer>
      <Spinner
        id="userDevices_page_spinner"
        src="/assets/print-anywhere/spinner.svg"
      />
    </LoadingContainer>
  );

  const getSuppliesBlock = () => {
    if (device.supplies) {
      if (device.isFetchingSupplies) {
        return loading;
      }
      return (
        <DeviceSupplies
          supplyType={printerSupplyType}
          levels={printerSupplyStates}
          doRefresh={doRefreshDeviceSupplies}
          printersPage={printersPage}
        />
      );
    }
    return null;
  };

  const getSubscriptionBlock = () => {
    if (printerSubscriptionState === 'eligible') {
      return (
        <StyledLink to="/ucde/print_plans">
          {i18n.t('myPrinters.subscribe')}
        </StyledLink>
      );
    }
    if (printerSubscriptionState === 'subscribed') {
      return (
        <UserDeviceStatus
          printersPage={printersPage}
          status="subscribed"
          id="subscription-state"
        />
      );
    }
    return null;
  };

  const hasGroupSupplies = () => device.supplies
    || (printerSubscriptionState && printerSubscriptionState !== 'nonEligible');

  const hasSecondLine = device.printerOptions || hasGroupSupplies();

  return device.modelName ? (
    <>
      <PrinterCard printersPage={printersPage} noPaddingBottom={hasSecondLine}>
        <PrinterMeta printersPage={printersPage}>
          <PrinterGroupImage printersPage={printersPage} onClick={selectPrinter}>
            <img
              src={printerImage}
              alt={device.modelName}
              onError={() => setPrinterImage(genericImage)}
            />
          </PrinterGroupImage>
          <PrinterGroupText printersPage={printersPage}>
            <PrinterName printersPage={printersPage} onClick={selectPrinter}>
              {device.modelName.replace(/_/g, ' ')}
            </PrinterName>
            <MyPrinterStatus device={device} printersPage={printersPage} />
          </PrinterGroupText>
        </PrinterMeta>
        {
          hasGroupSupplies()
            && (
              <PrinterGroupSupplies printersPage={printersPage}>
                {getSuppliesBlock()}
              </PrinterGroupSupplies>
            )
        }
      </PrinterCard>
      {
        hasSecondLine
          && (
            <PrinterCard printersPage={printersPage} noPaddingTop>
              <PrinterMeta printersPage={printersPage}>
                {device.printerOptions && getPrinterOptionsBlock()}
              </PrinterMeta>
              {
                hasGroupSupplies()
                  && (
                    <PrinterGroupSupplies printersPage={printersPage}>
                      {getSubscriptionBlock()}
                    </PrinterGroupSupplies>
                  )
              }
            </PrinterCard>
          )
      }
      {
        printersPage
          && (hasStateReason(printerStateReasons) || hasNonOriginalSupply(printerSupplyStates))
          && (
            <PrinterStateDrawer
              email={device.eprintEmailAddress}
              nonOriginal={hasNonOriginalSupply(printerSupplyStates)}
              reasons={filterStateReason(printerStateReasons)}
            />
          )
      }
      {
        printersPage
        && printerLastSeenTime
        && (
          <PrinterCardFooter>
            {i18n.t('myPrinters.lastSeenTime', { timestamp: printerLastSeenTime })}
          </PrinterCardFooter>
        )
      }
    </>
  ) : <ServerError direction="row" printersPage={printersPage} />;
};

UserDevice.defaultProps = {
  printersPage: false,
  selectPrinter: () => {},
};

UserDevice.propTypes = {
  printersPage: PropTypes.bool,
  device: PropTypes.shape({
    cloudId: PropTypes.string.isRequired,
    modelName: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
    platformIdentifier: PropTypes.string.isRequired,
    eprintEmailAddress: PropTypes.string.isRequired,
    connectivityStatus: PropTypes.shape({
      connectionState: PropTypes.oneOf(Object.keys(connectionState)).isRequired,
      lastSeenTime: PropTypes.string.isRequired,
    }),
    images: PropTypes.arrayOf(PropTypes.string),
    isFetchingSupplies: PropTypes.bool,
    supplies: PropTypes.shape({
      supplyType: PropTypes.string,
      supplyStates: DeviceSupplies.propTypes.levels,
    }),
    status: PropTypes.shape({
      printerStateReasons: PrinterStateDrawer.propTypes.reasons,
    }),
    subscription: PropTypes.shape({
      state: PropTypes.oneOf(['subscribed', 'eligible', 'nonEligible']),
    }),
    printerOptions: PropTypes.bool,
  }).isRequired,
  selectPrinter: PropTypes.func,
};

export default UserDevice;
