import React, { useState } from 'react';
import { Modal } from '@veneer/core';
import styled from 'styled-components';
import { selectClaimCodeModalData } from '../../selectors/myPrinters';
import { useSelector } from '../../hooks';
import { YETI_CLAIM_ERROR } from '../../constants/errorTypes';

const StyledError = styled.span`
  border-radius: 5px;
  p {
    color: #D67300;
    font-family: 'hp-simplified', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  }
  padding: 0.5rem 0.625rem;
  margin-bottom: 1.25rem;
  font-family: 'hp-simplified', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  display: block;
  margin-bottom: 20px;
  border: 1px solid  #D67300;
  color: #D67300;
`;
const PrinterClaimError = props => {
  const [isVisible, setIsVisible] = useState(true);
  const {
    errorDetail,
    errorMsg,
  } = props;

  const modalValues = useSelector(selectClaimCodeModalData, errorDetail);

  const removePrinterClaimError = () => {
    setIsVisible(false);
  };

  if (errorDetail && errorDetail === YETI_CLAIM_ERROR) {
    const {
      errorTitle,
      errorBody,
    } = modalValues || {};

    return (
      <Modal
        id="yeti-manual-claim-error-modal"
        onClose={removePrinterClaimError}
        show={isVisible}
        closeButton
        title={errorTitle}
      >
        {(errorBody.match(/<[a-z]+>/)
          ? <p dangerouslySetInnerHTML={{ __html: errorBody }} />
          : <p>{errorBody}</p>)}
      </Modal>
    );
  }
  return (
    <p>
      {(errorMsg.match(/<[a-z]+>/)
        ? <StyledError dangerouslySetInnerHTML={{ __html: errorMsg }} />
        : <StyledError>{errorMsg}</StyledError>)}
    </p>
  );
};

export default PrinterClaimError;
