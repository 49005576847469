import styled from 'styled-components';
import { breakpoints, smb_breakpoints } from '../../../../utils/styleHelpers';

const textStyles = `
  font-family: 'Forma DJR';
  color: #212121;
`;

export const ContentContainer = styled.div``;

export const TitleText = styled.h3`
  ${textStyles};
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
  margin-bottom: 20px;

  @media (max-width: ${breakpoints.wide}) {
    font-size: 40px;
    line-height: 48px;
  }

  @media (max-width: ${breakpoints.smalldesktop}) {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const DescriptionText = styled.span`
  p {
    ${textStyles};
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 0px;

    @media (max-width: ${breakpoints.wide}) {
      font-size: 18px;
      line-height: 24px;
    }

    @media (max-width: ${breakpoints.smalldesktop}) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
