import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router';
import { prop } from 'ramda';
import { useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { DirectionProvider } from '@veneer/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector, usePrevious } from '../hooks';
import { selectDetermineLocaleProps } from '../selectors/global';
import App from './app';
import Error from './Error';
import DataSharingNotice from './data-sharing-notice/dataSharingNotice';
import SupportContainer from './support-page';
import StoreSetup from './store-setup/StoreSetup';
import { getConfig } from '../actions/config';
import { captureUDLParams } from '../actions/analytics';
import { validateLocale } from '../utils/routing';
import LocaleInjectionRedirect from './routing/LocaleInjectionRedirect';
import { setLocale } from '../actions/location';
import { getIsFetchHPPlusCountry } from '../actions/location';
import '../scss/app.scss';
import { theme } from '../utils/styleHelpers';
import { getLocale } from '../utils/globals';
import { Redirect } from './routing';
import SmartAdvance from './smart-advance/SmartAdvance';
import PhotoPaper from './smart-novelli/photoPaper';
import SMBNoSetup from './smb-no-setup';
import SMBDownload from './smb-download';
import SMBGetSoftware from './smb-download/get-software';
import SMBSetupChoice from './smb-setup-choice';
import SMBAdminTou from './terms-of-use/SMBAdminTou';
import SureSupplyLearn from './sure-supply-learn/SureSupplyLearn';
import TonerReplaceConsents from './toner-replace-consents/TonerReplaceConsents';
import { LTR, RTL, RTL_LANGUAGES } from '../constants/i18n';
import { DOWNLOAD, GET_SOFTWARE, SETUP } from '../constants/common';
import Config from '../config/Config';
import { CONTENT_STACK_REDUNDANT_LOCALES } from '../constants/contentStackRedundantLocales';

// Utilities
import { pageViewTracking } from '../lib/analyticsTracking';
import RedirectToUrl from './routing/RedirectToUrl';
import { userOSDetector } from '../utils/userOSDetector';
import ExternalRedirect from './global/redirect/ExternalRedirect';

// Consts
import {
  WINDOWS_OS,
  MAC_OS,
  SMB_NO_SETUP_URL
} from '../constants/common';

const useDetermineLocaleHook = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getConfig());
  }, []);

  const [geoCountry, setGeoCountry] = useState(null);
  const [fetching, setFetching] = useState(false);
  const { configIsComplete, pathIsValid, country, language, currentLocale, pathname, signOutUrl } =
    useSelector(selectDetermineLocaleProps);

  const previous = usePrevious({ country, language });

  useEffect(() => {
    const determineGeoCountry = async () => {
      setFetching(true);
      const { country: localeCountry } = getLocale();
      setGeoCountry(localeCountry);
      setFetching(false);
    };
    if (!geoCountry && !fetching) {
      determineGeoCountry();
    }
    if (country !== null && !fetching) {
      dispatch(getIsFetchHPPlusCountry(country));
    }
  }, [geoCountry, country, fetching]);

  useEffect(() => {
    dispatch(captureUDLParams());
  }, []);

  useEffect(() => {
    if (
      country &&
      language &&
      (country !== prop('country', previous) || language !== prop('language', previous))
    ) {
      let locale = `${language}_${country.toUpperCase()}`;
      locale = CONTENT_STACK_REDUNDANT_LOCALES[locale] || locale;
      if (locale !== currentLocale && validateLocale(locale, pathname)) {
        dispatch(setLocale({ locale, country, language }));
      }
    }
  }, [country, language, previous, currentLocale]);

  useEffect(() => {
    // GA for SMB
    let pageNow = pathname.split('/');
    pageNow = pageNow[pageNow.length - 1];
    if ([SETUP, DOWNLOAD, GET_SOFTWARE].includes(pageNow)) {
      pageViewTracking(pathname);
    }
  }, [])

  return {
    signOutUrl,
    pathIsValid,
    configIsComplete,
    geoCountry,
    language
  };
};

const verifyOsAndSetSetupRedirect = () => {
  const config = new Config();
  const userOperatingSystem = userOSDetector();

  if (userOperatingSystem.includes(WINDOWS_OS)) {
    return config.Get('SETUP_SERVER_HP_OOBE_WINDOWS');
  } else if (userOperatingSystem.includes(MAC_OS)) {
    return config.Get('SETUP_SERVER_HP_OOBE_MAC');
  } else {
    return window.location.origin + SMB_NO_SETUP_URL;
  }
}

const DetermineLocale = () => {
  const ldFlags = useFlags();
  const { ffStoreSetup, beaconResetShellRedirect } = ldFlags;
  const { pathIsValid, configIsComplete, geoCountry, language } = useDetermineLocaleHook();

  const currentDirection = RTL_LANGUAGES.includes(language) ? RTL : LTR;

  if (!configIsComplete) {
    return null;
  }

  if (!pathIsValid) {
    return <LocaleInjectionRedirect />;
  }

  const config = new Config();
  const oobeSetupURL = verifyOsAndSetSetupRedirect();
  const oobeDownloadURL = config.Get('DOWNLOAD_SOFTWARE_HP_OOBE');

  return (
    <DirectionProvider direction={currentDirection}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/:country/:language/error-invitation">
            {({
              match: {
                params: { country: coun, language: lang }
              }
            }) => <Redirect to={`/${coun}/${lang}/error/invitation`} />}
          </Route>
          <Route path="/:country/:language/error/:type">
            <Error />
          </Route>
          <Route path="/:country/:language/admin-tou">
            <SMBAdminTou />
          </Route>
          <Route path="/:country/:language/smb-admin-tou">
            {({
              match: {
                params: { country: coun, language: lang }
              }
            }) => <Redirect to={`/${coun}/${lang}/admin-tou`} />}
          </Route>
          <Route path="/:country/:language/plain/data-sharing-notice">
            <DataSharingNotice />
          </Route>
          <Route path="/:country/:language/setup">
            <RedirectToUrl url={oobeSetupURL} />
          </Route>
          <Route path="/:country/:language/smb-no-setup">
            <SMBNoSetup geoCountry={geoCountry} />
          </Route>
          <Route path="/:country/:language/download">
            <RedirectToUrl url={oobeDownloadURL} />
          </Route>
          <Route path="/:country/:language/get-software">
            <SMBGetSoftware geoCountry={geoCountry} isRTLSupport={currentDirection === RTL} />
          </Route>
          <Route path="/:country/:language/support">
            {beaconResetShellRedirect ? (
              ({
                match: {
                  params: { country: coun, language: lang }
                }
              }) => 
                <ExternalRedirect
                  path={ '/support' + (window.location.pathname.split('/support')[1] || '')}
                  baseUrl={ coun && lang ? `/${coun}/${lang}/ucde` : `/ucde` }
                  queryParams={window.location.search}
                />
            ) : (
              <SupportContainer />
            )}
          </Route>
          {ffStoreSetup ? (
            <Route path="/:country/:language/store-setup/:product?">
              {(routeProps) => <StoreSetup {...routeProps.match.params} />}
            </Route>
          ) : (
            <Route path="/:country/:language/store-setup">
              {(routeProps) => <StoreSetup {...routeProps.match.params} />}
            </Route>
          )}
          <Route path="/:country/:language/smart-advance">
            {(routeProps) => <SmartAdvance geoCountry={geoCountry} {...routeProps.match.params} />}
          </Route>
          <Route path="/:country/:language/landing/photo-paper">
            <PhotoPaper geoCountry={geoCountry} />
          </Route>
          <Route path="/:country/:language/sure-supply-learn">
            <SureSupplyLearn />
          </Route>
          <Route path="/:country/:language/toner-replace-consents">
            {(routeProps) => <TonerReplaceConsents {...routeProps.match.params} />}
          </Route>
          <Route path="/:country/:language/create">
            {({
              match: {
                params: { country: coun, language: lang }
              }
            }) => <Redirect to={`/${coun}/${lang}/landing/photo-paper`}></Redirect>}
          </Route>
          <Route path="/:country/:language/:resource?">
            {(routeProps) => <App {...routeProps} geoCountry={geoCountry} flags={ldFlags} />}
          </Route>
          <LocaleInjectionRedirect />
          {/** this _should_ never get hit due to the `pathIsValid` logic above */}
        </Switch>
      </ThemeProvider>
    </DirectionProvider>
  );
};

export default DetermineLocale;
