/* eslint-disable no-return-assign */
import React, { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import Media from 'react-media';
import { path, isEmpty, slice } from 'ramda';
import { WhenJWebReady } from '@jweb/core';
import { Header, Button, IconMenu, IconX, SideBar, LogoHp } from '@veneer/core';
import { useDispatch } from 'react-redux';
import { dispatchJWebClose } from '../../../utils/jweb';
import { useSelector } from '../../../hooks';
import { paths, breakpoints, stylesIf } from '../../../utils/styleHelpers';
import { selectNewNavBarContent } from '../../../selectors/global';
import { Link } from '../../routing';
import LocaleSelector from './LocaleSelector';
import { getCookie } from '../../../lib/manageCookies';
import UserAvatar from './UserAvatar';
import { getInstantInkSupportedCountries } from '../../../utils/helperApi';
import { getHtmlDir } from '../../../utils/globals';
import { RTL } from '../../../constants/i18n';
import {
  HELP_AND_SUPPORT
} from '../../../constants/common';

export const StyledHeader = styled(Header)`
  && {
    font-family: ${paths.fontsFamily};
    padding-top: 8px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
    z-index: 1000;
    position: fixed;
    top: 0;
    > div {
      :first-child {
        line-height: 1;
      }
      :last-child {
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          position: absolute;
          top: 8px;
          right: 0;
          margin-right: 19px;
        }
      }
    }
  }
`;

export const StyledHPLogo = styled(LogoHp)`
  height: ${stylesIf('$small', '32px', '40px')};
  width: ${stylesIf('$small', '32px', '40px')};
  color: ${paths.colorsHpBlue};
  ${props => props.direction === RTL ? 'margin-left: 10px' : 'margin-right: 10px'};
`;

export const BrandText = styled.h4`
  font-size: ${paths.fontsLg};
  font-family: ${paths.fontsFamily};
  font-weight: normal;
  color: ${paths.colorsBlack};
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
`;

export const LinkedBrand = styled(Link)`
  ${props => props.direction === RTL ? 'margin-left: 52px' : 'margin-right: 52px'};
  display: flex;
  align-items: center;
`;

export const Brand = styled.div`
  ${props => props.direction === RTL ? 'margin-left: 52px' : 'margin-right: 52px'};
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  ${stylesIf(
  'mobile',
  `border-bottom: 1px solid #E5E8E8;
    padding: 12px 0;`,
  'margin-right: 54px;'
)}
  font-family: ${paths.fontsFamily};
  font-size: ${paths.fontsMd};
  color: ${paths.colorsDark};
  text-decoration: none;
  :hover {
    text-decoration: none;
    color: ${paths.colorsDark};
  }
`;

const MobileMenu = styled(SideBar)`
  &&&&&& {
    margin-top: -1px;
    z-index: 999;
    backdrop-filter: none;
    background-color: transparent;
  }
`;

const MobileInteractionContainer = styled.div`
  ${(props) =>
    props.isSMBDownloadHeader ? 'padding: 3.75rem 1rem 0 1rem' : ' padding: 0.5rem 1rem 0 1rem'};
  width: 360px;
  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
    overflow-y: scroll;
  }
  @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    top: 64px;
  }
  @media (max-height: 40rem) {
    overflow-y: auto;
  }
  height: calc(100vh - 68px);
  > div {
    padding-top: 0;
    padding-bottom: 8px;
    border-radius: 0;
  }
  .vn-modal--content {
    overflow: auto;
  }
  .vn-modal--body {
    overflow: visible;
  }
  #locale-selector {
    height: 0 !important;
    &--open {
      height: inherit;
      position: relative !important;
      width: 100%;
      transform: none !important;
      > div > div {
        width: 100%;
        > ul {
          margin: 0;
          @media screen and (min-resolution: 1.25dppx) and (max-width: 80rem) {
            max-height: 23rem;
          }
          @media screen and (min-resolution: 1.5dppx) and (max-width: 80rem) {
            max-height: 16rem;
          }
        }
      }
    }
  }
`;

const NavLinks = styled.div`
  font-family: ${paths.fontsFamily};
  display: flex;
  ${stylesIf('mobile', 'flex-direction: column;', 'padding-top: 2px;')}
`;

const SignInLink = styled(Button)`
  &&& {
    color: #027aae;
    margin: 0;
    white-space: nowrap;
    font-family: ${paths.fontsFamily};
    @media (min-width: ${breakpoints.wide}) {
      margin: ${props => props.direction === RTL ? '0 16px 0 32px' : '0 32px 0 16px'};
    }
  }
`;

const CreateAcctButton = styled(Button)`
  && {
    padding: 8px 24px;
    min-width: auto;

    @media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      min-width: 9rem;
    }
  }
`;

const CloseButton = styled(Button)`
  && {
    color: ${paths.colorsDark};
  }
`;

const LeftArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media (min-width: 120rem) {
    margin-left: calc((100vw - 120rem) / 2);
  }
`;

const RightArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: 120rem) {
    margin-right: calc((100vw - 120rem) / 2);
  }
  #locale-selector--open {
    ul {
      margin: 0;
      display: flex;
      flex-flow: column wrap;
      height: 27rem;
      border: none;
      min-width: 65rem;
      overflow: hidden;
      padding: 1rem;
      li {
        flex: 0 1 11%;
        margin-bottom: 0;
        font-family: ${paths.fontsFamilyLight};
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          flex: none;
        }
      }
    }
  }
`;

const Navbar = (props) => {
  const featureFlags = useFlags();
  const { geoCountry, minimal, userInfoData, isAdminPage, smbDownloadHeader, isDataNotice } = props;
  const { links, accountLinks } = useSelector(selectNewNavBarContent, featureFlags.jshellRedirectProbability || 0);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [signinLoading, setSigninLoading] = useState(false);
  const [createAcctLoading, setCreateAcctLoading] = useState(false);
  const loggedIn = !!userInfoData || getCookie('hpc3-session-type') === 'user';
  const [isNative, setNative] = useState(false);
  const [isIOS, setIOS] = useState(false);
  const [isAndroid, setAndroid] = useState(false);
  const [InstantInkSupportedCountries, setInstantInkSupportedCountries] = useState([]);

  const checkHPSmartApp = async () => {
    const res = await WhenJWebReady;
    if (res) {
      setNative(JWeb.isNative);
      setIOS(!!JWeb.isIOS);
      setAndroid(!!JWeb.isAndroid);
    }
  };

  useEffect(() => {
    checkHPSmartApp();
    //Need to Refactor once the SmartHelp.js becomes a functional component.
    getInstantInkSupportedCountries().then((responce) => {
      const lowerCasedValues = responce.data.instantInkSupportedRegions.map((countries) =>
        countries.toLowerCase()
      );
      setInstantInkSupportedCountries(lowerCasedValues);
    });
    window.addEventListener('focus', () => {
      setCreateAcctLoading(false);
      setSigninLoading(false);
    });
  }, []);

  if (!minimal && (!links || isEmpty(links) || !accountLinks || isEmpty(accountLinks))) {
    return null;
  }

  const closeText = path([3, 'text'], accountLinks) || 'Close';

  const getUserName = () => {
    if (userInfoData) {
      const { firstName, lastName } = userInfoData;
      return `${firstName} ${lastName}`;
    }
    return null;
  };

  const userName = getUserName();
  const direction = getHtmlDir();

  const getBrandHP = () => {
    if (!isNative) {
      return (
        <LinkedBrand to="/" direction={direction}>
          <StyledHPLogo appearance="multicolor" size={40} direction={direction} />
          {!isDataNotice && (
            <BrandText>{!isAdminPage ? 'HP Smart' : 'HP Smart Admin'}</BrandText>
          )}
        </LinkedBrand>
      );
    }
    // In App View
    return (
      <Brand direction={direction}>
        <StyledHPLogo appearance="multicolor" size={40} direction={direction} />
        {!isDataNotice && (
          <BrandText>{!isAdminPage ? 'HP Smart' : 'HP Smart Admin'}</BrandText>
        )}
      </Brand>
    );
  };
  // Find a better way of handling showing links  for INSTANT_INK_COUNTRIES
  const country = window.location.pathname.split('/')[1];
  const countryName = (country || geoCountry).toLowerCase();
  const isInstantInkCountry = InstantInkSupportedCountries.find(
    (country) => country === countryName
  );

  const getPersonalizedLinks = (links) => {
    const navLinks = isInstantInkCountry ? links : links.slice(1);
    if (isDataNotice) {
      return navLinks.filter(link => link.id === HELP_AND_SUPPORT);
    }
    return navLinks;
  }

  const navLinks = getPersonalizedLinks(links);
  return (
    <React.Fragment>
      <Media queries={{ small: `(max-width: ${breakpoints.wide})` }}>
        {({ small }) =>
          small && (
            <MobileMenu
              id={direction === 'rtl' ? 'mobile-menu' : null}
              className="revised"
              position="left"
              show={open}
              onClose={() => setOpen(!open)}
              content={
                <MobileInteractionContainer isSMBDownloadHeader={smbDownloadHeader}>
                  {!smbDownloadHeader && (
                    <NavLinks mobile="true">
                      {[...navLinks, ...(!loggedIn ? slice(0, 2, accountLinks) : [])].map(
                        ({ text, destination, id }) => (
                          <StyledLink
                            key={`${text}${destination}`}
                            to={destination}
                            id={id}
                            mobile="true"
                            onClick={
                              destination && destination.includes('http')
                                ? () => (window.location.href = destination)
                                : undefined
                            }
                          >
                            {text}
                          </StyledLink>
                        )
                      )}
                    </NavLinks>
                  )}
                  {!loggedIn && <LocaleSelector mobile="true" direction={direction} />}
                </MobileInteractionContainer>
              }
            />
          )
        }
      </Media>
      <StyledHeader
        position="fixed"
        leftArea={
          <LeftArea>
            {minimal ? (
              <Brand direction={direction}>
                <StyledHPLogo appearance="multicolor" size={40} direction={direction} />
                {!isDataNotice && (
                  <BrandText>{!isAdminPage ? 'HP Smart' : 'HP Smart Admin'}</BrandText>
                )}
              </Brand>
            ) : (
              <React.Fragment>
                {getBrandHP()}
                <Media queries={{ small: `(max-width: ${breakpoints.wide})` }}>
                  {({ small }) =>
                    small ? null : (
                      <NavLinks>
                        {navLinks.map(({ text, destination, _metadata }) => (
                          <StyledLink key={`${text}${destination}`} to={destination} id={_metadata.uid}>
                            {text}
                          </StyledLink>
                        ))}
                      </NavLinks>
                    )
                  }
                </Media>
                {(isIOS || isAndroid) && (
                  <CloseButton appearance="tertiary" onClick={dispatchJWebClose}>
                    {closeText}
                  </CloseButton>
                )}
              </React.Fragment>
            )}
          </LeftArea>
        }
        rightArea={
          <React.Fragment>
            {smbDownloadHeader && (
              <RightArea>
                <Media queries={{ small: `(max-width: ${breakpoints.wide})` }}>
                  {({ small }) =>
                    small ? (
                      <Button
                        appearance="tertiary"
                        id="mobile-menu-toggle"
                        onClick={() => setOpen((prevState) => !prevState)}
                        leadingIcon={open ? <IconX /> : <IconMenu />}
                      />
                    ) : (
                      <LocaleSelector direction={direction} />
                    )
                  }
                </Media>
              </RightArea>
            )}
            {!minimal && (
              <RightArea>
                <Media queries={{ small: `(max-width: ${breakpoints.wide})` }}>
                  {({ small }) =>
                    small ? (
                      <React.Fragment>
                        {path([0, 'text'], accountLinks) && !loggedIn && (
                          <SignInLink
                            id="sign-in-link"
                            appearance="tertiary"
                            loading={signinLoading}
                            direction={direction}
                            onClick={() => {
                              const destination = path([0, 'destination'], accountLinks);
                              setSigninLoading((prevSigninLoading) => !prevSigninLoading);
                              if (destination) {
                                if (destination.includes('http')) {
                                  window.location.href = destination;
                                } else {
                                  dispatch(push(destination));
                                }
                              }
                            }}
                          >
                            {path([0, 'text'], accountLinks)}
                          </SignInLink>
                        )}
                        <Button
                          appearance="tertiary"
                          id="mobile-menu-toggle"
                          onClick={() => setOpen(!open)}
                          leadingIcon={open ? <IconX /> : <IconMenu />}
                        />
                        {!isNative && loggedIn && (
                          <UserAvatar
                            userName={userName}
                            signOutLinkText={path([2, 'text'], accountLinks)}
                          />
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {!isNative && loggedIn && (
                          <UserAvatar
                            userName={userName}
                            signOutLinkText={path([2, 'text'], accountLinks)}
                          />
                        )}
                        {!loggedIn && <LocaleSelector direction={direction} />}
                        {path([0, 'text'], accountLinks) && !loggedIn && !isDataNotice && (
                          <SignInLink
                            id="sign-in-link"
                            appearance="tertiary"
                            loading={signinLoading}
                            direction={direction}
                            onClick={() => {
                              const destination = path([0, 'destination'], accountLinks);
                              setSigninLoading((prevSigninLoading) => !prevSigninLoading);
                              if (destination) {
                                if (destination.includes('http')) {
                                  window.location.href = destination;
                                } else {
                                  dispatch(push(destination));
                                }
                              }
                            }}
                          >
                            {path([0, 'text'], accountLinks)}
                          </SignInLink>
                        )}
                        {path([1, 'text'], accountLinks) && !loggedIn && !isDataNotice && (
                          <CreateAcctButton
                            direction={direction}
                            id="create-account-button"
                            loading={createAcctLoading}
                            onClick={() => {
                              const destination = path([1, 'destination'], accountLinks);
                              setCreateAcctLoading(
                                (prevCreateAcctLoading) => !prevCreateAcctLoading
                              );
                              if (destination) {
                                if (destination.includes('http')) {
                                  window.location.href = destination;
                                } else {
                                  dispatch(push(destination));
                                }
                              }
                            }}
                          >
                            {path([1, 'text'], accountLinks)}
                          </CreateAcctButton>
                        )}
                      </React.Fragment>
                    )
                  }
                </Media>
              </RightArea>
            )}
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

export default Navbar;
