import ActionTypes from '../constants/ActionTypes';
import { makeActionCreator } from '../utils/redux';

export const captureUDLParams = makeActionCreator(ActionTypes.CAPTURE_UDL_PARAMS);

export const saveUDLParams = makeActionCreator(ActionTypes.SAVE_UDL_PARAMS, 'params');

export const updateShortTitleMeta = makeActionCreator(ActionTypes.UPDATE_SHORT_TITLE_META, 'title');

export const encryptThenPageView = makeActionCreator(ActionTypes.ENCRYPT_THEN_PAGEVIEW, 'eventInfo', 'id');
