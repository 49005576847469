import ActionTypes from "../constants/ActionTypes";

//////// NOTIFICATIONS BANNER 
export function closeNotificationsBanner() {
    // THERE IS NO "open" FUNCTION BECAUSE NOTIFICATIONS IS TURNED ON IN app.js ONLY UPON ARRIVAL AT SITE
    return {
        type: ActionTypes.NOTIFICATIONS_BANNER_OFF,
    }
}

export function openNotificationsBanner() {
    return {
        type: ActionTypes.NOTIFICATIONS_BANNER_ON,
    }
}

//////// LOCALE 
export function openLocaleDropdown() {
    return {
        type: ActionTypes.OPEN_LOCALE_DROPDOWN,
    }
}

export function closeLocaleDropdown() {
    return {
        type: ActionTypes.CLOSE_LOCALE_DROPDOWN,
    }
}

///////// SEARCH
export function openSearchDropdown() {
    return {
        type: ActionTypes.OPEN_SEARCH_DROPDOWN,
    }
}

export function closeSearchDropdown() {
    return {
        type: ActionTypes.CLOSE_SEARCH_DROPDOWN,
    }
}

///////// SETTINGS
export function openSettingsDropdown() {
    return {
        type: ActionTypes.OPEN_SETTINGS_DROPDOWN,
    }
}

export function closeSettingsDropdown() {
    return {
        type: ActionTypes.CLOSE_SETTINGS_DROPDOWN,
    }
}

////// MOBILE MENU
export function openMobileMenu() {
    return {
        type: ActionTypes.OPEN_MOBILE_MENU,
    }
}

export function closeMobileMenu() {
    return {
        type: ActionTypes.CLOSE_MOBILE_MENU,
    }
}

////// DESKTOP OR MOBILE
export function desktopToMobile() {
    return {
        type: ActionTypes.TO_MOBILE,
    }
}

export function mobileToDesktop() {
    return {
        type: ActionTypes.TO_DESKTOP,
    }
}

export function toTablet() {
    return {
        type: ActionTypes.TO_TABLET,
    }
}

export function toNotTablet() {
    return {
        type: ActionTypes.TO_NOT_TABLET,
    }
}

export function dropdownsOff() {
    return {
        type: ActionTypes.DROPDOWNS_OFF,
    }
}
