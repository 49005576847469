import React from 'react';
import styled from 'styled-components';
import {
  path,
} from 'ramda';
import { stylesIf } from '../../../../utils/styleHelpers';

export const Page = styled.div`
  background: ${path(['theme', 'main', 'content', 'background'])};
  color: ${path(['theme', 'main', 'hp', 'fontColor'])};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 70px min-content;
  grid-auto-rows: min-content;
  grid-template-areas:
    "header header header"
    "none none none";
  justify-items: center;
  align-items: center;
  justify-content: stretch;
  align-content: stretch;
  grid-auto-flow: dense;
  gap: 32px;
  font-size: ${path(['theme', 'text', 'mobile', 'fontSize'])};
  padding: 12px;
  @media (min-width: 1200px) {
    padding: 30px;
    font-size: ${path(['theme', 'text', 'desktop', 'fontSize'])};
  }
`;

export const Header = styled.header`
  ${stylesIf('narrow', 'grid-column: 1 / span 2; grid-row: 1;', 'grid-area: header;')}
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: ${({ children }) => (
    React.Children.count(children) > 1
      ? 'space-between'
      : 'flex-start'
  )};
  h1 {
    font-size: ${path(['theme', 'text', 'mobile', 'h1'])};
  }
  @media (min-width: 1200px) {
    h1 {
      font-size: ${path(['theme', 'text', 'desktop', 'h1'])};
    }
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  > h1 {
    margin-bottom: 12px;
  }
  ${({ narrow }) => (!narrow ? 'max-width: 66%;' : '')}
`;

export const Main = styled.div`
  grid-column: 1 / span 3;
  @media (min-width: 1200px) {
    grid-column: 1 / span 2;
  }
  width: 100%;
`;

export const Side = styled.div`
  grid-column: 1 / span 3;
  @media (min-width: 1200px) {
    grid-column: 3;
  }
  width: 100%;
`;
