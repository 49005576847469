import styled from 'styled-components';

export const RaFTooltipContainer = styled.div`
  opacity: 0;
  transition: display 1s;
  border: 1px solid rgba(155, 155, 155, 0.5);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(155, 155, 155, 0.5);
  background: white;
  color: rgb(38, 50, 61);
  padding: 16px;
  min-width: 100px;  
  text-align: center;
  font-size: 14px;
  position: absolute;
  top: -60px;
  left: -34px;

  .arrow {
    width: 14px;
    height: 14px;
    background-color: white;
    border: solid rgba(155, 155, 155, 0.5);
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    position: absolute;
    left: 42px;
    bottom: -7px;
  }

  @keyframes showTooltip {
  0% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }  
  100% {
    opacity: 0;
  }
}
`;

export default RaFTooltipContainer;
