import { path } from 'ramda';
import { createSelector } from 'reselect';
import { selectLocaleParamsFromRoute } from '../../../../../../selectors/routing';
import selectCurrentStack from '../../../../store/modules/environment/selectors';

const selectSSProps = createSelector(
  selectLocaleParamsFromRoute,
  selectCurrentStack,
  path(['ucdeUser', 'externalIdMap', 'hpid']),
  (paramsFromRoute, stack, userID) => ({
    language: paramsFromRoute.language,
    country: paramsFromRoute.country,
    stack,
    userID,
  }),
);

export default selectSSProps;
