import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import ToggleSwitch from '../../shared-components/molecules/ToggleSwitch';
import { useSelector } from '../../../hooks/redux';
import { selectGlobalSharingData } from '../../../selectors/printAnywhere';
import { changePrintAnywhereMode } from '../../../actions/printAnywhereActions'

const AutoManual = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => (props.platform === 'desktop' ? '1rem 4.5rem 0' : '0 1.25rem 1.25rem')};
  @media (max-width: 640px) {
    padding: ${props => (props.platform === 'desktop' ? '1rem 4.5rem 0' : '0 1rem 1rem')};
  }
  @media (max-width: 340px) {
    padding: 0 0.5rem 0.5rem;
  }
  > * {
    flex: 1 1 auto;
  }
  p {
    margin: 0; 
  }
`;
const Succinct = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const TextSpan = styled.span`
  padding-right: .75rem;
`;
const ArrowSpan = styled.span`
  white-space: nowrap;
  display: inline-flex;
  align-items: flex-end;
  img {
    color: #0171AD;
    width: .75rem;
    height: .75rem;
    margin-left: .25rem;
  }
`;

const GlobalSharing = props => {
  const dispatch = useDispatch();
  const {
    platform,
  } = props;

  const {
    printAnywhereShareText,
    printAnywhereMoreText,
    sharingModeHasAuto,
    printerId,
  } = useSelector(selectGlobalSharingData);

  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(() => {
    setLoading(true);
    dispatch(changePrintAnywhereMode(
      printerId,
      sharingModeHasAuto ? 'manual' : 'auto',
      () => setLoading(false),
    ));
  }, [sharingModeHasAuto, printerId]);


  return (
    <AutoManual platform={platform}>
      <Succinct>
        {
          platform === 'desktop'
            ? (
              <h4>
                {printAnywhereShareText}
                {'  '}
              </h4>
            )
            : (
              <p>
                <TextSpan>
                  {printAnywhereShareText}
                </TextSpan>
                <ArrowSpan>
                  <a id="accessOptionsMoreAnchor" >
                    {printAnywhereMoreText}
                    <img
                      id="printAnywhereMore_arrow"
                      src="/assets/img/open_closed_arrow_blue.svg"
                      alt=""
                    />
                  </a>
                </ArrowSpan>
              </p>
            )
        }
        <ToggleSwitch
          loading={loading}
          platform={platform}
          handleChange={handleChange}
          checked={sharingModeHasAuto}
        />
      </Succinct>
    </AutoManual>
  );
};

GlobalSharing.defaultProps = {
  platform: 'web',
};

GlobalSharing.propTypes = {
  platform: PropTypes.oneOf(['desktop', 'mobile', 'web']),
};

export default GlobalSharing;
