/* eslint-disable no-use-before-define */
import {
  path,
  pathOr,
} from 'ramda';
import * as actions from './actions';
import { createReducer } from '../../../../../utils/redux';

const initialState = {};

function getDevices(state = {}, action) {
  const lastFetch = new Date();
  const isFetching = pathOr(false, ['payload', 'fetching'], action);
  const isError = pathOr(false, ['payload', 'error'], action);
  const devices = pathOr([], ['payload', 'data'], action);

  const result = {
    ...state,
    lastFetch,
    isFetching,
    isError,
    devices,
  };

  return result;
}

function getDeviceSuppliesInfo(state = {}, action) {
  const cloudId = pathOr('', ['payload', 'cloudId'], action);
  const supplies = path(['payload', 'supplies'], action);
  const isFetchingSupplies = pathOr(false, ['payload', 'fetchingSupplies'], action);
  const devices = state.devices.map(device => (
    device.cloudId === cloudId
      ? { ...device, supplies, isFetchingSupplies }
      : device
  ));

  const result = {
    ...state,
    devices,
  };

  return result;
}

const handlers = {
  [actions.getDevices]: getDevices,
  [actions.getDeviceSupplies]: getDeviceSuppliesInfo,
};

const deviceReducer = createReducer(initialState, handlers);

export default deviceReducer;
