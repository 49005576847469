import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  prop,
  head,
  length,
  not,
  path,
} from 'ramda';
import TweenLite from 'gsap/TweenLite';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../hooks';
import {
  selectToasts,
} from '../../selectors/application';
import { clearToastNotification } from '../../actions/application';

const toggleToast = bool => {
  const oneZero = bool ? 1 : 0;
  const tab = document.getElementById('toast-message-tab');
  const overlay = document.getElementById('toast-message-overlay');
  TweenLite.to(tab, 0.7, { y: `${(oneZero * 70) - 70}px` });
  TweenLite.to(overlay, 0.7, { autoAlpha: oneZero });
};

const ToastMessage = ({ triggerToast, message }) => {
  const [triggerCount, setTriggerCount] = useState(0);
  const toasts = useSelector(selectToasts);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!length(toasts) && triggerToast !== triggerCount) {
      toggleToast(true);

      setTimeout(() => {
        toggleToast(false);
      }, 2500);

      setTriggerCount(triggerCount + 1);
    }
    if (length(toasts)) {
      toggleToast(true);

      setTimeout(() => {
        toggleToast(false);
        dispatch(clearToastNotification(prop('message', head(toasts))));
      }, 2500);
    }
  }, [toasts, triggerToast, triggerCount]);

  const msg = useMemo(() => (
    length(toasts)
      ? path([0, 'message'], toasts)
      : message
  ), [toasts, message]);

  return (
    <Overlay id="toast-message-overlay">
      <ToastTab id="toast-message-tab">
        <CheckMark
          src={`/assets/print-anywhere/${(length(toasts) && not(path([0, 'success'], toasts))) ? 'red' : 'green'}_check.svg`}
          alt=""
        />
        {msg}
      </ToastTab>
    </Overlay>
  );
};

export default ToastMessage;


const Overlay = styled.div`
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background-color: #02020266;
`;
const ToastTab = styled.div`
    position: fixed;
    top: -70;
    left: 0;
    width: 100%;
    height: 68px;
    z-index: 205;
    background-color: #fff;
    border-radius: 0px 0px 16px 16px;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, .2);
    padding: 20px;
    font-family: 'hp-simplified','Helvetica Neue',Helvetica,Roboto,Arial,sans-serif;
`;
const CheckMark = styled.img`
    position: relative;
    top: 3px;
    margin-right: 7px;
`;
