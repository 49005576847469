import React, { useState } from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import {
  Button,
  IconChevronRight,
  IconChevronLeft,
} from '@veneer/core';
import {
  either,
  path,
} from 'ramda';
import Media from 'react-media';
import {
  breakpoints,
  stylesIf,
  paths,
  fontPacks,
} from '../../utils/styleHelpers';

const largeOpts = either(
  path(['breakPoints', 'large']),
  path(['theme', 'breakpoints', 'wide']),
);

const medOpts = either(
  path(['breakPoints', 'medium']),
  path(['theme', 'breakpoints', 'tablet']),
);

const smOpts = either(
  path(['breakPoints', 'small']),
  path(['theme', 'breakpoints', 'mobile']),
);

const FeatureDisplayContainer = styled.div`
  background: top center / calc(100vw - 3rem) 60% no-repeat url("../../assets/landing-page/features_bg_small.jpg");
  height: calc(100vh - 6.75rem);
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 3rem;
  ul, ol { padding-left: 0; }
  .slider {
    padding-bottom: 2.625rem;
  }
  @media screen and (min-width: ${smOpts}) {
    background: top center / calc(100vw - 3rem) 60% no-repeat url("../../assets/landing-page/features_bg_medium.jpg");
  }
  @media screen and (min-width: ${paths.breakTablet}) {
    background: top center / 100vw 60% no-repeat url("../../assets/landing-page/features_bg_medium.jpg");
    height: calc(100vh - 5rem);
    width: 100vw;
    position: relative;
    left: ${props => props.isRTLSupport ? '0.25 rem' : '-2.25rem'};
  }
  @media screen and (min-width: ${paths.breakWide}) {
    left: ${props => props.isRTLSupport ? '0.25 rem' : '-2.25rem'};
  }
  @media (min-width: ${largeOpts}) {
    background: top / 100vw 80% no-repeat url("../../assets/landing-page/features_bg_medium.jpg");
    margin-bottom: 0;
    text-align: left;
  }
`;

const FeatureContainer = styled.div`
  && {
    margin: 0 1rem;
    padding-top: 2rem;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 23.75rem) {
      padding-top: 1rem;
    }
    @media screen and (min-width: ${smOpts}) {
      margin: 0;
    }
    .slider-control-centerleft {
      top: 8.625rem !important;
      left: 1.375rem !important;
      @media screen and (min-width: ${smOpts}) {
        left: 10vw !important;
      }
    }
    .slider-control-centerright {
      top: 8.625rem !important;
      right: 1.375rem !important;
      @media screen and (min-width: ${smOpts}) {
        right: 10vw !important;
      }
    }
    .slider-control-bottomcenter {
        bottom: auto !important;
      }
    }
    p {
      margin-top: 0
    }
    @media (min-width: ${medOpts}) {
      height: 95%;
    }
    @media (min-width: ${largeOpts}) {
      display: flex;
      margin: 0 6.25rem;
      max-width: calc(100vw - 12.5rem);
      padding-top: 3.5rem;
      height: 90%;
      flex-flow: column wrap;
    }
    @media screen and (min-width: ${paths.breakImmense}) {
      max-width: calc(120rem - 12.5rem);
      margin-left: calc((calc(100vw - 120rem) / 2) + 6.25rem);
    }
  }
`;

const Summary = styled.div`
  color: white;
  margin: 0 auto;
  @media screen and (min-width: ${smOpts}) {
    margin: 0 auto;
    max-width: 22.75rem;
  }
  @media screen and (min-width: ${medOpts}) {
    max-width: 87.5vw;
  }
  @media screen and (min-width: ${largeOpts}) {
    order: 2;
    max-width: calc(100vw - 12.5rem - 27rem);
    max-height: none;
    flex: none;
    margin: 0;
    flex-basis: 17.5%;
    align-self: ${props => props.isRTLSupport && 'flex-start'};
    margin-right: ${props => props.isRTLSupport && '3rem'};
  }
  div {
    width: 2.875rem;
    margin: 0 auto;
    @media screen and (min-width: ${smOpts}) {
      margin: 0 auto 0 0;
    }
    @media screen and (min-width: ${largeOpts}) {
      width: 3.875rem;
    }
  }
  img {
    width: 100%;
  }
  h2 {
    color: white;
    margin: 0 0 1rem;
    @media screen and (max-width: 23.75rem) {
      margin: 0 0 0.5rem;
    }
    @media screen and (min-width: ${medOpts}) {
      margin: 0 0 2rem 0;
    }
    @media screen and (min-width: ${largeOpts}) {
      margin: 0 0 1rem 0;
    }
    ${fontPacks.titleRegularLight}
  }
`;
const FeatureIllustration = styled.div`
  flex: 1 1 auto;
  width: 16.5rem;
  margin-right: ${props => !props.isRTLSupport && '4rem'};
  order: 1;

  position: relative;
  img {
    z-index: 2;
    height: 100%;
    width: auto;
  }
  @media screen and (min-width: ${largeOpts}) {
    height: calc(100% + 2.5rem);
    width: auto;
    position: relative;
    margin-top: -1.5rem;
    flex-basis: 100%;
    align-self: ${props => props.isRTLSupport && 'flex-start'};
  }
`;
const FeatureNumber = styled.div`
  flex: 0 0 3.125rem;
  margin-right: 1.1875rem;
  font-size: 1.5rem;
  display: flex;
  color: ${stylesIf('active', '#0171AD', '#FFF')};
  height: 3.125rem;
  width: 3.125rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0.125rem solid white;
  background-color: ${stylesIf('active', '#FFF', 'transparent')};
  @media (min-width: 1200px) {
    flex: 0 0 4.375rem;
    margin-right: 2.25rem;
    font-size: 2.125rem;
    height: 4.375rem;
    width: 4.375rem;
  }
`;
const FeatureDescription = styled.span`
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;
const FeatureDescriptionCopy = styled.div`
  max-width: 35vw;
  > h3, > span, > span > p {
    color: ${paths.colorsWhite};
  }
  > h3 {
    margin-bottom: 0.25rem;
    ${fontPacks.subtitleLarge}
  }
  > span, > span > p {
    margin-bottom: 0;
    ${fontPacks.bodyLarge}
  }
  @media screen and (min-width: ${paths.breakWide}) {
    max-width: 50rem;
  }
  margin-right: ${props => props.isRTLSupport && '2%'};
  text-align: ${props => props.isRTLSupport && 'right'};
}
`;
const ImageWrap = styled.div`
  margin: 0 auto;
  flex: 2 1 60%;
  height: auto;
  max-height: 60%;
  @media screen and (min-width: 23.25rem) {
    img {
      margin-bottom: 1rem;
    }
  }
  img {
    width: auto;
    height: 100%;
  }
  @media (min-width: ${either(
    path(['breakpoints', 'tiny']),
    path(['theme', 'breakpoints', 'mobile']),
  )}) {
    flex: 1 1 auto;
  }
  @media screen and (min-width: ${paths.breakTablet}) {
    margin-bottom: 1.5rem;
    height: 50vh;
  }
`;
const DescriptionWrap = styled.div`
  margin-top: 1.5rem;
  margin-left : ${props => props.isRTLSupport ? '1.5rem' : 0};
  margin-right : ${props => props.isRTLSupport ? '1.5rem': 0};
  flex: 1 2 40%;
  height: auto;
  max-height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media screen and (max-width: 23.75rem) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0.5rem;
  }
  > h4, > h3 {
    ${fontPacks.subtitleLarge}
  }
  > span, > span > p {
    ${fontPacks.bodyLarge}
  }
  @media screen and (min-width: ${smOpts}) {
    padding: 0;
  }
  @media screen and (min-width: ${largeOpts}) {
    max-width: 50%;
  }
`;
const FeaturesList = styled.div`
  order: 3;
  display: flex;
  flex-direction: column;
  max-width: 75rem;
  @media screen and (min-width: ${largeOpts}) {
    max-width: calc(100vw - 12.5rem - 27rem);
    flex-basis: 65%;
  }
`;

const PagingButton = styled(Button)`

`;

const SlideContainer = styled.div`
  height: calc(((100vh - 5rem) * 0.95) - 3.5rem);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  text-align: center;
  @media screen and (min-width: ${smOpts}) {
    height: calc(((100vh - 5rem) * 0.95) - 7rem);
    width: calc(100vw - 5rem);

  }
  @media screen and (min-width: ${paths.breakWide}) {
    width: calc(100vw - 12.5rem);
  }
`;

const PagingDot = styled.div`
  @media screen and (max-width: 375px) {
    display: none;
  }
  cursor: pointer;
  display: inline-block;
  background-color: #BCBCBC;
  margin: 0 0.5rem;
  box-shadow: none;
  opacity: 1;
  height: 2rem;
  width: 2rem;
  max-height: 0.5rem;
  max-width: 0.5rem;
  border-radius: 1rem;
  &.active {
    max-width: 1rem;
    max-height: 1rem;
    background-color: #0171AD;
    position: relative;
    top: 0.25rem;
  }
  transition: all .25s ease-in;
`;

const FeatureDisplay = props => {
  const {
    headline,
    headlineIconSrc,
    breakPoints,
    features,
    isRTLSupport,
  } = props;
  const [activeFeature, setActiveFeature] = useState(0);

  return (
    <FeatureDisplayContainer breakPoints={breakPoints} isRTLSupport={isRTLSupport}>
      <FeatureContainer>
        <Summary isRTLSupport={isRTLSupport}>
          {
            headlineIconSrc && (
              <div>
                <img src={headlineIconSrc} alt="" />
              </div>
            )
          }
          <h2>
            {headline}
          </h2>
        </Summary>
        <Media queries={{ xlg: `(min-width: ${breakpoints.wide})` }}>
          {
            ({ xlg }) => (
              xlg
                ? (
                  <React.Fragment>
                    <FeatureIllustration isRTLSupport={isRTLSupport}>
                      {
                        Array.isArray(path([activeFeature, 'src'], features))
                          ? (
                            <picture>
                              <source
                                srcSet={`${path([activeFeature, 'src', 0], features)}, ${path([activeFeature, 'src', 1], features)} 2x`}
                                size="100vw"
                                alt={path([activeFeature, 'alt'], features)}
                              />
                              <img
                                src={path([activeFeature, 'src', 0], features)}
                                alt={path([activeFeature, 'alt'], features)}
                              />
                            </picture>
                          )
                          : (
                            <img
                              src={path([activeFeature, 'src'], features)}
                              alt={path([activeFeature, 'alt'], features)}
                            />
                          )
                      }
                    </FeatureIllustration>
                    <FeaturesList>
                      {
                        features.map(({ alt, header, body }, idx) => (
                          <FeatureDescription
                            key={header}
                            onClick={() => setActiveFeature(idx)}
                            id={`mobilefax_feature_number_${idx}`}
                            data-tracking={`: ${alt}`}
                            tabIndex="0"
                          >
                            <FeatureNumber
                              id={`mobilefax_feature_${idx}`}
                              active={activeFeature === idx}
                            >
                              {idx + 1}
                            </FeatureNumber>
                            <FeatureDescriptionCopy isRTLSupport={isRTLSupport}>
                              {
                                header && (
                                  <h3 dangerouslySetInnerHTML={{ __html: header }} />
                                )
                              }
                            <span dangerouslySetInnerHTML={{ __html: body }} />
                            </FeatureDescriptionCopy>
                          </FeatureDescription>
                        ))
                      }
                    </FeaturesList>
                  </React.Fragment>
                )
                : (
                  <Carousel
                    wrapAround
                    renderCenterRightControls={({ nextSlide }) => (
                      <PagingButton
                        leadingIcon={<IconChevronRight filled />}
                        onClick={nextSlide}
                        appearance="tertiary"
                        negative
                        id="next-slide-button"
                      />
                    )}
                    renderCenterLeftControls={({ previousSlide }) => (
                      <PagingButton
                        leadingIcon={<IconChevronLeft filled />}
                        onClick={previousSlide}
                        appearance="tertiary"
                        negative
                        id="next-slide-button"
                      />
                    )}
                    renderBottomCenterControls={({ goToSlide, currentSlide }) => {
                      return features.map(({ header, body }, index) => (
                        <PagingDot
                          key={header || body}
                          onClick={() => goToSlide(index)}
                          className={index === currentSlide ? 'active' : ''}
                          id={`paging-dot-${index}`}
                        />
                      ));
                    }}
                  >
                    {
                      features.map(({ header, body, src, alt = '' }) => (
                        <SlideContainer key={header || body}>
                          <ImageWrap>
                            {
                              Array.isArray(src)
                                ? (
                                  <picture>
                                    <source
                                      srcSet={`${src[0]}, ${src[1]} 2x`}
                                      size="100vw"
                                      alt={alt}
                                    />
                                    <img
                                      src={src[0]}
                                      alt={alt}
                                    />
                                  </picture>
                                )
                                : (
                                  <img
                                    src={src}
                                    alt={alt}
                                  />
                                )
                            }
                          </ImageWrap>
                          <DescriptionWrap isRTLSupport={isRTLSupport}>
                            {
                              header && (
                                <h3 dangerouslySetInnerHTML={{ __html: header }} />
                              )
                            }

                            <span dangerouslySetInnerHTML={{ __html: body }} />
                          </DescriptionWrap>
                        </SlideContainer>
                      ))
                    }
                  </Carousel>
                )
            )
          }
        </Media>
      </FeatureContainer>

    </FeatureDisplayContainer>
  );
};

export default FeatureDisplay;
