/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Header } from '@veneer/core';

export const StyledHeader = styled(Header)`
  && {
    padding-left: 0;
    padding-top: 8px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EBEBEB;
    z-index: 1000;
    position: fixed;
    top: 0;
  }
`;
