import React from 'react';
import { prop } from 'ramda';
import styled from 'styled-components';
import { Button } from '@veneer/core';
import { Link } from '../../routing';
import { paths, fontPacks } from '../../../utils/styleHelpers';
import { RTL } from '../../../constants/i18n';
import { BADGE_ID } from '../../Dashboard/utils/constants';

const SlideContainer = styled.div`
  position: relative;
  height: calc(100vh - 4rem);
  overflow: hidden;
  > img,
  > picture > img {
    width: 100vw;
    height: auto;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: none;
    }
  }
`;

const SlideContent = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  padding: 0 1.5rem;
  text-align: center;
  background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)) 0% 0%
  no-repeat padding-box;
  font-family: ${paths.fontsFamily};
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: url(${prop('smImg')});
    background-repeat: no-repeat;
    background-origin: border-box;
    background-clip: border-box;
    background-position: center;
    background-size: cover;
    @media (max-width: ${paths.breakWide}) {
      background-image: url(${prop('mdImg')});
    }
    @media (max-width: ${paths.breakImmense}) {
      background-image: url(${prop('lgImg')});
    }
  }
  @media (min-width: ${paths.venTablet}) {
    text-align: left;
    padding: 0 3.75rem 4.5rem 3.75rem;
    align-items: flex-start;
    justify-content: flex-end;
  }
  @media (min-width: 87rem) {
    padding-bottom: 0;
  }
  @media (min-width: ${paths.breakImmense}) {
    padding: 0 calc((100vw - 116rem) / 2);
  }
`;

const IEGradient = styled.span`
  z-index: 4;
  display: none;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: inline;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)) 0% 0%
      no-repeat padding-box;
  }
`;

const Headline = styled.h3`
  && {
    ${fontPacks.titleMediumRegular}
    ${ props => props?.direction === RTL ? `text-align:right` : `text-align:left`};
    z-index: 5;
    color: ${paths.colorsWhite};
    max-width: 100%;
    @media (min-width: ${paths.venTablet}) {
      width: 780px;
    }
  }
`;

const Body = styled.div`
  && {
    ${fontPacks.subtitleLarge}
    z-index: 5;
    color: ${paths.colorsWhite};
    margin-bottom: 1rem;
    max-width: 100%;
    > p {
      ${fontPacks.subtitleLarge}
      color: inherit;
      margin-bottom: 0;
    }
    @media (min-width: ${paths.venTablet}) {
      width: 780px;
      margin-bottom: 1rem;
    }
    ${ props => props?.direction === RTL ? `text-align:right` : `text-align:left`};
  }
`;

const BadgeGroup = styled.div`
  display: flex;
  z-index: 5;
  flex-direction: column;
  @media (min-width: ${paths.venTablet}) {
    flex-direction: row;
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
  }
`;

const Badge = styled(Link)`
  margin-bottom: 10px;
  z-index: 5;
  > img {
    width: 132px;
    height: auto;
  }
  @media (min-width: ${paths.venTablet}) {
    margin-bottom: 0;
    margin-right: 16px;
    > img {
      height: 42px;
      width: auto;
    }
  }
`;

const ButtonWrap = styled(Link)`
  z-index: 5;
  @media (min-width: ${paths.venTablet}) {
    margin-bottom: 2.5rem;
  }
`;

const HeroCarouselSlide = (slide) => {
  const {
    carousel_panel,
    direction,
    imageOnload,
    badges,
    instantInkPrice
  } = slide;

  const {
    headline,
    body,
    button_text,
    button_destination,
    mobile,
    mobile_2x,
    tablet,
    tablet_2x,
    widescreen,
    widescreen_2x
  } = carousel_panel;

  const {
    title: mobileTitle,
    url: mobileUrl
  } = mobile;

  const {
    title: mobile2xTitle,
    url: mobile2xUrl
  } = mobile_2x;

  const {
    title: tabletTitle,
    url: tabletUrl
  } = tablet;

  const {
    title: tablet2xTitle,
    url: tablet2xUrl
  } = tablet_2x;

  const {
    title: widescreenTitle,
    url: widescreenUrl
  } = widescreen;

  const {
    title: widescreen2xTitle,
    url: widescreen2xUrl
  } = widescreen_2x;

  let newBody = body.replace("+[instantInkPrice]", instantInkPrice);

  return (
    <SlideContainer>
      <picture>
        <source
          srcSet={`${widescreenUrl}, ${widescreen2xUrl} 2x`}
          sizes="(min-width: 1920px) 100vw"
          media="(min-width: 1920px)"
          alt={widescreenTitle}
        />
        <source
          srcSet={`${tabletUrl}, ${tablet2xUrl} 2x`}
          sizes="(min-width: 1280px) 100vw"
          media="(min-width: 1280px) and (max-width: 1919px)"
          alt={tabletTitle}
        />
        <source srcSet={`${mobileUrl}, ${mobile2xUrl} 2x`} sizes="100vw" alt={mobileTitle} />
        <img src={tabletUrl} alt={tabletTitle} onLoad={imageOnload} />
      </picture>
      <SlideContent smImg={mobile2xUrl} mdImg={tablet2xUrl} lgImg={widescreenUrl}>
        <Headline direction={direction}>{headline}</Headline>

        {newBody && (
          <Body direction={direction} dangerouslySetInnerHTML={{ __html: newBody }} />
        )}

        {badges && (
          <BadgeGroup>
            {badges.filter((badge) => badge.app_badge.app_badge_id !== BADGE_ID).map((badge) => {
              const { app_badge } = badge;

              const {
                app_badge_graphic,
                app_badge_id: appBadgeId,
                app_badge_link: appBadgeLink
              } = app_badge;

              const {
                title: appBadgeGraphicTitle,
                url: appBadgeGraphicUrl,
              } = app_badge_graphic;

              return (
                <Badge id={appBadgeId} to={appBadgeLink} key={appBadgeId}>
                  <img src={appBadgeGraphicUrl} alt={appBadgeGraphicTitle} />
                </Badge>
              );
            })}
          </BadgeGroup>
        )}
        {button_text && (
          <ButtonWrap to={button_destination} id={button_text.toLowerCase().replace(' ', '-')}>
            <Button>{button_text}</Button>
          </ButtonWrap>
        )}
        <IEGradient />
      </SlideContent>
    </SlideContainer>
  );
};

export default HeroCarouselSlide;
