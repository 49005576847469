import React, { Component } from 'react';
import { path, propOr, prop } from 'ramda';
import styled, { ThemeProvider } from 'styled-components';
import AccessOptions from './AccessOptions';
import { MainContent } from '../shared-components/library-css/LibraryCss';
import bind from '../bind';
import MyPrinterImage from '../my-printer/MyPrinterImage';
import PrinterAddressSettings from './PrinterAddressSettings';
import ToastMessage from './ToastMessage';
import NewAccordion from '../shared-components/organisms/NewAccordion';



class PrintAnywhereAppView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeOption: 0,
      groupControl: { identifier: '', group: '' },
      triggerToast: 0,
    };
    this.handleChoice = this.handleChoice.bind(this);
    this.isSummary = true;
    this.isManageEmail = false;
    this.isPending = true;
    this.doneOnce = false;
    this.getQueryStringParamsObject = this.getQueryStringParamsObject.bind(this);
    this.QSObject = this.getQueryStringParamsObject();
    this.acceptedPlatforms = ['web', 'android', 'desktop', 'ios'];
    this.platform = props.match.params.platform ? this.acceptedPlatforms.indexOf(props.match.params.platform.toLowerCase()) !== -1 ? props.match.params.platform.toLowerCase() : 'android' : 'web';
    this.accessList_snapshot = 'init';
    this.accessList_snapshot_length = 'init';
    this.showModal = false;
    this.toastMessage = '';
    this.triggerAnimateCancel = false;
  }


  componentDidMount() {
    const {
      router,
      locales,
      getMyPrintAnywherePrinter,
    } = this.props;
    getMyPrintAnywherePrinter(
      prop('ownership_id', this.QSObject),
      path(['location', 'query', 'generation'], router),
      propOr('', 'currentLocale', locales),
    );
  }


  componentDidUpdate() {
    if (!this.isPending && !this.doneOnce) {
      this.doneOnce = true;
    }

    if (!!this.props.printAnywhere && this.props.printAnywhere.isComplete && !!this.props.printAnywhere.data && !!this.props.printAnywhere.data.WPPData && !!this.props.printAnywhere.data.WPPData.accessList) {
      if (this.accessList_snapshot !== 'init' && this.accessList_snapshot !== JSON.stringify(this.props.printAnywhere.data.WPPData.accessList)) {
        if (this.accessList_snapshot_length !== 'init' && this.props.printAnywhere.data.WPPData.accessList.length < this.accessList_snapshot_length) {
          this.toastMessage = 'User Deleted';
        } else {
          this.toastMessage = 'User Access Updated';
        }
        this.setState({ triggerToast: this.state.triggerToast + 1 });
      } else {
      }
      this.accessList_snapshot = JSON.stringify(this.props.printAnywhere.data.WPPData.accessList);
      this.accessList_snapshot_length = this.props.printAnywhere.data.WPPData.accessList.length;
    }
  }

  getQueryStringParamsObject() {
    let returnObj = {
      error: 'Something went wrong parsing the querystring. Perhaps it was not properly formed, or was absent. A default has been inserted: 6ba9680b-4b5e-43ea-89ea-b0a041b1cad8',
      ownership_id: '6ba9680b-4b5e-43ea-89ea-b0a041b1cad8',
    };
    const rawQS = document.location.search;
    const extractedParams = (typeof rawQS === 'string' && rawQS !== '' && rawQS.indexOf('hpsmartParams=') !== -1) ? rawQS.split('hpsmartParams=')[1].split('&')[0] : null;
    if (extractedParams) {
      const qsJSON = decodeURIComponent(extractedParams);
      returnObj = JSON.parse(qsJSON);
    }
    return returnObj;
  }

  handleChoice(value) {
    const myPrinter = this.props.myPrinter.data.WPPData;
    this.props.updateMyPrinterAccessOption(myPrinter.ownershipId, myPrinter.accessOptions[value].Key);
  }

  trackingHookFn(ID) {
    document.getElementById(ID).click();
  }

  groupFn = o => {
    this.setState({
      groupControl: { identifier: o.myID, group: o.groupID },
    });
  };

  callbackFn = (isOpen, thisID) => {
    let element = '';
    if (thisID.indexOf('manageEmail') > 0) {
      element = '#AccordionTitle_manageEmail_arrow';
      this.trackingHookFn(`manage_printers_email_accordion_${isOpen ? 'open' : 'close'}`);
    } else if (thisID.indexOf('AccessOptions') > 0) {
      element = '#AccordionTitle_PrintAnywhere_arrow';
      this.trackingHookFn(`manage_print_anywhere_accordion_${isOpen ? 'open' : 'close'}`);
    }
    const rotate = isOpen ? 180 : 0;
    TweenLite.to(element, 0.5, { rotationX: rotate });
  };

  getLoadingBlock = () => (
    <PageLoader>
      <PageSpinner
        id="printAnywhere_page_spinner" src="/assets/print-anywhere/spinner.svg"
        alt=""
      />
    </PageLoader>
  )

  handleCopyCancel = () => {
    this.showModal = false;
    this.forceUpdate();
  }

  triggerCopyCancel = () => {
    this.triggerAnimateCancel = true;
    this.forceUpdate();
  }

  showCopyModal = () => {
    this.showModal = true;
    this.forceUpdate();
  }

  render() {
    const myPrinter = this.props.printAnywhere.data.WPPData;
    const { csData } = this.props.printAnywhere.data;
    this.isPending = this.props.printAnywhere.isFetching && !this.props.printAnywhere.isComplete || this.props.printAnywhere.isError;
    if (this.isPending) {
      return this.getLoadingBlock();
    }
    if (!csData || !myPrinter) { return null; }
    return (
      <ThemeProvider theme={PrintAnywhereTheme}>
        <AugmentedMainContent currentPlatform={this.platform}>
          <ToastMessage triggerToast={this.state.triggerToast} message={this.toastMessage} />
          {
            this.platform !== 'desktop'
            && (
              <PrinterHero>
                <VisualSummary printer={myPrinter} />
                <PrinterDetailsTitle printer={myPrinter} />
              </PrinterHero>
            )
          }
          <a name="tracking-hook" id="manage_print_anywhere_accordion_open" />
          <a name="tracking-hook" id="manage_print_anywhere_accordion_close" />
          <a name="tracking-hook" id="manage_printers_email_accordion_open" />
          <a name="tracking-hook" id="manage_printers_email_accordion_close" />
          <LayoutContainer platform={this.platform}>
            <NewAccordion
              noCollapse={this.platform === 'desktop'}
              title={csData.print_anywhere_title_text}
              preamble={csData.print_anywhere_summary_text}
              platform={this.platform}
            >
              <a id="printAnywhere_Summary" />
              <a id="printAnywhere_Options" />
              <AccessOptions
                trackingHook={this.trackingHookFn}
                printer={this.props.printer}
                currentOption={this.state.activeOption}
                handleChoice={this.handleChoice}
                active={this.props.active}
                platform={this.platform}
              />
            </NewAccordion>
            <NewAccordion
              noCollapse={this.platform === 'desktop'}
              platform={this.platform}
              title={csData.print_anywhere_email_title_text}
              preamble={csData.print_anywhere_email_summary_text}
            >
              <PrinterAddressSettings
                platform={this.platform}
                trackingHandler={this.trackingHookFn}
              />
            </NewAccordion>
          </LayoutContainer>
        </AugmentedMainContent>
      </ThemeProvider>
    );
  }
}

export default bind(PrintAnywhereAppView);

const PrintAnywhereTheme = {
  hpBlue: '#0171AD', // Web accessible 'brand blue' from the HP.com design system
  textColor: '#4e4e4e',
  hpSimplifiedLight: "font-family: 'hp-simplified-light', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';",
  hpSimplifiedNormal: "font-family: 'hp-simplified', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';",
};
const PageLoader = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
`;
const AugmentedMainContent = styled(MainContent)`
`;
const PageSpinner = styled.img`
display: inline-block;
margin-top: calc(50% + 80px);
  width: 35px;
  height: 35px;
  @keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }
  animation: spin .5s linear infinite;
`;
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props => (props.platform !== 'desktop')
    && `@media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
      &:first-child {
        margin-right: .625rem;
      }
      &:last-child {
        margin-left: .625rem;
      }
    }`
  }
  @media (max-width: 640px) {
    padding: 1rem;
  }
  @media (max-width: 320px) {
    padding: 0.5rem;
  }
`;
const PrinterHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`;
const Summary = styled.p`
  margin: 0 1.25rem 1.625rem;
`;
const VisualSummary = ({ printer }) => (
  <div>
    <MyPrinterImage
      mainImageSmall={printer.printerImageUrl300N245}
      mainImageMedium={printer.printerImageUrl300N245}
      mainImageLarge={printer.printerImageUrl300N245}
      mainImageAlt={printer.printerName}
      maxWidth="10rem"
    />
  </div>
);

const PrinterDetailsTitle = ({ printer }) => (
  <PrinterTitle>
    {printer.printerName || printer.printerModel || 'My HP Printer'}
  </PrinterTitle>
);

const PrinterTitle = styled.h3`
  text-align: center;
  margin: 0 1.25rem;
`;
