import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@veneer/core';
import NovelliPageVideo from './VideoBanner';
import { HeroContainer, NovelliDesc, NovelliSubDes, ButtonCenter } from './NovelliStyles';
import Navbar from '../revised/Global/Navbar';
import Footer from '../revised/Global/Footer';
import { useSelector } from '../../hooks';
import { selectPhotoPaperContentStackData } from '../../selectors/craft';

const PhotoPaper = (props) => {
  const { geoCountry } = props;
  const contentStack = useSelector(selectPhotoPaperContentStackData)?.data?.entries[0];
  const [contentStackData, setContentStackData] = useState(null);
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    setContentStackData(contentStack);
  }, [contentStack]);

  useEffect(() => {
    const layoutWidth = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', layoutWidth);
    return () => {
      window.removeEventListener('resize', layoutWidth);
    };
  }, [window]);
  const videoRef = useRef(null);

  if (contentStackData) {
    const {
      video_url,
      feature_list,
      landing_heading,
      more_paper_button_destination,
      more_paper_button_text,
      photo_print_help_text,
      tile_image
    } = contentStackData;

    const hostUrl = window.location.hostname;
    const port = window.location.port;
    const baseUrl = port ? `https://${hostUrl}:${port}/video` : `https://${hostUrl}/video`;

    let poster_url = contentStackData.poster_url;
    if (windowWidth > 1600) {
      poster_url = contentStackData.widescreen_2x_poster_url;
    } else if (windowWidth > 1280 && windowWidth <= 1600) {
      poster_url = contentStackData.widescreen_poster_url;
    } else if (windowWidth > 720 && windowWidth <= 1280) {
      poster_url = contentStackData.tablet_2x_poster_url;
    } else if (windowWidth > 540 && windowWidth <= 720) {
      poster_url = contentStackData.tablet_poster_url;
    } else if (windowWidth > 375 && windowWidth <= 540) {
      poster_url = contentStackData.mobile_2x_poster_url;
    } else if (windowWidth <= 375) {
      poster_url = contentStackData.mobile_poster_url;
    } else {
      poster_url = contentStackData.poster_url;
    }
    return (
      <div>
        <Navbar geoCountry={geoCountry} />
        <HeroContainer>
          <NovelliPageVideo
            src={baseUrl + video_url}
            poster={baseUrl + poster_url}
            videoRef={videoRef}
          />
        </HeroContainer>
        <NovelliDesc>
          <h2>{landing_heading}</h2>
          <div dangerouslySetInnerHTML={{ __html: feature_list }}></div>
          <NovelliSubDes>
            <img alt={tile_image.title} src={tile_image.url} />
            <p>{photo_print_help_text}</p>
          </NovelliSubDes>
          <ButtonCenter>
            <Button
              onClick={async () => {
                window.location.assign(more_paper_button_destination);
              }}
            >
              {more_paper_button_text}
            </Button>
          </ButtonCenter>
        </NovelliDesc>
        <Footer />
      </div>
    );
  }
  return null;
};
export default PhotoPaper;