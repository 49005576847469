import React from 'react';
import { prop } from 'ramda';
import { useSelector } from '../../hooks';
import {
  selectContentStackDataByResource,
} from '../../selectors/craft';
import {
  StyledContainer,
  StyledContent,
  StyledTitle,
  StyledDate,
  StyledIndex,
  StyledSections,
  StyledListItem
} from './Terms.styles.js';
import { RTL_LANGUAGES } from '../../constants/i18n';
import { getLocale } from '../../utils/globals';
import { TOU_RESOURCE_NAME } from '../../constants/contentStackResources';

const slugify = (str = '') => str.toLowerCase().replace(/ +/g, '-');

const Terms = () => {
  const { terms_of_use } = useSelector(selectContentStackDataByResource, [TOU_RESOURCE_NAME]);
  const tou = terms_of_use?.data?.entry;
  
  const { language } = getLocale();
  const isRTL = RTL_LANGUAGES.includes(language);

  if (tou && !tou.isError) {
    const { indexed_sections, title, last_updated, intro_section, index_title, copyright } = tou;

    return (
      <StyledContainer isRTL={isRTL}>
        <StyledContent>
          <StyledTitle>{title}</StyledTitle>
          <StyledDate>{last_updated}</StyledDate>
          <div dangerouslySetInnerHTML={{ __html: intro_section }} />
          <strong>{index_title}</strong>
          <StyledIndex isRTL={isRTL}>
            {indexed_sections.map((section) => {
              const { section_block } = section;
              const { section_title, _metadata } = section_block;
              const { uid } = _metadata;

              return (
                <li key={`title${slugify(uid)}`}>
                  <a href={`#${slugify(section_title)}`}>{section_title}</a>
                </li>
              );
            })}
          </StyledIndex>
          <StyledSections isRTL={isRTL}>
            {indexed_sections.map((section) => {
              const { section_block } = section;
              const { section_title, section_content, _metadata } = section_block;
              const { uid } = _metadata;

              return (
                <StyledListItem
                  key={`content${slugify(uid)}`}
                  id={slugify(section_title)}
                  dangerouslySetInnerHTML={{ __html: section_content }}
                />
              );
            })}
          </StyledSections>
          <strong>{copyright}</strong>
        </StyledContent>
      </StyledContainer>
    );
  }

  if (prop('isError', tou)) {
    return <p>{prop('errorMsg', tou)}</p>;
  } // Case: We're loading content
  return <div />;
};

export default Terms;
