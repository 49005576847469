import { useSelector as useReduxSelector } from 'react-redux';

export const useSelector = (selector, props) => {
    return useReduxSelector(state => selector(state, props));
}

export const useSelectorFormattedValue = (selector, props) => {
    const returnValue = useReduxSelector(state => selector(state, props));
    return returnValue?.data?.entries[0];
}
