import ActionTypes from "../constants/ActionTypes";
import { LIBRARY_LANDING } from "../constants/contentStackResources";
import { getContentStackData } from "../services/craft";

/**
 * This function uses axios to get library landing data.
 *
 * Since axios returns promises, we can dispatch our start event and
 * update it to complete or complete with error whe the promise returns.
 *
 * @returns {function(*)}
 */
export function getLibraryLanding(currentLocale = "") {
  let start = () => ({
      type: ActionTypes.START_LIBRARYLANDING_FETCH
    }),
    complete = (libraryLandingData) => ({
      type: ActionTypes.COMPLETE_LIBRARYLANDING_FETCH,
      libraryLandingData
    }),
    error = err => ({
      type: ActionTypes.COMPLETE_LIBRARYLANDING_FETCH_WITH_ERROR,
      err
    });
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(start());
      getContentStackData(LIBRARY_LANDING, currentLocale)
        .then(res => {
          dispatch(complete(res.data.entries[0]));
          return resolve(res.data.entries[0]);
        })
        .catch(err => {
          dispatch(error(err));
          reject(err);
        });
    });
  };
}