import axios from 'axios';
import { getCookie } from '../lib/manageCookies';
import ActionTypes from '../constants/ActionTypes';
import AreaTypes from '../constants/AreaTypes';
import { getApiPathWithLocale } from '../lib/urlUtils';
import Config from '../config/Config';
import discernCarePackScenario from '../lib/discernCarePackScenario';
import { makeApiCall } from './application';
import { makeActionCreator } from '../utils/redux';
import * as SERVICES from '../services/myPrinter';
import {
  EPRINT_ACCESS_SETTINGS,
  EPRINT_ADDRESS_SETTINGS,
  EPRINT_MISCELLANEOUS,
  EPRINT_PREFERENCE_SETTINGS,
  MY_PRINTER,
  MY_PRINTER_REMOVE_PRINTER,
  MY_PRINTER_SUBSCRIPTION,
  MY_PRINTER_UNREGISTERED_PRINTER
} from '../constants/contentStackResources';
import { getContentStackData } from '../services/craft';

export const printerAccessOptionsChanged = makeActionCreator(ActionTypes.UPDATE_MYPRINTER_ACCESS_OPTION, 'payload');

export const updateAccessOption = ({ ownershipId, printerId, generation, accessOption, callback }) => makeApiCall({
  asyncFn: SERVICES.updateAccessOption,
  args: {
    ownershipId,
    printerId,
    generation,
    accessOption,
  },
  callback,
  successActionCreator: printerAccessOptionsChanged,
});

// // DISCOVER PURPOUSE:
export function clearUpdateMyPrinterAddressStatus() {
  return {
    type: ActionTypes.CLEAR_UPDATE_MYPRINTER_ADDRESS_STATUS,
  };
}

export function updateMyPrinterWithAlreadyFetchedData(printerData) {
  return {
    type: ActionTypes.UPDATE_MYPRINTER_WITH_ALREADY_FETCHED_DATA,
    printerData,
  }
}

// TODO: This should eventually be a model class
const processMyPrinterData = (
  printer_detail,
  eprint_access_settings,
  eprint_preferences_settings,
  eprint_miscellaneous,
  printer_removal,
  printer_unregistered,
  printer_subscription,
  eprint_address_settings,
  wpp = {},
  cp = {}
) => {
  const carepack = { scenarios: cp.data };

  wpp.printerCurrentAccess = 'open';
  if (wpp.printerIsProtected) {
    wpp.printerCurrentAccess = 'allowed';
  } else if (!!wpp.blacklist && wpp.blacklist.length > 0) { // // NOT GOOD LOGIC BECAUSE IT RELYS ON THE BLACKLIST BEING ERASED WHICH MIGHT CHANGE
    wpp.printerCurrentAccess = 'blocked';
  }

  wpp.printerStatusIcon = '/assets/img/common/icon_printer_disconnected.svg';
  if (wpp.printerOnline) {
    if (wpp.printerStatus.toLowerCase() != 'ready' && wpp.printerStatus.toLowerCase() != 'online' || wpp.isPrinterRegistered === false) {
      wpp.printerStatusIcon = '/assets/img/common/icon_printer_problem.svg';
    } else {
      wpp.printerStatusIcon = '/assets/img/common/icon_printer_connected.svg';
    }
  }

  // Setup whitelist/blacklist models
  const access = {
    accessOptions: [
      {
        Label: eprint_access_settings.eprint_access_ota_label,
        Description: eprint_access_settings.eprint_access_ota_description,
        Key: 'open',
      },
      {
        Label: eprint_access_settings.eprint_access_blocked_label,
        Description: eprint_access_settings.eprint_access_blocked_description,
        Key: 'blocked',
      },
      {
        Label: eprint_access_settings.eprint_access_allowed_label,
        Description: eprint_access_settings.eprint_access_allowed_description,
        Key: 'allowed',
      },
    ],
  };
  // Setup printer preference models
  const preferences = { preferenceSettings: [] };
  if (!!wpp.preferences && wpp.preferences.color !== undefined) {
    preferences.preferenceSettings.push({
      Key: 'color',
      Label: eprint_preferences_settings.eprint_preferences_color,
      Options: [
        {
          Key: 'monochrome',
          Label: eprint_preferences_settings.eprint_preferences_color_choice_b,
          Description: eprint_preferences_settings.eprint_preferences_color_choice_b_description,
        },
        {
          Key: 'color',
          Label: eprint_preferences_settings.eprint_preferences_color_choice_a,
          Description: eprint_preferences_settings.eprint_preferences_color_choice_a_description,
        },
      ],
    });
  }
  if (!!wpp.preferences && wpp.preferences.paper !== undefined) {
    preferences.preferenceSettings.push({
      Key: 'paper',
      Label: eprint_preferences_settings.eprint_preferences_paper,
      Options: [
        {
          Key: 'plain',
          Label: eprint_preferences_settings.eprint_preferences_paper_choice_a,
          Description: eprint_preferences_settings.eprint_preferences_paper_choice_a_description,
        },
        {
          Key: 'photo',
          Label: eprint_preferences_settings.eprint_preferences_paper_choice_b,
          Description: eprint_preferences_settings.eprint_preferences_paper_choice_b_description,
        },
      ],
    });
  }
  if (!!wpp.preferences && wpp.preferences.sides !== undefined) {
    preferences.preferenceSettings.push({
      Key: 'sides',
      Label: eprint_preferences_settings.eprint_preferences_sides,
      Options: [
        {
          Key: '1-sided',
          Label: eprint_preferences_settings.eprint_preferences_sides_choice_a,
          Description: eprint_preferences_settings.eprint_preferences_sides_choice_a_description,
        },
        {
          Key: '2-sided',
          Label: eprint_preferences_settings.eprint_preferences_sides_choice_b,
          Description: eprint_preferences_settings.eprint_preferences_sides_choice_b_description,
        },
      ],
    });
  }
  wpp = { ...wpp, ...access, ...preferences };

  return {
    carepack,
    csData: printer_detail,
    accessSettingData: eprint_access_settings,
    preferenceSettingData: eprint_preferences_settings,
    miscellaneousData: eprint_miscellaneous,
    removalData: printer_removal,
    unregisteredData: printer_unregistered,
    subscriptionData: printer_subscription,
    addressSettingData: eprint_address_settings,
    WPPData: wpp,
  };
};


// ///////////////////////////////////////////////////////////////////////////////////////
export function getMyPrinter(id = '', currentLocale = '', generation = null) {
  const start = () => ({
    type: ActionTypes.START_MYPRINTER_AREA_PENDING,
    currentArea: AreaTypes.MYPRINTER_PAGE,
  });
  const complete = myPrinter => ({
    type: ActionTypes.COMPLETE_MYPRINTER_FETCH,
    currentArea: AreaTypes.MYPRINTER_PAGE,
    myPrinter,
  });
  const error = err => ({
    type: ActionTypes.MYPRINTER_AREA_ERROR,
    currentArea: AreaTypes.MYPRINTER_PAGE,
    isError: true,
    errorMsg: err.message,
  });

  return (dispatch, getState) => new Promise((resolve, reject) => {
    const config = new Config();
    dispatch(start());

    const axium = [];
    const csrfToken = getCookie('hpc3-csrf-token');
    if (typeof id === 'string' && id.length > 0) {
      id = `/${id}`;
    } else {
      id = '';
    }
    const MyPrinterRequests = [
      {
        url: `${config.Get('HPC_GATEWAY_URL')}/printers/${id}${generation ? `?generation=${generation}` : ''}`,
        config: {
          withCredentials: true,
          headers: {
            'x-hpc3-csrf-token': csrfToken,
          },
        },
      },
    ];

    const gottenState = getState();
    const isFeatureFlag = gottenState.config.data._configs.FEATURE_FLAGS.ff_carepack_enabled ? gottenState.config.data._configs.FEATURE_FLAGS.ff_carepack_enabled.toLowerCase() === 'true' : false;

    axium.push(getContentStackData(MY_PRINTER, currentLocale.replace('_', '-').toLowerCase()));
    axium.push(getContentStackData(EPRINT_ACCESS_SETTINGS, currentLocale.replace('_', '-').toLowerCase()));
    axium.push(getContentStackData(EPRINT_PREFERENCE_SETTINGS, currentLocale.replace('_', '-').toLowerCase()));
    axium.push(getContentStackData(EPRINT_MISCELLANEOUS, currentLocale.replace('_', '-').toLowerCase()));
    axium.push(getContentStackData(MY_PRINTER_REMOVE_PRINTER, currentLocale.replace('_', '-').toLowerCase()));
    axium.push(getContentStackData(MY_PRINTER_UNREGISTERED_PRINTER, currentLocale.replace('_', '-').toLowerCase()));
    axium.push(getContentStackData(MY_PRINTER_SUBSCRIPTION, currentLocale.replace('_', '-').toLowerCase()));
    axium.push(getContentStackData(EPRINT_ADDRESS_SETTINGS, currentLocale.replace('_', '-').toLowerCase()));

    MyPrinterRequests.forEach(request => {
      axium.push(axios.get(request.url, request.config));
    });

    axios.all(axium)
      .then(responses => {
        const carepackLogic = !!responses[1] && !!responses[1].data && !!responses[1].data.carepack ? responses[1].data.carepack : null;

        const printerModelNumber = !!responses[1] && !!responses[1].data && !!responses[1].data.printerModelNumber ? responses[1].data.printerModelNumber : null;


        // const carepackSlug = (carepackLogic != null) ? discernCarePackScenario(carepackLogic, gottenState.locales.countryCode, printerModelNumber) : []
        const carepackSlug = discernCarePackScenario(carepackLogic, gottenState.locales.countryCode, printerModelNumber);
        // ///////////////////////////////////////////////////////////////////////////////////////////////////
        // ///////////////////////////////////////////////////////////////////////////////////////////////////
        if (!carepackSlug) {
          const processed = processMyPrinterData(
            responses[0]?.data?.entries[0],
            responses[1]?.data?.entries[0],
            responses[2]?.data?.entries[0],
            responses[3]?.data?.entries[0],
            responses[4]?.data?.entries[0],
            responses[5]?.data?.entries[0],
            responses[6]?.data?.entries[0],
            responses[7]?.data?.entries[0],
            responses[8].data,
            []
          );
          // HPC3-812
          if (processed.WPPData.printerId === '' && processed.WPPData.printerModelNumber === '' && processed.WPPData.printerSerialNumber === '') {
            processed.WPPData.printerName = 'HP Printer';
          }
          dispatch(complete(processed));
          return resolve(complete(processed));
        }
        const url = getApiPathWithLocale(`/api/carePack/${carepackSlug}.json`, currentLocale);
        return axios.get(url)
          .then(response => {
            const carepackScenario = response.data;
            const processed = processMyPrinterData(
              responses[0]?.data?.entries[0],
              responses[1]?.data?.entries[0],
              responses[2]?.data?.entries[0],
              responses[3]?.data?.entries[0],
              responses[4]?.data?.entries[0],
              responses[5]?.data?.entries[0],
              responses[6]?.data?.entries[0],
              responses[7]?.data?.entries[0],
              responses[8].data,
              (isFeatureFlag) ? carepackScenario : []);
            // HPC3-812
            if (processed.WPPData.printerId === '' && processed.WPPData.printerModelNumber === '' && processed.WPPData.printerSerialNumber === '') {
              processed.WPPData.printerName = 'HP Printer';
            }
            dispatch(complete(processed));
            return resolve(complete(processed));
          });
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }
        dispatch(error(err));
        reject(err);
      });
  });
}

export function updateMyPrinterAddress(ownershipId, printer_id, newAddress, callback) {
  const start = () => ({
    type: ActionTypes.START_MYPRINTER_AREA_PENDING,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_EMAIL,
  });
  const complete = printerEmail => ({
    type: ActionTypes.COMPLETE_UPDATE_MYPRINTER_ADDRESS,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_EMAIL,
    ownershipId,
    printerEmail,
  });
  const error = err => ({
    type: ActionTypes.MYPRINTER_AREA_ERROR,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_EMAIL,
    err,
  });
  return (dispatch, getState) => {
    dispatch(start());
    return new Promise((resolve, reject) => {
      const gottenState = getState();

      const config = new Config();

      const csrfToken = getCookie('hpc3-csrf-token');
      const postBody = {
        emailAddress: newAddress,
        generation: gottenState.myPrinter.data.WPPData.generation,
      };
      axios.put(`${config.Get('HPC_GATEWAY_URL')}/printers/${printer_id}/email-address`, postBody, {
        withCredentials: true,
        headers: {
          'x-hpc3-csrf-token': csrfToken,
        },
      }).then(result => {
        // gottenState.myPrinter.data.WPPData.printerEmail = `${newAddress}@${gottenState.myPrinter.data.WPPData.emailDomain}`;

        callback();
        dispatch(complete(`${newAddress}@${gottenState.myPrinter.data.WPPData.emailDomain}`));
        return resolve(result.data);
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }
        dispatch(error(gottenState.myPrinter.data.addressSettingData.eprint_email_change_duplicate));
        // dispatch(error(err));
        reject(error(err));
      });
    });
  };
}

export function toggleMyPrinterAccessSendOnPrint(printerId, enabled) {
  const start = () => ({
    type: ActionTypes.START_MYPRINTER_AREA_PENDING,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST_SEND_TOGGLE,
  });
  const complete = newWhitelist => ({
    type: ActionTypes.COMPLETE_MYPRINTER_WHITELIST_TOGGLE,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST_SEND_TOGGLE,
    newWhitelist,
  });
  const error = err => ({
    type: ActionTypes.MYPRINTER_AREA_ERROR,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST_SEND_TOGGLE,
    err,
  });
  return dispatch => {
    dispatch(start());
    return new Promise((resolve, reject) => {
      const config = new Config();
      const csrfToken = getCookie('hpc3-csrf-token');
      const postBody = {
        enabled,
      };

      axios.put(`${config.Get('HPC_GATEWAY_URL')}/printers/${printerId}/whitelist/email-on-print`, postBody, {
        withCredentials: true,
        headers: {
          'x-hpc3-csrf-token': csrfToken,
        },
      }).then(result => {
        if (!result.hasOwnProperty('data')) {
          dispatch(error('Internal server error.'));
          return;
        }
        if (result.data.hasOwnProperty('error') && result.data.hasOwnProperty('code')) {
          dispatch(error(`Internal server error: ${result.data.code}`));
          return;
        }
        dispatch(complete(result.data));
        return resolve(result.data);
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }
        dispatch(error(err));
        reject(error(err));
      });
    });
  };
}

export function updateMyPrinterAccessOption(ownershipId, printerId, option) {
  const start = () => ({
    type: ActionTypes.START_MYPRINTER_AREA_PENDING,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS,
  });
  const complete = newPrinter => ({
    type: ActionTypes.COMPLETE_UPDATE_MYPRINTER_ACCESS_OPTION,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS,
    newPrinter,
  });
  const error = err => ({
    type: ActionTypes.MYPRINTER_AREA_ERROR,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS,
    err,
  });

  return (dispatch, getState) => {
    dispatch(start());
    return new Promise((resolve, reject) => {
      const config = new Config();
      const csrfToken = getCookie('hpc3-csrf-token');
      const { generation } = getState().myPrinter.data.WPPData;
      const postBody = {
        configuration: {
          accessOption: option,
        },
        generation,
      };

      let axiosCall;
      if (generation == 1) {
        axiosCall = axios.put(`${config.Get('HPC_GATEWAY_URL')}/printers/${printerId}/configuration`, postBody, {
          withCredentials: true,
          headers: {
            'x-hpc3-csrf-token': csrfToken,
          },
        });
      } else {
        axiosCall = axios.post(`${config.Get('HPC_GATEWAY_URL')}/printers/${ownershipId}/configuration`, postBody, {
          withCredentials: true,
          headers: {
            'x-hpc3-csrf-token': csrfToken,
          },
        });
      }

      axiosCall.then(result => {
        // TODO: A BUNCH OF NASTY HACKS TO MAKE THIS THING BEHAVE! Put this in the model!
        const wpp = getState().myPrinter.data.WPPData;
        const newAccess = {
          printerCurrentAccess: option,
        };
        const residualCarepackData = getState().myPrinter.data.WPPData.carepack;
        if (typeof result.data.carepack === 'object' && typeof residualCarepackData === 'object' && Object.keys(residualCarepackData).length > Object.keys(result.data.carepack).length) {
          result.data.carepack = residualCarepackData;
        }
        const completePrinter = { ...wpp, ...result.data, ...newAccess };
        dispatch(complete(completePrinter));
        return resolve(completePrinter);
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }
        dispatch(error(err));
        return reject(err);
      });
    });
  };
}

export function resolveMyPrinterAccessOption(printer) {
  const start = () => ({
    type: ActionTypes.START_MYPRINTER_AREA_PENDING,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS,
  });
  const complete = newPrinter => ({
    type: ActionTypes.COMPLETE_UPDATE_MYPRINTER_ACCESS_OPTION,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS,
    newPrinter,
  });

  return dispatch => {
    dispatch(start());
    const newAccess = {
      printerCurrentAccess: printer.printerCurrentAccess,
    };
    if (printer.printerCurrentAccess === 'blocked' && printer.blacklist.length === 0) {
    }
    dispatch(complete({ ...printer, ...newAccess }));
  };
}

// ////////////////////////////////////////////////////////////////////////////////////// add to whitelist
export function addMyPrinterAccessAllowedEmails(printerId, whitelistAddress) {
  const start = () => ({
    type: ActionTypes.START_ADD_MYPRINTER_ACCESS_ALLOWED_EMAILS,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST,
  });
  const complete = newWhitelist => ({
    type: ActionTypes.COMPLETE_ADD_MYPRINTER_ACCESS_ALLOWED_EMAILS,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST,
    newWhitelist,
  });
  const error = err => ({
    type: ActionTypes.ADD_MYPRINTER_ACCESS_ALLOWED_EMAILS_ERROR,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST,
    err,
  });

  return (dispatch, getState) => {
    dispatch(start());
    return new Promise((resolve, reject) => {
      const config = new Config();
      const csrfToken = getCookie('hpc3-csrf-token');
      const postBody = { whitelistAddress };
      const { generation } = getState().myPrinter.data.WPPData;
      postBody.generation = generation;

      let axiosCall;
      if (generation == 1) {
        axiosCall = axios.put(`${config.Get('HPC_GATEWAY_URL')}/printers/${printerId}/whitelist`, postBody, {
          withCredentials: true,
          headers: {
            'x-hpc3-csrf-token': csrfToken,
          },
        });
      } else {
        axiosCall = axios.post(`${config.Get('HPC_GATEWAY_URL')}/printers/${printerId}/whitelist`, postBody, {
          withCredentials: true,
          headers: {
            'x-hpc3-csrf-token': csrfToken,
          },
        });
      }
      axiosCall.then(result => {
        // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (result.data.status === 400) {
          throw new Error('found 400');
        }
        dispatch(complete(result.data));
        return resolve(result.data);
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }
        dispatch(error(err));
        return reject(err);
      });
    });
  };
}

export function removeMyPrinterAccessAllowedEmails(printerId, accessIndex, gen) {
  const start = accessIndex => ({
    type: ActionTypes.START_REMOVE_MYPRINTER_ACCESS_ALLOWED_EMAILS,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST,
    accessIndex,
  });
  const complete = newWhitelist => ({
    type: ActionTypes.COMPLETE_REMOVE_MYPRINTER_ACCESS_ALLOWED_EMAILS,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST,
    newWhitelist,
  });
  const error = err => ({
    type: ActionTypes.REMOVE_MYPRINTER_ACCESS_ALLOWED_EMAILS_ERROR,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST,
    err: err.message,
  });
  return dispatch => {
    dispatch(start(accessIndex));
    return new Promise((resolve, reject) => {
      const config = new Config();
      const csrfToken = getCookie('hpc3-csrf-token');
      let deleteBody = null;
      let url = null;
      if (gen === 1) {
        url = `${config.Get('HPC_GATEWAY_URL')}/printers/${printerId}/whitelist/${accessIndex.id}`;
      } else {
        url = `${config.Get('HPC_GATEWAY_URL')}/printers/${printerId}/whitelist/`;
        deleteBody = { emailAddress: accessIndex.emailAddress };
      }
      axios.delete(url, {
        data: deleteBody,
        withCredentials: true,
        headers: {
          'x-hpc3-csrf-token': csrfToken,
        },
      }).then(result => {
        dispatch(complete(result.data));
        return resolve(result.data);
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }
        dispatch(error(err));
        return reject(err);
      });
    });
  };
}

export function addMyPrinterAccessBlockedEmails(printerId, blacklistAddress) {
  const start = () => ({
    type: ActionTypes.START_ADD_MYPRINTER_ACCESS_BLOCKED_EMAILS,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_BLACKLIST,
  });
  const complete = newBlacklist => ({
    type: ActionTypes.COMPLETE_ADD_MYPRINTER_ACCESS_BLOCKED_EMAILS,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_BLACKLIST,
    newBlacklist,
  });
  const error = err => ({
    type: ActionTypes.ADD_MYPRINTER_ACCESS_BLOCKED_EMAILS_ERROR,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_BLACKLIST,
    err,
  });

  return (dispatch, getState) => {
    dispatch(start());
    return new Promise((resolve, reject) => {
      const config = new Config();
      const csrfToken = getCookie('hpc3-csrf-token');
      const postBody = { blacklistAddress };
      postBody.generation = getState().myPrinter.data.WPPData.generation;

      let axiosCall;
      if (postBody.generation == 1) {
        axiosCall = axios.put(`${config.Get('HPC_GATEWAY_URL')}/printers/${printerId}/blacklist`, postBody, {
          withCredentials: true,
          headers: {
            'x-hpc3-csrf-token': csrfToken,
          },
        });
      } else {
        axiosCall = axios.post(`${config.Get('HPC_GATEWAY_URL')}/printers/${printerId}/blacklist`, postBody, {
          withCredentials: true,
          headers: {
            'x-hpc3-csrf-token': csrfToken,
          },
        });
      }
      axiosCall.then(result => {
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (result.data.status === 400) {
          throw new Error('found 400');
        }
        dispatch(complete(result.data));
        return resolve(result.data);
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }
        dispatch(error(err));
        return reject(err);
      });
    });
  };
}

export function removeMyPrinterAccessBlockedEmails(printerId, accessIndex) {
  const start = accessIndex => ({
    type: ActionTypes.START_REMOVE_MYPRINTER_ACCESS_BLOCKED_EMAILS,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_BLACKLIST,
    accessIndex,
  });
  const complete = newBlacklist => ({
    type: ActionTypes.COMPLETE_REMOVE_MYPRINTER_ACCESS_BLOCKED_EMAILS,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_BLACKLIST,
    newBlacklist,
  });
  const error = err => ({
    type: ActionTypes.REMOVE_MYPRINTER_ACCESS_BLOCKED_EMAILS_ERROR,
    currentArea: AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_BLACKLIST,
    err,
  });
  return (dispatch, getState) => {
    dispatch(start(accessIndex));
    return new Promise((resolve, reject) => {
      const config = new Config();
      const csrfToken = getCookie('hpc3-csrf-token');
      const gen = getState().myPrinter.data.WPPData.generation;
      const found = getState().myPrinter.data.WPPData.blacklist[accessIndex];
      let deleteBody = null;
      let url = null;
      if (gen == 1) {
        url = `${config.Get('HPC_GATEWAY_URL')}/printers/${printerId}/blacklist/${found.id}`;
      } else {
        url = `${config.Get('HPC_GATEWAY_URL')}/printers/${printerId}/blacklist/`;
        deleteBody = { emailAddress: found.emailAddress };
      }
      axios.delete(url, {
        data: deleteBody,
        withCredentials: true,
        headers: {
          'x-hpc3-csrf-token': csrfToken,
        },
      }).then(result => {
        dispatch(complete(result.data));
        return resolve(result.data);
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }
        dispatch(error(err));
      });
    });
  };
}

export function updateEPrintPreference(printerId, preference, value) {
  let area = '';
  switch (preference) {
    case 'color':
      area = AreaTypes.MYPRINTER_EPRINT_SETTINGS_PREFERENCES_COLOUR;
      break;
    case 'paper':
      area = AreaTypes.MYPRINTER_EPRINT_SETTINGS_PREFERENCES_PAPER;
      break;
    case 'sides':
      area = AreaTypes.MYPRINTER_EPRINT_SETTINGS_PREFERENCES_SIDES;
      break;
  }
  const start = () => ({
    type: ActionTypes.START_UPDATE_MYPRINTER_PREFERENCE,
    currentArea: area,
  });
  const complete = myPrinter => ({
    type: ActionTypes.COMPLETE_UPDATE_MYPRINTER_PREFERENCE,
    currentArea: area,
    myPrinter,
  });
  const error = err => ({
    type: ActionTypes.UPDATE_MYPRINTER_PREFERENCE_ERROR,
    currentArea: area,
    err,
  });
  return dispatch => {
    dispatch(start());
    return new Promise((resolve, reject) => {
      const config = new Config();
      const csrfToken = getCookie('hpc3-csrf-token');
      const postData = {
        preferences: {
          preference,
          value,
        },
      };
      axios.put(`${config.Get('HPC_GATEWAY_URL')}/printers/${printerId}/preferences`, postData, {
        withCredentials: true,
        headers: {
          'x-hpc3-csrf-token': csrfToken,
        },
      }).then(result => {
        dispatch(complete(result.data));
        return resolve(result.data);
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }
        dispatch(error(err));
        return reject(err);
      });
    });
  };
}

export function unclaimPrinter(ownershipId, callback) {
  const start = () => ({
    type: ActionTypes.START_MYPRINTER_AREA_PENDING,
    currentArea: AreaTypes.MYPRINTER_PAGE,
  });
  const complete = myPrinter => ({
    type: ActionTypes.COMPLETE_MYPRINTER_UNCLAIM,
    currentArea: AreaTypes.MYPRINTER_PAGE,
    myPrinter,
  });
  const error = err => ({
    type: ActionTypes.MYPRINTER_AREA_ERROR,
    currentArea: AreaTypes.MYPRINTER_PAGE,
    isError: true,
    errorMsg: err,
  });
  return (dispatch, getState) => {
    dispatch(start());
    return new Promise((resolve, reject) => {
      const csrfToken = getCookie('hpc3-csrf-token');
      const config = new Config();
      const postData = {
        ownershipId,
        generation: getState().myPrinter.data.WPPData.generation,
      };
      axios.post(`${config.Get('HPC_GATEWAY_URL')}/printers/remove`, postData, {
        withCredentials: true,
        headers: {
          'x-hpc3-csrf-token': csrfToken,
        },
      }).then(result => {
        dispatch(complete(result.data));
        callback();
        return resolve(result.data);
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }
        dispatch(error(err));
        return reject(err);
      });
    });
  };
}
