import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { setCsrfState } from '../../../../utils/globals';
import Config from '../../../../config/Config';
import {
  selectSignInProps,
} from '../../store/modules/portal/selectors';
import { initializeUcdeUser } from '../../store/modules/ucdeUser/actions';
import SignIn from '../../../global/signin';
import { ifJWebAvailable } from '../../utils/auth';
import Loader from '../shared-components/Loader';

const config = new Config();

const SignInWeb = ({ encodedState, handshake }) => {

  setCsrfState(handshake);
  const signinUrl = `${config.Get('AUTHZ_OPENID_LOGIN')}${encodedState}`;

  return <SignIn loc={signinUrl} />;
};

SignInWeb.propTypes = {
  encodedState: PropTypes.string.isRequired,
  handshake: PropTypes.string.isRequired,
};

export default () => {
  const [useWebSignIn, setUseWebSignIn] = useState(false);
  const dispatch = useDispatch();

  const {
    handshake,
    redirectTo,
    encodedState,
  } = useSelector(selectSignInProps);

  if (useWebSignIn) {
    return <SignInWeb encodedState={encodedState} handshake={handshake} />;
  }

  ifJWebAvailable(() => {
    // JWeb bridge is available.. ready to sign in with Auth Plugin
    dispatch(initializeUcdeUser('jweb', `${redirectTo}`));
  }, () => {
    // JWeb bridge is not available.. ready to sign in with web
    setUseWebSignIn(true);
  });

  return (
    <Loader fullScreen />
  );
};
