import { take, select, delay } from 'redux-saga/effects';
import { selectSignOutURL } from '../selectors/config';
import actionTypes from '../constants/ActionTypes';

function* executeRedirect() {
  const url = yield select(selectSignOutURL);
  if (url) {
    window.location.href = url;
  } else {
    yield delay(500);
    yield executeRedirect();
  }
}

export default function* watchRedirectToSignout() {
  let task;
  while (true) {
    yield take(actionTypes.REDIRECT_TO_SIGNOUT);
    if (!task) {
      task = yield executeRedirect();
    }
  }
}
