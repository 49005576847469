import styled from 'styled-components';
import { breakpoints, theme } from '../../../utils/styleHelpers';
import '../global_styles/global.css';

export const SmartAdvanceHeroContainer = styled.div`
  && {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #464646;
    background-image: url(${(props) => props.smartAdvanceHeroData.mobile.url});
    @media (min-width: ${breakpoints.desktop}) {
      background-image: url(${(props) => props.smartAdvanceHeroData.tablet.url});
    }
    @media (min-width: ${breakpoints.bigtablet}) {
      background-image: url(${(props) =>
        props.direction === 'rtl'
          ? props.smartAdvanceHeroData.widescreen_rtl.url
          : props.smartAdvanceHeroData.widescreen.url});
    }
    @media (min-width: ${breakpoints.wide}) {
      background-image: url(${(props) =>
        props.direction === 'rtl'
          ? props.smartAdvanceHeroData.extrawide_rtl.url
          : props.smartAdvanceHeroData.extrawide.url});
    }
  }
`;

export const SmartAdvanceHeroContent = styled.div`
  && {
    padding: 46px 0 40px 0;
    margin: 0 auto;
    margin-top: 100px;
    width: 318px;
    text-align: center;
    color: ${theme.colors.white};
    @media (min-width: ${breakpoints.desktop}) {
      padding: 0 0 73px 0;
      width: 437px;
      margin-top: 180px;
    }
    @media (min-width: ${breakpoints.bigtablet}) {
      padding: 46px 0 73px 0;
      width: 457px;
      margin: initial;
      margin-top: 66px;
      ${(props) => (props.direction === 'rtl' ? `margin-right: 54px` : `margin-left: 54px`)};
      text-align: ${props => props.direction === "rtl" ? "right":"left"};
    }
    @media (min-width: ${breakpoints.wide}) {
      padding: 46px 0 65px 0;
      width: 698px;
      margin: initial;
      margin-top: 97px;
      ${(props) => (props.direction === 'rtl' ? `margin-right: 92px` : `margin-left: 92px`)};
      text-align: ${props => props.direction === "rtl" ? "right":"left"};
      width: 698px;
    }
  }
  h1 {
    font-size: 40px;
    text-transform: uppercase;
    margin: 16px 0;
    font-family: ${theme.fonts.gotham_ultra};
    color: ${theme.colors.white};
    line-height: 40px;
    .yellow-font {
      color: #fcbe2a;
    }
    @media (min-width: ${breakpoints.bigtablet}) {
      font-size: 50px;
      line-height: 52px;
      margin: 20px 0 8px 0;
    }
    @media (min-width: ${breakpoints.wide}) {
      font-size: 58px;
      line-height: 60px;
      margin: 28px 0 10px 0;
    }
  }
  p {
    /* width: 569px; */
    font-size: 24px;
    line-height: 32px;
    @media (min-width: ${breakpoints.desktop}) {
      width: 364px;
      margin: 0 auto;
    }
    @media (min-width: ${breakpoints.bigtablet}) {
      width: 380px;
      margin: initial;
    }
    @media (min-width: ${breakpoints.wide}) {
      width: 569px;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin: 0px;
    }
  }
  .learn-more {
    color: #009f86;
    @media (min-width: ${breakpoints.desktop}) {
      margin-top: 24px;
    }
  }
`;

export const AdvButton = styled.button`
  && {
    font-family: ${theme.fonts.family};
    border-radius: 19px;
    background-color: #fcbe2a;
    border: 0;
    padding: 4px 10px;
    width: 57px;
    height: 28px;
  }
`;

export const Heading = styled.h1``;

export const Body = styled.div``;
