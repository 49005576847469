import ActionTypes from "../constants/ActionTypes";
import { LIBRARY_LINK } from "../constants/contentStackResources";
import { getContentStackData } from "../services/craft";

/**
 * This function uses axios to get library ink data.
 *
 * Since axios returns promises, we can dispatch our start event and
 * update it to complete or complete with error whe the promise returns.
 *
 * @returns {function(*)}
 */
export function getLibraryLink(currentLocale = "") {
  let start = () => ({
      type: ActionTypes.START_LIBRARYLINK_FETCH
    }),
    complete = (libraryLinkData) => ({
      type: ActionTypes.COMPLETE_LIBRARYLINK_FETCH,
      libraryLinkData
    }),
    error = err => ({
      type: ActionTypes.COMPLETE_LIBRARYLINK_FETCH_WITH_ERROR,
      err
    });
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(start());
      getContentStackData(LIBRARY_LINK, currentLocale)
        .then(res => {
          dispatch(complete(res.data.entries));
          return resolve(res.data.entries);
        })
        .catch(err => {
          dispatch(error(err));
          reject(err);
        });
    });
  };
}