import styled from 'styled-components';

export const libraryTheme = {
  hpBlue: '#0171AD', // Web accessible 'brand blue' from the HP.com design system
  textColor: '#4e4e4e',
  menuSelected: '#DBF0F9',
  menuHover: '#EFEFEF',
  menuFocus: '#E6E6E6',
  menuFontSize: '1rem',
  windowsMenuItemPadding: '0.5rem 1rem 0.5rem 2rem',
  hpSimplifiedLight: "font-family: 'hp-simplified-light', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';",
  hpSimplifiedNormal: "font-family: 'hp-simplified', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';",
};

export const MainContent = styled.div`
  height: 100%;
  ${props => ( ((props.currentPlatform == 'web') || (props.currentPlatform == 'desktop') || (props.currentPlatform == 'wireless')) && props.theme.hpSimplifiedlight )}
  ${props => ( ((props.currentPlatform == 'web') || (props.currentPlatform == 'desktop') || (props.currentPlatform == 'wireless') || !props.isMobile) ? 'font-size: 1rem;' : 'font-size: .9375rem;')}
  ${props => ( ((props.currentPlatform == 'ios') || (props.currentPlatform == 'android')) && "font-family: -apple-system, Roboto-Light, DroidSans, sans-serif; font-weight: 300;" )}
  color: #4e4e4e;
  a, button, b, strong {
    ${props => ( ((props.currentPlatform == 'web') || (props.currentPlatform == 'desktop') || (props.currentPlatform == 'wireless')) && "font-family: 'hp-simplified', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'; font-weight: 500" )}
    ${props => ( ((props.currentPlatform == 'ios') || (props.currentPlatform == 'android')) && "font-family: -apple-system, Roboto-Medium, DroidSans-Bold, sans-serif; font-weight: 500;" )}
  }
  h1, h2 {
    ${props => ( ((props.currentPlatform == 'web') || (props.currentPlatform == 'desktop') || (props.currentPlatform == 'wireless')) && "font-family: 'hp-simplified', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'; font-weight: 500" )}
    ${props => ( ((props.currentPlatform == 'ios') || (props.currentPlatform == 'android')) && "font-family: -apple-system-headline, -apple-system, Roboto-Medium, DroidSans-Bold, sans-serif; font-weight: 500;" )}
  }
  h3 {
    font-size: 1.375rem;
    ${props => ( ((props.currentPlatform == 'web') || (props.currentPlatform == 'desktop') || (props.currentPlatform == 'wireless')) && "font-family: 'hp-simplified', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'; font-weight: 500" )}
    ${props => ( ((props.currentPlatform == 'ios') || (props.currentPlatform == 'android')) && "font-family: -apple-system-headline, -apple-system, Roboto-Medium, DroidSans-Bold, sans-serif; font-weight: 500;" )}
  }
  h4 {
    font-size: 1.125rem;
    ${props => ( ((props.currentPlatform == 'web') || (props.currentPlatform == 'desktop') || (props.currentPlatform == 'wireless')) && "font-family: 'hp-simplified', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'; font-weight: 500" )}
    ${props => ( ((props.currentPlatform == 'ios') || (props.currentPlatform == 'android')) && "font-family: -apple-system-headline, -apple-system, Roboto-Medium, DroidSans-Bold, sans-serif; font-weight: 500;" )}
  }
  h5 {
    ${props => ( ((props.currentPlatform == 'web') || (props.currentPlatform == 'desktop') || (props.currentPlatform == 'wireless')) && "font-family: 'hp-simplified', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'; font-weight: 500" )}
    ${props => ( ((props.currentPlatform == 'ios') || (props.currentPlatform == 'android')) && "font-family: -apple-system-headline, -apple-system, Roboto-Medium, DroidSans-Bold, sans-serif; font-weight: 500;" )}
  }
  h6 {
    ${props => ( ((props.currentPlatform == 'web') || (props.currentPlatform == 'desktop') || (props.currentPlatform == 'wireless')) && "font-family: 'hp-simplified', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'; font-weight: 500" )}
    ${props => ( ((props.currentPlatform == 'ios') || (props.currentPlatform == 'android')) && "font-family: -apple-system-headline, -apple-system, Roboto-Medium, DroidSans-Bold, sans-serif; font-weight: 500;" )}
  }
`
export const LibraryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media screen and (min-width: 48rem) {
    max-width: ${props => (props.currentPlatform != 'web' ? "none" : "80rem" )};
    flex-direction: row;
    height: 100%;
  }
  `;
export const StyledSection = styled.section`
  margin: 0 1.25rem;
  ul ul {
    list-style-type: circle;
  }
  @media screen and (min-width: 64rem) {
    max-width: 60rem;
    margin: 0 2rem;
  }
  a { color: ${props => props.theme.hpBlue ? props.theme.hpBlue : 'red'}; }
  &:first-child {
    margin-top: ${props => !props.isMobile ? '1.25rem' : '0'}
  }
  &:last-child {
    margin-bottom: 1.25rem;
  }
`
export const SubAccordion = styled.div`
ol {
  list-style-type: decimal;
}
> a {
  font-size: 1rem;
  padding: 0.5rem 0;
  margin-bottom: 0;
  display: flex;
  border-top: 2px solid #EEE;
  color: ${ props => props.theme.textColor};
  &:hover, &:active {
    color: ${ props => props.theme.textColor};
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}
`
export const SubArrows = styled.span`
  width: .875rem;
  margin-right: 0.5rem;
  img {
    width: .875rem
  }
`
export const VisualNav = styled.div`
  display: flex;
  padding: 0.75rem 1rem;
  max-width: 42rem;
  margin: 0 auto;
  @media screen and (min-width: 64rem) {
    padding: 1rem 2rem;
  }
  flex-direction: ${props => !props.isMobile ? 'row' : 'column'};
  flex-wrap: wrap;
  flex: 0 0 auto;
  justify-content: space-between;
  > div {
    h3 { 
      font-size: 1.25rem;
      margin-bottom: 0;
    } 
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    margin-bottom: 1rem;
  }
`
export const MenuSection = styled.div`

  ${props => !props.isMobile ? `height: ${props.isInApp ? props.myHeight : props.myHeight - 116 }px;` : ""}
  ${props => !props.isMobile ? `overflow: auto;` : ''}

  display: flex;
  flex-direction: column;
	flex: 1 1 auto;
  border-right: ${props => !props.isMobile ? '1.5px solid #e4e4e4' : 'none'};
  box-shadow: ${props => !props.isMobile ? '0.25rem 0 0.5rem #EEE' : 'none'};
  @media screen and (min-width: 48rem) {
		flex: 1 1 33.333%;
  }
  @media screen and (min-width: 80rem) {
		flex: 1 1 20%;
  }
  #contacthpLink:first-child {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
`;
export const MenuSubSection = styled.div`
  flex: 1 0 auto;
  margin-bottom: ${props => !props.isMobile && '1.125rem'};
`
export const Widescreen = styled.div`
  display: none;
  @media screen and (min-width: 48rem) {
    display: flex;

    height:  ${props => props.isInApp ? props.myHeight : props.myHeight - 116 }px;}
    overflow: auto;

    flex-direction: column;
    padding-top: 1rem;
		flex: 1 1 66.666%;
  }
`;
export const SectionTitle = styled.h2`
  font-size: ${props => !props.isMobile ? '1.25rem' : '1.0625rem'};
  color: ${props => props.isMobile ? '#6a6a6a' : '#4e4e4e'};
  ${props => props.theme.hpSimplified }
  padding: ${props => !props.isMobile ? '1rem 0 0.5rem 0' : '1rem'};
  &:first-child {
    padding: ${props => !props.isMobile && '0 0 0.5rem 0'};
  } 
  /* TODO: make this background color turn hpBlue when active, but only for Windows platforms. Keep the non-active options */
  background-color: ${props => !props.isMobile ? 'white' : '#eee'};
  margin: ${props => !props.isMobile ? '0.8125rem 1rem 0' : '0'};
`;
export const StyledLink = styled.a`
  color: ${props => props.theme.textColor};
  padding: ${props => (!props.isMobile) ? props.theme.windowsMenuItemPadding : '1.125rem 1rem' };
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  border-top: ${props => (!props.isMobile) ? 'none' : '2px solid #EEE' };
  background-color: ${props => (props.active && !props.isMobile) ? props.theme.menuSelected : 'none' };
  font-size: 1rem;
  @media screen and (min-width: 48rem) {
    border-bottom: none;
  }
  &:default {
    color: ${ props => props.theme.textColor};
  }
  &:hover, &:active, &:focus {
    text-decoration: none;
    color: ${props => props.theme.textColor};
    background-color: ${props => (!props.active && !props.isMobile) ? props.theme.menuHover : 'none' };
  }
  &:focus {
    outline: none;
  }
  img {
    margin-right: ${props => (!props.isMobile) ? '0.75rem' : '0' };
  }
  span:last-child {
    width: .875rem;
    img {
      width: 100%;
    }
  }
`;