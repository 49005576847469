import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import HeroCarousel from './HeroCarousel';
import VisualNav from './VisualNav';
import ServiceDetails from './ServiceDetails';
import ServiceExplainer from './ServiceExplainer';
import Testimonials from './Testimonials';
import HPPlus from './HPPlus';
import FAQ from './FAQ';
import Footnotes from './Footnotes';
import SmartAdvanceCompareApp from './SmartAdvanceCompareTable/SmartAdvanceCompareApp';
import { isHpPlusCountry, getInstantInkSupportedCountries, getInstantInkPlanPrice } from '../../../utils/helperApi';
import { usePathCorrection, useSelector } from '../../../hooks';
import { selectHomePageProps } from '../../../selectors/home';
import { Redirect } from '../../routing';
import { paths } from '../../../utils/styleHelpers';
import { RTL_LANGUAGES } from '../../../constants/i18n';

const Container = styled.div(props => `
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  padding: 0 1.5rem;
  overflow: ${props.isRTLSupport && 'hidden'};
  &:nth-child(1) {
    padding: ${props.isRTLSupport && '0'};
  }
  @media screen and (min-width: ${paths.breakTablet}) {
    padding: 0 2.5rem;
    &:nth-child(1) {
      padding: ${props.isRTLSupport && '0'};
    }
  }
  @media screen and (min-width: ${paths.breakWide}) {
    padding: 0 6.25rem;
    &:nth-child(1) {
      padding: ${props.isRTLSupport && '0'};
    }
  }
  @media screen and (min-width: ${paths.breakImmense}) {
    max-width: 120rem;
    margin-left: calc((100vw - 120rem) / 2);
  }
`);

const Home = ({ geoCountry, locales }) => {
  const countryCode = locales.countryCode;
  const languageCode = locales.languageCode;
  const [tableApi, setTableApi] = useState(false);
  const [instantInkSupportedCountries, setInstantInkSupportedCountries] = useState([]);
  const pathName = window.location.pathname;
  const countryNameArray = pathName.split('/');
  const isRTLSupport = RTL_LANGUAGES.includes(countryNameArray[2]);
  countryNameArray.shift();
  const country = countryNameArray[0] || geoCountry;

  const [instantInkPrice, setInstantInkPrice] = useState();

  const instantInkPlanPrice = async () => {
    await getInstantInkPlanPrice(locales?.alt5).then((res) => {
      const filteredInkPlansByFrequencyLight = Object.values(res?.data?.data).find(item => item.frequency === "light");
      setInstantInkPrice(res?.data?.success === true ? filteredInkPlansByFrequencyLight?.price : null);
    })
  }

  useEffect(() => {
    isHpPlusCountry(country).then((response) => {
      setTableApi(response.data.isHpPlusCountry);
    });
    getInstantInkSupportedCountries().then((response) => {
      const lowerCasedCounteriesList = response.data.instantInkSupportedRegions.map(countries => countries.toLowerCase());
      setInstantInkSupportedCountries(lowerCasedCounteriesList);
    });
    instantInkPlanPrice()
  }, [country, locales?.alt5]);
  const redirect = useSelector(selectHomePageProps, { countryCode, languageCode });

  if (redirect) {
    const path = usePathCorrection(redirect);
    return <Redirect to={path} />;
  }
  const visNavRef = useRef(null);

  return (
    <Container isRTLSupport={isRTLSupport}>
      <HeroCarousel
        scrollToFn={() => {
          window.scrollTo({
            left: 0,
            top: visNavRef.current.offsetTop,
            behavior: 'smooth'
          });
        }}
        geoCountry={country}
        instantInkSupportedCountries={instantInkSupportedCountries}
        instantInkPrice={instantInkPrice}
      />
      <VisualNav refProp={visNavRef} isRTLSupport={isRTLSupport} />
      {tableApi === 'true' ? 
        <SmartAdvanceCompareApp 
          isRTLSupport={isRTLSupport} 
          instantInkPrice={instantInkPrice} /> 
        : ''}
      <ServiceExplainer isRTLSupport={isRTLSupport} />
      <ServiceDetails
        geoCountry={country}
        instantInkSupportedCountries={instantInkSupportedCountries}
        isRTLSupport={isRTLSupport}
        locales={locales}
        isInstantInkSupported={instantInkSupportedCountries.includes(country)}
      />
      <Testimonials isRTLSupport={isRTLSupport} />
      <HPPlus
        geoCountry={country}
        instantInkSupportedCountries={instantInkSupportedCountries}
        isRTLSupport={isRTLSupport}
      />
      {/* <FAQ geoCountry={country} /> */}
      <Footnotes
        isRTLSupport={isRTLSupport}
        isInstantInkSupported={instantInkSupportedCountries.includes(country)}
      />
    </Container>
  );
};

export default Home;
