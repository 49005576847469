import React from 'react';
import PropTypes from 'prop-types';
import { FootnotesSection, FootnotesContent } from './styles/StyleOrigamiFootnotes';

const propTypes = {
  footnotesText: PropTypes.string.isRequired
};

const defaultProps = {
  footnotesText: ''
};

const OrigamiFootnotes = (props) => {
  const { footnotesText } = props;
  return (
    <FootnotesSection data-testid="origami-footnotes">
      <FootnotesContent
        data-testid="origami-footnotes-content"
        dangerouslySetInnerHTML={{ __html: footnotesText }}
      />
    </FootnotesSection>
  );
};

OrigamiFootnotes.propTypes = propTypes;
OrigamiFootnotes.defaultProps = defaultProps;
export default OrigamiFootnotes;
