// please see craft/config/seomatic.php for a discussion of how to enable seomatic
import React from 'react';
import { Helmet } from "react-helmet";
// import { fetchSeomaticData } from '../../../services/seomatic';
// import { useSelector } from '../../../hooks';
// import { selectPathname, selectCountryAndLanguage } from '../../../selectors/routing';

// const defaultSeomaticData = {
//   // rely on having these default values before we get the seomatic ones
//   metaTitleContainer: {title: {title: 'HP Smart'}},
//   metaTagContainer: [],
//   metaLinkContainer: []
// }


const HtmlHead = () => {
  // // it happens that selectCountryAndLanguage does not always return
  // // {country: 'xx', language: 'yy'}
  // // we handle that, defaulting to 'us', 'en'
  // const countryAndLanguage = useSelector(selectCountryAndLanguage);
  // const { country, language } = countryAndLanguage || { country: 'us', language: 'en' };

  // const [isLoading, setIsLoading] = useState(false);
  // const [seomaticData, setSeomaticData] = useState(defaultSeomaticData);

  // useEffect(() => { // runs after every render
  //   async function fetchData() {
  //     setIsLoading(true);
  //     const data = await fetchSeomaticData(pathName, country, language);
  //     setSeomaticData(data);
  //     setIsLoading(false);
  //   }
  //   if (!isLoading) { fetchData(); }
  // }, [pathName, country, language /*, isLoading */]);

  // const metaTags = seomaticData.metaTagContainer
  //   .map(tag => {
  //     return (
  //       <meta
  //         name={tag.name ? tag.name : ''}
  //         content={tag.content ? tag.content : ''}
  //         property={tag.property ? tag.property : ''}
  //         key={Object.values(tag).join('-')}
  //       />
  //     );
  //   });
  // const pathName = useSelector(selectPathname);

  // const metaLinks = seomaticData.metaLinkContainer
  //   .map(link => {
  //     return (
  //       <link
  //         href={link.href ? link.href : ''}
  //         hreflang={link.hreflang ? link.hreflang : ''}
  //         rel={link.rel ? link.rel : ''}
  //         type={link.type ? link.type : ''}
  //         key={Object.values(link).join('-')}
  //       />
  //     );
  //   });

  return (
    <Helmet>
    {/*
      <title>{seomaticData.metaTitleContainer.title.title}</title>
      {metaTags}
      {metaLinks}
    */}
    </Helmet>
  );
};


export default HtmlHead;
