import React, { Component } from 'react';
import bind from '../../../bind';
import { searchBarTracking } from '../../../../lib/analyticsTracking';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(event) {
    const {
      device,
      config,
    } = this.props;
    let printerModel = '';
    let category = 'Home';
    let CD1 = '';
    let CD2 = '';
    if (window.location.href.indexOf('/home') != -1) {
      printerModel = '';
      category = 'Home';
    } else if (window.location.href.indexOf('/newprinter') != -1) { // on add pinter page
      printerModel = '';
      category = 'AddPrinter';
    } else if (device.isComplete) { // on product page
      printerModel = ` (${this.props.device.data.Get('title')})`;
      category = 'Product';
    }
    if (window.location.href.indexOf('/myprinter/') != -1) { // on myprinter details page
      CD1 = this.props.userInfo.userPrinterModelLastTouched != null ? this.props.userInfo.userPrinterModelLastTouched : '';
      CD2 = this.props.userInfo.userPrinterName != null ? this.props.userInfo.userPrinterName : '';
      printerModel = ` (${this.props.userInfo.userPrinterName})`;
      category = 'PrinterSettings';
    }
    if (window.location.href.indexOf('/myprinters') != -1) {
      printerModel = '';
      category = 'MyPrinters';
    }
    searchBarTracking(this._search.value, printerModel, category, CD1, CD2);
    event.preventDefault();
    const URL = config.data.Get('SEARCH_URL');
    window.open(URL, 'hpSupport');
    this._search.value = '';
  }

  render() {
    const { printerCommon, placeholder } = this.props;
    let supportFieldLabel; let
      supportFieldPlaceholder = placeholder ?? '';
    if (printerCommon.isComplete) {
      if (!printerCommon.isError) {
        supportFieldLabel = printerCommon.data.Get('supportFieldLabel');
        supportFieldPlaceholder = printerCommon.data.Get('supportFieldPlaceholder');
      }
    }
    return (
      <form
        key="z0" className="search input-group"
        onSubmit={this.onFormSubmit}
      >
        <label
          key="z1" className="hidden"
          htmlFor="searchField"
        >
          {supportFieldLabel}
        </label>
        <input
          key="z2"
          className="input-group-field"
          placeholder={supportFieldPlaceholder}
          type="search"
          id={`searchField_${this.props.id}`}
          ref={input => this._search = input}
        />
        <button
          key="z3" type="submit"
          className="input-group-button" id={this.props.id}
        >
          <img key="z4" src="/assets/img/search_icon.svg" />
        </button>
      </form>
    );
  }
}

export default bind(SearchBar);
