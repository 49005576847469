import styled from 'styled-components';
import {
  Header,
} from '@veneer/core';

// AppBar Styles
export const HeaderContainer = styled(Header)`
  height: 70px;

  && {
    grid-template-columns: 96px auto 96px;
    padding-top: 8px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EBEBEB;
    z-index: 1000;
    position: fixed;
    top: 0;
  }

  .vn-notification-center__no-notifications {
    padding: 90px 0 30px 0;
    background: #ffffff url(/assets/img/dashboard/no-notifications.svg) no-repeat center 20px;
  }

  ${props => (props.theme.hostIsDesktopApp ? 'visibility: hidden;' : '')};
`;

export const AvatarContainer = styled.div`
  position: relative;
  margin-top: 6px;
  margin-left: 10px;
  cursor: pointer;
`;

export const CloseButton = styled.button`
  background: transparent;
  color:  ${props => props.theme.main.hp.color};
  font-family: ${props => props.theme.main.hp.fontFamily};
  border: 0;
  cursor: pointer;
  height: 48px;
  padding: 0;
`;

export const RightArea = styled.div`
  width: 100%;
  justify-content: flex-end;
`;

export const CenterArea = styled.div`
  width: 100%;

  > * {
    width: 100%;
  }
`;
