import React, { useState, useEffect } from 'react';
import {
  is,
} from 'ramda';
import styled from 'styled-components';
import { JarvisOnboardingComponent } from '@jarvis/react-user-onboarding';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { push } from 'connected-react-router';
import { usePathCorrection, useSelector } from '../../../../hooks';
import { selectOnboardingProps } from '../../../../selectors/user';
import { getExchangeToken } from '../../api/HPCSession';
import { initializeUcdeUser } from '../../store/modules/ucdeUser/actions';
import { redirectToSignIn } from '../../api/UCDEGateway';
import {
  getStratusAccessToken,
  getStratusIdToken,
  isStratusAccessTokenExpired,
} from '../../utils/auth';
import { renderErrorModal } from '../../components/ErrorModal';
import { getCookie } from '../../../../lib/manageCookies';
import { USER_TYPE } from '../../../../constants/cookieNames';
import { initializeUser } from '../../../../actions/userInfo';
import {
  gatewayRouteEnabledForBaseUser,
  removeCountryAndLanguage,
} from '../../utils/routing';
import LoaderBlock from '../../../shared-components/molecules/LoaderBlock';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
  width: 100vw;
`;

const Onboarding = () => {
  const onboardingProps = useSelector(selectOnboardingProps);
  if (!onboardingProps) {
    return null;
  }
  const {
    country,
    language,
    baseUrl,
    redirectTo,
    encodedState,
  } = onboardingProps;

  const [exchangeRequired, setExchangeRequired] = useState(false);
  const [exchanging, setExchanging] = useState(false);
  const [finished, setFinished] = useState(false);
  const [initialUserLevel, setInitialUserLevel] = useState(null);
  const dispatch = useDispatch();
  const legacyPath = usePathCorrection('/myprinters');
  const basePath = `/${country}/${language}/ucde`;
  const apiList = ['ws-hp.com/accountmgtsvc', 'ws-hp.com/uimgtsvc', 'ws-hp.com/programmgtsvc'];

  useEffect(() => {
    setInitialUserLevel((getCookie(USER_TYPE) || '').toLowerCase());
  }, []);

  if (!getStratusAccessToken() || isStratusAccessTokenExpired()) {
    const to = encodedState ? `${basePath}/signin?state=${encodedState}` : `${basePath}/signin`;
    return <Redirect from={`${basePath}/loggedin`} to={to} />;
  }

  const getBaseURL = async (apiName, apiVersion) => {

    if(apiVersion === '2' && apiList.includes(apiName)) {
      return `${baseUrl}/v2/ecosystem/${apiName.split('/')[1]}`
    }
    // Need to revisit if below api's are needed. The baseUrl will change for below
    // if (apiName === 'ws-hp.com/devices' && apiVersion === '1') {
    //   return `${Config.AUTHZ_ONBOARDING_DEVICES_BASE_URL}/v1`;
    // }
    // if (apiName === 'ws-hp.com/messagesdb' && apiVersion === '1') {
    //   return `${Config.STRATUS_BASE_URL}/atlas/v1`;
    // }
    return baseUrl;
}

  const handleOnboardingFinished = async arg => {
    setFinished(true);
    if (!arg) {
      // if (exchangeRequired) { // TODO: Aug 28 2020 removed to resolve legacy login until flow can be tested and refined. Likely solution is to add a `await getExchangeToken(token)` after userLevel check in next if block.
        const token = getStratusAccessToken();
        if (!exchanging) {
          setExchanging(true);
          await getExchangeToken(token);
        }
        setExchanging(false);
        // }
      const userLevel = (getCookie(USER_TYPE) || '').toLowerCase();
      const path = redirectTo || basePath;
      if (!gatewayRouteEnabledForBaseUser(removeCountryAndLanguage(path))) {
        if (userLevel === 'base') {
          dispatch(initializeUser('legacy'));
          dispatch(push(legacyPath));
          return;
        }
        if (initialUserLevel === 'base' && initialUserLevel !== userLevel) {
          window.location.href = basePath;
          return;
        }
      }

      dispatch(initializeUcdeUser('ucde', path));
      return;
    }

    const { response } = arg;

    if (!response) {
      renderErrorModal(arg);
      return;
    }

    const { status } = response;
    if (status === 401 || status === 403) {
      redirectToSignIn();
    }
  };

  useEffect(() => {
    const ast = getCookie('is_agent_session');
    if (is(String, ast)) {
      if (ast === 'true') {
        return handleOnboardingFinished();
      }
    }
    if (is(Boolean, ast)) {
      if (ast) {
        return handleOnboardingFinished();
      }
    }
  }, []);

  if (finished) {
    return (
      <LoaderContainer>
        <LoaderBlock />
      </LoaderContainer>
    );
  }

  return (
    <JarvisOnboardingComponent
      country={country.toUpperCase()}
      language={language}
      baseURLProvider={getBaseURL}
      authProvider={{
        getAccessToken: async exchange => {
          const token = getStratusAccessToken();
          if (exchange) {
            if (!exchanging) {
              setExchanging(true);
              await getExchangeToken(token);
            }
            setExchanging(false);
            setExchangeRequired(false);
            return getStratusAccessToken();
          }
          return token;
        },
        getIDToken: async () => {
          const idToken = getStratusIdToken();
          return idToken;
        },
        onTokenExchangeRequired: () => {
          setExchangeRequired(true);
        },
      }}
      onFinished={handleOnboardingFinished}
    />
  );
};

export default Onboarding;
