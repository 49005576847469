import React from 'react';
import { PairingContainer, ImageContent, TextContent } from './styles';

const Pairing = (props) => {
  return (
    <PairingContainer isRTL={props.isRTL}>
      <ImageContent>
        <img alt={props.title} src={props.image} />
      </ImageContent>
      <TextContent isRTL={props.isRTL}>
        <h3
          dangerouslySetInnerHTML={{
            __html: props.URL
          }}
        ></h3>
      </TextContent>
    </PairingContainer>
  );
};

export default Pairing;
