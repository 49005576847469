import {
  split,
  path,
  cond,
  __,
  always,
  prop,
  last,
  includes,
  without,
  T as alwaysTrue,
  isNil,
  propOr,
  compose,
  join,
  filter,
  toUpper,
  head,
  either,
  curry
} from 'ramda';
import {
  COUNTRY_DOMINANT_LANGUAGE,
  COUNTRIES_SET,
  LANGUAGES_SET,
  SUPPORTED_LANGUAGES,
  LTR_LOCALES,
  RTL_LOCALES,
  WIRELESS_PRINTING_LOCALES,
  COUNTRIES_AND_LANGUAGES_SET,
  RTL_LANGUAGES,
  TONER_RECALL_LOCALES,
  ORIGAMI_LOCALES,
  SUPPORT_UPGRADE_LOCALES
} from '../constants/i18n';
import { getBrowserLocale } from './globals';
import {
  PRINTER_CONNECTION_PAGE_PREFIX,
  TOU,
  HOME
} from '../constants/routes';
import {
  SMART_ADVANCE,
  GENERIC_SUPPORT,
  MOBILE_FAX,
  DATA_COLLECTION_NOTICE,
  DATA_SHARING_NOTICE,
  PRINTER_LIST_PAGE,
  DEVICE_LIST_PAGE,
  SMB_NO_SETUP,
  SMB_SETUP_CHOICE,
  PHOTO_PAPER,
  SURE_SUPPLY_LEARN,
  PRINTERS_INFO
} from '../constants/contentStackRoutes';
import { CONTENT_STACK_REDUNDANT_LOCALES } from '../constants/contentStackRedundantLocales';
import { HOSTS } from '../constants/hosts';

export const getDefaultCountryCode = (key, dictionary) =>
  compose(toUpper, head, either(prop(key), prop('en')))(dictionary);

export const createPathWithLocalePrefix = curry(({ country, language }, etc) => {
  const prefix = `/${country}/${language}`;
  if (etc.startsWith(prefix)) {
    return etc;
  }
  return `${prefix}${etc.startsWith('/') ? etc : `/${etc}`}`;
});

export const makePathRelative = curry((match, pathname) => {
  const matchUrl = prop('url', match);
  const prefix = pathname.startsWith(matchUrl) ? '' : matchUrl;
  const mid = pathname.startsWith('/') ? '' : '/';
  const end = last(pathname) === '/' ? pathname.slice(0, pathname.length - 1) : pathname;
  const relPath = `${prefix}${mid}${end}`;
  return relPath;
});

export const splitLocaleIntoCountryAndLanguage = (locale = '') => ({
  language: locale.slice(0, 2),
  country: locale.slice(-2, locale.length).toLowerCase()
});

export const stripLocaleFromPathname = (country, language, pathname) => {
  const locale = `/${country}/${language}`;
  return pathname.replace(locale, '');
};

export const validateLocale = (locale, pathname) => {
  const localeProps = splitLocaleIntoCountryAndLanguage(locale);
  const supportedLanguagesDictionary = getSupportedLanguagesDictionary(pathname);
  if (
    supportedLanguagesDictionary[localeProps.language] &&
    supportedLanguagesDictionary[localeProps.language].includes(localeProps.country)
  ) {
    return locale;
  }
  return null;
};

const getLocalePathFromSettings = (externalLocale, pathname, omitExternalLocale) => {
  const locale = omitExternalLocale ? getBrowserLocale() : externalLocale || getBrowserLocale();
  if (!locale) {
    // user hasn't been here before and no language settings
    return '/us/en';
  }
  const supportedLanguagesDictionary = getSupportedLanguagesDictionary(pathname);
  const { country: rawCountry, language } = splitLocaleIntoCountryAndLanguage(locale);
  const country = rawCountry ? rawCountry.replace(/uk/, 'gb') : rawCountry;

  if (!validateLocale(locale, pathname)) {
    if (locale.length === 2 && COUNTRIES_AND_LANGUAGES_SET.has(locale)) {
      // locale only gives language, most likely
      if (prop(country, supportedLanguagesDictionary)) {
        return `/${path([country, 0], supportedLanguagesDictionary)}/${country}`;
      }
      return '/us/en';
    }
    const languagesWithCountryLists = Object.entries(supportedLanguagesDictionary);
    const foundLangCountryPair = languagesWithCountryLists.find(([lang, countryList]) =>
      countryList.includes(country)
    );
    const [languageValidatedByCountry] = foundLangCountryPair || [];
    if (languageValidatedByCountry) {
      return `/${country}/${languageValidatedByCountry}`;
    }

    return '/us/en';
  }
  if (locale.length === 2 && COUNTRIES_AND_LANGUAGES_SET.has(locale)) {
    // locale only gives language, most likely
    if (prop(country, supportedLanguagesDictionary)) {
      return `/${path([country, 0], supportedLanguagesDictionary)}/${country}`;
    }
    if (prop(country, COUNTRY_DOMINANT_LANGUAGE)) {
      return `/${country}/${path([country, 0], COUNTRY_DOMINANT_LANGUAGE)}`; // just in case the locale only saved a country
    }
    return '/us/en';
  }

  return `/${country}/${language}`;
};
// Anything in below set will show all available languages.
const EXPANDED_LANG_ROUTE_INDICATORS = [
  ...DATA_COLLECTION_NOTICE,
  ...PRINTERS_INFO,
  DATA_SHARING_NOTICE,
  MOBILE_FAX,
  GENERIC_SUPPORT,
  DEVICE_LIST_PAGE,
  SMART_ADVANCE,
  SMB_NO_SETUP,
  SMB_SETUP_CHOICE,
  PHOTO_PAPER,
  SURE_SUPPLY_LEARN,
  PRINTER_CONNECTION_PAGE_PREFIX,
  PRINTER_LIST_PAGE,
  TOU,
  HOME
];

const isRTLLanguage = (pathname) => {
  const splitPathName = pathname.split('/');
  if (splitPathName.length === 4 && splitPathName[3] !== '') {
    return false;
  }
  if (splitPathName.length > 4) {
    return false;
  }
  if (RTL_LANGUAGES.includes(splitPathName[2])) {
    return true;
  }
  return false;
};

const pathHasExpandedLangs = (pathname) =>
  EXPANDED_LANG_ROUTE_INDICATORS.some(
    (str) =>
      pathname.includes(str) &&
      !pathname.includes('wireless-printing') &&
      !pathname.includes('toner-replace-consents') &&
      !pathname.includes('paper-subscription')
  );
// getSupportedLanguagesDictionary function returns a fucntion that takes pathname as an argument
// if the pathname matches given condition (first argument) in the arrays below
// it is enabled for the loclaes represented in the corresponding dictionary.
export const getSupportedLanguagesDictionary = cond([
  [isNil, always(LTR_LOCALES)],
  [pathHasExpandedLangs, always(SUPPORTED_LANGUAGES)],
  [isRTLLanguage, always(RTL_LOCALES)],
  [includes('wireless-printing'), always(WIRELESS_PRINTING_LOCALES)],
  [includes('toner-replace-consents'), always(TONER_RECALL_LOCALES)],
  [includes('paper-subscription'), always(ORIGAMI_LOCALES)],
  [alwaysTrue, always(LTR_LOCALES)]
]);

const resolveCorrectLocalePath = ({ pathname, externalLocale }) => {
  if (!externalLocale) {
    const [_, pathCountry, pathLanguage] = pathname.split('/');
    if (LANGUAGES_SET.has(pathLanguage) && COUNTRIES_SET.has(pathCountry)) {
      // hpc3 supports current language
      const validLangsForCountry = COUNTRY_DOMINANT_LANGUAGE[pathCountry];
      if (validLangsForCountry.length > 1) {
        // need to have an alternate language for the country
        const otherLangs = validLangsForCountry.filter((lang) => lang !== pathLanguage);
        const validLang = otherLangs.find((l) => validateLocale(`${l}_${pathCountry}`, pathname)); // find the first language that makes a valid locale
        if (validLang) {
          return `/${pathCountry}/${validLang}`; // return new localed with valid lang, else fall thru to downstream logic
        }
      }
    }
  }

  const { country, language } = splitLocaleIntoCountryAndLanguage(externalLocale);
  const supportedLanguagesDictionary = getSupportedLanguagesDictionary(pathname);
  if (prop(language, supportedLanguagesDictionary)) {
    // path supports current language
    const countryList = prop(language, supportedLanguagesDictionary);
    return countryList.includes(country)
      ? `/${country}/${language}`
      : `/${head(countryList)}/${language}`;
  }

  if (prop(country, supportedLanguagesDictionary)) {
    if (prop(country, supportedLanguagesDictionary).includes(language)) {
      return `/${language}/${country}`;
    }
    return `/${path([country, 0], supportedLanguagesDictionary)}/${country}`; // our path has no country, but has a language
  }

  if (COUNTRIES_SET.has(country)) {
    if (!LANGUAGES_SET.has(language)) {
      return `/${country}/${COUNTRY_DOMINANT_LANGUAGE[country][0]}`;
    }
    if (propOr([], language, supportedLanguagesDictionary).includes(country)) {
      return `/${country}/${language}`;
    }
  }
  return getLocalePathFromSettings(externalLocale, pathname, true);
};

const resolvePathSuffix = ({ pathname }) =>
  compose(
    join('/'),
    without(['gb']),
    filter((item) => item.length > 2 && !COUNTRIES_AND_LANGUAGES_SET.has(item)),
    split('/')
  )(pathname);

const formatPathSuffix = (str) => {
  if (!str) {
    return '';
  }

  return str.startsWith('/') ? str : `/${str}`;
};

export const determineLocalePath = ({ location, dropSuffix, externalLocale }) => {
  const { pathname, search = '' } = location;
  const basePath = resolveCorrectLocalePath({ pathname, externalLocale });
  if (
    (basePath.length === 6 && pathname.length === 6 && pathname.match(/(\/[a-zA-Z]{2}){2}/)) ||
    dropSuffix
  ) {
    return basePath;
  }
  const pathSuffix = resolvePathSuffix({ pathname });
  const suffix = formatPathSuffix(pathSuffix);
  return `${basePath}${suffix}${search}`;
};

/**
 * @param {{
 *  country: String
 *  language: String,
 *  pathname: String,
 * }} param0
 * @returns {boolean} Whether or not match locale is supported
 */
export const validateCurrentMatchLocale = ({ country, language, pathname }) => {
  if (pathname.includes('/signout')) {
    return true;
  }
  if (!country || !language) {
    return false;
  }
  const supportedLanguagesDictionary = getSupportedLanguagesDictionary(pathname);
  if (prop(language, supportedLanguagesDictionary)) {
    /**
     * Compares the country and language from the pathname to a dictionary of supported
     * languages with their respective supported countries.
     * @param {Object} dictionary of supported languages
     */
    return compose(includes(country), propOr([], language))(supportedLanguagesDictionary);
  }
  return false;
};

export const createRelativePath = (match, pathname) =>
  `${match.url}${pathname.startsWith('/') ? pathname : `/${pathname}`}`;

/**
  * @description Returns the locale that should be used in page translations (Content Stack API calls).
  * @details Locales that are not supported by the Content Stack are being converted to supported locales, avoiding HTTP 422 errors.
  * @param {string} currentLocale String provided by the PROPS of the components. The value is defined by the DetermineLocale component, by the setLocale method.
  * When the browser locale is not supported by the content stack, in theory this value is already updated to the correct one.
  * @param {object} match Object provided by the PROPS of the components and is related to the URL of the browser.
  * @param {string} alt4 One of the four representations of the locale that comes from the browser URL.
  * @param {string} defaultLocale Default locale to be used in case the currentLocale, match and alt4 are not provided.
  * @returns {string} string with 'aa_BB' format (language_COUNTRY)
*/
export const getLocaleForTranslation = ({ currentLocale = '', match = null, alt4 = '', defaultLocale = 'en_US'} = {}) => {
  if (currentLocale) {
    return CONTENT_STACK_REDUNDANT_LOCALES[currentLocale] || currentLocale;
  }

  const country = match?.params?.country;
  const language = match?.params?.language;

  if (country && language) {
    const localeFromMatch = `${language}_${country.toUpperCase()}`;
    return CONTENT_STACK_REDUNDANT_LOCALES[localeFromMatch] || localeFromMatch;
  }

  if (alt4) {
    const [language, region] = alt4.split('_');
    const localeFromAlt4 = region ? `${language}_${region.toUpperCase()}` : alt4;
    return CONTENT_STACK_REDUNDANT_LOCALES[localeFromAlt4] || localeFromAlt4;
  }

  return defaultLocale
}

export const isChineseDomain = () => {
  const { hostname } = window.location;
  return hostname === HOSTS.PROD_CHINA || hostname === HOSTS.STAGE_CHINA;
}

export const getLocaleFromPath = () => {
  const pathname = window.location.pathname;
  const [, country, language] = pathname.split('/');
  return { country, language };
};