import styled from 'styled-components';

// pages gauge specific constants
const pagesDiam = 100;
const pagesGauge = 7;

export const PagesText = styled.div`
  line-height: ${pagesDiam}px;
  text-align: center;
  font-weight: bold;
`;

const PagesBase = styled.div`
  height: ${pagesDiam}px;
  width: ${pagesDiam}px;
  position: absolute;
  left: 0;
  top: 0;
`;

// gauges are calculated from 0 degree, this rotate all to the desired position
export const PagesPie = styled(PagesBase)`
  transform: rotate(225deg);
`;

const PagesClip = styled(PagesBase)`
  border: ${pagesGauge}px solid #e7ecf0;
  border-radius: 50%;
`;

// hides the left half side of the background circle
export const PagesPie180 = styled(PagesClip)`
  clip: rect(0, ${pagesDiam}px, ${pagesDiam}px, ${pagesDiam / 2}px);
  z-index: 2;
`;

// hides the top left quarter and right half side of the background circle
export const PagesPie90 = styled(PagesClip)`
  clip: rect(${pagesDiam / 2}px, ${pagesDiam}px, ${pagesDiam}px, 0);
  z-index: 1;
`;

// as result we will have 3/4 circle
const PagesGauge = styled(PagesClip)`
  margin: -${pagesGauge}px ${pagesGauge}px ${pagesGauge}px -${pagesGauge}px;
`;

// hides the right half side of the foreground circle
export const PagesGauge180 = styled(PagesGauge)`
  clip: rect(0, ${pagesDiam / 2}px, ${pagesDiam}px, 0);
`;

// hides the left half side of the foreground circle
export const PagesGauge90 = styled(PagesGauge)`
  clip: rect(0, ${pagesDiam}px, ${pagesDiam}px, ${pagesDiam / 2}px);
`;

// as result we will have a full foreground circle
// the foreground circle is rotated over the background circle to create effect
// of a circular gauge being filled

// pages helper functions
const levelToDeg = level => (270 / 100) * Math.min(270, Math.max(0, level));
const isTextWarning = level => level < 1;
const isGaugeWarning = level => level < 50;
const isPie180Visible = level => levelToDeg(level) < 180;
const isGauge180Visible = level => level > 0 && (levelToDeg(level) < 180);
const isGauge90Visible = level => levelToDeg(level) > 180;
const getGauge180Rotation = level => Math.min(180, levelToDeg(level));
const getGauge90Rotation = level => levelToDeg(level) - 180;

export const PagesWrapper = styled.div`
  height: ${pagesDiam}px;
  width: ${pagesDiam}px;
  position: relative;

  ${PagesText} {
    color: ${({ level }) => (isTextWarning(level) ? '#d0021b' : '#4e4e4e')};
  }

  ${PagesPie180} {
    border-color: ${({ level }) => (isPie180Visible(level) ? '#e7ecf0' : '#4e4e4e')};
  }

  ${PagesGauge180} {
    border-color: ${({ level }) => (isGaugeWarning(level) ? '#f5901b' : '#4e4e4e')};
    display: ${({ level }) => (isGauge180Visible(level) ? 'block' : 'none')};
    transform: rotate(${({ level }) => getGauge180Rotation(level)}deg);
  }

  ${PagesGauge90} {
    border-color: #4e4e4e;
    display: ${({ level }) => (isGauge90Visible(level) ? 'block' : 'none')};
    transform: rotate(${({ level }) => getGauge90Rotation(level)}deg);
  }
`;
