import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  compose,
  split,
  last,
  isEmpty,
  slice
} from 'ramda';
import {
  Button,
} from '@veneer/core';
import { useSelector } from '../../../hooks';
import { selectHPPlusProps } from '../../../selectors/home';
import svg from '../../../assets/home/logo-hp-plus.svg';
import ImageAndBrief from './ImageAndBrief';
import {
  paths,
  fontPacks,
} from '../../../utils/styleHelpers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${paths.colorsExtraLightBackgroundGrey};
  padding: 3rem 1.375rem;
  margin-bottom: 3rem;
  align-items: center;
  margin: ${props => props.isRTLSupport && '0 1.5rem'};
  width: ${props => props.isRTLSupport ? '88%' : '100%'};
  @media screen and (min-width: ${paths.breakMobile}) {
    width: ${props => props.isRTLSupport && '94%'};
  }
  @media screen and (min-width: ${paths.breakTablet}) {
    width: ${props => props.isRTLSupport && '94%'};
  }
  @media screen and (min-width: ${paths.breakWide}) {
    margin-left: 5rem;
    margin-right: ${props => props.isRTLSupport && '5rem'};
    width: 90.5%;
  }
  @media screen and (min-width: ${paths.breakImmense}) {
    width: ${props => props.isRTLSupport ? '93.75%' : '94%'};
    margin-right: ${props => props.isRTLSupport && '5rem'};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2.25rem;
  img {
    height: 3.375rem;
    width: auto;
    margin-bottom: 1.875rem;
  }
  h2 {
    ${fontPacks.titleRegularLight}
  }
  p, span > p {
    ${fontPacks.subtitleLarge}
  }
  @media screen and (min-width: ${paths.breakTablet}) {
    img {
      height: 4rem;
    }
  }
  @media screen and (min-width: ${paths.breakWide}) {
    img {
      height: 4.5rem;
    }
  }
  @media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img {
      width: 10rem;
      align-self: center;
    }
  }
`;

const BenefitBrief = styled(ImageAndBrief)`
`;

const BenefitContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  ${BenefitBrief} {
    margin: 0 auto 2.25rem;
    @media screen and (min-width: ${paths.breakTablet}) {
      margin: 0 1.5rem 3rem;
      max-width: 32rem;
    }
  }
`;

const StyledButton = styled(Button)`
  margin: 0.75rem auto 0;
  width: fit-content;
`;

const HPPlus = ({ geoCountry, instantInkSupportedCountries, isRTLSupport }) => {
  const dispatch = useDispatch();
  const props = useSelector(selectHPPlusProps, { geoCountry, instantInkSupportedCountries });

  const {
    hp_plus_button_text,
    hp_plus_headline,
    hp_plus_services,
    hp_plus_subhead,
    hp_plus_button_destination,
  } = props;
  const isInstantInkCountry = instantInkSupportedCountries.find(
    (country) => country === geoCountry
  );
  const hpPlusServicesList = hp_plus_services !== undefined &&
    (isInstantInkCountry ? hp_plus_services : [...hp_plus_services].slice(1));
  const handleClick = useCallback(() => {
    if (hp_plus_button_destination.includes('http')) {
      if (hp_plus_button_destination.includes('/openid/v1/authorize?client_id=')) {
        window.location.href = hp_plus_button_destination;
        return;
      }
      window.open(hp_plus_button_destination, '_blank');
      return;
    }
    dispatch(push(hp_plus_button_destination));
  }, [hp_plus_button_destination]);

  if (isEmpty(props)) {
    return null;
  }

  return (
    <Container isRTLSupport={isRTLSupport}>
      <Header>
        <img
          id="graphic"
          src={svg}
          alt="hp plus logo"
        />
        {
          hp_plus_headline
            && (
              <h2 dangerouslySetInnerHTML={{ __html: hp_plus_headline }} />
            )
        }
        {
          hp_plus_subhead
            && (
              <span dangerouslySetInnerHTML={{ __html: hp_plus_subhead }} />
            )
        }
      </Header>
      <BenefitContainer>
        {
          hpPlusServicesList.map(svc => {
            const { service_block } = svc;
            const {
              icon,
              body,
              head,
            } = service_block;
            const {
              title: iconTitle,
              url: iconUrl,
            } = icon;
            const uniqueKey = compose(
              last,
              split('/'),
            )(iconUrl);
            return (
              <BenefitBrief
                key={`hp-plus-svc-${uniqueKey}`}
                invertImg
                vertical
                src={iconUrl}
                alt={iconTitle}
                title={head}
                copy={body}
              />
            );
          })
        }
      </BenefitContainer>

      <StyledButton
        onClick={handleClick}
      >
        {hp_plus_button_text}
      </StyledButton>
    </Container>
  );
};

export default HPPlus;
