import React, { useEffect } from 'react';
import Config from '../../../config/Config';
import { Button } from '@veneer/core';
import { useHistory } from 'react-router';
import { userOSDetector } from '../../../utils/userOSDetector';
import {
  SetupContainer,
  SetupHeader,
  Features,
  PrinterImage,
  BeginSetupButtonText,
  Line,
  AccessPrinterText,
  DownloadDriversButtonText
} from './styles';
import { 
  WINDOWS_OS,
  MAC_OS,
  EASY_START_PRO_URL,
  SMB_NO_SETUP_URL,
  DOWNLOAD_PAGE_URL,
  GET_SOFTWARE_PAGE_URL,
  SETUP_PAGE_URL,
  UNSUPPORTED_OS_LIST,
  SETUP
} from '../../../constants/common';
import { prepareGATrackingForSMB } from '../../../lib/analyticsTracking';

const config = new Config();
const hpOOBEURL = config.Get('SETUP_SERVER_HP_OOBE');

const Setup = (props) => {
  const history = useHistory();

  const onBeginSetupButtonClick = () => {
    try {
      const userOperatingSystem = userOSDetector();

      if (userOperatingSystem.indexOf(WINDOWS_OS) !== -1) {
        window.location.assign(hpOOBEURL);
      } else if (userOperatingSystem.indexOf(MAC_OS) !== -1) {
        window.location.assign(hpOOBEURL.concat(EASY_START_PRO_URL));
      } else {
        history.push(SMB_NO_SETUP_URL);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect( ()=> {
    prepareGATrackingForSMB(SETUP);
  },[props])

 const onDownloadDriversButtonClick = () => {  
  const userOperatingSystem = userOSDetector();
  if(UNSUPPORTED_OS_LIST.includes(userOperatingSystem))  {
    const pageURL = history.location.pathname.replace(SETUP_PAGE_URL, GET_SOFTWARE_PAGE_URL)
    window.open(pageURL, "_blank");
  }
  else {
    const pageURL = history.location.pathname.replace(SETUP_PAGE_URL, DOWNLOAD_PAGE_URL)
    window.open(pageURL, "_blank");
  }
}

  return (
    <SetupContainer>
      <SetupHeader>{props.setupHeader}</SetupHeader>
      <Features dangerouslySetInnerHTML={{ __html: props.features }} />
      <PrinterImage alt={props.printerImageAlt} src={props.printerImage} />
      <Button className="beginSetupButton" id="begin-setup-button" appearance="primary" onClick={onBeginSetupButtonClick}>
        <BeginSetupButtonText >{props.beginSetupButtonText}</BeginSetupButtonText>
      </Button>
      <Line />
      <AccessPrinterText>{props.accessPrinterText}</AccessPrinterText>
      <Button
        className="downloadDriversButton"
        appearance="secondary"
        id="download-driver-button"
        onClick={onDownloadDriversButtonClick}
      >
        <DownloadDriversButtonText id="download-hp-drivers-button">{props.accessPrinterButtonText}</DownloadDriversButtonText>
      </Button>
    </SetupContainer>
  );
};

export default Setup;
