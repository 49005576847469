/* eslint-disable */
// TODO: disable eslint for the default export rule. reenable when more methods are put in this file
import { gateway } from '../utils/request';

export const updateAccessOption = ({ ownershipId, printerId, generation, accessOption }) => {
  return gateway({
    method: generation === 1 ? 'put' : 'post',
    url: `/printers/${generation === 1 ? printerId : ownershipId}/configuration`,
    data: {
      configuration: { accessOption },
      generation,
    }
  });
};