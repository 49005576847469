import React, { Component } from 'react';
// import TextButton from '../shared-components/atoms/textButton';
import { prop, path, } from 'ramda';
import TweenLite, { Power3 } from 'gsap'
import bind from './../bind';
import styled from 'styled-components';
import { getCookie } from "../../lib/manageCookies";
import { GLOBAL_NAV_BAR_RESOURCE_NAME } from '../../constants/contentStackResources';

const BannerBackground = styled.div`
  background-color: #e88233;
`
const Banner = styled.div`
  opacity: 0;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: column nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
  padding: 0.625rem 0 0.625rem 1.25rem;
  flex-direction: row;
  @media screen and (min-width: 40rem) {
    padding: 1rem 0 1.375rem 1.5625rem;
  }
  width: 100%;
  color: white;
  a {
    color: white;
    text-decoration: underline;
  }
  justify-content: space-between;
`
const BannerText = styled.div`
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  @media screen and (min-width: 40rem) {
    flex-direction: column;
  }
`
const BannerHead = styled.div`
  display: none;
  font-size: 1.25rem;
  @media screen and (min-width: 40rem) {
    display: block;
    font-size: 1.5rem;
  }
  font-family: 'hp-simplified', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
`
const BannerMessage = styled.div`
  padding-right: 1.25rem;
  flex-basis: 100%;
  line-height: 1.5;
  font-family: 'hp-simplified', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-size: 1rem;
  @media screen and (min-width: 40rem) {
    flex-basis: auto;
  }
`
const BannerOption = styled.a`
  padding-right: rem-calc(20);
  font-family: 'hp-simplified', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-size: rem-calc(14);
`
const DismissBanner = styled.div`
  display: flex;
  align-items: center;
  a {
    padding: 0.625rem 1.6875rem 0.625rem 1.25rem;
    @media screen and (min-width: 40rem) {
      padding: 0 1.25rem;
    }
    img {
      max-width: inherit;
    }
  }
`

class NotificationBanner extends Component {
  constructor(props) {
    super(props);
    this.bannerHeight = 120 // THIS IS A MAX HEIGHT, NOT EXACT 
    this.hide = false
    this.animateOnOff = this.animateOnOff.bind(this)
  }

  animateOnOff = (ON) => {
    const notificationClosed = getCookie("notificationClosed") === "true"
    ON = notificationClosed ? false : ON
    let num = ON ? 1 : 0;
    let bannerheight = this.bannerHeight * num;
    const inPageNav_exists = document.getElementById("inPageNav")
    if (inPageNav_exists) {
      TweenLite.set("#notificationBanner", { zIndex: 4 });
    }
    let completed = () => {
      if (!ON) {
        this.hide = true
        this.forceUpdate()
      }
    };
    let easing = ON ? Power3.easeIn : Power3.easeOut
    ///////////////////////////////////////////////
    TweenLite.to("#notificationBanner", 1, { maxHeight: bannerheight, ease: easing, onComplete: completed });
    // NEEDS TO BE REFINED: CLOSING does not obey overflow hidden
    TweenLite.to("#notificationContent", .5, { ease: easing, opacity: num, delay: (0.1 + num) * .45 })
    //////////////
    if (!ON && document.getElementById("swipeActionable") !== null && document.getElementById("notificationBanner") !== null) {
      let actualBannerHeight = document.getElementById("notificationBanner").getBoundingClientRect().height
      let actualSwiperTop = document.getElementById("swipeActionable").getBoundingClientRect().top
      const newSwiperTop = actualSwiperTop - actualBannerHeight
      TweenLite.to("#swipeActionable", 1, { top: newSwiperTop });
    }
  }

  componentDidMount() {
    let defferedFn = () => {
      this.animateOnOff(true)
    }
    setTimeout(defferedFn, 4000);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.ON) {
      this.animateOnOff(nextProps.ON)
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  render() {
    const {
      contentStack
    } = this.props;
    if (!prop(GLOBAL_NAV_BAR_RESOURCE_NAME, contentStack)) {
      return null;
    }

    const bannerData = path([GLOBAL_NAV_BAR_RESOURCE_NAME, 'data', 'entries', 0, 'notifications_banner'], contentStack);
    if (prop('show_banner', bannerData) === "0" || !prop('message', bannerData)) {
      return null;
    }

    return (
      <BannerBackground id="notificationBanner" style={{ maxHeight: '0px' }} >
        {!this.hide &&
          <Banner id="notificationContent"   >
            <BannerText>
              <BannerHead>{bannerData.headline}</BannerHead>
              <BannerMessage>
                {bannerData.message}
                {bannerData.switchBack != "" && " "}
                {bannerData.switchBack != "" && <BannerOption id="notificationBannerLink1" href={bannerData.inlineLinkUrl} >{bannerData.inlineLink}</BannerOption>} {/** TODO: double check this navigation element with Sean */}
              </BannerMessage>

            </BannerText>
            <DismissBanner>
              <a id="notificationBannerClose" onClick={this.props.closeBannerFn} >
                <img className="icon--dismiss" src="/assets/img/common/icon_dismiss.svg" alt="" />
              </a>
            </DismissBanner>
          </Banner>
        }
      </BannerBackground>
    );
  }
}

export default bind(NotificationBanner);  