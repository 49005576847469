import React, { Component } from 'react';
import classNames from 'classnames';
import AccordionTitle from '../../shared-components/molecules/AccordionTitle';
import LoaderElement from '../../shared-components/molecules/LoaderElement';
import EprintAccessOptions from './eprint-access/EprintAccessOptions';
import SettingsSection from './SettingsSection';
import AreaTypes from '../../../constants/AreaTypes';
import bind from '../../bind';

class EprintSettingsAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      activeOption: this.getActiveOption(this.props.myPrinter.data.WPPData),
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleChoice = this.handleChoice.bind(this);
    this.isReset = false;
  }

  toggleAccordion() {
    this.$container = this.refs.accordionContainer;
    if (this.$container === undefined) return false; // // necessary for cases where this is used outside of accordion - widescreen
    this.$content = this.$container.querySelector('.accordionContent');
    const animationSpeed = 0.5;
    const getContentHeight = () => `${this.$content.scrollHeight}px`;
    if (this.stateactive) {
      this.$content.style.maxHeight = getContentHeight();
      TweenLite.to(this.$content, animationSpeed, { ease: Power3.easeOut, maxHeight: '0px' });
      this.stateactive = false;
    } else {
      TweenLite.to(this.$content, animationSpeed, {
        ease: Power3.easeOut,
        maxHeight: getContentHeight(),
        onComplete: () => {
          this.$content.style.maxHeight = 'none';
        },
      });
      this.stateactive = true;
    }
  }

  componentWillReceiveProps(nextProps) {
    const nextPrinter = nextProps.myPrinter.data.WPPData;
    if (!nextPrinter.hasOwnProperty('printerCurrentAccess')
      || !nextPrinter.hasOwnProperty('accessOptions')) {
      return;
    }

    if (this.props.active !== nextProps.active && nextProps.active !== this.stateactive || this.isReset) {
      this.isReset = false;
      this.toggleAccordion();
    }

    if (this.props.isTablet !== nextProps.isTablet && nextProps.isTablet === false) {
      this.stateactive = null;
      this.isReset = true;
      this.props.handleToggle('', true);
    }

    const activeOption = this.getActiveOption(nextPrinter);
    this.setState({
      activeOption,
    });
  }

  getActiveOption(printer) {
    let activeOption = 0;
    for (let i = 0, len = printer.accessOptions.length; i < len; i++) {
      const current = printer.accessOptions[i];
      if (current.Key == printer.printerCurrentAccess) {
        activeOption = i;
      }
    }
    return activeOption;
  }

  /**
   * Call parent's handleToggle
   */
  handleToggle() {
    if (this.propsactive) {
      this.props.resolveMyPrinterAccessOption(this.props.myPrinter.data.WPPData);
    }
    this.props.handleToggle('access');
  }

  handleChoice(value) {
    const myPrinter = this.props.myPrinter.data.WPPData;
    if (!myPrinter.accessOptions[value]) {
      return;
    }
    this.props.updateMyPrinterAccessOption(myPrinter.ownershipId, myPrinter.printerId, myPrinter.accessOptions[value].Key);
  }

  getCurrentOption() {
    const myPrinter = this.props.myPrinter.data.WPPData;
    if (!myPrinter.hasOwnProperty('accessOptions')) return {};
    if (myPrinter.accessOptions[this.state.activeOption] === undefined) {
      // TODO: PUT THIS IN A FUNCTION! IT'S DUPLICATE CODE!
      let activeOption = 0;
      for (let i = 0, len = myPrinter.accessOptions.length; i < len; i++) {
        const current = myPrinter.accessOptions[i];
        if (current.Key == myPrinter.printerCurrentAccess) {
          activeOption = i;
        }
      }
      return myPrinter.accessOptions[activeOption];
    }
    return myPrinter.accessOptions[this.state.activeOption];
  }

  trackingHookFn(ID) {
    document.getElementById(ID).click();
  }

  render() {
    const containerClass = classNames(
      'accordion',
      'myPrinterSetting',
      'ePrintSettingsAccess',
      { 'accordion-active': this.stateactive },
    );
    const myPrinter = this.props.myPrinter.data.WPPData;
    const { accessSettingData } = this.props.myPrinter.data;
    const currentOption = this.getCurrentOption();
    const isPending = this.props.myPrinter.isFetching && !this.props.myPrinter.isComplete && this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS;
    return (
      <div className="printerGrid--printerCell ePrintSection--access">
        <a name="tracking-hook" id="eprint-access-open" />
        <a name="tracking-hook" id="eprint-access-blocked" />
        <a name="tracking-hook" id="eprint-access-allowed" />
        <a name="tracking-hook" id="eprint-access-add" />
        {
          this.props.isTablet
            ? (
              <div ref="accordionContainer" className={containerClass}>
                <AccordionTitle
                  ID="eprint-access" loading={isPending}
                  accordionTitle={accessSettingData.eprint_access_title}
                  onClick={this.handleToggle}
                >
                  {
                    isPending
                      ? <LoaderElement />
                      : <span className="accordionTitle--status">{currentOption.Label}</span>
                  }
                </AccordionTitle>
                <div className="accordionContent">
                  <EprintAccessOptions
                    trackingHook={this.props.trackingHandler}
                    printer={this.props.printer}
                    options={myPrinter.accessOptions}
                    currentOption={this.state.activeOption}
                    handleChoice={this.handleChoice}
                    printerCurrentAccess={myPrinter.printerCurrentAccess}
                    changePrinterState={this.props.changePrinterState}
                    accessSettingData={accessSettingData}
                  />
                </div>
              </div>
            ) : (
              <SettingsSection sectionTitle={accessSettingData.eprint_access_title}>
                <EprintAccessOptions
                  isPending={isPending} inWidescreen
                  trackingHook={this.props.trackingHandler}
                  printer={this.props.printer}
                  options={myPrinter.accessOptions}
                  currentOption={this.state.activeOption}
                  handleChoice={this.handleChoice}
                  printerCurrentAccess={myPrinter.printerCurrentAccess}
                  changePrinterState={this.props.changePrinterState}
                  accessSettingData={accessSettingData}
                />
              </SettingsSection>
            )
        }
      </div>
    );
  }
}

export default bind(EprintSettingsAccess);
