import React, { Component } from 'react';
import AccordionTitle from "../shared-components/molecules/AccordionTitle";
import LoaderElement from "../shared-components/molecules/LoaderElement";
import EprintAddressChange from './EprintAddressChange';
import SettingsSection from './eprint-settings/SettingsSection';
import classNames from 'classnames';
import AreaTypes from "../../constants/AreaTypes";
import bind from "../bind";


class EprintAddressSettings extends Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
    this.stateactive = this.props.active
    this.toggleAccordion = this.toggleAccordion.bind(this)
    this.isReset = false
    this.myID = this.props.ID || ""
  }

  toggleAccordion() {
    this.$container = this.refs.accordionContainer;
    if (this.$container === undefined) return false //// necessary for cases where this is used outside of accordion - widescreen
    this.$content = this.$container.querySelector('.accordionContent');
    let animationSpeed = 0.5,
      getContentHeight = () => {
        return this.$content.scrollHeight + "px";
      };
    if (this.stateactive) {
      this.$content.style.maxHeight = getContentHeight();
      TweenLite.to(this.$content, animationSpeed, { ease: Power3.easeOut, maxHeight: "0px" });
      this.stateactive = false;
    } else {
      TweenLite.to(this.$content, animationSpeed, {
        ease: Power3.easeOut, maxHeight: getContentHeight(), onComplete: () => {
          this.$content.style.maxHeight = 'none';
        }
      });
      this.stateactive = true;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active && nextProps.active !== this.stateactive || this.isReset) {
      this.isReset = false
      this.toggleAccordion(); /////  THIS IS THE INHERITED COMPONENT
    }
    if (this.props.isTablet !== nextProps.isTablet && nextProps.isTablet === false) {
      this.stateactive = null;
      this.isReset = true
      this.props.handleToggle("", true);
    }
  }

  handleToggle() {
    this.props.handleToggle("address");
  }

  trackingHookFn(ID) {
    document.getElementById(ID).click()
  }

  render() {
    let myPrinter = this.props.myPrinter.data.WPPData;
    let addressSettingData = this.props.myPrinter.data.addressSettingData;
    let containerClass = classNames(
      "accordion",
      "myPrinterSetting",
      "ePrintSettingsAddress",
      { "accordion-active": this.stateactive }
    );
    let isPending = this.props.myPrinter.isFetching && !this.props.myPrinter.isComplete && this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_EPRINT_SETTINGS_EMAIL;
    return (
      <div className="printerGrid--printerCell ePrintSection--address">
        <a name="tracking-hook" id="eprint-email-cancel"></a>
        <a name="tracking-hook" id="eprint-email-submit"></a>
        <a name="tracking-hook" id="eprint-email-send-now"></a>

        {this.props.isTablet ||
          <SettingsSection sectionTitle={addressSettingData.eprint_email_change_title}>
            <EprintAddressChange ID={this.myID} loading={isPending} trackingHook={this.props.trackingHandler} printer={myPrinter} handleToggle={this.handleToggle} inModal={this.props.inModal} />
          </SettingsSection>
        }

        {this.props.isTablet &&

          <div id="accordion_container" ref="accordionContainer" className={containerClass}>
            <AccordionTitle ID="eprint-email" loading={isPending} accordionTitle={addressSettingData.eprint_email_change_title} onClick={this.handleToggle}>
              {isPending ? <LoaderElement /> : <span className="accordionTitle--status">{this.props.printer.printerEmail}</span>}
            </AccordionTitle>
            <div className="accordionContent">
              <EprintAddressChange ID={this.myID} inTablet={true} loading={isPending} trackingHook={this.props.trackingHandler} printer={myPrinter} handleToggle={this.handleToggle} inModal={this.props.inModal} />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default bind(EprintAddressSettings);
