import { pathOr } from 'ramda';

export const SupplyNames = {
  C: 'C',
  M: 'M',
  Y: 'Y',
  K: 'K',
  TONER: 'toner',
  TRICOLOR: 'tricolor',
  LARGE: 'large', // currently unsupported response from backend
  PAGES: 'pages', // currently unsupported response from backend
  PAPER: 'paper', // currently unsupported response from backend
};

export const SuppliesTricolor = new Set([
  SupplyNames.C,
  SupplyNames.M,
  SupplyNames.Y,
]);

const SupplyNamesOrder = {
  DEFAULT: [
    SupplyNames.TRICOLOR,
    SupplyNames.C,
    SupplyNames.M,
    SupplyNames.Y,
    SupplyNames.K,
    SupplyNames.TONER,
    SupplyNames.LARGE,
    SupplyNames.PAGES,
    SupplyNames.PAPER,
  ],
  INK_CMYK: [
    SupplyNames.C,
    SupplyNames.M,
    SupplyNames.Y,
    SupplyNames.K,
  ],
  INK_MCYK: [
    SupplyNames.M,
    SupplyNames.C,
    SupplyNames.Y,
    SupplyNames.K,
  ],
  INK_TRICOLOR: [
    SupplyNames.TRICOLOR,
    SupplyNames.K,
  ],
};

export const getSupplyNamesOrder = supplyType => pathOr(SupplyNamesOrder.DEFAULT, [supplyType], SupplyNamesOrder);
