import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { paths, fontPacks } from '../../../utils/styleHelpers';
import { useSelector } from '../../../hooks';
import { selectServiceDetails } from '../../../selectors/home';
import ServiceItem from './ServiceItem';
import { getInstantInkSupportedCountries, getInstantInkPlanPrice } from '../../../utils/helperApi';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 3rem;
  margin: ${(props) => props.isRTLSupport && '0 1.5rem'};
  @media screen and (min-width: ${paths.breakWide}) {
    margin: 0 5rem 0 5rem;
  }
`;

const SectionHeader = styled.div`
  padding: 1rem;
  @media screen and (min-width: ${paths.breakMobile}) {
    display: flex;
    flex-direction: column;
  }
`;

const Headline = styled.h3`
  ${fontPacks.titleRegularLight}
`;

const Subhead = styled.span`
  max-width: 100%;
  ${fontPacks.bodyLarge}
`;

const Grid = styled.div`
  display: flex;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  background-color: ${paths.colorsLightBackgroundGrey};
  width: 100%;
  height: 100%;
`;

const ServiceDetails = ({ geoCountry, instantInkSupportedCountries, isInstantInkSupported, isRTLSupport, locales }) => {
  const props = useSelector(selectServiceDetails);
  const [instantInkPrice, setInstantInkPrice] = useState();

  useEffect(() => {
    isInstantInkSupported === true &&
      getInstantInkPlanPrice(locales?.alt5).then((res) => {
        const filteredInkPlansByFrequencyLight = Object.values(res?.data?.data).find(item => item.frequency === "light");
        setInstantInkPrice(res?.data?.success === true ? filteredInkPlansByFrequencyLight?.price : null);
      })
  }, [isInstantInkSupported, locales?.alt5]);

  if (!props) {
    return null;
  }

  const { safe_service: service, service_headline, service_subhead } = props;
  const instantInkSupported = instantInkSupportedCountries.includes(geoCountry);

  return (
    <Container isRTLSupport={isRTLSupport}>
      <SectionHeader>
        <Headline>{service_headline}</Headline>
        <Subhead dangerouslySetInnerHTML={{ __html: service_subhead }} />
      </SectionHeader>
      <Grid>
        {service.map(({ service_block }, index) => {
          const {
            body,
            header,
            button_destination: buttonDestination,
            button_text: buttonText,
            footer,
            service_image,
            service_image_2x
          } = service_block;

          let newBody = body.replace("+[instantInkPrice]", instantInkPrice);

          const { title: serviceImageTitle, url: serviceImageUrl } = service_image;

          const { title: serviceImage2xTitle, url: serviceImage2xUrl } = service_image_2x;

          if (!instantInkSupported && index === 0) return false;
          else
            return (
              <ServiceItem
                key={header}
                body={newBody}
                buttonDestination={buttonDestination}
                buttonText={buttonText}
                footer={footer}
                header={header}
                serviceImageTitle={serviceImageTitle}
                serviceImageUrl={serviceImageUrl}
              />
            );
        })}
      </Grid>
    </Container>
  );
};

export default ServiceDetails;
