import { createSelector } from 'reselect';
import {
  identity,
  prop,
  path,
} from 'ramda';

const selectorsBase = {
  GET_USER: prop('ucdeUser'),
};

export const selectUcdeUser = createSelector(
  selectorsBase.GET_USER,
  identity,
);

export const selectUserTenantId = createSelector(
  selectorsBase.GET_USER,
  prop('tenantResourceId'),
);

export const selectUcdeUserFullName = createSelector(
  selectorsBase.GET_USER,
  user => (
    user && user.givenName && user.familyName
      ? `${user.givenName} ${user.familyName}`
      : ''
  ),
);

export const selectUcdeUserApiError = createSelector(
  selectorsBase.GET_USER,
  prop('loadUserApiError'),
);

export const selectUcdeUserLoading = createSelector(
  selectorsBase.GET_USER,
  prop('loading'),
);

export const selectUcdeUserLoggedIn = createSelector(
  selectorsBase.GET_USER,
  ucdeUser => !!path(['resourceId'], ucdeUser),
);

export const selectUcdeUserLocale = createSelector(
  selectorsBase.GET_USER,
  prop('locale'),
);

export const selectUcdeUserLocaleProps = createSelector(
  selectorsBase.GET_USER,
  user => {
    const {
      country,
      language,
      locale,
    } = user;
    if (country && language) {
      return {
        country,
        language,
      };
    }
    const [la, co] = locale.split('_');
    return {
      country: co,
      language: la,
    };
  },
);
