import React, { useState } from 'react';
import {
  Button,
  Modal,
} from '@veneer/core';
import PropTypes from 'prop-types';
import {
  render,
  unmountComponentAtNode,
} from 'react-dom';
import {
  Content,
  Icon,
} from './styles';
import i18n from '../../utils/i18n';

const containerDomNode = document.createElement('div');
containerDomNode.id = 'ucde-error-modal';

const ErrorModal = ({
  title,
  message,
  actionLabel,
  actionCallback,
  dismissLabel,
  dismissCallback,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const removeErrorModal = () => {
    if (onClose) {
      onClose();
    }

    setIsVisible(false);
    unmountComponentAtNode(containerDomNode);
    containerDomNode.parentNode.removeChild(containerDomNode);
  };

  const ActionButtons = () => {
    const handleAction = () => {
      if (actionCallback) {
        actionCallback();
      }

      removeErrorModal();
    };

    const handleDismiss = () => {
      if (dismissCallback) {
        dismissCallback();
      }

      removeErrorModal();
    };

    if (actionLabel) {
      return (
        <div className="vn-button-group--responsive">
          <Button ariaLabel={actionLabel} onClick={handleAction}>
            {actionLabel}
          </Button>
          {dismissLabel && (
            <Button
              appearance="tertiary"
              ariaLabel={dismissLabel}
              onClick={handleDismiss}
            >
              {dismissLabel}
            </Button>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <Modal
      id="regular-modal"
      onClose={removeErrorModal}
      show={isVisible}
      footer={<ActionButtons />}
      align="center"
    >
      <Content
        data-testid="dashboard-error-modal"
      >
        <div>
          <div>
            <Icon size={48} />
            <h4>{title}</h4>
            <p>{message}</p>
          </div>
        </div>
      </Content>
    </Modal>
  );
};

export const renderErrorModal = ({
  title,
  errorMessage,
  actionLabel,
  actionCallback,
  dismissLabel,
  dismissCallback,
  onClose,
} = {}) => {
  document.body.appendChild(containerDomNode);

  render(
    <ErrorModal
      title={title}
      message={errorMessage}
      actionLabel={actionLabel}
      actionCallback={actionCallback}
      dismissLabel={dismissLabel}
      dismissCallback={dismissCallback}
      onClose={onClose}
    />,
    containerDomNode,
  );
};

ErrorModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  actionLabel: PropTypes.string,
  actionCallback: PropTypes.func,
  dismissLabel: PropTypes.string,
  dismissCallback: PropTypes.func,
  onClose: PropTypes.func,
};

ErrorModal.defaultProps = {
  title: i18n.t('home.error.retrieveData.title'),
  message: i18n.t('home.error.retrieveData.message'),
  actionLabel: i18n.t('home.error.confirmLabel'),
  actionCallback: i18n.t(() => window.location.reload()),
  dismissLabel: i18n.t('home.error.cancelLabel'),
  dismissCallback: undefined,
  onClose: undefined,
};

export default Modal;
