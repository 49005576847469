export const RIGHT = 'right';
export const LEFT = 'left';
export const DESKTOP_SCREEN = 768;
export const BREAKPOINT = 1024;
export const ADDITIONAL_OFFSET = 50;

/* Home Page */
export const HOME = 'home';
export const AU = 'au';

/* Lazy Loading Component */
export const LAZY_COMPONENT_SEC_1 = 1;
export const LAZY_COMPONENT_SEC_2 = 2;
export const LAZY_COMPONENT_SEC_3 = 3;
export const LAZY_COMPONENT_SEC_4 = 4;
export const LAZY_COMPONENT_SEC_5 = 5;
export const LAZY_COMPONENT_SEC_6 = 6;
export const LAZY_COMPONENT_SEC_7 = 7;

/** STACKS */
export const STACK_STAGE = 'stage';
export const STACK_PROD = 'prod';
export const STACK_DEV = 'dev';
export const STACK_PIE = 'pie';

/** LEBI Files */
export const LEBI_ST580 = 'ST580-590';
export const LEBI_ST520 = 'ST520-540';
export const LEBI_ST210 = 'ST210-220';

/** Un Suported OS List */
export const UNSUPPORTED_OS_LIST = ['Windows 7', 'Windows 8', 'Windows 8.1', 'Chrome OS'];

/** SMB Setup Component */
export const WINDOWS_OS = 'Windows';
export const MAC_OS = 'Mac';
export const EASY_START_PRO_URL = '/easystartpro';
export const SMB_NO_SETUP_URL = '/smb-no-setup';
export const DOWNLOAD_PAGE_URL = '/download';
export const GET_SOFTWARE_PAGE_URL = '/get-software';
export const SETUP_PAGE_URL = '/setup';

/** My Printer Component */
export const GEN2YETI = 'gen2Yeti';

/** Hero Carousel Component  */
export const RENDER_BOTTOM_LEFT_CONTROLS = 'renderBottomLeftControls'
export const RENDER_BOTTOM_RIGHT_CONTROLS = 'renderBottomRightControls'

/** Keywords For GA */
export const DOWNLOAD = 'download';
export const GET_SOFTWARE = 'get-software';
export const SETUP = 'setup';

/** keywords for Footer */
export const YOUR_PRIVACY_CHOICES = 'your-privacy choices';
export const HP_PRIVACY = 'hp-privacy';
export const HP_DOT_COM = 'hp-dot com';
export const HP_URL = 'https://hp.com';

/** Keywords for Header and Home */
export const HELP_AND_SUPPORT = 'link-to-https://support.hp.com';
export const INSTANT_INK_URL = 'https://instantink.hpconnected.com'; // This value is also coming from the contentStack, so if the value changes in the contentStack, it should be updated here as well.
export const ONE_TWO_THREE_URL = 'https://123.hp.com';
export const SUPPORT_URL = 'https://support.hp.com';

/** Entry ID for Terms of Use */
export const CS_TOU_ENTRY_ID = 'blt4294af20834931ca';
export const CS_ADMIN_TOU_ENTRY_ID = 'blt97a48f80754a7f99';
export const CS_HPAPP_TOU_ENTRY_ID = 'blt4ee296e359388569';

/** Entry ID for HP Telemetry */
export const CS_PS_TELEMETRY_ENTRY_ID = 'bltd14264ad2efcf1d9';

/** Keywords For Error Component */
export const ERROR_AUTHZ = 'authz';
export const ERROR_INVITATION = 'invitation';

export const ERROR_AUTHZ_ENTRYID = 'blt34f7bff1452344a1';
export const ERROR_INVITATION_ENTRYID = 'bltfc42793394201095';

/** Entry ID for SMB Download */
export const CS_SMB_DOWNLOAD_COMMON_ENTRY_ID = 'blted8315c305b7db32';
export const CS_SMB_DOWNLOAD_WIN7_ENTRY_ID = 'blt0e3ca7dcdff4f070';
export const CS_SMB_DOWNLOAD_CHROMEOS_ENTRY_ID = 'bltcedaa14e9ba90373';
export const CS_SMB_DOWNLOAD_GETSOFTWARE_ENTRY_ID = 'blt72879830c2413d06';
export const CS_SMB_DOWNLOAD_UNSUPPORTEDOS_ENTRY_ID = 'blt12bfdd240225fec4';

/** keywords for Navigation Headers */
export const ENTRYID_GLOBAL_NAV_LINKS = 'blta8f4f0f1eea2b8a5';
export const ENTRYID_GLOBAL_NAV_ACCOUNT_LINKS = 'bltb2f48ce7402dbdaf';
