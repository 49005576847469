import React, { Component } from 'react';
// import BlueCheckmark from "./../../shared-components/atoms/BlueCheckmark";
// import classNames from 'classnames';
import bind from './../../bind';

class SettingsSection extends Component{
    constructor(props){
        super(props);
    }
    render(){        
        return (<div className="printerCell--settings">
            <h4 className="setting--title">{this.props.sectionTitle}</h4>          
            {this.props.children}
        </div>);
    }
}

export default bind(SettingsSection);