import Config from '../config/Config';
let config = new Config();
export default class Logger {
    static Log(message, b='', c='', d='', e='') {
        if (!config.Get('LOGGER_ENABLED')) return;
        console.log(`Logger: `, message, b, c, d, e);
    }

    static Error(message, b='') {
        if (!config.Get('LOGGER_ENABLED')) return;
        console.error(`Logger: `, message, b);
    }

    static Warning(message, b='') {
        if (!config.Get('LOGGER_ENABLED')) return;
        console.warn(`Logger: `, message, b);
    }
}

// SHORTCUT
export function log(a, b, c, d, e) {
    Logger.Log(a, b, c, d, e)
} 

////////////////////////////// Useage
// import { Logger, log } from "./../../../lib/logger"
//
// Logger.Log(“Hello world”);
// Logger.Warning(“Hello world”);
// Logger.Error(“hello world”);
//   or
// let world = "World"
// log("Hello", world); //// SAME AS: Logger.Log(“Hello", world);

