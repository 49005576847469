import {
    path,
    identity
} from 'ramda';
import { createSelector } from 'reselect'

const SELECTORS_BASE = {
    selectCurrentLocale: path(['locales', 'currentLocale']),
}

export const selectCurrentLocale = createSelector(
    SELECTORS_BASE.selectCurrentLocale,
    identity,
)
