import {
  IconCheckCircle,
  IconMinusCircle,
  IconWarning,
} from '@veneer/core';

export const hpPlusStatus = {
  GOOD: {
    statusLabelId: 'home.status.item1.state.green',
    Icon: IconCheckCircle,
    iconColor: 'colorGreen5',
  },
  NOT_CONNECTED: {
    statusLabelId: 'home.status.item1.state.yellow',
    Icon: IconWarning,
    iconColor: 'colorYellow5',
  },
  NON_GENUINE: {
    statusLabelId: 'home.status.item1.state.red1',
    Icon: IconMinusCircle,
    iconColor: 'colorRed5',
  },
  UNKNOWN: {
    statusLabelId: 'home.status.item.unknown',
    Icon: IconMinusCircle,
    iconColor: 'colorGray10',
  },
};

export const printerStatus = {
  STOPPED: {
    statusLabelId: 'home.status.item3.state.red',
    Icon: IconMinusCircle,
    iconColor: 'colorRed5',
  },
  NEEDS_ATTENTION: {
    statusLabelId: 'home.status.item3.state.yellow',
    Icon: IconWarning,
    iconColor: 'colorYellow5',
  },
  GOOD: {
    statusLabelId: 'home.status.item3.state.green',
    Icon: IconCheckCircle,
    iconColor: 'colorGreen5',
  },
  UNKNOWN: {
    statusLabelId: 'home.status.item.unknown',
    Icon: IconMinusCircle,
    iconColor: 'colorGray10',
  },
};

export const smartSecurityStatus = {
  NOT_PROTECTED: {
    statusLabelId: 'home.status.item2.state.red',
    Icon: IconMinusCircle,
    iconColor: 'colorRed5',
  },
  SECURITY_DISABLED: {
    statusLabelId: 'home.status.item2.state.yellow',
    Icon: IconWarning,
    iconColor: 'colorYellow5',
  },
  PROTECTED: {
    statusLabelId: 'home.status.item2.state.green',
    Icon: IconCheckCircle,
    iconColor: 'colorGreen5',
  },
  UNKNOWN: {
    statusLabelId: 'home.status.item.unknown',
    Icon: IconMinusCircle,
    iconColor: 'colorGray10',
  },
};
