import axios from "axios";
import CraftApiModel from "../models/CraftApiModel";
import { getApiPathWithLocale } from "../lib/urlUtils";
import ActionTypes from "../constants/ActionTypes";

/**
 * This function uses axios to get mobile fax data.
 *
 * Since axios returns promises, we can dispatch our start event and
 * update it to complete or complete with error whe the promise returns.
 *
 * @returns {function(*)}
 */
export function getMobileFax(currentLocale = "") {
  let start = () => ({
    type: ActionTypes.START_MOBILEFAX_FETCH
  }),
    complete = (mobileFaxData) => ({
      type: ActionTypes.COMPLETE_MOBILEFAX_FETCH,
      mobileFaxData
    }),
    error = err => ({
      type: ActionTypes.COMPLETE_MOBILEFAX_FETCH_WITH_ERROR,
      err
    });
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(start());
      axios
        .get(
          getApiPathWithLocale("/api/mobile-fax.json", currentLocale)
        )
        .then(res => {
          let mobileFaxData = (res.data && res.data.data) ? new CraftApiModel(res.data.data.length > 0 ? res.data.data : []) : new CraftApiModel({})
          dispatch(complete(mobileFaxData));
          return resolve(mobileFaxData);
        })
        .catch(err => {
          if (err.response && err.response.status === 403) {
            dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT })
          }
          dispatch(error(err));
          reject(err);
        });
    });
  };
}
