import React from 'react';
import { useSelector } from '../../../hooks';
import { selectInkPurchaseOptionProps } from '../../../selectors/products';
import HollowButton from '../../shared-components/atoms/hollowButton';

const PurchaseOptions = () => {
  const props = useSelector(selectInkPurchaseOptionProps);
  if (!props) {
    return null;
  }
  const { purchaseOptions, hideIIBtn } = props;
  return purchaseOptions.map(inkPurchaseOptions => (
    hideIIBtn && inkPurchaseOptions.options_block?.option_button_id.includes('Instant-Ink')
      ? null
      : (
        <div key={inkPurchaseOptions.options_block?.option_button_id} className="purchaseOptions--option">
          <p>{inkPurchaseOptions.options_block?.option_title}</p>
          <HollowButton
            buttonText={inkPurchaseOptions.options_block?.option_button_text}
            buttonID={inkPurchaseOptions.options_block?.option_button_id}
            buttonLink={inkPurchaseOptions.options_block?.option_button_link}
          />
        </div>
      )
  ));
};

export default PurchaseOptions;
