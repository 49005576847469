import styled from 'styled-components';

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.main.hp.fontFamily};

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    flex: 1;
  }
`;

export const StatusDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: #4E4E4E;
  margin-left: 10px;

  > div {
    display: flex;
    align-items: center;

    > span {
      max-width: 145px;
    }

    > svg {
      align-self: start;
      margin-right: 5px;
    }
  }

  a {
    color:  ${props => props.theme.main.hp.color};
    margin-left: 5px;
  }
`;

export const StatusImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    height: 55px;
    width: 55px;
  }
`;
