import React from 'react';

import {
  AnimatedSection,
  PropsLoadingSection,
  PropsContainerLoadingSection
} from '../styles/StyleSkeleton';

const SkeletonValueProp = () => {
  return (
    <PropsLoadingSection>
      <PropsContainerLoadingSection>
        <AnimatedSection width="250px" margin="20px" />
        <AnimatedSection width="250px" margin="20px" />
        <AnimatedSection width="250px" margin="20px" />
      </PropsContainerLoadingSection>
      <AnimatedSection width="350px" margin="20px" />
      <AnimatedSection width="200px" height="35px" margin="20px" />
      <AnimatedSection width="250px" margin="20px" />
    </PropsLoadingSection>
  );
};

export default SkeletonValueProp;
