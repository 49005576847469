import React from 'react';
import {
  Container,
} from '../../../../components/shared-components/styles';
import { ProfileForm } from '../../../../components/AccountDetails/Profile';

const Profile = () => (
  <Container>
    <ProfileForm />
  </Container>
);

export default Profile;
