import React from 'react';
import SectionHeadIcon from './../shared-components/molecules/sectionHeadIcon';
import SearchBar from './../shared-components/organisms/search-bar';
import styled from 'styled-components';
import { GrayTemplateSection, ContentWrapper } from '../shared-components/shared-styles/TemplateStyles';

const RedefinedWrapper = styled(GrayTemplateSection)`
  font-size: 1.25rem;
  margin-top: 5rem;
  padding-bottom: 9rem !important;
  font-size: 1.25rem;
`
const DirectionsTextCentered = styled.div`
  text-align: center;
  width: 100%;
  flex: 1 1 auto;
`
const GraphicContainer = styled.div`
  width: 12rem;
  margin: 1rem auto 2rem;
  text-align: center;
`
const GetSupport = props => {
  const {
    printer_common,
    device: {
      getSupportGraphicLink,
    },
  } = props;
  const {
    support_title: supportTitle,
    support_title_icon,
    get_support_description_text: getSupportDescriptionText,
    get_support_link_text: getSupportLinkText,
    get_support_graphic,
    support_field_placeholder: supportFieldPlaceholder,
  } = printer_common ? printer_common : {};
  const {
    url: supportTitleIconUrl,
    title: supportTitleIconTitle,
  } = support_title_icon ? support_title_icon : {};
  const {
    url: getSupportGraphicUrl,
    title: getSupportGraphicTitle,
  } = get_support_graphic ? get_support_graphic : {};

  return (
    <RedefinedWrapper id="get-support">
      <ContentWrapper>
        <SectionHeadIcon
          sectionHeading={supportTitle}
          sectionIcon={supportTitleIconUrl}
          sectionIconAlt={supportTitleIconTitle}
        />
        <DirectionsTextCentered dangerouslySetInnerHTML={{ __html: getSupportDescriptionText }} />
        <GraphicContainer>
          <a href={getSupportGraphicLink}>
            <img src={getSupportGraphicUrl} alt={getSupportGraphicTitle} />
            <span>
              {getSupportLinkText}
            </span>
          </a>
        </GraphicContainer>
        <SearchBar id="search-btn-product" placeholder={supportFieldPlaceholder} />
      </ContentWrapper>
    </RedefinedWrapper>
  );
}

export default GetSupport;
