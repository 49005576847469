import React, { Component } from 'react'
import { TweenLite } from 'gsap'

class Accordion extends Component {
    constructor(props) {
        super(props)
        this.myID = `accordion_${this.props.ID}`
        this.isOpen = true
        this.groupID = !!this.props.groupID ? this.props.groupID : "noID_" + this.props.ID
        this.clickTarget = document.getElementById(this.props.actionID)
        this.groupAccordionFn = !!this.props.groupFn ? this.props.groupFn : (arg) => { }
        this.callbackFn = this.props.callbackFn ? this.props.callbackFn : () => { }
        this.timeAdjustment = !!this.props.addSeconds ? Number(this.props.addSeconds) : 0
        this.offsetTiming = !!this.props.offsetSeconds ? Number(this.props.offsetSeconds) : 0
        this.easingOff = !!this.props.easingOff
        this.noCloseFn = !!this.props.noCloseFn ? this.props.noCloseFn : () => false
        this.introduceMe = this.props.introduce === undefined || this.props.introduce === null || this.introduceMe === false ? false : this.props.introduce
    }

    componentDidMount() {
        this.noCloseFn = !!this.props.noCloseFn ? this.props.noCloseFn : () => false
        if (!this.props.isSubAccordionOpen) {
            if (!!this.props.startOpen ) {
                this.callbackFn(true, this.props.ID);
            } else {
                if (this.introduceMe) {
                    this.introduceMe = false
                    this.initClosed()
                    setTimeout(
                        () => {
                            this._toggle(true)
                        }
                        ,
                        300
                    )
                } else {
                    this.initClosed()
                }
            }
        } else if (this.props.isSubAccordionOpen) {
            const icn = `#${this.props.ID}_arrow`;
            TweenLite.set(icn, { rotationX: -180 });
        }
        this.clickTarget = document.getElementById(this.props.actionID)
        if(!this.clickTarget) {
            setTimeout(() => {
                this.clickTarget = document.getElementById(this.props.actionID);
                this.addEventListenerToElement();
            }, 100);
        }
        if (!!this.clickTarget) {
            this.addEventListenerToElement();
        }
    }

    addEventListenerToElement = () => {
        this.clickTarget.style.cursor = "pointer"
        this.clickTarget.addEventListener("click", (event) => {
            this._toggleSwitch()
        })
        this.clickTarget.addEventListener("keypress", (event) => {
            if (event.key === "Enter") {
                this._toggleSwitch()
            }
        })
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.controler.group === this.groupID) {
            if (nextProps.controler.identifier !== this.myID) {
                if (this.isOpen) {
                    this._toggle(false)
                    return true
                }
            }
        }
        return false
    }

    initClosed = () => {
        const element = document.getElementById(this.myID)
        TweenLite.set(element, { height: 0 })
        this.isOpen = false
    }

    _toggleSwitch = () => {
        if (!!this.props.inSmartHelp && !this.noCloseFn() && this.isOpen) {
            // DO NOTHING
        } else {
            this.groupAccordionFn({ myID: this.myID, groupID: this.groupID })
            this._toggle(!this.isOpen)
        }
    }

    _toggle = (ON) => {
        const easing = (!this.easingOff) ? !!this.props.invertEasing ? Power1.easeIn : Power1.easeOut : Power0.easeNone
        const seconds = .4529
        const element = document.getElementById(this.myID)
        const complete = () => {
            // document.getElementById(this.myID).style.height = "auto";
            element.style.height = "auto";
        }
        if (ON) {
            let hite = document.getElementById(`${this.myID}`).scrollHeight
            TweenLite.to(element, seconds + this.timeAdjustment + this.offsetTiming, { ease: easing, height: hite, onComplete: complete })
        } else {
            TweenLite.to(element, seconds + this.timeAdjustment - this.offsetTiming, { ease: easing, height: 0 })

        }
        this.isOpen = ON
        this.callbackFn(this.isOpen, this.props.ID)
    }

    render() {
        let styles = { "overflow": "hidden" }
        return (
            <div id={this.myID} style={styles} >
                <div id={`${this.myID}_inner_lining`} >
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default Accordion
