import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Config from '../../config/Config';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';

const APP_NAME = 'hp-plus-second-offer';

const config = new Config();
const hpPlusSecondOfferManifestUrl = config.Get('HP_PLUS_SECOND_OFFER_MANIFEST_URL');

const SecondOffer = () => {
  const [error, setError] = useState(false);

  useEffect(() => {
    (async function fetchHpPlusSecondOffer() {
      try {
        const response = await axios.get(hpPlusSecondOfferManifestUrl);
        const manifestData = response.data;

        const style = document.createElement('link');
        style.rel = 'stylesheet';
        style.href = manifestData['app.css'];
        style.id = `${APP_NAME}-styles`;
        document.head.appendChild(style);

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = manifestData['app.js'];
        script.id = `${APP_NAME}-app`;
        script.onload = () => window['renderHpPlusSecondOffer'](APP_NAME);
        document.body.appendChild(script);

      } catch (error) {
        console.error(error);
        setError(error);
      }
    })();
  }, []);

  if (error) {
    throw error;
  }

  return (
    <div id={APP_NAME}>
      <LoaderBlock />
    </div>
  );
};

export default SecondOffer;
