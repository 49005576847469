import React from 'react';

const SettingSummary = props => {
  const { settingKey, settingValue } = props;

  return (
    <div className="settingSummary">
      <span className="settingKey">{settingKey}</span>
      <span className="settingValue">{settingValue}</span>
    </div>
  );
};

export default SettingSummary;
