import Config from "../config/Config"
import { createSelector } from 'reselect';
import {
  pathOr,
  propOr,
} from 'ramda';
import {
  selectNewLocalesContentStackData,
  selectNewNavBarContentStackData,
  selectContentStackDictionary,
} from './craft';
import {
  selectLocaleParamsFromRoute,
  selectLocation,
  selectPathname,
  selectRootMatch
} from './routing';

import {GLOBAL_FOOTER_RESOURCE_NAME } from '../constants/contentStackResources';
import { DEFAULT_COUNTRY_LOCALE_LANGUAGE } from '../constants/i18n';
import {
  selectAuthZLoginUrl,
  selectAuthZCreateUrl,
  selectConfigIsComplete,
  selectSignOutURL,
} from './config';
import { selectSignInProps } from '../components/Dashboard/store/modules/portal/selectors';
import { encodeAuthState } from '../components/Dashboard/utils/routing';
import { selectAnalyticsData } from './analytics';
import { selectJWebInfo } from './application';
import { selectCurrentLocale } from './locales';
import { validateCurrentMatchLocale } from '../utils/routing';
import { HP_URL, INSTANT_INK_URL, ONE_TWO_THREE_URL, SUPPORT_URL } from '../constants/common';
import { JSHELL_URLS } from '../constants/jshell';
import { isChineseDomain } from "../utils/routing"

export const selectLocaleSelectorProps = createSelector(
  selectLocation,
  selectNewLocalesContentStackData,
  selectLocaleParamsFromRoute,
  (location, countryList = [], { country = 'us', language = 'en' }) => {
    // Selected locale in the GNB/Footer reflects current language based on DEFAULT_COUNTRY_LOCALE_LANGUAGE
    // Below line of code needs to revisted when we support right to left languages and any locale expansion
    const validLangForCountry = DEFAULT_COUNTRY_LOCALE_LANGUAGE[`${language}_${country}`];
    const currentLocale = (validLangForCountry) ? validLangForCountry[0] : `${language}_${country}`;
    const {
      currentLanguage,
      languages,
      selectedValue,
    } = countryList.reduce((
      {
        selectedValue: selected,
        currentLanguage: current,
        languages: langs,
      },
      {
        locale: {
          language: lang,
          country: coun,
          locale_code,
        }
      },
    ) => {
      const value = locale_code.replace('-', '_').toLowerCase();
      const label = `${coun} (${lang})`;
      const disabled = value === currentLocale;
      return {
        selectedValue: disabled ? value : selected,
        currentLanguage: disabled ? label : current,
        languages: [
          ...langs,
          {
            label,
            value,
            disabled,
          },
        ],
      };
    }, { selectedValue: currentLocale, currentLanguage: '', languages: [] });
    return {
      selectedValue,
      location,
      currentLanguage,
      languages,
    };
  },
);

/** targetURL must be one of the following configs: AUTHZ_OPENID_LOGIN, AUTHZ_OPENID_CREATE */
const createAuthZOpenIDURL = (targetURL, redirectTo, analyticsData, country = 'us', language = 'en', tenantId = '', redirect_uri = '') => {
  // state param
  const handshake = String(Math.random());
  const stateParam = encodeAuthState(handshake, redirectTo, analyticsData, tenantId, redirect_uri);
  // localization params
  const countryValue = country.toUpperCase();
  const languageValue = language.toLowerCase();
  const localizationParams = `&country=${countryValue}&locale=${languageValue}_${countryValue}`;

  return `${targetURL}${stateParam}${localizationParams}`;
}

const getHPDotComSupportedLocale = (country = 'us', language = 'en') =>
  // Not supported combinations must use a fallback locale
  (
    (country === 'es' &&  language === 'ca')
    || (country === 'no' &&  language === 'nb')
    || (country === 'ru' &&  language === 'ru')
  )
  ? { country: 'us', language: 'en' }
  : { country, language };

const getSupportedLocale = (targetURL, country, language) => (targetURL === HP_URL)
  ? getHPDotComSupportedLocale(country, language)
  : { country, language };

const getLocaleSeparator = (targetURL) => {
  switch (targetURL.toLowerCase()) {
    case HP_URL: return '-';
    case INSTANT_INK_URL: return '/';
    case ONE_TWO_THREE_URL: return '/';
    case SUPPORT_URL: return '-';
    default:
      return targetURL.startsWith('/')
        ? '/'        // Portal routes must use '/' as locale separator
        : undefined; // Cannot define a separator for unmapped URLs
  };
};

export const localizeURL = (targetURL, countryParam = 'us', languageParam = 'en') => {
  const { country, language } = getSupportedLocale(targetURL, countryParam, languageParam);
  const localeSeparator = getLocaleSeparator(targetURL);
  const localePath = `${country}${localeSeparator}${language}`;
  const isPortalRoute = targetURL?.startsWith('/');

  return localeSeparator
    ? (isPortalRoute ? `/${localePath}${targetURL}` : `${targetURL}/${localePath}/`)
    : targetURL;
}

const getJshellUrl = () => {
  const config = new Config();
  const stack = config.Get('CURRENT_STACK');
  const isProd = stack === 'prod';

  if (isChineseDomain()) {
    return isProd ? JSHELL_URLS.PROD_CHINA :  JSHELL_URLS.STAGE_CHINA;
  }

  return isProd ? JSHELL_URLS.PROD :  JSHELL_URLS.DEV_PIE_STAGE.replace('stack', stack);
}

const getAuthZDestination = (destination, signInURL, createUrl) => {
  switch (destination.toLowerCase()) {
    case 'signin': return signInURL;
    case 'createaccount': return createUrl;
    default: return undefined;
  }
};

const shouldRedirectToJshell = (jshellRedirectProbability) => {
  if (jshellRedirectProbability === 0) return false;
  if (jshellRedirectProbability === 1) return true;
  return Math.random() <= jshellRedirectProbability
}

const getRedirectUri = (destination, jshellRedirectProbability) => {
  const jshellUrl = shouldRedirectToJshell(jshellRedirectProbability) ? getJshellUrl() : undefined

  switch (destination.toLowerCase()) {
    case 'signin': return jshellUrl;
    case 'createaccount': return jshellUrl;
    default: return undefined;
  }
}

export const determineDest = ({
  signInURL,
  createUrl,
  redirectTo,
  analyticsData = null,
  destination,
  country = 'us',
  language = 'en',
  tenantId = '',
  redirect_uri = '',
  jshellRedirectProbability = 0
}) => {
  const authZDestination = getAuthZDestination(destination, signInURL, createUrl);

  const redirectUri = redirect_uri || getRedirectUri(destination, jshellRedirectProbability);

  if (authZDestination) {
    return createAuthZOpenIDURL(authZDestination, redirectTo, analyticsData, country, language, tenantId, redirectUri);
  }

  return localizeURL(destination, country, language);
};

export const selectNewNavBarContent = createSelector(
  selectNewNavBarContentStackData,
  selectAuthZLoginUrl,
  selectAuthZCreateUrl,
  selectAnalyticsData,
  selectSignInProps,
  selectLocaleParamsFromRoute,
  (_, prop) => prop,
  ({ links = [], accountLinks = [] }, signInURL, createUrl, analyticsData, { redirectTo }, { country = 'us', language = 'en' }, jshellRedirectProbability) => ({
    links: links.map(link => {
      const {
        nav_item
      } = link;

      const {
        text,
        destination,
        ...restLink
      } = nav_item;

      return {
        ...restLink,
        text,
        id: `link-to-${destination}`,
        destination: determineDest({
          signInURL,
          createUrl,
          analyticsData,
          redirectTo,
          destination,
          country,
          language
        }),
      };
    }),
    accountLinks: accountLinks.map(({ nav_item }) => {
      const {
        destination,
        ...restLink
      } = nav_item;

      return {
        ...restLink,
        id: `link-to-${destination}`,
        destination: determineDest({
          signInURL,
          createUrl,
          analyticsData,
          redirectTo,
          destination,
          country,
          language,
          jshellRedirectProbability
        }),
      }
    }),
  }),
);

const formatFooterLinks = (arr, country, language) => arr.map(({ footer_segment }) => {
  const { footer_text, footer_link, footer_link_id } = footer_segment;
  const id = footer_link_id.toLowerCase()
    .replace(' ', '-');
  return {
    label: footer_text,
    id,
    ...footer_link.includes('http')
      ? { href: localizeURL(footer_link, country, language)}
      : { to: localizeURL(footer_link, country, language)},
  };
});

export const selectNewFooterContent = createSelector(
  selectContentStackDictionary,
  selectLocaleParamsFromRoute,
  (dictionary, { country = 'us', language = 'en' }) => {
    const content = pathOr([], [GLOBAL_FOOTER_RESOURCE_NAME, 'data', 'entries', 0, 'footer'], dictionary);
    return formatFooterLinks(content, country, language);
  },
);

export const selectDetermineLocaleProps = createSelector(
  selectConfigIsComplete,
  selectRootMatch,
  selectPathname,
  selectSignOutURL,
  selectCurrentLocale,
  selectJWebInfo,
  (configIsComplete, match, pathname, signOutUrl, currentLocale, jwebInfo) => {
    const {
      country,
      language,
    } = propOr({ country: 'us', language: 'en' }, 'params', match);
    const pathIsValid = validateCurrentMatchLocale({ country, language, pathname });
    return {
      pathIsValid,
      country,
      language,
      pathname,
      currentLocale,
      configIsComplete,
      signOutUrl,
      jwebDetermined: jwebInfo !== null,
    };
  },
);
