import PropTypes from 'prop-types';
import { useSelector } from '../../../../hooks';
import { selectFeatureFlagEnabled } from '../../../../selectors/config';

const FeatureFlag = props => {
  const { children, featureFlagKey } = props;

  const isEnabled = useSelector(selectFeatureFlagEnabled, featureFlagKey);

  if (isEnabled) {
    return children;
  }

  return null;
};

FeatureFlag.propTypes = {
  children: PropTypes.node.isRequired,
  featureFlagKey: PropTypes.string.isRequired,
};

export default FeatureFlag;
