import styled from 'styled-components';
import { Button } from '@veneer/core';

export const Actions = styled.div`
  display: flex;
  flex: 0 0 24px;
  justify-content: space-between;
  cursor: pointer;
  
  @media (max-width: ${props => props.theme.breakPoints.xg}) {
    && {
      * {
        font-size: 12px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    && {
      justify-content: flex-end;
    }
  }
`;

export const ActionsHeader = styled.div`
  display: flex;
  flex: 0 0 24px;
`;

export const UserContainer = styled.div`
  flex: 1 1 205px;
  display: flex;
  align-items: center;
  > button {
    display: none;
    cursor: pointer;
    background: transparent;
    border: 0;
    
    svg {
      fill: ${props => props.theme.main.hp.fontColor};
    }
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    > button {      
      display: block;
      margin-left: auto;
    }
  }
`;

export const AvatarContainer = styled.div`
  div {
    display: flex;
    justify-content: center;

    @media (max-width: ${props => props.theme.breakPoints.md}) {
      > div:nth-child(2) {
        top: 4px
      }
    }
  }
`;

export const AvatarHeader = styled.div`
  flex: 0 0 30px;
  min-width: 30px;

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    display:none;
  }
`;

export const Email = styled.div`
  flex: 1 1 225px;

  @media (max-width: ${props => props.theme.breakPoints.xg}) {
    line-break: anywhere;
  }
`;

export const EmailHeader = styled.div`
  flex: 1 1 225px;
`;

export const Name = styled.div`
  margin-left: 8px;
  line-break: anywhere;
  @media (max-width: ${props => props.theme.breakPoints.md}) {
    font-size: 16px;
  }
`;

export const Role = styled.div`
  flex: 1 1 190px;

  @media (max-width: ${props => props.theme.breakPoints.xg}) {
    flex: 1 1 150px;
    padding-left: 20px;
  }
`;

export const RoleHeader = styled.div`
  flex: 1 1 190px;

  @media (max-width: ${props => props.theme.breakPoints.xg}) {
    padding-left: 6px;
  }

  @media (max-width: ${props => props.theme.breakPoints.xg}) {
    flex: 1 1 150px;
    padding-left: 20px;
  }
`;

export const Status = styled.div`
  flex: 1 1 150px;
`;

export const StatusHeader = styled.div`
  flex: 1 1 150px;
  margin-right: 24px;
`;

export const UserItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 8px;
  height: 64px;

  @media (max-width: ${props => props.theme.breakPoints.xg}) {
    font-size: 12px;
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    border-radius: 12px;  
    margin-bottom: 10px; 
    background-color: #FFF;
    box-shadow: 0 4px 16px #2121211A;

    > div {
      flex: 1;
      display: flex;            
      margin: 0;
      padding: 15px;
      width: 100%;

      &:not(:first-child) {
        display: ${props => (props.isExpanded ? 'flex' : 'none')};
      }

      &:nth-of-type(even) {
        background: #F8F8F8;
      }
    }
  }
`;

export const UserHeader = styled.div`
  flex: 1 1 175px;
  margin-left: 10px;
`;

export const UserListContainer = styled.div`
  border-radius: 12px;
  box-shadow: 0 4px 16px #2121211A;
  flex-basis: 100%;
  margin-top: 30px;

  > div:nth-of-type(even) {
    background-color: #F8F8F8;
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    border-radius: 0;
    box-shadow: none;

    > div:nth-of-type(even) {
      background-color: #FFF;
    } 
  }
`;

export const UserListHeaders = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 64px;
  padding-left: 16px;
  padding-right: 8px;
  text-transform: uppercase;

  > div:not(:nth-of-type(2)) {
    color: #737373;
  }

  @media (max-width: ${props => props.theme.breakPoints.xg}) {
    font-size: 12px;
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    display: none;
  }
`;

export const Label = styled.span`
  display: none;

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    width: 60px;
    display: block;
    text-transform: uppercase;    
  }
`;

export const OwnerButton = styled(Button)`
  && {
    background: transparent;
    border: solid 1px rgba(0, 0, 0, 0);
  }
`;
