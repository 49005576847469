import Types from './types';

const Actions = {
  addMicrofrontend: (name, scripts) => ({
    type: Types.ADD_MICROFRONTEND,
    payload: { name, scripts },
  }),
  removeMicrofrontend: name => ({
    type: Types.REMOVE_MICROFRONTEND,
    payload: { name },
  }),
};

export default Actions;
