import styled from 'styled-components';
import { theme } from '../../../../utils/styleHelpers';

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => (props.isRTL ? 'margin: 25px 118px 0 auto' : 'margin: 25px auto 0 118px')};

  @media (min-width: 640px) {
    ${(props) => (props.isRTL ? 'margin: 25px 118px 75px auto' : 'margin: 25px auto 75px 118px')};
  }

  @media (min-width: 780px) {
    ${(props) => (props.isRTL ? 'margin: 25px 118px 50px auto' : 'margin: 25px auto 50px 118px')};
  }

  @media (min-width: 948px) {
    ${(props) => (props.isRTL ? 'margin: 25px 118px 30px auto' : 'margin: 25px auto 30px 118px')};
  }
`;

export const SupportText = styled.p`
  font-family: ${theme.fonts.familyLight};
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  height: 38px;
  line-height: 26px;
  width: 832px;
  ${(props) => (props.isRTL ? 'margin: auto 33px 10px auto' : 'margin: auto auto 10px 33px')};

  @media (max-width: 780px) {
    max-width: 387px;
  }
`;
