import React from 'react';
import styled from 'styled-components';

// When we convert this to a styled component, replace header with StyledHeader in the JSX:
const StyledHeader = styled.header`
	text-align: center;
	display: flex;
  justify-content: center;
  margin-bottom: 2.25rem;
	@media screen and (min-width: 640px) {
		margin-bottom: 3.75rem;
    margin-left: -3rem;
    margin-right: 0;
	}
	@media screen and (min-width: 1024px) {
		margin-bottom: 5rem;
	}
  align-items: center;
  span {
    width: 3rem;
    display: inline-block;
    margin-right: 1rem;
    img {
      width: 100%;
    }
  }
  h2 {
    font-size: 2rem;
    @media screen and (min-width: 640px) {
      font-size: 2.625rem;
    }
    @media screen and (min-width: 1024px) {
      font-size: 3.375rem;
    }
    margin-bottom: 0;
    line-height: 1;
  }
`
export default function SectionHeadIcon(props) {
    return <header className="sectionHeader sectionHeader--withIcon">
						  <span className="sectionHeader--icon"><img src={props.sectionIcon} alt={props.sectionIconAlt} /></span>
						  <h2>{props.sectionHeading}</h2>
					</header>
}