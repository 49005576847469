import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector } from '../../hooks';
import { selectContentStackDataByResource, selectAppBadgesContentStackData } from '../../selectors/craft';
import { SMB_DOWNLOAD_RESOURCE_NAME } from '../../constants/contentStackResources';
import Navbar from '../revised/Global/Navbar';
import Footer from '../revised/Global/Footer';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';
import { AppBadges } from './AppBadges/AppBadges';
import { InstallHPSmartButton } from './AppBadges/AppBadges';
import {
  Container,
  HeaderContent,
  HeaderText,
  InstallSteps,
  InstallStep,
  AppStoreContent,
  AppStoreText,
  AppStoreBadgesContainer,
  QRCodeImage,
  QRCodeText,
  StraightLine,
  SmartAppHelpText,
  SupportIconImg,
  SupportText
} from './Styles/styles';
import { prepareGATrackingForSMB } from '../../lib/analyticsTracking'
import { CS_SMB_DOWNLOAD_COMMON_ENTRY_ID, DOWNLOAD } from '../../constants/common'


export const InstallHPSmart = (props) => {
  // Getting ContentStack Data
  const { download } = useSelector(selectContentStackDataByResource, [SMB_DOWNLOAD_RESOURCE_NAME]);
  // Extracting ContentStack Data
  const smbDownloadWin10Data = download?.data?.entries.filter(section => section.uid === CS_SMB_DOWNLOAD_COMMON_ENTRY_ID);

  const appBadgesContent = useSelector(selectAppBadgesContentStackData)?.data?.entries[0];

  const { isRTLSupport, geoCountry } = props;
  const breakpoint = 768;
  // Mobile View
  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);

  const resizeLayout = () => {
    setIsMobile(window.innerWidth < breakpoint);
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', resizeLayout);
  });

  useEffect( ()=> {
    prepareGATrackingForSMB(DOWNLOAD);
  },[smbDownloadWin10Data])

  if (smbDownloadWin10Data && appBadgesContent && !smbDownloadWin10Data.isError) {
    const {
      desktop_header_image,
      mobile_header_image,
      download_header,
      install_steps,
      install_button_text,
      app_store_text,
      qr_code,
      qr_code_text,
      smart_app_help_text,
      support_icon,
      support_icon_text
    } = smbDownloadWin10Data[0];

    const { landing_page_app_badges } = appBadgesContent;
    return (
      <div>
        <Navbar geoCountry={geoCountry} minimal={true} smbDownloadHeader={true} />
        <Container>
          <HeaderContent
            isRTL={isRTLSupport}
            desktopHeaderImageUrl={desktop_header_image?.url}
            mobileHeaderImageUrl={mobile_header_image?.url}
          >
            <HeaderText isRTL={isRTLSupport}>{download_header}</HeaderText>
            <InstallSteps isRTL={isRTLSupport}>
              {install_steps.map((steps) => {
                const {install_step} = steps;
                const {step, _metadata} = install_step;
                const {uid} = _metadata;
                return <InstallStep dangerouslySetInnerHTML={{ __html: step }} key={ uid } />
              })}
            </InstallSteps>
            <InstallHPSmartButton id="install-HpSmart-btn-downloadPage" buttonText={install_button_text} buttonDestination={landing_page_app_badges} />
          </HeaderContent>
          <AppStoreContent isRTL={isRTLSupport}>
            <AppStoreText>{app_store_text}</AppStoreText>
            <AppBadges badges={landing_page_app_badges} isRTL={isRTLSupport} />
            <AppStoreBadgesContainer>
              <QRCodeImage src={qr_code?.url} alt={qr_code?.title} />
              <QRCodeText isRTL={isRTLSupport}>{qr_code_text}</QRCodeText>
            </AppStoreBadgesContainer>
            {isMobile && <StraightLine />}
            <SmartAppHelpText
              isRTL={isRTLSupport}
              dangerouslySetInnerHTML={{ __html: smart_app_help_text }}
            />
            <StraightLine />
            <AppStoreBadgesContainer>
              <SupportIconImg src={support_icon?.url} alt={support_icon?.title} />
              <SupportText
                isRTL={isRTLSupport}
                dangerouslySetInnerHTML={{ __html: support_icon_text }}
              />
            </AppStoreBadgesContainer>
          </AppStoreContent>
        </Container>
        <Footer />
      </div>
    );
  }
  return (
    <div className="contentLoading">
      <LoaderBlock />
    </div>
  );
};

export default InstallHPSmart;
