import ActionTypes from '../constants/ActionTypes';
import fetchStatus from './fetchStatus';

const userProgramsDefault = {
    ...fetchStatus(),
    data: null
};

function userPrograms(state = userProgramsDefault, action) {
    switch (action.type) {
        case ActionTypes.START_USER_PROGRAMS_FETCH:
            return {
                ...state,
                ...fetchStatus(state, { type: ActionTypes.START_FETCH })
            };
        case ActionTypes.COMPLETE_USER_PROGRAMS_FETCH_WITH_ERROR:
            return {
                ...state,
                ...fetchStatus(state, {
                    ...action,
                    type: ActionTypes.COMPLETE_FETCH_WITH_ERROR
                })
            };
        case ActionTypes.COMPLETE_USER_PROGRAMS_FETCH:
            return {
                ...state,
                ...fetchStatus(state, { type: ActionTypes.COMPLETE_FETCH }),
                ...{ data: action.userProgramsData }
            };
    }

    return state;
}

export default userPrograms;
