export const CONTENT_STACK_REDUNDANT_LOCALES = {
  de_AT: 'de_DE',
  de_CH: 'de_DE',
  el_CY: 'el_GR',
  en_CA: 'en_US',
  en_IE: 'en_US',
  en_MT: 'en_US',
  en_NZ: 'en_US',
  fr_BE: 'fr_FR',
  fr_CA: 'fr_FR',
  fr_CH: 'fr_FR',
  fr_LU: 'fr_FR',
  it_CH: 'it_IT',
  nl_NL: 'nl_BE',
  ro_MD: 'ro_RO'
};