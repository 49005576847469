import {
  pathOr,
} from 'ramda';

export const serviceEnabled = (portalElements, service = '') => (
  ['published', 'enabled'].includes(
    pathOr('', ['services', 'cards', service, 'state'], portalElements).toLowerCase(),
  )
);

export default {
  serviceEnabled,
};
