import styled from 'styled-components';
import EnrollContentBox from '../../shared-components/atoms/EnrollContentBox';

export const ValuePropSection = styled.div`
  background-color: #F8F8F8;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ValuePropText = styled.div`
  font-family: 'Forma DJR', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #212121;
  margin: 25px 0 0 0;
  padding-left: 200px;

  @media (max-width: 1440px) {
    padding-left: 100px;
  }

  @media (max-width: 1366px) {
    padding-left: 100px;
  }
  
  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    padding-left: 50px;
  }

  @media (max-width: 390px) {
    padding-left: 10px;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ValuePropFreeMonthText = styled.div`
  font-family: 'Forma DJR', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #333;
`;

export const ValuePropEnrollContentBox = styled(EnrollContentBox)`
  &.valuePropEnroll {
    width: 100%;
  }
`;

export const ValuePropTextField = styled.div`
  font-family: 'Forma DJR', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #404040;
  margin-bottom: 35px;
  padding-left: 200px;

  @media (max-width: 1440px) {
    padding-left: 100px;
  }

  @media (max-width: 1366px) {
    padding-left: 100px;
  }

  @media (max-width: 768px) {
    padding-left: 50px;
    max-width: 500px;
  }

  @media (max-width: 390px) {
    padding-left: 10px;
  }
`
