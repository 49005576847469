import React, { useMemo } from 'react';
import { PricingDetailSection } from './styles/StylePricingDetail';
import { getCookie } from '../../../lib/manageCookies';
import { LandingPagePlans } from '@jarvis/react-instant-ink-plans';

const propTypes = {};
const defaultProps = {};

const PricingDetail = () => {
  const localeCookie = getCookie("gnb_locale");
  const [language, country] = localeCookie.split('_');

  const pricingDetailObject = useMemo(() => {
    const pricingDetails = {
      en_US: [
        { frequency: "light", pages: 10, price: "$0.79", priceInkWithPaper: "$2.28" },
        { frequency: "occasional", pages: 50, price: "$1.99", priceInkWithPaper: "$6.98" },
        { frequency: "medium", pages: 100, price: "$3.49", priceInkWithPaper: "$10.48" },
        { frequency: "frequent", pages: 300, price: "$5.99", priceInkWithPaper: "$19.98" },
        { frequency: "microbiz", pages: 700, price: "$10.99", priceInkWithPaper: "$38.98" },
      ],
      en_GB: [
        { frequency: "light", pages: 10, price: "£0.79", priceInkWithPaper: "£2.28" },
        { frequency: "occasional", pages: 50, price: "£1.99", priceInkWithPaper: "£5.98" },
        { frequency: "medium", pages: 100, price: "£3.49", priceInkWithPaper: "£8.98" },
        { frequency: "frequent", pages: 300, price: "£5.99", priceInkWithPaper: "£17.98" },
        { frequency: "microbiz", pages: 700, price: "£10.99", priceInkWithPaper: "£36.48" },
      ],
      de_DE: [
        { frequency: "light", pages: 10, price: "€0.79", priceInkWithPaper: "€2.28" },
        { frequency: "occasional", pages: 50, price: "€1.99", priceInkWithPaper: "€6.98" },
        { frequency: "medium", pages: 100, price: "€3.49", priceInkWithPaper: "€10.48" },
        { frequency: "frequent", pages: 300, price: "€5.99", priceInkWithPaper: "€19.98" },
        { frequency: "microbiz", pages: 700, price: "€10.99", priceInkWithPaper: "€38.98" },
      ],
      fr_FR: [
        { frequency: "light", pages: 10, price: "€0.79", priceInkWithPaper: "€2.28" },
        { frequency: "occasional", pages: 50, price: "€1.99", priceInkWithPaper: "€6.98" },
        { frequency: "medium", pages: 100, price: "€3.49", priceInkWithPaper: "€10.48" },
        { frequency: "frequent", pages: 300, price: "€5.99", priceInkWithPaper: "€19.98" },
        { frequency: "microbiz", pages: 700, price: "€10.99", priceInkWithPaper: "€38.98" },
      ]
    };
    return pricingDetails[localeCookie] || pricingDetails['en_US'];
  }, [localeCookie]);

  return (
    <PricingDetailSection data-testid="origami-pricing-detail-section">
      <LandingPagePlans
        plans={pricingDetailObject}
        country={country.toUpperCase()}
        language={language}
        program="i_paper"
      />
    </PricingDetailSection>
  );
};

PricingDetail.propTypes = propTypes;
PricingDetail.defaultProps = defaultProps;
export default PricingDetail;