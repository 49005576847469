import React from 'react';
import styled from 'styled-components';
import { getHtmlDir } from '../../../utils/globals';
import { useSelector } from '../../../hooks';
import { selectInstallDriversContentStackData, selectAppBadgesContentStackData } from '../../../selectors/craft';
import Navbar from '../../revised/Global/Navbar';
import Header from './Header/Header';
import Experience from './Experience/Experience';
import Support from './Support/Support';
import Footer from '../../revised/Global/Footer';
import { CS_SMB_DOWNLOAD_WIN7_ENTRY_ID } from '../../../constants/common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InstallDrivers = (props) => {
  const direction = getHtmlDir();
  const { geoCountry } = props;
  const isRTL = direction === 'rtl';
  const contentStackContent = useSelector(selectInstallDriversContentStackData)?.data?.entries.filter(section => section.uid === CS_SMB_DOWNLOAD_WIN7_ENTRY_ID);
  const appBadgesContent = useSelector(selectAppBadgesContentStackData)?.data?.entries[0];

  if (contentStackContent && appBadgesContent) {
    const {
      desktop_header_image,
      download_header,
      download_sub_header,
      install_numerals,
      install_steps,
      install_button_text,
      app_promo_text,
      app_store_text,
      qr_code,
      qr_code_text,
      support_icon,
      support_icon_text
    } = contentStackContent[0];
    const { landing_page_app_badges } = appBadgesContent;

    return (
      <div>
        <Navbar
          geoCountry={geoCountry}
          minimal={true}
          smbDownloadHeader={true}
        />
        <Container>
          {install_steps && (
            <Header
              image={desktop_header_image?.title}
              imageURL={desktop_header_image?.url}
              header={download_header}
              subHeader={download_sub_header}
              stepNumbers={install_numerals}
              stepDescription={install_steps}
              buttonText={install_button_text}
              buttonDestination={landing_page_app_badges}
              isRTL={isRTL}
            />
          )}
          {app_promo_text && (
            <Experience
              heading={app_promo_text}
              appStoreText={app_store_text}
              appStoreBadges={landing_page_app_badges}
              showMacAppStoreBadge={false}
              qrCodeTitle={qr_code?.title}
              qrCodeUrl={qr_code?.url}
              qrCodeText={qr_code_text}
              isRTL={isRTL}
            />
          )}
          {support_icon_text && (
            <Support
              alt={support_icon?.title}
              icon={support_icon?.url}
              text={support_icon_text}
              isRTL={isRTL}
            />
          )}
        </Container>
        <Footer />
      </div>
    );
  }
  return null;
};

export default InstallDrivers;
