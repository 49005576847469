import React from 'react';
import { useSelector } from 'react-redux';
import selectPageNameFromRoute from '../../store/modules/pageTitle/selector';
import {
  Container,
  Title,
} from './styles';
import i18n from '../../utils/i18n';

const PageTitle = () => {
  const pageNameStrId = useSelector(selectPageNameFromRoute);
  return (
    <Container>
      <Title>
        {pageNameStrId && i18n.t(pageNameStrId)}
      </Title>
    </Container>
  );
};

export default PageTitle;
