import React, { Component } from 'react';
import classNames from "classnames";
import bind from "./../../../bind";
import LoaderElement from "./../../../shared-components/molecules/LoaderElement";
import { RTL_LANGUAGES } from '../../../../constants/i18n';


class EmailPrintOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preference: this.props.setting,
      activeOption: this.props.activeOption
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.isPending = -1
  }
  handleButtonClick(option) {
    this.isPending = option.Key
    this.props.myPrintersAltered()

    if (this.props.myPrinter.isFetching || option.Key === this.state.activeOption.Key) {
      return;
    }
    this.props.trackingHandler("eprint-preferences-" + option.Key);
    this.props.updateEPrintPreference(this.props.myPrinter.data.WPPData.printerId, this.state.preference.Key, option.Key);
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.activeOption || !nextProps.setting) {
      return;
    }
    this.setState({
      preference: nextProps.setting,
      activeOption: nextProps.activeOption
    });
  }
  render() {
    const language = window.location.pathname.split('/')[2];
    const isRTLSupport = RTL_LANGUAGES.includes(language);
    if (this.state.preference === null) {
      return null;
    }
    return <div className="ePrintPreference--optionSet">
      <form className="ePrintPreference--form">
        {this.state.preference.Options.map((option, index) => {
          let oClass = classNames(
            "ePrintPreference--option",
            { "ePrintPreference--option-rtl": isRTLSupport },
            { "ePrintPreference--option-active": this.state.activeOption.Key == option.Key }
          );
          return <button id={"eprint-preferences-" + option.Key + "-btn"} key={index} type="button" className={oClass} onClick={() => this.handleButtonClick(option)}>
            {!this.props.gnb.isTablet && this.props.isPending && this.isPending === option.Key ? <LoaderElement left={true} /> : option.Label}</button>
        })}
      </form>
      <span className="ePrintPreference--explanation">{this.state.activeOption.Description}</span>
    </div>;
  }
}

export default bind(EmailPrintOptions);