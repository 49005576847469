import React from 'react';

class MyPrinterImage extends React.Component {
    constructor(props) {
        super(props)
        this.myID = !!props.ID ? props.ID : "no-id" + Math.random();
        this.imageClick = this.imageClick.bind(this)
        this.genericPrinterImg = (
            <picture className="myPrinterImage" id={this.myID}>
                <img src="/assets/img/printer_graphic.svg" alt={this.props.mainImageAlt} onClick={this.imageClick} />
            </picture>
        )
        this.LOAD_ERROR = false
    }

    loadFn = () => { }

    loadErrorFn = () => {
        this.LOAD_ERROR = true
        this.forceUpdate()
    }

    imageClick()  {
        if (!!this.props && typeof this.props.whenClicked === "function"  ) {
            this.props.whenClicked()
        }
    }

    render() {
        const optionalStyle = this.props.maxWidth !== null ? {maxWidth: this.props.maxWidth} : {}
        if (!this.LOAD_ERROR && (this.props.mainImageLarge || this.props.mainImageMedium || this.props.mainImageSmall)) {
            return (
                <picture className="myPrinterImage" id={this.myID} >
                    <source media="(min-width: 800px)" srcSet={this.props.mainImageLarge} />
                    <source media="(min-width: 480px)" srcSet={this.props.mainImageMedium} />
                    <source media="(max-width: 479px)" srcSet={this.props.mainImageSmall} />
                    <img src={this.props.mainImageLarge}  onClick={this.imageClick}  alt={this.props.mainImageAlt} onError={this.loadErrorFn} onLoad={this.loadFn} style={optionalStyle} />
                </picture>
            )
        } else {
            return this.genericPrinterImg
        }
    }
}

export default MyPrinterImage