import styled from 'styled-components';
import {
  Modal,
} from '@veneer/core';

/*
 * Global modal definitions
 */
export const ModalContainer = styled(Modal)`
  .vn-modal--dialog {
    .vn-modal--content {
      h2 {
        font-size: 24px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    .vn-modal--dialog {
      .vn-modal--content {
        padding: 20px;

        h2 {
          font-size: 20px;
          padding-bottom: 10px;
        }

        h4 {
          font-size: 14px;
        }

        label {
          font-size: 14px;
        }

        input {
          font-size: 14px;
        }

        button {
          padding: 6px 0;
          min-width: 80px;
          border-radius: 6px;
        }

        .vn-input__icon {
          padding-left: 0;

          button {
            min-width: 24px;
          }
        }
      }
    }
  }
`;

export default ModalContainer;
