import { path } from 'ramda';
import ActionTypes from '../constants/ActionTypes';
import Logger from '../lib/logger';

export default function fetchStatus(state = {
  isFetching: false,
  isComplete: false,
  isError: false,
  errorMsg: '',
}, action = {}) {
  switch (action.type) {
    case ActionTypes.START_FETCH:
      return {
        ...state, isFetching: true, isComplete: false, isError: false, errorMsg: '', errorDetail: undefined,
      };
    case ActionTypes.COMPLETE_FETCH:
      return {
        ...state, isComplete: true, isFetching: false, isError: false, errorMsg: '', errorDetail: undefined,
      };
    case ActionTypes.COMPLETE_FETCH_WITH_ERROR: {
      const errorMsg = path(['err', 'message'], action) || action.errorMsg || '';
      const newStates = {
        isError: true,
        isComplete: true,
        isFetching: false,
        errorDetail: action.errorDetail || '',
        errorMsg,
      };
      Logger.Error(errorMsg);

      return {
        ...state,
        ...newStates,
      };
    }
    default:
      return state;
  }
}
