import styled from 'styled-components';

export const SectionContainer = styled.div`
  padding: ${({ small }) => (small ? '16px 16px 0 16px' : '32px 32px 0 32px')};
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ small }) => (small ? '16px' : '32px')};
  > h4 {
    font-weight: 600;
  }
  ${({ small }) => (small ? '> h4 { font-size: 20px; }' : '')}
`;

export const SectionContent = styled.div`
  width: 100%;
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: 
    max-height 0.6s ease,
    visibility 0.5s ease;
  &.expanded {
    max-height: 900px;
    visibility: inherit;
    padding-bottom: ${({ small }) => (small ? '16px' : '32px')};
    transition: 
      max-height 0.6s ease-in,
      visibility 0.5s ease-in;
  }
`;

export const SectionToggle = styled.span`
  > svg {
    transform: rotateX(0deg);
    transition: transform 0.5s linear;
  }
  &.expanded > svg {
    transform: rotateX(180deg);
    transition: transform 0.5s linear;
  }
`

export const AccordionContainer = styled.div`
  ${SectionContainer}:not(:last-child) {
    border-bottom: 1px solid #E2E2E2;
  }
`;