/* eslint-disable */
import { createSelector } from 'reselect';
import {
  identity,
  prop,
} from 'ramda';
import {
  selectRootMatch,
} from '../../../../../selectors/routing';
import {
  selectUserTenantId,
} from '../ucdeUser/selectors';

const selectorsBase = {
  GET_DEVICES: prop('ucdeDevices'),
}

export const selectUCDEDevices = createSelector(
  selectorsBase.GET_DEVICES,
  identity
);

// status.connectivityState.connectionState = [ online, offline ]
// status.powerState = [ sleep, active-normal, auto_off, shutting_down ]
// status.printerIsAcceptingJobs = [ no, yes ]
// status.printerState = [ not_a_printer, idle, processing, stopped ]
// status.printerStateReasons = [ none, other, media-needed, media-jam, paused, shutdown, toner-low, toner-empty, spool-full, door-open, cover-open, media-empty, marker-supply-low, marker-supply-empty, media-low ]
// status.printerStateSeverity = [ none, report, warning, error ]
const mockDeviceStatus = {
  // "cloudId": "AQAAAAFyhgpRagAAAAHsv_-f",
  "status": {
    "connectivityState": {
      "connectionState": "online", // [ online, offline ]
      "lastSeenTime": "2020-06-11T 16:00:05.000Z"
    },
    "powerState": "IDLE", // [ sleep, active-normal, auto_off, shutting_down ]
    "printerIsAcceptingJobs": "YES", // [ no, yes ]
    "printerState": "idle", // [ not_a_printer, idle, processing, stopped ]
    "printerStateReasons": [
      "door-open"
    ], // [ none, other, media-needed, media-jam, paused, shutdown, toner-low, toner-empty, spool-full, door-open, cover-open, media-empty, marker-supply-low, marker-supply-empty, media-low ]
    "printerStateSeverity": "NONE", // [ none, report, warning, error ]
    "assessmentResult": 'protected' // [protected, notProtected, securityDisabled, unknown]
  },
}

const parseDate = (dateString) => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  return date.toLocaleString();
}

export const selectMyPrintersProps = createSelector(
  selectorsBase.GET_DEVICES,
  ({ devices = [], lastFetch, isFetching = false, isError = false }) => ({
    lastFetch: parseDate(lastFetch),
    isFetching,
    isError,
    devices
  })
)
