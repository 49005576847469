import { select, put, all, call, fork, takeEvery } from 'redux-saga/effects';
import {
  path,
  propOr,
  pathOr,
  complement,
  isNil,
  prop,
  compose,
  cond,
  both,
  head,
  isEmpty,
  identity,
  length,
  over,
  lensIndex,
  map,
  curry,
  T,
  is,
  reject,
  not,
  equals,
  toPairs,
  fromPairs
} from 'ramda';
import { gotCraftData, craftDataFailure } from '../actions/craft';
import { gotContentStackData, contentStackDataFailue } from '../actions/contentStack';
import { selectLocaleParamsFromRoute, selectRootMatch } from '../selectors/routing';
import { getContentStackData, getCraftData } from '../services/craft';
import {
  getSupportedLanguagesDictionary,
  getDefaultCountryCode,
  validateCurrentMatchLocale,
  stripLocaleFromPathname
} from '../utils/routing';
import * as ROUTES from '../constants/routes';
import * as CONTENTSTACKROUTES from '../constants/contentStackRoutes';
import * as CRAFT_RESOURCES from '../constants/craftResources';
import * as CONTENTSTACK_RESOURCES from '../constants/contentStackResources';
import { selectCraftDataByResource } from '../selectors/craft';
import { LANGUAGES, SIMPLIFIED_CHINESE_COUNTRIES, COUNTRIES, CONTENT_STACK_SUPPORTED_LOCALES } from '../constants/i18n';
import { setCookie } from '../utils/globals';

const isArray = is(Array);

const surfaceNestedData = path(['data', 'data']);
const lengthEqualsOne = compose(equals(1), length);

const transformObjectValues = curry((callback, object) =>
  compose(fromPairs, map(over(lensIndex(1), callback)), toPairs)(object)
);

function processData(arg) {
  return cond([
    [both(isArray, lengthEqualsOne), compose(processData, head)],
    [both(complement(isArray), is(Object)), transformObjectValues(processData)],
    [T, identity]
  ])(arg);
}

export const determineDefaultLocale = ({ language, country, pathname }) => {
  if (language === LANGUAGES.ZH) {
    if (SIMPLIFIED_CHINESE_COUNTRIES.has(country)) {
      return `${language}_${COUNTRIES.CN}`;
    }
    return `${language}_${country}`;
  }
  const localeDictionary = getSupportedLanguagesDictionary(pathname);

  if (language !== 'en' && CONTENT_STACK_SUPPORTED_LOCALES.hasOwnProperty(country) && CONTENT_STACK_SUPPORTED_LOCALES[country].includes(language)) {
    return `${language}_${country}`;
  }

  return `${language}_${getDefaultCountryCode(language, localeDictionary)}`;
};

const getContentStackResources = ({ pathname, country, language }) => {
  const contentStackRoutePairs = toPairs(CONTENTSTACKROUTES);
  const strippedPathname = stripLocaleFromPathname(country, language, pathname) || '/';

  const match = contentStackRoutePairs.reduce((accum, [key, value]) => {
    if (accum) {
      return accum;
    }
    if (isArray(value)) {
      const matchedResource = value.find((val) => strippedPathname.includes(val));
      if (matchedResource) {
        return prop(key, CONTENTSTACK_RESOURCES)(matchedResource, strippedPathname);
      }
    }

    if (strippedPathname.includes(value)) {
      return prop(key, CONTENTSTACK_RESOURCES);
    }

    return accum;
  }, null);

  return match || [];
};

const getCraftResources = ({ pathname, country, language }) => {
  const routePairs = toPairs(ROUTES);
  const strippedPathname = stripLocaleFromPathname(country, language, pathname) || '/';
  const match = routePairs.reduce((accum, [key, value]) => {
    if (accum) {
      return accum;
    }
    if (isArray(value)) {
      const matchedResource = value.find((val) => strippedPathname.includes(val));
      if (matchedResource) {
        return prop(key, CRAFT_RESOURCES)(matchedResource, strippedPathname);
      }
    }

    if (strippedPathname.includes(value)) {
      return prop(key, CRAFT_RESOURCES);
    }

    return accum;
  }, null);

  return match || [];
};

function* makeApiRequest({ resource, locale }) {
  try {
    return yield call(getCraftData, {
      resource,
      lang: locale,
      notLocalized: CRAFT_RESOURCES.NOT_LOCALIZED.has(resource)
    });
  } catch (err) {
    return null; // swallow the error so the saga doesn't halt
  }
}

const inProgress = new Set();

function* fetchCraftData({ resource, defaultLocale }) {
  inProgress.add(resource);
  const responses = yield call(makeApiRequest, {
    resource,
    ...(!CRAFT_RESOURCES.NOT_LOCALIZED.has(resource) && {
      locale: defaultLocale
    })
  });
  inProgress.delete(resource);
  return compose(processData, surfaceNestedData)(responses);
}

function* handleResourceFetch(defaultLocale, resource) {
  if (inProgress.has(resource)) {
    return null;
  }

  const data = yield call(fetchCraftData, { resource, defaultLocale });
  if (data) {
    const axn = gotCraftData(resource, data);
    yield put(axn);
  } else {
    yield put(
      craftDataFailure(resource, {
        isError: true,
        errorMsg: 'No Data Found'
      })
    );
  }
}

function* handleContentStackResourceFetch(resource, contentStackLocaleCode, uuid = '') {
  if (inProgress.has(resource)) {
    return null;
  }

  let contentStackData = yield call(getContentStackData, resource, contentStackLocaleCode, uuid);

  if (contentStackData) {
    const axn = gotContentStackData(resource, contentStackData);
    yield put(axn);
  } else {
    yield put(
      contentStackDataFailue(resource, {
        isError: true,
        errorMsg: 'No Data Found'
      })
    );
  }
}

function* checkStateForResource(resource) {
  const stateData = yield select(selectCraftDataByResource, resource);
  if (stateData && not(isEmpty(stateData))) {
    return null;
  }
  return resource;
}
let stateLocaleObj;
const setLocaleObj = (newLocaleObj) => {
  stateLocaleObj = newLocaleObj; // eslint-disable-line no-return-assign
  setCookie(
    'gnb_locale',
    `${newLocaleObj.language}_${propOr('', 'country', newLocaleObj).toUpperCase()}`
  );
};

function* handleCraftFlow(action) {
  // eslint-disable-line consistent-return
  const {
    payload: {
      location: { pathname }
    }
  } = action;

  const { language: oldLanguage, country: oldCountry } = stateLocaleObj;

  const match = yield select(selectRootMatch);

  const language = pathOr('en', ['params', 'language'], match);
  const country = pathOr('us', ['params', 'country'], match);

  if (!validateCurrentMatchLocale({ ...prop('params', match), pathname })) {
    return null;
  }

  const localeChanged = country !== oldCountry || language !== oldLanguage;
  if (localeChanged) {
    setLocaleObj({ country, language });
  }

  const resources = [
    ...getCraftResources({ pathname, country, language }),
    ...(!['in-app', 'plain', 'ucde', 'signout', 'essentials', 'printer-connection'].some((str) =>
      pathname.includes(str)
    )
      ? CRAFT_RESOURCES.GLOBALS
      : [])
  ];

  if (!resources.length) {
    return null;
  }

  const stateResources = yield all(resources.map(localeChanged ? identity : checkStateForResource));
  const filteredResources = reject(isNil, stateResources);
  yield all(
    filteredResources.map((resource) =>
      call(handleResourceFetch, determineDefaultLocale({ language, pathname, country }), resource)
    )
  );
}

function* handleContentStackFlow(action) {
  // eslint-disable-line consistent-return
  const {
    payload: {
      location: { pathname }
    }
  } = action;

  const { language: oldLanguage, country: oldCountry } = stateLocaleObj;

  const match = yield select(selectRootMatch);

  const language = pathOr('en', ['params', 'language'], match);
  const country = pathOr('us', ['params', 'country'], match);

  if (!validateCurrentMatchLocale({ ...prop('params', match), pathname })) {
    return null;
  }

  const localeChanged = country !== oldCountry || language !== oldLanguage;
  if (localeChanged) {
    setLocaleObj({ country, language });
  }

  const contentStackResources = [
    ...getContentStackResources({ pathname, country, language }),
    ...(!['in-app', 'plain', 'ucde', 'signout', 'essentials', 'printer-connection'].some((str) =>
      pathname.includes(str)
    )
      ? CONTENTSTACK_RESOURCES.GLOBALS
      : [])
  ];

  const contentStackLocaleCode = determineDefaultLocale({ language, pathname, country })
    .replace('_', '-')
    .toLowerCase();

  // Revisit this code in next Iteration
  if (contentStackResources.length) {
    yield all(
      contentStackResources.map((resource) =>
        typeof resource === 'object'
          ? call(
            handleContentStackResourceFetch,
            Object.keys(resource)[0],
            contentStackLocaleCode,
            Object.values(resource)[0]
          )
          : call(handleContentStackResourceFetch, resource, contentStackLocaleCode)
      )
    );
  }
}

function* handleLocationChange(action) {
  yield fork(handleCraftFlow, action);
  yield fork(handleContentStackFlow, action);
}

export default function* watchLocationChange() {
  const initialLocaleObj = yield select(selectLocaleParamsFromRoute);
  setLocaleObj(initialLocaleObj);
  yield takeEvery('@@router/LOCATION_CHANGE', function* (action) {
    yield handleLocationChange(action);
  });
}

