import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button } from '@veneer/core';
import {
  stylesIf,
  paths,
  fontPacks,
} from '../../../utils/styleHelpers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 35rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (min-width: ${paths.breakImmense}) {
    ${stylesIf(
    'vertical',
    `
      text-align: center;
    `,
    `
      flex-direction: row;
      text-align: left;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: none;
    `,
  )}
  }
`;

const ContentContainer = styled(Container)`
  max-width: 80vw;
  @media (min-width: ${paths.breakImmense}) {
    flex-direction: column;
    text-align: ${stylesIf('vertical', 'center', 'left')};
    justify-content: ${stylesIf('vertical', 'center', 'flex-start')};
    align-items: ${stylesIf('vertical', 'center', 'flex-start')};
    flex: 1 1 66%;
    max-width: 30rem;
    margin-right: ${props => props.isRTLSupport && '40px'}
  }
`;

const Title = styled.h3`
  color: ${paths.colorsNearDark};
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex: 1 1 auto;
  @media (min-width: ${paths.breakImmense}) {
    margin-top: 0;
  }
  ${fontPacks.titleRegularLight}
`;

const Brief = styled.span`
  &&&& {
    color: ${paths.colorsNearDark};
    max-width: 100%;
    flex: 1 1 auto;
    ${fontPacks.bodyLarge}
    > p {
      ${fontPacks.bodyLarge}
    }
  }
`;

const ImageContainer = styled.div`
  border-radius: 50%;
  background-color: ${stylesIf('invertImg', paths.colorsWhite, paths.colorsLightBackgroundGrey)};
  height: 7rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  padding: 2rem;
  > img {
    height: 3rem;
    width: auto;
  }
  @media (min-width: ${paths.breakWide}) {
    height: 12.5rem;
    width: 12.5rem;
    max-height: 12.5rem;
    max-width: 12.5rem;
    padding: 3rem;
    > img {
      width: auto;
      height: 5rem;
    }
  }
  @media (min-width: ${paths.breakImmense}) {
    ${stylesIf('vertical', '', 'margin-right: 3rem;')}
    flex: 1 1 ${stylesIf('vertical', 'auto', '33%')};
  }
`;

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const ImageAndBrief = ({
  src,
  alt,
  title,
  copy,
  buttonDestination,
  buttonId,
  buttonText,
  invertImg,
  vertical,
  className = '',
  isRTLSupport,
}) => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (buttonDestination.includes('http')) {
      if (buttonDestination.includes('/openid/v1/authorize?client_id=')) {
        window.location.href = buttonDestination;
        return;
      }
      window.open(buttonDestination, '_blank');
      return;
    }
    dispatch(push(buttonDestination));
  }, [buttonDestination]);

  return (
    <Container className={className} vertical={vertical}>
      {
        src && (
          <ImageContainer invertImg={invertImg} vertical={vertical}>
            <img src={src} alt={alt} />
          </ImageContainer>
        )
      }
      <ContentContainer vertical={vertical} isRTLSupport={isRTLSupport}>
        <Title dangerouslySetInnerHTML={{ __html: title }} />

        <Brief dangerouslySetInnerHTML={{ __html: copy }} />

        {
          buttonText && (
            <StyledButton
              id={buttonId}
              onClick={handleClick}
            >
              {buttonText}
            </StyledButton>
          )
        }
      </ContentContainer>
    </Container>
  );
};

export default ImageAndBrief;
