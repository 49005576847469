import { fork } from 'redux-saga/effects';

import watchSetLocale from './setLocale';
import watchCompleteUserInfo from './updateLocaleFromUserInfo';
import watchLocationChange from './watchLocationChange';
import watchApiCallActions from './apiCallActions';
import watchFailureNotifications from './errorNotifications';
import watchRedirectToSignout from './redirectToSignout';
import watchCaptureUDLParams from './handleAnalyticsParams';
import watchEncryptThenPageView from './handleUDLDeviceID';
import initializeUser from './initializeUser';
import watchUpdateShortTitleMeta from './updateMetaTag';
import {
  watchInitializeUcdeUser,
  watchSaveUcdeUserProfile,
  watchLoadUcdeUserProfile,
} from '../components/Dashboard/store/modules/ucdeUser/sagas';
import watchUcdeUserPortalElements from '../components/Dashboard/store/modules/portalElements/sagas';
import {
  watchGetDetailedDevices,
  watchGetDeviceSupplies,
} from '../components/Dashboard/store/modules/devices/sagas';

export default function* rootSaga() {
  yield fork(watchFailureNotifications);
  yield fork(initializeUser);
  yield fork(watchUpdateShortTitleMeta);
  yield fork(watchApiCallActions);
  yield fork(watchSetLocale);
  yield fork(watchLocationChange);
  yield fork(watchCompleteUserInfo);
  yield fork(watchRedirectToSignout);
  yield fork(watchInitializeUcdeUser);
  yield fork(watchUcdeUserPortalElements);
  yield fork(watchSaveUcdeUserProfile);
  yield fork(watchLoadUcdeUserProfile);
  yield fork(watchGetDetailedDevices);
  yield fork(watchGetDeviceSupplies);
  yield fork(watchCaptureUDLParams);
  yield fork(watchEncryptThenPageView);
}
