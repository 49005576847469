import React from 'react';
import { StyledFooter, StyledLink } from './styles';
import { useSelector } from '../../../hooks';
import { selectFooterProps } from '../../Dashboard/store/modules/portal/selectors.js'
import i18n from '../../Dashboard/utils/i18n';

const date = new Date();
const Footer = () => {
  const {
    country,
    language,
    accountUrl,
  } = useSelector(selectFooterProps);

  return (
    <StyledFooter
      mainContent={{
        leftArea: (
          <span>
            {`© Copyright ${date.getFullYear()} HP Development Company, L.P. `}
          </span>
        ),
        links: [{
          label: 'HP Support',
          url: 'https://www.hp.com/support',
        }, {
          label: 'End User License Agreement',
          url: 'http://www.hp.com/go/sweula',
        }, {
          label: 'HP Privacy',
          url: 'https://hp.com/privacy',
        }, {
          label: 'HP Smart Terms of Use',
          url: 'https://www.hpsmart.com/tou',
        }],
        rightArea: (
          <StyledLink to={accountUrl}>
            {`${i18n.t(`myAccount.profile.countries.${country}`)} (${i18n.t(`myAccount.profile.languages.${language}`)})`}
          </StyledLink>
        ),
      }}
      appearance="minimal"
    />
  );
};

export default Footer;
