import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSelector } from '../../../hooks/redux';
import { selectAddNewEmailCsData } from '../../../selectors/printAnywhere';
import LoaderElement from '../../shared-components/molecules/LoaderElement';
import * as ERROR_TYPES from '../../../constants/errorTypes';
import { addPrintAnywhereEmail } from '../../../actions/printAnywhereActions';
import { RTL_LANGUAGES } from '../../../constants/i18n';

const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line
const valueIsEmailAddress = value => emailRegex.test(value);

const useAddNewEmailHook = printerId => {
  const {
    printAnywhereChooseText,
    printAnywhereUserEmailText,
    printAnywhereAddButtonText,
    printAnywhereEmailErrorText,
    printAnywhereDuplicateEmailErrorText,
  } = useSelector(selectAddNewEmailCsData);

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [shareError, showShareError] = useState(false);
  const [pending, setPending] = useState(false);

  const handleChangeEmail = e => {
    showShareError(false);
    return setEmail(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!valueIsEmailAddress(email)) {
      return showShareError(true);
    }
    setPending(true);
    dispatch(addPrintAnywhereEmail(
      printerId,
      email,
      err => {
        showShareError(err);
        setPending(false);
        if (!err) {
          setEmail('');
        }
      },
    ));
  };

  return {
    printAnywhereChooseText,
    printAnywhereUserEmailText,
    printAnywhereAddButtonText,
    errorText: shareError === ERROR_TYPES.DUPLICATE
      ? (printAnywhereDuplicateEmailErrorText || printAnywhereEmailErrorText)
      : printAnywhereEmailErrorText,
    email,
    handleChangeEmail,
    shareError,
    handleSubmit,
    pending,
  };
};

const AddNewEmail = ({ platform, printerId }) => {
  const language = window.location.pathname.split('/')[2];
  const isRTLSupport = RTL_LANGUAGES.includes(language);
  const {
    printAnywhereChooseText,
    printAnywhereUserEmailText,
    printAnywhereAddButtonText,
    errorText,
    email,
    handleChangeEmail,
    shareError,
    handleSubmit,
    pending,
  } = useAddNewEmailHook(printerId);

  return (
    <ShareForm
      platform={platform}
      onSubmit={handleSubmit}
      isRTLSupport={isRTLSupport}
    >
      <h4>
        {printAnywhereChooseText}
      </h4>
      <span>
        {
          pending && (
            <LoaderWrapper>
              <LoaderElement />
            </LoaderWrapper>
          )
        }
        <input
          type="text"
          id="newShareEmail"
          placeholder={pending ? '' : printAnywhereUserEmailText}
          value={pending ? '' : email}
          onChange={handleChangeEmail}
        />
        <SubmitButton
          platform={platform}
          type="submit"
          id="newShareButton"
          disabled={!email}
        >
          {printAnywhereAddButtonText}
        </SubmitButton>
      </span>
      {
        shareError && (
          <Responses>
            <span className="feedbackMessage--error-visible">
              {errorText}
            </span>
          </Responses>
        )
      }
    </ShareForm>
  );
};

export default AddNewEmail;

const ShareForm = styled.form`
  border-top: 1px solid #E3E3E3;
  padding: ${props => (props.platform === 'desktop' ? '2.25rem 4.5rem 0' : '1.25rem')};
  @media (max-width: 340px) {
    padding: 0.5rem;
  }
  span {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: ${props => (props.platform === 'desktop' ? '1.5rem' : '1rem')};
    @media (max-width: 340px) {
      margin-top: 0.5rem;
    }
  }
  [type='text'] {
    border: 1px solid #eee;
    border-radius: 1.5rem;
    padding-left: 1rem;
    margin: 0;
    margin-right: ${props => props.isRTLSupport ? "0px" : "1rem"};
    margin-left: ${props => props.isRTLSupport ? "1rem" : "0px"};
    flex: 1 1 auto;
    max-width: 37rem;
    @media (max-width: 380px) {
      margin-right: 0.625rem;
      padding-left: 0.625rem;
    }
  }
`;
const SubmitButton = styled.button`
  :disabled {
    background-color: #bbb;
    color: ${props => (props.platform === 'desktop' ? '#4E4E4E' : 'white')};
  }
  &:hover, &:active, &:focus {
    background-color: #bbb;
    color: ${props => (props.platform === 'desktop' ? '#4E4E4E' : 'white')};
  }
  border: 0px;
  border-radius: .1875rem;
  font-size: 1rem;
  padding: ${props => (props.platform === 'desktop' ? '.8125rem 1.375rem .5625rem' : '.625rem 1.375rem')};
  background-color: #0171ad;
  color: white;
  @media (max-width: 380px) {
    padding: 0.625rem 1rem;
  }
`;

const Responses = styled.div`
  margin-top: 1rem;
  .feedbackMessage--error {
    border-radius: 5px;
    padding: 0.25rem 0.625rem;
    margin-bottom: 1.25rem;
    border: 0.125rem solid  #D67300;
    color: #D67300;
    display: none;
  }
  .feedbackMessage--error-visible {
    border-radius: 5px;
    padding: 0.25rem 0.625rem;
    margin-bottom: 1.25rem;
    border: 0.125rem solid  #D67300;
    color: #D67300;
    display: block;
  }
  .feedbackMessage--success {
    border-radius: 5px;
    padding: 0.25rem 0.625rem;
    margin-bottom: 1.25rem;
    border: 0.125rem solid #0084BC;
    color: #0084BC;
    display: none;
  }
  .feedbackMessage--success-visible {
    border-radius: 5px;
    padding: 0.25rem 0.625rem;
    margin-bottom: 1.25rem;
    border: 0.125rem solid #0084BC;
    color: #0084BC;
    display: block;
  }
`;
const LoaderWrapper = styled.div`
  position: absolute;
  z-index: 39;
  top: .6875rem;
  left: .9375rem;
`;
