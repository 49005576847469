import styled from 'styled-components';
import { breakpoints, theme } from '../../../utils/styleHelpers';
import EnrollContentBox from '../../shared-components/atoms/EnrollContentBox';

export const OrigamiHeroSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const OrigamiHeroMain = styled.div`
  width: 100%;
  display: flex;
  max-width: 100%;
  position: relative;
  height: 100%;

.origami-hero-grid-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: minmax(240px, auto) minmax(360px, auto);
    grid-auto-flow: row;
}

.origami-hero-section-1 {
    grid-row: 1;
    grid-column: 1;
    background-color: #F2EBDC;
}

.origami-hero-section-2 {
    grid-row: 2;
    grid-column: 1;
    border-top: 1px solid black;
    background-color: #F2EBDC;
}

.origami-hero-section-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row: 1 / span 2;
    grid-column: 2;
    border-left: 1px solid black;
}

@media (max-width: ${breakpoints.wide}) {
  .origami-hero-grid-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 540px auto auto;
    grid-auto-rows: minmax(auto, auto);
  }

  .origami-hero-section-1 {
    grid-row: 2;
    grid-column: 1;
  }

  .origami-hero-section-2 {
    grid-row: 3;
    grid-column: 1;
  }

  .origami-hero-section-3 {
    grid-row: 1;
    grid-column: 1;
    border-bottom: 1px solid black;
    border-left: none;
  }
}

@media (max-width: ${breakpoints.bigtablet}) {
  .origami-hero-section-3 {
    grid-template-columns: 1fr;
    grid-template-rows: 450px auto auto;
    grid-auto-rows: minmax(auto, auto);
    border-bottom: 1px solid black;
    border-left: none;
  }
}

@media (max-width: ${breakpoints.desktop}) {
  .origami-hero-grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 450px auto auto;
    grid-auto-rows: minmax(auto, auto);
  }
}
`;

export const OrigamiHeroBanner = styled.span`
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  background-image: url(${(props) => props.backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  background-position: center center;
  justify-content: center;
  @media (max-width: ${breakpoints.bigmobile}) {
    background-image: url(${(props) => props.mobileImage});
  }
`;

export const HeroHeading = styled.span`
  font-family: ${theme.fonts.family};
  font-style: normal;
  color: #212121;

  p {
    font-size: 62px;
    font-weight: 400;
    line-height: 68px;
    padding: 40px 125px;
    margin: 0;
  }

  @media (max-width: ${breakpoints.large}) {
    p {
      font-size: 56px;
      line-height: 64px;
      padding: 40px 125px;
    }
  }

  @media (max-width: ${breakpoints.wide}) {
    p {
      font-size: 40px;
      line-height: 48px;
      padding: 24px 24px;
    }
  }

  @media (max-width: ${breakpoints.small}) {
    p {
      font-size: 28px;
      line-height: 36px;
    }
  }
`;

export const HeroSubheading = styled.span`
  font-family: ${theme.fonts.familyLight};
  font-style: normal;
  color: #000000;
  p {
    font-size: 20px;
      line-height: 28px;
      font-weight: 400;
      padding: 20px 125px;
      margin:0;
  }

  @media (max-width: ${breakpoints.wide}) {
    p {
      font-size: 20px;
      line-height: 32px;
      font-weight: 400;
      padding: 12px 24px;
      margin:0;
    }
  }

  @media (max-width: ${breakpoints.small}) {
    p {
      font-size: 18px;
      line-height: 32px;
      font-weight: 400;
      padding: 12px 24px;
      margin:0;
    }
  }

  @media (max-width: 320px) {
    p {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      padding: 10px 10px;
      margin:0;
    }
  }

  @media (max-width: ${breakpoints.xSmall}) {
    p {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      padding: 12px 24px;
      margin:0;
    }
  }
`;

export const HeroEnrollContentBox = styled(EnrollContentBox)`
  &.heroEnroll {
    padding: 0px 40px 24px 125px;

    @media (max-width: ${breakpoints.wide}) {
      padding: 5px 24px;
    }

    @media (max-width: ${breakpoints.bigmobile}) {
      button {
        width: 100%;
      }
    }
  }
`;
