import React from 'react';
import styled from 'styled-components';
import Footer from './revised/Global/Footer';
import NavBar from './revised/Global/Navbar';
import { selectErrorPageContentStackData } from '../selectors/craft';
import { useSelector } from '../hooks';
import { fontPacks } from '../utils/styleHelpers';

export const Container = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 6rem;
`;

export const Title = styled.h3`
  ${fontPacks.titleRegularLight}
`;

export const Body = styled.div`
  ${fontPacks.bodyLarge}
  padding-left: 1.375rem;
  padding-right: 1.375rem;
`;
const Error = () => {
  const errorHeaderBody = useSelector(selectErrorPageContentStackData);
  const errorPageContentStackData = errorHeaderBody?.data?.entry;

  if (errorPageContentStackData) {
    const {error_body, error_heading} = errorPageContentStackData;

    return (
      <div>
        <NavBar minimal />
        <Container>

          {(error_heading.match(/<[a-z]+>/)
            ? <Title dangerouslySetInnerHTML={{ __html: error_heading }} />
            : <Title>{error_heading}</Title>)}

          {(error_body.match(/<[a-z]+>/)
            ? <Body dangerouslySetInnerHTML={{ __html: error_body }} />
            : <Body>{error_body}</Body>)}

        </Container>
        <Footer minimal />
      </div>
    );
  }
  return null;
};

export default Error;
