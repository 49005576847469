import React, { Component } from 'react';
import { compose, path, split, head } from 'ramda';
import AreaTypes from './../../constants/AreaTypes';
import classNames from 'classnames';
import bind from './../bind';
import LoaderElement from './../shared-components/molecules/LoaderElement';
import styled from 'styled-components';
import { RTL_LANGUAGES } from '../../constants/i18n';

class PrinterAddressChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressValue: '',
      valid: true,
      validMessage: '',
      dirty: false,
      emailChanging: false,
    };
    this.isPending = this.isPending.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAddressKeyUp = this.onAddressKeyUp.bind(this);
    this.input_ID = props.ID ? props.ID + '_email_input' : 'email_input'
    this.track_submit_signature = props.ID === 'onboarding_modal' ? 'onboarding-modal-submit' : 'eprint-email-submit'
  }

  isPending() {
    const { myPrinter: { isFetching, currentArea } } = this.props;
    return isFetching && currentArea === AreaTypes.MYPRINTER_EPRINT_SETTINGS_EMAIL;
  }

  getValidationGauntlet = function (printerData, currentEmail = '') {
    return [
      {
        message: printerData.print_anywhere_error_consecutive_dots_text,
        validate: (value) => {
          let regex = /.*\.\..*/;
          return regex.test(value);
        }
      },
      {
        message: printerData.print_anywhere_error_two_dots_text,
        validate: (value) => {
          let regex = /.*\..*\..*\..*/;
          return regex.test(value);
        }
      },
      {
        message: printerData.print_anywhere_error_more_underscores_text,
        validate: (value) => {
          // let regex = /[_]{2,}/;
          let regex = /.*\_.*\_.*/;
          return regex.test(value);
        }
      },
      {
        message: printerData.print_anywhere_error_character_length_text, //TODO: Move this to submit action!
        validate: (value) => {
          return value.length > 30 || value.length < 6;
        }
      },
      {
        message: printerData.print_anywhere_error_invalid_characters_text,
        validate: (value) => {
          let regex = /[^_A-Za-z0-9\-\.]/;
          return regex.test(value);
        }
      },
      {
        message: printerData.print_anywhere_email_error_text,
        validate: (value) => {
          return value === currentEmail
        }
      },
      {
        message: printerData.print_anywhere_error_invalid_characters_text, //////////// NEEDS NEW ERROR MESSAGE FOR RESERVED WORDS
        validate: (value) => {
          let reservedWords = [
            'Noc',
            'www',
            'hp.domains',
            'Webmaster',
            'Marketing',
            'Admin',
            'News',
            'Sales',
            'Uucp',
            'administrator',
            'Info',
            'Postmaster',
            'Clamav',
            'Abuse',
            'Hostmaster',
            'Donotreply',
            'Usenet',
            'Security',
            'Support',
            'ftp',
            'Root'
          ]
          for (let i = reservedWords.length - 1; i >= 0; i--) {
            if (value.toLowerCase() === reservedWords[i].toLowerCase()) { //// return if value IS reserved
              return true
            }
          }
          return false
        }
      }
    ];
  };

  validateValue = (value, printerData) => {
    const { printAnywhere } = this.props;
    const currentEmail = compose(
      head,
      split('@'),
      path(['data', 'WPPData', 'printerEmail'])
    )(printAnywhere);

    let validationGauntlet = this.getValidationGauntlet(printerData, currentEmail);

    for (let i = 0, len = validationGauntlet.length; i < len; i++) {
      let currConfig = validationGauntlet[i];
      if (currConfig.validate(value)) {
        return currConfig.message;
      }
    }
    return true;
  };

  validateAddress() {
    const { printAnywhere: { data: { csData } } } = this.props;
    const { addressValue } = this.state;
    const message = this.validateValue(addressValue, csData);
    if (message === true) {
      this.removeErrorMessage()
      return true;
    } else {
      this.removeErrorMessage()

      this.setState({
        validMessage: message,
        valid: false
      })
      return false;
    }
  }

  removeErrorMessage = () => {
    const { clearUpdateMyPrinterAddressStatus } = this.props;
    this.setState(state => ({
      validMessage: '',
      valid: true,
      dirty: state.addressValue !== ''
    }), clearUpdateMyPrinterAddressStatus)
  }

  handleSubmit(e) {
    const {
      myPrintersAltered,
      trackingHook,
      updateMyPrintAnywherePrinterAddress,
      printAnywhere: {
        data: {
          WPPData: {
            ownershipId,
            printerId,
            generation,
            printerEmailDomain,
          },
          csData,
        },
      },
    } = this.props;
    const {
      addressValue,
    } = this.state;

    myPrintersAltered()
    trackingHook(this.track_submit_signature);

    if (this.isPending()) {
      return false;
    }

    if (!this.validateAddress()) {
      return;
    }

    this.setState({ emailChanging: true })

    updateMyPrintAnywherePrinterAddress(
      ownershipId,
      printerId,
      addressValue,
      generation,
      printerEmailDomain,
      error => {
        if (!error) {
          document.getElementById(this.input_ID).value = '';
        }
        this.setState({
          emailChanging: false,
          ...error
            ? {
              valid: false,
              validMessage: csData.print_anywhere_email_error_text
            }
            : {
              addressValue: '',
              dirty: false,
            },
        });
      },
    );
  }

  onAddressKeyUp(e) {
    if (e.keyCode === 13) {
      this.handleSubmit(e);
      return true;
    }
    this.setState({
      addressValue: e.target.value,
      dirty: true,
    })
    this.validateAddress();
    if (e.target.value.length > 0) {
      document.getElementById('newChangeButton').setAttribute('style', 'color:white; background-color:#0171ad;')
    } else {
      document.getElementById('newChangeButton').removeAttribute('style')
    }
  }

  render() {
    const language = window.location.pathname.split('/')[2];
    const isRTLSupport = RTL_LANGUAGES.includes(language);
    const { printAnywhere, platform } = this.props;
    const { valid, validMessage, dirty, emailChanging } = this.state;
    const printer = path(['data', 'WPPData'], printAnywhere);
    const csData = path(['data', 'csData'], printAnywhere);
    if (!csData || !printer) {
      return null
    }

    const formSuccess = valid &&
      validMessage !== '' &&
      !dirty

    return (
      <PrintAnywhereForm platform={platform}>
        <div>
          {
            platform !== 'desktop' && (
              <ChangeDescription>
                {csData.print_anywhere_email_description_text}
              </ChangeDescription>
            )
          }
          <p>
            <strong>
              {csData.print_anywhere_address_label_text}
            </strong>
          </p>
          <div>
            {
              emailChanging
                ? <LoaderElement />
                : printer.printerEmail
            }
          </div>
          {
            platform === 'desktop' && (
              <ChangeDescriptionDesktop>
                <strong>
                  {csData.print_anywhere_email_description_text}
                </strong>
              </ChangeDescriptionDesktop>
            )
          }
          <InputArea platform={platform} isRTLSupport={isRTLSupport}>
            <span>
              <input
                id={this.input_ID}
                type="text"
                placeholder={csData.print_anywhere_address_placeholder}
                onKeyUp={this.onAddressKeyUp}
              />
              {' '}@hpeprint.com
            </span>
            <input
              id="newChangeButton"
              type="button"
              value={csData.print_anywhere_change_text}
              onClick={this.handleSubmit}
              disabled={this.isPending()}
            />
          </InputArea>
        </div>
        <Responses id="responses" >
          <span
            className={classNames({
              'feedbackMessage--error': valid || !dirty,
              'feedbackMessage--error-visible': !valid && dirty
            })}
          >
            {validMessage}
          </span>
          <span
            className={classNames({
              'feedbackMessage--success': !formSuccess,
              'feedbackMessage--success-visible': formSuccess
            })}
          >
            {csData.print_anywhere_change_success_text}
          </span>
          <br />
          <span
            className={classNames({
              'feedbackMessage--error': !printAnywhere.isError,
              'feedbackMessage--error-visible': printAnywhere.isError
            })}
          >
            {csData.print_anywhere_change_duplicate_text}
          </span>
        </Responses>
      </PrintAnywhereForm>
    )
  };
}

export default bind(PrinterAddressChange);

const InputArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    > * { 
      margin-top: 1rem;
    }
  [type='text']{
    flex: 1 0 auto;
    border: 1px solid #eee;
    border-radius: 1.5rem;
    padding: .625rem 1rem;
    max-width: 37rem;
    width: auto;
    margin: ${props => props.isRTLSupport ? "0 0 0 1rem" : "0 1rem 0 0"};
    @media (max-width: 410px) {
      margin-bottom: 0.3rem;
      padding: 0.4rem 0.5rem;
    }
  }
  span { 
    flex: 1 1 auto;
    display: flex;
    margin-right: ${props => props.isRTLSupport ? "0px" : "1rem"};
    margin-left: ${props => props.isRTLSupport ? "1rem" : "0px"};
    align-items: center;
    @media (max-width: 410px) {
      margin-right: 0;
      margin-top: 0.4rem;
      flex-flow: row wrap;

    }
  }
  [type='button']{
    flex: 0 0 auto;
    background-color: #bbb;
    color: ${props => props.platform === 'desktop' ? '#4E4E4E' : 'white'};
    border: 0px;
    border-radius: .1875rem;
    font-size: 1rem;
    padding: ${props => props.platform === 'desktop' ? '.8125rem 1.375rem .5625rem' : '.625rem 1.375rem'};
  }
`
const ChangeDescription = styled.p`
  margin-top: 0;
`
const ChangeDescriptionDesktop = styled.p`
  margin: 2rem 0 .25rem;
`
const Responses = styled.div`
margin-top: 1rem;
.feedbackMessage--error {
  border-radius: 5px;
  padding: 0.25rem 0.625rem;
  margin-bottom: 1.25rem;
  border: 2px solid  #D67300;
  color: #D67300;
  display: none; }

.feedbackMessage--error-visible {
  border-radius: 5px;
  padding: 0.25rem 0.625rem;
  margin-bottom: 1.25rem;
  border: 2px solid  #D67300;
  color: #D67300;
  display: block; }

.feedbackMessage--success {
  border-radius: 5px;
  padding: 0.25rem 0.625rem;
  margin-bottom: 1.25rem;
  border: 2px solid #0084BC;
  color: #0084BC;
  display: none; }

.feedbackMessage--success-visible {
  border-radius: 5px;
  padding: 0.25rem 0.625rem;
  margin-bottom: 1.25rem;
  border: 2px solid #0084BC;
  color: #0084BC;
  display: block; }

`

const PrintAnywhereForm = styled.div`
  margin: ${props => props.platform === 'desktop' ? '0 4.5rem 1.25rem' : '0 1.25rem .625rem'};
  @media (max-width: 340px) {
    margin: 0 0.5rem 0.3rem;
  }
`
