import React from 'react';

import {
  AnimatedSection,
  BannerLoadingSection,
  BannerContentLoadingSection
} from '../styles/StyleSkeleton';

const SkeletonHero = () => {
  return (
    <BannerLoadingSection>
      <AnimatedSection height="100%">
        <BannerContentLoadingSection>
          <AnimatedSection height="50px" margin="10px" />
          <AnimatedSection height="50px" margin="30px" />
          <AnimatedSection height="30px" margin="5px" />
          <AnimatedSection height="30px" margin="5px" />
          <AnimatedSection height="30px" margin="30px" />
          <AnimatedSection height="40px" margin="20px" width="50%" />
          <AnimatedSection width="70%" margin="20px" />
          <AnimatedSection width="90%" />
        </BannerContentLoadingSection>
      </AnimatedSection>
    </BannerLoadingSection>
  );
};

export default SkeletonHero;
