import axios from 'axios';

const handleSuccess = ({
  appName, history, globalFunction, manifestData, language, country, setIsLoading,
}) => {
  const manifest = manifestData.data;
  const microfrontendScripts = [];

  const loadApplication = () => {
    if (manifest['app.js']) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = manifest['app.js'];
      script.id = `${appName}-application`;
      script.onload = () => window[globalFunction](appName, history);

      microfrontendScripts.push(script.id);

      document.body.appendChild(script);

      setIsLoading(false);
    }

    if (manifest['app.css']) {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = manifest['app.css'];
      style.id = `${appName}-styles`;

      microfrontendScripts.push(style.id);

      document.head.appendChild(style);
    }
  };

  const loadVendors = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = manifest['vendors.js'];
    script.id = `${appName}-vendors`;
    script.onload = loadApplication;

    microfrontendScripts.push(script.id);

    document.body.appendChild(script);
  };

  const handleLoad = manifest['vendors.js'] ? loadVendors : loadApplication;
  let langScripts = Object.keys(manifest).filter(key => key.toLowerCase().includes((`${language}_${country}`).toLowerCase()));

  if (langScripts.length === 0) {
    langScripts = Object.keys(manifest).filter(key => key.toLowerCase().includes('en_us'));
  }

  if (langScripts.length > 0) {
    langScripts.forEach(script => {
      const langScript = document.createElement('script');
      langScript.type = 'text/javascript';
      langScript.src = manifest[script];
      langScript.id = `${appName}-${script}`;

      microfrontendScripts.push(langScript.id);

      if (langScripts.length === microfrontendScripts.length) {
        langScript.onload = handleLoad;
      }

      document.head.appendChild(langScript);
    });
  } else {
    handleLoad();
  }

  return microfrontendScripts;
};

export const loadManifest = async mfeArguments => {
  const { setIsLoading, manifest } = mfeArguments;

  setIsLoading(true);

  const manifestData = await axios.get(manifest);

  return handleSuccess({ ...mfeArguments, manifestData });
};

export const unloadManifest = microFrontendScripts => {
  microFrontendScripts.forEach(script => {
    const elem = document.getElementById(script);
    elem.parentNode.removeChild(elem);
  });
};
