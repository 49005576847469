import styled from 'styled-components';
import { stylesIf } from '../../../../../../../utils/styleHelpers';

export const ErrorContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  width: auto;
`;

export const ErrorMessage = styled.div`
  color: #404040;
  margin: 5px;
  font-size: 10px;
  @media (min-width: ${props => props.theme.breakPoints.xg}) {
    ${stylesIf('printersPage', 'font-size: 15px')};
  }
`;

export const RefreshLink = styled.span`
  color: #027AAE;
  font-size: 12px;
  @media (min-width: ${props => props.theme.breakPoints.xg}) {
    ${stylesIf('printersPage', 'font-size: 14px')};
  }
  :hover {
    cursor: pointer;
  }
`;

export default ErrorMessage;
