export const userOSDetector = () => {
  try {
    const navigatorAgent = navigator.userAgent;

    if (
      navigatorAgent.indexOf('macintosh') !== -1 &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2
    )
      return 'iOS';

    const clientStrings = [
      { osName: 'Windows 10', osUserAgent: /(Windows 10.0|Windows NT 10.0)/i },
      { osName: 'Windows 8.1', osUserAgent: /(Windows 8.1|Windows NT 6.3)/i },
      { osName: 'Windows 8', osUserAgent: /(Windows 8|Windows NT 6.2)/i },
      { osName: 'Windows 7', osUserAgent: /(Windows 7|Windows NT 6.1)/i },
      { osName: 'Windows Vista', osUserAgent: /Windows NT 6.0/i },
      { osName: 'Windows Server 2003', osUserAgent: /Windows NT 5.2/i },
      { osName: 'Windows XP', osUserAgent: /(Windows NT 5.1|Windows XP)/i },
      { osName: 'Windows 2000', osUserAgent: /(Windows NT 5.0|Windows 2000)/i },
      { osName: 'Windows ME', osUserAgent: /(Win 9x 4.90|Windows ME)/i },
      { osName: 'Windows 98', osUserAgent: /(Windows 98|Win98)/i },
      { osName: 'Windows 95', osUserAgent: /(Windows 95|Win95|Windows_95)/i },
      { osName: 'Windows NT 4.0', osUserAgent: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/i },
      { osName: 'Windows CE', osUserAgent: /Windows CE/i },
      { osName: 'Windows 3.11', osUserAgent: /Win16/i },
      { osName: 'Android', osUserAgent: /Android/i },
      { osName: 'Open BSD', osUserAgent: /OpenBSD/i },
      { osName: 'Sun OS', osUserAgent: /SunOS/i },
      { osName: 'Chrome OS', osUserAgent: /CrOS/i },
      { osName: 'Linux', osUserAgent: /(Linux|X11(?!.*CrOS))/i },
      { osName: 'iOS', osUserAgent: /(iPhone|iPad|iPod)/i },
      { osName: 'Mac OS X', osUserAgent: /Mac OS X/i },
      { osName: 'Mac OS', osUserAgent: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/i },
      { osName: 'QNX', osUserAgent: /QNX/i },
      { osName: 'UNIX', osUserAgent: /UNIX/i },
      { osName: 'BeOS', osUserAgent: /BeOS/i },
      { osName: 'OS/2', osUserAgent: /OS\/2/i },
      {
        osName: 'Search Bot',
        osUserAgent: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/i
      }
    ];

    for (let os in clientStrings) {
      let currentClientString = clientStrings[os];
      if (currentClientString.osUserAgent.test(navigatorAgent)) return currentClientString.osName;
    }
    return 'Other.';
  } catch (error) {}
};
