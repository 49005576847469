import React from 'react';
import PropTypes from 'prop-types';
import UserDeviceStatus from './index';
import { Link } from '../../../../../routing';
import i18n from '../../../../utils/i18n';
import {
  getStatusWithId,
  stateReason,
} from './statusDictionary';
import {
  DetailContainer,
  Explanation,
  DrawerContainer,
  HeaderText,
} from './styles';

const DeviceStatusDetail = ({ status }) => {
  const { message, url } = getStatusWithId(status);
  return (
    <DetailContainer>
      <UserDeviceStatus
        status={status}
        printersPage
      />
      <Explanation>
        {url ? <Link to={i18n.t(url)}>{i18n.t(message)}</Link> : i18n.t(message)}
      </Explanation>
    </DetailContainer>
  );
};

DeviceStatusDetail.propTypes = {
  status: PropTypes.string.isRequired,
};

const PrinterStateDrawer = ({ nonOriginal, reasons = [] }) => (
  <DrawerContainer>
    <HeaderText>{i18n.t('myPrinters.printerDetails.title')}</HeaderText>
    {
        nonOriginal && (
          <DeviceStatusDetail
            key="not-original-inks"
            status="not-original-inks"
          />
        )
      }
    {
        reasons.map(reason => reason && (
          <DeviceStatusDetail
            key={reason}
            status={reason}
          />
        ))
      }
  </DrawerContainer>
);

PrinterStateDrawer.propTypes = {
  nonOriginal: PropTypes.bool.isRequired,
  reasons: PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
    if (!Object.keys(stateReason).some(reason => propValue[key] && reason === propValue[key].toLowerCase())) {
      return new Error(
        `Invalid prop \`${propFullName}\` supplied to \`${componentName}\`. Validation failed.`,
      );
    }
    return undefined;
  }).isRequired,
};

export default PrinterStateDrawer;
