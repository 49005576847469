import React, { Component } from 'react';
import BreadCrumbs from '../shared-components/atoms/BreadCrumbs';
import HollowButton from '../shared-components/atoms/hollowButton';
import bind from '../bind';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.originalSettings = {checkbox0: false, checkbox1: false} // get from backend
    this.currentSettings = {checkbox0: false, checkbox1: false} // get from backend
    this.state = { checkbox0: false, checkbox1: false, settingsChanged: false }
  }

  componentDidMount() {
    this.props.getNotifications(this.props.locales.alt4);
  }

  changeInput = (arg) => {
    this.currentSettings[arg] = !this.state[arg]
    this.setState({
      [arg]: !this.state[arg],
      settingsChanged: this.currentSettings["checkbox0"] !== this.originalSettings["checkbox0"] || this.currentSettings["checkbox1"] !== this.originalSettings["checkbox1"]
    })
  }

  byAnchorChangeInput = (arg) => {
    document.getElementById(arg).checked = !document.getElementById(arg).checked
    this.changeInput(arg)
  }

  returnToThePast = () => {
    this.props.history.goBack()
  }

  render() {
    const notify = this.props.notifications.notificationData
    if (this.props.notifications.isComplete) {
      return (
        <div className="setting">
          <div className="setting--meta">
            <BreadCrumbs section={notify.notification} />
          </div>
          <form className="setting--form">
            <div className="setting--headline">
              <h2>{notify.notification}</h2>
            </div>
            <fieldset>
              <div className="setting--input">
                <input id="checkbox0" data-tracking={notify.preferenceTitle1} type="checkbox" defaultChecked={this.state.checkbox0} onChange={() => this.changeInput("checkbox0")} />
                <a id="input0anchor" data-tracking={notify.preferenceTitle1} className="settings--inputLink" onClick={() => this.byAnchorChangeInput("checkbox0")}>{notify.preferenceDescription1}</a>
              </div>
              <div className="settingButtons--buttonPair">
                <HollowButton buttonID="cancel-notifications-button" buttonText={notify.cancel}  onClick={this.returnToThePast} /> 
                {/* TODO: change to notifications.saveChanges.url */}
                <HollowButton buttonID="save-notifications-button" disabled={!this.state.settingsChanged} buttonText={notify.saveChanges} buttonLink={`/${this.props.match.params.languageCountry}/myprinters`} />
              </div>
            </fieldset>
          </form>
        </div>
      )
    } else if (this.props.notifications.isError) {
      return (
        <div className="setting">
          {this.props.notifications.errorMsg}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default bind(Notifications);