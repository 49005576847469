import React, { useEffect } from 'react';
import { prop } from 'ramda';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../hooks';
import { userOSDetector } from '../../utils/userOSDetector';
import { Button, IconArrowLeft } from '@veneer/core';
import { replace as navReplace } from 'connected-react-router';
import { selectDataCollectionNoticeProps } from '../../selectors/dataCollectionNotice';
import {
  StyledContainer,
  StyledContent,
  StyledTitle,
  StyledDate,
  MainContent,
  BackButtonContainer
} from '../terms-of-use/plain.styles';

const useNoticeHook = () => {
  const dispatch = useDispatch();
  const { content, actualCorrectedPath } = useSelector(selectDataCollectionNoticeProps);

  useEffect(() => {
    if (actualCorrectedPath) dispatch(navReplace(actualCorrectedPath));
  }, [actualCorrectedPath]);

  return content;
};

const LegalNotice = () => {
  const history = useHistory();
  const content = useNoticeHook();
  const userOperatingSystem = userOSDetector();
  const mobileOperatingSystems = new Set(['iOS', 'Android']);

  const onBackButtonClick = () => history.goBack();

  if (content) {
    const { title, last_updated, document_body } = content;

    return (
      <StyledContainer>
        <StyledContent>
          <StyledTitle>{title}</StyledTitle>
          <StyledDate>{last_updated}</StyledDate>
          {document_body && <MainContent dangerouslySetInnerHTML={{ __html: document_body }} />}
          {mobileOperatingSystems.has(userOperatingSystem) && (
            <BackButtonContainer>
              <Button appearance="secondary" onClick={onBackButtonClick}>
                <IconArrowLeft color="colorHpBlue6" />
              </Button>
            </BackButtonContainer>
          )}
        </StyledContent>
      </StyledContainer>
    );
  }
  if (prop('isError', content)) {
    return <p>{content.errorMsg}</p>;
  }
  return null;
};

export default LegalNotice;