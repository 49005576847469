import axios from "axios";
import CraftApiModel from "../models/CraftApiModel";
import ActionTypes from "../constants/ActionTypes";
import { getApiPathWithLocale } from "../lib/urlUtils";

/**
 * This function uses axios to get printer list.
 *
 * Since axios returns promises, we can dispatch our start event and
 * update it to complete or complete with error whe the promise returns.
 *
 * @returns {function(*)}
 */
export function getHome(currentLocale = "") {
    let start = () => ({
        type: ActionTypes.START_HOMECONTENT_FETCH
    }),
        complete = (home) => ({
            type: ActionTypes.COMPLETE_HOMECONTENT_FETCH,
            home
        }),
        error = (err) => ({
            type: ActionTypes.COMPLETE_HOMECONTENT_WITH_ERROR,
            err
        });
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(start());
            axios.get(getApiPathWithLocale("/api/homepage.json", currentLocale)
                // , { timeout: 30000 }
            )
                .then((res) => {
                    let banners = new CraftApiModel(res.data && res.data.data && res.data.data.length > 0 ? res.data.data[0] : {});
                    dispatch(complete(banners));
                    return resolve(banners);
                })
                .catch((err) => {
                    if (err.response && err.response.status === 403) {
                        dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT })
                    }
                    dispatch(error(err));
                    reject(err);
                });
        });
    }
} 
