import json from '../locale/index';


const DEFAULT_COUNTRY = 'US';
const DEFAULT_LANGUAGE = 'en';

function stringParser(string, keys) {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
  return string.replace(templateMatcher, (substring, idx) => keys[idx]);
}

function getFallbackCountry(language) {
  const fallbacks = {
    en: 'US',
    de: 'DE',
    bg: 'BG',
    cs: 'CZ',
    el: 'GR',
    et: 'EE',
    hr: 'HR',
    hu: 'HU',
    lt: 'LT',
    lv: 'LV',
    pl: 'PL',
    ro: 'RO',
    sk: 'SK',
  };
  return fallbacks[language];
}

class I18n {
  constructor() {
    this.country = DEFAULT_COUNTRY;
    this.language = DEFAULT_LANGUAGE;
  }

  locale() {
    return `${this.language}_${this.country}`;
  }

  setSupportedLocale(country, language, userLocale = '') {
    let upperCasedCountry = country.toUpperCase();
    this.language = language;

    if (json[`${language}_${upperCasedCountry}`]) {
      this.country = upperCasedCountry;
      return;
    }

    let fallbackCountry = getFallbackCountry(language);
    if (fallbackCountry) {
      this.country = fallbackCountry;
      return;
    }

    if (userLocale) {
      const [userCountry, userLanguage] = userLocale.split('_');
      upperCasedCountry = userCountry.toUpperCase();
      this.language = userLanguage;

      if (json[`${userLanguage}_${upperCasedCountry}`]) {
        this.country = upperCasedCountry;
        return;
      }

      fallbackCountry = getFallbackCountry(userLanguage);
      if (fallbackCountry) {
        this.country = fallbackCountry;
        return;
      }
    }

    this.country = DEFAULT_COUNTRY;
    this.language = DEFAULT_LANGUAGE;
  }

  t(id, keys) {
    try {
      const rawString = id.split('.').reduce((o, i) => o[i], json[this.locale()]);
      return stringParser(rawString, keys);
    } catch (err) {
      return id;
    }
  }
}

const i18n = new I18n(DEFAULT_COUNTRY, DEFAULT_LANGUAGE);

export default i18n;
