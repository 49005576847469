import Logger from "../lib/logger";
let dataExists = function(data){
    return data !== undefined && data !== null;
};
/**
 * CraftApiModel: The class built as a wrapper to provide one place for error checking logic of craft fields.
 */
export default class CraftApiModel{
    constructor(data){
        this._model = data;
    } 
    /**
     * The method allowing components to attempt to access craft fields if they exists.
     * Otherwise, return an empty string, or a default value specified.
     * 
     * @param {*} input
     * @param {*} defaultValue 
     */
    Get(input, defaultValue = ""){
        let returnValue = defaultValue;
        if(input.indexOf(".") === -1){
            if(this._model.hasOwnProperty(input)){
                returnValue = this._model[input];
            }
        }else{
            let series = input.split(".");
            let pointer = this._model, fullTransverse = true;
            for(let i = 0, len = series.length; i < len; i++){
                if(pointer !== null && pointer.hasOwnProperty(series[i])){
                    pointer = pointer[series[i]];
                }else{
                    fullTransverse = false;
                    break;
                }
            }
            if(fullTransverse){
                returnValue = pointer;
            }
        }
        if(returnValue === ""){
            Logger.Warning("Failed to load key: ", input);
        }
        return returnValue;
    }
    /**
     * Get a specific child object of the craft object if that child is stored as an array with only one element.
     * @param {*} input 
     */
    GetSingleArrayAsObject(input){
        let initial = this.Get(input);
        if(initial === "" || !Array.isArray(initial) || (Array.isArray(initial) && initial.length !== 1) ){
            return {};
        }
        return initial[0];
    }
}