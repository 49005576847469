export function get_hpsmartCustom() {
    let custom = false
    try {
        const url_string = window.location.href
        const url = new URL(url_string);
        const c = url.searchParams.get("hpSmartCustom");
        custom = c ? JSON.parse(c) : false
    } catch (e) {
        console.error("Catch: hpSmartCustom object from querystring was malformed.")
        custom = false
    }
    /// HARDCODED EXCEPTIONS -----------------------------------------------------------------------
    if (custom) {
        const objKeys = Object.keys(custom)
        if (objKeys.indexOf("psdrLink") !== -1) {
            const psdr_ids = ["psdr1", "psdr2"] // ids of duplications of psdrLink
            psdr_ids.forEach((ID) => {
                custom[ID] = custom["psdrLink"]
            })
        }
    }
    const url_array = window.location.href.split("?")[0].toLowerCase().split("/")
    if (url_array.indexOf("in-app-help") !== -1 && (url_array.indexOf("ios") !== -1 || url_array.indexOf("android") !== -1)) {
        const exception1 = {
            psdr1: { "removeElement": "anyString", },
            psdr2: { "removeElement": "anyValue" },
        }
        custom = custom ? custom : {}
        custom = { ...custom, ...exception1 }
    }
    const exception2 = {
        "goToYourPrintersPrintQualityTools": { "removeElement": "anything" },
    }
    custom = custom ? custom : {}
    custom = { ...exception2, ...custom }
    return custom
}
