import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ContentBox from '../shared-components/atoms/ContentBox';
import {
  AdditionalFeaturesSection,
  ImageContainer,
  AdditionalFeaturesContentBox
} from './styles/StyleOrigamiAdditionalFeature';
import { isIE } from '../../lib/isIE';
import TreeIcon from '../../images/misc/icon_tree.svg';

const HP_PAPER_OPTIMIZED_SECTION_ID = "cs74dc459835e2b5ae"
const FOREST_SECTION_ID = "cs77ba85eb2e287195"

const OrigamiAdditionalFeatures = (props) => {
  const isBrowserIE = isIE();
  const { additionalFeaturesSection } = props;

  return (
    <React.Fragment>
      {additionalFeaturesSection.map(function (item) {
        const {
          feature: {
            widescreen: { url: widescreenUrl },
            feature_heading: featureHeading,
            feature_details_text: featureDetailsText,
            _metadata: { uid: id }
          }
        } = item;

        const isPaperOptimizedSection = id === HP_PAPER_OPTIMIZED_SECTION_ID;

        return (
          <AdditionalFeaturesSection data-testid="origami-additional-features" key={id} isPaperOptimizedSection={isPaperOptimizedSection}>
            <ImageContainer isPaperOptimizedSection={isPaperOptimizedSection}>
              <img src={widescreenUrl} alt="Origami Paper Optimized Image" />
            </ImageContainer>
            <AdditionalFeaturesContentBox isPaperOptimizedSection={isPaperOptimizedSection} isIE={isBrowserIE}>
              {id === FOREST_SECTION_ID && <img src={TreeIcon} alt="Origami Tree Icon" />}
              <ContentBox titleText={featureHeading} descriptionText={featureDetailsText} />
            </AdditionalFeaturesContentBox>
          </AdditionalFeaturesSection>
        );
      })}
    </React.Fragment>
  );
};

OrigamiAdditionalFeatures.propTypes = {
  additionalFeaturesSection: PropTypes.array.isRequired
}

OrigamiAdditionalFeatures.defaultProps = {
  additionalFeaturesSection: []
};

export default memo(OrigamiAdditionalFeatures);
