import React from 'react';
import PropTypes from 'prop-types';
import {
  Cartridge,
  CartridgeBar,
  CartridgeLevelGrad,
  CartridgeLevel,
  CartridgeExtra,
  CartridgeContainer,
} from './styles';
import {
  SupplyNames,
} from '../supplyNames';

const colorMapping = {
  C: 'cyan', M: 'magenta', Y: 'yellow', K: 'black',
};

const DeviceCartridgeBar = ({
  type,
  level,
  state,
  extra,
}) => {
  if (type === SupplyNames.TRICOLOR) {
    return (
      <Cartridge
        tricolor
        level={level}
        state={state}
      >
        <CartridgeBar type={colorMapping[SupplyNames.C]}>
          <CartridgeLevel>
            <CartridgeLevelGrad grad={0.25} />
            <CartridgeLevelGrad grad={0.50} />
            <CartridgeLevelGrad grad={0.75} />
          </CartridgeLevel>
        </CartridgeBar>
        <CartridgeBar type={colorMapping[SupplyNames.M]}>
          <CartridgeLevel>
            <CartridgeLevelGrad grad={0.25} />
            <CartridgeLevelGrad grad={0.50} />
            <CartridgeLevelGrad grad={0.75} />
          </CartridgeLevel>
        </CartridgeBar>
        <CartridgeBar type={colorMapping[SupplyNames.Y]}>
          <CartridgeLevel>
            <CartridgeLevelGrad grad={0.25} />
            <CartridgeLevelGrad grad={0.50} />
            <CartridgeLevelGrad grad={0.75} />
          </CartridgeLevel>
        </CartridgeBar>
      </Cartridge>
    );
  }
  if (type === SupplyNames.TONER) {
    return (
      <CartridgeContainer>
        <Cartridge level={level} state={state}>
          <CartridgeBar type={colorMapping[SupplyNames.K]}>
            <CartridgeLevel>
              <CartridgeLevelGrad grad={0.25} />
              <CartridgeLevelGrad grad={0.50} />
              <CartridgeLevelGrad grad={0.75} />
            </CartridgeLevel>
          </CartridgeBar>
        </Cartridge>
        <CartridgeExtra>
          {extra}
        </CartridgeExtra>
      </CartridgeContainer>
    );
  }
  return (
    <Cartridge level={level} state={state}>
      <CartridgeBar type={colorMapping[type]}>
        <CartridgeLevel>
          <CartridgeLevelGrad grad={0.25} />
          <CartridgeLevelGrad grad={0.50} />
          <CartridgeLevelGrad grad={0.75} />
        </CartridgeLevel>
      </CartridgeBar>
    </Cartridge>
  );
};

DeviceCartridgeBar.defaultProps = {
  extra: undefined,
};

DeviceCartridgeBar.propTypes = {
  type: PropTypes.oneOf([SupplyNames.C, SupplyNames.M, SupplyNames.Y, SupplyNames.K,
    SupplyNames.TRICOLOR, SupplyNames.TONER]).isRequired,
  level: PropTypes.number.isRequired,
  state: PropTypes.oneOf(['OK', 'LOW', 'VERY_LOW']).isRequired,
  extra: PropTypes.string,
};

export default DeviceCartridgeBar;
