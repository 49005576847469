export function validateAccessEmail(input, invalidDomains = []) {
    let invalidDomainSet = invalidDomains.split(",");
    let domain = input.substring(input.lastIndexOf('.') + 1);
    let fail = false
    invalidDomainSet.forEach(dom => {
        if (domain.indexOf(dom) !== -1) {
            fail = true
            return false;
        }
    })
    if (fail) {
        return false
    }
    return /^[a-zA-Z0-9\._\%\+\-]+@[a-zA-Z0-9\.\-]+\.[A-Za-z]{2,}$/.test(input);
};


