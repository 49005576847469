import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { IconShieldPerson, Button } from '@veneer/core';
import { breakpoints, theme } from '../../utils/styleHelpers';
import { getQueryParams } from '../../utils/globals';
import Config from '../../config/Config';
import { selectContentStackDataByResource } from '../../selectors/craft';
import { useSelector } from '../../hooks';
import { TONER_REPLACE_CONSENTS_RESOURCE_NAME } from '../../constants/contentStackResources';

const TonerReplaceConsentsContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    padding: 1%;
  }
`;

const TonerReplaceConsentsContent = styled.div`
  && {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @media (min-width: ${breakpoints.xSmall}) {
      width: 80%;
    }
    @media (min-width: ${breakpoints.desktop}) {
      width: 50%;
    }
    margin: 40px 0 40px 0;
  }
`;

export const AcceptAllButton = styled(Button)`
  && {
    font-family: ${theme.fonts.family};
    padding: 15px 30px;
    min-width: auto;
    @media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      min-width: 9rem;
    }
  }
`;

const REDIRECT_URL = 'redirectUrl';
const SHIPPING_INFO = 'shippinginfo';
const KOREAN_LANG = 'ko';
const CONSENTS_API = '/api/2/consents';
const CONSENT_STATE = 'OptedIn';

const validUrlRegExp = new RegExp(
  '^(https?:\\/\\/)?(hpsmart?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&amp;a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
  'i'
);

const callConsents = async (method, url = '', data = {}) => {
  const response = await axios({
    method,
    url,
    withCredentials: true,
    data
  });
  return (response && response.data) ? response.data : {};
};

const TonerReplaceConsents = (props) => {
  const { language, country } = props;

  const config = new Config();
  const tonerReplacePurposeId = config.Get('TONER_REPLACE_PURPOSE_ID');

  const currentLocation = window.location.href;
  const queryParams = getQueryParams(currentLocation);
  const params = new URLSearchParams(queryParams);
  const newParams = new URLSearchParams();
  for (const [name, value] of params) {
    newParams.append(name.toLowerCase(), value);
  }
  const redirectURL = newParams.get(REDIRECT_URL.toLowerCase());
  const decodedRedirectURL =
    redirectURL && redirectURL !== undefined ? decodeURIComponent(redirectURL) : '';
  const shippingInfo = newParams.get(SHIPPING_INFO.toLowerCase());
  const decodeShippingInfo = JSON.parse(window.atob(shippingInfo));
  const emailId = decodeShippingInfo?.Email;

  const initConsentsState = {
    resourceId: '',
    purposeId: '',
    verbiageValue: ''
  };
  const [consentValues, setConsentValues] = useState(initConsentsState);
  const [isLoading, setIsLoading] = useState(true);

  const { toner_recall } = useSelector(selectContentStackDataByResource, [TONER_REPLACE_CONSENTS_RESOURCE_NAME]);
  const csData = toner_recall?.data?.entries[0];

  useEffect(() => {
    const consentsData = {
      language: language,
      purposeIdList: [tonerReplacePurposeId],
      isSetup: true,
      email: window.btoa(emailId)
    };

    callConsents('POST', CONSENTS_API, consentsData)
      .then((response) => {
        setConsentValues({
          ...consentValues,
          resourceId: response[0].resourceId,
          purposeId: response[0].purposeId,
          verbiageValue: response[0].verbiageValue
        });
        setIsLoading(false);
      })
      .catch((err) => {
        //TO DO
      });
  }, []);

  const handleRedirect = () => {
    const { resourceId, purposeId, verbiageValue } = consentValues;
    const consentsPatchData = {
      [resourceId]: {
        purposeId,
        verbiageValue,
        state: CONSENT_STATE
      }
    };
    setIsLoading(true);
    callConsents(
      'PATCH',
      `${CONSENTS_API}?email=${window.btoa(
        emailId
      )}&language=${language}&country=${country}&platform=windows`,
      consentsPatchData
    )
      .then((response) => {
        /** TODO
         * CHECK FOR WHITELISTED URLs FROM APP CONFIG
         **/
        setIsLoading(false);
        if (
          decodedRedirectURL &&
          decodedRedirectURL !== undefined &&
          validUrlRegExp.test(decodedRedirectURL)
        ) {
          window.location.assign(`${decodedRedirectURL}${queryParams}`);
        }
        return false;
      })
      .catch((err) => {
        //TO DO
      });
  };

  if (csData) {
    const {
      consent_content,
      consents_accept_button_text,
    } = csData;
    return (
      <TonerReplaceConsentsContainer>
        <IconShieldPerson color="colorHpBlue6" size={144} />
        <TonerReplaceConsentsContent dangerouslySetInnerHTML={{ __html: consent_content }} />
        <AcceptAllButton loading={isLoading} disabled={isLoading} onClick={handleRedirect}>
          {consents_accept_button_text}
        </AcceptAllButton>
      </TonerReplaceConsentsContainer>
    );
  }
  return null;

}

export default TonerReplaceConsents;