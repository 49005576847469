import styled from 'styled-components';

export const Header = styled.header`
  padding: 3.125rem 0.9375rem;
  text-align: center;
  margin-bottom: -0.625rem;
  line-height: 3rem;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  color: #027aae;
  font-weight: bold;
`;

export const SubTitle = styled.h2`
  font-size: 1.25rem;
  text-transform: uppercase;
  line-height: 1.5rem;
  font-weight: bold;
`;

export const TableContainer = styled.div`
  box-shadow: 0rem 0.25rem 1rem rgba(33, 33, 33, 0.1);
  border-radius: 0.75rem;
  max-width: 100%;
  width: 90%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  margin: auto auto;
  background-color: #fff;
`;

export const CellContainer = styled.div`
  display: flex;

  span {
    margin-left: 5px;
  }
`

export const TableConatinerTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead > tr {
    background-color: transparent;
    font-weight: bolder;
  }
  th {
    border-bottom: 0.125rem solid #ddd;
    padding: 0.625rem;
    text-align: left;
    font-size: 1.25rem;
    font-weight: bold;
    &:nth-child(1) {
      font-size: 1.75rem;
      line-height: 1.75rem;
    }
    &:nth-child(2) {
      color: #d06702;
      font-size: 1.25rem;
    }
  }
  td {
    border-bottom: 0.0625rem solid #ddd;
    padding: 0.5rem 1.5rem;
    &:nth-child(1) {
      font-weight: bold;
      width: 40%;
      font-size: 1.25rem;
    }
    &:nth-child(2) {
      background-color: rgba(208, 103, 2, 0.1);
    }
  }
`;

export const ExtraBenifits = styled.span`
  background: #d06702;
  padding: 0.375rem 0.9375rem;
  border-radius: 1.25rem;
  margin-left: 0.625rem;
  display: inline-block;
  max-width: 65%;
  font-size: 0.75rem;
  color: #fff;
  font-weight: bold;
  width: max-content;
  text-align: center;
`;

export const ExclusiveFeatures = styled.td`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.125rem solid #ddd;
  font-weight: 400;
`;

export const Tooltip = styled.div`
  float: right;
  padding-right: 1.25rem;
  position: relative;
  display: inline-block;
  font-size: 1.25rem;
  padding-left: 0.3125rem;
  color: #cdcdcd;
  &:hover > span {
    visibility: visible;
  }
`;

export const TooltipText = styled.span`
  right: 0rem;
  top: 2.375rem;
  visibility: hidden;
  width: 12.5rem;
  font-size: 0.75rem;
  background-color: #404040;
  color: #fff;
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.3125rem 0.3125rem;
  position: absolute;
  z-index: 1;
  right: 0.1rem;
  top: 100%;
  &&:after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 1.25rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: transparent transparent #404040 transparent;
  }
`;

export const CellContent = styled.div`
  display: inline-block;
  padding-bottom: 10px;
`;
