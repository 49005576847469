import React from 'react';
import { Title, Header, SubTitle } from './SACompareStyles';

const SACompareTitle = (props) => {
  return (
    <Header id="Header-Title-Desk">
      <SubTitle id="SubTitle-Desk">{props.subTitle}</SubTitle>
      <Title id="Title-Desk" dangerouslySetInnerHTML={{ __html: props.title }} />
    </Header>
  );
};

export default SACompareTitle;
