import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, useToast } from '@veneer/core';
import { selectRootMatch } from '../../../../selectors/routing';
import { Description, Title } from '../shared-components/styles';
import {
  AddUser,
  Content,
  LoaderContent,
  getColor,
} from './styles';
import UserList from './Users/usersList';
import i18n from '../../utils/i18n';
import { getUsers } from '../../api/UCDEGateway';
import { Link } from '../../../routing';
import InviteUsers from './InviteUsers';
import Loader from '../shared-components/Loader';
import { renderErrorModal } from '../ErrorModal';
import Config from '../../../../config/Config';
import { PATHS } from '../../utils/constants';

const config = new Config();
const featureFlags = config.Get('FEATURE_FLAGS');

const defaultModalErrorOptions = {
  title: i18n.t('manageUsers.error.list.header'),
  errorMessage: i18n.t('manageUsers.error.list.body'),
  actionLabel: i18n.t('manageUsers.error.list.buttons.retry'),
  actionCallback: () => window.location.reload(),
  dismissLabel: i18n.t('manageUsers.error.list.buttons.cancel'),
};

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalAddUser, setShowModalAddUser] = useState(false);
  const { url } = useSelector(selectRootMatch);
  const { addToast } = useToast();
  const openToast = (id, type, text) => {
    addToast({ id: `${id}-toast`, type, text });
  };

  async function loadUsers() {
    setIsLoading(true);
    try {
      const usersData = await getUsers();
      const usersInfo = usersData.map((user, index) => ({
        resourceId: user.resourceId,
        fullName: `${user.givenName || ''} ${user.familyName || ''}`,
        email: user.email,
        ownerTenant: user.ownerTenant || false,
        state: user.state,
        color: getColor(index),
      }));
      setUsers(usersInfo);
    } catch (getUsersError) {
      renderErrorModal(defaultModalErrorOptions);
    }
    setIsLoading(false);
  }

  const onError = ({ email }) => openToast(
    email, 'negative',
    i18n.t('manageUsers.table.delete.errorMessage', { email }),
  );

  const reloadUsers = () => loadUsers(setUsers);

  const DescriptionContent = () => {
    if (featureFlags.ff_ucde_portal_services) {
      return (
        <Description>
          {`${i18n.t('manageUsers.topOfPage.copyHyperlink')} `}
          <Link to={`${url}${PATHS.SERVICES.SUMMARY}`}>{i18n.t('manageHPBenefits.headers.mainHeader')}</Link>
          .
        </Description>
      );
    }
    return (
      <Description>
        {`${i18n.t('manageUsers.topOfPage.copy')}`}
      </Description>
    );
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <Content
      data-testid="manage-users"
    >
      <Title>{i18n.t('manageUsers.topOfPage.mainHeader')}</Title>
      <DescriptionContent />
      <AddUser>
        <Button
          ariaLabel={i18n.t('manageUsers.button')}
          onClick={() => {
            setShowModalAddUser(true);
          }}
        >
          {i18n.t('manageUsers.button')}
        </Button>
      </AddUser>
      {isLoading ? (
        <LoaderContent>
          <Loader />
        </LoaderContent>
      ) : (
        <UserList
          users={users}
          onDelete={reloadUsers}
          onError={onError}
        />
      )}
      <InviteUsers
        show={showModalAddUser}
        users={users}
        onClose={() => setShowModalAddUser(false)}
        onFinish={reloadUsers}
      />
    </Content>
  );
};

export default ManageUsers;
