import ActionTypes from '../constants/ActionTypes';
import { makeActionCreator } from '../utils/redux';

export const gotCraftData = makeActionCreator(ActionTypes.CRAFT_CONTENT, 'resource', 'data');

export const craftDataFailure = makeActionCreator(
  ActionTypes.CRAFT_CONTENT_FAILURE,
  'resource',
  'error'
);
