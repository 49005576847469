import { takeLeading, put } from 'redux-saga/effects';
import {
  getUcdePortalElementsRequest,
  getUcdeUserPortalElements,
} from './actions';

function* loadUcdePortalElements() {
  yield put(getUcdeUserPortalElements);
}

export default function* watchUcdeUserPortalElements() {
  yield takeLeading(
    getUcdePortalElementsRequest.toString(),
    loadUcdePortalElements,
  );
}
