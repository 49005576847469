import React from 'react'
import styled from 'styled-components'
import LandingPageSectionHead from "./LandingPageSectionHead";
const BenefitSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  > div {
    margin: 0 auto;
  }
  h2, p {
    width: 100%;
  }
`
const Benefits = styled.div`
  padding: 2.5rem 1.25rem 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media screen and (min-width:${props => props.theme.small}) {
    > div {
      margin: initial;;
    }
    > div + div {
      margin-left: 5rem;
    }
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
  }
  @media screen and (min-width:${props => props.theme.large}) {
    padding-top: 1.5rem;
    > div + div {
    margin-left: 8rem;
    }
  }
`
const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-width: 15rem;
  @media screen and (min-width:${props => props.theme.small}) {
    flex: 1 1 15rem;
  }
  @media screen and (min-width:${props => props.theme.large}) {
    max-width: 16rem;
    flex: 1 1 16rem;
  }
  h3 {
    font-size: 1.625rem;
    margin: 1.125rem 0 1rem;
    line-height: 1.3;
    width: 100%;
  }
  p {
    font-size: 1.25rem;
    margin: 0 0 3.5rem;
    line-height: 1.4;
    width: 100%;
    @media screen and (min-width:${props => props.theme.small}) {
      margin: 0 0 5rem;
    }
    @media screen and (min-width:${props => props.theme.large}) {
      margin: 0 0 10.25rem;
    }
  }
  > * {
    flex: 1 1 auto;
  }
`;
export default function LandingPageBenefits(props) {
  return (
    <BenefitSection id={props.sectionID}>
      {props.benefitsSection.map(({ benefits_overview }, i) => 
        <LandingPageSectionHead key={i}
          sectionIconMobile={benefits_overview.benefits_icon_small.url}
          sectionIcon={benefits_overview.benefits_icon.url}
          headline={benefits_overview.benefits_headline_text}
          description={benefits_overview.benefits_description_text}
          isMobile={props.isMobile}
        />
      )}
      <Benefits>
        {props.benefits.map(({ benefit }, i) => 
          <Benefit key={i}>
            <img
              src={benefit.benefit_icon.url} 
              //alt={benefit.benefit_icon_title} 
            />
            <h3>{benefit.benefit_headline_text}</h3>
            <p>{benefit.benefit_description_text}</p>
          </Benefit>
        )}
      </Benefits>
    </BenefitSection>
  )
}