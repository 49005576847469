const REFURBISHED_CODE = 457
const EOSL_CODE = 460
export default function discernCarePackScenario(carepackLogic, countryCode = null, printerModelNumber = null) {

  if (!carepackLogic || Object.keys(carepackLogic).length === 0) {
    return null
  }
  let cpScenario = ""

  const isGB = countryCode === 'gb';

  let consumer_printer = carepackLogic.isConsumer
  let commercial_printer = carepackLogic.isCommercial  
  let in_Base_Warranty = carepackLogic.isWarranty
  let in_Care_Pack = carepackLogic.isCarepack
  let first_90_days = carepackLogic.firstNinety
  let last_90_days = isGB ? false : carepackLogic.lastNinety 
  let between_90_days = carepackLogic.betweenNineties
  let last_90_days_of_CarePack_warranty = isGB ? false : carepackLogic.lastNinety

  let is_within_start_date_and_end_date_of_CarePack_warranty = !carepackLogic.isExpired

  let refurbished = false
  let _EOSL = false
  if (!!carepackLogic.error) {
    if (Array.isArray(carepackLogic.error)) {
      for (let i = 0; i < carepackLogic.error.length; i++) {
        if (!refurbished) {
          refurbished = parseInt(carepackLogic.error[i].messageCode) === REFURBISHED_CODE
        }
        if (!_EOSL) {
          _EOSL = parseInt(carepackLogic.error[i].messageCode) === EOSL_CODE
        }
        if (parseInt(carepackLogic.error[i].messageCode) === -1) {
          return undefined
        }
        if (parseInt(carepackLogic.error[i].messageCode) === 504) {
          return undefined
        }
      }
    } else {
      if (parseInt(carepackLogic.error.code) === -1) {
        return undefined
      }
      if (parseInt(carepackLogic.error.code) === 504) {
        return undefined
      }
      refurbished = carepackLogic.error.code === REFURBISHED_CODE  
      _EOSL = carepackLogic.error.code === EOSL_CODE 
    }
  }

  let Care_Pack_Expired = carepackLogic.isExpired && carepackLogic.isCarepack
  let Base_Warranty_Expired = carepackLogic.isExpired && carepackLogic.isWarranty
  
  const scenario_2D_List = [
    "E3E03A",
    "P4C78A",
    "P4C85A",
    "P4C86A",
    "J7K33A",
    "T0F30A",
    "T0F32A",
    "J7K34A",
    "T0F33A",
    "CR768A",
    "G3J47A",
    "G1X85A",
    "Y0S18A",
    "Y0S19A",
    "G5J38A",
    "1KR71B",
    "1KR70B",
    "3UC57B",
    "4KJ69B",
    "1KR65B",
    "1KR64B",
    "1KR66B",
    "3UC61B",
    "D9L63A",
    "J3P68A",
    "D9L18A",
    "J6X76A",
    "K7S37A",
    "M9L70A",
    "T0G47A",
    "T0G48A",
    "T0G49A",
    "D9L19A",
    "K7S34A",
    "K7S35A",
    "M9L80A",
    "D9L20A",
    "3UK83B",
    "1KR50B",
    "1KR49B",
    "1KR51B",
    "3UK91B",
    "3UK86B",
    "1KR55B",
    "1MR78B",
    "1MR71B",
    "1MR70B",
    "3UL05B",

    "T6B70A",
    "T6B71A",
    "T6B59A",
    "T6B60A",
    "T6B80A",
    "T6B81A",
    "T6B82A",

    "Z4B56A",
    "Z6Z98A",
    "1TJ12A",
    "5HX14A",
    "Y0F74A"
  ]

  if (isGB && scenario_2D_List.indexOf(printerModelNumber) !== -1) {
    return "scenario-2d"
  }

  if (carepackLogic.remainingDays < -1096 && carepackLogic.remainingDays > -1460) {
    if (in_Care_Pack) {
      cpScenario = "2c:Slide12"
    } else {
      cpScenario = "5:Slide15"
    }
  } else if (_EOSL && !refurbished) {
    if (commercial_printer) {
      cpScenario = "8:Slide21"
    } else {
      cpScenario = "5:Slide15"
    }
  } else if (refurbished) {
    cpScenario = "7:Slide20"
  } else if (in_Base_Warranty || in_Care_Pack) {
    if (!in_Care_Pack) {
      if (first_90_days || last_90_days) { 
        if (commercial_printer) {
          cpScenario = "6:Slide16"
        } else {
          cpScenario = "1:Slide8"
        }
      } else if (between_90_days) { 
        if (commercial_printer) {
          cpScenario = "6:Slide16"
        } else {
          cpScenario = "1b:Slide9"
        }
      } else {    
        if (commercial_printer) {
          cpScenario = "6d:Slide19"
        } else {
          if (carepackLogic.remainingDays < -1096) {
            cpScenario = "5:Slide15"
          } else {
            cpScenario = "4:Slide14"
          }
        }
      }
    } else {
      if (last_90_days_of_CarePack_warranty) {
        if (commercial_printer) {
          cpScenario = "6c:Slide18"
        } else {
          cpScenario = "2b:Slide11"
        }
      } else if (is_within_start_date_and_end_date_of_CarePack_warranty) {
        if (consumer_printer) {
          cpScenario = "2:Slide10"
        } else if (commercial_printer) {
          cpScenario = "6b:Slide17"
        }
      } else {
        if (commercial_printer) {
          cpScenario = "6d:Slide19"
        } else {
          cpScenario = "2c:Slide12"
        }
      }

    }
  }



  let scenarioDictionary = {
    "1:Slide8": "scenario-1",
    "1b:Slide9": "scenario-1b",
    "2:Slide10": "scenario-2",
    "2b:Slide11": "scenario-2b",
    "2c:Slide12": "scenario-2c",
    "4:Slide14": "scenario-4",
    "5:Slide15": "scenario-5",
    "6:Slide16": "scenario-6",
    "6b:Slide17": "scenario-6b",
    "6c:Slide18": "scenario-6c",
    "6d:Slide19": "scenario-6d",
    "7:Slide20": "scenario-7",
    "8:Slide21": "scenario-8",
  }
  let scenario = scenarioDictionary[cpScenario]

  return scenario
}