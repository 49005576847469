const HPID_USER_AUTH_SERVICE = 'hpid';

export default class Config {
  constructor() {
    this._appConfigUrl = '/AppConfig.php';
    this._configs = {
      HPC_GATEWAY_URL2: _hpc3_base_config.hpc3_gateway_url2,
      HPC_SUPPORT_URL: _hpc3_base_config.hpc3_support_url,
      HPID_URL: _hpc3_base_config.hpid_url,
      HPID_USER_PORTAL: _hpc3_base_config.hpid_user_portal,
      HPID_CLIENT_ID: _hpc3_base_config.hpid_client_id,
      HPID_SCOPE: 'openid+offline_access+user.profile.write+profile+email',
      USER_AUTH_SERVICE: _hpc3_base_config.user_auth_service,
      GOOGLE_ANALYTICS_ID: _hpc3_base_config.google_analytics_id,
      II_BASE_URL: _hpc3_base_config.instantink_base_url,
      CURRENT_STACK: _hpc3_base_config.current_stack,
      WEBAUTH_CLIENT_ID: 'WQ0H4zIja3mPfRzMlYBFNciLXKw6qX6J',
      UCDE_GATEWAY_URL2: _hpc3_base_config.ucde_gateway_url2,
      HP_PLUS_SECOND_OFFER_MANIFEST_URL: _hpc3_base_config.hp_plus_second_offer_manifest_url,
      TONER_REPLACE_PURPOSE_ID: _hpc3_base_config.toner_recall_purpose_id,
      SETUP_SERVER_HP_OOBE: _hpc3_base_config.setup_server_hp_oobe,
      DOWNLOAD_SOFTWARE_HP_OOBE: _hpc3_base_config.download_software_hp_oobe,
      CONTENT_STACK_API_KEY: _hpc3_base_config.content_stack_api_key,
      CONTENT_STACK_ENV: _hpc3_base_config.contentstack_environment,
      CONTENT_STACK_ACCESS_TOKEN: _hpc3_base_config.content_stack_access_token,
      BRANCH_IO_REDIRECT_BASE_URL: _hpc3_base_config.branch_io_redirect_base_url,
      LAUNCH_DARKLY_CLIENT_ID: _hpc3_base_config.launch_darkly_client_id,
      ORIGAMI_ENROLL_IN_INSTANT_INK_URL: _hpc3_base_config.origami_enroll_in_instant_ink_url,
      ORIGAMI_ADD_PAPER_TO_PLAN_URL: _hpc3_base_config.origami_add_paper_to_plan_url,
      AUTHZ_OPENID_URL: _hpc3_base_config.authz_openid_url,
      AUTHZ_OPENID_REDIRECT_URI2: _hpc3_base_config.authz_openid_redirect_uri2,
      STRATUS_CLIENT_ID: _hpc3_base_config.stratus_client_id,
      STRATUS_SCOPE: _hpc3_base_config.stratus_scope,
      CS_PIPL_CONSENT_ENTRY_ID: _hpc3_base_config.cs_pipl_consent_entry_id,
      AUTHZ_OPENID_LOGIN: '',
      AUTHZ_OPENID_CREATE: '',
      RESPONSE_TYPE: 'code',
      STATE: '',
      THEME: 'RWD',
      OVERLAY: false,
      FORCE_LOGIN: false,
      COUNTRY_CHANGEABLE: true,
      ENABLE_DEMO: false,
      ENVIRONMENT: 'local',
      CREATE_ACCOUNT_URL: '',
      AUTHZ_ONBOARDING_BASE_URL: _hpc3_base_config.authz_onboarding_base_url,
      STRATUS_BASE_URL: _hpc3_base_config.stratus_base_url,
      SESSION_SERVICE_BASE_URL2: _hpc3_base_config.session_service_base_url2,
      LOGIN_URL: '',
      LOGOUT_URL: '',
      SETTINGS_URL: '',
      BACKEND_HOST: '',
      LOGGER_ENABLED: false,
      SEARCH_URL: '',
      SEARCH_FILTER: '&filter=-1',
      SEARCH: '/search?q=',
      FEATURE_FLAGS: {},
      STACK_SPECIFIC_IDENTIFIER: _hpc3_base_config.oneTrustId,
      SUPPORT_URL_DICTIONARY: _hpc3_base_config.support_url_dictionary,
      API_HOST_HELPER: (_hpc3_base_config.api_host_helper) ? JSON.parse(_hpc3_base_config.api_host_helper) : {},
      HPSMART_RUM_TOKEN: _hpc3_base_config.hpsmart_rum_token,
      BOT_CLIENT: _hpc3_base_config.bot_client,
      BOT_SUB_CLIENT: _hpc3_base_config.bot_sub_client,
      SETUP_SERVER_HP_OOBE_WINDOWS: _hpc3_base_config.setup_server_hp_oobe_windows,
      SETUP_SERVER_HP_OOBE_MAC: _hpc3_base_config.setup_server_hp_oobe_mac,
      HP_123_PATH: 'https://www.123.hp.com/'
    };

    // Multi host changes pick hostname and port from browser url
    this.hostname = window.location.hostname;
    this.port = window.location.port;

    const UPDATED_AUTHZ_OPENID_REDIRECT_URL = new URL(this._configs.AUTHZ_OPENID_REDIRECT_URI2);
    const UPDATED_GATEWAY_URL = new URL(this._configs.HPC_GATEWAY_URL2);
    const UPDATED_SESSION_URL = new URL(this._configs.SESSION_SERVICE_BASE_URL2);
    const UPDATED_UCDE_GATEWAY_URL = new URL(this._configs.UCDE_GATEWAY_URL2);

    UPDATED_AUTHZ_OPENID_REDIRECT_URL.hostname = this._configs.API_HOST_HELPER[this.hostname];
    UPDATED_GATEWAY_URL.hostname = this._configs.API_HOST_HELPER[this.hostname];
    UPDATED_SESSION_URL.hostname = this._configs.API_HOST_HELPER[this.hostname];
    UPDATED_UCDE_GATEWAY_URL.hostname = this._configs.API_HOST_HELPER[this.hostname];

    if (this.port) {
      UPDATED_AUTHZ_OPENID_REDIRECT_URL.port = this.port;
      UPDATED_GATEWAY_URL.port = this.port;
      UPDATED_SESSION_URL.port = this.port;
      UPDATED_UCDE_GATEWAY_URL.port = this.port;
    }

    this._configs.AUTHZ_OPENID_REDIRECT_URI = UPDATED_AUTHZ_OPENID_REDIRECT_URL.toString();
    this._configs.HPC_GATEWAY_URL = UPDATED_GATEWAY_URL.toString();
    this._configs.SESSION_SERVICE_BASE_URL = UPDATED_SESSION_URL.toString();
    this._configs.UCDE_GATEWAY_URL = UPDATED_UCDE_GATEWAY_URL.toString();

    for (const key in _hpc3_base_config) {
      if (key.indexOf('ff_') === 0) {
        this._configs.FEATURE_FLAGS[key] = _hpc3_base_config[key];
      }
    }

    window._hpc3_config_store = this._configs;
  }

  GetFeatureFlag(featureFlag) {
    if (!featureFlag || !this._configs.FEATURE_FLAGS.hasOwnProperty(featureFlag)) {
      console.warn('Requested unknown feature flag: ', featureFlag);
      return '';
    }
    return this._configs.FEATURE_FLAGS[featureFlag];
  }

  Get(configInput) {
    if (!configInput || configInput === '' || !this._configs.hasOwnProperty(configInput)) {
      console.warn('Requested unknown config value: ', configInput);
      return '';
    }
    switch (configInput) {
      case 'CREATE_ACCOUNT_URL':
        if (this._configs.USER_AUTH_SERVICE === HPID_USER_AUTH_SERVICE) {
          return `${this._configs.HPID_URL}/directory/v1/oauth/authorize?response_type=code&client_id=${this._configs.HPID_CLIENT_ID}&redirect_uri=${this._configs.HPC_GATEWAY_URL}/hpid&scope=${this._configs.HPID_SCOPE}&state=create&prompt=consent&target=create`;
        }
        return `${this._configs.HPC_WEBAUTH_URL}/oauth/ui/signUp?client_id=${this._configs.WEBAUTH_CLIENT_ID}&response_type=${this._configs.RESPONSE_TYPE}&theme=${this._configs.THEME}&overlay=${this._configs.OVERLAY}&forceLogin=${this._configs.FORCE_LOGIN}&country_changeable=${this._configs.COUNTRY_CHANGEABLE}&redirect_uri=${this._configs.HPC_GATEWAY_URL}/webauth`;
      case 'LOGIN_URL':
        if (this._configs.USER_AUTH_SERVICE === HPID_USER_AUTH_SERVICE) {
          return `${this._configs.HPID_URL}/directory/v1/oauth/authorize?response_type=code&client_id=${this._configs.HPID_CLIENT_ID}&redirect_uri=${this._configs.HPC_GATEWAY_URL}/hpid&scope=${this._configs.HPID_SCOPE}&state=login&prompt=consent`;
        }
        return `${this._configs.HPC_WEBAUTH_URL}/oauth/ui/login?client_id=${this._configs.WEBAUTH_CLIENT_ID}&response_type=${this._configs.RESPONSE_TYPE}&theme=${this._configs.THEME}&overlay=${this._configs.OVERLAY}&forceLogin=${this._configs.FORCE_LOGIN}&country_changeable=${this._configs.COUNTRY_CHANGEABLE}&prompt=consent&redirect_uri=${this._configs.HPC_GATEWAY_URL}/webauth`;
      case 'LOGOUT_URL':
        return `${this._configs.HPID_URL}/directory/v1/oauth/logout?post_logout_redirect_uri=${this._configs.HPC_GATEWAY_URL}/hpid&state=logout`;
      case 'SETTINGS_URL':
        if (this._configs.USER_AUTH_SERVICE === HPID_USER_AUTH_SERVICE) {
          return this._configs.HPID_USER_PORTAL;
        }
        return `${this._configs.HPC_WEBAUTH_URL}/oauth/ui/accountSetting?client_id=${this._configs.WEBAUTH_CLIENT_ID}&theme=${this._configs.THEME}&redirect_uri=${this._configs.HPC_GATEWAY_URL}/webauth&target=_parent&state=logout`;
      case 'SEARCH_URL':
        return this._configs.HPC_SUPPORT_URL;
      case 'II_BASE_URL':
        return this._configs.II_BASE_URL;
      case 'CURRENT_STACK':
        return this._configs.CURRENT_STACK;
      case 'AUTHZ_OPENID_LOGIN':
        return (
          `${this._configs.AUTHZ_OPENID_URL}/openid/v1/authorize?client_id=${this._configs.STRATUS_CLIENT_ID}` +
          `&response_type=${this._configs.RESPONSE_TYPE}&scope=${this._configs.STRATUS_SCOPE}` +
          `&redirect_uri=${this._configs.AUTHZ_OPENID_REDIRECT_URI}&state=`
        );
      case 'AUTHZ_OPENID_CREATE':
        return (
          `${this._configs.AUTHZ_OPENID_URL}/openid/v1/authorize?client_id=${this._configs.STRATUS_CLIENT_ID}` +
          `&response_type=${this._configs.RESPONSE_TYPE}&scope=${this._configs.STRATUS_SCOPE}` +
          `&redirect_uri=${this._configs.AUTHZ_OPENID_REDIRECT_URI}&target=create&state=`
        );
      case 'SESSION_SERVICE_BASE_URL':
        return this._configs.SESSION_SERVICE_BASE_URL;
      case 'AUTHZ_ONBOARDING_BASE_URL':
        return this._configs.AUTHZ_ONBOARDING_BASE_URL;
      case 'STRATUS_BASE_URL':
        return this._configs.STRATUS_BASE_URL;
      case 'SUPPORT_URL_DICTIONARY':
        return JSON.parse(this._configs.SUPPORT_URL_DICTIONARY);
      default:
        return this._configs[configInput];
    }
  }
}

window._hpc3_config_store = null;
