import { takeEvery, put } from 'redux-saga/effects';
import {
  keys,
  prop,
  propOr,
} from 'ramda';
import {
  failureToastNotification,
} from '../actions/application';
import ActionTypes from '../constants/ActionTypes';

const type = prop('type');

function* callFailure() {
  yield put(failureToastNotification('Something went wrong, please try again. If you have tried again and are seeing this message, try refreshing your browser first.'));
}

const failureDictionary = {
  [ActionTypes.CALL_FAILED]: callFailure,
};

function handleFailure(action) {
  const handler = propOr(prop(type(action), failureDictionary), action.toString(), failureDictionary);
  return handler(action);
}

export default function* watchForFailures() {
  yield takeEvery(keys(failureDictionary), handleFailure);
}
