import styled from 'styled-components';


export const PsTelemetryDiv = styled.div`
 &&&& { 
  * {
    font-family: 'Forma DJR', sans-serif;
  }
 }
`;

export const StyledContainer = styled.div`
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  a {
    color: #0171ad;
  }
  em {
    font-style: normal;
    text-decoration: underline;
  }
  ol {
    margin-bottom: 1rem;
  }
  ol.letters {
    list-style-type: lower-latin;
    margin-left: 0;
    ${(props) => (props.isRTL ? 'margin-right: 2.5rem' : 'margin-left: 2.5rem')};
  }
  ol.roman {
    list-style-type: lower-roman;
    margin-left: 0;
    ${(props) => (props.isRTL ? 'margin-right: 2.5rem' : 'margin-left: 2.5rem')};
  }
  ul {
    margin-left: 0;
    ${(props) => (props.isRTL ? 'margin-right: 2.5rem' : 'margin-left: 2.5rem')};
    margin-bottom: 1rem;
  }
  small {
    ${(props) => (props.isRTL ? 'margin-right: 2.5rem' : 'margin-left: 2.5rem')};
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
  }
  table td, table th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  td {
    display: table-cell;
    vertical-align: inherit;
  }
`;

export const StyledContent = styled.div`
  margin: 1.25rem;
  @media screen and (min-width: 48rem) {
    margin: 1.875rem;
  }
`;

export const StyledTitle = styled.h2`
  text-align: center;
  @media screen and (max-width: 32rem) {
    font-size: 1.65rem;
    margin-top: 60px;
  }
  font-size: 2rem;
`;
