import React from 'react';
import {
  AdvancedScanContainer,
  AdvancedScanContent,
  ImageTile,
  ContentTile,
  ScanFeature
} from './styles';
import { RTL_LANGUAGES } from '../../../constants/i18n';

const AdvancedScan = (props) => {
  const { featureDetails } = props;
  const advancedScanFeatureData = featureDetails[0]?.details;
  const multiItemRecognitionData = featureDetails[1]?.detail_description;
  const textExtractData = featureDetails[2]?.detail_description;
  const bookScanningData = featureDetails[3]?.detail_description;
  const flattenPagesData = featureDetails[4]?.detail_description;
  const autoHealData = featureDetails[5]?.detail_description;
  const language = window.location.pathname.split('/')[2];
  const isRTLSupport = RTL_LANGUAGES.includes(language);

  return (
    <AdvancedScanContainer id={'advanced-scan'}>
      <AdvancedScanContent>
        <div className="image-content">
          <ImageTile isRTLSupport={isRTLSupport}>
            <img
              alt={advancedScanFeatureData.mobile.title}
              src={advancedScanFeatureData.mobile.url}
              className="feature-image feature-image_375"
            />
            <img
              alt={advancedScanFeatureData.tablet.title}
              src={advancedScanFeatureData.tablet.url}
              className="feature-image feature-image_540"
            />
            <img
              alt={advancedScanFeatureData.widescreen.title}
              src={advancedScanFeatureData.widescreen.url}
              className="feature-image feature-image_720"
            />
            <img
              alt={advancedScanFeatureData.extrawide.title}
              src={advancedScanFeatureData.extrawide.url}
              className="feature-image feature-image_1280"
            />
          </ImageTile>
          <ContentTile>
            <div className="title">{advancedScanFeatureData.section_title}</div>
            <div className="description">{advancedScanFeatureData.section_heading}</div>
            <div className="text-content">{advancedScanFeatureData.section_body}</div>
            <div className="scan-features">
              <ScanFeature>
                <img
                  alt={multiItemRecognitionData.detail_icon.title}
                  src={multiItemRecognitionData.detail_icon.url}
                  className="feature-image feature-logo"
                />
                <div>
                  <div className="feature-title">{multiItemRecognitionData.detail_heading}</div>
                  <div className="feature-description">{multiItemRecognitionData.detail_body}</div>
                </div>
              </ScanFeature>

              <ScanFeature>
                <img
                  alt={textExtractData.detail_icon.title}
                  src={textExtractData.detail_icon.url}
                  className="feature-image feature-logo"
                />
                <div>
                  <div className="feature-title">{textExtractData.detail_heading}</div>
                  <div className="feature-description">{textExtractData.detail_body}</div>
                </div>
              </ScanFeature>

              <ScanFeature>
                <img
                  alt={bookScanningData.detail_icon.title}
                  src={bookScanningData.detail_icon.url}
                  className="feature-image feature-logo"
                />
                <div>
                  <div className="feature-title">{bookScanningData.detail_heading}</div>
                  <div className="feature-description">{bookScanningData.detail_body}</div>
                </div>
              </ScanFeature>

              <ScanFeature>
                <img
                  alt={flattenPagesData.detail_icon.title}
                  src={flattenPagesData.detail_icon.url}
                  className="feature-image feature-logo"
                />
                <div>
                  <div className="feature-title">{flattenPagesData.detail_heading}</div>
                  <div className="feature-description">{flattenPagesData.detail_body}</div>
                </div>
              </ScanFeature>

              <ScanFeature className="last-feature">
                <img
                  alt={autoHealData.detail_icon.title}
                  src={autoHealData.detail_icon.url}
                  className="feature-image feature-logo"
                />
                <div>
                  <div className="feature-title">{autoHealData.detail_heading}</div>
                  <div className="feature-description">{autoHealData.detail_body}</div>
                </div>
              </ScanFeature>
            </div>
          </ContentTile>
        </div>
      </AdvancedScanContent>
    </AdvancedScanContainer>
  );
};

export default AdvancedScan;
