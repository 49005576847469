import styled from 'styled-components';

export const Content = styled.div`
  padding-bottom: 70px;
`;

export const ListContainer = styled.div`
  border-radius: 12px;
  box-shadow: 0 4px 16px #2121211A;
  flex-basis: 100%;
  margin-top: 6px;
  background-color: #FFFFFF;

  p {
    font-family: ${props => props.theme.main.hp.fontFamily};
    color: ${props => props.theme.main.hp.fontColor};
  }
`;

export const BenefitsListHeader = styled.section`
  padding: 16px 0 16px 12px;
  border-bottom: 1px solid #EBEBEB;
  display: grid;
  grid-template-columns: 53px auto;
  grid-template-areas: 'logo' 'title';

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    grid-template-columns: 86px auto;
  }
`;

export const IconHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 39px;
    height: 30px;
  }

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    > img {
      width: 47px;
      height: 36px;
    }
  }
`;

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;

  p {
    font-size: 24px;
  }

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    padding-left: 6px;
  }
`;

export const ListFeaturesContainer = styled(ListContainer)`
  margin-top: 23px;
`;

export const OtherFeaturesHeader = styled.div`
  padding: 16px 0 16px 21px;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    padding-top: 7px;
    font-size: 24px;
  }
`;
