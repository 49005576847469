import React from 'react';
import styled from 'styled-components';
import {
  Button,
  IconChevronLeft,
  IconChevronRight,
} from '@veneer/core';
import {
  paths,
} from '../../../utils/styleHelpers';

const SlideButton = styled(Button)`
  && {
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
  }
`;

const CarouselButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 17rem;
  margin-bottom: 1rem;
  @media screen and (min-width: ${paths.breakTablet}) {
    margin-right: inherit;
    width: 8rem;
    ${props => !props.isRTLSupport && 'margin-right: 3.5rem'};
    ${props => props.isRTLSupport && 'margin-left : 2rem'};
    margin-bottom: 2.5rem;
  }
  @media (min-width: ${paths.breakImmense}) {
    ${props => !props.isRTLSupport && 'margin-right: calc(((100vw - 120rem) / 2) + 3rem)'};
    ${props => props.isRTLSupport && 'margin-left: calc(((100vw - 120rem) / 2) + 3rem)'};
  }
  @media (min-width: ${paths.breakImmensewide}) {
    ${props => !props.isRTLSupport && 'margin-right: calc(((100vw - 120rem) / 2) + 3rem)'};
    ${props => props.isRTLSupport && 'margin-left: calc(((100vw - 150rem) / 2) + 3rem)'};
  }
`;

const PagingControls = ({ nextSlide, previousSlide, isRTLSupport }) => (
  <CarouselButtons isRTLSupport={isRTLSupport}>
    <SlideButton
      appearance="primary"
      negative
      onClick={previousSlide}
      leadingIcon={<IconChevronLeft />}
      id="previous-slide-button"
    />
    <SlideButton
      appearance="primary"
      negative
      onClick={nextSlide}
      leadingIcon={<IconChevronRight />}
      id="next-slide-button"
    />
  </CarouselButtons>
);

export default PagingControls;
