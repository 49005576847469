import React from 'react';
import {
  Redirect,
} from 'react-router-dom';
import {
  isEmpty,
} from 'ramda';
import Media from 'react-media';
import {
  Header,
  Main,
  Page,
} from './page/styles';
import HelpCenterMain from './page/HelpCenterMain';
import HelpCenterMobile from './page/HelpCenterMobile';
import LoaderBlock from '../../../shared-components/molecules/LoaderBlock'
import { useSelector } from '../../../../hooks';
import { selectHelpCenterPageProps } from '../../store/modules/helpCenter/selector';

const HelpCenterPage = ({ location }) => {
  const props = useSelector(selectHelpCenterPageProps);
  if (isEmpty(props)) {
    return (
      <Page>
        <Main>
          <LoaderBlock />
        </Main>
      </Page>
    )
  }

  const {
    topicIsValid,
    title,
    topic,
    country,
    language,
  } = props;

  if (!topicIsValid) {
    return (
      <Redirect
        from={location.pathname}
        to={`/${country}/${language}/ucde/help/about-hp-smart`}
      />
    );
  }

  return (
    <Page>
      <Header narrow>
        <h1>
          {title}
        </h1>
      </Header>
      <Main>
        <Media query={{ minWidth: 1200 }}>
          {
            matches => (
              matches
                ? <HelpCenterMain key={topic} />
                : <HelpCenterMobile key={topic} />
            )
          }
        </Media>
      </Main>
    </Page>
  );
};

export default HelpCenterPage;
