import React from 'react';

export default function BlueCheckmark(){
    return (
    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <g fill="none" fillRule="evenodd">
            <circle className="checkmark--circle" cx="13" cy="13" r="11" fill="#0096D6" />
            <polyline className="checkmark--check" points="10 17 16 17 16 5" transform="rotate(42 13 11)" />
        </g>
    </svg>);
}