import React from 'react';
import PropTypes from 'prop-types';
import UserItem from './userItem';
import {
  ActionsHeader,
  AvatarHeader,
  UserHeader,
  EmailHeader,
  StatusHeader,
  RoleHeader,
  UserListHeaders,
  UserListContainer,
} from './styles';
import i18n from '../../../utils/i18n';

const UserList = ({ users, onDelete, onError }) => {
  const headers = {
    user: i18n.t('manageUsers.tableHeaders.header1'),
    email: i18n.t('manageUsers.tableHeaders.header2'),
    status: i18n.t('manageUsers.tableHeaders.header3'),
    role: i18n.t('manageUsers.tableHeaders.header4'),
  };

  const usersList = users.map(({
    resourceId, fullName, email, state, color, ownerTenant,
  }) => (
    <UserItem
      resourceId={resourceId}
      key={email}
      fullName={fullName}
      email={email}
      owner={ownerTenant}
      state={state}
      color={color}
      onDelete={onDelete}
      onError={onError}
      headers={headers}
    />
  ));

  return (
    <UserListContainer>
      <UserListHeaders>
        <AvatarHeader />
        <UserHeader>{headers.user}</UserHeader>
        <EmailHeader>{headers.email}</EmailHeader>
        <RoleHeader>{headers.role}</RoleHeader>
        <StatusHeader>{headers.status}</StatusHeader>
        <ActionsHeader />
      </UserListHeaders>
      {usersList}
    </UserListContainer>
  );
};

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      resourceId: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default UserList;
