import React from 'react';
import { useSelector } from '../../../hooks';
import { selectGetInkProps } from '../../../selectors/products';
import { TemplateSection } from '../../shared-components/shared-styles/TemplateStyles';
import SectionHeadIcon from '../../shared-components/molecules/sectionHeadIcon';
import PurchaseOptions from './PurchaseOptions';

const GetInk = () => {
  const props = useSelector(selectGetInkProps);
  if (!props) {
    return null;
  }

  const {
    inkSectionTitle,
    inkSectionIconUrl,
    inkSectionIconTitle,
    cartridgeSelector,
    countryCode,
  } = props;

  const cartridgeSelectorData = Array.isArray(cartridgeSelector) ? [...cartridgeSelector] : [cartridgeSelector]

  return (
    <TemplateSection id="get-ink">
      <SectionHeadIcon
        sectionHeading={inkSectionTitle}
        sectionIcon={inkSectionIconUrl}
        sectionIconAlt={inkSectionIconTitle}
      />
      <div className="cartridges">
        {
          cartridgeSelectorData.map(ink => (
            ink.countryFilterSelector
            && ink.cartridge
            && Object.values(ink.countryFilterSelector[0].countryFilter).map(country => (country.value)).includes(countryCode)
            && (
              <div key={`${ink.cartridge[0].cartridgeDescription}${ink.cartridge[0].cartridgeGraphicTitle}`} className="cartridge">
                <span className="cartridge-number">
                  <img src={ink.cartridge[0].cartridgeGraphicUrl} alt={ink.cartridge[0].cartridgeGraphicTitle} />
                </span>
                <p>{ink.cartridge[0].cartridgeDescription}</p>
              </div>
            )
          ))
        }
      </div>
      <div className="purchaseOptions">
        <PurchaseOptions />
      </div>
    </TemplateSection>
  );
};

export default GetInk;
