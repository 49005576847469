import React, { useState } from 'react';
import {
  identity,
} from 'ramda';
import styled from 'styled-components';
import classnames from 'classnames';

const NewAccordion = props => {
  const [expanded, setExpanded] = useState(false);
  const {
    platform,
    preamble,
    children,
    title,
    noCollapse,
  } = props;

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <AccordionContainer platform={platform}>
      <Heading
        platform={platform}
        onClick={noCollapse ? identity : toggleExpanded}
        noCollapse={noCollapse}
      >
        {
          !noCollapse && (
            <Caret
              className={classnames({ expanded })}
              src="/assets/img/open_closed_arrow.svg"
            />
          )
        }
        <h4>
          {title}
        </h4>
      </Heading>
      <Body
        className={classnames({ expanded: noCollapse || expanded })}
        noCollapse={noCollapse}
      >
        {children}
      </Body>
      {
        preamble && (
          <Preamble
            platform={platform}
            className={classnames({ expanded: noCollapse || !expanded })}
            noCollapse={noCollapse}
          >
            {preamble}
          </Preamble>
        )
      }
    </AccordionContainer>
  );
};

export default NewAccordion;

const Body = styled.div`
  width: 100%;
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: 
    max-height 0.4s ease-out,
    visibility 0.5s ease-out;
  &.expanded {
    max-height: 900px;
    visibility: inherit;
  }
  order: ${({ noCollapse }) => (noCollapse ? '3' : '2')};
  .accordionContentInk, .accordionContentCarePack {
    background-color: white;
  }
  `;
const Preamble = styled.div`
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  padding: 0 1rem;
  transition: 
    max-height 0.4s ease-out,
    visibility 0.5s ease-out,
    margin-bottom 0.2s ease-out;
  &.expanded {
    max-height: 200px;
    visibility: inherit;
    padding: ${props => (props.platform === 'desktop' ? '0 4.5rem 1.4rem' : '0 1rem 1.4rem')};    
    @media (max-width: 340px) {
      margin: 0 0.5rem 0.75rem;
    }
  }
  order: ${({ noCollapse }) => (noCollapse ? '2' : '3')};
`;

const AccordionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: ${props => (props.platform === 'desktop' ? '0' : '1.25rem')};
  border-radius: 1.25rem;
  ${props => (props.platform === 'desktop' ? '' : 'box-shadow: 0px 0px 1.25rem #eee;')}

  flex: 1 1 auto;
  @media (min-width: 768px) {
    flex: 1 1 50%;
  }
  @media (max-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
  }
  overflow: hidden;
`;

const Heading = styled.span`
  && {
    display: flex;
    width: 100%;
    padding: 1rem;
    transition: background-color 0.3s ease;
    color: ${props => (props.theme.textColor ? props.theme.textColor : 'red')};
    &:hover {
      text-decoration: none;
    }
    overflow: hidden;
    align-items: center;
    padding: ${props => (props.platform === 'desktop' ? '1rem 4.5rem' : '1rem')};
    order: 1;
    @media (max-width: 340px) {
      padding: .5rem;
    }
    h4 {
      width: 100%;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0;
      margin-top: 0;
      > * {
        font-weight: bold;
      }
    }
  }
`;

const Caret = styled.img`
  margin-right: 1rem;
  width: .75rem;
  height: .75rem;
  transform: rotateX(0deg);
  &.expanded {
    transform: rotateX(180deg);
  }

  transition: transform 0.5s linear;

`;
