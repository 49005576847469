import React from 'react';
import SubsectionHead from '../shared-components/molecules/SubsectionHead';
import Carousel from 'nuka-carousel';
import SliderPanel from '../shared-components/molecules/SliderPanel';
import PagingDots from '../shared-components/molecules/PagingDots';
import { TemplateSection } from '../shared-components/shared-styles/TemplateStyles';
import HollowButton from '../shared-components/atoms/hollowButton';
import { ArrowButtonLeft, ArrowButtonRight } from '../shared-components/atoms/ArrowButtons';
import styled from 'styled-components';
import { sliderPanelTracking } from '../../lib/analyticsTracking';


class GetReadyToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.slideData = [];
    this.state = {
      slideIndex: 0
    }
    if (props.device) {
      const { preliminarySliderPanels } = props.device;
      this.slideData = preliminarySliderPanels.map(panel => {
        return panel.preliminaryPanelGroup.value;
      })
    }
    this.groupButtonWasClicked = false
    this.panelTracking = this.panelTracking.bind(this)
    this.analyticsKey = []
  }

  componentDidUpdate(prevProps, prevState) {
    let erroneous_nuka_element = document.getElementById("omit_parent_2").parentNode
    if (!!erroneous_nuka_element) {
      erroneous_nuka_element.style.display="none"
    }
    const { device } = this.props;
    if (!device && this.analyticsKey.length === 0) {
      const { preliminarySliderPanels } = device;
      for (let i = 0; i < preliminarySliderPanels.length; i++) {
        this.analyticsKey[i] = preliminarySliderPanels[i].preliminaryPanelTitle;
      }
    }
    if (this.state.slideIndex !== prevState.slideIndex) {
      this.panelTracking()
    }
  }

  panelTracking() {
    sliderPanelTracking(`Tango: Get Ready To Print: slide(${this.state.slideIndex}): ` + this.analyticsKey[this.state.slideIndex])
  }

  handleButtonClick = (button) => {
    let index_found = this.slideData.findIndex(preliminaryGroup => preliminaryGroup === button.preliminaryButtonAssociation)
    this.groupButtonWasClicked = true
    this.setState({ slideIndex: index_found })
  }

  handleStateChange = (preSlide, slideIndex) => {
    if (this.groupButtonWasClicked) {
      this.groupButtonWasClicked = false
    } else {
      this.setState({ slideIndex })
    }
  }

  render() {
    const { device } = this.props;
    if (!device) {
      return null;
    }

    const {
      preliminarySectionTitle,
      preliminarySectionButtons,
      preliminarySliderPanels,
    } = device;

    return (
      <TemplateSection id="get-ready">
        <SubsectionHead text={preliminarySectionTitle} />
        <ButtonGroup>
          {preliminarySectionButtons.map(button => (
            <HollowButton
              buttonID={`${button.preliminaryButtonAssociation}Button`}
              key={button.id}
              buttonText={button.preliminaryButtonText}
              classes={this.slideData[this.state.slideIndex] === button.preliminaryButtonAssociation ? `${button.preliminaryButtonAssociation} current` : `${button.preliminaryButtonAssociation}`}
              targetSlideIndex={this.slideData.findIndex(preliminaryGroup => preliminaryGroup === button.preliminaryButtonAssociation)}
              onClick={() => this.handleButtonClick(button)}
            />
          ))}
        </ButtonGroup>

        <div dir="ltr" >
          <Carousel
            id="preliminaryCarousel"
            renderAnnounceSlideMessage={()=><span id="omit_parent_2"></span>}   
            slideIndex={this.state.slideIndex}
            beforeSlide={this.handleStateChange}
            renderCenterLeftControls={({ previousSlide, currentSlide }) => (
              <ArrowButtonLeft id="preliminaryCarouselLeftArrow" disabled={currentSlide === 0} onClick={previousSlide}>&lt;</ArrowButtonLeft>
            )}
            renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => (
              <ArrowButtonRight id="preliminaryCarouselRightArrow" disabled={currentSlide == (slideCount - 1)} onClick={nextSlide}>&gt;</ArrowButtonRight>
            )}
            renderBottomCenterControls={props => <PagingDots prefix="getReady" {...props} />}
          >
            {
              preliminarySliderPanels.map(panel => (
                <SliderPanel
                  key={panel.id}
                  animationSrc={panel.preliminaryPanelAnimationUrl}
                  header={panel.preliminaryPanelTitle}
                  desc={panel.preliminaryPanelDescription}
                  lightingGroup={panel.preliminaryPanelGroup.value}
                />
              ))
            }
          </Carousel>
        </div>

      </TemplateSection>
    )
  }
}

export default GetReadyToPrint;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  max-width: 40rem;
  margin: 0 auto 1rem;
  @media (min-width: 40rem) {
    margin-bottom: 2rem;
  }
  a.trayGroup, a.paperGroup, a.inkGroup {
    width: 33.333%;
    font-size: .875rem;
    padding-left: .5rem;
    padding-right: .5rem;
    margin-right: .5rem;
    margin-left: .5rem;
    @media (min-width: 30rem) {
      padding-left: 1.375em;
      padding-right: 1.375em;
      margin-bottom: 1.25rem;
    }
    border-color: #0171AD;
    color: #0171AD;
    &:focus {
      border-color: #0171AD;
      color: #0171AD;
    }
    &:hover {
      border-color: #0171AD;
      background-color: #0171AD;
      color: white;
    }
    &.current {
      background-color: #0171AD;
      color: white;
      &:hover, &:active, &:focus {
        border-color: #0171AD;
        background-color: #0171AD;
        color: white;
      }
    }
  }
  * + * {
    margin-left: 0.5rem;
    @media (min-width: 30rem) {
      margin-left: 1.25rem;
    }
  }
`
