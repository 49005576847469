import React from 'react';
import styled from 'styled-components';

import {
  paths,
  fontPacks,
} from '../../../utils/styleHelpers';

const CardContainer = styled.div`
  color: ${paths.colorsWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: calc(100vh - 68px - 3.5rem);
  text-align: center;
  @media (min-width: ${paths.breakMobile}) {
    height: calc(100vh - 68px - 5rem);
  }
  @media (min-width: ${paths.breakWide}) {
    width: 100%;
  }
`;

const Headline = styled.h2`
  color: ${paths.colorsWhite};
  ${fontPacks.titleRegularLight}
`;

const Subhead = styled.h3`
  color: ${paths.colorsWhite};
  ${fontPacks.subtitleLarge}
`;

const Quote = styled.span`
  color: ${paths.colorsWhite};
  ${fontPacks.titleRegularLight}
  > p {
    ${fontPacks.titleRegularLight}
    color: ${paths.colorsWhite};
  }
  @media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 85%;
    margin-top: 3.125rem;
  }
`;

const MiddleIcon = styled.img`
  width: 4rem;
  height: auto;
  @media (min-width: ${paths.breakMobile}) {
    width: 4.75rem;
  }
  @media (min-width: ${paths.breakTablet}) and (max-resolution: 1dppx) {
    width: 7rem;
  }
`;

const Author = styled.span`
  color: ${paths.colorsWhite};
  height: auto;
  ::before {
    display: block;
    content: "";
    border-top: 1px solid ${paths.colorsInactiveGrey};
    height: 1px;
    width: 3rem;
    position: relative;
    top: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: ${paths.breakExtraSmall}) {
    :: before {
      top: -1rem;
    }
  }
  @media (min-width: ${paths.breakTablet}) {
    :: before {
      top: -2rem;
    }
  }
  @media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    :: before {
      top: 0;
    }
  }
  ${fontPacks.overlineLarge}
`;

const MainImg = styled.img`
  max-height: 8rem;
  width: auto;
  @media (min-width: ${paths.breakTablet}) and (max-resolution: 1dppx) {
    max-height: 12rem;
    max-width: calc(100% - 0.5rem);
  }
  @media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    margin-bottom: 3rem;
  }
`;

const TestimonialCard = props => {
  const {
    testimonial,
  } = props;

  const {
    headline,
    quote,
    author,
    subhead,
    graphic,
    icon,
  } = testimonial;

  const {
    title: graphicTitle,
    url: graphicUrl,
  } = graphic || {};

  return (
    <CardContainer>
      { graphicUrl && (
      <MainImg
        src={graphicUrl}
        alt={graphicTitle}
      />)
      }
      {
        headline && (
          <Headline>
            {headline}
          </Headline>
        )
      }
      {
        subhead && (
          <Subhead dangerouslySetInnerHTML={{ __html: subhead }} />
        )
      }
      {
        icon && (
          <MiddleIcon
            src={icon.url}
            alt={icon.title}
          />
        )
      }

      <Quote dangerouslySetInnerHTML={{ __html: quote }} />

      {
        author && (
          <Author dangerouslySetInnerHTML={{ __html: author }} />
        )
      }
    </CardContainer>
  );
};

export default TestimonialCard;
