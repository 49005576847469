import { path, isEmpty } from 'ramda';
import { createSelector } from 'reselect';

const selectCurrentStack = createSelector(
  path(['config', 'data']),
  configClass => {
    if (isEmpty(configClass)) {
      return null;
    }
    return configClass.Get('CURRENT_STACK');
  },
);

export default selectCurrentStack;
