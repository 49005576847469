import {
  path,
  identity,
} from 'ramda';
import { createSelector } from 'reselect';

const SELECTORS_BASE = {
  selectAsyncArray: path(['application', 'asyncArray']),
  selectToasts: path(['application', 'toasts']),
  selectJWebInfo: path(['application', 'jweb']),
};

export const selectSignalIsInSet = createSelector(
  SELECTORS_BASE.selectAsyncArray,
  (_, signal) => signal,
  (set, signal) => set.includes(signal),
);

export const selectToasts = createSelector(
  SELECTORS_BASE.selectToasts,
  identity,
);

export const selectJWebInfo = createSelector(
  SELECTORS_BASE.selectJWebInfo,
  identity,
);
