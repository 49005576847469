import {
  path,
  pathOr,
  identity,
} from 'ramda';
import base64url from 'base64url';
import { createSelector } from 'reselect';
import { selectOnboardingRouteProps } from './routing';
import { getCsrfState } from '../utils/globals';
import { selectOnboardingConfigProps } from './config';

const SELECTORS_BASE = {
  selectUserInfo: path(['userInfo', 'userInfoData']),
  selectUserCurrentLocale: pathOr('', ['userInfo', 'userInfoData', 'locale']),
  selectOnboardingStep: pathOr(null, ['userInfo', 'step']),
};

export const selectUserLoggedIn = createSelector(
  SELECTORS_BASE.selectUserInfo,
  userInfo => Boolean(userInfo),
);

export const selectUserCurrentLocale = createSelector(
  SELECTORS_BASE.selectUserCurrentLocale,
  identity,
);

export const selectUserInfoData = createSelector(
  SELECTORS_BASE.selectUserInfo,
  identity,
);

export const selectOnboardingProps = createSelector(
  selectOnboardingRouteProps,
  selectOnboardingConfigProps,
  getCsrfState,
  ({ encodedState, country, language }, { baseUrl, uiExtensionInfoId }, storedCsrfMatch) => {
    const decodedState = base64url.decode(encodedState);
    const parsedState = JSON.parse(decodedState || '{}');
    const csrf = parsedState.csrfToken;
    return ({
      country,
      language,
      baseUrl,
      uiExtensionInfoId,
      csrfMatch: csrf === storedCsrfMatch,
      redirectTo: parsedState.redirectTo,
      encodedState,
    });
  },
);
