import Types from './types';

const INITIAL_STATE = {};

export default function microfrontends(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ADD_MICROFRONTEND:
      return {
        ...state,
        [action.payload.name]: {
          scripts: action.payload.scripts,
        },
      };
    case Types.REMOVE_MICROFRONTEND:
      delete state[action.payload.name];
      return { ...state };
    default:
      return state;
  }
}
