import React from 'react';
import SubsectionHead from '../shared-components/molecules/SubsectionHead';
import Carousel from 'nuka-carousel';
import SliderPanel from '../shared-components/molecules/SliderPanel';
import PagingDots from '../shared-components/molecules/PagingDots';
import { TemplateSection } from '../shared-components/shared-styles/TemplateStyles';
import HollowButton from '../shared-components/atoms/hollowButton';
import { ArrowButtonLeft, ArrowButtonRight } from '../shared-components/atoms/ArrowButtons';
import styled from 'styled-components';
import { sliderPanelTracking } from '../../lib/analyticsTracking';


class EdgeLighting extends React.Component {
  constructor(props) {
    super(props);
    this.slideData = [];
    this.state = {
      slideIndex: 0
    }
    if (props.device) {
      const { displaySectionSliderPanels } = props.device;
      this.slideData = displaySectionSliderPanels.map(panel => {
        return panel.displaySectionPanelGroup.value;
      })
    }
    this.panelTracking = this.panelTracking.bind(this)
    this.analyticsKey = []
  }

  componentDidUpdate(prevProps, prevState) {
    const { device } = this.props;
    let erroneous_nuka_element = document.getElementById("omit_parent_3").parentNode
    if (!!erroneous_nuka_element) {
      erroneous_nuka_element.style.display="none"
    }
    if (device && this.analyticsKey.length === 0) {
      const { displaySectionSliderPanels } = device;
      for (let i = 0; i < displaySectionSliderPanels.length; i++) {
        this.analyticsKey[i] = displaySectionSliderPanels[i].displaySectionPanel
      }
    }
    if (this.state.slideIndex !== prevState.slideIndex) {
      this.panelTracking()
    }
  }

  panelTracking() {
    sliderPanelTracking(`Tango: Edge Lighting: slide(${this.state.slideIndex}): ` + this.analyticsKey[this.state.slideIndex])
  }

  handleButtonClick = (button) => {
    let index_found = this.slideData.findIndex(colorGroup => colorGroup === button.displaySectionButtonColor)
    this.setState({ slideIndex: index_found })
  }

  handleStateChange = (preSlide, slideIndex) => {

    this.setState({ slideIndex: slideIndex })
  }

  render() {
    const { device } = this.props;
    if (!device) {
      return null;
    }

    const {
      displaySectionTitle,
      displaySectionIntroduction,
      displaySectionCategories,
      displaySectionDirections,
      displaySectionButtons,
      displaySectionSliderPanels,
    } = device;

    return (
      <TemplateSection id="display">
        <SubsectionHead text={displaySectionTitle} />
        <StyledIntro>
          <div dangerouslySetInnerHTML={{ __html: displaySectionIntroduction }} />
          <StyledList>
            {displaySectionCategories.map(category => (
              <li key={category.id} id={`category-${category.id}`}>
                <img src={category.displaySectionCatImageUrl} alt={category.displaySectionCatImageTitle} />
                <div dangerouslySetInnerHTML={{ __html: category.displaySectionCatDescription }} />
              </li>
            ))}
          </StyledList>
          <div className="displayDirections" dangerouslySetInnerHTML={{ __html: displaySectionDirections }} />
        </StyledIntro>
        <ButtonPair>
          {displaySectionButtons.map(button => (
            <HollowButton
              buttonID={`${button.displaySectionButtonColor}Button`}
              key={button.id}
              buttonText={button.displaySectionButtonText}
              classes={this.slideData[this.state.slideIndex] === button.displaySectionButtonColor ? `${button.displaySectionButtonColor} current` : `${button.displaySectionButtonColor}`}
              targetSlideIndex={this.slideData.findIndex(colorGroup => colorGroup === button.displaySectionButtonColor)}
              onClick={() => this.handleButtonClick(button)}
            />
          ))}
        </ButtonPair>

        <div dir="ltr" >
          <Carousel
            id="displayCarousel"
            renderAnnounceSlideMessage={()=><span id="omit_parent_3"></span>}   
            slideIndex={this.state.slideIndex}
            beforeSlide={this.handleStateChange}
            renderCenterLeftControls={({ previousSlide, currentSlide }) => (
              <ArrowButtonLeft id="displayCarouselLeftArrow" disabled={currentSlide === 0} onClick={previousSlide}>&lt;</ArrowButtonLeft>
            )}
            renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => (
              <ArrowButtonRight id="displayCarouselRightArrow" disabled={currentSlide == (slideCount - 1)} onClick={nextSlide}>&gt;</ArrowButtonRight>
            )}
            renderBottomCenterControls={props => <PagingDots prefix="edge" {...props} />}
          >
            {
              displaySectionSliderPanels.map(panel => (
                <SliderPanel
                  key={panel.id}
                  animationSrc={panel.displaySectionPanelAnimationUrl}
                  header={panel.displaySectionPanel}
                  desc={panel.displaySectionPanelDescription}
                  lightingGroup={panel.displaySectionPanelGroup.value}
                />
              ))
            }
          </Carousel>
        </div>

      </TemplateSection>
    )
  }
}

export default EdgeLighting;

const StyledIntro = styled.div`
  margin-bottom: 1rem;
  @media (min-width: 640px) {
    margin-bottom: 3rem;
    .displayDirections {
      text-align: center;
    }
  }
`;
const StyledList = styled.ul`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0 0 1rem;
  > li {
    max-width: 50%;
    flex: 1 1 50%;
    @media (min-width: 640px) {
      max-width: 25%;
      flex: 1 1 25%;
    }
    font-size: 1rem;
    padding-bottom: .5rem;
    p {
      margin-bottom: 0;
    }
    ul {
      line-height: 1.25;
    }
    &:nth-child(1) {
      padding-right: .625rem;
    }
    &:nth-child(2) {
      padding-right: 0;
      padding-left: .625rem;
      @media (min-width: 640px) {
        padding-right: .625rem;
      }
    }
    &:nth-child(3) {
      padding-right: .625rem;
      padding-left: 0;
      @media (min-width: 640px) {
        padding-left: .625rem;
      }
    }
    &:nth-child(4) {
      padding-left: .625rem;
    }
  }
`

const ButtonPair = styled.div`
  display: flex;
  width: 100%;
  max-width: 30rem;
  margin: 0 auto 1rem;
  @media (min-width: 640px) {
    margin-bottom: 2rem;
  }
  a {
    width: 50%;
    font-size: .875rem;
    padding-left: 1.375em;
    padding-right: 1.375em;
    margin-bottom: 1.25rem;
    border: solid 2px;
    margin-left: .5rem;
    margin-right: .5rem;
    &.blue {
      border-color: #0095D6;
      color: #0095D6;
      &:focus {
        border-color: #0095D6;
        color: #0095D6;
      }
      &:hover {
        border-color: #0095D6;
        background-color: #0095D6;
        color: white;
      }
      &.current {
        color: white;
        background-color: #0095D6;
        &:hover, &:active, &:focus {
          border-color: #0095D6;
          background-color: #0095D6;
          color: white;
        }
      }
    }
    &.amber {
      border-color: #C0740D;
      color: #C0740D;
      &:focus {
        border-color: #C0740D;
        color: #C0740D;      
      }
      &:hover {
        border-color: #C0740D;
        background-color: #C0740D;
        color: white;
      }
      &.current {
        background-color: #C0740D;
        color: white;
        &:hover, &:active, &:focus {
          border-color: #C0740D;
          background-color: #C0740D;
          color: white;
        }
      }
    }
  }
  * + * {
    // margin-left: 1.25rem;
  }
`;
