import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import bind from '../bind';
export const touTheme = {
  hpBlue: '#0096D6',
  textColor: '#4e4e4e',
  hpSimplifiedLight: "font-family: 'hp-simplified-light', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';",
  hpSimplifiedNormal: "font-family: 'hp-simplified', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';",
  medium: '48rem',
  large: '75rem',
};
const MainContent = styled.div`
  color: ${props => props.theme.textColor ? props.theme.textColor : 'inherit'};
  padding: 1.5rem;
  margin: 0 auto;
  max-width: 80rem;
  height: 100%;
  h1 {
    margin-bottom: 1.5rem;
    @media screen and (min-width: ${props => props.theme.medium}) {
      margin-bottom: 2rem;
    }
  }
`
const ListContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: ${props => props.theme.medium}) {
    flex-direction: row;
  }
`
const ContactEntry = styled.div`
  display: flex;
  /* flex-direction: ${props => props.isMobile ? 'column' : 'row' }; */
  flex-direction: column;
  flex: 1 1 auto;
  div { 
    display: flex;
    @media screen and (min-width: ${props => props.theme.medium}) {
      line-height: 1.75;
    }
    flex: 0 1 auto;
    span:first-child {
      flex: 0 0 25%;
      padding-right: 1rem;
      white-space: nowrap;
    }
    span:last-child {
      flex: 1 1 75%;
      @media screen and (min-width: ${props => props.theme.medium}) {
        flex: 1 1 100%;
      }
    }
  }
  @media screen and (min-width: ${props => props.theme.medium}) {
    &:nth-child(2n-1) {
      span:first-child { display: inline-block; }
      flex: 0 1 60%;
      padding-right: .5rem;
      padding-left: 0;
    }
    &:nth-child(2n) {
      flex: 0 1 40%;
      padding-left: 0.5rem;
      span:first-child { display: none; }
    }
  }
  @media screen and (min-width: ${props => props.theme.large}) {
    &:nth-child(3n-2) {
      flex: 0 1 40%;
      padding-right: 0.666rem;
      padding-left: 0;
      span:first-child { display: inline-block; }
    }
    &:nth-child(3n-1) {
      flex: 0 1 30%;
      padding-right: 0.333rem;
      padding-left: 0.333rem;
      span:first-child { display: none; }
    }
    &:nth-child(3n) {
      flex: 0 1 30%;
      padding-right: 0;
      padding-left: 0.666rem;
      span:first-child { display: none; }
    }
  }
  margin-bottom: 2.5rem;
  ${props => props.theme.hpSimplifiedNormal ? props.theme.hpSimplifiedNormal : 'inherit'};
  span:first-child  {
    ${props => props.theme.hpSimplifiedLight ? props.theme.hpSimplifiedLight : 'inherit'};
    /* display: ${props => props.isMobile ? 'inline-block' : 'none'}; */
  }
`

const sortLists = (sections) => {
  return  sections.sort((firstItem, secondItem) => firstItem.index - secondItem.index)
}

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.breakpoint = 768;
    this.state = {
      is_mobile: window.innerWidth < this.breakpoint,
    };
    this.resizeFn = this.resizeFn.bind(this);
    window.addEventListener("resize", this.resizeFn);
  }
  resizeFn = () => {
    const debounce = (func, delay) => {
      let inDebounce
      return function () {
        const context = this
        const args = arguments
        clearTimeout(inDebounce)
        inDebounce = setTimeout(() => func.apply(context, args), delay)
      }()
    }
    const resizeLayout = () => {
      if (this.state.is_mobile !== window.innerWidth < this.breakpoint) {
        this.setState({
          is_mobile: window.innerWidth < this.breakpoint
        })
      }
    }
    debounce(resizeLayout, 700)
  }
  componentWillMount() {
    let locales = this.props.locales;
    const localValue = locales.languageCode + '-' + locales.countryCode;
    locales = localValue;

    this.props.getContact(locales);
    this.props.getContactEntries(locales);
  }

  render () {
    let { contact, contactEntries } = this.props;
    if (contact.isComplete && !contact.isError && contactEntries.isComplete && !contactEntries.isError) {
      let contactLabelData = !!contact.data.contact_labels ? contact.data.contact_labels[0] : {};
      let pageTitle = !!contactLabelData.label_block.page_title ? contactLabelData.label_block.page_title : "";
      let contactData = !!contactEntries.data.response ? contactEntries.data.response : {};
      const contactEntriesSort = sortLists(contactData);
      return (
        <ThemeProvider theme={touTheme}>
          <MainContent>
            <h1>{pageTitle}</h1>
            <ListContainer isMobile={this.state.is_mobile}>
              {contactEntriesSort.map((contactEntry, i) => (
                <ContactEntry isMobile={this.state.is_mobile} key={i}>
                  <div><span>Country:</span><span>{contactEntry.country}</span></div>
                  <div><span>Language:</span><span>{contactEntry.language}</span></div>
                  <div><span>HP Contact:</span><span><a href={contactEntry.hp_contact}>{contactEntry.hp_contact}</a></span></div> {/** TODO: should these anchor tags navigate? */}
                  <div><span>HP Entity:</span><span>{contactEntry.hp_entity}</span></div>
                  <div><span>Street Address:</span><span>{contactEntry.street_address}</span></div>
                  <div><span>Support Contact:</span><span><a href={contactEntry.support_contact}>{contactEntry.support_contact}</a></span></div>
                  <div><span>Support Phone:</span><span>{contactEntry.support_phone ? contactEntry.support_phone : "…"}</span></div>
                  <div><span>VAT ID:</span><span>{contactEntry.vat_id ? contactEntry.vat_id : "…"}</span></div>
                </ContactEntry>
              ))}
            </ListContainer>  
          </MainContent>
        </ThemeProvider>
      );
    } else if (contact.isError) {
      return (
        <p>{contact.errorMsg}</p>
      )
    } else { // Case: We're loading content
      return <div></div>;
    }
  }
}

export default bind(Contact);