/* globals _O */
import axios from 'axios';

axios.defaults.xsrfHeaderName = '';

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie != '') {
    const rawCookies = document.cookie;
    const cookies = rawCookies.split(';');
    for (let i = 0; i < cookies.length; ++i) {
      const nameVal = cookies[i].split('=');
      if (nameVal[0].trim() == name) {
        cookieValue = nameVal[1];
      }
    }
  }
  return cookieValue;
}

export const analyticsObjectExists = () => {
  try {
    const test = _O !== undefined;
    return test;
  } catch (e) {
    console.warn('Errored out checking for Analytics Object, "_O"', e);
    return false;
  }
};

export const getGeoCountry = () => {
  let tries = 0;
  function getGeo(res, rej) {
    if (analyticsObjectExists()) {
      const { country: geoCountry } = _O.getGeo();
      if (geoCountry) {
        return res(geoCountry);
      }
    }
    if (tries === 4) {
      return rej(null);
    }
    tries += 1;
    setTimeout(() => {
      getGeo();
    }, 10);
  }
  return new Promise(getGeo);
};

export const ifPrivacyAvailable = (successCallback, failureCalback) => {
  function testPrivacy(resolve, reject) {
    let timeout;

    function privacyReady() {
      clearTimeout(timeout);
      resolve(successCallback);
    }

    if (analyticsObjectExists()) {
      privacyReady();
    } else {
      timeout = setTimeout(() => {
        if (analyticsObjectExists()) {
          privacyReady();
        } else {
          reject(failureCalback);
        }
      }, 1000);
    }
  }
  return new Promise(testPrivacy)
    .then(success => {
      if (typeof success === 'function') {
        success();
      }
    })
    .catch(failure => {
      if (typeof failure === 'function') {
        failure();
      }
    });
};

export function getConsentCookie() {
  if (analyticsObjectExists()) {
    const optInString = _O.getOptIn();
    if (optInString) {
      // getOptIn returns a string of up to six characters where each character is a "0", "1", or "?"
      // these characters represent different groups of permissions
      // the existing/legacy code is concerned with group `2`, so we need to increase the indecies
      // of the characters of the string so the existing implementation is looking at the right value
      //
      // documentation: https://docs.google.com/document/d/1BVb0M10bNtHYNRkRmwtxJXQkB3ooOVzM2cXph273fms/edit#heading=h.xz2zs9iqonwb
      // stories: HPC3-4137 and HPC3-4494
      return `0${optInString}`;
    }
  }
  let cookieValue = null;
  if (document.cookie && document.cookie != '') {
    const rawCookies = document.cookie;
    const cookies = rawCookies.split(';');
    for (let i = 0; i < cookies.length; ++i) {
      const nameVal = cookies[i].split('groups='); // =
      if (nameVal.length === 2) {
        const prep = unescape(nameVal[1].split('&')[0]).split(',');
        const toMultiArray = {};
        prep.forEach(element => {
          toMultiArray[element.slice(0, 1)] = element.slice(2);
        });
        cookieValue = toMultiArray;
      }
    }
  }

  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////
  // Whitelist the subdomains for internal testing
  const hostSubDomain = window.location.hostname.split('.')[0];
  const hostDomain = window.location.hostname.split('.')[1];
  if (hostSubDomain === 'local-hpc3' || hostSubDomain === 'hpc-pie1' || hostSubDomain === 'hpc3-dev' || hostSubDomain === 'hpc-stage1' || hostDomain === 'hpsmartdev' || hostDomain === 'hpconnectedpie' || hostDomain === 'hpsmartpie') {
    cookieValue = cookieValue === null ? [null, null, '1'] : cookieValue; // this causes the tracking to work all the time, when OptanonConsent cookie is not set by OneTrust
  } else {
    // if we are not in test envirnonment AND the cookieValue is null, insert off-state
    cookieValue = cookieValue === null ? [null, null, '0'] : cookieValue; // insert cookie in off-state
  }
  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////

  return cookieValue;
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  let hostName = window.location.hostname;
  let domainName = '';
  if (hostName.split('.').length >= 3) {
    hostName = hostName.split('.');
    const lenth = hostName.length;
    domainName = `.${hostName[lenth - 2] }.${hostName[lenth - 1]}`;
  } else {
    domainName = `.${hostName}`;
  }
  document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${domainName}`;
}

export function setOnboardingCookie(printerId, this_callback = () => { }) {
  let thisSession = getCookie('hpc3-csrf-token');
  if (thisSession !== null) {
    thisSession = thisSession.split('-')[0]; // .join('')
  } else {
    thisSession = Math.trunc(Date.now() / 1000000);
  }
  const currentPrinterId = printerId.split('-').join('');

  const arraysToString = arrayOfArrays => {
    const tempArray = [];
    for (let i = 0; i < arrayOfArrays.length; i++) {
      tempArray.push(arrayOfArrays[i].join('.'));
    }
    return tempArray.join('-');
  };

  const stringToArrays = string => {
    const stringArray = string.split('-');
    const newArray = [];
    for (let i = 0; i < stringArray.length; i++) {
      newArray[i] = stringArray[i].split('.');
    }
    return newArray;
  };

  const getOnboardingCookie = () => {
    let val = getCookie('onboarded');
    val = !val ? `initialized.${thisSession}` : val;
    return stringToArrays(val);
  };

  const _setOnboardingCookie = cookieValue => {
    setCookie('onboarded', cookieValue, 1024);
  };

  const onboardingCookie = getOnboardingCookie();

  const activateOnboarding = () => {
    _setOnboardingCookie(arraysToString(onboardingCookie));
  };

  let idFound = -1;
  for (let i = 0; i < onboardingCookie.length; i++) {
    if (onboardingCookie[i].indexOf(currentPrinterId) > -1) {
      idFound = i;
    }
  }

  // if session id found in onboardingCookie
  if (idFound != -1) {
    // if length is exactly 2 then add a new session entry to index 2
    if (onboardingCookie[idFound].length === 2) {
      if (this_callback === undefined) {
        onboardingCookie[idFound].push(thisSession);
        activateOnboarding();
      } else if (onboardingCookie[idFound][1] != thisSession) {
        onboardingCookie[idFound].push(thisSession);
        activateOnboarding();
        this_callback();
      } else {
        // do nothing
      }
    } else {
      // do nothing
    }
  } else {
    onboardingCookie.push([currentPrinterId, thisSession]);
    activateOnboarding();
    this_callback();
  }
}
