import React, { Component } from 'react';
import AccordionTitle from "./../../shared-components/molecules/AccordionTitle";
import LoaderElement from "./../../shared-components/molecules/LoaderElement";
import AreaTypes from "../../../constants/AreaTypes";
import EmailPrintPreference from "./email-printing-preferences/EmailPrintPreference";
import EmailPrintOptions from "./email-printing-preferences/EmailPrintOptions";
import SettingsSection from './SettingsSection';
import classNames from 'classnames';
import bind from "./../../bind";

class EmailPrintingPreferences extends Component {
  constructor(props) {
    super(props);
    /////////
    this.stateactive = this.props.active
    // this.state = {
    //   active: this.props.active
    // };
    this.isPending = this.isPending.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.$container = null;
    this.isReset = false
  }
  isPending(area) {
    switch (area) {
      case 'color':
        return this.props.myPrinter.isFetching && this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_EPRINT_SETTINGS_PREFERENCES_COLOUR;
      case 'paper':
        return this.props.myPrinter.isFetching && this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_EPRINT_SETTINGS_PREFERENCES_PAPER;
      case 'sides':
        return this.props.myPrinter.isFetching && this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_EPRINT_SETTINGS_PREFERENCES_SIDES;
    }

  }
  performAccordion() {
    this.$container = this.refs.preferencesContainer;
    let animationSpeed = 0.5
    if (this.$container === undefined) return null  //////// IMPORTANT FOR WIDESCREEN
    let $preferenceItems = this.$container.getElementsByClassName('ePrintPreference--optionSet');
    this.stateactive = this.props.active
    if (this.stateactive) {
      for (let i = 0, len = $preferenceItems.length; i < len; i++) {
        let $element = $preferenceItems[i];
        $element.style.maxHeight = $element.scrollHeight + "px";
        TweenLite.to($element, animationSpeed, { ease: Power3.easeOut, maxHeight: "0px" });
      }
      this.stateactive = false
    } else {
      for (let i = 0, len = $preferenceItems.length; i < len; i++) {
        let $element = $preferenceItems[i];
        TweenLite.to($element, animationSpeed, {
          ease: Power3.easeOut, maxHeight: $element.scrollHeight + "px", onComplete: () => {
            $element.style.maxHeight = 'none';
          }
        });
      }
      this.stateactive = true
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active && nextProps.active !== this.stateactive || this.isReset) {
      this.isReset = false
      this.performAccordion();
    }
    // this.stateactive = nextProps.active

    if (this.props.isTablet !== nextProps.isTablet && nextProps.isTablet === false) {
      this.stateactive = null;
      this.isReset = true
      this.props.handleToggle("", true);
    }
  }

  handleToggle() {
    this.props.handleToggle("preferences");
    this.performAccordion();
  }

  render() {
    let printer = this.props.myPrinter.data.WPPData;
    let preferenceSettingData = this.props.myPrinter.data.preferenceSettingData;
    if (!printer.hasOwnProperty('preferenceSettings')) {
      return null;
    }
    let getOptionsContent = (printer) => (printer.preferenceSettings.map((setting, index) => {
      let activeOption = null;
      for (let i = 0, len = setting.Options.length; i < len; i++) {
        if (setting.Options[i].Key === printer.preferences[setting.Key]) {
          activeOption = setting.Options[i];
        }
      }
      let contentContainerClass = classNames(
        'preferenceContentContainer',
        { 'preferenceContentContainer--pending': this.isPending(setting.Key) }
      );
      return (
        <div key={index} className={contentContainerClass}>
          <EmailPrintPreference name={setting.Label + ":"} >{this.isPending(setting.Key) ? <LoaderElement /> : activeOption.Label}</EmailPrintPreference>
          <EmailPrintOptions
            isPending={this.isPending(setting.Key)}
            setting={setting}
            activeOption={activeOption}
            trackingHandler={this.props.trackingHandler} />
        </div>
      );
    }));
    let containerClass = classNames(
      "accordion",
      "myPrinterSetting",
      "ePrintSettingsPreferences",
      { "accordion-active": this.stateactive }
    );
    return (
      <div className="printerGrid--printerCell ePrintSection--preferences">
        <a name="tracking-hook" id="eprint-preferences-monochrome"></a>
        <a name="tracking-hook" id="eprint-preferences-color"></a>
        <a name="tracking-hook" id="eprint-preferences-plain"></a>
        <a name="tracking-hook" id="eprint-preferences-photo"></a>
        <a name="tracking-hook" id="eprint-preferences-1-sided"></a>
        <a name="tracking-hook" id="eprint-preferences-2-sided"></a>
        {this.props.isTablet ||
          <SettingsSection sectionTitle={preferenceSettingData.eprint_preferences_title}>
            {getOptionsContent(printer)}
          </SettingsSection>
        }
        {this.props.isTablet &&
          <div className={containerClass} ref="preferencesContainer">
            <AccordionTitle ID="eprint-preferences" loading={false} accordionTitle={preferenceSettingData.eprint_preferences_title} onClick={this.handleToggle} />
            {getOptionsContent(printer)}
          </div>
        }
      </div>
    );
  }
}

export default bind(EmailPrintingPreferences);