import styled from 'styled-components';
import {
  Button,
  Card,
} from '@veneer/core';
import { stylesIf } from '../../../../utils/styleHelpers';
import { UCDEMainTheme } from '../Dashboard/styles';
import { MainContent } from '../../components/shared-components/styles';

export const PrinterCard = styled(Card)`
  border-width: 0!important;
`;

export const SubContent = styled(MainContent)`
  @media (max-width: ${UCDEMainTheme.breakPoints.lg}) {
    display: none;
  }
`;

export const SubGroup = styled.div`
  align-items: center;
  text-align: end;
  justify-content: ${stylesIf('nested', 'flex-end', 'space-between')};
  display: ${stylesIf('nested', 'block', 'flex')};

  @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
    display: ${stylesIf('nested', 'none', 'flex')};
    > * {
      &:not(:last-child)  {
        margin-right: 16px;
      }
    }
  }
`;

export const SubDescription = styled.div`
  font-family: ${UCDEMainTheme.main.hp.fontFamilyLight};
  font-size: 12px;

  @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
    font-size: 16px;
  }
`;

export const DefaultButton = styled(Button)`
  && {
    font-size: 14px;

    @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
      font-size: 16px;
    }
  }
`;

export const LinkButton = styled.div`
  && {
    font-family: ${UCDEMainTheme.main.hp.fontFamily};
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    color: ${UCDEMainTheme.main.hp.color};

    > svg {
      fill: ${UCDEMainTheme.main.hp.color};
    }

    :hover {
      cursor: pointer;
    }

    @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
      font-size: 16px;
    }
  }
`;

export const NoPrinters = styled.div`
  && {
    font-family: ${UCDEMainTheme.main.hp.fontFamily};
    font-size: 12px;
    padding: 32px 16px;
    text-align: center;

    > img {
      display: block;
      margin: auto auto 32px;
    }

    @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
      font-family: ${UCDEMainTheme.main.hp.fontFamilyLight};
      font-size: 16px;
      padding: 32px;
    }
  }
`;
