import React from 'react';
import { useSelector } from 'react-redux';
import { selectUcdeUser } from '../../store/modules/ucdeUser/selectors';
import { AvatarContainer, Content, WelcomeMessage } from './styles';
import Avatar from '../Avatar';
import i18n from '../../utils/i18n';
import { USER_TYPE } from '../../../../constants/cookieNames';
import { getCookie } from '../../../../utils/globals';

const UserPanel = ({ hpMosaicLogo }) => {
  const user = useSelector(selectUcdeUser);
  const isHpPlus = getCookie(USER_TYPE) === 'HP+';

  // Cycle between the 4 available messages
  const greeting = (user && user.givenName)
    ? `home.topOfPage.mainHeader${Math.floor(Math.random() * 4) + 1}`
    : 'home.topOfPage.mainHeaderDefault';

  const formattedGreeting = `${i18n.t(greeting, { name: user.givenName })}`;

  return (
    <Content>
      <AvatarContainer>
        {
          isHpPlus
            ? <img src={hpMosaicLogo} alt="HP+ logo" />
            : <Avatar text={`${user.givenName} ${user.familyName}`} />
        }
        <WelcomeMessage isHpPlus={isHpPlus}>
          {formattedGreeting}
        </WelcomeMessage>
      </AvatarContainer>
    </Content>
  );
};

export default UserPanel;
