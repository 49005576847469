import styled from 'styled-components';

const EssentialsMessaging = styled.div`
  display: block;
  text-align: left;
  p, li, a {font-size: 1rem;}
  img {
    width: 1rem;
    height: 1rem;
  }
  @media screen and (min-width: 40rem) {
    p, li, a {font-size: 1.25rem;}
  }
  ul {
    display: inline-block;
    list-style-type: none;
    text-align: left;
    margin-left: 0;
  }
  ol {
    text-align: left;
  }
  ul li {
    display: flex;
    align-items: center;
  }
  li img {
    width: 1rem;
    height: 1rem;
    margin: 0 .375em 0 .375em ;
    vertical-align: middle;
  }
`;

export default EssentialsMessaging;
