import React from 'react';
import i18n from '../../../utils/i18n';
import {
  RaFTooltipContainer,
} from './style';

export const RaFTooltip = ({ visible, onVisible }) => {
  if (visible) {
    document.getElementById('raf-tooltip').style.animation = '2s linear 0s 1 normal running showTooltip';
    window.setTimeout(() => {
      document.getElementById('raf-tooltip').style.animation = '';
      onVisible(false);
    }, 2000);
  }

  return (
    <RaFTooltipContainer id="raf-tooltip">
      {i18n.t('home.ReferAFriend.shareButtons.copyLink.tooltip')}
      <div className="arrow" />
    </RaFTooltipContainer>
  );
};

export default RaFTooltip;
