import styled from 'styled-components';
import {
  IconCheckCircle,
  IconWarningAlt,
  IconXCircle,
  IconLock,
  IconSlashCircle,
  IconLockOpen,
  IconShield,
  IconShieldOff,
  IconMinusCircle,
  IconInfo,
} from '@veneer/core';
import { UCDEMainTheme } from '../../../../../../components/Dashboard/pages/Dashboard/styles';
import { stylesIf } from '../../../../../../utils/styleHelpers';

export const IconInformation = styled(IconInfo)`
  && {
    fill: #212121;
  }
`;

export const IconPrinterEnabled = styled(IconCheckCircle)`
  && {
    fill: #08a622;
  }
`;

export const IconPrinterWarning = styled(IconWarningAlt)`
  && {
    fill: #e8701a;
  }
`;

export const IconPrinterDisabled = styled(IconXCircle)`
  && {
    fill: #bf1212;
  }
`;

export const IconSecurityEnabled = styled(IconLock)`
  && {
    fill: #08a622;
  }
`;

export const IconSecurityWarning = styled(IconSlashCircle)`
  && {
    fill: #e8701a;
  }
`;

export const IconSecurityDisabled = styled(IconLockOpen)`
  && {
    fill: #dddddd;
  }
`;

export const IconSecurityUnavailable = styled(IconShieldOff)`
  && {
    fill: #404040;
  }
`;

export const IconPrinterError = styled(IconXCircle)`
  && {
    fill: #404040;
  }
`;

export const IconShieldGreen = styled(IconShield)`
  && {
    fill: #08a622;
  }
`;

export const IconShieldGrey = styled(IconShield)`
  && {
    fill: #dddddd;
  }
`;

export const IconShieldOrange = styled(IconShield)`
  && {
    fill: #e8701a;
  }
`;

export const IconSlashCircleRed = styled(IconSlashCircle)`
  && {
    fill: #bf1212;
  }
`;

export const IconWarningAltOrange = styled(IconWarningAlt)`
  && {
    fill: #e8701a;
  }
`;

export const IconMinusCircleRed = styled(IconMinusCircle)`
  && {
    fill: #bf1212;
  }
`;

export const PrinterStatus = styled.div`
  text-align: left;
  letter-spacing: 0;
  color: #404040;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  margin-left: 20px;

  @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
    font-size: ${stylesIf('printersPage', '16px', '12px')};
    line-height: 24px;
    margin-left: 28px;
  }

  ${IconPrinterEnabled}, ${IconPrinterWarning}, ${IconPrinterDisabled},
  ${IconSecurityEnabled}, ${IconSecurityWarning}, ${IconSecurityDisabled},
  ${IconPrinterError}, ${IconShieldGrey}, ${IconShieldGreen}, 
  ${IconShieldOrange}, ${IconSlashCircleRed}, ${IconWarningAltOrange}, 
  ${IconSecurityUnavailable}, ${IconMinusCircleRed}, ${IconInformation} {
    vertical-align: bottom;
    margin-right: 4px;
    width: 16px;
    height: 16px;
    margin-left: -20px;

    @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
      width: 24px;
      height: 24px;
      margin-left: -28px;
    }
  }
`;

export const DrawerContainer = styled.div`
  && {
    background-color: #E2E2E212;
    padding: 32px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: calc(100% + 64px);
      position: relative;
      top: -32px;
      left: -32px;
      height: 21px;
      background: transparent linear-gradient(0deg, #E2E2E200 0%, #717171 100%) 0% 0% no-repeat padding-box;
      opacity: 0.06;
    }
  }
`;

export const HeaderText = styled.div`
  width: 100%;
  margin-bottom: 20px;
  margin-top: -20px;
  font-size: 12px;

  @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
    font-size: 16px;
  }
`;

export const DetailContainer = styled.div`
  padding: 18px 0;
  border-top: 1px solid #E9E5E5;
  
  ${PrinterStatus} {
    font-family: ${UCDEMainTheme.main.hp.fontFamily};
    font-size: 12px;

    @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
      font-size: 20px;
    }
  }
`;

export const Explanation = styled.div`
  margin-left: 20px;
  font-size: 12px;

  @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
    margin-left: 28px;
    font-size: 16px;
  } 
`;
