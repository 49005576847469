import React from 'react';

import {
  AnimatedSection,
  GapLoadingSection,
  AdditionalFeaturesLoadingSection,
  AdditionalFeaturesContentLoadingSection
} from '../styles/StyleSkeleton';

const SkeletonAdditionalFeatures = () => {
  return (
    <AdditionalFeaturesLoadingSection>
      <AnimatedSection height="100%">
        <AdditionalFeaturesContentLoadingSection paddingMargin="50%">
          <AnimatedSection height="50px" margin="20px" />
          <AnimatedSection height="15px" margin="5px" />
          <AnimatedSection height="15px" margin="5px" />
          <AnimatedSection height="15px" margin="5px" />
          <AnimatedSection height="15px" />
        </AdditionalFeaturesContentLoadingSection>
        <GapLoadingSection />
        <AdditionalFeaturesContentLoadingSection paddingMargin="20%">
          <AnimatedSection height="50px" margin="20px" />
          <AnimatedSection height="15px" margin="5px" />
          <AnimatedSection height="15px" margin="5px" />
          <AnimatedSection height="15px" margin="5px" />
          <AnimatedSection height="15px"></AnimatedSection>
        </AdditionalFeaturesContentLoadingSection>
      </AnimatedSection>
    </AdditionalFeaturesLoadingSection>
  );
};

export default SkeletonAdditionalFeatures;
