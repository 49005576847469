import logoBenefitsShield from '../../assets/img/benefits-shield.svg';
import logoBenefitsCloudPrint from '../../assets/img/benefits-cloudPrint.svg';
import logoBenefitsTruck from '../../assets/img/benefits-truckPrint.svg';
import logoBenefitsEmailPrint from '../../assets/img/benefits-emailPrint.svg';

export const myPrintersURLs = {
  dev: 'https://hpsmartdev.com/myprinters',
  pie: 'https://hpsmartpie.com/myprinters',
  stage: 'https://hpsmartstage.com/myprinters',
  prod: 'https://hpsmart.com/myprinters',
};

export const entitlements = {
  smartSecurity: {
    shortName: 'home.services.service2.title',
    name: 'manageHPBenefits.overview.benefit1.text',
    detail: 'manageHPBenefits.overview.benefit1.description',
    icon: logoBenefitsShield,
    link: '/services/smart-security',
    enabledStatus: 'home.services.service2.goodState',
    disabledStatus: 'home.services.service2.offState',
  },
  printOnTheGo: {
    shortName: 'home.services.service3.title',
    name: 'manageHPBenefits.overview.benefit2.text',
    detail: 'manageHPBenefits.overview.benefit2.description',
    icon: logoBenefitsCloudPrint,
    link: '/services/print-on-the-go',
    enabledStatus: 'home.services.service3.goodState',
    disabledStatus: 'home.services.service3.offState',
  },
  printPlans: {
    shortName: 'home.services.service1.title',
    name: 'manageHPBenefits.overview.benefit3.text',
    detail: 'manageHPBenefits.overview.benefit3.description',
    icon: logoBenefitsTruck,
    link: '/print_plans',
    enabledStatus: 'home.services.service1.goodState',
    disabledStatus: 'home.services.service1.offState',
  },
  ePrint: {
    shortName: 'home.services.service4.title',
    name: 'manageHPBenefits.overview.feature1.text',
    detail: 'manageHPBenefits.overview.feature1.description',
    icon: logoBenefitsEmailPrint,
    link: '',
    enabledStatus: 'home.services.service4.goodState',
    disabledStatus: 'home.services.service4.offState',
  },
};

export const handleCardsToShow = (params = {}) => {
  const {
    role, key,
  } = params;

  // If user role is 'User', don't show links to Print Plans and Print By Email
  if (role === 'User' && (key === 'printPlans' || key === 'ePrint')) {
    return false;
  }

  return true;
};
