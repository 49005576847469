import React from 'react';
import {
  prop,
  is,
  pathOr,
  propOr,
} from 'ramda';
import styled from 'styled-components';
import Badges from '../shared-components/molecules/Badges';
import PictureAndText from './PictureAndText';
import { Container, TitleImage } from './PictureAndText/PictureAndText.styles';
import { TemplateSection } from '../shared-components/shared-styles/TemplateStyles';

export const SectionWrapper = styled.div`
  max-width: 52rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  padding: 5.5rem 1.25rem 0;
  min-width: initial;
  section:first-of-type {
    order: 2;
  }
  ${TemplateSection} {
    order: 3;
    h3 {
      font-size: 1.875rem;
    }
    :first-of-type {
      padding-top: 0;
    }
  }
  @media screen and (min-width: 40rem) {
    padding: 9rem 1.25rem 0;
    ${Container}:first-of-type {
      margin-top: 0;
    }
  }
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 1.75rem;
  @media screen and (min-width: 40rem) {
    margin-bottom: 0;
    padding-bottom: 2.5rem;
  }
  order: 1
`;

const PaddedSpan = styled.span`
  padding-left: 1rem;
`;

const Title = ({ title }) => {
  if (!title) {
    return null;
  }
  if (is(Object, title)) {
    return (
      <SectionTitle>
        {
          title.source && (
            <TitleImage
              src={title.source}
              alt={propOr('', 'alt', title)}
            />
          )
        }
        <PaddedSpan>
          {title.text}
        </PaddedSpan>
      </SectionTitle>
    );
  }
  return (
    <SectionTitle>
      {title}
    </SectionTitle>
  );
};

const ProductSection = ({ title, badges, segments = [], children, }) => (
  <SectionWrapper segmentsCount={segments.length}>
    <Title title={title} />
    {children}
    {
      segments.length && segments.map(seg => (
        <PictureAndText
          key={`${title}${pathOr(prop('title', seg), ['title', 'text'], seg)}`}
          {...seg} // eslint-disable-line
        />
      ))
    }
    {
      badges && <Badges footerText={is(Object, badges) && prop('footerText', badges)} />
    }
  </SectionWrapper>
);

export default ProductSection;
