/* eslint-disable */
import { createSelector } from 'reselect';
import {
  isEmpty,
  or,
  includes,
  prop,
  propOr,
  path,
  not,
} from 'ramda';
import { selectPrinterCommonContentstackData, selectProductCraftData } from './craft';
import {
  DJ2700IA,
  DJ4100IA,
  DJ6000,
  DJ6400,
  TANGO
} from '../constants/printerIds';
import {
  INSTANT_INK_COUNTRIES,
} from '../constants/i18n';

const createSetUpGraphicProps = (device, printer_common) => {
  const install_hp_smart = path(['install_hp_smart', 0, 'install_details_block'], printer_common);
  const setup_help = path(['setup_help', 0, 'setup_block'], printer_common);
  const secure_your_printer = path(['secure_your_printer', 0, 'your_printer_block'], printer_common);

  return {
    title: prop('setup_section_title_text', printer_common),
    sectionID: 'get-ready',
    badges: prop('install_features_text', install_hp_smart),
    segments: [
      ...install_hp_smart
        ? [
          {
            sectionID: 'hp-smart-app',
            title: {
              text: prop('install_subhead_text', install_hp_smart),
            },
            preamble: prop('install_directions_text', install_hp_smart),
            left: {
              main: true,
              source: prop('installHpSmartGraphicUrl', device),
              alt: prop('installHpSmartGraphicTitle', device),
            },
          },
        ]
        : [],
      ...setup_help
        ? [
          {
            sectionID: 'wifi-reset',
            title: {
              text: prop('setup_subhead_text', setup_help),
            },
            preamble: prop('setup_description_text', setup_help),
            left: {
              text: path(['essentialsDirectionsA', 'essentialsDirectionsDescription'], device),
            },
            right: {
              source: path(['essentialsDirectionsA', 'essentialsDirectionsIllustrationUrl'], device),
              alt: path(['essentialsDirectionsA', 'essentialsDirectionsIllustrationTitle'], device),
            },
            footer: prop('setup_additional_text', setup_help),
          },
        ]
        : [],
      ...(secure_your_printer && prop('secureYourPrinterGraphicUrl', device))
        ? [
          {
            title: {
              text: prop('secure_your_printer_subhead_text', secure_your_printer),
            },
            left: {
              source: prop('secureYourPrinterGraphicUrl', device),
              alt: prop('setUpHelpGraphicTitle', device),
            },
            right: {
              text: prop('secure_your_printer_description_text', secure_your_printer),
            },
          },
        ]
        : [],
    ],
  };
};

const createUseAppSectionProps = (device, printer_common) => {
  const smartAppPrintSection = prop('smart_app_print_section', printer_common);
  const smartAppScanCopy = path(['smartAppScanCopySelector', 'smartAppScanCopy'], device);
  const scannerGraphics = prop('scannerGraphics', device);
  const documentFeederUrl = prop('documentFeederUrl', scannerGraphics);

  return {
    title: prop('use_smart_app_section_title_text', printer_common),
    badges: false,
    segments: [
      ...smartAppPrintSection
        ? [
          {
            title: {
              source: path(['print_section_block', 'print_subhead_icon', 'url'], smartAppPrintSection[0]),
              alt: path(['print_section_block', 'print_subhead_icon', 'title'], smartAppPrintSection[0]),
              text: path(['print_section_block', 'print_subhead_text'], smartAppPrintSection[0]),
            },
            preamble: path(['print_section_block', 'print_summary_text'], smartAppPrintSection[0]),
            left: {
              source: prop('useSmartAppPrintGraphicUrl', device),
              alt: prop('useSmartAppPrintGraphicTitle', device),
            },
            right: {
              text: path(['print_section_block', 'print_description_text'], smartAppPrintSection[0]),
            },
          },
        ]
        : [],
      ...smartAppScanCopy
        ? [
          {
            title: {
              source: prop('scanAndCopyIconUrl', smartAppScanCopy),
              alt: prop('scanAndCopyIconTitle', smartAppScanCopy),
              text: prop('scanAndCopySectionTitleText', smartAppScanCopy),
            },
            preamble: prop('scanAndCopyDirections1Text', smartAppScanCopy),
            ...documentFeederUrl
              ? {
                left: {
                  source: prop('scannerGlassUrl', scannerGraphics),
                  alt: prop('scannerGlassTitle', scannerGraphics),
                  caption: prop('scannerGlassCaptionText', scannerGraphics),
                },
                right: {
                  source: prop('documentFeederUrl', scannerGraphics),
                  alt: prop('documentFeederTitle', scannerGraphics),
                  caption: prop('documentFeederCaptionText', scannerGraphics),
                },
              }
              : {
                left: {
                  main: true,
                  source: prop('scannerGlassUrl', scannerGraphics),
                  alt: prop('scannerGlassTitle', scannerGraphics),
                  caption: prop('scannerGlassCaptionText', scannerGraphics),
                },
              },
            footer: prop('scanAndCopyDirections2Text', smartAppScanCopy),
          },
        ]
        : [],
    ],
  };
};

const createLearnAboutTangoProps = device => {
  const featureDescription = propOr({}, 'featureDescriptionDetails', device);
  return {
    sectionID: 'learn-about',
    title: prop('learningSectionTitle', device),
    badges: false,
    segments: [
      {
        sectionID: 'print-anywhere',
        title: prop('featureDescriptionTitle', device),
        left: {
          text: prop('featureDescriptionSummary', device),
        },
        right: {
          source: prop('featureDescriptionIllustrationUrl', featureDescription),
          alt: prop('featureDescriptionIllustrationTitle', featureDescription),
        },
        footer: prop('featureDescriptionText', featureDescription),
      },
    ],
  };
};

const INK_ADVANTAGE_PRINTERS = [DJ2700IA, DJ4100IA, DJ6000, DJ6400];

export const selectProductPageCraftData = createSelector(
  selectProductCraftData,
  selectPrinterCommonContentstackData,
  (data, data_printer_common) => {
    if (isEmpty(data)) {
      return {};
    }
    const { id, device, country } = data;
    const printer_common = data_printer_common;
    const showIILine = includes(country, INSTANT_INK_COUNTRIES);
    const isTango = id === TANGO;
    return {
      device,
      printer_common,
      isTango,
      setUpSectionProps: createSetUpGraphicProps(device, printer_common),
      useAppSectionProps: isTango
        ? createLearnAboutTangoProps(device, printer_common)
        : createUseAppSectionProps(device, printer_common),
      ...showIILine && {
        footnoteText: path(['create_account_section', 'instant_supplies_footnote'], printer_common),
      },
    };
  },
);

export const selectInkPurchaseOptionProps = createSelector(
  selectProductCraftData,
  selectPrinterCommonContentstackData,
  (data, data_printer_common) => {
    if (isEmpty(data)) {
      return null;
    }
    const { country, id } = data;
    const printer_common = data_printer_common;
    return {
      purchaseOptions: prop('purchase_options', printer_common),
      hideIIBtn: not(INSTANT_INK_COUNTRIES.includes(country))
        || INK_ADVANTAGE_PRINTERS.includes(id),
    }
  }
);

export const selectGetInkProps = createSelector(
  selectProductCraftData,
  selectPrinterCommonContentstackData,
  (data, data_printer_common) => {
    if (isEmpty(data)) {
      return null;
    }
    const { device, country } = data;
    const printer_common = data_printer_common;
    return {
      inkSectionTitle: prop('ink_section_title', printer_common),
      inkSectionIconUrl: path(['ink_section_icon', 'url'], printer_common),
      inkSectionIconTitle: path(['ink_section_icon', 'title'], printer_common),
      cartridgeSelector: prop('cartridgeSelector', device),
      countryCode: country,
    }
  }
);
