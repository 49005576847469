import React, { Component } from 'react';
import AreaTypes from '../../../../constants/AreaTypes';
import LoaderElement from './../../../shared-components/molecules/LoaderElement';
import classNames from 'classnames';
import HollowButton from '../../../shared-components/atoms/hollowButton';
import { validateAccessEmail } from '../../../../lib/stringUtils';
import bind from './../../../bind';
import ErrorBoundary from '../../../shared-components/atoms/ErrorBoundary';
import { pathOr, prop } from 'ramda';
import { RTL_LANGUAGES } from '../../../../constants/i18n';

class EprintWhitelist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formAddress: '',
      formValid: true
    };
    this.isPending = this.isPending.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.onAddClicked = this.onAddClicked.bind(this);
    this.onRemoveClicked = this.onRemoveClicked.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleAccessCheckboxChange = this.handleAccessCheckboxChange.bind(this);
  }

  isPending() {
    return (
      this.props.myPrinter.isFetching &&
      !this.props.myPrinter.isComplete &&
      this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST
    );
  }

  handleKeyUp($event) {
    $event.preventDefault();
    if ($event.keyCode === 13) {
      this.onAddClicked($event);
      return true;
    }
  }

  handleAddressChange(event) {
    this.setState({
      formAddress: event.target.value,
      formValid: true
    });
  }

  onAddClicked(event) {
    if (this.isPending()) {
      return;
    }
    event.preventDefault();
    this.props.trackAdd();

    // Validate email
    const isValidEmail = validateAccessEmail(
      this.state.formAddress,
      this.props.myPrinter.data.miscellaneousData.eprint_access_blocked_domains
    );
    if (!isValidEmail) {
      this.setState({ formValid: false });
      return;
    }
    this.props.addMyPrinterAccessAllowedEmails(
      this.props.printer.printerId,
      this.state.formAddress
    );
    this.setState({
      formValid: true,
      formAddress: ''
    });
  }

  onRemoveClicked(event, entryId_email) {
    event.preventDefault();
    this.setState({ formValid: true });
    const printer_generation = this.props.printer.generation;
    this.props.removeMyPrinterAccessAllowedEmails(
      this.props.printer.printerId,
      entryId_email,
      printer_generation
    );
  }

  onFormSubmit($event) {
    $event.preventDefault();
    $event.stopPropagation();
    return true;
  }

  handleAccessCheckboxChange($event) {
    this.props.toggleMyPrinterAccessSendOnPrint(
      this.props.printer.printerId,
      $event.target.checked
    );
    $event.target.checked = !$event.target.checked;
  }

  render() {
    const language = window.location.pathname.split('/')[2];
    const isRTLSupport = RTL_LANGUAGES.includes(language);
    const printer = this.props.myPrinter.data.WPPData;
    const whitelist = pathOr(prop('whitelist', printer), ['whitelist', 'whitelist'], printer);
    const { formValid } = this.state;
    let accessSettingData = this.props.myPrinter.data.accessSettingData;
    let user = this.props.userInfo.userInfoData;
    let emailValidClass = classNames({
      'feedbackMessage--error': this.state.formValid,
      'feedbackMessage--error-visible': !this.state.formValid
    });
    const addButtonAttrs = {
      buttonText: accessSettingData.eprint_access_action_text,
      onClick: this.onAddClicked,
      classes: { 'ePrintAccessSetting--button': true, 'rtl-button': isRTLSupport ? true : false }
    };
    const isCheckboxPending =
      this.props.myPrinter.isFetching &&
      !this.props.myPrinter.isComplete &&
      this.props.myPrinter.currentArea ===
      AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_WHITELIST_SEND_TOGGLE;
    if (this.isPending()) {
      addButtonAttrs.disabled = true;
    }
    let emailSenderToggle = true;

    if (whitelist === null) {
      return null;
    }

    const errorMessage = accessSettingData.eprint_access_email_error_text;

    if (printer.errorStateReturned === true) {
      emailValidClass = classNames({
        'feedbackMessage--error': false,
        'feedbackMessage--error-visible': true
      });
      this.props.myPrinter.data.WPPData.errorStateReturned = null;
    }

    whitelist.forEach((entry) => {
      if (!entry.allowOutBoundMailStatus) {
        emailSenderToggle = false;
      }
    });

    const filterOwnerEmail = (email, printerWhitelist) => {
      return printerWhitelist.filter((printer) => printer.emailAddress !== email);
    };

    let printer_whitelist = whitelist.slice(0).reverse();

    const filteredPrinters = filterOwnerEmail(user.email, printer_whitelist);

    printer_whitelist = [{ emailAddress: user.email }, ...filteredPrinters];

    return (
      <div id="whitelist" className="ePrintAccessSetting">
        <h4 className="setting--title">{accessSettingData.eprint_access_allowed_label}</h4>
        <form className="ePrintAccessSetting--form" onSubmit={this.onFormSubmit}>
          <p className="ePrintAccessSetting--explanation">
            {accessSettingData.eprint_access_allowed_description}
          </p>
          <div className="ePrintAccessSetting--inputs">
            <input
              type="text"
              placeholder={accessSettingData.eprint_access_allowed_address_placeholder}
              value={this.state.formAddress}
              onKeyUp={this.handleKeyUp}
              onChange={this.handleAddressChange}
              maxLength={254}
            />
            <HollowButton {...addButtonAttrs} />
          </div>
          {!formValid && <span className={emailValidClass}>{errorMessage}</span>}
          <ErrorBoundary>
            <ul className="ePrintAccessSetting--addresses">
              {printer_whitelist.map((entry, index) => {
                const removableEmailIndicator = { ...entry, indx: index };
                const email = entry.emailAddress;
                if (index === 0) {
                  return (
                    <li key={index} className="ePrintAccessSetting--address-owner">
                      <span className="ePrintAccessSetting--icon-owner">●</span>
                      <span className="ePrintAccessSetting--email">{email}</span>
                      <span className="ePrintAccessSetting--id-owner">
                        {' '}
                        - {accessSettingData.eprint_access_allowed_example_owner_text}
                      </span>
                    </li>
                  );
                }
                if (
                  this.isPending() &&
                  this.props.myPrinter.accessIndex !== null &&
                  index === this.props.myPrinter.accessIndex.indx
                ) {
                  return (
                    <li key={index} className="eprintAccessSetting-loader">
                      <LoaderElement />
                    </li>
                  );
                }
                return (
                  <li
                    key={index}
                    className="ePrintAccessSetting--address ePrintAccessSetting--address-removable"
                  >
                    <button
                      type="button"
                      onClick={(e) => this.onRemoveClicked(e, removableEmailIndicator)}
                    >
                      x
                    </button>
                    <span className="ePrintAccessSetting--email">{email}</span>
                  </li>
                );
              })}
              {this.isPending() && this.props.myPrinter.accessIndex === null ? (
                <li className="eprintAccessSetting-loader">
                  <LoaderElement />
                </li>
              ) : null}
            </ul>
          </ErrorBoundary>
          {printer.generation === 2 ? null : (
            <div className="ePrintAccessSetting--checkbox">
              <input
                id="access-allowed-checkbox"
                type="checkbox"
                className={isRTLSupport ? 'rtl-checkbox' : ''}
                onChange={this.handleAccessCheckboxChange}
                checked={emailSenderToggle}
                disabled={isCheckboxPending}
              />
              <div className="ePrintAccessSetting--checkboxLabel">
                {accessSettingData.eprint_access_checkbox_message}
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default bind(EprintWhitelist);
