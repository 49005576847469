/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import base64url from 'base64url';
import {
  prop,
} from 'ramda';
import { selectUcdePortalElementsLoading } from '../portalElements/selectors';
import {
  selectLocation,
  selectQueryParameters,
  selectQueryString,
  selectRootMatch,
} from '../../../../../selectors/routing';
import {
  selectAnalyticsData,
} from '../../../../../selectors/analytics';
import { selectOrgamiAddPaperUrlFromConfig } from '../../../../../selectors/config';
import { selectUcdeUserLocaleProps } from '../ucdeUser/selectors';
import { encodeAuthState } from '../../../utils/routing';

export const selectPortalProps = createSelector(
  selectUcdePortalElementsLoading,
  selectLocation,
  (loading, location = {}) => {
    return {
      loading,
      takeover: location.state && location.state.takeover,
    }
  }
);

export const selectPortalTakeoverProps = createSelector(
  selectLocation,
  (location = {}) => {
    return location.state && location.state.takeover;
  },
);

export const selectFooterProps = createSelector(
  selectRootMatch,
  selectUcdeUserLocaleProps,
  (match, { country: userCountry, language: userLanguage }) => {
    const { params = {} } = match;
    const {
      country: urlCountry = 'us',
      language: urlLanguage = 'en',
      resource,
    } = params;

    const country = (userCountry || urlCountry).toUpperCase();
    const language = userLanguage || urlLanguage;

    return {
      country,
      language: language.includes(country) ? language : `${language}_${country}`,
      accountUrl: `/${urlCountry}/${language}/${resource}/account-details/profile`,
    };
  },
);

const determineRedirectTo = ({ url, country, language, decodedRedirectTo, queryString, origamiAddPaperUrl }) => {
  if (
    url === `/${country}/${language}`
    || url === `/${country}/${language}/ucde`
    || url === `/${country}/${language}/gateway`
    || url === `/${country}/${language}/signin`
    || url === `/${country}/${language}/ucde/signin`
    || url === `/${country}/${language}/gateway/signin`
  ) {
    return decodedRedirectTo;
  }

  if (url === `/${country}/${language}/paper-subscription`) {
    const origamiPath = new URL(origamiAddPaperUrl).pathname;
    const origamiUrl = `/${country}/${language}${origamiPath}${queryString}`;

    return origamiUrl;
  }

  return decodedRedirectTo || url;
};

export const selectSignInProps = createSelector(
  selectRootMatch,
  selectQueryParameters,
  selectQueryString,
  selectAnalyticsData,
  selectOrgamiAddPaperUrlFromConfig,
  (rootMatch, queryParams, queryString, analyticsData, origamiAddPaperUrl) => {
    if (!rootMatch) {
      return {};
    }
    const {
      url,
      params: {
        country,
        language,
      },
    } = rootMatch;
    const {
      state,
    } = queryParams;
    const stateDecoded = state ? JSON.parse(base64url.decode(state)) : {};

    const decodedRedirectTo = prop('redirectTo', stateDecoded);
    const redirectTo = determineRedirectTo({
      url,
      country,
      language,
      decodedRedirectTo,
      queryString,
      origamiAddPaperUrl,
    });

    const handshake = String(Math.random());

    const encodedState = encodeAuthState(handshake, redirectTo, analyticsData);
    return {
      handshake,
      redirectTo,
      encodedState,
    };
  },
);
