import React, { Component } from 'react';
import MyPrinterImage from '../my-printer/MyPrinterImage';
import HollowButtonPrimary from '../shared-components/atoms/hollowButtonPrimary';
import MyPrinterEmailHeader from './MyPrinterEmailHeader';
import SettingSummary from './SettingSummary';
import bind from '../bind';

class MyPrinterCardView extends Component {
  constructor(props) {
    super(props);
    this.change_settings = this.change_settings.bind(this);
  }

  change_settings() {
    this.props.change_settings_fn(this.props.myid, this.props.link_url, this.props.cd1, this.props.cd2);
  }

  render() {
    return (
      <div
        id={this.props.myid} className="myPrinterCardView"
        data-cd1={this.props.cd1} data-cd2={this.props.cd2}
      >
        <MyPrinterEmailHeader
          pEmail={this.props.pEmail}
          myid={this.props.myid}
          ePrintSupport={this.props.ePrintSupport}
        />
        <MyPrinterImage
          ID={`${this.props.myid}_pict`} mainImageSmall={this.props.images[1]}
          mainImageMedium={this.props.images[1]}
          mainImageLarge={this.props.images[0]}
          mainImageAlt={this.props.imageAlt}
          whenClicked={this.change_settings}
        />
        <h3 className="myPrinterTitle myPrinterTitle-cardList" onClick={this.change_settings}>
          <span className="myPrinterCardView--status">
            <img src={this.props.printerStatusIndicator} alt={this.props.printerStatusTitle ? this.props.printerStatusTitle : ''} />
          </span>
          {this.props.printerName ? this.props.printerName : 'My HP Printer'}
        </h3>
        <div className="myPrinterCardView--ePrintSettingsSummary" onClick={this.change_settings}>
          <h4 className="myPrinterCardView--ePrintSettingsTitle">{(this.props.ePrintSupport) ? this.props.ePrintSettings : this.props.ePrintSettings.slice(7)}</h4>
          {(this.props.ePrintSupport) ? (this.props.summary.map((row, index) => (
            <SettingSummary
              key={index} settingKey={row.Key}
              settingValue={row.Label}
            />
          ))) : <p />}
        </div>
        <HollowButtonPrimary
          ID={`${this.props.myid}_changeSettings`} buttonText={this.props.buttonText}
          onClick={this.change_settings}
        />
      </div>
    );
  }
}

export default bind(MyPrinterCardView);
