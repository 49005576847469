import ActionTypes from '../constants/ActionTypes';
import fetchStatus from './fetchStatus';
// import AreaTypes from "../constants/AreaTypes";
function fetchMyPrinterStatus(state = null, action = { currentArea: null }) {
  const status = (state === null) ? fetchStatus() : fetchStatus(state, action);
  return {
    ...status,
    currentArea: action.currentArea,
  };
}
const myPrinterDefault = {
  ...fetchMyPrinterStatus(),
  data: {},
};

/**
 * The reducer controls the state of the axios or ajax call.
 * returning the current state with any changes from the
 * actions dispatchers
 *
 *
 * @param state
 * @param action
 * @returns {*}
 */
function myPrinter(state = myPrinterDefault, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_MYPRINTER_ACCESS_OPTION:
      return {
        ...state,
        data: {
          ...state.data,
          WPPData: {
            ...state.data.WPPData,
            // TODO: This is updating based on a 200 response and a user generated piece of data, not
            // any data that is in the API response
            printerCurrentAccess: action.payload.accessOption,
          },
        },
      };
    case ActionTypes.START_MYPRINTER_AREA_PENDING:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.START_FETCH }),
      };
    case ActionTypes.MYPRINTER_AREA_ERROR:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH_WITH_ERROR }),
      };
    case ActionTypes.START_UPDATE_MYPRINTER_ADDRESS:
      return {
        ...state,
        data: state.data,
      };

    case ActionTypes.COMPLETE_UPDATE_MYPRINTER_ADDRESS:
      return {
        ...state,
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: { ...state.data, WPPData: { ...state.data.WPPData, printerEmail: action.printerEmail || action.data.printerEmail } },
      };
      // data: Object.assign({}, state.data, { WPPData: Object.assign({}, state.data.WPPData, action.myPrinter) })

    case ActionTypes.CLEAR_UPDATE_MYPRINTER_ADDRESS_STATUS:
      return {
        ...state,
        isError: false,
        isComplete: false,
        isFetching: false,
      };
    case ActionTypes.COMPLETE_UPDATE_MYPRINTER_ACCESS_OPTION:/** handle holding state here for open to anyone */
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: { ...state.data, WPPData: action.newPrinter },
      };
    case ActionTypes.START_ADD_MYPRINTER_ACCESS_ALLOWED_EMAILS:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.START_FETCH }),
        accessIndex: null,
      };
    case ActionTypes.COMPLETE_ADD_MYPRINTER_ACCESS_ALLOWED_EMAILS:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: { ...state.data, WPPData: { ...state.data.WPPData, whitelist: action.newWhitelist, errorStateReturned: false } },
        accessIndex: null,
      };
    case ActionTypes.ADD_MYPRINTER_ACCESS_ALLOWED_EMAILS_ERROR:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH_WITH_ERROR }),
        data: { ...state.data, WPPData: { ...state.data.WPPData, errorStateReturned: true } },
      };
    case ActionTypes.START_REMOVE_MYPRINTER_ACCESS_ALLOWED_EMAILS:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.START_FETCH }),
        accessIndex: action.accessIndex,
      };
    case ActionTypes.COMPLETE_REMOVE_MYPRINTER_ACCESS_ALLOWED_EMAILS:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: { ...state.data, WPPData: { ...state.data.WPPData, whitelist: action.newWhitelist } },
        accessIndex: null,
      };
    case ActionTypes.REMOVE_MYPRINTER_ACCESS_ALLOWED_EMAILS_ERROR:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH_WITH_ERROR }),
      };
    case ActionTypes.START_ADD_MYPRINTER_ACCESS_BLOCKED_EMAILS:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.START_FETCH }),
        accessIndex: null,
      };
    case ActionTypes.COMPLETE_ADD_MYPRINTER_ACCESS_BLOCKED_EMAILS:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: { ...state.data, WPPData: { ...state.data.WPPData, blacklist: action.newBlacklist, errorStateReturned: false } },
        accessIndex: null,
      };
    case ActionTypes.ADD_MYPRINTER_ACCESS_BLOCKED_EMAILS_ERROR:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH_WITH_ERROR }),
        data: { ...state.data, WPPData: { ...state.data.WPPData, errorStateReturned: true } },
        accessIndex: null,
      };
    case ActionTypes.START_REMOVE_MYPRINTER_ACCESS_BLOCKED_EMAILS:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.START_FETCH }),
        accessIndex: action.accessIndex,
      };
    case ActionTypes.COMPLETE_REMOVE_MYPRINTER_ACCESS_BLOCKED_EMAILS:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: { ...state.data, WPPData: { ...state.data.WPPData, blacklist: action.newBlacklist } },
        accessIndex: null,
      };
    case ActionTypes.REMOVE_MYPRINTER_ACCESS_BLOCKED_EMAILS_ERROR:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH_WITH_ERROR }),
        accessIndex: null,
      };
    case ActionTypes.START_UPDATE_MYPRINTER_PREFERENCE:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.START_FETCH }),
      };
    case ActionTypes.COMPLETE_UPDATE_MYPRINTER_PREFERENCE:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: { ...state.data, WPPData: { ...state.data.WPPData, ...action.myPrinter } },
      };
    case ActionTypes.UPDATE_MYPRINTER_PREFERENCE_ERROR:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH_WITH_ERROR }),
      };
    case ActionTypes.COMPLETE_MYPRINTER_FETCH:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: action.myPrinter,
      };
    case ActionTypes.UPDATE_MYPRINTER_WITH_ALREADY_FETCHED_DATA:
      return {
        data: action.printerData,
      };
    case ActionTypes.COMPLETE_MYPRINTER_UNCLAIM:
      return {
        ...state,
        currentArea: action.currentArea,
      };
    case ActionTypes.COMPLETE_MYPRINTER_WITH_ERROR:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH_WITH_ERROR }),
      };
    case ActionTypes.COMPLETE_MYPRINTER_WHITELIST_TOGGLE:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: { ...state.data, WPPData: { ...state.data.WPPData, whitelist: action.newWhitelist } },
      };
    case ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_EMAIL:
      return {
        ...state,
        data: { ...state.data, WPPData: { ...state.data.WPPData, printerEmail: action.printerEmail || action.data.printerEmail } },
      };
    default:
      return state;
  }

  return state;
}

export default myPrinter;
