import styled from 'styled-components';

export const Container = styled.div`
`;

export const EcoCard = styled.div`
  background: #15907F no-repeat;
  background-position: center center;
  width: 100%;
  max-width: 300px;
  border-radius: 12px;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    max-width: 344px;
    height: 200px;
  }
`;

export const EcoCardContent = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  margin-top: 64px;

  @media (max-width: ${props => props.theme.breakPoints.lg}) {
    margin-top: 0;
  }

  h5 {
    color: #FFFFFF;
    font-family: ${props => props.theme.main.hp.fontFamily};
    font-size: 20px;
    line-height: 22px;
  }

  p {
    color: #FFFFFF;
    font-family: ${props => props.theme.main.hp.fontFamilyLight};
    font-size: 16px;
    padding-top: 32px;
    line-height: 22px;
    @media (max-width: ${props => props.theme.breakPoints.md}) {
      padding-top: 0;
    }
  }
`;

export default Container;
