import React from 'react';
import { HeaderContainer, HeaderText } from './styles';

const Header = (props) => {
  return (
    <HeaderContainer>
      <HeaderText>{props.text}</HeaderText>
    </HeaderContainer>
  );
};

export default Header;
