import { values, join, compose, map } from 'ramda';
import { CONSTANTS as DCN } from './dataCollectionNotices';
import { CONSTANTS as PRINTERIDS } from './printerIds';

export const SMART_HELP = '/smart-help';
export const IN_APP_HELP = '/in-app-help';
export const SMART_ADVANCE = '/smart-advance';
export const UPGRADE_SUPPORT = '/support/upgrade';
export const TOU = '/tou';
export const SMB_ADMIN_TOU = '/admin-tou';
export const HPAPP_TOU = '/hpapp-tou';
export const PIPL_CONSENT = '/pipl-consent';
export const DATA_SHARING_NOTICE = '/data-sharing-notice';
export const DATA_COLLECTION_NOTICE = values(DCN);
export const TONER_REPLACE_CONSENTS = '/toner-replace-consents';
export const SMB_DOWNLOAD = '/download';
export const MOBILE_FAX = '/mobile-fax';
export const SMB_SETUP_CHOICE = '/setup';
export const SMB_NO_SETUP = '/smb-no-setup';
export const GET_SOFTWARE = '/get-software';
export const GENERIC_SUPPORT = '/support/generic';
export const PHOTO_PAPER = '/landing/photo-paper';
export const PRINTER_LIST_PAGE = '/support/printer-list';
export const DEVICE_LIST_PAGE = '/support/list';
export const PRINTER_CONNECTION_PAGE = ['/support/printer-connection/'];
export const PS_TELEMETRY = '/data-notice';
export const SURE_SUPPLY_LEARN = '/sure-supply-learn';
export const ERROR_PAGE = ['/error/'];
export const PRINTERS_INFO = values(PRINTERIDS);
export const MY_PRINTERS = '/myprinters';
export const NEW_PRINTER = '/newprinter';
export const NEW_HOME = '/';
