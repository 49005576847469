import React, { Component } from 'react';
import classNames from "classnames";
import LoaderElement from "./../../../shared-components/molecules/LoaderElement";
import AreaTypes from "../../../../constants/AreaTypes";
import HollowButton from '../../../shared-components/atoms/hollowButton';
import { validateAccessEmail } from "../../../../lib/stringUtils";
import bind from "./../../../bind";
import { RTL_LANGUAGES } from '../../../../constants/i18n';

class EprintBlacklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formAddress: "",
      formValid: true
    };
    this.isPending = this.isPending.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.onAddClicked = this.onAddClicked.bind(this);
    this.onRemoveClicked = this.onRemoveClicked.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
  }
  isPending() {
    return this.props.myPrinter.isFetching && this.props.myPrinter.currentArea === AreaTypes.MYPRINTER_EPRINT_SETTINGS_ACCESS_BLACKLIST;
  }
  handleKeyUp($event) {
    $event.preventDefault();
    if ($event.keyCode === 13) {
      this.onAddClicked($event);
      return true;
    }
  }
  handleAddressChange(event) {
    this.setState({
      formAddress: event.target.value,
      formValid: true
    });
  }

  onAddClicked(event) {
    if (this.isPending()) {
      return;
    }
    event.preventDefault();
    this.props.trackAdd();

    // Validate email
    const isValidEmail = validateAccessEmail(this.state.formAddress,
      this.props.myPrinter.data.miscellaneousData.eprint_access_blocked_domains);
    if (!isValidEmail) {
      this.setState({ formValid: false });
      return;
    }
    this.props.addMyPrinterAccessBlockedEmails(this.props.printer.printerId, this.state.formAddress);
    this.setState({
      formValid: true,
      formAddress: ""
    });
  }
  onRemoveClicked(event, accessIndex) {
    event.preventDefault();
    this.setState({ formValid: true });
    this.props.removeMyPrinterAccessBlockedEmails(this.props.printer.printerId, accessIndex);
  }
  onFormSubmit($event) {
    $event.preventDefault();
    $event.stopPropagation();
    return true;
  }

  render() {
    const language = window.location.pathname.split('/')[2];
    const isRTLSupport = RTL_LANGUAGES.includes(language);
    const printer = this.props.myPrinter.data.WPPData;
    const { accessSettingData } = this.props.myPrinter.data;
    const { formValid } = this.state;
    const errorMessage = accessSettingData.eprint_access_email_error_text;
    let emailValidClass = classNames({
      "feedbackMessage--error": this.state.formValid,
      "feedbackMessage--error-visible": !this.state.formValid
    });
    let addButtonAttrs = {
      buttonText: accessSettingData.eprint_access_action_text,
      onClick: this.onAddClicked,
      classes: {
        'ePrintAccessSetting--button': true,
        'rtl-button': isRTLSupport ? true : false
      }
    };
    if (this.isPending()) {
      addButtonAttrs.disabled = true;
    }
    if (printer.errorStateReturned === true) {
      emailValidClass = classNames({
        "feedbackMessage--error": false,
        "feedbackMessage--error-visible": true
      });
      this.props.myPrinter.data.WPPData.errorStateReturned = null
    }

    return (<div id="blacklist" className="ePrintAccessSetting">
      <h4 className="setting--title">{accessSettingData.eprint_access_blocked_label}</h4>
      <form className="ePrintAccessSetting--form" onSubmit={this.onFormSubmit}>
        <p className="ePrintAccessSetting--explanation">{accessSettingData.eprint_access_blocked_description}</p>
        <div className="ePrintAccessSetting--inputs">
          <input type="text" placeholder={accessSettingData.eprint_access_blocked_address_placeholder} value={this.state.formAddress} onKeyUp={this.handleKeyUp} onChange={this.handleAddressChange} maxLength={254} />
          <HollowButton
            {...addButtonAttrs}
          />
        </div>
        {(!formValid) && <span className={emailValidClass}>{errorMessage}</span>}
        <ul className="ePrintAccessSetting--addresses">
          {printer?.blacklist?.map((entry, index) => {
            if (this.isPending() && index === this.props.myPrinter.accessIndex) {
              return (<li key={index} className="eprintAccessSetting-loader"><LoaderElement /></li>);
            }
            return <li key={index} className="ePrintAccessSetting--address-removable"><button type="button" onClick={(e) => this.onRemoveClicked(e, index)}>x</button><span className="ePrintAccessSetting--email">{entry.emailAddress}</span></li>;
          })}
          {(this.isPending() && this.props.myPrinter.accessIndex === null) ? <li className="eprintAccessSetting-loader"><LoaderElement /></li> : null}
        </ul>
      </form>
    </div>
    )
  }
}

export default bind(EprintBlacklist);