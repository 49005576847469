import ActionTypes from "../constants/ActionTypes";
import { getContentStackData } from "../services/craft";
import { CONTACT_ENTRIES_RESOURCE_NAME } from "../constants/contentStackResources";

/**
 * This function uses axios to get smart help data.
 *
 * Since axios returns promises, we can dispatch our start event and
 * update it to complete or complete with error whe the promise returns.
 *
 * @returns {function(*)}
 */

export function getContactEntries(language) {
  let start = () => ({
      type: ActionTypes.START_CONTACT_ENTRIES_FETCH
    }),
    complete = (response) => ({
      type: ActionTypes.COMPLETE_CONTACT_ENTRIES_FETCH,
      response
    }),
    error = err => ({
      type: ActionTypes.COMPLETE_CONTACT_ENTRIES_FETCH_WITH_ERROR,
      err
    });
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(start());

      getContentStackData(CONTACT_ENTRIES_RESOURCE_NAME, language)
        .then(res => {
          dispatch(complete(res.data.entries));
          return resolve(res.data.entries);
        })
        .catch(err => {
          dispatch(error(err));
          reject(err);
        });
    });
  };
}