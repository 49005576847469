import React from 'react';
import { userOSDetector } from '../../../utils/userOSDetector';
import GetSoftware from './GetSoftware';
import UnsupportedOS from './UnsupportedOS';
import { UNSUPPORTED_OS_LIST } from '../../../constants/common';

const SMBGetSoftware = () => {
  try {
    const userOperatingSystem = userOSDetector();

    if (!!userOperatingSystem) {
      if (UNSUPPORTED_OS_LIST.includes(userOperatingSystem))
        return <UnsupportedOS />;
      else return <GetSoftware />;
    }
  } catch (error) {
    generalLog.error('Error in SMB download component. Find details below- ');
    generalLog.error(error);
  }
  return null;
};

export default SMBGetSoftware;