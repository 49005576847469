import React from 'react';
import styled from 'styled-components';

export const Paragraph = styled.p`
  color:white;
  text-align: center;
`;

const MyPrinterEmailHeader = props => {
  const {
    pEmail, myid
  } = props;

    if (pEmail) {
      return (
        <a
          id={`${myid}_email`} href={`mailto:${pEmail}`}
          className="myPrinter--ePrintAddress"
        >
          {pEmail}
        </a>
      );
    }
    if(!pEmail) {
      return (
        <span id={`${myid}_email`} className="myPrinter--ePrintAddress">no.address@hpeprint.com</span>
      );
    }
};

export default MyPrinterEmailHeader;
