import React from 'react';
import PropTypes from 'prop-types';

import {
  ContentContainer,
  AddPaperButton,
  EnrollContainer,
  EnrollLinkText,
  EnrollLinkImage,
  ChangeEnrollText
} from './styles/StyleEnrollContentBox';

const propTypes = {
  addPaperButtonText: PropTypes.string.isRequired,
  enrollLinkText: PropTypes.string.isRequired,
  enrollLinkChevronTitle: PropTypes.string.isRequired,
  enrollLinkChevronUrl: PropTypes.string.isRequired,
  changeEnrollText: PropTypes.string,
  handleClick: PropTypes.func,
  handleEnrollLink: PropTypes.func,
  className: PropTypes.string.isRequired,
  isFlex: PropTypes.bool
};

const defaultProps = {
  addPaperButtonText: '',
  enrollLinkText: '',
  enrollLinkChevronTitle: '',
  enrollLinkChevronUrl: '',
  changeEnrollText: '',
  className: '',
  isFlex: false
};

const EnrollContentBox = (props) => {
  const {
    addPaperButtonText,
    enrollLinkText,
    enrollLinkChevronTitle,
    enrollLinkChevronUrl,
    changeEnrollText,
    handleClick,
    handleEnrollLink,
    className, 
    isFlex
  } = props;

  return (
    <ContentContainer data-testid="enroll-content-box" className={className} flex={isFlex}>
      <EnrollContainer>
        <EnrollLinkText
          data-testid="enroll-content-box-link-text"
          dangerouslySetInnerHTML={{ __html: enrollLinkText }}
          onClick={handleEnrollLink}
        />
        <EnrollLinkImage>
          <img
            data-testid="enroll-content-box-link-img"
            src={enrollLinkChevronUrl}
            alt={enrollLinkChevronTitle}
            title={enrollLinkChevronTitle}
          />
        </EnrollLinkImage>
      </EnrollContainer>
      <AddPaperButton onClick={handleClick}>{addPaperButtonText}</AddPaperButton>
      {changeEnrollText && (
        <ChangeEnrollText
          data-testid="enroll-content-box-change-enrolltext"
          dangerouslySetInnerHTML={{ __html: changeEnrollText }}
        />
      )}
    </ContentContainer>
  );
};

EnrollContentBox.propTypes = propTypes;
EnrollContentBox.defaultProps = defaultProps;
export default EnrollContentBox;
