import { createSelector } from 'reselect';
import { selectRootMatch, selectPathname } from '../../../../../selectors/routing';
import { selectHelpLinks } from '../helpCenter/selector';
import getMenuItemsObject from '../../../../Portal/SideNav/menuItems';
import selectUcdePortalElements, {
  selectHasPrintPlansActiveSubscription,
} from '../portalElements/selectors';

const helpTitles = [
  {
    path: '/help/about-hp-smart',
    titleStringId: 'helpCenter.headers.aboutHPSmart',
  },
  {
    path: '/help/printer-and-connection-information',
    titleStringId: 'helpCenter.headers.printersAndConnectionInformation',
  },
  {
    path: '/help/printing-and-scanning',
    titleStringId: 'helpCenter.headers.printerFeatures',
  },
  {
    path: '/help/additional-help-and-support',
    titleStringId: 'helpCenter.headers.additionalHelpAndSupport',
  },
];

function createEntries(url, helpLinks, portalElements, printPlansActiveSubscription = false) {
  const menuItems = getMenuItemsObject({ portalElements, printPlansActiveSubscription });
  const itens = Object.values(menuItems);

  const menuItemsEntries = itens.flatMap(({
    pathname, title, labelId, subMenus = [],
  }) => {
    const subMenuItems = subMenus.map(subItem => ({
      pathname: subItem.pathname,
      titleStringId: subItem.title || subItem.labelId,
    }));

    return subMenus.length > 0
      ? subMenuItems
      : { pathname, titleStringId: title || labelId };
  });

  const helpLinksEntries = helpLinks.map(({ pathname }) => {
    const resource = pathname.replace(url, '');
    const helpEntry = helpTitles.find(item => item.path === resource);

    return {
      pathname: resource,
      titleStringId: helpEntry ? helpEntry.titleStringId : '',
    };
  });

  return [...menuItemsEntries, ...helpLinksEntries];
}

const selectPageNameFromRoute = createSelector(
  selectRootMatch,
  selectPathname,
  selectHelpLinks,
  selectUcdePortalElements,
  selectHasPrintPlansActiveSubscription,
  (rootMatch, pathname, helpLinks, portalElements, printPlansActiveSubscription) => {
    const { url } = rootMatch;
    const currentRoute = pathname.replace(url, '') || '/';
    const entries = createEntries(url, helpLinks, portalElements, printPlansActiveSubscription);
    const currentEntry = entries.find(entry => entry.pathname === currentRoute);

    return currentEntry ? currentEntry.titleStringId : '';
  },
);

export default selectPageNameFromRoute;
