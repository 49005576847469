export const PLAIN_DATA_COLLECTION_NOTICE = '/plain/data-collection-notice';
export const PLAIN_PRINTER_DATA_COLLECTION_NOTICE = '/plain/printer-data-collection-notice';
export const PLAIN_SERVICES_DATA_COLLECTION_NOTICE = '/plain/services-data-collection-notice';
export const PLAIN_WINDOWS_PRINTER_DATA_COLLECTION_NOTICE = '/plain/windows-printer-data-collection-notice';

export const CONSTANTS = {
  PLAIN_DATA_COLLECTION_NOTICE,
  PLAIN_PRINTER_DATA_COLLECTION_NOTICE,
  PLAIN_SERVICES_DATA_COLLECTION_NOTICE,
  PLAIN_WINDOWS_PRINTER_DATA_COLLECTION_NOTICE,
};

export const RESOURCES = {
  [PLAIN_DATA_COLLECTION_NOTICE]: 'blta7e420ce8e21d902',
  [PLAIN_PRINTER_DATA_COLLECTION_NOTICE]: 'blt8c4448fae18a9917',
  [PLAIN_SERVICES_DATA_COLLECTION_NOTICE]: 'blta1acba731c5beda4',
  [PLAIN_WINDOWS_PRINTER_DATA_COLLECTION_NOTICE]: 'blta7e420ce8e21d902',
};