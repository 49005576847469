
export default function keyMirror(structure) {
    let ret = {};
    for (let key in structure) {
        if (structure.hasOwnProperty(key)) {
            ret[key] = key;
        }
    }
    return ret;
}
