import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

import { deviceActions } from '../actions';
import { getUser } from '../actions/userInfo';

export default function bind(me) {
    return connect(
        /**
         * "reducers" from the combineReducers call in the reducers/index.js file.
         *
         * These are mapped to the this.props
         *
         * mapStateToProps
         */
        (state) => state,

        /**
         * actions functions that are mapped to the this.props object
         * This allows the dispatcher to be available to the methods
         *
         * mapDispatchToProps
         */
        (dispatch) =>
            bindActionCreators(
                {
                    ...deviceActions,
                    getUser: (callback) => getUser(callback),
                    push: (path) => push(path)
                },
                dispatch
            )
    )(me);
}
