import ActionTypes from "../constants/ActionTypes";
import fetchStatus from "./fetchStatus";

const termsDefault = {
    ...fetchStatus(),
    data: null
};

/**
 * The reducer controls the state of the axios or ajax call.
 * returning the current state with any changes from the
 * actions dispatchers
 *
 *
 * @param state
 * @param action
 * @returns {*}
 */
function terms(state = termsDefault, action) {
    switch (action.type) {
      case ActionTypes.START_TERMS_FETCH:
        return {
          ...state,
          ...fetchStatus(state, { type : ActionTypes.START_FETCH })
        };
        case ActionTypes.COMPLETE_TERMS_FETCH_WITH_ERROR:
          return {
            ...state,
            ...fetchStatus(state, { ...action, type : ActionTypes.COMPLETE_FETCH_WITH_ERROR }),
          };
        case ActionTypes.COMPLETE_TERMS_FETCH:
          return {
            ...state,
            ...fetchStatus(state, { type : ActionTypes.COMPLETE_FETCH }),
            ...{ data : action.termsData }
          };
    }

    return state;
}

export default terms;
