import React from 'react';
import { useSelector } from '../../hooks';
import { selectEssentialsProps } from '../../selectors/routing';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';
import Redirect from './Redirect';

const EssentialsRedirect = () => {
  const props = useSelector(selectEssentialsProps);
  if (!props) return <LoaderBlock />;
  const {
    country,
    language,
    printerId,
    sku,
    url,
  } = props;


  if (url) {
    window.location.href = url;
    return null;
  }

  if (printerId) {
    return <Redirect to={`/${printerId}`} />;
  }
  window.location.href = `https://support.hp.com/${country}-${language}${sku ? `/printer` : ''}`;
  return null;
};

export default EssentialsRedirect;
 