import React from 'react';

export default function MyPrinterListView(props) {
  const change_settings = () => {
    props.change_settings_fn( props.myid, props.link_url, props.cd1, props.cd2);
  }
  // Unregistered printer code. Works, but stops subsequent printers from being displayed when real data is used. In demo mode, this works fine.
  // if (props.isRegistered === false) {
  //   return (
  //     <a id={props.myid} className="myPrinterListView myPrinterListView-unRegistered" href={props.link_url} >
  //       <div className="myPrinter--icon"><img src={props.printerSettingsIcon} alt={props.printerSettingsIconTitle} /></div>
  //       <div className="myPrinterListView--description">
  //         <h3 className="myPrinterListView--title">{props.unregisteredTitle}</h3>
  //         <span className="myPrinterListView--subtitle">{props.unregisteredSubtitle}</span>
  //       </div>
  //       <div className="myPrinterListView--status">
  //         <img src={props.printerStatusIndicator} alt={props.printerStatusTitle} />
  //       </div>
  //     </a>
  //   );
  // }
  return (
    <a id={`${props.myid}_changeSettings`} className="myPrinterListView"  data-cd1={props.cd1} data-cd2={props.cd2} onClick={change_settings} >
      <div className="myPrinter--icon"><img src={props.printerSettingsIcon} alt={props.printerSettingsIconTitle} /></div>
      <div className="myPrinterListView--description">
        <h3 className="myPrinterListView--title">{props.printerName ? props.printerName : "My HP Printer"}</h3>
        <span id={`${props.myid}_email`} className="myPrinterListView--ePrintAddress">{props.eprintAddress ? props.eprintAddress : "no.address@hpeprint.com"}</span>
      </div>
      <div className="myPrinterListView--status">
        <img src={props.printerStatusIndicator ? props.printerStatusIndicator : "/assets/img/common/icon_printer_disconnected.svg"} alt={props.printerStatusTitle} />
      </div>
    </a>
  );
}
