import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import i18n from '../../utils/i18n';
import selectUcdePortalElements from '../../store/modules/portalElements/selectors';
import { makePathRelative } from '../../../../utils/routing';
import Config from '../../../../config/Config';
import { PATHS } from '../../utils/constants';
import {
  CardHeader,
} from '../shared-components/styles';
import {
  BenefitsDescription,
  BenefitsList,
  Container,
} from './styles';

const config = new Config();
const featureFlags = config.Get('FEATURE_FLAGS');

const Benefit = ({
  image, alt, description, link, linkTitle,
}) => (
  <div>
    <div>
      <img src={`/assets/img/dashboard/${image}`} alt={alt} />
    </div>
    <p>{description}</p>
    {link && linkTitle && (
      <Link to={link}>{linkTitle}</Link>
    )}
  </div>
);

const Benefits = ({ match }) => {
  const buildRelativePath = makePathRelative(match);
  const ucdePortalElements = useSelector(selectUcdePortalElements);
  const {
    info: {
      role,
    },
  } = ucdePortalElements;

  // For fall release, the benefits will be hardcode with these 3
  // Please refer to https://jira.cso-hp.com/browse/SDASH-335
  let benefits = [];

  if (role === 'User') {
    benefits = [
      ...benefits,
      {
        image: 'benefits-truck.svg',
        alt: 'home.benefits.benefit1.linkTitle',
        description: 'home.benefits.benefit1.description',
      },
    ];
  } else {
    benefits = [
      ...benefits,
      {
        image: 'benefits-truck.svg',
        alt: 'home.benefits.benefit1.linkTitle',
        description: 'home.benefits.benefit1.description',
        linkTitle: 'home.benefits.benefit1.linkTitle',
        link: PATHS.PRINT_PLANS,
      },
    ];
  }

  benefits = [
    ...benefits,
    {
      image: 'benefits-green-check.svg',
      description: 'home.benefits.benefit2.description',
    },
  ];

  if (featureFlags.ff_ucde_portal_services) {
    benefits = [
      ...benefits,
      {
        image: 'benefits-hand.svg',
        alt: 'home.benefits.benefit3.linkTitle',
        description: 'home.benefits.benefit3.description',
        linkTitle: 'home.benefits.benefit3.linkTitle',
        link: PATHS.SERVICES.SUMMARY,
      },
    ];
  } else {
    benefits = [
      ...benefits,
      {
        image: 'benefits-hand.svg',
        alt: 'home.benefits.benefit3.linkTitle',
        description: 'home.benefits.benefit3.description',
      },
    ];
  }

  return (
    <Container
      data-testid="benefits-section"
    >
      <CardHeader
        display="block"
      >
        <h5>{i18n.t('home.benefits.mainHeader')}</h5>
        <BenefitsDescription>{i18n.t('home.benefits.description')}</BenefitsDescription>
      </CardHeader>

      <BenefitsList>
        {benefits.map(benefit => (
          <Benefit
            key={`${benefit.link}${benefit.linkTitle}`}
            image={benefit.image}
            alt={benefit.alt && i18n.t(benefit.alt)}
            description={benefit.description && i18n.t(benefit.description)}
            link={benefit.link && buildRelativePath(benefit.link)}
            linkTitle={benefit.linkTitle && i18n.t(benefit.linkTitle)}
          />
        ))}
      </BenefitsList>
    </Container>
  );
};

Benefit.defaultProps = {
  link: '',
  linkTitle: '',
};

Benefit.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkTitle: PropTypes.string,
};

Benefits.propTypes = {
  match: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default Benefits;
