import React from 'react';
import PropTypes from 'prop-types';
import { IconMinusCircle } from '@veneer/core';
import {
  ErrorContainer,
  ErrorMessage,
  RefreshLink,
} from './styles';
import i18n from '../../../../../utils/i18n';

const SupplyError = ({ printersPage, doRefresh }) => (
  <ErrorContainer printersPage={printersPage}>
    <IconMinusCircle color="colorRed6" size={printersPage ? 36 : 24} />
    <ErrorMessage printersPage={printersPage}>
      {i18n.t('home.myPrintersCard.error.supplies.message')}
    </ErrorMessage>
    <RefreshLink printersPage={printersPage} onClick={doRefresh}>
      {i18n.t('home.myPrintersCard.error.buttons.refresh')}
    </RefreshLink>
  </ErrorContainer>
);

SupplyError.defaultProps = {
  printersPage: false,
};

SupplyError.propTypes = {
  printersPage: PropTypes.bool,
  doRefresh: PropTypes.func.isRequired,
};
export default SupplyError;
