import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { ContextualMenu } from '@veneer/core';
import { selectUcdeUserFullName } from '../../Dashboard/store/modules/ucdeUser/selectors';
import { makePathRelative } from '../../../utils/routing';
import { selectRootMatch } from '../../../selectors/routing';
import i18n from '../../Dashboard/utils/i18n';
import Avatar from '../../Dashboard/components/Avatar';
import { AvatarContainer } from './styles';
import { PATHS } from '../../Dashboard/utils/constants';

const ProfileMenu = () => {
  const signOutOption = {
    value: 0,
    label: i18n.t('home.accountIcon.overlayMenu'),
  };

  const userFullName = useSelector(selectUcdeUserFullName);
  const match = useSelector(selectRootMatch);
  const relativePath = makePathRelative(match);
  const dispatch = useDispatch();

  const performSignOut = useCallback(
    () => {
      dispatch(push(relativePath(PATHS.SIGNOUT)));
    },
    [dispatch],
  );

  return (
    <ContextualMenu
      options={[signOutOption]}
      placement="bottom-end"
      onClick={performSignOut}
    >
      <AvatarContainer>
        <Avatar
          text={userFullName}
          backgroundOpacity="0.2"
          fontSize="16px"
        />
      </AvatarContainer>
    </ContextualMenu>
  );
};

export default ProfileMenu;
