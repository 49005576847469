import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UserDeviceStatus from './index';
import { connectionState } from './statusDictionary';
import { getDeviceHealth } from '../../../../api/UCDEGateway';

const MyPrinterStatus = ({ device, printersPage }) => {
  const [printerStateData, setPrinterStateData] = useState({});

  if (!device) {
    return null;
  }

  useEffect(() => {
    const loadStatus = async () => {
      try {
        const printerState = await getDeviceHealth(device.cloudId);
        setPrinterStateData(printerState);
      } catch {
        setPrinterStateData({});
      }
    };
    loadStatus();
  }, []);

  return (
    <>
      {
        device.connectivityStatus && (
        <UserDeviceStatus
          printersPage={printersPage}
          status={device.connectivityStatus.connectionState}
          id="connection-status"
        />
        )
      }
      {
        printerStateData.data && printerStateData.data.state
          && (
          <UserDeviceStatus
            printersPage={printersPage}
            status={printerStateData.data.state.toLowerCase()}
            id="printer-status"
          />
          )
      }
    </>
  );
};

MyPrinterStatus.propTypes = {
  printersPage: PropTypes.bool.isRequired,
  device: PropTypes.shape({
    cloudId: PropTypes.string.isRequired,
    connectivityStatus: PropTypes.shape({
      connectionState: PropTypes.oneOf(Object.keys(connectionState)).isRequired,
    }),
}).isRequired,
};

export default MyPrinterStatus;
