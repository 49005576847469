import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import location from './locationReducer';
import device from './deviceReducer';
import craft from './craft';
import application from './application';
import eprintLanding from './eprintLandingReducer';
import home from './homeReducer';
import printerCommon from './printerCommonReducer';
import locales from './localeReducer';
import config from './configReducer';
import gnb from './globalNavBarReducer';
import termsOfUse from './termsOfUseReducer';
import contact from './contactReducer';
import contactEntries from './contactEntriesReducer';
import terms from './termsReducer';
import mobileFax from './mobileFaxReducer';
import wirelessLanding from './wirelessLandingReducer';
import wirelessVisualNav from './wirelessVisualNavReducer';
import wirelessSection from './wirelessSectionReducer';
import wirelessTopic from './wirelessTopicReducer';
import wirelessSubject from './wirelessSubjectReducer';
import wirelessInk from './wirelessInkReducer';
import myPrinters from './myPrintersReducer';
import myPrinter from './myPrinterReducer';
import userInfo from './userInfoReducer';
import analytics from './analyticsReducer';
import notifications from './notificationsReducer';
import printAnywhere from './printAnywhereReducer';
import portalReducers from '../components/Dashboard/store/modules/rootReducer';
import userPrograms from './userProgramsReducer';
import origami from './origamiReducer';
import contentStack from './contentStack';

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        analytics,
        craft,
        contentStack,
        application,
        location,
        device,
        eprintLanding,
        home,
        printerCommon,
        locales,
        config,
        gnb,
        termsOfUse,
        mobileFax,
        terms,
        contact,
        contactEntries,
        wirelessLanding,
        wirelessVisualNav,
        wirelessSection,
        wirelessTopic,
        wirelessSubject,
        wirelessInk,
        myPrinters,
        myPrinter,
        userInfo,
        printAnywhere,
        notifications,
        userPrograms,
        origami,
        ...portalReducers
    });

export default createRootReducer;
