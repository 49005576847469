import styled from 'styled-components';
import { Card as VeneerCard } from '@veneer/core';
import { UCDEMainTheme } from '../../pages/Dashboard/styles';

export const Card = styled(VeneerCard)`
  display: ${props => (props.isVisible ? 'block' : 'none')};

  @media (max-width: ${UCDEMainTheme.breakPoints.lg}) {
    && {
      max-width: 300px;
      margin: 0 auto;
    }
  }
`;

export const RaFContainer = styled.div`
  display: block;
  text-align: center;
  font-size: 14px;
  font-family: ${UCDEMainTheme.main.hp.fontFamily};

  > img {
    margin: 20px 0;
  }

  > a {
    display: block;
    padding-bottom: 15px;
    text-align: left;
  }

  ol {
    text-align: left;
    padding: 15px 15px 0;
    font-family: ${UCDEMainTheme.main.hp.fontFamilyLight};
    font-size: 16px;

    li {
      list-style: decimal;
      margin-bottom: 30px;
      overflow-wrap: break-word;
    }
  }
`;

export const RaFShareSection = styled.div`
  margin: 30px auto;
  width: 180px;

  a {
    display: inline-block;
    width: 36px;
    height: 36px;
    margin: 0 6px;
    background-size: 100% 100%;
  }
`;

export const TwitterLink = styled.a`
  && {
    background-image: url('/assets/img/dashboard/raf-twitter.png');
    margin-left: 0;
  }
`;

export const FacebookLink = styled.a`
  && {
    background-image: url('/assets/img/dashboard/raf-facebook.png');
    margin: 0 6px;
  }
`;

export const CopyLink = styled.a`
  && {
    background-image: url('/assets/img/dashboard/raf-copy-link.png');
    margin: 0 6px;
    position: relative;
  }
`;

export const EmailLink = styled.a`
  && {
    background-image: url('/assets/img/dashboard/raf-email.png');
    margin-right: 0;
  }
`;

export const RaFClipBoardArea = styled.input`
  /*  to clipboard works is necessary to have a input element, and this input should be visible */
  position: absolute;
  left: -1000px;
`;
