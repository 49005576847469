import React from 'react';
import { AppBadges } from '../../AppBadges/AppBadges';
import {
  ExperienceContainer,
  Introduction,
  AppStoreText,
  QRData,
  QRCode,
  QRCodeText,
  Line
} from './styles';

const Experience = (props) => {
  const badges = props.appStoreBadges;
  return (
    <ExperienceContainer isRTL={props.isRTL}>
      <Introduction
        dangerouslySetInnerHTML={{
          __html: props.heading
        }}
      />
      <AppStoreText>{props.appStoreText}</AppStoreText>
      <AppBadges className="appBadges" badges={badges} isRTL={props.isRTL} />
      <QRData>
        <QRCode alt={props.qrCodeTitle} src={props.qrCodeUrl} />
        <QRCodeText isRTL={props.isRTL}>{props.qrCodeText}</QRCodeText>
      </QRData>
      <Line />
    </ExperienceContainer>
  );
};

export default Experience;
