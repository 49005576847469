import styled from 'styled-components';

export const Container = styled.div`
  margin: 40px;
  text-align: center;
`;

export const BenefitsList = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;

  > div {
      min-height: 125px;
      width: 168px;
      text-align: center;
      font-family: ${props => props.theme.main.hp.fontFamily};

      div {
        &:first-of-type {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          margin-bottom: 27px;
        }
      }

      p {
        font-family: ${props => props.theme.main.hp.fontFamilyLight};
        font-size: ${props => props.theme.main.hp.fontSize};
        color: ${props => props.theme.main.benefits.textColor};
        margin: 0 auto 10px auto;
        line-height: ${props => props.theme.main.benefits.lineHeight};
      }

      a {
        color:  ${props => props.theme.main.hp.color};
        font-size: 14px;
        line-height: 30px;
      }

      img {
        vertical-align: bottom;
      }

      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
`;
export const BenefitsDescription = styled.p`
  margin-top: 16px;
`;
