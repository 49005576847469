import styled from 'styled-components';
import { breakpoints } from '../../../utils/styleHelpers';

export const HowItWorksSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 64px 182px;

  @media (max-width: ${breakpoints.wide}) {
    padding: 0px 32px;
    margin: 32px 0;
  }

  @media (max-width: 768px) {
    padding: 0px 40px;
  }

  @media (max-width: ${breakpoints.bigmobile}) {
    padding: 0px 24px;
  }
`;

export const HowItWorksContainer = styled.div`
  background-color: #F8F8F8;
  padding: 32px;
`;

export const HowItWorksVideoContainer = styled.div`
  background-color: #F8F8F8;
  padding: 30px;
`;

export const Heading = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  color: #212121;
  margin-bottom: 0;

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 48px;
  }

  @media (max-width: ${breakpoints.bigmobile}) {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const Subheading = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin: 0.5rem 0 2rem 0;
  color: #000;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: ${breakpoints.bigmobile}) {
    font-size: 18px;
    font-height: 24px;
    margin: 0.5rem 0 1.5rem 0;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;

  >:nth-of-type(1) {
    border-right: 1px solid #212121;
    padding-right: 32px;
  }

  >:nth-of-type(2) {
    border-right: 1px solid #212121;
    padding: 0 32px;
  }

  >:nth-of-type(3) {
    padding-left: 32px;
  }

  div:child {
    text-align: initial;
    max-width: 100%;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0;

    >:nth-of-type(1) {
      border-right: none;
      border-bottom: 1px solid black;
      padding: 0 0 48px 0;
    }

    >:nth-of-type(2) {
      border-right: none;
      border-bottom: 1px solid black;
      padding: 48px 0 48px 0;
    }

    >:nth-of-type(3) {
      padding: 48px 0 0px 0;
    }
  }

  @media (max-width: ${breakpoints.bigmobile}) {
    >:nth-of-type(1) {
      padding: 0 0 24px 0;
    }

    >:nth-of-type(2) {
      padding: 0 0 24px 0;
    }

    >:nth-of-type(3) {
      padding: 0 0 0px 0;
    }
  }
`;
