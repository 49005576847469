/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import {
  prop,
  pick,
  compose,
  path,
  identity,
} from 'ramda';
import {
  selectMyPrintersContentStackData,
} from './craft';
import { YETI_CLAIM_ERROR } from '../constants/errorTypes';

const SELECTORS_BASE = {
  selectWPPData: path(['myPrinters', 'data', 'WPPData']),
};

export const selectWPPData = createSelector(
  SELECTORS_BASE.selectWPPData,
  identity,
);

export const selectClaimCodeModalData = createSelector(
  selectMyPrintersContentStackData,
  (_, errorCode) => errorCode,
  (myPrintersContentstackData, errorCode) => {
    if (!myPrintersContentstackData || errorCode !== YETI_CLAIM_ERROR) {
      return null;
    }
    const modalValues = compose(
      pick(['errorTitle', 'errorBody']),
      prop('claimCodeModalError'),
    )(myPrintersContentstackData);

    return modalValues;
  },
);
