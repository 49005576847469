import React, { Component } from 'react';
import {
  path,
  prop,
  either,
  always,
  length,
  gt,
  compose,
  head,
  match,
} from 'ramda';
import TweenLite from 'gsap';
import { getCookie } from '../../lib/manageCookies';
import { USER_TYPE } from '../../constants/cookieNames';
import MyPrinterListView from './MyPrinterListView';
import MyPrinterCardView from './MyPrinterCardView';
import MyPrinterCardViewBlank from './MyPrinterCardViewBlank';
import bind from '../bind';
import { NavDot } from '../shared-components/atoms/NavDot';
import checkEntitlement from '../../utils/entitlement';

const printerBlacklistHasStuff = compose(
  gt(0),
  length,
  either(
    always([]),
    either(
      prop('blacklist'),
      path(['WPPData', 'blacklist']),
    ),
  ),
);

const isPrinterLimited = printer => (
  printer.printerIsProtected
  || (
    printer.printerCurrentAccess
    && printer.printerCurrentAccess !== 'open'
  )
  || (
    printerBlacklistHasStuff(printer)
  )
);

const getLocale = compose(
  head,
  match(/\/..\/../),
  path(['router', 'location', 'pathname']),
);

class MyPrinterViews extends Component {
  constructor(props) {
    super(props);
    this.on_resize = this.on_resize.bind(this);
    this.mapViewCards = this.mapViewCards.bind(this);
    this.initialize_card_swipe = this.initialize_card_swipe.bind(this);
    this.card_change_settings_fn = this.card_change_settings_fn.bind(this);
    this.card_keys = [];
    const n = this.props.numberOfCards;
    for (let i = n - 1; i >= 0; i--) {
      this.card_keys.unshift(`#card_${i}`);
    }
    this.dragStartX = 0;
    this.objInitLeft = 0;
    this.dragTarget = '';
    this.targetDestination = 50;
    this.direction = 0;
    this.item_position = null;
    this.mouse_is_down = false;
    this.card_width = 0;
    this.off_set_main = 0;
    // this.view_width = window.innerWidth // not used?
    this.reduced_scale = 0.85;
    this.last_id = '#card_0';
    this.state = { window_size: window.innerWidth };
    this.breakpoint = 639;
    this.is_desktop = this.state.window_size > this.breakpoint;
    this.navKeys = [];
  }

  componentWillUnmount() {
    // // THESE 2 LINES ARE TO RE-ENABLE MOUSE POINTER HIGHLIGHTING
    document.body.setAttribute('unselectable', 'off', 1);
    document.onselectstart = function () {
      return true;
    };
    window.removeEventListener('resize', this.on_resize);
  }

  componentDidMount() {
    window.addEventListener('resize', this.on_resize);
    // // THESE 2 LINES ARE TO PREVENT MOUSE POINTER HIGHLIGHTING
    document.body.setAttribute('unselectable', 'on', 0);
    document.onselectstart = function () {
      return false;
    };
    this.initialize_card_swipe();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.window_size != this.state.window_size) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    this.initialize_card_swipe();
  }

  cardWidthsAndPadding = () => this.props.numberOfCards * (Math.floor(this.state.window_size * 0.7) + 0); /// //////////////////////////////////

  initialize_card_swipe() {
    this.is_desktop = window.innerWidth > this.breakpoint;
    if (this.is_desktop === false && this.props.numberOfCards < 13 && this.props.myPrinters.isComplete === true) {
      if (document.getElementById('card_0') != null) {
        this.card_width = document.getElementById('card_0').offsetWidth;
      } else {
        return false;
      }
      this.off_set_main = Math.round((window.innerWidth - this.card_width) / 2);
      this.dragTarget = document.getElementById('swipe_contents');
      const position = `width:${this.cardWidthsAndPadding()}px;margin-left:${3000}px`; /// /////////////////////////////////
      this.dragTarget.setAttribute('style', position);
      this.item_position = (this.item_position === null) ? 0 : this.item_position;
      const here_id = this.card_keys[this.item_position];
      this.last_id = here_id;
      const shrunken = this.card_keys.filter(card => card != this.card_keys[this.item_position]);
      TweenLite.to(here_id, 1, { scale: 1 });
      TweenLite.to(shrunken, 1, { scale: this.reduced_scale });
      TweenLite.to('#swiper_container', 1, {
        scrollTo: { x: here_id, offsetX: this.off_set_main },
        onComplete: () => {
          this.setActionableDimensions(here_id);
        },
      });
      this.thumbUpdate();
    }
  }

  on_resize() {
    this.setState({ window_size: window.innerWidth });
  }

  /// ///////  EVENTS  /////////////////////////////////////////////////////////////////////////
  touchDown = event => {
    this.mouse_is_down = true;
    this.objInitLeft = this.dragTarget.offsetLeft;
    this.dragStartX = event.changedTouches[0].pageX;
  }

  touchMove = event => {
    if (this.mouse_is_down) {
      this.targetDestination = Math.round(this.objInitLeft + event.changedTouches[0].pageX - this.dragStartX);
      this.direction = event.changedTouches[0].pageX - this.dragStartX;
      const position = `width:${this.cardWidthsAndPadding()}px;margin-left:${this.targetDestination}px`;
      this.dragTarget.setAttribute('style', position);
    }
  }

  mouseDown = event => {
    this.mouse_is_down = true;
    this.objInitLeft = this.dragTarget.offsetLeft;
    this.dragStartX = event.pageX;
  }

  mouseMove = event => {
    if (this.mouse_is_down) {
      this.targetDestination = (this.objInitLeft + event.pageX - this.dragStartX);
      this.direction = event.pageX - this.dragStartX;
      const position = `width:${this.cardWidthsAndPadding()}px;margin-left:${this.targetDestination}px`;
      this.dragTarget.setAttribute('style', position);
    }
  }

  mouseUp = event => {
    event.preventDefault();
    if (this.mouse_is_down) {
      if (Math.abs(this.direction) > 30) {
        this.item_position += (this.direction < 0) ? 1 : -1;
      } else if (Math.abs(this.direction) < 6) {
        this.item_position += (this.dragStartX < 60) ? -1 : 0;
        this.item_position += (this.dragStartX > window.innerWidth - 60) ? 1 : 0;
      }
      this.item_position = this.item_position > 0 ? this.item_position : 0;
      this.item_position = this.item_position < this.props.numberOfCards - 1 ? this.item_position : this.props.numberOfCards - 1;
      this.tween_function();
    }
    this.direction = 0;
    this.mouse_is_down = false;
  }

  tween_function = () => {
    const here_id = this.card_keys[this.item_position];
    const time_animated = 0.6;
    const time_scaled = 0.4;
    if (this.last_id == here_id) {
      // MOVE TO CENTER
      const tween = TweenLite.to('#swiper_container', time_animated, { scrollTo: { x: here_id, offsetX: this.off_set_main + 0 } });
    } else {
      // ENLARGE TO 100%
      const tween_enlarge = TweenLite.to(here_id, time_scaled, { scaleX: 1, scaleY: 1 });
      // REDUCE SIZE OF OTHERS - THIS COVERS ALL CASES BETTER THAN let shrunken = this.last_id
      const shrunken = this.card_keys.filter(card_key => card_key != here_id);
      /// / let shrunken = this.last_id //// USE card_keys.filter INSTEAD
      const tween_shrink = TweenLite.to(shrunken, time_scaled, { scaleX: this.reduced_scale, scaleY: this.reduced_scale });
      // MOVE TO CENTER
      const tween_slide = TweenLite.to('#swiper_container', time_animated, {
        scrollTo: { x: here_id, offsetX: this.off_set_main + 20 },
        onComplete: () => {
          this.setActionableDimensions(here_id);
        },
      });
    }
    this.last_id = here_id;
    this.thumbUpdate();
  }

  setActionableDimensions = here_id => {
    const emailElement = document.getElementById(`${here_id.substr(1)}_email`);
    if (emailElement) {
      const gnbElement = document.getElementById('gnb-background');
      const gnbBox = gnbElement.getBoundingClientRect();
      const gnbBottom = gnbBox.bottom;
      const emailBox = emailElement.getBoundingClientRect();
      const emailBot = emailBox.bottom - gnbBottom;
      const swipeElement = document.getElementById('swipeActionable');
      const btnElement = document.getElementById(`${here_id.substr(1)}_changeSettings`);
      const btnBox = btnElement.getBoundingClientRect();
      const btnTop = btnBox.top - gnbBottom;
      const calculatedHeight = btnTop - emailBot;
      if (swipeElement) {
        swipeElement.setAttribute('style', `top:${emailBot}px;height:${calculatedHeight}px;`);
      }
    }
  }

  /// //////////////////
  thumbNav = e => {
    this.item_position = Number(e.target.id.split('_')[1]);
    this.tween_function();
  }

  thumbUpdate = () => {
    for (const id of this.navKeys) {
      document.getElementById(id).setAttribute('class', 'thumbNav--dot-inactive');
    }
    document.getElementById(this.navKeys[this.item_position]).setAttribute('class', 'thumbNav--dot-active');
  }

  generateThumbNav = () => {
    this.navKeys = [];
    return this.card_keys.map((myID, i) => {
      this.navKeys[i] = `navdot_${i}`;
      return (
        <NavDot
          ID={this.navKeys[i]} thumbNavFn={this.thumbNav}
          key={i} active={this.item_position}
        />
      );
    });
  }
  /// //////////////////

  card_change_settings_fn(iam, newPath, CD1, CD2) {
    const { history } = this.props;
    const printerInfoObj = { mod: CD1, name: CD2 };
    this.props.changeUserPrinterModel(printerInfoObj);
    const new_path = newPath;
    if (this.props.numberOfCards <= 12 && this.is_desktop != true) {
      if (`#${iam}` == this.card_keys[this.item_position]) {
        history.push(new_path);
      }
    } else {
      history.push(new_path);
    }
  }

  mapViewCards_card(csData, myPrintersWPP) {
    if (typeof myPrintersWPP !== 'object') {
      // console.warn("typeof myPrintersWPP: ", typeof myPrintersWPP, "- should be object")
      // console.warn("myPrintersWPP: " + myPrintersWPP)
      return false;
    }

    const locale = getLocale(this.props);
    const isBaseUser = () => {
      const useCookie = getCookie(USER_TYPE);
      return (useCookie) ? useCookie.toLowerCase() === 'base' : true;
    };

    return (
      myPrintersWPP.map(
        (printerObj, i) => {
          const perPrinter = printerObj.WPPData || printerObj;
          const pName = perPrinter.printerName || perPrinter.printerModel || 'My HP Printer';
          const isRegistered = perPrinter.isPrinterRegistered;
          let ePrintSupport = true;

          if (!isBaseUser() && perPrinter.generation === 2 && perPrinter.programInfo) {
            const { entitlementList } = perPrinter.programInfo;
            ePrintSupport = checkEntitlement(entitlementList, 'ws-hp.com/eprint');
          }
          // TODO: This logic should be moved to printer model
          let pStatusIndicator = '/assets/img/common/icon_printer_disconnected.svg';
          if (perPrinter.printerOnline) {
            if (perPrinter.printerStatus.toLowerCase() != 'ready' && perPrinter.printerStatus.toLowerCase() != 'online' || perPrinter.isPrinterRegistered === false) {
              pStatusIndicator = '/assets/img/common/icon_printer_problem.svg';
            } else {
              pStatusIndicator = '/assets/img/common/icon_printer_connected.svg';
            }
          }
          // ContentStack data for unregistered printeres:
          const registeredObj = isRegistered !== false ? { isRegistered } : {
            isRegistered,
            unregisteredTitle: csData.unregistered_printer.unregistered_printer_title,
            unregisteredSubtitle: csData.unregistered_printer.unregistered_printer_subtitle,
            unregisteredPrinterGraphic: csData.unregistered_printer.unregistered_printer_graphic,
            unregisteredSummary: csData.unregistered_printer.unregistered_printer_summary,
            unregisteredCardBtn: csData.unregistered_printer.unregistered_printer_card_button,
            unregisteredGraphicUrl: csData.unregistered_printer.unregistered_printer_graphic.url,
            unregisteredGraphicTitle: csData.unregistered_printer.unregistered_printer_graphic.title,
          };
          const isLimited = isPrinterLimited(perPrinter);
          const pStatusTitle = perPrinter.printerStatus;
          const btnText = csData.details.card_change_settings_button; // "Change Settings"
          // SUMMARY OBJECT CONSTRUCT :
          const summaries = [
            { Key: csData.details.card_access, Label: (isLimited) ? csData.details.card_limited : csData.details.card_open_to_anyone },
          ];
          if (perPrinter.generation === 1) {
            for (const key in perPrinter.preferences) {
              switch (key) {
                case 'color':
                  summaries.push({
                    Key: csData.details.card_color,
                    Label: perPrinter.preferences[key] === 'color'
                      ? csData.details.card_color
                      : csData.details.card_monochrome
                  });
                  break;
                case 'paper':
                  summaries.push({
                    Key: csData.details.card_paper,
                    Label: perPrinter.preferences[key] === 'plain'
                      ? csData.details.card_plain
                      : csData.details.card_photo
                  });
                  break;
                case 'sides':
                  summaries.push({
                    Key: csData.details.card_sides,
                    Label: perPrinter.preferences[key] === '1-sided'
                      ? csData.details.card_1_sided
                      : csData.details.card_2_sided
                  });
                  break;
              }
            }
          }
          // IMAGES OBJECT :
          const imgArray = [perPrinter.printerImageUrl300N245, perPrinter.printerImageUrl170N128, perPrinter.printerImageUrl130N102, perPrinter.printerImageUrl42N26];
          const imgAlt = `${perPrinter.printerName} image`;
          const card_id = `card_${i}`;

          const link_to = `${locale}/myprinter/${perPrinter.ownershipId}?generation=${perPrinter.generation}`;

          if (myPrintersWPP.length <= 12 || this.is_desktop) {
            return (
              <MyPrinterCardView
                myid={card_id}
                key={i}
                printerName={pName}
                link_url={link_to}
                change_settings_fn={this.card_change_settings_fn}
                cd1={perPrinter.printerModelNumber}
                cd2={pName}
                printerStatusIndicator={pStatusIndicator}
                printerStatusTitle={pStatusTitle}
                buttonText={btnText}
                ePrintSettings={csData.details.card_eprint_settings}
                ePrintSupport={ePrintSupport}
                summary={summaries}
                images={imgArray}
                imageAlt={imgAlt}
                pEmail={perPrinter.printerEmail}
                {...registeredObj}
              />
            );
          }
          return (
            <MyPrinterListView
              myid={card_id}
              key={i}
              printerName={pName}
              link_url={link_to}
              {...registeredObj}
              change_settings_fn={this.card_change_settings_fn}
              cd1={perPrinter.printerModelNumber}
              cd2={pName}
              printerSettingsIcon="/assets/img/common/icon_gear.png"
              printerSettingsIconTitle=""
              eprintAddress={perPrinter.printerEmail}
              printerStatusIndicator={pStatusIndicator}
              printerStatusTitle={pStatusTitle}
            />
          );
        },
      )
    );
  }

  mapViewCards(csData, myPrintersWPP) {
    this.is_desktop = window.innerWidth > this.breakpoint;
    const scStyles = { width: `${this.cardWidthsAndPadding()}px` };
    if (myPrintersWPP.length <= 12 && !this.is_desktop) {
      // MOBILE CARD VIEW WRAPPER IS DIFFERENT:
      return (
        <div className="myPrinterCardView--slidesContainer">
          <div
            id="swipeActionable" className="myPrinterCardView--eventReceptor"
            onTouchStart={this.touchDown}
            onTouchMove={this.touchMove}
            onTouchEnd={this.mouseUp}
            onMouseDown={this.mouseDown}
            onMouseMove={this.mouseMove}
            onMouseUp={this.mouseUp}
            onMouseOut={this.mouseUp} key="unique_key_swipeActionable"
          />
          <div id="guardrail_left" className="guardrail" />
          <div id="guardrail_right" className="guardrail" />
          <div id="swiper_container" className="myPrinterCardView--slider ">
            <div
              id="swipe_contents" className="myPrinterCardView--slides"
              style={scStyles}
            >
              {this.mapViewCards_card(csData, myPrintersWPP)}
              {this.props.numberOfCards > 1 && <MyPrinterCardViewBlank myid={`card_${this.props.numberOfCards}`} />}
            </div>
          </div>
          <div className="thumbNav">{this.generateThumbNav()}</div>
        </div>
      );
    } if (!this.is_desktop) {
      // THIS IS FOR THE LIST VIEW
      return (
        <div id="list_views">
          <div id="swiper_container_list">
            <div id="swipe_contents_list" className="myPrinterCardView--listSlides myPrinters">
              {this.mapViewCards_card(csData, myPrintersWPP)}
            </div>
          </div>
        </div>
      );
    } if (this.is_desktop) {
      return (
        <div id="desktop_views" className="myPrinters">
          {this.mapViewCards_card(csData, myPrintersWPP)}
        </div>
      );
    }
    <div />;
  }

  render() {
    return (
      <div id="GSAP_view_container">
        {this.mapViewCards(this.props.csData, this.props.WPPdata)}
      </div>
    );
  }
}

export default bind(MyPrinterViews);
