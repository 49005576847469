import React from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
} from '@veneer/core';
import { Link } from '../../../../routing';
import { selectRootMatch } from '../../../../../selectors/routing';
import ServiceItem from './ServiceItem';
import i18n from '../../../utils/i18n';
import { SERVICES } from '../../../utils/constants';
import {
  entitlements,
  handleCardsToShow,
  myPrintersURLs,
} from '../entitlementsHandling';
import selectUcdePortalElements from '../../../store/modules/portalElements/selectors';
import selectCurrentStack from '../../../store/modules/environment/selectors';
import {
  CardContainer,
  CardHeaderBenefits,
  CardHeaderOtherFeatures,
} from '../../shared-components/styles';

const Services = () => {
  const { url } = useSelector(selectRootMatch);
  const ucdePortalElements = useSelector(selectUcdePortalElements);
  const stack = useSelector(selectCurrentStack);
  const {
    info: {
      role,
    },
    services: { cards },
  } = ucdePortalElements;

  let benefits = [];
  let otherFeatures = [];

  Object.entries(cards).forEach(([key, card]) => {
    const { level, state } = card;

    if (handleCardsToShow({ role, key })) {
      const {
        shortName, enabledStatus, disabledStatus, link,
      } = entitlements[key];

      const newLink = (key === SERVICES.KEY_EPRINT)
        ? myPrintersURLs[stack]
        : `${url}${link}`;

      const isStatePublished = (state === SERVICES.STATE_PUBLISHED);
      // If entitlement is enabled, there is no link to go
      const status = isStatePublished ? disabledStatus : enabledStatus;

      if (level === SERVICES.HPPLUS_PROGRAM_LEVEL) {
        benefits = [...benefits, {
          name: shortName,
          status,
          link: isStatePublished ? newLink : null,
        }];
      } else {
        otherFeatures = [...otherFeatures, {
          name: shortName,
          status,
          link: isStatePublished ? newLink : null,
        }];
      }
    }
  });

  const benefitsList = benefits.map(({
    name, status, link,
  }) => (
    <ServiceItem
      name={name}
      status={status}
      link={link}
    />
  ));

  const otherFeaturesList = otherFeatures.map(({
    name, status, link,
  }) => (
    <ServiceItem
      name={name}
      status={status}
      link={link}
    />
  ));

  const CardContent = () => (
    <CardContainer
      data-testid="services-card"
    >

      {benefitsList && benefitsList.length > 0 && (
        <>
          <CardHeaderBenefits>
            <h5>{i18n.t('home.benefitsStatus.benefitsTitle')}</h5>
            <Link to={`${url}/services/summary`}>{i18n.t('home.benefitsStatus.otherFeaturesExplore')}</Link>
          </CardHeaderBenefits>

          {benefitsList}
        </>
      )}

      {otherFeaturesList && otherFeaturesList.length > 0 && (
        <>
          <CardHeaderOtherFeatures>
            <h5>{i18n.t('home.benefitsStatus.otherFeatures')}</h5>
          </CardHeaderOtherFeatures>

          {otherFeaturesList}
        </>
      )}
    </CardContainer>
  );

  return (
    <Card
      appearance="dropShadow"
      content={CardContent()}
    />
  );
};

export default Services;
