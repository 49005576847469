import styled from 'styled-components';
import Button from '@veneer/core/dist/scripts/button';
import { breakpoints, stylesIf } from '../../../utils/styleHelpers';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'hp-simplified', Arial, sans-serif;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  box-sizing: border-box;
  max-width: 90vw;
  padding-top: 1rem;
  padding-bottom: 0;
  margin: 0 5vw;
  @media screen and (min-width: ${breakpoints.mobile}) {
    margin: auto;
    min-height: calc(100vh - 50vw);
  }
  @media screen and (min-width: ${breakpoints.tablet}) {
    min-height: calc(100vh - 10vw);
  }
  > * {
    text-align: center;
    :first-child {
      padding-top: 0;
    }
  }

  @media only screen and (min-width: ${breakpoints.desktop}) and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.wide}) {
    font-size: 0.875rem;
    padding: 0 1rem;
    max-width: 64rem;
    min-height: calc(100vh - 20vh);
    margin-top: 4vh;
    margin-bottom: 0;
  }
`;

// This was added to fix the styling as per design
export const ImageAndContentContainer = styled.div`
  display: block;

  @media only screen and (min-width: ${breakpoints.desktop}) and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    -ms-flex-direction: column;
    > img {
      height: 30%;
      width: 30%;
      align-self: center;
    }
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    flex-grow: 0;
    padding-top: 5vw;
    padding-bottom: 1rem;
    display: inline-flex;
  }

  @media only screen and (min-width: ${breakpoints.wide}) {
    -ms-flex-direction: row;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    display: block;
  }

  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    display: block;
  }
`;

export const Body = styled.div`
  font-family: 'hp-simplified', Arial, sans-serif;

  && {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }

    @media only screen and (min-width: ${breakpoints.tablet}) {
      max-width: 44.5rem;
      display: inline-block;
      text-align: ${(props) => (props.isRTL ? 'right' : 'left')};
    }
    @media only screen and (min-width: ${breakpoints.desktop}) and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      max-width: 39.5rem;
    }

    @media only screen and (max-width: ${breakpoints.tablet}) {
      max-width: 34.5rem;
      padding-top: 1rem;
      display: block;
      text-align: center;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      max-width: 34.5rem;
      padding-top: 1rem;
      display: block;
      text-align: center;
    }

    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
      max-width: 34.5rem;
      padding-top: 1rem;
      display: block;
      text-align: center;
    }

    padding: 0;
    width: 100%;
    font-size: 1rem;
    line-height: 1.25;
    color: #4e4e4e;
    p,
    > span > p {
      margin: 1rem 0;
      > a {
        color: #027aae;
        :hover {
          color: #006691;
        }
        :active {
          color: #004f74;
        }
      }
    }
  }
`;

export const Title = styled.h4`
  font-family: 'hp-simplified', Arial, sans-serif;
  color: #313131;
  font-style: normal;
  font-weight: 400;
  font-size: 1.01rem;
  line-height: 1.4;
  text-align: center;
`;

export const SubTitle = styled.h4`
  font-family: 'hp-simplified', Arial, sans-serif;
  width: auto;

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding-top: 1rem;
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    margin-bottom: 1rem;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: 100%;
    padding-top: 5rem;
    text-align: ${(props) => (props.isRTL ? 'right' : 'left')};
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    width: auto;
  }

  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    width: auto;
  }

  && {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }
    line-height: 1.4;
    font-weight: 500;
    font-size: 1.375rem;
    color: #4e4e4e;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding-bottom: 1rem;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    padding-top: 1rem;

    & > button:not(:first-of-type) {
      margin-right: 0;
    }

    & > button:last-child {
      margin-bottom: 0;
    }

    & > button {
        margin-left: 0;
        margin-bottom: 0.625rem;
    } 
  }
  
  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 8rem;
    flex-direction: row-reverse;
    width: 100%;

    & > button:first-of-type {
        margin-left: ${(props) => (props.isRTL ? '0' : '1rem')};
        margin-right: ${(props) => (props.isRTL ? '1rem' : '0')};
    }

    & > button {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: ${
    breakpoints.desktop
  }) and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding-top: 10px;
  }

  @media screen and (min-width: 500px) and (max-width: 500px) and (min-height: 530px ) and (max-height: 530px) {    
    width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
    align-items: center;
    flex-direction: row-reverse;
    margin-top: 1.5rem;
    margin-bottom: 1rem;

    & > button:not(:first-of-type) {
      margin-right: 1rem;
    }

    & > button {
      margin-bottom: 0;
    }
  }

  
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    flex-direction: column;
    padding-top: 1rem;

    & > button:first-of-type {
      margin-left: 0;
    }

    & > button {
        margin-left: 0;
        margin-bottom: 0.625rem;
    }
  }

  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    flex-direction: column;
    padding-top: 1rem;

    & > button:not(:first-of-type) {
      margin-right: 0;
    }

    & > button:first-of-type {
        margin-left: 0;
    }

    & > button {
      margin-bottom: 0.625rem;
    }
  }
}
`;

export const StyledButton = styled(Button)`
  && {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }
    min-height: ${stylesIf('small', '2.25', '2.875')}rem;
    ${stylesIf('small', 'border-radius: 0.5rem;')}
    ${stylesIf(
      'hasBody',
      `
        @media screen and (min-width: 25.875rem) {
          margin-top: 1rem;
        }
      `
    )}
  }
`;

export const Graphic = styled.img`
  @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
    margin-left: 50%;
    transform: translate(-50%);
  }
  height: auto;
  width: auto;

  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding-bottom: 0.8rem;
    text-align: center;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    padding-bottom: 0.8rem;
    text-align: center;
  }

  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    padding-bottom: 0.8rem;
    text-align: center;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    text-align: left;
    ${(props) => (props.isRTL ? `margin-left: 4rem` : `margin-right: 4rem`)};
  }
`;
