import React from 'react';
import styled from 'styled-components';
import ImageAndBrief from './ImageAndBrief';
import { paths } from '../../../utils/styleHelpers';
import { useSelector } from '../../../hooks';
import { selectVisualNavElements } from '../../../selectors/home';

const VisualNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  > div {
    padding: 3.75rem 2.25rem;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  @media screen and (min-width: ${paths.breakWide}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 12.75rem 0;
    > div {
      padding: initial;
    }
    margin-left: ${props => props.isRTLSupport && '5rem'};
  }
  @media screen and (min-width: ${paths.breakImmense}) {
    margin-right: ${props => props.isRTLSupport && '10rem'};
  }
  @media screen and (min-width: ${paths.breakImmensewide}) {
    max-width: 120rem;
    margin-left: ${props => (props.isRTLSupport ? 'calc((100vw - 150rem) / 2)' : 'calc((100vw - 120rem) / 2)')};
    margin-right: ${props => props.isRTLSupport && '25rem'};
  }
`;

const VisualNav = ({ refProp, isRTLSupport }) => {
  const elements = useSelector(selectVisualNavElements);
  return (
    <VisualNavContainer ref={refProp} isRTLSupport={isRTLSupport}>
      {
        elements.map(({
          nav_section
        }, index) => {
          const {
            header: title,
            body: copy,
            button_text: buttonText,
            button_destination: buttonDestination,
            icon
          } = nav_section;

          const {
            uid: buttonId,
            url: src,
            title: alt,
          } = icon;
           
          const identifier = title
            .toLowerCase()
            .replace(' ', '-');
          const id = `${identifier}-${index}`;
          return (
            <ImageAndBrief
              key={id}
              id={id}
              src={src}
              alt={alt}
              title={title}
              copy={copy}
              buttonId={buttonId}
              buttonText={buttonText}
              buttonDestination={buttonDestination}
              isRTLSupport={isRTLSupport}
            />
          );
        })
      }
    </VisualNavContainer>
  );
};

export default VisualNav;
