import React from "react";
import { TemplateSection } from '../shared-styles/TemplateStyles';
import SectionHeadIcon from "./../molecules/sectionHeadIcon";
import AppStoreBadges from "./../molecules/AppStoreBadges";

const AppPromo = props => {
  const { printerCommon = {}, ID } = props;
  let sectionHeadIcon, sectionHeadPitch = "";

  if (printerCommon) {
    sectionHeadIcon = (
      <SectionHeadIcon
        sectionHeading={printerCommon.app_promotion_title}
        sectionIcon={printerCommon.app_promotion_icon?.url}
        sectionIconAlt={printerCommon.app_promotion_icon?.title}
      />
    );
    sectionHeadPitch = printerCommon.app_promotion_pitch;
  }
  return (
    <TemplateSection id="get-app">
      {sectionHeadIcon}
      <div className="appPromo--pitch" dangerouslySetInnerHTML={{ __html: sectionHeadPitch }} />
      <AppStoreBadges ID={ID} />
    </TemplateSection>
  );
}

export default AppPromo;