import ActionTypes from "../constants/ActionTypes";
import fetchStatus from "./fetchStatus";

const initialState = {
    ...fetchStatus(),
    data: {}
}

function config(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.START_CONFIG_FETCH:
            return {
                ...fetchStatus(state, Object.assign({}, action, {type: ActionTypes.START_FETCH})),
            };

        case ActionTypes.COMPLETE_CONFIG_FETCH:
            return {
                ...fetchStatus(state, Object.assign({}, action, {type: ActionTypes.COMPLETE_FETCH})),
                data: action.config
            };

        case ActionTypes.COMPLETE_CONFIG_WITH_ERROR:
            return {
                ...fetchStatus(state, Object.assign({}, action, {type: ActionTypes.COMPLETE_FETCH_WITH_ERROR})),
                errorMsg: action.err.message
            };
    }

    return state;
}

export default config;