import React from 'react';
import { userOSDetector } from '../../utils/userOSDetector';
import AddPrinters from './AddPrinters';
import InstallDrivers from './InstallDrivers/index.js';
import InstallHPSmart from './InstallHPSmart';

const SMBDownload = (props) => {
  try {
    const userOperatingSystem = userOSDetector();

    if (!!userOperatingSystem) {
      if (userOperatingSystem === 'Windows 7') return <InstallDrivers />;
      else if (userOperatingSystem === 'Chrome OS') return <AddPrinters />;
      else return <InstallHPSmart {...props} />;
    }
  } catch (error) {
    generalLog.error('Error in SMB download component. Find details below- ');
    generalLog.error(error);
  }
  return null;
};

export default SMBDownload;
