import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
  Select,
  TextBox,
  Button,
} from '@veneer/core';
import { Title as SharedTitle } from '../../shared-components/styles';

export const FormInput = styled(TextBox)`
  margin-bottom: 16px;
  ${props => props.helperText
    && `
    transition: 0.25s ease-out;
    margin-bottom: 0;

    > p {
      padding-top: 0;
    }

    &:focus-within {
      margin-bottom: 16px;
      > p {
        padding-top: 4px;
      }
    }
  `}
  ${props => props.error
    && `
    margin-bottom: 16px;

    > p {
      padding-top: 4px;
    }
  `}
`;

export const FormSelect = styled(Select)`
  margin-bottom: 16px;
`;

export const FormButton = styled(Button)`
  margin-right: 8px;
  margin-top: 16px;
  background: none;

  @media (max-width: ${props => props.theme.breakPoints.lg}) {
    margin-bottom: 16px;
  }
`;

export const Form = styled.form`
  max-width: 288px;
`;

export const Container = styled.div`
  flex-grow: 1;
`;

export const Title = styled(SharedTitle)`
  margin-bottom: ${isMobile ? '32px' : '10px'};
`;

export const ExternalLink = styled.a`
  font-weight: bold;
`;

export const PaddedDiv = styled.div`
  padding-bottom: 24px;
`;
