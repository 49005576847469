import styled from 'styled-components';
import { breakpoints } from '../../../utils/styleHelpers';

export const Container = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto;
  min-height: 96vh !important;
  @media only screen and (max-width: ${breakpoints.smalldesktop}) {
    padding: 1.25rem 0.25rem;
  }
  @media screen and (min-width: ${breakpoints.wide}) {
    font-size: 0.875rem;
    max-width: 64rem;
    min-height: calc(100vh - 20vh);
    margin-top: 4vh;
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  flex-shrink: 2;
  text-align: center;
  display: flex;
  img {
    display: none;
    @media only screen and (min-width: ${breakpoints.wide}) {
      margin-right: 120px;
      display: block;
    }
  }
  @media only screen and (min-width: ${breakpoints.tablet}) {
    text-align: left;
    padding: 2.5rem 0;
  }
`;

export const SelectPrinterModel = styled.p`
  margin-left: 0.25rem;
  font-size: 1rem;
  margin-bottom: 0rem;
  text-align: left;
  color: #404040;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 60px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  left: 0px;
  padding: 2.125rem 3.5rem;
  @media only screen and (max-height: ${breakpoints.smalldesktop}) {
    bottom: 0px;
  }
`;

export const SelectPrinter = styled.div`
  width: 100%;
  z-index: 999;
`;
