import React from 'react';
import i18n from '../../utils/i18n';
import {
  Container,
  EcoCard,
  EcoCardContent,
} from './styles';

const ForestFriendlyBanner = () => (
  <Container
    data-testid="eco-card"
  >
    <EcoCard>
      <EcoCardContent>
        <h5>{i18n.t('home.ecoMessage.header')}</h5>
        <p>{i18n.t('home.ecoMessage.copy')}</p>
      </EcoCardContent>
    </EcoCard>
  </Container>
);

export default ForestFriendlyBanner;
