import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  StatusContainer,
  StatusDetails,
  StatusImage,
} from './styles';

const StatusItem = (({
  description, Image, status, Icon, iconColor, redirect,
}) => (
  <StatusContainer>
    <StatusImage>
      <img src={Image} alt="" />
    </StatusImage>
    <StatusDetails>
      <Link to={redirect}>{description}</Link>
      <div>
        <Icon
          size={24}
          filled
          color={iconColor}
        />
        <span>{status}</span>
      </div>
    </StatusDetails>
  </StatusContainer>
));

StatusItem.propTypes = {
  description: PropTypes.string.isRequired,
  Image: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  Icon: PropTypes.instanceOf(Object).isRequired,
  iconColor: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
};

export default StatusItem;
