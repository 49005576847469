import {
  path,
  compose,
  includes,
  propOr,
} from 'ramda';
import { createSelector } from 'reselect';

const sharingModeHasAutoString = compose(
  includes('auto'),
  propOr('', 'sharingMode'),
);

const SELECTORS_BASE = {
  selectPrintAnywhereCsData: path(['printAnywhere', 'data', 'csData']),
  selectPrintAnywhereWPP: path(['printAnywhere', 'data', 'WPPData']),
  selectPrintAnywhereWPPPrinterId: path(['printAnywhere', 'data', 'WPPData', 'printerId']),
};

const selectGlobalSharingCsData = createSelector(
  SELECTORS_BASE.selectPrintAnywhereCsData,
  csClass => ({
    printAnywhereShareText: csClass.print_anywhere_share_text,
    printAnywhereMoreText: csClass.print_anywhere_more_text,
  }),
);

const selectGlobalSharingAccessData = createSelector(
  SELECTORS_BASE.selectPrintAnywhereWPP,
  sharingModeHasAutoString,
);

export const selectGlobalSharingData = createSelector(
  selectGlobalSharingCsData,
  SELECTORS_BASE.selectPrintAnywhereWPPPrinterId,
  selectGlobalSharingAccessData,
  ({ printAnywhereShareText, printAnywhereMoreText }, printerId, sharingModeHasAuto) => ({
    printAnywhereShareText,
    printAnywhereMoreText,
    printerId,
    sharingModeHasAuto,
  }),
);

export const selectAddNewEmailCsData = createSelector(
  SELECTORS_BASE.selectPrintAnywhereCsData,
  csClass => ({
    printAnywhereChooseText: csClass.print_anywhere_choose_text,
    printAnywhereUserEmailText: csClass.print_anywhere_user_email_text,
    printAnywhereAddButtonText: csClass.print_anywhere_add_button_text,
    printAnywhereEmailErrorText: csClass.print_anywhere_email_error_text,
    printAnywhereDuplicateEmailErrorText: csClass.print_anywhere_duplicate_email_error_text,
  }),
);
