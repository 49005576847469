import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import OrigamiPricing from '../OrigamiPricing';
import SkeletonPricing from '../Skeleton/SkeletonPricing';
import { StyledSection } from '../styles/StyleOrigami';
import { SkeletonContainer } from '../styles/StyleSkeleton';

const propTypes = {
  pricingHeader: PropTypes.string.isRequired,
  pricingSubhead: PropTypes.string.isRequired,
  pricingDetail: PropTypes.array.isRequired
};

const defaultProps = {
  pricingHeader: '',
  pricingSubhead: '',
  pricingDetail: []
};

const OrigamiPricingContent = (props) => {
  const { pricingHeader, pricingSubhead, pricingDetail } = props;
  return (
    <StyledSection>
      <OrigamiPricing
        pricingHeader={pricingHeader}
        pricingSubhead={pricingSubhead}
        pricingDetail={pricingDetail}
      />
    </StyledSection>
  );
};

const OrigamiPricingPromise = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          default: (props) => {
            const { pricingHeader, pricingSubhead, pricingDetail } = props;
            return (
              <OrigamiPricingContent
                pricingHeader={pricingHeader}
                pricingSubhead={pricingSubhead}
                pricingDetail={pricingDetail}
              />
            );
          }
        });
      }, 1000);
    })
);

const OrigamiLazyPricing = (props) => {
  const { pricingHeader, pricingSubhead, pricingDetail } = props;
  return (
    <Suspense
      fallback={
        <SkeletonContainer>
          <SkeletonPricing />
        </SkeletonContainer>
      }
    >
      <OrigamiPricingPromise
        pricingHeader={pricingHeader}
        pricingSubhead={pricingSubhead}
        pricingDetail={pricingDetail}
      />
    </Suspense>
  );
};

OrigamiLazyPricing.propTypes = propTypes;

OrigamiLazyPricing.defaultProps = defaultProps;

export default OrigamiLazyPricing;
