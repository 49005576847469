import axios from 'axios';
import ActionTypes from '../constants/ActionTypes';
import Config from '../config/Config';
import { PRINTERS_LIST_RESOURCE_NAME } from '../constants/contentStackResources';
import { getContentStackData } from '../services/craft';


export function myPrintersAltered() {
  return {
    type: ActionTypes.MYPRINTERS_ALTERED,
  };
}

/**
 * This function uses axios to get printer list.
 *
 * Since axios returns promises, we can dispatch our start event and
 * update it to complete or complete with error whe the promise returns.
 *
 * @returns {function(*)}
 */
export function getMyPrinters(currentLocale = '') {
  const start = () => ({
    type: ActionTypes.START_MYPRINTERS_FETCH,
  });
  const complete = myPrintersData => ({
    type: ActionTypes.COMPLETE_MYPRINTERS_FETCH,
    myPrintersData,
  });
  const error = err => ({
    type: ActionTypes.COMPLETE_MYPRINTERS_WITH_ERROR,
    err,
  });
  return dispatch => new Promise((resolve, reject) => {
    const config = new Config();

    dispatch(start());
    function getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie != '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) == (`${name}=`)) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          }
        }
      }
      return cookieValue;
    }

    const axium = [];
    const csrfToken = getCookie('hpc3-csrf-token');
    const loggedIn = getCookie('hpc3-session-type') === 'user';

    if (!loggedIn) {
      getContentStackData(PRINTERS_LIST_RESOURCE_NAME, currentLocale.replace('_', '-'))
        .then(res => {
          const myPrintersData = {
            csData: res?.data?.entries.length > 0 ? res?.data?.entries[0] : {},
            WPPData: [],
          };
          dispatch(complete(myPrintersData));
          resolve(myPrintersData);
        });
      return;
    }
    const MyPrintersRequests = [
      {
        url: `${config.Get('HPC_GATEWAY_URL')}/printers`,
        withCredentials: true,
        headers: {
          'x-hpc3-csrf-token': csrfToken,
        },
        timeout: 1000 * 60 * 5,
      },
    ];
    axium.push(getContentStackData(PRINTERS_LIST_RESOURCE_NAME, currentLocale.replace('_', '-')));
    MyPrintersRequests.forEach(request => {
      axium.push(axios.request({ method: 'GET', ...request }));
    });
    axios.defaults.timeout = 1000 * 60 * 5;
    axios.all(axium)
      .then(responses => {
        const processedPrinters = [];
        if (responses[1].data.length > 0) {
          const wPPPrinters = responses[1].data;
          wPPPrinters.forEach(thisprinter => {
            if (thisprinter.printerId === '' && thisprinter.printerModelNumber === '' && thisprinter.printerSerialNumber === '') {
              thisprinter.printerName = 'HP Printer';
            }
            processedPrinters.push(thisprinter);
          });
        }

        const myPrintersData = {
          csData: responses[0]?.data?.entries.length > 0 ? responses[0].data.entries[0] : {},
          WPPData: processedPrinters,
        };
        dispatch(complete(myPrintersData));
        return resolve(myPrintersData);
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }
        dispatch(error(err));
        reject(err);
      });
    axios.defaults.timeout = 0;
  });
}
