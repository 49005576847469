export const CAREPACK = 'ff_carepack_enabled';
export const PRINTANYWHEREWEB = 'ff_printAnywhereWeb_enabled';
// ^^ refers to specific components, not entire pages/routes

export const TANGO = 'ff_tango_enabled';
// ^^ refers to specific product page. Tango product page will not need to be
// turned off, but other product pages may need to be.

export const PRINTANYWHERE = 'ff_printanywhere_enabled';
export const UCDE = 'ff_ucde_enabled';

export const HPPLUS = 'ff_hpplus_enabled';

export const HP_PLUS_SECOND_OFFER = 'ff_hp_plus_second_offer';
