import styled from 'styled-components';
import { stylesIf } from '../../../../../utils/styleHelpers';
import { UCDEMainTheme } from '../../../pages/Dashboard/styles';

export const ErrorContainer = styled.div`
  display: flex;
  flex-flow: ${props => props.direction} nowrap;
  align-items: center;
  justify-content: ${props => (props.direction === 'row' ? 'flex-start' : 'space-evenly')};
  text-align: ${props => (props.direction === 'row' ? 'left' : 'center')};
  height: 100%;
  width: 100%;
  ${props => (props.direction === 'row' ? 'min-height: 100px;' : '')}
  ${props => (props.direction === 'row' ? 'margin-left: 50px;' : '')}
  ${stylesIf('largerText', 'padding: 32px;')}

  > div {
    ${props => (props.direction === 'row' ? 'margin-left: 10px;' : '')}
  }
  
  @media (max-width: ${UCDEMainTheme.breakPoints.md}) {
    ${props => (props.direction === 'row' ? 'margin-left: auto;' : '')}
    ${stylesIf('largerText', 'padding: 16px;')}
  }
`;

export const ErrorTitle = styled.div`
  font-size: ${stylesIf('largerText', '24px', '14px')};
  font-family: ${UCDEMainTheme.main.hp.fontFamily};
  margin-top: 16px;
  color: #212121;

  @media (max-width: ${UCDEMainTheme.breakPoints.md}) {
    font-size: 14px;
  }
`;

export const ErrorMessage = styled.div`
  font-size: ${stylesIf('largerText', '16px', '12px')};
  color: #212121;

  @media (max-width: ${UCDEMainTheme.breakPoints.md}) {
    font-size: 12px;
  }
`;
