import React from 'react';
import styled from 'styled-components';
import { TweenLite, Power2 } from 'gsap';
import { useSelector } from '../../hooks';
import { selectMobileFaxHeroMessageData } from '../../selectors/mobileFax';
import Button from '../shared-components/atoms/Button';
import { getHtmlDir } from '../../utils/globals'; 
import { RTL } from '../../constants/i18n';
import { RIGHT, LEFT } from '../../constants/common';

const HeroContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (min-width:${props => props.theme.small}) {
    flex-direction: row;
    margin: 0 auto;
  }
  @media screen and (min-width:${props => props.theme.xlarge}) {
    margin-bottom: 2.5rem;
  }
`;
const PictureElement = styled.picture`
  height: 100%;
  @media screen and (min-width:${props => props.theme.small}) {
    width: 78%;
    margin-left: auto;
  }
  @media screen and (min-width:${props => props.theme.large}) {
    width: 73%;
  }
`;
const BaseImage = styled.img`
  width: 100%;
  flex: 1 1 100%;
  position: relative;
`;
const HeroMessage = styled.div`
  && {
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: -9rem 10% 0;
    flex: 1 1 auto;
    padding: 2rem 2.125rem 0;
    @media screen and (min-width:${props => props.theme.small}) {
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-53%);
      left: 0;
      align-items: flex-start;
      max-width: 24rem;
      text-align: ${ props => props.direction === RTL ? RIGHT : LEFT };
      padding: 3.5rem 3rem 3.625rem 2.25rem;
      img { display: none; }
    }
    @media screen and (min-width:${props => props.theme.medium}) {
      padding: 3.75rem 4rem 3.75rem 2.25rem;
      max-width: 29rem;
    }
    @media screen and (min-width:${props => props.theme.large}) {
      max-width: 35rem;
    }
    @media screen and (min-width:80rem) {
      left: 50%;
      transform: translate(-40.875rem,-53%);
    }
    @media screen and (min-width:${props => props.theme.xlarge}) {
      padding: 4.375rem 4.875rem 4.875rem 2.25rem;
    }
    background-color: white;
    > * {
      flex: 1 1 auto;
    }
    h2 { 
      font-weight: 400;
      margin: 0 0 .4375rem;
      width: 100%;
      @media screen and (max-width:${props => props.theme.small}) {
        margin: 0 0 .75rem;
        font-size: 1.625rem;
        line-height: 1.125;
      }
      @media screen and (min-width:${props => props.theme.large}) {
        margin: 0 0 1.375rem;
      }
    }
    p { 
      font-size: 1.125rem;
      margin-bottom: 1.5rem;
      width: 100%;
      @media screen and (min-width:${props => props.theme.small}) {
        margin-bottom: 1.625rem;
        font-size: 1.375rem;
      }
      @media screen and (min-width:${props => props.theme.large}) {
        line-height: 1.5 !important;
        margin-bottom: 2;
        font-size: 1.5rem;
      }
      margin-top: 0;
    }
    img { margin-top: 0.875rem; }
  }
`;
const TextLink = styled.a`
  && {
    ${props => props.theme.boldFontFamilyAndWeight}
    font-size: 1rem;
    margin-top: 1rem;
    text-align: left;
    &:hover, &:focus {
      color: ${props => props.theme.hpBlue};
    }
    @media screen and (max-width:${props => props.theme.small}) {
      width: 14.5rem;
      text-align: center;
    }
    @media screen and (max-width:${props => props.theme.medium}) {
      width: 16rem;
    }
  }
`;

const HeroMessageComponent = props => {
  const selectorProps = useSelector(selectMobileFaxHeroMessageData);
  const direction = getHtmlDir();

  if (!selectorProps) {
    return null;
  }
  const {
    scrollToFn,
    imageLoadComplete,
    backLinkTracking,
  } = props;
  const {
    hero_button_text,
    hero_headline_text,
    hero_subhead_text,
    hero_button_id,
    close_link_text,
    close_link_id,
    button_destination,
    signin_link,
  } = selectorProps;

  return (
    <HeroMessage id="heroMessage" direction={direction}>
      <h2>{hero_headline_text}</h2>
      <p>{hero_subhead_text}</p>
      {imageLoadComplete
        ? (
          <Button
            buttonType="primary"
            buttonText={hero_button_text}
            buttonID={hero_button_id}
            onClick={button_destination ? undefined : scrollToFn}
            buttonLink={button_destination}
            tabIndex="0"
            softButton
            widthOverride="10rem"
          />
        ) : (
          <Button
            buttonType="primary"
            buttonText={hero_button_text}
            buttonID={hero_button_id}
            onClick={null}
            buttonLink={null}
            tabIndex="0"
            softButton
            widthOverride="10rem"
          />
        )
      }
      {
        signin_link && (
          <TextLink
            href={signin_link}
            onClick={backLinkTracking}
            tabIndex="0"
            id={close_link_id}
          >
            {close_link_text}
          </TextLink>
        )
      }
      <img src="/assets/landing-page/icon_chevron.svg" alt="" />
    </HeroMessage>
  );
};

export default class LandingPageHero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heroLoaded: false,
    };
    this.scrollToFn = this.scrollToFn.bind(this);
    this.extToWebP = this.extToWebP.bind(this);
  }

  scrollToFn = () => {
    TweenLite.to(window, 1.25, {
      scrollTo: '#get-the-app',
      autoKill: false,
      ease: Power2.easeOut,
      // onComplete: completeFn
    });
    this.tracking('getStarted');
  }

  imageLoadComplete = () => {
    this.props.onReadyFn();
    this.setState({ heroLoaded: true });
  }

  followDestination = () => {
    // add analytics here
    const link = this.props.buttonDestination ? this.props.buttonDestination : '#';
    window.location.href = link;

    this.tracking('getStarted');
  };

  tracking = ID => {
    ID = ID.indexOf('#') === 0 ? ID.substr(1) : ID;
    document.getElementById(`mobilefax_tracking_hero_${ID}`).click();
  }

  extToWebP = fileNameAndPath => {
    let trimmedString;
    if (fileNameAndPath != undefined) {
      trimmedString = `${fileNameAndPath.slice(0, -4)}.webp`;
    } else {
      trimmedString = null;
    }
    return trimmedString;
  }

  srcOptions = (fileNameAndPath, fileNameAndPath2x, mediaDef) => {
    const webPVersion = this.extToWebP(fileNameAndPath);
    const webPVersion2x = this.extToWebP(fileNameAndPath2x);
    const webPSrc = (webPVersion != null) && (webPVersion2x != null) && (
    <source
      type="image/webp"
      key={`webPSrc(${mediaDef})`}
      media={`(${mediaDef})`}
      srcSet={`${webPVersion}, ${webPVersion2x} 2x`}
    />
    );
    const standardSrc = (
      <source
        type="image/jpeg"
        key={`standardSrc(${mediaDef})`}
        media={`(${mediaDef})`}
        srcSet={`${fileNameAndPath}, ${fileNameAndPath2x} 2x`}
      />
    );
    return [standardSrc];
  }

  render() {

    return (
      <HeroContainer>
        {
          this.props.heroImages.map((image, i) => (
            <PictureElement key={`pictureElement${i}`}>
              {this.srcOptions(image.heroImageLargeUrl, image.heroImageLarge2xUrl, 'min-width: 1024px')}
              {this.srcOptions(image.heroImageMediumUrl, image.heroImageMedium2xUrl, 'min-width: 641px')}
              {this.srcOptions(image.heroImageSmallUrl, image.heroImageSmall2xUrl, 'max-width: 640px')}
              <BaseImage
                src={image.heroImageLargeUrl} 
                //alt={image.heroImageLargeTitle}
                onLoad={this.imageLoadComplete}
              />
            </PictureElement>
          ))
        }

        <HeroMessageComponent
          scrollToFn={this.scrollToFn}
          imageLoadComplete={this.state.heroLoaded}
          backLinkTracking={() => this.tracking('noThanks')}
        />
      </HeroContainer>
    );
  }
}