import React, { Component } from 'react';
import AccordionTitle from "./../../shared-components/molecules/AccordionTitle";
import AccordionSubscriptionContent from "./AccordionSubscriptionContent";
// import AccordionComponent from "./../../shared-components/organisms/AccordionComponent";
import SubscriptionSection from './SubscriptionSection';
import classNames from 'classnames';
import bind from './../../bind';
import Accordion from "./../../shared-components/organisms/Accordion";

class ARSSubscription extends Component {
  constructor(props) {
    super(props);
    this.keyID = "auto-replenishment"
    this.onActionClicked = this.onActionClicked.bind(this);
  }
  onActionClicked() {
    document.getElementById("auto-replenishment-manage-subscription").click();
    let url = this.props.myPrinter.data.WPPData.arsSubscriptionInfo.buttonUrl;
    let win = window.open(url, '_blank');
    win.focus();
  }
  callbackFn = (bool) => {
    const containerClass = classNames('accordion', 'inkSettingsInstant', 'myPrinterSetting', { 'accordion-active': bool });
    document.getElementById(`container_${this.keyID}`).className = containerClass;
  }
  render() {
    const printer = this.props.printer.WPPData;
    const subscriptionData = this.props.printer.subscriptionData;
    // const containerClass = classNames('accordion', 'inkSettingsInstant', 'myPrinterSetting', { 'accordion-active': this.state.active });
    const containerClass = classNames('accordion', 'inkSettingsInstant', 'myPrinterSetting');
    if (!printer.arsSubscriptionInfo) {
      return null;
    }
    return (
      <div className="printerGrid--printerCell promoSection--ars">
        <a name="tracking-button" id="auto-replenishment-manage-subscription"></a>
        {this.props.gnb.isTablet ||
          <SubscriptionSection
            sectionHeader={printer.arsSubscriptionInfo.settingsTitle}
            showCheckmark={false}
            sectionDescription={
              `<p>
                <a
                  id="auto-replenishment-tou-link"
                  href="${subscriptionData.ars_terms_of_use_link_url}" 
                  target="_blank" rel="noreferrer noopener">
                    ${subscriptionData.ars_terms_of_use_link_text}
                  </a>
              </p>`
            }
            sectionActionText={printer.arsSubscriptionInfo.buttonText}
            sectionActionId="auto-replenishment-manage-subscription"
            onActionClicked={this.onActionClicked}
          />
        }

        {this.props.gnb.isTablet &&
          <div id={`container_${this.keyID}`} className={containerClass}>
            <AccordionTitle
              loading={false}
              ID={this.keyID}
              accordionTitle={printer.arsSubscriptionInfo.settingsTitle} />
            <Accordion ID={this.keyID} actionID={this.keyID} groupID="InkSettings" groupFn={this.props.groupFn} controler={this.props.controler} callbackFn={this.callbackFn} >
              <AccordionSubscriptionContent
                ID="auto-replenishment-manage-subscription"
                subscriptionLinkText={subscriptionData.ars_terms_of_use_link_text}
                subscriptionLinkUrl={subscriptionData.ars_terms_of_use_link_url}
                subscriptionLinkClassName="accordionContentInk--tou"
                actionText={printer.arsSubscriptionInfo.buttonText}
                onActionClicked={this.onActionClicked}
              />
            </Accordion>
          </div>
        }

      </div>
    );
  }
}

export default bind(ARSSubscription);
