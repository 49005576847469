import React from 'react';
import styled from 'styled-components';
import {
  isEmpty,
} from 'ramda';

import { useSelector } from '../../../hooks';
import { selectTestimonialsSectionProps } from '../../../selectors/home';

import Carousel from '../../wrapped/Carousel';
import TestimonialCard from './TestimonialCard';

import {
  breakpoints,
  paths,
} from '../../../utils/styleHelpers';

const TestimonialsContainer = styled.div`
  && {
    background: top center / calc(100vw - 3rem) 100% no-repeat url("../../assets/home/bg-sm2.jpg");
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
    height: calc(100vh - 68px);
    color: ${paths.colorsWhite};
    width: 100%;
    margin-bottom: 3rem;
    @media (min-width: ${breakpoints.mobile}) {
      padding: 2rem 1.5rem;
    }
    @media (min-width: ${breakpoints.tablet}) {
      background: top center / calc(100vw - 3rem) 100% no-repeat url("../../assets/home/bg-md2.jpg");
    }
    @media (min-width: ${breakpoints.wide}) {
      background: top center / calc(100vw - 3rem) 100% no-repeat url("../../assets/home/bg-lg2.jpg");
    }
    @media (min-width: 64rem) {
      padding: 3rem;
      margin-top: 3rem;
      height: 100%;
      margin-left: 5rem;
      margin-right: ${props => props.isRTLSupport && '5rem'};
      width: 90.5%;
      @media (min-resolution: 1.5dppx) {
        padding: 1rem;
        height: calc(100vh - 68px);
        width: 93.75%;
      }
    }
  }
`;

const StyledCarousel = styled(Carousel)`
  && {
    max-width: 100%;
    margin: 0 auto;

    .carousel.carousel-slider {
      height: 26rem;
      @media (min-width: 64rem) {
        height: 28rem;
      }
    }
    @media (min-width: ${paths.venDesk}) {
      max-width: 90%;
    }
    @media (min-width: ${paths.breakWide}) {
      max-width: 85%;
    }
  }
`;

const PagingDot = styled.div`
  cursor: pointer;
  display: inline-block;
  background-color: ${paths.colorsInactiveGrey};
  margin: 0 0.5rem;
  box-shadow: none;
  opacity: 1;
  height: 2rem;
  width: 2rem;
  max-height: 8px;
  max-width: 8px;
  border-radius: 15px;
  &.active {
    max-width: 15px;
    max-height: 15px;
    background-color: ${paths.colorsWhite};
    position: relative;
    top: 4px;
  }
  transition: all .25s ease-in;
`;

const CarouselContainer = styled.div`
  height: 100%;
  width: 100%;

  & .slider-list {
    display: flex;
    align-items: center;
  }
`;

const Testimonials = ({ isRTLSupport }) => {
  const slides = useSelector(selectTestimonialsSectionProps);

  if (isEmpty(slides) || !slides) {
    return null;
  }

  return (
    <TestimonialsContainer isRTLSupport={isRTLSupport}>
      <CarouselContainer>
        <StyledCarousel
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          renderBottomCenterControls={({ goToSlide, currentSlide }) => {
            return slides.map(({ testimonial }, index) => {
              const { headline } = testimonial;
              const { uid } = headline;
              return(
                <PagingDot
                  key={uid}
                  onClick={() => goToSlide(index)}
                  className={index === currentSlide ? 'active' : ''}
                />
              )
            });
          }}
          autoplay
          autoplayInterval={7000}
          pauseOnHover
          wrapAround
        >
          {
            slides.map((test) => {
              const { testimonial } = test;
              const { headline } = testimonial;
              const { uid } = headline;
              return (
                <TestimonialCard
                  key={uid}
                  {...test}
                />
              );
            })
          }
        </StyledCarousel>
      </CarouselContainer>
    </TestimonialsContainer>
  );
};

export default Testimonials;
