export const SMART_HELP_RESOURCE_NAME = 'smart-help';
export const SMART_HELP_SECTIONS_RESOURCE_NAME = 'smart-help-sections';

export const SMART_HELP = [SMART_HELP_RESOURCE_NAME, SMART_HELP_SECTIONS_RESOURCE_NAME];
export const IN_APP_HELP = SMART_HELP;

export const UCDE_HELP_CENTER = [SMART_HELP_RESOURCE_NAME, SMART_HELP_SECTIONS_RESOURCE_NAME];

export const PRINTER_ESSENTIALS_PREFIX = 'printers/';
export const PRINTER_ESSENTIALS = (suffix) => [
  `${PRINTER_ESSENTIALS_PREFIX}${suffix}`
];

export const NEW_USER = 'smartSuite/welcome';
export const RETURNING_USER = 'smartSuite/returning-customer';
export const PRINTER_WELCOME = 'smartSuite/printer-data-collection';

export const CONSENTS_WELCOME = [NEW_USER, RETURNING_USER];
export const CONSENTS_PRINTER = [PRINTER_WELCOME];

export const GLOBALS = [];

export const NOT_LOCALIZED = new Set([]);
