import styled from 'styled-components';
import { breakpoints, smb_breakpoints } from '../../../utils/styleHelpers';

export const SupportContainer = styled.div`
  && {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    ${(props) => (props.isRTL ? 'margin-right: 18px' : 'margin-left: 18px')};
    @media (min-width: ${smb_breakpoints.desktop}) {
      ${(props) => (props.isRTL ? 'margin-right: 81px' : 'margin-left: 81px')};
    }
    @media (min-width: ${smb_breakpoints.wide}) {
      ${(props) => (props.isRTL ? 'margin-right: 341px' : 'margin-left: 341px')};
    }
    @media (min-width: ${breakpoints.tablet}) {
      margin-bottom: 35px;
    }
  }
`;

export const ImageContent = styled.div``;

export const TextContent = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    width: 280px;
    margin-top: 5px;
    ${(props) => (props.isRTL ? 'margin-right: 20px' : 'margin-left: 20px')};
    @media (min-width: ${smb_breakpoints.mobile}) {
      margin-top: 14px;
      width: 550px;
    }
  }
  h3 {
    font-size: 21px;
    @media (min-width: ${breakpoints.mobile}) {
      font-size: 20px;
    }
  }
  p {
    line-height: 22px;
  }
`;
