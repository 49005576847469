import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import BenefitItem from './BenefitItem';
import i18n from '../../../utils/i18n';
import { SERVICES } from '../../../utils/constants';
import selectCurrentStack from '../../../store/modules/environment/selectors';
import { selectRootMatch } from '../../../../../selectors/routing';
import selectUcdePortalElements from '../../../store/modules/portalElements/selectors';
import logoHpPlus from '../../../assets/img/hp-plus.svg';
import {
  entitlements,
  handleCardsToShow,
  myPrintersURLs,
} from '../entitlementsHandling';
import {
  BenefitsListHeader,
  Content,
  IconHeader,
  ListContainer,
  ListFeaturesContainer,
  OtherFeaturesHeader,
  TitleHeader,
} from './styles';

const BenefitsList = () => {
  const { url } = useSelector(selectRootMatch);
  const ucdePortalElements = useSelector(selectUcdePortalElements);
  const dispatch = useDispatch();
  const stack = useSelector(selectCurrentStack);
  const {
    info: {
      role,
    },
    services: { cards },
  } = ucdePortalElements;

  let benefits = [];
  let otherFeatures = [];

  Object.entries(cards).forEach(([key, card]) => {
    const { level } = card;

    if (handleCardsToShow({ role, key })) {
      const {
        name, detail, icon, link,
      } = entitlements[key];

      const toggleLink = ((key === SERVICES.KEY_EPRINT)
        ? (() => window.open(myPrintersURLs[stack], '_self'))
        : (() => dispatch(push(`${url}${link}`))));

      if (level === 'HpPlus') {
        benefits = [...benefits, {
          name,
          detail,
          icon,
          toggleLink,
        }];
      } else {
        otherFeatures = [...otherFeatures, {
          name,
          detail,
          icon,
          toggleLink,
        }];
      }
    }
  });

  const benefitsList = benefits.map(({
    name, detail, icon, toggleLink,
  }) => (
    <BenefitItem
      name={name}
      detail={detail}
      icon={icon}
      toggleLink={toggleLink}
    />
  ));

  const otherFeaturesList = otherFeatures.map(({
    name, detail, icon, toggleLink,
  }) => (
    <BenefitItem
      name={name}
      detail={detail}
      icon={icon}
      toggleLink={toggleLink}
    />
  ));

  return (
    <Content
      data-testid="benefits-list"
    >
      {benefitsList && benefitsList.length > 0 && (
        <ListContainer>
          <BenefitsListHeader>
            <IconHeader>
              <img
                src={logoHpPlus}
                alt={i18n.t('manageHPBenefits.overview.benefitsHeader')}
              />
            </IconHeader>
            <TitleHeader>
              <p>{i18n.t('manageHPBenefits.overview.benefitsHeader')}</p>
            </TitleHeader>
          </BenefitsListHeader>
          {benefitsList}
        </ListContainer>
      )}

      {otherFeaturesList && otherFeaturesList.length > 0 && (
        <ListFeaturesContainer>
          <OtherFeaturesHeader>
            <p>{i18n.t('manageHPBenefits.overview.otherFeaturesHeader')}</p>
          </OtherFeaturesHeader>
          {otherFeaturesList}
        </ListFeaturesContainer>
      )}
    </Content>
  );
};

export default BenefitsList;
