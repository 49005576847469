import React from 'react';
import { Link } from '../../routing';
// import ReactGA from 'react-ga';

export default function BreadCrumbs(props) {
  // Condtional needed: If a subsection is passed, the section needs to be an active link back to the My Printers listing page. Otherwise it should just be text
  // Ok, the conditional is whether or not props.sectionLink is passed with a string url.

  let render_crumbs = () => {
    // if (props.sectionLink != null) {
      // improve logic
    if (props.sectionLink) {
      return <span><Link id={props.ID} to={props.sectionLink} >{props.section}</Link>{props.subsection} </span>
    } else {
      return <span> {props.section} {props.subsection} </span>
    }
  }

  return (
    <div className="breadCrumbs">
      {render_crumbs()}
    </div>
  )
}