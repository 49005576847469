/* eslint-disable no-use-before-define */
import { is } from 'ramda';
import { createReducer } from '../utils/redux';
import * as CraftActions from '../actions/craft';

const processIncomingCraftData = ({ resource, data }) => {
  return data;
};

const initialState = {};

const handlers = {
  [CraftActions.gotCraftData]: putCraftDataInState,
  [CraftActions.craftDataFailure]: sendCraftDataErrorIntoState
};

const craftReducer = createReducer(initialState, handlers);
export default craftReducer;

function putCraftDataInState(state, { resource, data }) {
  return {
    ...state,
    [resource]: processIncomingCraftData({ resource, data })
  };
}

function sendCraftDataErrorIntoState(state, { resource, error }) {
  return {
    ...state,
    [resource]: error
  };
}
