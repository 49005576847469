import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import OrigamiHowItWorks from '../OrigamiHowItWorks';
import SkeletonHowItWorks from '../Skeleton/SkeletonHowItWorks';
import { StyledSection } from '../styles/StyleOrigami';
import { SkeletonContainer } from '../styles/StyleSkeleton';

const propTypes = {
  dashedSeparatorTitle: PropTypes.string.isRequired,
  dashedSeparatorUrl: PropTypes.string.isRequired,
  howItWorksHeading: PropTypes.string.isRequired,
  howItWorksSubhead: PropTypes.string.isRequired,
  howItWorksVideoCode: PropTypes.string.isRequired,
  howItWorksSection: PropTypes.array.isRequired
};

const defaultProps = {
  dashedSeparatorTitle: '',
  dashedSeparatorUrl: '',
  howItWorksHeading: '',
  howItWorksSubhead: '',
  howItWorksVideoCode: '',
  howItWorksSection: []
};

const OrigamiHowItWorksContent = (props) => {
  const {
    dashedSeparatorTitle,
    dashedSeparatorUrl,
    howItWorksHeading,
    howItWorksSubhead,
    howItWorksVideoCode,
    howItWorksSection
  } = props;
  return (
    <StyledSection>
      <OrigamiHowItWorks
        dashedSeparatorTitle={dashedSeparatorTitle}
        dashedSeparatorUrl={dashedSeparatorUrl}
        howItWorksHeading={howItWorksHeading}
        howItWorksSubhead={howItWorksSubhead}
        howItWorksVideoCode={howItWorksVideoCode}
        howItWorksSection={howItWorksSection}
      />
    </StyledSection>
  );
};

const OrigamiHowItWorksPromise = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          default: (props) => {
            const {
              dashedSeparatorTitle,
              dashedSeparatorUrl,
              howItWorksHeading,
              howItWorksSubhead,
              howItWorksVideoCode,
              howItWorksSection
            } = props;
            return (
              <OrigamiHowItWorksContent
                dashedSeparatorTitle={dashedSeparatorTitle}
                dashedSeparatorUrl={dashedSeparatorUrl}
                howItWorksHeading={howItWorksHeading}
                howItWorksSubhead={howItWorksSubhead}
                howItWorksVideoCode={howItWorksVideoCode}
                howItWorksSection={howItWorksSection}
              />
            );
          }
        });
      }, 1000);
    })
);

const OrigamiLazyHowItWorks = (props) => {
  const {
    dashedSeparatorTitle,
    dashedSeparatorUrl,
    howItWorksHeading,
    howItWorksSubhead,
    howItWorksVideoCode,
    howItWorksSection
  } = props;

  return (
    <Suspense
      fallback={
        <SkeletonContainer>
          <SkeletonHowItWorks />
        </SkeletonContainer>
      }
    >
      <OrigamiHowItWorksPromise
        dashedSeparatorTitle={dashedSeparatorTitle}
        dashedSeparatorUrl={dashedSeparatorUrl}
        howItWorksHeading={howItWorksHeading}
        howItWorksSubhead={howItWorksSubhead}
        howItWorksVideoCode={howItWorksVideoCode}
        howItWorksSection={howItWorksSection}
      />
    </Suspense>
  );
};

OrigamiLazyHowItWorks.propTypes = propTypes;
OrigamiLazyHowItWorks.defaultProps = defaultProps;
export default OrigamiLazyHowItWorks;
