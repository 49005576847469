import styled, { keyframes } from 'styled-components';
import { theme } from '../../../utils/styleHelpers';

const fadeIn = keyframes`
  from {
    // transform: scale(.25);
    opacity: 0;
  }
  to {
    // transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0;
  }
  to {
    transform: scale(.25);
    opacity: 1;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  display: inline-block;
  animation: ${fadeIn} ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
`;

export const OrigamiContent = styled.div`
  font-family: ${theme.fonts.familyLight};
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: center;

  && {
    sup {
      top: 0;
      font-size: 60%;
    }
  }
`;

export const StyledSection = styled.section`
  background-color: #f8f8f8;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: center;
  align-self: center;
  max-width: 100%;
  position: relative;
  display: inline-block;
  animation: ${fadeIn} ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
`;
