import React, { useState } from 'react';
import styled from 'styled-components';
import Media from 'react-media';
import {
  IconArrowDown,
  DirectionProvider,
} from '@veneer/core';
import { useSelector } from '../../../hooks';
import { selectHeroCarouselSlides } from '../../../selectors/home';
import { selectAppBadgesContentStackData } from '../../../selectors/craft';
import Carousel from '../../wrapped/Carousel';
import HeroCarouselSlide from './HeroCarouselSlide';
import PagingControls from './PagingControls';
import {
  venBreaks,
  paths,
} from '../../../utils/styleHelpers';
import { RTL_LANGUAGES, RTL, LTR } from '../../../constants/i18n';
import { RENDER_BOTTOM_LEFT_CONTROLS,RENDER_BOTTOM_RIGHT_CONTROLS } from '../../../constants/common';

const CarouselContainer = styled.div(props => `
  height: calc(100vh - 4rem);
  width: 100vw;
  position: relative;
  left: ${!props.isRTLSupport && '-1.5rem'};
  @media screen and (min-width: ${paths.breakTablet}) {
    left: ${!props.isRTLSupport && '-2.5rem'};
  }
  @media screen and (min-width: ${paths.breakWide}) {
    left: ${!props.isRTLSupport && '-6.25rem'};
  }
  @media screen and (min-width: ${paths.breakImmense}) {
    left: ${!props.isRTLSupport && 'calc((-1 * ((100vw - 120rem) / 2)) + -100px)'};
  }
  .slider-list {
    height: 100%;
  }
`);

const ArrowContainer = styled.span`
  && {
    @keyframes bounce {
      0%, 100%   { bottom: 1.5rem; }
      50% { bottom: 2.5rem; }
    }
    animation: bounce 1s infinite ease-in-out;
    border: 2px solid white;
    border-radius: 10px;
    color: white;
    background-color: transparent;
    width: 1.5rem;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      fill: white;
      padding: 0;
      margin: -1px;
    }
  }
`;

const BottomCenterSection = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  ${ArrowContainer} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const MoreBelowIndicator = ({ scrollToFn }) => (
  <ArrowContainer id="annoying-bouncing-arrow" onClick={scrollToFn}>
    <IconArrowDown
      size={36}
      filled
    />
  </ArrowContainer>
);

const HeroCarousel = ({ scrollToFn, geoCountry, instantInkSupportedCountries, instantInkPrice }) => {
  const slides = useSelector(selectHeroCarouselSlides, { geoCountry, instantInkSupportedCountries });
  const appBadgesContent = useSelector(selectAppBadgesContentStackData)?.data?.entries[0];
  
  const [autoplayEnabled, setAutoplayEnabled] = useState(false);
  const language = window.location.pathname.split('/')[2];
  const isRTLSupport = RTL_LANGUAGES.includes(language);
  const renderControls = isRTLSupport ? RENDER_BOTTOM_LEFT_CONTROLS : RENDER_BOTTOM_RIGHT_CONTROLS;

  return (
    <CarouselContainer isRTLSupport={isRTLSupport}>
      <DirectionProvider direction={isRTLSupport ? RTL : LTR}>
      <Carousel
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        renderBottomCenterControls={({ nextSlide, previousSlide }) => (
          <Media queries={{ tablet: `(min-width: ${venBreaks.tablet})` }}>
            {
              ({ tablet }) => (
                <BottomCenterSection>
                  {
                    !tablet && (
                      <PagingControls nextSlide={nextSlide} previousSlide={previousSlide} isRTLSupport={isRTLSupport} />
                    )
                  }
                  <MoreBelowIndicator scrollToFn={scrollToFn} />
                </BottomCenterSection>
              )
            }
          </Media>
        )}
        {...{
          [renderControls]: ({ nextSlide, previousSlide }) => (
            <Media queries={{ tablet: `(min-width: ${venBreaks.tablet})`}}>
              {
                ({ tablet }) => tablet && <PagingControls nextSlide={nextSlide} previousSlide={previousSlide} isRTLSupport={isRTLSupport} />
              }
            </Media>
          )
        }}
        autoplay={autoplayEnabled}
        autoplayInterval={7000}
        pauseOnHover
        wrapAround
      >
          {
            appBadgesContent &&
              slides.map((slide, index) => {
                const { carousel_panel } = slide;
                const { _metadata } = carousel_panel;
                const { uid } = _metadata;
                slide.badges = (index === 0) ? appBadgesContent.landing_page_app_badges : null;
                slide.instantInkPrice = instantInkPrice;
                return(
                  <HeroCarouselSlide
                    key={uid}
                    direction={isRTLSupport ? RTL : LTR}
                    imageOnload={
                      index === 0
                        ? () => setAutoplayEnabled(true)
                        : () => {}
                    }
                    {...slide}
                  />
                )
              })
        }
      </Carousel>
      </DirectionProvider>
    </CarouselContainer>
  );
};

export default HeroCarousel;
