import React from 'react';

import { AnimatedSection, NotesContainerLoadingSection } from '../styles/StyleSkeleton';

const SkeletonFootnotes = () => {
  return (
    <NotesContainerLoadingSection>
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" width="50%" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" width="70%" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" width="50%" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" width="70%" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" width="20%" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" width="50%" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" width="70%" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" width="50%" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" width="70%" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" margin="5px" />
      <AnimatedSection height="10px" width="20%" margin="25px" />
    </NotesContainerLoadingSection>
  );
};

export default SkeletonFootnotes;
