import styled from 'styled-components';
import { breakpoints } from '../../../utils/styleHelpers';

export const AboutSmartAdvanceContainer = styled.div`
  && {
    width: 100%;
    background: #ffffff;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    padding: 61px 38px;
    box-sizing: border-box;
  }
`;

export const AboutSmartAdvanceContent = styled.div`
  && {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'HP Simplified';
    .about-section-title {
      color: #009f86;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
      width: 300px;
    }
    .about-section-description {
      color: #4e4e4e;
      font-size: 24px;
      line-height: 28px;
      width: 336px;
      padding-bottom: 29px;
      @media (min-width: ${breakpoints.bigtablet}) {
        font-size: 32px;
        line-height: 36px;
        width: 500px;
        margin-bottom: 20px;
      }
    }
    .features {
      .vector {
        display: none;
      }
      @media (min-width: ${breakpoints.bigtablet}) {
        display: flex;
        position: relative;
        align-items: flex-start;
        width: 100%;
        max-width: 650px;
        justify-content: space-between;
        .vector {
          display: block;
          margin-top: 50px;
          width: 80px;
          position: relative;
          margin-left:25px;
          img {
            position: absolute;
            left: -30px;
          }
        }
      }
    }
    .review-tile {
      img {
        width: 318px;
      }
    }
  }
`;

export const FeatureTile = styled.div`
  && {
    margin-bottom: 10px;
    .feature-image {
      width: 104px;
      height: 104px;
    }
    .feature-title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 7px;
      color: #4e4e4e;
      font-weight: 400;
    }
    .feature-description {
      font-size: 16px;
      line-height: 24px;
      color: #1f1f1f;
      font-weight: 300;
      width: 320px;
      @media (min-width: ${breakpoints.desktop}) {
        width: 395px;
      }
      @media (min-width: ${breakpoints.bigtablet}) {
        width: 200px;
        height: auto;
      }
      @media (min-width: ${breakpoints.wide}) {
        width: 274px;
        height: auto;
      }
    }
  }
`;
