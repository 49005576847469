import React from 'react';

import {
  AnimatedSection,
  HowItWorksLoadingSection,
  HowItWorksContentContainerLoadingSection,
  HowItWorksContentLoadingSection
} from '../styles/StyleSkeleton';

const SkeletonHowItWorks = () => {
  return (
    <HowItWorksLoadingSection>
      <AnimatedSection height="50px" width="200px" margin="15px" />
      <AnimatedSection height="20px" width="350px" margin="30px" />
      <HowItWorksContentContainerLoadingSection>
        <HowItWorksContentLoadingSection>
          <AnimatedSection height="70px" width="70px" margin="20px" />
          <AnimatedSection height="25px" margin="30px" />
          <AnimatedSection margin="5px" />
          <AnimatedSection margin="5px" />
          <AnimatedSection margin="30px" />
        </HowItWorksContentLoadingSection>
        <HowItWorksContentLoadingSection>
          <AnimatedSection height="70px" width="70px" margin="20px" />
          <AnimatedSection height="25px" margin="30px" />
          <AnimatedSection margin="5px" />
          <AnimatedSection margin="5px" />
          <AnimatedSection margin="30px" />
        </HowItWorksContentLoadingSection>
        <HowItWorksContentLoadingSection>
          <AnimatedSection height="70px" width="70px" margin="20px" />
          <AnimatedSection height="25px" margin="30px" />
          <AnimatedSection margin="5px" />
          <AnimatedSection margin="5px" />
          <AnimatedSection margin="30px" />
        </HowItWorksContentLoadingSection>
      </HowItWorksContentContainerLoadingSection>
    </HowItWorksLoadingSection>
  );
};

export default SkeletonHowItWorks;
