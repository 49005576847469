import React, { Component } from 'react';
import bind from "./../../../bind";

class EprintOpen extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let accessSettingData = this.props.myPrinter.data.accessSettingData;
    return (<div id="openToAnyone" className="ePrintAccessSetting ePrintAccessSetting-current">
      <h4 className="setting--title">{accessSettingData.eprint_access_ota_label}</h4>
      <p>{accessSettingData.eprint_access_ota_description}</p>
    </div>
    );
  }
}

export default bind(EprintOpen);