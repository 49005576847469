import styled from 'styled-components';
import { breakpoints, smb_breakpoints } from '../../../utils/styleHelpers';

export const SetupContainer = styled.div`
  && {
    margin-top: 20px;
    ${(props) => (props.isRTL ? 'margin-right: 16px' : 'margin-left: 16px')};
    margin-bottom: 20px;
    @media (min-width: ${smb_breakpoints.mobile}) {
      margin-top: 40px;
    }
    @media (min-width: ${smb_breakpoints.desktop}) {
      ${(props) => (props.isRTL ? 'margin-right: 43px' : 'margin-left: 43px')};
    }
    @media (min-width: ${smb_breakpoints.large}) {
      ${(props) => (props.isRTL ? 'margin-right: 71px' : 'margin-left: 71px')};
    }
    @media (min-width: ${smb_breakpoints.wide}) {
      ${(props) => (props.isRTL ? 'margin-right: 362px' : 'margin-left: 362px')};
    }
  }
`;

export const SetupContent = styled.div`
  && {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
`;

export const ImageContent = styled.div``;

export const TextContent = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    ${(props) => (props.isRTL ? 'margin-right: 5px' : 'margin-left: 5px')};
    ${(props) => (props.isRTL ? 'margin-left: 10px' : 'margin-right: 10px')};
    width: 270px;

    @media (min-width: ${breakpoints.mobile}) {
      width: 303px;
    }
    @media (min-width: ${smb_breakpoints.mobile}) {
      width: 246px;
      ${(props) => (props.isRTL ? 'margin-left: 20px' : 'margin-right: 20px')};
    }
    @media (min-width: ${breakpoints.bigtablet}) {
      width: 403px;
    }
    }
    h3 {
      font-size: 19px;
      @media (min-width: ${smb_breakpoints.mobile}) {
        font-size: 19px;
      }
    }
    p {
      line-height: 22px;
    }
  }
`;

export const Line = styled.hr`
  && {
    color: #027aae;
    border: 1px solid;
    width: 95%;
    @media (min-width: ${smb_breakpoints.desktop}) {
      width: 90%;
    }
    @media (min-width: ${smb_breakpoints.large}) {
      width: 60%;
    }
    @media (min-width: ${breakpoints.large}) {
      width: 50%;
    }
    @media (min-width: ${smb_breakpoints.wide}) {
      width: 40%;
    }
  }
`;
