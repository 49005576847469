import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ContextualMenu,
  IconChevronDown,
  IconChevronUp,
  IconTrash,
} from '@veneer/core';
import PropTypes from 'prop-types';

import {
  Actions,
  AvatarContainer,
  Email,
  Label,
  Name,
  OwnerButton,
  Role,
  Status,
  UserContainer,
  UserItemContainer,
} from './styles';
import Avatar from '../../Avatar';
import { selectUcdeUser } from '../../../store/modules/ucdeUser/selectors';
import i18n from '../../../utils/i18n';
import { deleteInvitation } from '../../../api/UCDEGateway';

const UserItem = ({
  resourceId, fullName, email, owner, state, color, onDelete, onError, headers,
}) => {
  const user = useSelector(selectUcdeUser);
  const [isExpanded, setIsExpanded] = useState(false);

  const statuses = {
    Active: i18n.t('manageUsers.table.status.active'),
    Pending: i18n.t('manageUsers.table.status.pending'),
  };

  const options = [
    {
      value: 1,
      label: i18n.t('manageUsers.table.button.cancel'),
    },
    {
      value: 2,
      label: i18n.t('manageUsers.table.button.delete'),
    },
  ];

  return (
    <UserItemContainer isExpanded={isExpanded}>
      <UserContainer>
        <AvatarContainer>
          <Avatar
            text={fullName.trim() || email}
            fontSize="12px"
            backgroundSize="32"
            backgroundColor={color}
            fontColor={color}
          />
        </AvatarContainer>
        <Name>
          {fullName.trim() || '-'}
        </Name>

        <button type="button" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <IconChevronUp /> : <IconChevronDown />}
        </button>
      </UserContainer>

      <Email>
        <Label>{headers.email}</Label>
        {email}
      </Email>
      <Role>
        <Label>{headers.role}</Label>
        {owner ? i18n.t('manageUsers.table.typeOfUsers.owner') : i18n.t('manageUsers.table.typeOfUsers.user') }
      </Role>
      <Status>
        <Label>{headers.status}</Label>
        {statuses[state]}
      </Status>
      <Actions>
        {owner ? (
          <OwnerButton
            disabled
            appearance="tertiary"
            leadingIcon={<IconTrash size={24} />}
          />
        ) : (
          <ContextualMenu
            options={options}
            placement="bottom-end"
            selectedValue={2}
            useJsPositioning
            onClick={async (evt, option) => {
              if (option.value === 2) {
                try {
                  await deleteInvitation({
                    id: user.tenantResourceId,
                    resourceId,
                    callback: onDelete,
                  });
                } catch (deleteError) {
                  onError({ ...deleteError, email });
                }
              }
            }}
          >
            <Button
              appearance="tertiary"
              leadingIcon={<IconTrash size={24} />}
            />
          </ContextualMenu>
        )}
      </Actions>
    </UserItemContainer>
  );
};

UserItem.propTypes = {
  resourceId: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  owner: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  headers: PropTypes.shape({
    email: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

UserItem.defaultProps = {
  onError: () => {},
};

export default UserItem;
