import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { KeepAlive } from 'react-keep-alive';

import MicroFrontend from '../../../components/MicroFrontend';
import selectCurrentStack from '../../../store/modules/environment/selectors';
import { Container } from '../../../components/shared-components/styles';

const manifestUrls = {
  dev: 'https://assets-pie1.instantink.com/vulcan/manifest.json',
  pie: 'https://assets-pie1.instantink.com/vulcan/manifest.json',
  stage: 'https://assets-stage1.instantink.com/vulcan/manifest.json',
  prod: 'https://assets.instantink.com/vulcan/manifest.json',
};

const InstantSupplies = ({ history }) => {
  const stack = useSelector(selectCurrentStack);

  return (
    <KeepAlive name="instantSupplies">
      <Container>
        <MicroFrontend
          history={history}
          appName="vulcan"
          globalFunction="renderVulcan"
          manifest={manifestUrls[stack]}
        />
      </Container>
    </KeepAlive>
  );
};

InstantSupplies.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default InstantSupplies;
