import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@veneer/core/dist/scripts/button';
import { FaqViewer } from "@jarvis/react-instant-ink-faq";
import Config from '../../config/Config';
import { getCookie } from '../../lib/manageCookies';

import {
  OrigamiFaqSection,
  OrigamiFaqContent
} from './styles/StyleOrigamiFaqs';

const propTypes = {
  faqHeading: PropTypes.string.isRequired,
  showMoreButtonText: PropTypes.string.isRequired,
  showLessButtonText: PropTypes.string.isRequired
};

const defaultProps = {
  faqHeading: '',
  showMoreButtonText: '',
  showLessButtonText: ''
};

const OrigamiFaqs = (props) => {
  const { 
    faqHeading, 
    showMoreButtonText, 
    showLessButtonText } = props;

  const [heading, setHeading] = useState(faqHeading);
  const [pageSize, setPageSize] = useState(8);
  const [isShowMore, setIsShowMore] = useState(true);

  const config = new Config();
  const currentStack = config.Get('CURRENT_STACK');
  const localeCookie = getCookie("gnb_locale");
  const language = localeCookie.split('_')[0];
  const country = localeCookie.split('_')[1];

  if (heading !== faqHeading) {
    setHeading(faqHeading);
  }

  const showMoreFaq = () => {
    if (pageSize < 26) {
      setPageSize(pageSize + 8);
    }
    if (pageSize >= 18) {
      setIsShowMore(false);
    }
  };
  
  const showLessFaq = () => {
    setPageSize(Math.max(pageSize - 8, 8));
    if (pageSize <= 16) {
      setIsShowMore(true);
    }
  };

  const formatCurrency = (value, country) => {
    let formatter;
    switch (country.toLowerCase()) {
        case 'fr':
        case 'de':
            formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
            break;
        case 'us':
            formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
            break;
        case 'gb':
            formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
            break;
    }
    return formatter.format(value);
  };

  const getGeminiAbsolutePath = (path = "") => {
    return config.Get('II_BASE_URL').concat(path);
  };

  const faqViewerParams = {
    language: language,
    country: country.toUpperCase(),
    program: 'ii_paper',
    subset: 'landing_page',
    view: "partial",
    pageSize: pageSize,
    dynamicValues: {
      plansPrice: formatCurrency(0.79, country),
      overageBlockRange: '10',
      overageBlockPrice: 50,
      formattedPlansPrice: formatCurrency(0.79, country),
      hpTermsOfService: getGeminiAbsolutePath("/v2/terms"),
      hpAdminUrl: getGeminiAbsolutePath('/users/signin'),
      hpSupportUrl: 'https://www.hp.com/support/instantinkcontact',
      hpInstantInkUrl: getGeminiAbsolutePath(),
      hpSmartAppLink: config.Get('HP_123_PATH'),
      lowestAdditionalSheetsQty: 10,
      highestAdditionalSheetsQty: 60,
      lowestAdditionalSheetsPrice: "$0.50",
      additionalSheetsSetPages: 6,
      maxAddons: 5
    }
  };

  return (
    <OrigamiFaqSection data-testid="origami-faqs">
      <OrigamiFaqContent data-testid="origami-faqs-content">
        <div data-testid="origami-faqs-heading" className="title">
          {faqHeading}
        </div>
        <FaqViewer {...faqViewerParams} />
        <div className="control-bar">
          {isShowMore && (
            <Button
              appearance="secondary"
              className="show-more"
              data-testid="origami-faqs-btn-more"
              onClick={showMoreFaq}
            >
              {showMoreButtonText}
            </Button>
          )}
          {!isShowMore && (
            <Button
              appearance="secondary"
              className="show-less"
              data-testid="origami-faqs-btn-less"
              onClick={showLessFaq}
            >
              {showLessButtonText}
            </Button>
          )}
        </div>
      </OrigamiFaqContent>
    </OrigamiFaqSection>
  );
};

OrigamiFaqs.propTypes = propTypes;
OrigamiFaqs.defaultProps = defaultProps;
export default OrigamiFaqs;
