import {
  takeLeading,
  call,
  all,
  put,
} from 'redux-saga/effects';
import {
  prop,
  propEq,
  propOr,
  pick,
} from 'ramda';
import {
  getAllUserDevices,
  getDeviceCompleteInfo,
  getDeviceSuppliesInfo,
} from '../../../api/UCDEGateway';
import {
  getDetailedDevices,
  getDevices,
  getDeviceSupplies,
} from './actions';

function* fetchDetailedDevice({ cloudId }) {
  try {
    const resp = yield call(getDeviceCompleteInfo, { cloudId });
    return propOr({}, 'data', resp);
  } catch (e) {
    return {
      status: {
        error: e,
      },
    };
  }
}

function* handleDetailedDeviceFetch() {
  try {
    yield put(getDevices({ fetching: true }));
    const { data: devices } = yield call(getAllUserDevices);
    const detailedDeviceResponses = yield all(devices.map(fetchDetailedDevice));

    const detailedDevices = detailedDeviceResponses.map(resp => {
      const deviceResponse = prop('device', resp);
      const deviceCloudId = prop('cloudId', deviceResponse);
      const initialDevice = devices.find(propEq('cloudId', deviceCloudId));
      const freshData = pick(['supplies', 'status', 'subscription', 'printerOptions'], resp);
      const result = {
        ...initialDevice,
        ...deviceResponse,
        ...freshData,
      };
      return result;
    });
    yield put(getDevices({ fetching: false, error: false, data: detailedDevices }));
  } catch (error) {
    yield put(getDevices({ fetching: false, error: true }));
  }
}

function* handleDeviceSuppliesFetch(action) {
  try {
    yield put(getDeviceSupplies({ ...action.payload, fetchingSupplies: true }));

    const resp = yield call(getDeviceSuppliesInfo, action.payload.cloudId);

    yield put(getDeviceSupplies({ supplies: resp.data, cloudId: action.payload.cloudId, fetchingSupplies: false }));
  } catch (error) {
    yield put(getDeviceSupplies({ ...action.payload, fetchingSupplies: false }));
  }
}

export function* watchGetDeviceSupplies() {
  yield takeLeading(getDeviceSupplies.toString(), handleDeviceSuppliesFetch);
}

export function* watchGetDetailedDevices() {
  yield takeLeading(getDetailedDevices.toString(), handleDetailedDeviceFetch);
}
