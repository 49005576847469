import ActionTypes from "../constants/ActionTypes";

const homeDefault = {
    isComplete: false,
    isError: false,
    errorMsg: "",
    data: []
};

/**
 * The reducer controls the state of the axios or ajax call.
 * returning the current state with any changes from the
 * actions dispatchers
 *
 *
 * @param state
 * @param action
 * @returns {*}
 */
function home(state = homeDefault, action) {
    switch (action.type) {
        case ActionTypes.START_HOMECONTENT_FETCH:
            return {...homeDefault, isFetching:true };

        case ActionTypes.COMPLETE_HOMECONTENT_FETCH:
            return {...homeDefault, isComplete:true, isFetching:false, data: action.home};

        case ActionTypes.COMPLETE_HOMECONTENT_WITH_ERROR:
            return {...homeDefault, isError: true, isFetching:false, errorMsg: action.err.message};
    }

    return state;
}

// usage: this.props.home
export default home;