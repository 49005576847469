import React from 'react';
import styled from "styled-components";

const Title = styled.a`
  display: flex;
  padding: .9377rem;
  transition: background-color 0.3s ease;
  background-color: ${props => (props.active ? "#E7F2F6" : "none")};
  .accordionTitle--title {
    font-family: 'hp-simplified', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    color: #5A5A5A;
  }
  &:hover {
    text-decoration: none;
    background-color: ${props => !props.active && "#E7F2F6"};
  }
`
export default function AccordionTitle(props) {
  // Create a variable which equals true if either elligible is true or accordionTitleStatus is defined
  const anyStatus = props.eligible || props.accordionTitleStatus !== undefined || props.loading;

  return (
    <Title id={props.ID} data-tracking={props.datatracking} onClick={props.onClick} active={props.active}>
      <span className="accordionTitle--icon">
        <img src="/assets/img/accordion_icon.svg" alt="accordion icon" />
      </span>
      <span className="accordionTitle--title">{props.accordionTitle}</span>
      {props.children}
    </Title>
  );
}
