import styled from 'styled-components';
import { breakpoints, theme } from '../../../utils/styleHelpers';

export const PricingSection = styled.div`
  width: 100%;
  background-color: #F8F8F8;
  padding-left: 200px;
  font-weight: 400;
  color: #fff;
  padding-top: 30px;
  font-family: 'Forma DJR', sans-serif;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: left;
  align-self: left;
  max-width: 100%;
  padding-bottom: 32px;

  h4 {
    color: #212121;
    font-family: 'Forma DJR', sans-serif;
    font-weight: 400;
    font-size: 56px;
    line-height: 64px;
    margin-bottom: 1rem;
  }
  p {
    color: #212121;
    font-family: 'Forma DJR', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }
  
  @media (max-width: 1440px) {
    padding-left: 100px;
  }

  @media (max-width: 1366px) {
    padding-left: 100px;
  }

  @media (max-width: 1024px) {
    h4 {
      font-size: 40px;
      line-height: 48px;
    }
  }

  @media (max-width: 768px) {
    padding-left: 50px;
     p {
      font-size: 20px;
      line-height: 28px;
    }
  }

  @media (max-width: 390px) {
    padding-left: 10px;
    h4 {
      font-size: 28px;
      line-height: 36px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const ValuePropContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: left;
  background-color: #F8F8F8;
  width: auto
  max-width: 95vw;
  padding-left: 200px;
  padding-bottom: 30px;

  @media (max-width: 1440px) {
    padding-left: 100px;
  }

  @media (max-width: 1366px) {
    padding-left: 100px;
  }

  @media (max-width: 768px) {
    padding-left: 50px;
    flex-direction: column;
    flex-flow: wrap;
    align-items: left;
    justify-content: left;
  }

  @media (max-width: 390px) {
    padding-left: 10px;
  }
`;

export const ValuePropContent = styled.div`
  display: flex;
  flex-direction: row;
  align-self: left;

  @media (max-width: ${breakpoints.bigmobile}) {
    padding-top: 20px;
  }
`;

export const PropImage = styled.div`
  @media (max-width: ${breakpoints.bigmobile}) {
    margin-left: 5px;
  }
`;

export const PropText = styled.div`
  font-style: normal;
  font-family: 'Forma DJR', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: left;
  color: #333;
  margin: 0 20px 0 10px;
  @media (max-width: 390px) {
    font-size: 16px;
  }
  p {
    margin: 0;
  }
`;
