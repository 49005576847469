import styled from 'styled-components';
import { breakpoints, theme } from '../../../utils/styleHelpers';

export const SetupContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto 0 auto;

  @media (min-width: ${breakpoints.bigtablet}) {
    box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.1);
    border-radius: 16px;
    margin: 0 auto;
    position: relative;
    bottom: 50px;
  }

  .beginSetupButton {
    align-items: center;
    padding: 0px 20px;
    width: auto;
    height: auto;
    background: #027aae;
    border-radius: 8px;
    margin: 10px auto auto auto;

    @media (min-width: ${breakpoints.bigtablet}) {
      height: auto;
      padding: 0px 24px;
      border-radius: 12px;
      margin: 24px auto;
    }
  }

  .downloadDriversButton {
    font-family: ${theme.fonts.family};
    justify-content: center;
    align-items: center;
    padding: 7px 10px 0px 10px;
    width: auto;
    height: auto;
    border: 1px solid #027aae;
    box-sizing: border-box;
    border-radius: 12px;
    margin: 10px auto;

    @media (min-width: ${breakpoints.bigtablet}) {
      margin-bottom: 40px;
    }
  }
`;

export const SetupHeader = styled.h1`
  width: 311px;
  height: auto;
  font-family: ${theme.fonts.family};
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  align-items: center;
  text-align: center;
  color: #212121;
  margin: 10px auto 20px auto;

  @media (min-width: ${breakpoints.bigtablet}) {
    width: 400px;
    font-size: 28px;
    line-height: 36px;
    margin: 35px auto 20px auto;
  }
`;

export const Features = styled.h2`
  margin: auto;
  height: auto;
  font-family: ${theme.fonts.family};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #212121;
  width: 360px;

  @media (min-width: ${breakpoints.bigtablet}) {
    width: 415px;
    margin: auto 35px 5px 35px;
  }

  p {
    font-size: 16px;
  }
`;

export const PrinterImage = styled.img`
  margin: auto;
  width: 48%;
`;

export const BeginSetupButtonText = styled.h1`
  width: auto;
  font-family: ${theme.fonts.family}
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 10px 0px;
`;

export const Line = styled.hr`
  width: 325px;
  border: 1px solid #dcdcdc;
  margin: 30px auto 10px auto;

  @media (min-width: ${breakpoints.desktop}) {
    width: 442px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 565px;
  }

  @media (min-width: ${breakpoints.bigtablet}) {
    margin: auto;
    width: 85%;
  }
`;

export const AccessPrinterText = styled.h1`
  width: 311px;
  height: auto;
  font-family: ${theme.fonts.family};
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  align-items: center;
  text-align: center;
  margin: 8px auto 0 auto;

  @media (min-width: ${breakpoints.bigtablet}) {
    width: 400px;
    font-size: 28px;
    line-height: 36px;
    color: #212121;
    align-self: stretch;
    margin: 20px auto;
  }
`;

export const DownloadDriversButtonText = styled.h1`
  font-family: ${theme.fonts.family};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #027aae;
`;
