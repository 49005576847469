import styled from 'styled-components';
import { UCDEMainTheme } from '../../../pages/Dashboard/styles';

export const UsageContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${UCDEMainTheme.main.hp.fontFamily};
`;

export const UsagePages = styled.div`
  font-size: 60px;
`;

export const UsageDescription = styled.div`
  max-width: 80%;
  text-align: center;
`;

export const UsageDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: #4E4E4E;
  width: 100%;
  align-items: center;

  > img {
    height: 64px;
    width: 64px;

    + ${UsageDescription} {
      padding-top: 16px;
    }
  }
`;
