import React from 'react';
import { HeaderContainer, HeaderText } from './styles';

const Header = (props) => {
  return (
    <HeaderContainer alt={props.alt} background={props.image}>
      <HeaderText isRTL={props.isRTL}>{props.heading}</HeaderText>
    </HeaderContainer>
  );
};
export default Header;
