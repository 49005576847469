import React from 'react';
import { prop } from 'ramda';
import { useSelector } from '../../hooks';
import { selectContentStackDataByResource } from '../../selectors/craft.js';
import {
  StyledContainer,
  StyledContent,
  StyledTitle,
  StyledDate,
  StyledIndex,
  StyledSections
} from './Terms.styles.js';
import { RTL_LANGUAGES } from '../../constants/i18n';
import { getLocale } from '../../utils/globals';
import { HPAPP_TOU_RESOURCE_NAME } from '../../constants/contentStackResources.js';
import Navbar from '../revised/Global/Navbar.js';
import Footer from '../revised/Global/Footer.js';

const slugify = (str = '') => str.toLowerCase().replace(/ +/g, '-');

const HpAppTou = (props) => {
  const { terms_of_use } = useSelector(selectContentStackDataByResource, [HPAPP_TOU_RESOURCE_NAME]);
  const hpAppTou = terms_of_use?.data?.entry;

  const { language } = getLocale();
  const isRTL = RTL_LANGUAGES.includes(language);

  if (hpAppTou && !hpAppTou.isError) {
    const { indexed_sections, title, last_updated, intro_section, index_title, copyright } = hpAppTou;

    return (
      <div data-testid="hpapp-tou">
        <Navbar isDataNotice={true} />
        <StyledContainer isRTL={isRTL}>
          <StyledContent fontFlag="true">
            <StyledTitle fontFlag="true">{title}</StyledTitle>
            <StyledDate>{last_updated}</StyledDate>
            <div dangerouslySetInnerHTML={{ __html: intro_section }} />
            <strong>{copyright}</strong>
          </StyledContent>
        </StyledContainer>
        <Footer isDataNotice={true} />
      </div>
    );
  }

  if (prop('isError', props.terms)) {
    return <p>{prop('errorMsg', props.terms)}</p>;
  } // Case: We're loading content
  return <div />;
};

export default HpAppTou;
