import styled from 'styled-components';
import { breakpoints, theme } from '../../../utils/styleHelpers';

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px auto;

  @media (min-width: ${breakpoints.bigtablet}) {
    margin: 0 auto 10px auto;
  }
`;

export const PairingURL = styled.h1`
  width: 220px;
  height: auto;
  font-family: ${theme.fonts.familyLight};
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  margin: 40px auto 0 auto;

  p {
    font-size: 17px;
  }

  @media (min-width: ${breakpoints.bigtablet}) {
    font-size: 26px;
    margin: 37px auto 0 auto;
    width: 295px;

    p {
      font-size: 26px;
    }
  }
`;

export const SupportText = styled.h1`
  width: 375px;
  height: auto;
  font-family: ${theme.fonts.familyLight};
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #212121;
  margin: auto;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 auto 30px auto;
  }

  @media (min-width: ${breakpoints.bigtablet}) {
    width: 600px;
    height: auto;
    font-size: 26px;
  }
`;
