/* eslint-disable import/prefer-default-export */
import {
  isEmpty,
} from 'ramda';

// values to send to UDL
const HOME = 'home'; // works in this group and the one below
const CATEGORY = 'category';
const DETAIL = 'detail';

// values that correspond to route resources
const SLASH = '/';
const SIGNIN = 'signin';
const SIGNOUT = 'signout';
const LOGGEDIN = 'loggedin';
const PRINT_PLANS = 'print_plans';
const MY_PRINTERS = 'my-printers';
const MANAGE_USERS = 'manage-users';
const SERVICES = 'services';

const SMART_SECURITY = 'smart-security';

// Sets with 0^1 lookup for helping to determine what gets sent to UDL
const HOME_SET = new Set([HOME, SLASH]);
const CATEGORY_SET = new Set([SIGNIN, SIGNOUT, LOGGEDIN, PRINT_PLANS, MY_PRINTERS, MANAGE_USERS, SERVICES]);

const determineL5 = ({ secondary, tertiary }) => {
  if (!secondary || HOME_SET.has(secondary)) {
    return HOME;
  }

  if (CATEGORY_SET.has(secondary)) {
    if (secondary === PRINT_PLANS && tertiary) {
      return DETAIL;
    }
    return CATEGORY;
  }

  return DETAIL;
};

const determineL8 = ({ primary, secondary, tertiary, quaternary, pageNameL5 }) => {
  if (pageNameL5 === HOME) {
    return HOME;
  }
  if (tertiary === SMART_SECURITY) {
    return SMART_SECURITY;
  }
  return quaternary || tertiary || secondary || primary;
};

const determineL6 = ({ secondary, pageNameL5 }) => {
  if (pageNameL5 === HOME) {
    return HOME;
  }
  if (pageNameL5 === DETAIL) {
    return secondary;
  }

  return null;
};

const determineL7 = ({ tertiary, quaternary, pageNameL5 }) => {
  if (pageNameL5 === HOME) {
    return HOME;
  }

  if (pageNameL5 === DETAIL) {
    return quaternary
      ? tertiary
      : null;
  }

  return null;
};

export const constructEventInfo = params => {
  if (!params || isEmpty(params)) {
    return null;
  }

  const {
    primary,
  } = params;

  if (!['ucde', 'gateway'].includes(primary)) {
    return null;
  }

  const pageNameL5 = determineL5(params);
  const pageNameL6 = determineL6({ ...params, pageNameL5 });
  const pageNameL7 = determineL7({ ...params, pageNameL5 });
  const pageNameL8 = determineL8({ ...params, pageNameL5 });
  return {
    pageNameL5,
    pageNameL8,
    ...pageNameL6 && { pageNameL6 },
    ...pageNameL7 && { pageNameL7 },
  };
};
