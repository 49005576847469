import ActionTypes from '../constants/ActionTypes';
import fetchStatus from './fetchStatus';
import {
  COUNTRIES,
} from '../constants/i18n';

const localeDefault = {
  ...fetchStatus(),
  data: [], /* Ultimately holds the complete list of locales enabled on Craft */
  currentLocale: null,

  countryCode: '',
  languageCode: '',

  alt1: '',
  alt2: '',
  alt3: '',
  alt4: '',
  alt5: '',
};
/**
 * The reducer controls the state of the axios or ajax call.
 * returning the current state with any changes from the
 * actions dispatchers
 *
 *
 * @param state
 * @param action
 * @returns {*}
 */
function locales(state = localeDefault, action) {
  switch (action.type) {
    case ActionTypes.START_LOCALES_FETCH:
      return {
        ...fetchStatus(state, { type: ActionTypes.START_FETCH }),
      };
    case ActionTypes.COMPLETE_LOCALES_FETCH_WITH_ERROR:
      return {
        ...fetchStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH_WITH_ERROR }),
      };

    case ActionTypes.COMPLETE_LOCALES_FETCH:
      return {
        ...fetchStatus(state, { type: ActionTypes.COMPLETE_FETCH }),
        ...{ data: action.locales },
      };

    case ActionTypes.START_LOCALE_UPDATE:
      return {
        ...state,
        isLocaleSelectorChanging: true,
      };

    case ActionTypes.UPDATE_LOCALE:
      const { locale, country, language } = action;
      const correctCountry = country === COUNTRIES.UK ? COUNTRIES.GB : country;
      const alt1 = `${correctCountry}/${language}`;
      const alt2 = `${correctCountry}-${language}`;
      const alt3 = `${correctCountry}_${language}`;
      const alt4 = `${language}_${correctCountry}`;
      const alt5 = `${language}_${correctCountry.toUpperCase()}`;

      return {
        ...state,
        ...{
          currentLocale: locale,
          languageCode: language,
          countryCode: correctCountry,
          alt1,
          alt2,
          alt3,
          alt4,
          alt5
        },
      };
    default:
      return state;
  }
}

export default locales;

// usage: this.props.locales
