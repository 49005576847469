import styled from 'styled-components';
import { Button } from '@veneer/core';

export const BenefitItemContainer = styled.section`
  margin: auto 12px;
  padding: 18px 0;
  border-bottom: 1px solid #EBEBEB;
  display: grid;
  grid-template-columns: 53px auto 50px;
  grid-template-areas: 'logo' 'title' 'link';

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    grid-template-columns: 86px auto 50px;
  }
`;

export const IconBenefit = styled.div`
  display: flex;
  align-items: flex-start;  
  justify-content: center;

  > img {
    width: 53px;
    height: 53px;
  }

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    align-items: center;

    > img {
      width: 86px;
      height: 86px;
    }
  }
`;

export const InfoBenefit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 42px;

  span {
    font-size: 18px;
  }

  p {
    padding-top: 7px;
    font-size: 12px;
  }

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    justify-content: center;
    padding-left: 6px;
    padding-right: 65px;

    span {
      font-size: 20px;
    }

    p {
      padding-top: 6px;
      font-size: 14px;
    }
  }
`;

export const LinkButton = styled(Button)`
  && {
    margin-right: auto;
  }
`;
