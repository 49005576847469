import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import bind from '../../bind';

const NavDots = styled.ul`
  margin: 0 auto;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  padding-bottom: 1.5rem;
  list-style-type: none;
`
const NavDotButton = styled.li`
  display: flex;
  flex: 0 0 auto;
  button {
    height: ${props => props.theme.pagingDotSize ? props.theme.pagingDotSize : '0.625rem'};
    width: ${props => props.theme.pagingDotSize ? props.theme.pagingDotSize : '0.625rem'};
    animation: background 1s;
    border-radius: 50%;
    background: ${props => props.active ? '#0171AD' : '#DDD'};
    transition-property: background;
    transition: 0.3s;
    padding: 0;
    border: 0;
    &:focus {
      outline: none;
    }
  }
  &:hover button {
    background: #0171AD;
    border-radius: 50%;
  }
  &:focus {
    outline: none;
  }
  padding: ${props => props.theme.pagingDotPadding ? props.theme.pagingDotPadding : '0.5rem'};
  @media (min-width: 30rem) {
    padding: 0.625rem;
  }
  margin: 0;
`
class PagingDots extends React.Component {
  getIndexes(count, inc) {
    const arr = [];
    for (let i = 0; i < count; i += inc) {
      arr.push(i);
    }
    return arr;
  }

  render() {
    const indexes = this.getIndexes(
      this.props.slideCount,
      this.props.slidesToScroll,
    );
    return (
      <NavDots>
        {indexes.map(index => {
          return (
            <NavDotButton key={index} id={this.props.prefix+index} onClick={this.props.goToSlide.bind(null, index)} active={index === this.props.currentSlide}>
              <button 
                // style={this.getButtonStyles(this.props.currentSlide === index)}
              ></button>
            </NavDotButton>
          );
        })}
      </NavDots>
    );
  }
};

PagingDots.propTypes = {
  prefix: PropTypes.string.isRequired
};

export default bind(PagingDots);
