import React from 'react';
import styled from 'styled-components';
import ToggleSwitch from '../../shared-components/molecules/ToggleSwitch';

const ListItemWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 3rem;
  justify-content: space-between;
  align-items: center;
  transition: min-height 0.5s linear;
`;

const AccessListItem = ({
  actionId,
  platform,
  onRemoveClick,
  userName,
  handleToggleClick,
  toggleChecked,
  toggleLoading,
}) => {

  return (
    <ListItemWrapper>
      <a id={actionId} />
      <UserRow platform={platform}>
        <div>
          <a role="button" onClick={onRemoveClick}>
            <img
              src="/assets/img/icon_remove.svg"
              alt=""
            />
          </a>
          <span>
            {userName}
          </span>
        </div>
        <ToggleSwitch
          platform={platform}
          handleChange={handleToggleClick}
          checked={toggleChecked}
          loading={toggleLoading}
        />
      </UserRow>
    </ListItemWrapper>
  );
};

// padding: ${props => (props.platform === 'desktop' ? '0 4.5rem 0' : '0 1rem')};
//   @media (max-width: 340px) {
//     padding: 0 0.5rem;
//   }
const UserRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.platform === 'desktop' ? '0 3.5rem' : '0rem')};
  min-height: 3rem;
  width: 100%;
  div {
    display: flex;
    button, span {
      word-break: break-all;
      flex: 1 1 auto;    
      display: inline-flex;
      align-content: center;
    }
    a {
      margin-right: 0.375rem;
      word-break: break-all;
      flex: 1 1 auto;    
      display: inline-flex;
      align-content: center;
    }
  }
  @media (max-width: 340px) {
    div {
      a {
        img {
          position: relative;
          left: -3px;
        }
      }
    }
  }
`;

export default AccessListItem;
