import React from 'react';
import { Title, Header, SubTitle } from './SACompareStylesMob';

const SACompareTitle = (props) => {
  return (
    <Header id="Header-Title-Mob">
      <SubTitle id="SubTitle-Mob">{props.subTitle}</SubTitle>
      <Title id="Title-Mob" dangerouslySetInnerHTML={{ __html: props.title }} />
    </Header>
  );
};

export default SACompareTitle;
