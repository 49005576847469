import ActionTypes from "../constants/ActionTypes";
import Config from "../config/Config";

export function getConfig() {
    let start = () => ({
        type: ActionTypes.START_CONFIG_FETCH
    }),
    complete = (config) => ({
        type: ActionTypes.COMPLETE_CONFIG_FETCH,
        config
    }),
    error = (err) => ({
        type: ActionTypes.COMPLETE_CONFIG_WITH_ERROR,
        err
    });
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(start());
            let config = new Config();
            dispatch(complete(config));
            return resolve(config);
        });
    }
}
