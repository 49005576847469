import {
  IconDocument,
  IconEnvelope,
  IconGear,
  IconHome,
  IconPeople,
  IconPrinter,
} from '@veneer/core';
import { pathOr } from 'ramda';
import {
  collapseSideMenu,
} from './collapseSideMenu';
import { SERVICES, HOME_PAGE } from '../../Dashboard/utils/constants';
import { serviceEnabled } from '../../Dashboard/utils/portalElements';
import Config from '../../../config/Config';

const config = new Config();
const featureFlags = config.Get('FEATURE_FLAGS');

function getPrintPlansMenuObject(printPlansActiveSubscription) {
  const baseMenuItemObject = {
    labelId: 'hpPrintPlans.headers.mainHeader',
    Icon: IconEnvelope,
    dataTestId: 'print-plans-menu',
  };

  if (printPlansActiveSubscription) {
    return {
      ...baseMenuItemObject,
      subMenus: [
        {
          pathname: '/print_plans',
          labelId: 'hpPrintPlans.headers.planOverview',
          dataTestId: 'plan-overview-submenu',
          onClick: collapseSideMenu,
        },
        {
          pathname: '/print_plans/my_account',
          labelId: 'hpPrintPlans.headers.changePlan',
          dataTestId: 'change-plan-submenu',
          onClick: collapseSideMenu,
        },
        {
          pathname: '/print_plans/print_history',
          labelId: 'hpPrintPlans.headers.printHistory',
          dataTestId: 'print-history-submenu',
          onClick: collapseSideMenu,
        },
        {
          pathname: '/print_plans/ink_shipment',
          labelId: 'hpPrintPlans.headers.shipmentTracking',
          dataTestId: 'shipment-tracking-submenu',
          onClick: collapseSideMenu,
        },
        {
          pathname: '/print_plans/account_history',
          labelId: 'hpPrintPlans.headers.planActivity',
          dataTestId: 'plan-activity-submenu',
          onClick: collapseSideMenu,
        },
      ],
    };
  }

  return {
    ...baseMenuItemObject,
    pathname: '/print_plans',
    onClick: collapseSideMenu,
  };
}

function getServicesMenuObject(portalElements) {
  let subMenus = [];

  if (featureFlags.ff_ucde_portal_services) {
    subMenus = [
      {
        pathname: '/services/summary',
        labelId: 'manageHPBenefits.headers.benefitsOverview',
        dataTestId: 'services-summary-submenu',
        onClick: collapseSideMenu,
      },
    ];
  }

  if (serviceEnabled(portalElements, SERVICES.PRINT_ON_THE_GO)) {
    subMenus = [
      ...subMenus,
      {
        key: SERVICES.PRINT_ON_THE_GO,
        pathname: '/services/print-on-the-go',
        labelId: 'manageHPBenefits.headers.printAnywhere',
        subscription: true,
        dataTestId: 'print-on-the-go-submenu',
        onClick: collapseSideMenu,
      },
    ];
  }

  if (serviceEnabled(portalElements, SERVICES.SMART_SECURITY)) {
    subMenus = [
      ...subMenus,
      {
        key: SERVICES.SMART_SECURITY,
        pathname: '/services/smart-security',
        labelId: 'manageHPBenefits.headers.securityMonitoring',
        subscription: true,
        dataTestId: 'smart-security-submenu',
        onClick: collapseSideMenu,
      },
    ];
  }

  return {
    labelId: 'manageHPBenefits.headers.mainHeader',
    Icon: IconGear,
    dataTestId: 'services-menu',
    subMenus,
  };
}

function getAccountDetailsMenuObject(printPlansActiveSubscription) {
  const menuItemObject = {
    labelId: 'myAccount.headers.mainHeader',
    Icon: IconDocument,
    dataTestId: 'my-account-menu',
    subMenus: [
      {
        pathname: '/account-details/profile',
        labelId: 'myAccount.profile.mainHeader',
        dataTestId: 'profile-submenu',
        onClick: collapseSideMenu,
      },
      {
        pathname: '/account-details/view-notifications',
        labelId: 'myAccount.headers.viewNotifications',
        dataTestId: 'view-notifications-submenu',
        onClick: collapseSideMenu,
      },
      {
        pathname: '/account-details/notification-settings',
        labelId: 'myAccount.notificationSettings.mainHeader',
        dataTestId: 'notification-submenu',
        onClick: collapseSideMenu,
      },
      {
        pathname: '/account-details/privacy-settings',
        labelId: 'myAccount.privacySettings.mainHeader',
        dataTestId: 'privacy-submenu',
        onClick: collapseSideMenu,
      },
    ],
  };

  if (printPlansActiveSubscription) {
    menuItemObject.subMenus.unshift(
      {
        pathname: '/account-details/billing-information',
        labelId: 'myAccount.headers.billing',
        dataTestId: 'billing-submenu',
        onClick: collapseSideMenu,
      },
      {
        pathname: '/account-details/shipping-information',
        labelId: 'myAccount.headers.shipping',
        dataTestId: 'shipping-submenu',
        onClick: collapseSideMenu,
      },
    );
  }

  return menuItemObject;
}

function getMenuItemsObject(opts = {}) {
  const {
    portalElements = {},
    printPlansActiveSubscription = false,
  } = opts;

  const menuItems = {
    printPlans: getPrintPlansMenuObject(printPlansActiveSubscription),
    myPrinters: {
      pathname: '/my-printers',
      labelId: 'myPrinters.headers.mainHeader',
      Icon: IconPrinter,
      dataTestId: 'my-printers-menu',
      onClick: collapseSideMenu,
    },
    manageUsers: {
      pathname: '/manage-users',
      labelId: 'manageUsers.topOfPage.mainHeader',
      Icon: IconPeople,
      dataTestId: 'manage-users-menu',
      onClick: collapseSideMenu,
    },
    // HP Benifits
    services: getServicesMenuObject(portalElements),
    // My Account
    accountDetails: getAccountDetailsMenuObject(printPlansActiveSubscription),
  };

  if (pathOr([], ['home', 'tags', 0], portalElements) === HOME_PAGE.SMART_DASHBOARD) {
    return {
      home: {
        pathname: '/',
        labelId: 'home.mainHeader',
        title: 'home.headers.accountSummary',
        Icon: IconHome,
        dataTestId: 'home-menu',
        onClick: collapseSideMenu,
      },
      ...menuItems,
    };
  }

  return menuItems;
}

export default getMenuItemsObject;
