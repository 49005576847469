import styled from 'styled-components';
import { stylesIf } from '../../../utils/styleHelpers';

export const Container = styled.section`
  margin: 2rem;
  order: 4;
  max-width: 100%;
  height: 100%;
  padding-bottom: 1rem;
`;

export const Title = styled.h4`
  font-size: 1.875rem;
`;

export const Preamble = styled.p`
  font-size: 1.25rem;
  margin: 0;
`;


export const TitleImage = styled.img`
  max-width: 48px;
`;

export const PaddedSpan = styled.span`
  padding-left: ${stylesIf('hasImage', '1rem', '0')};
`;

export const HeaderText = styled.header`
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 2.25rem;
  @media screen and (min-width: 40rem) {
    margin-bottom: 0;
    padding-bottom: 2rem;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  order: 1;
  padding-bottom: 2rem;
  width: 100%;
  height: 100%;
  max-width: 80%;
  flex: 1 1 auto;
  @media (min-width: 40rem) {
    :first-child {
      margin-right: ${stylesIf('main', 0, '2rem')};
    }
    :nth-child(2) {
      margin-left: 2rem;
    }
    flex: 1 1 50%;
    max-width: 50%;
    order: 2;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  height: fit-content;
  ${stylesIf(
    'twoPics',
    `${ImageWrapper} {
      padding: 0 2rem;
      :nth-of-type(2) {
        order: 3;
      }
    }
    ::after {
      order: 2;
      width: 15rem;
      content: '';
      margin: 1rem 0;
      height: 1px;
      background: black;
    }`,
  )}
  @media (min-width: 40rem) {
    min-width: 40rem;
    ${stylesIf(
    'twoPics',
    `align-items: flex-end;
      ::after {
        order: 2;
        height: 15rem;
        margin-bottom: 5rem;
        content: '';
        width: 1px;
        background: black;
      }`,
  )}
    margin-top: 2rem;
    flex-direction: row;
    margin-top: 0;
  }
`;


export const ImageCaption = styled.div`
  &&& {
    order: 0;
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
    padding-bottom: 1rem;
    margin-right: 0;
    @media (min-width: 40rem) {
      order: 2;
    }
  }
`;

export const Image = styled.img`
  opacity: 1;
  max-width: 100%;
  width: 100%;
  height: 100%;
  @media (min-width: 40rem) {
    order: 1;
  }
`;

export const Body = styled.div`
  && {
    order: 2;
    height: 100%;
    width: 100%;
    p, li, ol {
      font-size: 1.25rem;
    }
    li {
      padding-bottom: 0.5rem;
    }
    ol {
      text-decoration: initial;
      list-style: decimal;
    }
    flex: 1 1 auto;
    @media (min-width: 40rem) {
      padding-top: 2rem;
      padding-top: 0;
      flex: 1 1 ${stylesIf('main', 'auto', '50%')};
      :first-child {
        margin-right: ${stylesIf('main', 0, '2rem')};
      }
      :nth-child(2) {
        margin-left: 2rem;
      }
    }
  }
`;

export const Footer = styled.p`
  text-align: center;
  font-size: 1.25rem;
  flex: 1 1 auto;
`;
