import React from 'react';
import { useSelector } from 'react-redux';
import TopNav from './TopNav/index';
import SideNav from './SideNav/index';
import Footer from './Footer';
import PortalTakeover from './PortalTakeover';
import { selectPortalProps } from '../Dashboard/store/modules/portal/selectors';
import Loader from '../Dashboard/components/shared-components/Loader';

const Portal = ({ match }) => {
  const { loading, takeover } = useSelector(selectPortalProps);

  if (loading) {
    return (
      <Loader fullScreen />
    );
  }

  if (takeover) {
    return <PortalTakeover />;
  }

  return (
    <React.Fragment>
      <TopNav />
      <SideNav match={match} />
      <Footer />
    </React.Fragment>
  );
};

export default Portal;
