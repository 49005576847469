import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import EssentialsMessaging from './../../product-page/EssentialsMessaging';

const StyledPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 4rem;
  text-align: center;
  width: 17.5rem;
  h4 { 
    font-size: 1.375rem;
    @media (min-width: 40rem) {
      font-size: 1.5rem;
    }
    @media (min-width: 64rem) {
      font-size: 2rem;
    }
  }
  @media (min-width: 40rem) {
    width: 22.5rem;
  }
`;
const CenteredDescription = styled(EssentialsMessaging)`
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

const ImageWrap = styled.div`
  margin-bottom: 1rem;
  height: 100%;
  @media (min-width: 40rem) {
    margin-bottom: 3rem;
    flex: 1 1 auto;
  }
  img {
    width: 100%;
  }
`;

const Animation = styled.div`
  margin-bottom: 1rem;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  @media (min-width: 40rem) {
    margin-bottom: 2rem;
    flex: 1 1 17.1875rem
  }
`;

export default class SliderPanel extends React.Component {
  constructor(props) {
    super(props);

    this.sliderElementRef = null;

    this.setRef = element => {
      this.sliderElementRef = element;
    }
  }

  resizeWhenReady = () => {
    let resizer = setInterval(() => {
      if (this.sliderElementRef.clientHeight !== 0) {
        if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
          const evt = document.createEvent('UIEvents');
          evt.initUIEvent('resize', true, false, window, 0);
          window.dispatchEvent(evt);
        } else {
          window.dispatchEvent(new Event('resize'));
        }
        clearInterval(resizer);
      }
    }, 500);
  };

  
  render() {
    const direction = window.location.href.indexOf('/ar/') !== -1 ? 'rtl' : 'ltr'
    return (
    <StyledPanel>
      {this.props.imgSrc ?
        <ImageWrap>
          <img
            src={this.props.imgSrc}
            ref={this.setRef}
            alt={this.props.imgAlt}
            onLoad={this.resizeWhenReady}
          />
        </ImageWrap>
        :
        <Animation
          data-group = {this.props.lightingGroup}
          >
          <Lottie
            options={
              {
                loop: true,
                autoplay: true, 
                path: this.props.animationSrc,
                renderer: 'svg',
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid meet'
                }
              }
            }
          />
        </Animation>
      }
      <h4 dir={direction} >{this.props.header}</h4>
      <CenteredDescription dangerouslySetInnerHTML={{ __html: this.props.desc }} />
    </StyledPanel>
    )
  }
};
