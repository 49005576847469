import React from 'react';
import { useSelector } from '../../../hooks';
import { selectAppBadgesContentStackData } from '../../../selectors/craft';

export default function AppStoreBadges() {

  const appBadgesContent = useSelector(selectAppBadgesContentStackData)?.data?.entries[0];
  const { landing_page_app_badges } = appBadgesContent ? appBadgesContent : {};

  const GenerateBadges = () => {
    return landing_page_app_badges.map((appPromoBadges, i) =>
      <a
        href={appPromoBadges.app_badge?.app_badge_link}
        key={i}
        id={appPromoBadges.app_badge?.app_badge_id}
        target="_blank"
      >
        <img src={appPromoBadges.app_badge?.app_badge_graphic?.url} alt={appPromoBadges.app_badge?.app_badge_graphic?.title} />
      </a>
    );
  }

  return (
    <div className="app-store-badges">
      <GenerateBadges />
    </div>
  );
}