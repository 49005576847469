import styled from 'styled-components';

const ArrowButton = styled.button`
  font-size: 2rem;
  line-height: 1;
  border-radius: 0;
  color: #0095D6;
  cursor: pointer;
  background-color: transparent;
  border: none;
  &:focus, &:hover {
    outline: 0;
    font-weight: bold;
  }
  &:disabled {
    color: #CCC;
    &:focus, &:hover {
      font-weight: normal;
    }
  }
`;
export const ArrowButtonRight = styled(ArrowButton)`
  padding: .25rem 0 .5rem 1.5rem;
`;
export const ArrowButtonLeft = styled(ArrowButton)`
  padding: .25rem 1.5rem .5rem 0;
`;