import microfrontends from './microfrontends/reducer';
import ucdeUser from './ucdeUser/reducer';
import ucdeDevices from './devices/reducer';
import ucdePortalElements from './portalElements/reducer';

export default {
  microfrontends,
  ucdeUser,
  ucdeDevices,
  ucdePortalElements,
};
