import {
  path,
  identity,
  propEq,
  isEmpty,
} from 'ramda';
import base64url from 'base64url';
import { createSelector } from 'reselect';
import { HPPLUS } from '../constants/featureFlags';

const SELECTORS_BASE = {
  selectConfigIsComplete: path(['config', 'isComplete']),
  selectFeatureFlags: path(['config', 'data', '_configs', 'FEATURE_FLAGS']),
  selectConfigClass: path(['config', 'data']),
};

export const selectConfigIsComplete = createSelector(
  SELECTORS_BASE.selectConfigIsComplete,
  identity,
);

export const selectFeatureFlagEnabled = createSelector(
  SELECTORS_BASE.selectFeatureFlags,
  (_, featureFlag) => featureFlag,
  (flagsDictionary, featureFlag) => {
    return propEq(featureFlag, 'true', flagsDictionary)
  }
);

export const selectHPPlusFeatureFlagEnabled = createSelector(
  SELECTORS_BASE.selectFeatureFlags,
  propEq(HPPLUS, 'true'),
);

export const selectSignOutURL = createSelector(
  SELECTORS_BASE.selectConfigClass,
  configClass => {
    if (isEmpty(configClass)) {
      return null;
    }
    return configClass.Get('LOGOUT_URL');
  },
);

export const selectSupportURLDictionary = createSelector(
  SELECTORS_BASE.selectConfigClass,
  configClass => {
    if (isEmpty(configClass)) {
      return {};
    }
    return configClass.Get('SUPPORT_URL_DICTIONARY');
  },
);

export const selectSignInURL = createSelector(
  SELECTORS_BASE.selectConfigClass,
  configClass => {
    if (isEmpty(configClass)) {
      return null;
    }
    return configClass.Get('LOGIN_URL');
  },
);

export const selectOrgamiAddPaperUrlFromConfig = createSelector(
  SELECTORS_BASE.selectConfigClass,
  (configClass) => {
    if (isEmpty(configClass)) {
      return null;
    }
    return configClass.Get('ORIGAMI_ADD_PAPER_TO_PLAN_URL');
  }
);

export const selectOnboardingConfigProps = createSelector(
  SELECTORS_BASE.selectConfigClass,
  configClass => {
    if (isEmpty(configClass)) {
      return {};
    }
    return {
      baseUrl: configClass.Get('AUTHZ_ONBOARDING_BASE_URL'),
      uiExtensionInfoId: 'ucde.user.portal.onboard',
    };
  },
);

export const selectAuthZLoginUrl = createSelector(
  SELECTORS_BASE.selectConfigClass,
  configClass => {
    if (isEmpty(configClass)) {
      return '';
    }
    return configClass.Get('AUTHZ_OPENID_LOGIN');
  },
);

export const selectAuthZCreateUrl = createSelector(
  SELECTORS_BASE.selectConfigClass,
  configClass => {
    if (isEmpty(configClass)) {
      return '';
    }
    return configClass.Get('AUTHZ_OPENID_CREATE');
  },
);

export const selectCurrentStack = createSelector(
  SELECTORS_BASE.selectConfigClass,
  configClass => {
    if (isEmpty(configClass)) {
      return '';
    }
    return configClass.Get('CURRENT_STACK');
  },
);

export const selectAuthZLoginUrlWithStateParam = createSelector(
  selectAuthZLoginUrl,
  url => {
    const handshake = String(Math.random());
    const state = base64url.encode(JSON.stringify({
      csrfToken: handshake,
    }));
    return `${url}${state}`;
  },
);
