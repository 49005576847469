import React from 'react';
import PropTypes from 'prop-types';
import PricingDetail from './PricingDetail/PricingDetail';
import { PricingSection, ValuePropContainer, ValuePropContent, PropImage, PropText } from './styles/StyleOrigamiPricing';

const propTypes = {
  valuePropSection: PropTypes.array.isRequired,
  pricingHeader: PropTypes.string.isRequired,
  pricingSubhead: PropTypes.string.isRequired,
  pricingDetail: PropTypes.array.isRequired
};

const defaultProps = {
  valuePropSection: [],
  pricingHeader: '',
  pricingSubhead: '',
  pricingDetail: []
};

const OrigamiPricing = (props) => {
  const { pricingHeader, pricingSubhead, pricingDetail, valuePropSection } = props;
  return (
    <>
      <PricingSection data-testid="origami-pricing-section">
        <h4 data-testid="origami-pricing-header">{pricingHeader}</h4>
        <p data-testid="origami-pricing-subhead">{pricingSubhead}</p>
      </PricingSection>
      <ValuePropContainer data-testid="value-prop-container">
        {valuePropSection.map(
          ({
            value_prop: {
              prop_img: { title: propImgTitle, url: propImgUrl },
              prop_text: propText,
              _metadata: { uid: id }
            }
          }) => {
            return (
              <ValuePropContent key={id} data-testid="value-prop-content">
                <PropImage>
                  <img src={propImgUrl} alt={propImgTitle} />
                </PropImage>
                <PropText dangerouslySetInnerHTML={{ __html: propText }} />
              </ValuePropContent>
            );
          }
        )}
      </ValuePropContainer>
      <PricingDetail data-testid="origami-pricing-detail"/>
    </>
  );
};

OrigamiPricing.propTypes = propTypes;
OrigamiPricing.defaultProps = defaultProps;
export default OrigamiPricing;
