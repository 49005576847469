import { Service } from 'axios-middleware';
import { push } from 'connected-react-router';
import createAxiosInstance from './api';
import userInfoMock from '../mock/user.json';
import Config from '../../../config/Config';
import { encodeRedirect } from '../utils/routing';
import portalElementsMock from '../mock/users-portal-elements.json';
import getUsersMock from '../mock/get-users.json';
import userIISubscriptionMock from '../mock/user-ii-subscription.json';
import getCountriesMock from '../mock/countries_get.json';
import { store } from '../../../index';
import { parseUcdeGatewayApiError } from '../utils/apiErrorHandling';
import { getStratusAccessToken, isJWebAuthPluginValid, jwebSignIn } from '../utils/auth';
import { refreshToken } from './HPCSession';

const mock = false;

const config = new Config();
const ucdeGatewayUrl = config.Get('UCDE_GATEWAY_URL');

const ucdeGatewayApi = createAxiosInstance(ucdeGatewayUrl);

const service = new Service(ucdeGatewayApi);

function setAuthorizationHeaderInInterceptor(interceptorConfig) {
  const { headers } = interceptorConfig;
  headers.Authorization = `Bearer ${getStratusAccessToken()}`;
}

const refreshController = {
  requestQueue: [],
  isRefreshing: false,
  isRedirecting: false,
  processRequests: () => {
    refreshController.requestQueue.forEach(request => {
      setAuthorizationHeaderInInterceptor(request.interceptorConfig);
      request.resolve(request.interceptorConfig);
    });

    refreshController.requestQueue = [];
  },
  clearQueue: () => {
    refreshController.requestQueue.forEach(request => {
      request.reject();
    });
  },
};

export const redirectToSignIn = () => {
  if (refreshController.isRedirecting) {
    return;
  }

  refreshController.isRedirecting = true;

  const reduxStore = store.getState();
  const match = reduxStore.router.location.pathname;
  const locale = match.substring(0, 7);

  const state = encodeRedirect(match);

  if (match.includes('ucde')) {
    store.dispatch(push((`${locale}ucde/signin?state=${state}`)));
  } else {
    store.dispatch(push((`${locale}gateway/signin?state=${state}`)));
  }
};

service.register({
  async onRequest(interceptorConfig) {
    if (refreshController.isRefreshing) {
      return new Promise((resolve, reject) => {
        refreshController.requestQueue.push({ interceptorConfig, resolve, reject });
      });
    }

    refreshController.isRefreshing = true;

    try {
      await refreshToken();
    } catch (err) {
      if (isJWebAuthPluginValid()) {
        await jwebSignIn();
        setAuthorizationHeaderInInterceptor(interceptorConfig);
      } else {
        refreshController.clearQueue();
        redirectToSignIn();
        return new Promise(() => {});
      }
    }

    refreshController.processRequests();
    refreshController.isRefreshing = false;

    return interceptorConfig;
  },
  onResponseError(error) {
    if (!error) {
      return;
    }

    const { response: { status } } = error;

    if (status === 401 || status === 403) {
      redirectToSignIn();
      return;
    }

    const currentStack = config.Get('CURRENT_STACK');

    if (currentStack === 'dev') {
      console.error(parseUcdeGatewayApiError(error));
    }
  },
});

export const setAuthorizationHeader = accessToken => {
  ucdeGatewayApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const getUserData = async () => {
  if (mock) {
    return { data: userInfoMock };
  }

  const response = await ucdeGatewayApi.get('/users/me');
  return response;
};

export const getUserIISubscription = async ({ hpId }) => {
  if (mock) {
    return userIISubscriptionMock;
  }

  const response = await ucdeGatewayApi.get(`/users/${hpId}/instant-ink/subscriptions`);
  return response.data;
};

export const getUserPortalElementsData = async () => {
  if (mock) {
    return { data: portalElementsMock };
  }

  const response = await ucdeGatewayApi.get('/users/me/portal-elements');
  return response;
};

export const inviteUser = async ({ email, id }) => {
  const response = await ucdeGatewayApi.post(`/users/accounts/${id}/userinvitations`, { email });

  return response.data;
};

export const deleteInvitation = async ({
  id,
  resourceId,
  callback,
}) => {
  const response = await ucdeGatewayApi.delete(`/users/accounts/${id}/userinvitations/${resourceId}`);
  if (callback) {
    callback();
  }
  return response;
};

export const getUserProfile = async callback => {
  if (mock) {
    return { data: userInfoMock };
  }

  const response = await ucdeGatewayApi.get('/users/profile');
  if (callback) {
    callback();
  }
  return response;
};

export const getUsers = async () => {
  if (mock) {
    return getUsersMock;
  }
  const response = await ucdeGatewayApi.get('/users');
  return response.data;
};

export const saveUserData = async userData => {
  const response = await ucdeGatewayApi.put(`/users/profile/${userData.resourceId}`, userData);
  return response;
};

export const getAllUserDevices = async () => {
  const response = await ucdeGatewayApi.get('/devices');
  return response;
};

export const getDeviceCompleteInfo = async ({ cloudId }) => {
  const response = await ucdeGatewayApi.get(`/devices/${cloudId}?info=complete`);
  return response;
};

export const getCountriesInfo = async () => {
  if (mock) {
    return getCountriesMock;
  }
  const response = await ucdeGatewayApi.get('/users/profile/countries-info');
  return response.data;
};

export const getAllDevicesStatus = async () => {
  const response = await ucdeGatewayApi.get('/devices/health');
  return response.data;
};

export const getDeviceHealth = async cloudId => {
  const response = await ucdeGatewayApi.get(`/devices/${cloudId}/health/device`);
  return response;
};

export const getConnectivity = async cloudId => {
  const response = await ucdeGatewayApi.get(`/devices/${cloudId}/health/connectivity`);
  return response;
};

export const getDeviceSuppliesInfo = async cloudId => {
  const response = await ucdeGatewayApi.get(`/devices/${cloudId}/supplies`);
  return response;
};

export const getDevicesTelemetry = async accountId => {
  const response = await ucdeGatewayApi.get(`/devices/${accountId}/telemetry/`);
  return response.data;
};
