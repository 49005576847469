import React, { useEffect } from 'react';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';

const CreateAccount = ({ loc }) => {

  useEffect(() => {
    window.location.href = loc;
  }, [])
  return (
    <LoaderBlock />
  );
}

export default CreateAccount;
