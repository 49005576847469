import styled from 'styled-components';
import {
  IconMinusCircle,
} from '@veneer/core';
// Importing ucde theme directly, since modal error is a standalone react component
import { UCDEMainTheme } from '../../pages/Dashboard/styles';

// some of this values will be refactored and the format of this definition maybe will be
// changed according the design instructions (e.g. another types of alerts)
const errorColor = '#C70000';

export const Icon = styled(IconMinusCircle)`
  && {
    fill: ${errorColor};
    overflow: unset;
  }
`;

export const Header = styled.div`
  text-align: center;
  h2 {
    font-family: ${UCDEMainTheme.main.hp.fontFamily};
  }
`;

export const Content = styled.div`
  text-align: center;
  
  > div {
    p {
      font-family: ${UCDEMainTheme.main.hp.fontFamilyLight};
      font-size: 14px;
      line-height: 24px;
      color: #464D50;
      margin: 0 20px 20px;
    }
  }
`;
