import React, { useEffect, useState } from 'react';
import { WhenJWebReady } from '@jweb/core';
import {
  Container,
  ImageAndContentContainer,
  SubTitle,
  Body,
  ButtonContainer,
  StyledButton,
  Graphic
} from './styles/GenericSupportStyles';
import { selectQueryParameters } from '../../selectors/routing';
import { selectGenericSupportContentStackData } from '../../selectors/craft';
import { useSelector } from '../../hooks';
import { RTL_LANGUAGES } from '../../constants/i18n';
import { getLocale } from '../../utils/globals';

const GenericSupport = () => {
  const [isIOS, setIOS] = useState(false);
  const [isAndroid, setAndroid] = useState(false);
  const primaryCallBack = 'hpsmart://reset/continue';
  const tertiaryCallback = 'hpsmart://reset/back';
  const queryParams = useSelector(selectQueryParameters);
  const { platform } = queryParams;
  const { language } = getLocale();
  const isRTL = RTL_LANGUAGES.includes(language);

  const checkHPSmartApp = async () => {
    const res = await WhenJWebReady;
    if (res) {
      setIOS(!!JWeb.isIOS);
      setAndroid(!!JWeb.isAndroid);
    }
  };

  useEffect(() => {
    // checks Jweb only when the platform value is not present in the query
    if (!platform) {
      checkHPSmartApp();
    }
  }, []);

  const isMobile = ['ios', 'android'].includes(platform) || isIOS || isAndroid;
  const generic_printer_support = useSelector(selectGenericSupportContentStackData);
  const csContent = generic_printer_support?.data?.entries[0];

  if (csContent) {
    const {
      generic_buttons: genericButtons,
      support_body: supportBody,
      support_graphic: {
        title: supportGraphicTitle,
        url: supportGraphicUrl
      },
      support_headline: supportHeadlineText
    } = csContent;

    const {
      buttons_group: {
        primary_button_text: primaryButtonText,
        secondary_button_text: secondaryButtonText,
        tertiary_button_text: tertiaryButtonText
      }
    } = genericButtons[0];

    return (
      <Container>
        {supportHeadlineText.match(/<[a-z]+>/) ? (
          <SubTitle
            dangerouslySetInnerHTML={{
              __html: supportHeadlineText
            }}
          />
        ) : (
          <SubTitle isRTL={isRTL}>{supportHeadlineText}</SubTitle>
        )}
        <ImageAndContentContainer>
          <Graphic id="graphic" src={supportGraphicUrl} alt={supportGraphicTitle} isRTL={isRTL} />
          <Body dangerouslySetInnerHTML={{ __html: supportBody }} isRTL={isRTL} />
        </ImageAndContentContainer>
        <ButtonContainer isRTL={isRTL}>
          <StyledButton
            onClick={async () => {
              window.location.assign(primaryCallBack);
            }}
            appearance="primary"
            id="find-all-printers"
            small={isMobile}
          >
            {primaryButtonText}
          </StyledButton>
          <StyledButton
            onClick={async () => {
              window.open('https://support.hp.com/go/document/ish_3967769-3956733-16', '_blank');
            }}
            appearance="secondary"
            id="hp-support"
            small={isMobile}
          >
            {secondaryButtonText}
          </StyledButton>
          <StyledButton
            onClick={async () => {
              window.location.assign(tertiaryCallback);
            }}
            appearance="tertiary"
            id="generic-support-close"
            small={isMobile}
          >
            {tertiaryButtonText}
          </StyledButton>
        </ButtonContainer>
      </Container>
    );
  }

  return null;
};

export default GenericSupport;
