import React from 'react';
import { path } from 'ramda';
import { useSelector } from 'react-redux';
import SmartSecurity from 'react-smartsecurity/lib/SmartSecurity';
import styled from 'styled-components';
import { Page } from '../../../layout';
import { getExchangeToken } from '../../../../api/HPCSession';
import { getCookie } from '../../../../../../utils/globals';
import selectSSProps from './selector';
import { STRATUS_ID_TOKEN } from '../../../../../../constants/cookieNames';
import { getStratusAccessToken } from '../../../../utils/auth';
import { Container } from '../../../../components/shared-components/styles';

const SSPage = styled(Page)`
  display: flex;
  padding: 0;
  margin: 0;
`;

const SmartSecurityPage = ({ gateway, match }) => {
  const deviceID = path(['params', 'deviceID'], match);
  const { country, language, stack, userID } = useSelector(selectSSProps);

  const authProvider = {
    getAccessToken: async (exchange) => {
      const token = getStratusAccessToken();
      if (exchange) {
        await getExchangeToken(token);
        return getStratusAccessToken();
      }
      return token;
    },
    idToken: async () => getCookie(STRATUS_ID_TOKEN),
  };
  return (
    <Container>
      <SSPage>
        {userID ? (
          <SmartSecurity
            gateway={gateway}
            stack={stack}
            country={country}
            language={language}
            deviceID={deviceID}
            userID={userID}
            authProvider={authProvider}
          />
        ) : (
          ''
        )}
      </SSPage>
    </Container>
  );
};

export default SmartSecurityPage;
