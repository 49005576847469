import React from 'react';
import { getHistory } from '../../../index';

export default function TextButton(props) {
  function onTextButtonClick(e) {
    if (props.hasOwnProperty('onClick')) {
      props.onClick(e);
    }
    let linkTo = (arg) => {
      if (arg.indexOf('http') !== -1) {
        let win = window.open(arg, '_self');
        win.focus();
      } else {
        let history = getHistory();
        history.push(arg);
      }
    };
    if (props.buttonLink !== undefined) linkTo(props.buttonLink);
  }

  return (
    <a onClick={onTextButtonClick} id={props.ID} className={props.buttonClass}>
      {props.buttonText}
    </a>
  );
}
