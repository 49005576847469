import styled from 'styled-components';
import { UCDEMainTheme } from '../../../../../../components/Dashboard/pages/Dashboard/styles';
import { stylesIf } from '../../../../../../utils/styleHelpers';

export const SuppliesLabel = styled.div`
  font-size: 10px;
  text-align: center;
  letter-spacing: 0;
  color: #404040;

  @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
    font-size: ${stylesIf('printersPage', '15px', '10px')};
  }
`;

export const Supplies = styled.div`
  position: relative;
  white-space: nowrap;
`;
