import React from 'react';
import SACompareMobileTable from './SmartAdvanceCompareMobile/SACompareTable';
import SACompareMobileTitle from './SmartAdvanceCompareMobile/SACompareTitle';
import SACompareWebTable from './SmartAdvanceCompare/SACompareTable';
import SACompareWebTitle from './SmartAdvanceCompare/SACompareTitle';
import react, { useState, useEffect } from 'react';
import { isEmpty, isNil } from 'ramda';
import { useSelector } from '../../../../hooks';
import { breakpoints } from '../../../../utils/styleHelpers';
import { selectContentStackDataByResource } from '../../../../selectors/craft';
import { HOME_SMART_ADVANCE_RESOURCE_NAME } from '../../../../constants/contentStackResources';
import {
  SACContainerDesktop,
  SACContainerMobile,
  FootnoteMob,
  FootnoteDesktop
} from './SmartAdvanceCompareAppStyles';

const SmartAdvanceCompareApp = ({ isRTLSupport, instantInkPrice }) => {
  const { smart_advance_home } = useSelector(selectContentStackDataByResource, [HOME_SMART_ADVANCE_RESOURCE_NAME]);
  const contentStackContent = smart_advance_home?.data?.entries[0];

  const breakpoint = parseInt(breakpoints.wide) * 16;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > breakpoint);
  const [contentStackData, setContentStackData] = useState(null);
  const resizeLayout = () => {
    return setIsDesktop(window.innerWidth > breakpoint);
  };

  useEffect(() => {
    setContentStackData(contentStackContent);
  }, [contentStackContent]);

  useEffect(() => {
    window.addEventListener('resize', resizeLayout);
    return () => {
      window.removeEventListener('resize', resizeLayout);
    };
  }, [window]);

  if (contentStackData) {
    const {
      smart_advance_section_heading: Heading,
      smart_advance_headline: AdvanceHeadline,
      smart_advance_footnote: Footnote,
      header_row: HeadingRow,
      header_description_row: HeaderDescriptionRow,
      feature_row: FeatureRow,
    } = contentStackData;

    return (
      <react.Fragment>
        {isDesktop ? (
          <SACContainerDesktop id="SAC-Compare-Desktop" isRTLSupport={isRTLSupport}>
            <SACompareWebTitle
              id="SA-Compare-Web-Title"
              subTitle={Heading}
              title={AdvanceHeadline}
            />
            {!isEmpty(FeatureRow) && !isNil(FeatureRow) ? (
              <SACompareWebTable
                id="SA-Compare-Web-Table"
                featureRow={FeatureRow}
                headerRow={HeadingRow}
                headerDescriptionRow={HeaderDescriptionRow}
                instantInkPrice={instantInkPrice}
              />
            ) : (
              ''
            )}
            <FootnoteDesktop id="Footnote-Desktop" dangerouslySetInnerHTML={{ __html: Footnote }} />
          </SACContainerDesktop>
        ) : (
          <SACContainerMobile id="SAC-Container-Mobile">
            <SACompareMobileTitle
              id="SA-Compare-Mobile-Title"
              subTitle={Heading}
              title={AdvanceHeadline}
            />
            {!isEmpty(FeatureRow) && !isNil(FeatureRow) ? (
              <SACompareMobileTable
                id="SA-Compare-Mobile-Table"
                featureRow={FeatureRow}
                headerRow={HeadingRow}
              />
            ) : (
              ''
            )}
            <FootnoteMob id="Footnote-Mob" dangerouslySetInnerHTML={{ __html: Footnote }} />
          </SACContainerMobile>
        )}
      </react.Fragment>
    );
  }
  return null;
};

export default SmartAdvanceCompareApp;
