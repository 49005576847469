import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.header`
	text-align: center;
	display: flex;
  justify-content: center;
  margin-top: 2.5rem;
  margin-bottom: 2.25rem;
  padding: 0 1.25rem;
  flex: 1 0 auto;
	@media screen and (min-width: 40rem) {
    margin-left: -3rem;
    margin-right: 0;
	}
	@media screen and (min-width: 64rem) {
		margin-bottom: 5rem;
	}
  align-items: center;
  > span {
    flex: 0 0 auto;
    width: 3rem;
    @media screen and (min-width: 40rem) {
      width: 3.5rem;
    }
    @media screen and (min-width: 64rem) {
      width: 4rem;
    }
    display: inline-block;
    margin-right: 1rem;
    img {
      width: 100%;
    }
  }
  h1 {
    text-align: left;
    line-height: 1.2;
    @media screen and (min-width: 40rem) {
      font-size: 3rem;
    }
    @media screen and (min-width: 64rem) {
      font-size: 3.75rem;
    }
  }
`

export default function PageHeadIcon(props) {
  return <StyledHeader>
    <span><img src={props.pageIcon} alt={props.pageIconAlt} /></span>
    <h1>{props.pageHeading}</h1>
  </StyledHeader>
}