import styled from 'styled-components';
import { UCDEMainTheme } from '../../pages/Dashboard/styles';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    justify-content: center;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  font-weight: bold;
  position: relative;
  padding-top: 12px;

  div {
    width: 60px;
    height: 60px;
  }

  img {
    &.hp-logo {
      position: absolute;
      width: 30px;
      height: 30px;
      bottom: 0;
      left: 40px;
      background: white;
      border-radius: 50%;
    }
    width: 66px;
    height: 50px;
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    font-size: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      width: 45px;
      height: 45px;
    }
  }
`;

export const WelcomeMessage = styled.h4`
  font-size: 40px;
  margin-left: 25px;
  color: ${props => (props.isHpPlus ? 'white' : UCDEMainTheme.main.hp.fontColor)};

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    font-size: 24px;
    margin-left: 12px;
    margin-top: 16px;
    text-align: center;
  }
`;

export default Content;
