import React from 'react';
import {
  propOr,
  prop,
  is,
} from 'ramda';
import Side from './Side';
import {
  Container,
  HeaderText,
  Title,
  Content,
  Footer,
  TitleImage,
  PaddedSpan,
  Preamble,
} from './PictureAndText.styles';

const PictureAndText = props => {
  const {
    title,
    left,
    right,
    preamble,
    footer,
    sectionID = '',
  } = props;

  const twoPics = Boolean(prop('source', left) && prop('source', right));
  const titleSource = prop('source', title);
  return (
    <Container id={sectionID}>
      <HeaderText>
        {
          title && (
            <Title>
              {
                titleSource && (
                  <TitleImage
                    src={titleSource}
                    alt={propOr('', 'alt', title)}
                  />
                )
              }
              <PaddedSpan hasImage={Boolean(titleSource)}>
                {
                  is(String, title)
                    ? title
                    : prop('text', title)
                }
              </PaddedSpan>
            </Title>
          )
        }
        {
          preamble && (
            <Preamble big={!title}>
              {
                preamble.match(/<[a-z]+>/)
                  ? <span dangerouslySetInnerHTML={{ __html: preamble }} />
                  : preamble
              }
            </Preamble>
          )
        }

      </HeaderText>
      <Content twoPics={twoPics}>
        {
          left && <Side {...left} /> // eslint-disable-line
        }
        {
          right && <Side {...right} /> // eslint-disable-line
        }
      </Content>
      {
        footer && (
          <Footer>
            {
              (is(String, footer) && footer.match(/<[a-z]+>/))
                ? <span dangerouslySetInnerHTML={{ __html: footer }} />
                : footer
            }
          </Footer>
        )
      }
    </Container>
  );
};

export default PictureAndText;
