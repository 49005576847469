import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { loadManifest, unloadManifest } from '../../utils/loadManifest';
import MicrofrontendActions from '../../store/modules/microfrontends/actions';
import Loader from '../shared-components/Loader';
import { selectLocaleParamsFromRoute } from '../../../../selectors/routing';
import { LoaderContent } from './styles';

const MicroFrontend = ({
  appName, globalFunction, history, manifest,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { language, country } = useSelector(selectLocaleParamsFromRoute);

  let microFrontendScripts = [];

  useEffect(() => {
    async function loadMicroFrontend() {
      const mfeArguments = {
        appName,
        globalFunction,
        history,
        manifest,
        language,
        country,
        setIsLoading,
      };

      microFrontendScripts = await loadManifest(mfeArguments);
      dispatch(MicrofrontendActions.addMicrofrontend(appName, microFrontendScripts));
    }

    loadMicroFrontend();

    return () => {
      unloadManifest(microFrontendScripts);
      dispatch(MicrofrontendActions.removeMicrofrontend(appName));
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <LoaderContent>
          <Loader />
        </LoaderContent>
      ) : <main id={appName} className={appName} />}
    </>
  );
};

MicroFrontend.propTypes = {
  appName: PropTypes.string.isRequired,
  globalFunction: PropTypes.string.isRequired,
  manifest: PropTypes.string.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MicroFrontend;
