import React from 'react';
import { SupportContainer, ImageContent, TextContent } from './styles';

export const Support = (props) => {
  return (
    <SupportContainer isRTL={props.isRTL}>
      <ImageContent>
        <img alt={props.title} src={props.image} />
      </ImageContent>
      <TextContent isRTL={props.isRTL}>
        <h3
          dangerouslySetInnerHTML={{
            __html: props.URL
          }}
        ></h3>
      </TextContent>
    </SupportContainer>
  );
};

export default Support;
