/* eslint-disable no-use-before-define */
import { is } from 'ramda';
import { createReducer } from '../utils/redux';
import * as CraftActions from '../actions/contentStack';
import { MOBILE_FAX_RESOURCE_NAME } from '../constants/contentStackResources';

const processIncomingContentStackData = ({ resource, data }) => {
  // This IF condition is to be revisited when we integrate Mobile Fax ContentStack data.
  if (resource === MOBILE_FAX_RESOURCE_NAME) {
    const { landing_page_hero_content } = data?.data?.entries?.[0];
    if (is(Array, landing_page_hero_content)) {
      const { hero_content: defaultOffer } = landing_page_hero_content.find(
        (entry) => entry.hero_content.close_link_id === 'noThanksHero'
      );
      const { hero_content: twentyFourMonthOffer } = landing_page_hero_content.find(
        (entry) => entry.hero_content.close_link_id === 'existingCustomer'
      );

      return {
        ...data?.data?.entries?.[0],
        landing_page_hero_content: {
          trial: defaultOffer,
          '24month': twentyFourMonthOffer
        }
      };
    }
    return {
      ...data?.data?.entries?.[0],
      landing_page_hero_content: {
        trial: landing_page_hero_content
      }
    };
  }

  return data;
};

const initialState = {};

const handlers = {
  [CraftActions.gotContentStackData]: putContentStackDataInState,
  [CraftActions.contentStackDataFailue]: sendContentStackDataErrorIntoState
};

const contentStackReducer = createReducer(initialState, handlers);
export default contentStackReducer;

function putContentStackDataInState(state, { resource, data }) {
  return {
    ...state,
    [resource]: processIncomingContentStackData({ resource, data })
  };
}

function sendContentStackDataErrorIntoState(state, { resource, error }) {
  return {
    ...state,
    [resource]: error
  };
}
