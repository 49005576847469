import styled from 'styled-components';
import { theme, breakpoints, smb_breakpoints } from '../../../utils/styleHelpers';

export const HeaderContainer = styled.div`
  && {
    background-color: #027aae;
    display: flex;
  }
`;

export const HeaderContent = styled.div`
  && {
    margin-top: 116px;
    ${(props) => (props.isRTL ? 'margin-left: auto' : 'margin-right: auto')};
    margin-bottom: 43px;
    ${(props) => (props.isRTL ? 'margin-right: 18px' : 'margin-left: 18px')};
    width: 345px;
    @media (min-width: ${smb_breakpoints.mobile}) {
      margin-top: 111px;
      ${(props) => (props.isRTL ? 'margin-left: auto' : 'margin-right: auto')};
      margin-bottom: 43px;
      margin-inline-start: 28px;
      width: 395px;
    }
    @media (min-width: ${breakpoints.bigtablet}) {
      margin-top: 111px;
      ${(props) => (props.isRTL ? 'margin-left: auto' : 'margin-right: auto')};
      margin-bottom: 43px;
      ${(props) => (props.isRTL ? 'margin-right: 23px' : 'margin-left: 23px')};
      width: 615px;
    }
    @media (min-width: ${smb_breakpoints.desktop}) {
      margin-top: 111px;
      ${(props) => (props.isRTL ? 'margin-left: auto' : 'margin-right: auto')};
      margin-bottom: 43px;
      ${(props) => (props.isRTL ? 'margin-right: 33px' : 'margin-left: 33px')};
      width: 700px;
    }
    @media (min-width: ${smb_breakpoints.large}) {
      margin-top: 111px;
      ${(props) => (props.isRTL ? 'margin-left: auto' : 'margin-right: auto')};
      margin-bottom: 43px;
      ${(props) => (props.isRTL ? 'margin-right: 61px' : 'margin-left: 61px')};
    }
    @media (min-width: ${smb_breakpoints.wide}) {
      margin-top: 111px;
      ${(props) => (props.isRTL ? 'margin-left: auto' : 'margin-right: auto')};
      margin-bottom: 43px;
      ${(props) => (props.isRTL ? 'margin-right: 352px' : 'margin-left: 352px')};
    }

    h1 {
      color: ${theme.colors.white};
      font-family: ${theme.fonts.familyLight};
      font-size: 33px;
      line-height: 41px;
      @media (min-width: ${smb_breakpoints.mobile}) {
        font-size: 38px;
        line-height: 44px;
      }
      @media (min-width: ${smb_breakpoints.desktop}) {
        font-size: 40px;
        line-height: 47px;
      }
    }
  }
`;
