import React, { useState } from 'react';
import {
  IconChevronDown,
} from '@veneer/core';
import {
  SectionContainer,
  SectionHeader,
  SectionToggle,
  SectionContent,
  AccordionContainer,
} from './styles';

const AccordionSection = ({ title, content, id, dataAnalyticsTag, analyticsHandler, small }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    if (dataAnalyticsTag && analyticsHandler) {
      analyticsHandler(dataAnalyticsTag)
    }
    setExpanded(!expanded)
  };

  return (
    <SectionContainer small={small}>
      <SectionHeader id={id} small={small}>
        <h4>
          {title}
        </h4>
        {
          content && (
            <SectionToggle
              onClick={toggleExpanded}
              className={expanded ? 'expanded' : ''}
            >
              <IconChevronDown />
            </SectionToggle>
          )
        }
      </SectionHeader>
      {
        content && (
          <SectionContent small={small} className={expanded ? 'expanded' : ''}>
            {content}
          </SectionContent>
        )
      }
    </SectionContainer>
  )
}

const Accordion = ({ sections = [], small }) => {

  return (
    <AccordionContainer small={small}>
      {
        sections.map(section => {
          if (!section.title) {
            return null;
          }
          return (
            <AccordionSection
              small={small}
              key={section.title}
              {...section}
            />
          );
        })
      }
    </AccordionContainer>
  );
};

export default Accordion;
