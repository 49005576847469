import styled, { keyframes } from 'styled-components';
import { breakpoints, stylesIf } from '../../../utils/styleHelpers';
import Button from '@veneer/core/dist/scripts/button';

export const StyledContainer = styled.div`
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-family: sans-serif;
  color: black;
  font-size: 0.9rem;
  line-height: 1.3;
`;

export const PiplContainerDesktop = styled.div`
  color: #212121;
  padding: 2rem 5rem 10rem 5rem;
  width: 100%;
`;

export const PiplContainerMobile = styled.div`
  padding: 0 1.25rem;
  color: #212121;
  margin-bottom: 4rem;
`;

export const Header = styled.header`
  text-align: center;
`;

export const SubTitle = styled.h2`
  font-size: 1rem;
  text-transform: none;
  font-weight: normal;
  margin: 0;
  text-align: left;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: #027aae;
  margin-top: 2rem;
  margin-bottom: 0;
  p {
    margin: 0;
  }
`;

export const DescriptionDesktop = styled.div`
  text-align: center;
  font-size: 0.875rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: #212121;
  p {
    margin: 0;
  }
`;

export const DescriptionMob = styled.div`
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 0.875rem;
  color: #212121;
`;

export const TableContainer = styled.div`
  box-shadow: 0rem 0.25rem 1rem rgba(33, 33, 33, 0.1);
  border-radius: 0.75rem;
  max-width: 100%;
  width: 95%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  margin: auto auto;
  background-color: #fff;
  p {
    display: inline-block;
    padding-left: 1%;
    margin-bottom: 0;
    position: absolute;
  }
`;

export const TableConatinerTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead > tr {
    background-color: transparent;
    font-weight: bolder;
  }
  th {
    border-bottom: 0.125rem solid #ddd;
    padding: 0.625rem;
    text-align: left;
    font-size: 1.25rem;
    font-weight: bold;
  }
  td {
    border-bottom: 0.0625rem solid #ddd;
    padding: 0.5rem 1.5rem;
  }
`;

export const FeatureSection = styled.div`
  box-shadow: 0rem 0.25rem 1rem rgb(34 34 34 / 15%);
`;

export const MobileConatiner = styled.div`
  overflow: auto;
  display: inline;
  font-size: 0.75rem;
  padding: 0rem;
`;

export const MobCols = styled.div`
  float: left;
  width: 50%;
  text-align: center;
  padding: 0.6rem 0.2rem 0.6rem 0.2rem;
  background: #eeeeee;
  border-left: 0.0625rem solid #dbdbdb;
  font-size: 0.85rem;
  font-weight: normal;
  vertical-align: middle;
  overflow-wrap: break-word;
  p {
    margin: 0.3125rem;
  }
  &:nth-child(1) {
    border-left: none;
  }
`;

export const FeatureTitle = styled.div`
  text-align: center;
  margin-top: 1.25rem;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  padding: 0.4rem;
`;

export const MobileSection = styled.div`
    display: flex;
    border-bottom: 0.0625rem solid #dbdbdb;
    &:last-child{
      > #Mob-Cols {
        p{
          font-size: 0.75rem;
          @media only screen and (max-width: ${breakpoints.tablet}) {
            font-size: 0.625rem;
          }
        }
      }
`;
