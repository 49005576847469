import React from 'react';
import {
  Card,
} from '@veneer/core';
import {
  is,
} from 'ramda';
import Accordion from '../Accordion';
import { Link } from '../../../../routing';
import { useSelector } from '../../../../../hooks';
import { selectHelpCenterMainProps } from '../../../store/modules/helpCenter/selector';
import {
  ContentContainer,
  Content,
  AccordionContent,
} from './styles';

const SubContent = ({ title, content }) => (content
  ? (
    <ContentContainer>
      {
        title && (
          <h5>
            {title}
          </h5>
        )
      }
      {
        content.match(/<[a-z]+>/)
          ? <Content dangerouslySetInnerHTML={{ __html: content }} />
          : <Content>{content}</Content>
      }
    </ContentContainer>
  )
  : null);

const HelpCenterMain = () => {
  const sections = useSelector(selectHelpCenterMainProps);

  return (
    <Card
      appearance="dropShadow"
      content={(
        <Accordion
          sections={sections.map(section => ({
            ...section,
            title: is(Object, section.title)
              ? <Link to={section.title.to}>{section.title.text}</Link>
              : section.title,
            content: section.content
              ? (
                <AccordionContent>
                  {
                    section.content.map(sub => (
                      <SubContent
                        key={sub.id}
                        title={sub.title}
                        content={sub.content}
                      />
                    ))
                  }
                </AccordionContent>
              )
              : undefined,
          }))}
        />
      )}
    />
  );
};

export default HelpCenterMain;
