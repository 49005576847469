import React from 'react';
import { useState, useEffect } from 'react';

import {
  StyledContainer,
  PiplContainerDesktop,
  PiplContainerMobile,
  Header,
  Title,
  SubTitle,
  DescriptionDesktop,
  DescriptionMob,
  TableContainer,
  TableConatinerTable,
  FeatureSection,
  MobileConatiner,
  MobCols,
  FeatureTitle,
  MobileSection
} from './styles/StylePiplConsents';

import { breakpoints } from '../../utils/styleHelpers';

import { useSelector } from '../../hooks';
import { selectContentStackDataByResource } from '../../selectors/craft';
import { PIPL_CONSENT_RESOURCE_NAME } from '../../constants/contentStackResources';

const CHINA_COUNTRY_CODE = 'cn';
const CHINESE_LANG_CODE = 'zh';

const PIPLConsents = (props) => {
  const breakpoint = parseInt(breakpoints.wide) * 16;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= breakpoint);
  const resizeLayout = () => {
    return setIsDesktop(window.innerWidth >= breakpoint);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeLayout);
    return () => {
      window.removeEventListener('resize', resizeLayout);
    };
  }, [window]);

  const {
    match: {
      params: { country, language }
    },
    history
  } = props;
  if (country !== CHINA_COUNTRY_CODE || language !== CHINESE_LANG_CODE) history.push('/');

  const { consents } = useSelector(selectContentStackDataByResource, [PIPL_CONSENT_RESOURCE_NAME]);
  const consent_data = consents?.data?.entry;

  if (consent_data) {
    const { smart_suite_body, header_row, first_row, second_row, continue_button_text } =
      consent_data;
    const { body_text, secondary_body_text, supplimental_text } = smart_suite_body[0].body;

    return (
      <StyledContainer>
        {isDesktop ? (
          <PiplContainerDesktop>
            <Header>
              <SubTitle dangerouslySetInnerHTML={{ __html: body_text }} />
              <Title dangerouslySetInnerHTML={{ __html: secondary_body_text }} />
            </Header>
            <DescriptionDesktop dangerouslySetInnerHTML={{ __html: supplimental_text }} />
            <TableContainer>
              <TableConatinerTable>
                <thead>
                  <tr>
                    {header_row.map((header) => {
                      const header_data = header.header_title.column_title;
                      return <th dangerouslySetInnerHTML={{ __html: header_data }}></th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {first_row.map((first_row_element, index) => {
                      const first_row_data = first_row_element.column_description.description;
                      return (
                        <td
                          dangerouslySetInnerHTML={{
                            __html:
                              index === first_row.length - 1
                                ? `<a href="${first_row_data}">${first_row_data}</a>`
                                : first_row_data
                          }}
                        ></td>
                      );
                    })}
                  </tr>
                  <tr>
                    {second_row.map((second_row_element, index) => {
                      const second_row_data = second_row_element.column_description.description;
                      return (
                        <td
                          dangerouslySetInnerHTML={{
                            __html:
                              index === second_row.length - 1
                                ? `<a href="${second_row_data}">${second_row_data}</a>`
                                : second_row_data
                          }}
                        ></td>
                      );
                    })}
                  </tr>
                </tbody>
              </TableConatinerTable>
            </TableContainer>
          </PiplContainerDesktop>
        ) : (
          <PiplContainerMobile>
            <Header>
              <SubTitle dangerouslySetInnerHTML={{ __html: body_text }} />
              <Title dangerouslySetInnerHTML={{ __html: secondary_body_text }} />
            </Header>
            <DescriptionMob dangerouslySetInnerHTML={{ __html: supplimental_text }} />
            <MobileConatiner>
              {header_row.map((header, index) => {
                const header_data = header.header_title.column_title;
                const first_row_data = first_row[index].column_description.description;
                const second_row_data = second_row[index].column_description.description;
                return (
                  <FeatureSection>
                    <FeatureTitle dangerouslySetInnerHTML={{ __html: header_data }} />
                    <MobileSection>
                      <MobCols
                        dangerouslySetInnerHTML={{
                          __html:
                            index === first_row.length - 1
                              ? `<a href="${first_row_data}">${first_row_data}</a>`
                              : first_row_data
                        }}
                      />
                      <MobCols
                        dangerouslySetInnerHTML={{
                          __html:
                            index === second_row.length - 1
                              ? `<a href="${second_row_data}">${second_row_data}</a>`
                              : second_row_data
                        }}
                      />
                    </MobileSection>
                  </FeatureSection>
                );
              })}
            </MobileConatiner>
          </PiplContainerMobile>
        )}
      </StyledContainer>
    );
  }

  return null;
};

export default PIPLConsents;
