import styled from 'styled-components';
import { stylesIf } from '../../../../../utils/styleHelpers';
import { Link } from '../../../../routing';
import { UCDEMainTheme } from '../../../pages/Dashboard/styles';

export const PrinterGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const PrinterCardFooter = styled(PrinterGroup)`
  background-color: #f9f9f9;
  border-radius: 0 0 16px 16px;
  font-size: 12px;
  min-height: 24px;

  @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
    font-size: 14px;
    min-height: 32px;
  }
`;

export const StyledLink = styled(Link)`
  color: ${UCDEMainTheme.main.hp.color};
  font-family: ${UCDEMainTheme.main.hp.fontFamily};
  display: block;
  font-size: 12px;
  text-align: center;

  @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
    font-size: 14px;
  }
`;

export const PrinterGroupImage = styled(PrinterGroup)`
  display: block;
  text-align: center;
  width: 58px;
  img {
    position: relative;
    width: 58px;
    height: auto;
  }

  @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
    width: 100px;
    img {
      width: 100px;
    }
  }
`;

export const PrinterName = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-align: left;
  letter-spacing: 0.25px;
  color: #404040;
  word-break: break-word;
  overflow-wrap: anywhere;

  @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
    font-size: ${stylesIf('printersPage', '24px', '14px')};
    line-height: ${stylesIf('printersPage', '26px', '16px')};
  }
`;

export const PrinterMeta = styled(PrinterGroup)`
  flex-direction: row;
  flex: 1 1 66%;
  max-width: 66%;
  justify-content: flex-start;
  padding: ${stylesIf('printersPage', '16px 8px 16px 16px', '16px 8px')};

  @media (min-width: ${UCDEMainTheme.breakPoints.md}) {
    padding: ${stylesIf('printersPage', '32px 16px 32px 32px', '32px 8px')};
  }

  ${StyledLink} {
    width: 58px;
  }

  @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
    ${StyledLink} {
      width: 100px;
    }
  }
`;

export const PrinterGroupSupplies = styled(PrinterGroup)`
  max-width: auto;
  border-left: 1px solid #e9e5e5;
  flex: 1 1 33%;
  max-width: 33%;
  flex-direction: column;
  justify-content: center;
  padding: 16px 8px;

  @media (min-width: ${UCDEMainTheme.breakPoints.md}) {
    padding: ${stylesIf('printersPage', '32px 32px 32px 16px', '32px 8px')};
  }
`;

export const PrinterCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${stylesIf('printersPage', 'space-between', 'center')};
  ${stylesIf('printersPage', '', 'padding: 0 50px;')}

  @media (max-width: ${UCDEMainTheme.breakPoints.md}) {
    padding: 0;
  }

  ${PrinterMeta},
  ${PrinterGroupSupplies} {
    ${stylesIf('noPaddingTop', 'padding-top: 16px;', '')}
    ${stylesIf('noPaddingBottom', 'padding-bottom: 0;', '')}
  }
`;

export const PrinterGroupText = styled(PrinterGroup)`
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${stylesIf('printersPage', '16px', '8px')};

  @media (min-width: ${UCDEMainTheme.breakPoints.lg}) {
    margin-left: 32px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Spinner = styled.img`
  width: 48px;
  height: 48px;
  @keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }
  animation: spin .5s linear infinite;
`;
