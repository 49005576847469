import styled from 'styled-components';
import { breakpoints, theme } from '../../../../utils/styleHelpers';

export const PricingDetailSection = styled.div`
  width: 100%;
  font-weight: 400;
  background-color: #F8F8F8;
  padding-left: 190px;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: left;
  align-self: left;

  @media (max-width: 1440px) {
    padding-left: 90px;
  }

  @media (max-width: 1366px) {
    padding-left: 90px;
  }

  @media (max-width: 768px) {
    padding-left: 40px;
  }

  @media (max-width: 390px) {
    padding-left: 0px;
  }

  p {
    font-family: 'Forma DJR', sans-serif;
    margin-bottom: 0;
    font-size: 12px;
  }
  h3 {
    font-family: 'Forma DJR', sans-serif;
    margin-bottom: 0;
    font-size: 16px;
  }
`;
