import React from "react";
import LandingPageSectionHead from "./LandingPageSectionHead";
import { TweenLite } from 'gsap';
import Button from '../shared-components/atoms/Button';
import Accordion from "../shared-components/organisms/Accordion";
import styled from "styled-components";
const FAQ = styled.div`
  margin: 0.75rem 0 1.5rem;
  @media screen and (min-width:${props => props.theme.small}) {
    margin: 6.75rem 0 4rem;
  }
  @media screen and (min-width:${props => props.theme.medium}) {
    margin: 6.75rem 0 4rem;
  }
  @media screen and (min-width:${props => props.theme.large}) {
    margin-top: 7.625rem;
  }
  @media screen and (min-width:${props => props.theme.xlarge}) {
    margin-top: 12rem;
  }
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`
const FAQEntries = styled.div`
  max-width: 89%;
  @media screen and (min-width:${props => props.theme.medium}) {
    max-width: 46rem;
    margin: .625rem 0 2.75rem;;
  }
  @media screen and (min-width:${props => props.theme.xlarge}) {
    max-width: 60.75rem;
  }
  background-color: white;
  border: .125rem solid #CCC;
  border-radius: 0.3125rem;
  margin: 0 0 1.3125rem;
  display: flex;
  flex-direction: column;
  ul, ol { padding-left: 1rem; }
`
const FaqEntry = styled.div`
  margin: 0;
  border-bottom: 0.125rem solid #CCC;
  line-height: 1.5;
  &:last-child {
    border-bottom: none;
  }
  div div div {
    padding: 0 1rem;
    > * {
      font-size: 0.9375rem;
      line-height: 1.5;
      @media screen and (min-width:${props => props.theme.medium}) {
        font-size: 1.25rem;
      }
    }
  }
`
const FaqQuestion = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &:focus {
    outline: none;
    p {
      color: ${props => props.theme.hpBlue ? props.theme.hpBlue : black};
    }
  }
  img { margin-left: 1rem; }
  padding: 1rem;
  @media screen and (min-width:${props => props.theme.medium}) {
    font-size: 1.25rem;
    align-items: center;
  }
  p { margin: 0 }
  ${props => props.theme.boldFontFamilyAndWeight}
`
const AccordionContent = styled.div`
  padding: 0;
  > *:first-child { margin-top: 0; }
`
const ExitLink = styled.a`
  ${props => props.theme.boldFontFamilyAndWeight}
  &:hover, &:focus {
    color: ${props => props.theme.hpBlue};
    text-decoration: none;
  }
  font-size: 1.125rem;
  text-align: center;
  margin-top: 1rem;
  max-width: 100%;
  padding: 0 1.25rem;
  @media screen and (min-width:${props => props.theme.medium}) {
    margin-top: 1.4375rem;
  }
  @media screen and (min-width:${props => props.theme.medium}) {
    margin-top: 2.125rem;
  }
  text-decoration: none;
`
// export default function LandingPageFAQ(props) {
// const [accordionID, setAccordionID] = useState(null)
// const [group, setGroup] = useState("faq")
// const controller = { groupControl: { identifier: accordionID, group: group } }
class LandingPageFAQ extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      groupControl: { identifier: "", group: "" }
    }
  }

  callbackFn = (isOpen, thisID) => {
    if (isOpen) {
      this.props.tracking(`mobilefax_faq_accordion_${thisID}`)
    }
    const oneZero = isOpen ? 1 : 0
    const icn = `#fax_${thisID}_arrow`;
    TweenLite.to(icn, .41, { rotationX: -180 * oneZero });
  };

  groupFn = (o) => {
    this.setState({ groupControl: { identifier: o.myID, group: o.groupID } })
  }

  render() {
    return (
      <FAQ>
        {this.props.faqMeta.map(({ faq_meta }, i) =>
          <LandingPageSectionHead key={i}
            isMobile={this.props.isMobile}
            sectionIconMobile="/assets/landing-page/icon_conversation_mobile.svg"
            sectionIcon="/assets/landing-page/icon_conversation.svg"
            headline={faq_meta.faq_headline_text}
          />
        )}
        <FAQEntries>
          {this.props.faqContent.map(({ faq_entry }, i) => (
            <FaqEntry key={i}>
              <FaqQuestion
                id={`mobilefax_action_${i}`}
                groupID="faq"
                tabIndex={0}
                // data-tracking={faq_entry.faq_question_text}
                // active={this.props.active}
              >
                <p>{faq_entry.faq_question_text}</p>
                <img id={`fax_${i}_arrow`} src="/assets/landing-page/icon_accordion_state.svg" />
              </FaqQuestion>
              {}
              <Accordion
                ID={`${i}`}
                groupID="faq"
                actionID={`mobilefax_action_${i}`}
                controler={this.state.groupControl}
                groupFn={this.groupFn}
                callbackFn={this.callbackFn}
                invertEasing={false}
                addSeconds={0}
                offsetSeconds={0}
              >
                <AccordionContent dangerouslySetInnerHTML={{ __html: faq_entry.faq_answer_text }} />
              </Accordion>
            </FaqEntry>
          ))}
        </FAQEntries>
        <Button 
          buttonID={"moreFaqs"} 
          buttonType="secondary" newTab={true} 
          buttonText={this.props.faqMeta[0].faq_meta.faq_button_text} 
          buttonLink={this.props.faqMeta[0].faq_meta.faq_button_destination} 
          buttonIcon="/assets/landing-page/icon_exit.svg" 
        />
        <ExitLink 
          id={"faqSupportLink"} 
          href={this.props.faqMeta[0].faq_meta.faq_link_destination} 
          target="_blank"
          tabIndex="0"
        >
          {this.props.faqMeta[0].faq_meta.faq_link_text}
        </ExitLink>
      </FAQ>
    );
  }
}

export default LandingPageFAQ