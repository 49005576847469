import React, { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import EprintWhitelist from './EprintWhitelist';
import EprintBlacklist from './EprintBlacklist';
import EprintOpen from './EprintOpen';
import LoaderElement from '../../../shared-components/molecules/LoaderElement';
import ErrorBoundary from '../../../shared-components/atoms/ErrorBoundary';
import { useDispatch } from 'react-redux';
import {
  useSelector,
} from '../../../../hooks';
import {
  selectEprintAccessOptions,
} from '../../../../selectors/craft';
import {
  selectPrinterIdentificationMeta,
} from '../../../../selectors/myPrinter';
import { updateAccessOption } from '../../../../actions/myPrinter';
import { RTL_LANGUAGES } from '../../../../constants/i18n';

const PaddingRightButton = styled.button`
  padding-right: 1.25rem;
`

const EprintAccessOptions = props => {
  const language = window.location.pathname.split('/')[2];
  const isRTLSupport = RTL_LANGUAGES.includes(language);
  const dispatch = useDispatch();
  const [pendingOption, setPendingOption] = useState(null);
  const {
    ownershipId,
    printerId,
    generation,
  } = useSelector(selectPrinterIdentificationMeta);
  const accessSettingData = props.accessSettingData;
  const options = accessSettingData && [
    {
      label: accessSettingData.eprint_access_ota_label,
      description: accessSettingData.eprint_access_ota_description,
      id: 'eprint-access-open',
      key: 'open'
    },
    {
      label: accessSettingData.eprint_access_blocked_label,
      description: accessSettingData.eprint_access_blocked_description,
      id: 'eprint-access-blocked',
      key: 'blocked'
    },
    {
      label: accessSettingData.eprint_access_allowed_label,
      description: accessSettingData.eprint_access_allowed_description,
      id: 'eprint-access-allowed',
      key: 'allowed'
    }
  ];
  const {
    trackingHook,
    printerCurrentAccess,
    printer
  } = props;

  if (!options) {
    return null;
  }

  const handleClick = ({ id, key }) => {
    setPendingOption(key);
    trackingHook(id);
    dispatch(updateAccessOption({
      printerId,
      ownershipId,
      generation,
      callback: () => setPendingOption(null),
      accessOption: key,
    }));
  };

  const trackAdd = () => trackingHook('eprint-access-add');
  return (
    <div className="ePrintAccessChange">
      <form className="accessOptions">
        {
          options.map(({
            label,
            id,
            key,
          }) => (
            <PaddingRightButton
              key={key}
              id={id}
              type="button"
              className={classNames(
                'accessOptions--button',
                { 'accessOptions--button-rtl': isRTLSupport },
                { 'accessOptions--button-current': key === printerCurrentAccess },
              )}
              onClick={() => handleClick({ id, key })}
            >
              {label}
              {
                pendingOption === key
                && <LoaderElement />
              }
            </PaddingRightButton>
          ))
        }
      </form>
      <ErrorBoundary>
        {
          printerCurrentAccess === 'open'
          && <EprintOpen printer={printer} />
        }
        {
          printerCurrentAccess === 'blocked'
          && <EprintBlacklist printer={printer} trackAdd={trackAdd} />
        }
        {
          printerCurrentAccess === 'allowed'
          && <EprintWhitelist printer={printer} trackAdd={trackAdd} />
        }
      </ErrorBoundary>
    </div>
  );
};

export default EprintAccessOptions;
