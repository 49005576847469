import React from 'react';
import { LogoHp } from '@veneer/core';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useSelectorFormattedValue } from '../../hooks';
import { selectSureSupplyLearnContentStackData } from '../../selectors/craft';
import { Button } from '@veneer/core';
import { useHistory } from 'react-router';

export const Container = styled.div`
  padding: 1rem 2rem;
  color: black;
`;

export const LogoContainer = styled.div`
  text-align: center;
  padding: 1rem;
`;

export const ButtonContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  }
`;

const SureSupplyLearn = () => {
  const contentStack = useSelectorFormattedValue(selectSureSupplyLearnContentStackData);
  const [csData, setContentStackData] = useState(null);
  const history = useHistory();
  useEffect(() => {
    setContentStackData(contentStack);
  }, [contentStack]);

  if (csData) {
    const { smart_suite_body, back_button_text } = csData;
    return (
      <div>
        <Container id="conatiner">
          <LogoContainer id="logo-container">
            <LogoHp appearance="multicolor" size={100} />
          </LogoContainer>
          <div dangerouslySetInnerHTML={{ __html: smart_suite_body[0]?.body?.body_text }} />
        </Container>
        <ButtonContainer>
          <Button appearance="secondary" onClick={() => history.goBack()}>
            {back_button_text}
          </Button>
        </ButtonContainer>
      </div>
    );
  }
  return null;
};
export default SureSupplyLearn;
