
export const determineDest = ({
  signInURL,
  createUrl,
  encodedState,
  destination,
}) => {
  if (!destination) {
    return destination;
  }

  const dest = destination.toLowerCase();

  if (dest === 'createaccount') {
    return `${createUrl}${encodedState}`;
  }

  if (dest === 'signin') {
    return `${signInURL}${encodedState}`;
  }

  return destination;
};
