import React from 'react';
import styled from 'styled-components';
import { useSelector } from '../../hooks/redux';
import { selectMobileFaxSpecialOfferData } from '../../selectors/mobileFax';
import Button from '../shared-components/atoms/Button';
import { Link } from '../routing';

const SpecialOfferContainer = styled.div`
  && {
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5rem 10% 3rem;
    flex: 1 1 auto;
    padding: 2rem 2.125rem 0;
    background-color: white;
    > * {
      flex: 1 1 auto;
    }
    @media (max-width: ${props => props.theme.large}) {
      flex-direction: column;
      padding: 0;
    }
    @media (max-width: ${props => props.theme.small}) {
      margin: 1rem 1rem 2rem;
    }
    h2 { 
      margin: 0 0 .4375rem;
      font-size: 2.75rem;
      width: 100%;
      @media screen and (max-width:${props => props.theme.small}) {
        margin: 0 0 .75rem;
        font-size: 1.5rem;
        line-height: 1.125;
      }
      @media screen and (min-width:${props => props.theme.large}) {
        margin: 0 0 1.375rem;
      }
    }
    p { 
      font-size: 1.125rem;
      margin-bottom: 1.5rem;
      width: 100%;
      @media screen and (min-width:${props => props.theme.small}) {
        margin-bottom: 1.625rem;
        font-size: 1.375rem;
      }
      @media screen and (min-width:${props => props.theme.large}) {
        line-height: 1.5 !important;
        margin-bottom: 2;
        font-size: 1.5rem;
      }
      margin-top: 0;
    }
    img { margin-top: 0.875rem; }
  }
`;

const TextLink = styled(Link)`
  ${props => props.theme.boldFontFamilyAndWeight}
  font-size: 1rem;
  margin-top: 1rem;
  &:hover, &:focus {
    color: ${props => props.theme.hpBlue};
  }
  @media screen and (min-width:${props => props.theme.small}) {
    width: 14.5rem;
  }
  @media screen and (min-width:${props => props.theme.medium}) {
    width: 16rem;
  }
`;

const PictureElement = styled.picture`
  height: 100%;
  @media screen and (min-width:${props => props.theme.small}) {
    width: 78%;
    margin-left: auto;
  }
  @media screen and (min-width:${props => props.theme.large}) {
    width: 73%;
  }
`;
const BaseImage = styled.img`
  width: 100%;
  flex: 1 1 100%;
  position: relative;
`;

const PromoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
  h2 {
    margin: 0;
  }
  @media (max-width: ${props => props.theme.large}) {
    align-items: center;
    margin-left: 0;
    margin-top: 2rem;
    text-align: center;
  }
`;

const HorizontalThing = styled.div`
  width: 2rem;
  height: .25rem;
  background-color: ${({ theme }) => theme.hpBlue};
  margin: 1.25rem 0;
`;

const MobileFaxSpecialOffer = () => {
  const props = useSelector(selectMobileFaxSpecialOfferData);
  if (!props) {
    return null;
  }
  const {
    promoImage2xUrl,
    promoImageUrl,
    promoImageTitle,
    promo_headline,
    promo_description,
    button_text,
    link_text,
    button_destination,
    signin_link,
  } = props;

  return (
    <SpecialOfferContainer>
      <PictureElement>
        <source
          type="image/jpeg"
          media="(min-width: 1024px)"
          srcSet={`${promoImage2xUrl} 2x`}
        />
        <source
          type="image/jpeg"
          media="(max-width: 1023px)"
          srcSet={`${promoImageUrl} 2x`}
        />
        <BaseImage
          src={promoImageUrl}
          alt={promoImageTitle}
        />
      </PictureElement>
      <PromoTextContainer>
         <h2>{promo_headline}</h2>
        <HorizontalThing />
        <p>{promo_description}</p>
        <Button
          buttonText={button_text}
          buttonLink={button_destination}
          softButton
          widthOverride="10rem"
        />
        <TextLink to={signin_link}>
          {link_text}
        </TextLink>
      </PromoTextContainer>
    </SpecialOfferContainer>
  );
};

export default MobileFaxSpecialOffer;