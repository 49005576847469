import { createSelector } from 'reselect';
import { path, isEmpty } from 'ramda';
import { selectLocaleParamsFromRoute } from '../../../../../../selectors/routing';

const selectBaseUrlFromConfig = createSelector(
  path(['config', 'data']),
  (configClass) => {
    return isEmpty(configClass) ? null : configClass.Get('AUTHZ_ONBOARDING_BASE_URL');
  }
);

export const selectPrivacyProps = createSelector(
  selectBaseUrlFromConfig,
  selectLocaleParamsFromRoute,
  (baseUrlFromConfig, localeParamsFromRoute) => ({
    baseUrl: baseUrlFromConfig,
    language: localeParamsFromRoute.language,
    country: localeParamsFromRoute.country,
  })
);
