import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getOrigamiPageData } from '../../actions/origami';

import OrigamiLazyBanner from './LazyLoading/LazyOrigamiHero';
import OrigamiLazyHowItWorks from './LazyLoading/LazyHowItWorks';
import OrigamiLazyPricing from './LazyLoading/LazyPricing';
import OrigamiLazyValueProp from './LazyLoading/LazyValueProp';
import OrigamiLazyAdditionalFeatures from './LazyLoading/LazyAdditionalFeatures';
import OrigamiLazyFaqs from './LazyLoading/LazyFaqs';
import OrigamiLazyFootNotes from './LazyLoading/LazyFootnotes';

import OrigamiHero from './OrigamiHero';
import OrigamiHowItWorks from './OrigamiHowItWorks';
import OrigamiPricing from './OrigamiPricing';
import OrigamiValueProp from './OrigamiValueProp';
import OrigamiAdditionalFeatures from './OrigamiAdditionalFeatures';
import OrigamiFaqs from './OrigamiFaqs';

import SkeletonHero from './Skeleton/SkeletonHero';
import SkeletonHowItWorks from './Skeleton/SkeletonHowItWorks';
import SkeletonPricing from './Skeleton/SkeletonPricing';

import {
  LAZY_COMPONENT_SEC_1,
  LAZY_COMPONENT_SEC_2,
  LAZY_COMPONENT_SEC_3,
  LAZY_COMPONENT_SEC_4,
  LAZY_COMPONENT_SEC_5,
  LAZY_COMPONENT_SEC_6,
  LAZY_COMPONENT_SEC_7,
  ADDITIONAL_OFFSET
} from '../../constants/common';
import { 
  getQueryParams, 
  getURLWithQueryParams, 
  buildingUCDELinkWithCountryAndLanguage } from '../../utils/globals';
import { OrigamiContent } from './styles/StyleOrigami';
import { checkMobileScreen } from '../../utils/checkMobileScreen';

const propTypes = {
  widescreenPosterTitle: PropTypes.string,
  widescreenPosterUrl: PropTypes.string,
  mobilePosterTitle: PropTypes.string,
  mobilePosterUrl: PropTypes.string,
  heroHeading: PropTypes.string,
  heroSubheading: PropTypes.string,
  addPaperButtonText: PropTypes.string,
  enrollLinkText: PropTypes.string,
  enrollLinkChevronTitle: PropTypes.string,
  enrollLinkChevronUrl: PropTypes.string,
  changeEnrollText: PropTypes.string,
  dashedSeparatorTitle: PropTypes.string,
  dashedSeparatorUrl: PropTypes.string,
  howItWorksHeading: PropTypes.string,
  howItWorksSubhead: PropTypes.string,
  howItWorksVideoCode: PropTypes.string,
  howItWorksSection: PropTypes.array,
  pricingHeader: PropTypes.string,
  pricingSubhead: PropTypes.string,
  pricingDetail: PropTypes.array,
  valuePropSection: PropTypes.array,
  valuePropText: PropTypes.string,
  valuePropFreeMonthText: PropTypes.string,
  valuePropTextField: PropTypes.string,
  additionalFeaturesSection: PropTypes.array,
  faq: PropTypes.object,
  footnotesText: PropTypes.string
};

const Origami = (props) => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [localeVal, setLocaleVal] = useState('');
  const [page, setPage] = useState(LAZY_COMPONENT_SEC_1);
  const [origamiComponent, setOrigamiComponent] = useState([]);
  const [loadingFirst, setLoadingFirst] = useState(true);
  const [loadingApi, setLoadingApi] = useState(false);
  const [origamiData, setOrigamiData] = useState({});
  const checkMobile = checkMobileScreen();
  const { config, locales, origami } = props;

  if (localeVal != locales.languageCode + '-' + locales.countryCode) {
    const localValue = locales.languageCode + '-' + locales.countryCode;
    setLocaleVal(localValue);
  }
  
  const origamiAddPaperToPlanUrl = config.data.Get('ORIGAMI_ADD_PAPER_TO_PLAN_URL');
  const buttonDestination = buildingUCDELinkWithCountryAndLanguage(origamiAddPaperToPlanUrl, locales.countryCode, locales.languageCode);
  const enrollToInstantInkURL = config.data.Get('ORIGAMI_ENROLL_IN_INSTANT_INK_URL');

  const queryString = getQueryParams();
  const urlWithQueryString = getURLWithQueryParams(buttonDestination, queryString);

  const handleClick = useCallback(() => {
    if (buttonDestination?.includes('http') || buttonDestination?.includes('https')) {
      if (queryString) {
        window.open(urlWithQueryString, '_blank');
      } else {
        window.open(buttonDestination, '_blank');
      }
      return;
    }
  }, [urlWithQueryString]);

  const handleEnrollLink = useCallback(() => {
    if (enrollToInstantInkURL?.includes('http') || enrollToInstantInkURL?.includes('https')) {
      window.open(enrollToInstantInkURL, '_blank');
      return;
    }
  }, [enrollToInstantInkURL]);

  useEffect(() => {
    dispatch(getOrigamiPageData(localeVal));
    setLoadingApi(true);
  }, [localeVal]);

  if (origami.isFetched && (loadingFirst || loadingApi)) {
    if (origami.data.locale.includes(localeVal)) {
      setOrigamiData(origami.data);
      setLoadingFirst(false);
      setLoadingApi(false);
    }
  }

  useEffect(() => {
    if (origami.isFetched) fetchMoreListItems();
  }, [origamiData]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadingFirst]);

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
  }, [isFetching]);

  useEffect(() => {
    if (page == LAZY_COMPONENT_SEC_2 || (page == LAZY_COMPONENT_SEC_3 && checkMobile))
      fetchMoreListItems();
  }, [page, checkMobile]);

  function handleScroll() {
    const bottom =
      Math.ceil(
        window.innerHeight +
          (document.documentMode ? document.documentElement.scrollTop : window.scrollY) +
          ADDITIONAL_OFFSET
      ) >= document.documentElement.scrollHeight;
    if (!bottom || isFetching || loadingFirst) return;
    setIsFetching(true);
  }

  function fetchMoreListItems() {
    let componentSpace = Array();

    for (let num = 1; num < page; num++) {
      if (num == LAZY_COMPONENT_SEC_1) {
        componentSpace[num] = (
          <OrigamiHero
            widescreenPosterTitle={widescreenPosterTitle}
            widescreenPosterUrl={widescreenPosterUrl}
            mobilePosterTitle={mobilePosterTitle}
            mobilePosterUrl={mobilePosterUrl}
            heroHeading={heroHeading}
            heroSubheading={heroSubheading}
            addPaperButtonText={addPaperButtonText}
            enrollLinkText={enrollLinkText}
            enrollLinkChevronTitle={enrollLinkChevronTitle}
            enrollLinkChevronUrl={enrollLinkChevronUrl}
            changeEnrollText={changeEnrollText}
            handleClick={handleClick}
            handleEnrollLink={handleEnrollLink}
            key={LAZY_COMPONENT_SEC_1}
          />
        );
      }
      if (num == LAZY_COMPONENT_SEC_2) {
        componentSpace[num] = (
          <OrigamiHowItWorks
            dashedSeparatorTitle={dashedSeparatorTitle}
            dashedSeparatorUrl={dashedSeparatorUrl}
            howItWorksHeading={howItWorksHeading}
            howItWorksSubhead={howItWorksSubhead}
            howItWorksVideoCode={howItWorksVideoCode}
            howItWorksSection={howItWorksSection}
            key={LAZY_COMPONENT_SEC_2}
          />
        );
      }
      if (num == LAZY_COMPONENT_SEC_3) {
        componentSpace[num] = (
          <OrigamiPricing
            pricingHeader={pricingHeader}
            pricingSubhead={pricingSubhead}
            pricingDetail={pricingDetail}
            valuePropSection={valuePropSection}
            key={LAZY_COMPONENT_SEC_3}
          />
        );
      }
      if (num == LAZY_COMPONENT_SEC_4) {
        componentSpace[num] = (
          <OrigamiValueProp
            valuePropText={valuePropText}
            valuePropFreeMonthText={valuePropFreeMonthText}
            valuePropTextField={valuePropTextField}
            addPaperButtonText={addPaperButtonText}
            enrollLinkText={enrollLinkText}
            enrollLinkChevronTitle={enrollLinkChevronTitle}
            enrollLinkChevronUrl={enrollLinkChevronUrl}
            handleClick={handleClick}
            handleEnrollLink={handleEnrollLink}
            key={LAZY_COMPONENT_SEC_4}
          />
        );
      }
      if (num == LAZY_COMPONENT_SEC_5) {
        componentSpace[num] = (
          <OrigamiAdditionalFeatures
            additionalFeaturesSection={additionalFeaturesSection}
            key={LAZY_COMPONENT_SEC_5}
          />
        );
      }
      if (num == LAZY_COMPONENT_SEC_6) {
        componentSpace[num] = (
          <OrigamiFaqs
            faqHeading={faqHeading}
            showMoreButtonText={showMoreButtonText}
            showLessButtonText={showLessButtonText}
            key={LAZY_COMPONENT_SEC_6}
          />
        );
      }
    }

    if (page == LAZY_COMPONENT_SEC_1)
      componentSpace[page] = (
        <OrigamiLazyBanner
          widescreenPosterTitle={widescreenPosterTitle}
          widescreenPosterUrl={widescreenPosterUrl}
          mobilePosterTitle={mobilePosterTitle}
          mobilePosterUrl={mobilePosterUrl}
          heroHeading={heroHeading}
          heroSubheading={heroSubheading}
          addPaperButtonText={addPaperButtonText}
          enrollLinkText={enrollLinkText}
          enrollLinkChevronTitle={enrollLinkChevronTitle}
          enrollLinkChevronUrl={enrollLinkChevronUrl}
          changeEnrollText={changeEnrollText}
          handleClick={handleClick}
          handleEnrollLink={handleEnrollLink}
          key={LAZY_COMPONENT_SEC_1}
        />
      );
    if (page == LAZY_COMPONENT_SEC_2)
      componentSpace[page] = (
        <OrigamiLazyHowItWorks
          dashedSeparatorTitle={dashedSeparatorTitle}
          dashedSeparatorUrl={dashedSeparatorUrl}
          howItWorksHeading={howItWorksHeading}
          howItWorksSubhead={howItWorksSubhead}
          howItWorksVideoCode={howItWorksVideoCode}
          howItWorksSection={howItWorksSection}
          key={LAZY_COMPONENT_SEC_2}
        />
      );
    if (page == LAZY_COMPONENT_SEC_3)
      componentSpace[page] = (
        <OrigamiLazyPricing
          pricingHeader={pricingHeader}
          pricingSubhead={pricingSubhead}
          pricingDetail={pricingDetail}
          key={LAZY_COMPONENT_SEC_3}
        />
      );
    if (page == LAZY_COMPONENT_SEC_4)
      componentSpace[page] = (
        <OrigamiLazyValueProp
          valuePropSection={valuePropSection}
          valuePropText={valuePropText}
          valuePropFreeMonthText={valuePropFreeMonthText}
          valuePropTextField={valuePropTextField}
          addPaperButtonText={addPaperButtonText}
          enrollLinkText={enrollLinkText}
          enrollLinkChevronTitle={enrollLinkChevronTitle}
          enrollLinkChevronUrl={enrollLinkChevronUrl}
          handleClick={handleClick}
          handleEnrollLink={handleEnrollLink}
          key={LAZY_COMPONENT_SEC_4}
        />
      );
    if (page == LAZY_COMPONENT_SEC_5)
      componentSpace[page] = (
        <OrigamiLazyAdditionalFeatures
          additionalFeaturesSection={additionalFeaturesSection}
          key={LAZY_COMPONENT_SEC_5}
        />
      );
    if (page == LAZY_COMPONENT_SEC_6)
      componentSpace[page] = (
        <OrigamiLazyFaqs
          faqHeading={faqHeading}
          faqSection={faqSection}
          showMoreButtonText={showMoreButtonText}
          showLessButtonText={showLessButtonText}
          key={LAZY_COMPONENT_SEC_6}
        />
      );
    if (page == LAZY_COMPONENT_SEC_7)
      componentSpace[page] = (
        <OrigamiLazyFootNotes footnotesText={footnotesText} key={LAZY_COMPONENT_SEC_7} />
      );

    if (componentSpace.length > 0) setOrigamiComponent(componentSpace);
    if (page < LAZY_COMPONENT_SEC_7) setPage(page + 1);
    setIsFetching(false);
  }

  const {
    widescreen_poster,
    mobile_poster,
    hero_heading: heroHeading,
    hero_subheading: heroSubheading,
    add_paper_button_text: addPaperButtonText,
    enroll_link_text: enrollLinkText,
    enroll_link_chevron,
    change_enroll_text: changeEnrollText,
    dashed_separator,
    how_it_works_heading: howItWorksHeading,
    how_it_works_subhead: howItWorksSubhead,
    how_it_works_video_code: howItWorksVideoCode,
    how_it_works_section: howItWorksSection,
    pricing_header: pricingHeader,
    pricing_subhead: pricingSubhead,
    pricing_detail: pricingDetail,
    value_prop_section: valuePropSection,
    value_prop_text: valuePropText,
    free_month_text: valuePropFreeMonthText,
    value_prop_text_field: valuePropTextField,
    additional_features_section: additionalFeaturesSection,
    faq,
    footnotes_text: footnotesText
  } = origamiData ? origamiData : {};

  const { title: widescreenPosterTitle, url: widescreenPosterUrl } = widescreen_poster
    ? widescreen_poster
    : {};

  const { title: mobilePosterTitle, url: mobilePosterUrl } = mobile_poster ? mobile_poster : {};

  const { title: enrollLinkChevronTitle, url: enrollLinkChevronUrl } = enroll_link_chevron
    ? enroll_link_chevron
    : {};

  const { title: dashedSeparatorTitle, url: dashedSeparatorUrl } = dashed_separator
    ? dashed_separator
    : {};

  const {
    faq_heading: faqHeading,
    faq_section: faqSection,
    show_more_button_text: showMoreButtonText,
    show_less_button_text: showLessButtonText
  } = faq ? faq : {};

  return (
    <OrigamiContent data-testid="origami-content">
      {origamiComponent.map((item) => item)}
      {!isFetching && page == LAZY_COMPONENT_SEC_1 ? (
        checkMobile ? (
          <>
            <SkeletonHero />
            <SkeletonHowItWorks />
            <SkeletonPricing />
          </>
        ) : (
          <>
            <SkeletonHero />
            <SkeletonHowItWorks />
          </>
        )
      ) : (
        ''
      )}
    </OrigamiContent>
  );
};

Origami.propTypes = propTypes;

export default Origami;
