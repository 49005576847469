import styled from 'styled-components';
import { UCDEMainTheme } from '../../pages/Dashboard/styles';

export const AddUser = styled.div`
  vertical-align: top;
  margin-bottom: 30px;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: ${UCDEMainTheme.main.hp.fontFamily};
  font-size: ${UCDEMainTheme.main.hp.fontSize};
  justify-content: space-between;
`;

export const LoaderContent = styled.div`
  flex-basis: 100%;
`;

const colors = [
  '#027AAE', // Blue
  '#F1C029', // Yellow
  '#1C7A17', // Green
  '#D06702', // Orange
];

export function getColor(idx) {
  return colors[idx % colors.length];
}
