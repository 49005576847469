import React from 'react';
import { Link } from '../routing';

export default function AddPrinterButton(props) {

  return (
    <Link
      to={props.addPrinterSubmitLink}
      className="addPrinterButton"
      data-linkid={props.buttonID}
      id={props.buttonID}
    >
      <span className="addPrinterButton--text">
        {props.addPrinterButtonText}
      </span>
      <img
        className="addPrinterButton--icon"
        src={props.addPrinterIcon}
        alt={props.addPrinterIconTitle}
      />
    </Link>
  )

}