import {
  takeEvery, takeLeading, put, call,
} from 'redux-saga/effects';
import ucdeUserActions, {
  initializeUcdeUser,
  loadUcdeUserProfile,
  saveUcdeUserProfile,
} from './actions';
import { getUcdePortalElementsRequest } from '../portalElements/actions';
import { getStratusAccessToken, jwebSignIn } from '../../../utils/auth';
import { setAuthorizationHeader } from '../../../api/UCDEGateway';
import { push } from 'connected-react-router';

function* manageUserInitialization({ userType = 'ucde', path = '/ucde' }) {
  yield put(ucdeUserActions.loadUcdeUserRequest());

  if (userType === 'jweb') {
    yield call(jwebSignIn);
  }
  setAuthorizationHeader(getStratusAccessToken());

  yield put(ucdeUserActions.getUcdeUser);
  yield put(getUcdePortalElementsRequest());
  yield put(push(path));
}

function* retrieveUcdeUserProfile({ userType = 'ucde' }) {
  if (userType === 'ucde') {
    yield put(ucdeUserActions.getUcdeUserProfile);
  }
}

function* saveUcdeUser(action) {
  yield put(ucdeUserActions.saveUcdeUser(action.payload));
}

export function* watchInitializeUcdeUser() {
  yield takeLeading(initializeUcdeUser.toString(), manageUserInitialization);
}

export function* watchSaveUcdeUserProfile() {
  yield takeLeading(saveUcdeUserProfile.toString(), saveUcdeUser);
}

export function* watchLoadUcdeUserProfile() {
  yield takeEvery(loadUcdeUserProfile.toString(), retrieveUcdeUserProfile);
}
