import React from 'react';

export default function EmailPrintPreference(props) {

return <div className="ePrintPreference ePrintPreference-summary">
  <span className="ePrintPreference--name">{props.name}</span>
  <span className="ePrintPreference--value">{props.children}</span>
</div>
  
}
