import React, { useState } from "react";
import { TweenLite } from "gsap";
import styled from "styled-components";
import Accordion from "./../shared-components/organisms/Accordion";
import {
  SubAccordion,
  StyledSection,
  SubArrows,
} from "../shared-components/library-css/LibraryCss";
import { smartHelpTracking } from "./../../lib/analyticsTracking";

const StyledTip = styled.div`
  background-color: #e5f4fa;
  padding: 0.75rem;
`;
const StyledNote = styled.div`
  background-color: #fff6ce;
  padding: 0.75rem;
`;

const StyledIOSSection = styled.div`
  @media screen and (max-width: 812px) {
    margin-left: 0.50rem;
  }
`;

const MenuContent = (props) => {
  const section = props.section;
  const sectionId = props.ID;
  let subAccordionsOpen = true;

  const LEARN_MORE_ANALYTICS_ID = 'Learn More';

  const [groupControl, setGroupControl] = useState({
    identifier: "",
    group: "",
  });

  const groupFn = (o) => {
    setGroupControl({ identifier: o.myID, group: o.groupID });
  };

  const callbackFn = (isOpen, thisID) => {
    if (isOpen) {
      smartHelpTracking(thisID);
    }

    const oneZero = isOpen ? 1 : 0;
    const icn = `#${thisID}_arrow`;
    TweenLite.to(icn, 0.41, { rotationX: -180 * oneZero });
  };
  return (
    <Accordion
      ID={sectionId}
      actionID={props.actionID}
      groupID="menuContent"
      groupFn={props.groupFn}
      controler={props.controler}
      callbackFn={props.callbackFn}
      invertEasing={false}
      addSeconds={0.25}
      offsetSeconds={0.13}
      inSmartHelp={true}
      noCloseFn={props.is_in_MobileFn}
      startOpen={props.isOpenOnLoad}
    >
      <StyledSection isMobile={props.isMobile}>
        {props.isHPPlusCountry === 'true' ? section.helpSection.help_for_all_apps && (
          <SubAccordion
            isMobile={props.isMobile}
            currentPlatform={props.currentPlatform}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: section.helpSection.help_for_all_apps,
              }}
            />
            {section.helpSection.help_tip && (
              <StyledTip
                dangerouslySetInnerHTML={{ __html: section.helpSection.help_tip }}
              />
            )}
            {section.helpSection.help_note && (
              <StyledNote
                dangerouslySetInnerHTML={{ __html: section.helpSection.help_note }}
              />
            )}
          </SubAccordion>
        ) : (
          <SubAccordion
            isMobile={props.isMobile}
            currentPlatform={props.currentPlatform}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: section.helpSection.analytics_id == LEARN_MORE_ANALYTICS_ID ? section.helpSection.help_for_all_apps_no_hp_plus : section.helpSection.help_for_all_apps,
              }}
            />
            {section.helpSection.help_tip && (
              <StyledTip
                dangerouslySetInnerHTML={{ __html: section.helpSection.help_tip }}
              />
            )}
            {section.helpSection.help_note && (
              <StyledNote
                dangerouslySetInnerHTML={{ __html: section.helpSection.help_note }}
              />
            )}
          </SubAccordion>
        )}
        {section.helpSection.help_for_desktop &&
          (props.currentPlatform == "desktop" ||
            props.currentPlatform == "web") && (
            <SubAccordion
              isMobile={props.isMobile}
              currentPlatform={props.currentPlatform}
            >
              {props.currentPlatform == "web" && (
                <a
                  // tabIndex={props.myindex + 1}
                  // tabIndex={0}
                  id={`${sectionId}_subheader_desktop`}
                >
                  <SubArrows>
                    <img
                      id={`${sectionId}_subsection_desktop_arrow`}
                      src="/assets/img/open_closed_arrow.svg"
                      alt=""
                    />
                  </SubArrows>
                  {/* Desktop */}
                  {props.platformTitles.desktop_app_section_title}
                </a>
              )}
              <Accordion
                ID={`${sectionId}_subsection_desktop`}
                actionID={`${sectionId}_subheader_desktop`}
                groupID={sectionId}
                groupFn={groupFn}
                controler={groupControl}
                callbackFn={callbackFn}
                isSubAccordionOpen={subAccordionsOpen}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: section.helpSection.help_for_desktop,
                  }}
                />
                {section.helpSection.help_tip && (
                  <StyledTip
                    dangerouslySetInnerHTML={{ __html: section.helpSection.help_tip }}
                  />
                )}
                {section.helpSection.help_note && (
                  <StyledNote
                    dangerouslySetInnerHTML={{
                      __html: section.helpSection.help_note,
                    }}
                  />
                )}
              </Accordion>
            </SubAccordion>
          )}
        {section.helpSection.help_for_mobile &&
          (props.currentPlatform == "ios" ||
            props.currentPlatform == "android" ||
            props.currentPlatform == "web") && (
            <SubAccordion
              isMobile={props.isMobile}
              currentPlatform={props.currentPlatform}
            >
              {props.currentPlatform == "web" && (
                <a
                  // tabIndex={props.myindex + 2}
                  // tabIndex={0}
                  id={`${sectionId}_subheader_mobile`}
                >
                  <SubArrows>
                    <img
                      id={`${sectionId}_subsection_mobile_arrow`}
                      src="/assets/img/open_closed_arrow.svg"
                      alt=""
                    />
                  </SubArrows>
                  {/* Mobile */}
                  {props.platformTitles.mobile_app_section_title}
                </a>
              )}
              <Accordion
                ID={`${sectionId}_subsection_mobile`}
                actionID={`${sectionId}_subheader_mobile`}
                groupID={sectionId}
                groupFn={groupFn}
                controler={groupControl}
                callbackFn={callbackFn}
                isSubAccordionOpen={subAccordionsOpen}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: section.helpSection.help_for_mobile,
                  }}
                />
                {section.helpSection.help_tip && (
                  <StyledTip
                    dangerouslySetInnerHTML={{ __html: section.helpSection.help_tip }}
                  />
                )}
                {section.helpSection.help_note && (
                  <StyledNote
                    dangerouslySetInnerHTML={{
                      __html: section.helpSection.help_note,
                    }}
                  />
                )}
              </Accordion>
            </SubAccordion>
          )}
        {section.helpSection.help_for_ios &&
          (props.currentPlatform == "ios" ||
            props.currentPlatform == "web") && (
            <SubAccordion
              isMobile={props.isMobile}
              currentPlatform={props.currentPlatform}
            >
              {props.currentPlatform == "web" && (
                <a
                  // tabIndex={props.myindex + 3}
                  // tabIndex={0}
                  id={`${sectionId}_subheader_ios`}
                >
                  <SubArrows>
                    <img
                      id={`${sectionId}_subsection_ios_arrow`}
                      src="/assets/img/open_closed_arrow.svg"
                      alt=""
                    />
                  </SubArrows>
                  {/* iOS */}
                  {props.platformTitles.ios_app_section_title}
                </a>
              )}
              <Accordion
                ID={`${sectionId}_subsection_ios`}
                actionID={`${sectionId}_subheader_ios`}
                groupID={sectionId}
                groupFn={groupFn}
                controler={groupControl}
                callbackFn={callbackFn}
                isSubAccordionOpen={subAccordionsOpen}
              >
                <StyledIOSSection
                  dangerouslySetInnerHTML={{
                    __html: section.helpSection.help_for_ios,
                  }}
                />
                {section.helpSection.help_tip && (
                  <StyledTip
                    dangerouslySetInnerHTML={{ __html: section.helpSection.help_tip }}
                  />
                )}
                {section.helpSection.help_note && (
                  <StyledNote
                    dangerouslySetInnerHTML={{
                      __html: section.helpSection.help_note,
                    }}
                  />
                )}
              </Accordion>
            </SubAccordion>
          )}
        {section.helpSection.help_for_android &&
          (props.currentPlatform == "android" ||
            props.currentPlatform == "web") && (
            <SubAccordion
              isMobile={props.isMobile}
              currentPlatform={props.currentPlatform}
            >
              {props.currentPlatform == "web" && (
                <a
                  // tabIndex={props.myindex + 4}
                  // tabIndex={0}
                  id={`${sectionId}_subheader_android`}
                >
                  <SubArrows>
                    <img
                      id={`${sectionId}_subsection_android_arrow`}
                      src="/assets/img/open_closed_arrow.svg"
                      alt=""
                    />
                  </SubArrows>
                  {/* Android */}
                  {props.platformTitles.android_app_section_title}
                </a>
              )}
              <Accordion
                ID={`${sectionId}_subsection_android`}
                actionID={`${sectionId}_subheader_android`}
                groupID={sectionId}
                groupFn={groupFn}
                controler={groupControl}
                callbackFn={callbackFn}
                isSubAccordionOpen={subAccordionsOpen}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: section.helpSection.help_for_android,
                  }}
                />
                {section.helpSection.help_tip && (
                  <StyledTip
                    dangerouslySetInnerHTML={{ __html: section.helpSection.help_tip }}
                  />
                )}
                {section.helpSection.help_note && (
                  <StyledNote
                    dangerouslySetInnerHTML={{
                      __html: section.helpSection.help_note,
                    }}
                  />
                )}
              </Accordion>
            </SubAccordion>
          )}
      </StyledSection>
    </Accordion>
  );
};

export default MenuContent;
