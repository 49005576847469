import React from "react";
import styled from "styled-components";

const MenuTitle = styled.a`
  color: ${(props) => props.theme.textColor};
  padding: ${(props) =>
    !props.isMobile ? props.theme.windowsMenuItemPadding : "1.125rem 1rem"};
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  border-top: ${(props) => (!props.isMobile ? "none" : "2px solid #EEE")};
  background-color: ${(props) =>
    props.active && !props.isMobile ? props.theme.menuSelected : "none"};
  font-size: ${(props) =>
    props.currentPlatform == "web" ||
    props.currentPlatform == "desktop" ||
    !props.isMobile
      ? " 1rem;"
      : ".9375rem;"};
  @media screen and (min-width: 48rem) {
    border-bottom: none;
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: ${(props) => props.theme.textColor};
    background-color: ${(props) =>
      !props.active && !props.isMobile ? props.theme.menuHover : "none"};
  }
  &:focus {
    outline: none;
    background-color: ${(props) =>
      !props.active && !props.isMobile ? props.theme.menuFocus : "none"};
  }
  span:last-child {
    width: 0.875rem;
    img {
      width: 100%;
    }
  }
`;

const MenuItem = (props) => {
  const element = `#${props.ID}_arrow`;

  if (!props.isMobile) {
    TweenLite.set(element, { rotation: -90 });
  }

  return (
    <MenuTitle
      id={props.ID}
      tabIndex={0}
      data-tracking={props.datatracking}
      active={props.active}
      currentPlatform={props.currentPlatform}
      isMobile={props.isMobile}
    >
      {props.accordionTitle}
      {props.isMobile && (
        <span>
          <img
            id={`${props.ID}_arrow`}
            src="/assets/img/open_closed_arrow.svg"
            alt=""
          />
        </span>
      )}
    </MenuTitle>
  );
};

export default MenuItem;
