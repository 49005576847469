import React, { Component } from 'react';
import EprintSettingsAccess from "./eprint-settings/EprintSettingsAccess";
import EprintAddressSettings from "./EprintAddressSettings"
// import PrintAnywhereAccess from "./../print-anywhere/PrintAnywhereAccess";
import EmailPrintingPreferences from "./eprint-settings/EmailPrintingPreferences";
import bind from "./../bind";
import { GEN2YETI } from '../../constants/common'
import { BREAKPOINT } from '../../constants/common';
//TODO: Make this into an export able array/map to be used by child components
const accordionParts = ["address", "access", "preferences"];

class RemotePrinting extends Component {
  constructor(props) {
    super(props);
    this.breakpoint = BREAKPOINT;
    this.state = {
      width: window.innerWidth,
      isTablet: window.innerWidth < this.breakpoint,
      activeAccordion: null,
    };
    this.handleAccordionToggle = this.handleAccordionToggle.bind(this);
    this.trackingHandler = this.trackingHandler.bind(this)
    this.resizeLayout = this.resizeLayout.bind(this);
  }

  resizeLayout = () => {
    this.setState({
      width: window.innerWidth
    });

    if (this.state.width < this.breakpoint) {
      this.setState({
        isTablet: true
      })
    } else {
      this.setState({
        isTablet: false
      })
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeLayout)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeLayout)
  }

  componentWillReceiveProps(nextProps) {
    let nextPrinter = nextProps.myPrinter.data.WPPData;
    if (!nextPrinter ||
      !nextPrinter.hasOwnProperty('printerCurrentAccess') ||
      !nextPrinter.hasOwnProperty('accessOptions')) {
      return;
    }
  }

  handleAccordionToggle(value, negate) {
    if (negate) {
      this.setState({ activeAccordion: null })
      return;
    }
    if (accordionParts.indexOf(value) === -1) {
      return;
    }
    this.setState({ activeAccordion: (value == this.state.activeAccordion) ? null : value });
  }

  trackingHandler(ID) {
    document.getElementById(ID).click();
  }

  render() {
    let myPrinter = this.props.myPrinter.data.WPPData;
    let miscellaneousData = this.props.myPrinter.data.miscellaneousData;
    if (!myPrinter.printerId) {
      return null;
    }
    let accordionAttrs = {
      printer: myPrinter,
      handleToggle: this.handleAccordionToggle,
      trackingHandler: this.trackingHandler
    };
    const { ePrintSupport } = this.props;
    const nonYetiPrinter = !(myPrinter.platformIdentifier === GEN2YETI);
    if (ePrintSupport && nonYetiPrinter) {
      return (
        <div id="ePrintSettings">
          <div className="myPrinterSection myPrinterSection--ePrintSettings">
            <h3 className="myPrinterSection--title">{miscellaneousData.eprint_title}</h3>
            {/* <PrinterAddressSettings {...accordionAttrs} active={this.state.activeAccordion == accordionParts[0]} /> */}
            <EprintAddressSettings {...accordionAttrs} active={this.state.activeAccordion == accordionParts[0]} isTablet={this.state.isTablet} />
            <EprintSettingsAccess {...accordionAttrs} active={this.state.activeAccordion == accordionParts[1]} changePrinterState={this.props.changePrinterState} isTablet={this.state.isTablet} />
            {/* <PrintAnywhereAccess {...accordionAttrs} active={this.state.activeAccordion == accordionParts[1]} /> */}
            {(myPrinter.preferenceSettings.length === 0) ? null
              : <EmailPrintingPreferences {...accordionAttrs} active={this.state.activeAccordion == accordionParts[2]} isTablet={this.state.isTablet} />}
          </div>
        </div>
      );
    }
    return (<div />);

  }
}

export default bind(RemotePrinting);
