import React from 'react';
import { useSelector } from '../../../../../../hooks';
import { selectHelpCenterMobileProps } from '../../../../store/modules/helpCenter/selector';
import HelpCenterSubtopic from './HelpCenterSubtopic';
import {
  HelpCenterMobileCard,
} from './styles';

const HelpCenterMobile = () => {
  const content = useSelector(selectHelpCenterMobileProps);

  return (
    <HelpCenterMobileCard
      appearance="dropShadow"
      content={content.map(entry => (
        <HelpCenterSubtopic
          key={`${entry.title.text}${entry.title.to}${entry.title.destination}`}
          {...entry}
        />
      ))}
    />
  );
};

export default HelpCenterMobile;
