import ActionTypes from '../constants/ActionTypes';
import fetchStatus from './fetchStatus';
// import AreaTypes from "../constants/AreaTypes";
function fetchMyPrinterStatus(state = null, action = { currentArea: null }) {
  const status = (state === null) ? fetchStatus() : fetchStatus(state, action);
  return {
    ...status,
  };
}

const myPrinterDefault = {
  ...fetchMyPrinterStatus(),
  data: {},
};

/**
 * The reducer controls the state of the axios or ajax call.
 * returning the current state with any changes from the
 * actions dispatchers
 *
 *
 * @param state
 * @param action
 * @returns {*}
 */
function printAnywhere(state = myPrinterDefault, action) {
  switch (action.type) {
    case ActionTypes.START_PRINTANYWHERE_FETCH:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.START_FETCH }),
      };

    case ActionTypes.COMPLETE_PRINTANYWHERE_FETCH:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: action.myPrinter,
      };

    case ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_EMAIL:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: { ...state.data, WPPData: { ...state.data.WPPData, ...action.data } },
      };
    case ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_MODE:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: {
          ...state.data,
          WPPData: {
            ...state.data.WPPData,
            sharingMode: action.payload.newMode,
          },
        },
      };

    case ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_PERMISSIONS:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: {
          ...state.data,
          WPPData: {
            ...state.data.WPPData,
            accessList: state.data.WPPData.accessList.map(userObj => {
              if (
                (userObj.userEmail && userObj.userEmail === action.data.userEmail)
                || (userObj.userID && userObj.userID === action.data.userID)
              ) {
                return {
                  ...userObj,
                  roles: action.data.roles,
                };
              }
              return userObj;
            }),
          },
        },
      };

    case ActionTypes.COMPLETE_PRINTANYWHERE_ADD_PERMISSIONS:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: {
          ...state.data,
          WPPData: {
            ...state.data.WPPData,
            accessList: [action.data, ...state.data.WPPData.accessList],
          },
        },
      };

    case ActionTypes.COMPLETE_PRINTANYWHERE_REMOVE_PERMISSIONS:
      return {
        ...fetchMyPrinterStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: {
          ...state.data,
          WPPData: {
            ...state.data.WPPData,
            accessList: state.data.WPPData.accessList.filter(obj => obj.userEmail !== action.data.userEmail),
          },
        },
      };

      case ActionTypes.COMPLETE_UPDATE_MYPRINTER_ADDRESS:
        return {
            ...state,
            data: { ...state.data, WPPData: { ...state.data.WPPData, printerEmail: action.printerEmail || action.data.printerEmail } },
        }

    case ActionTypes.START_PRINTANYWHERE_UPDATE_EMAIL:
    case ActionTypes.START_PRINTANYWHERE_UPDATE_MODE:
    case ActionTypes.START_PRINTANYWHERE_UPDATE_PERMISSIONS:
    case ActionTypes.START_PRINTANYWHERE_ADD_PERMISSIONS:
    case ActionTypes.START_PRINTANYWHERE_REMOVE_PERMISSIONS:
      return {
        ...state,
        data: state.data,
      };
    case ActionTypes.COMPLETE_PRINTANYWHERE_ADD_PERMISSIONS_WITH_ERROR:
    case ActionTypes.COMPLETE_PRINTANYWHERE_REMOVE_PERMISSIONS_WITH_ERROR:
    case ActionTypes.COMPLETE_PRINTANYWHERE_WITH_ERROR:
    case ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_MODE_WITH_ERROR:
    case ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_PERMISSIONS_WITH_ERROR:
    case ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_EMAIL_WITH_ERROR:
    default:
      return state;
  }
}

export default printAnywhere;
