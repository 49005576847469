import styled from 'styled-components';
import { stylesIf } from '../../../../utils/styleHelpers';

/*
 * Base components for pages
 */
export const Title = styled.h4`
  flex-basis: 100%;
  font-size: 40px;
  font-family: ${props => props.theme.main.hp.fontFamilyLight};
  margin-bottom: 10px;
  padding-top: 20px;
`;

export const Description = styled.div`
  font-family: ${props => props.theme.main.hp.fontFamilyLight};
  font-size: 16px;
  margin-bottom: ${stylesIf('noMargin', '0', '32px')};
  max-width: 660px;
`;

/*
 * Grid Layout Components: used in dashboard / pages
 */
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 640px 300px;
  grid-gap: 32px 32px;
  margin: ${stylesIf('noMargin', '0', '32px 0')};

  @media (max-width: ${props => props.theme.breakPoints.lg}) {
    ${props => (props.theme.hostIsDesktopApp ? '' : 'grid-template-columns: 1fr;')};
  }

  @media (max-width: ${props => props.theme.breakPoints.xg}) {
    grid-template-columns: 1fr;
  }
`;

export const GridSeparator = styled.div`
  max-width: 975px;
  width: 100%;

  @media (max-width: ${props => props.theme.breakPoints.lg}) {
    width: inherit;
    flex: 1;
  }
`;

export const Container = styled.div`
  margin: 0 32px${props => (props.theme.gateway ? '' : ' 32px 292px')};
  position: relative;

  @media (max-width: ${props => props.theme.breakPoints.xg}) {
    ${props => (props.theme.hostIsDesktopApp ? '' : 'margin: 0 32px;')};
  }

  @media (min-width: 1200px) and (max-width: ${props => props.theme.breakPoints.xg}) {
    margin-left: 292px;
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    ${props => (props.theme.hostIsDesktopApp ? '' : 'margin: 0 16px;')};
  }
`;

export const SideContent = styled.div`
  > * {
    &:not(:last-child)  {
      margin-bottom: 32px;
    }
  }
`;

export const MainContent = styled.div`
  > * {
    &:not(:last-child)  {
      :not(:empty) {
        margin-bottom: 32px;
      }
    }
  }
`;

export const NotificationContent = styled.div`
  > * {
    &:not(:empty) {
      padding: 0 0 12px 0;
    }
  }
`;

/*
 * Cards Components: used in dashboard page
 */
export const CardContainer = styled.div`
  padding: 32px;

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    padding: 20px;
  }
`;

export const CardHeader = styled.div`
  display: ${props => props.display || 'flex'};
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  font-family: ${props => props.theme.main.hp.fontFamily};

  h5 {
    font-family: ${props => props.theme.main.hp.fontFamily};
    font-size: 24px;
  }

  a {
    color: ${props => props.theme.main.hp.color};
    font-size: 14px;
    line-height: 30px;
    text-align: right;
  }

  p {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const CardHeaderStatus = styled(CardHeader)`
  border-bottom: 1px solid #EBEBEB;
`;

export const CardHeaderBenefits = styled(CardHeader)`
`;

export const CardHeaderOtherFeatures = styled(CardHeader)`
  h5 {
    &:last-child {
      margin-top: 32px;
    }
  }
`;
