import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import OrigamiAdditionalFeatures from '../OrigamiAdditionalFeatures';
import SkeletonAdditionalFeatures from '../Skeleton/SkeletonAdditionalFeatures';
import { StyledSection } from '../styles/StyleOrigami';
import { SkeletonContainer } from '../styles/StyleSkeleton';

const propTypes = {
  additionalFeaturesSection: PropTypes.array.isRequired
};

const defaultProps = {
  additionalFeaturesSection: []
};

const OrigamiAdditionalFeaturesContent = (props) => {
  const { additionalFeaturesSection } = props;
  return (
    <StyledSection>
      <OrigamiAdditionalFeatures additionalFeaturesSection={additionalFeaturesSection} />
    </StyledSection>
  );
};

const OrigamiAdditionalFeaturesPromise = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          default: (props) => {
            const { additionalFeaturesSection } = props;
            return (
              <OrigamiAdditionalFeaturesContent
                additionalFeaturesSection={additionalFeaturesSection}
              />
            );
          }
        });
      }, 1000);
    })
);

const OrigamiLazyAdditionalFeatures = (props) => {
  const { additionalFeaturesSection } = props;
  return (
    <Suspense
      fallback={
        <SkeletonContainer>
          <SkeletonAdditionalFeatures />
        </SkeletonContainer>
      }
    >
      <OrigamiAdditionalFeaturesPromise additionalFeaturesSection={additionalFeaturesSection} />
    </Suspense>
  );
};

OrigamiLazyAdditionalFeatures.propTypes = propTypes;

OrigamiLazyAdditionalFeatures.defaultProps = defaultProps;

export default OrigamiLazyAdditionalFeatures;
