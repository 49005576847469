import { createSelector } from 'reselect';
import { isEmpty, path } from 'ramda';
import { selectLocaleParamsFromRoute } from '../../../../../selectors/routing';
import selectCurrentStack from '../environment/selectors';

const selectStratusBaseUrlFromConfig = createSelector(
  path(['config', 'data']),
  configClass => isEmpty(configClass) ? null : configClass.Get('STRATUS_BASE_URL'),
);

export const selectNotificationProps = createSelector(
  selectStratusBaseUrlFromConfig,
  selectLocaleParamsFromRoute,
  selectCurrentStack,
  (baseUrlFromConfig, localeParamsFromRoute, currentStackFromConfig) => ({
    baseUrl: `${baseUrlFromConfig}/atlas`,
    language: localeParamsFromRoute.language,
    country: localeParamsFromRoute.country,
    currentStack: currentStackFromConfig,
  }),
);

export default selectNotificationProps;
