/* eslint-disable import/prefer-default-export */
import { util } from '../utils/request';
import Config from '../config/Config';

const config = new Config();

export const encrypt = data => {

  return util({
    url: '/encrypt?purpose=ga',
    method: 'POST',
    data,
  });
};
