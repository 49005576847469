import React from 'react';
import PropTypes from 'prop-types';
import {
  OrigamiHeroSection,
  OrigamiHeroMain,
  OrigamiHeroBanner,
  HeroHeading,
  HeroSubheading,
  HeroEnrollContentBox,
} from './styles/StyleOrigamiHero';

const propTypes = {
  widescreenPosterTitle: PropTypes.string.isRequired,
  widescreenPosterUrl: PropTypes.string.isRequired,
  mobilePosterTitle: PropTypes.string.isRequired,
  mobilePosterUrl: PropTypes.string.isRequired,
  heroHeading: PropTypes.string.isRequired,
  heroSubheading: PropTypes.string.isRequired,
  addPaperButtonText: PropTypes.string.isRequired,
  enrollLinkText: PropTypes.string.isRequired,
  enrollLinkChevronTitle: PropTypes.string.isRequired,
  enrollLinkChevronUrl: PropTypes.string.isRequired,
  changeEnrollText: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleEnrollLink: PropTypes.func.isRequired
};

const defaultProps = {
  widescreenPosterTitle: '',
  widescreenPosterUrl: '',
  mobilePosterTitle: '',
  mobilePosterUrl: '',
  heroHeading: '',
  heroSubheading: '',
  addPaperButtonText: '',
  enrollLinkText: '',
  enrollLinkChevronTitle: '',
  enrollLinkChevronUrl: '',
  changeEnrollText: ''
};

const OrigamiHero = (props) => {
  const {
    widescreenPosterUrl,
    mobilePosterUrl,
    heroHeading,
    heroSubheading,
    addPaperButtonText,
    enrollLinkText,
    enrollLinkChevronTitle,
    enrollLinkChevronUrl,
    changeEnrollText,
    handleClick,
    handleEnrollLink
  } = props;
  return (
    <OrigamiHeroSection data-testid="origami-hero-section">
      <OrigamiHeroMain>
          <div class="origami-hero-grid-container">
            <div class="origami-hero-section-1">
              <HeroHeading
                data-testid="origami-hero-heading"
                dangerouslySetInnerHTML={{ __html: heroHeading }}
                />
            </div>
            <div class="origami-hero-section-2">
              <HeroSubheading
                data-testid="origami-hero-subheading"
                dangerouslySetInnerHTML={{ __html: heroSubheading }}
              />
              <HeroEnrollContentBox
                addPaperButtonText={addPaperButtonText}
                enrollLinkText={enrollLinkText}
                enrollLinkChevronTitle={enrollLinkChevronTitle}
                enrollLinkChevronUrl={enrollLinkChevronUrl}
                changeEnrollText={changeEnrollText}
                handleClick={handleClick}
                handleEnrollLink={handleEnrollLink}
                className="heroEnroll"
              />
            </div>
            <div class="origami-hero-section-3">
              <OrigamiHeroBanner
                data-testid="origami-hero-banner"
                backgroundImg={widescreenPosterUrl}
                mobileImage={mobilePosterUrl}
              />
            </div>
        </div>
      </OrigamiHeroMain>
    </OrigamiHeroSection>
  );
};

OrigamiHero.propTypes = propTypes;

OrigamiHero.defaultProps = defaultProps;

export default OrigamiHero;
