export const ENVY6000 = 'envy6000';
export const ENVYPRO6400 = 'envypro6400';
export const DJ2700IA = 'dj2700ia';
export const DJ4100IA = 'dj4100ia';
export const DJ6400IA = 'dj6400ia';
export const DJ6000 = 'dj6000';
export const DJ6400 = 'dj6400';
export const DJ2700 = 'dj2700';
export const DJ4100 = 'dj4100';
export const TANGO = 'tango';

const ENVY6000_INFO = `/${ENVY6000}-info`;
const ENVYPRO6400_INFO = `/${ENVYPRO6400}-info`;
const DJ2700IA_INFO = `/${DJ2700IA}-info`;
const DJ4100IA_INFO = `/${DJ4100IA}-info`;
const DJ6400IA_INFO = `/${DJ6400IA}-info`;
const DJ6000_INFO = `/${DJ6000}-info`;
const DJ6400_INFO = `/${DJ6400}-info`;
const DJ2700_INFO = `/${DJ2700}-info`;
const DJ4100_INFO = `/${DJ4100}-info`;
const TANGO_INFO = `/${TANGO}-info`;

export const CONSTANTS = {
    ENVY6000_INFO,
    ENVYPRO6400_INFO,
    DJ2700IA_INFO,
    DJ4100IA_INFO,
    DJ6400IA_INFO,
    DJ6000_INFO,
    DJ6400_INFO,
    DJ2700_INFO,
    DJ4100_INFO,
    TANGO_INFO
};
