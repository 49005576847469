import React, { useEffect, useState } from 'react';
import base64url from 'base64url';
import printerSVG from './assets/printer.svg';
import { useHistory } from 'react-router';
import { useSelector } from '../../hooks';
import { WhenJWebReady } from '@jweb/core';
import { BEACON_RESET_SUPPORTED_SKUS } from '../../constants/printerConnection';
import { selectQueryParameters } from '../../selectors/routing';
import { selectDeviceListContentStackData } from '../../selectors/craft';
import {
  Container,
  StyledList,
  StyledListItem,
  Title,
  Graphic,
  ButtonContainer,
  StyledButton
} from './styles/DeviceSupportListStyles';

const genericPage = '/support/generic';

const DeviceSupportList = () => {
  const [isIOS, setIOS] = useState(true);
  const [isAndroid, setAndroid] = useState(true);
  const history = useHistory();

  const checkHPSmartApp = async () => {
    const res = await WhenJWebReady;
    if (res) {
      setIOS(!!JWeb.isIOS);
      setAndroid(!!JWeb.isAndroid);
    }
  };

  useEffect(() => {
    checkHPSmartApp();
  }, []);

  const { deviceid } = useSelector(selectQueryParameters);

  if (deviceid) {
    const base64DecodedData = JSON.parse(base64url.decode(decodeURIComponent(deviceid)));
    const csContent = useSelector(selectDeviceListContentStackData);

    if (csContent) {
      const csDataTextContent = csContent?.data?.entries[0];
      const {
        support_headline = '',
        select_printer_model = '',
        not_my_printer_button_text = ''
      } = csDataTextContent;

      const items = base64DecodedData
        .filter((item, index, array) => array.findIndex(obj => obj.modelName === item.modelName) === index)
        .map((item, index) => {
        const printerModelHref =
          item.links && item.links[0].href
            ? item.links[0].href.split('/').slice(-1)[0]
            : genericPage;
        const helpPageHref =
        BEACON_RESET_SUPPORTED_SKUS.indexOf(printerModelHref.toUpperCase()) !== -1
            ? `/support/printer-connection/${printerModelHref}`
            : genericPage;
        const printerModelName = item.modelName || 'HP Generic Printer';

        if (helpPageHref !== genericPage) {
          return (
            <StyledListItem
              onClick={() =>
                history.push({
                  pathname: helpPageHref,
                  search: '?showChangeButton=true'
                })
              }
              key={index}
              leftArea={<Graphic id="graphic" src={printerSVG} alt="Printer" />}
              centerArea={
                <div style={{ display: 'inline-grid' }}>
                  <div className="vn-list-item__title">
                    <span>{printerModelName}</span>
                  </div>
                  <div className="vn-list-item__subtitle">
                    <span>{select_printer_model}</span>
                  </div>
                </div>
              }
              rightArea={<span />}
            />
          );
        }
      });

      return (
        <Container>
          <Title>{support_headline}</Title>
          <StyledList id="list-of-beacon-devices" mobile={isIOS || isAndroid}>
            {items}
          </StyledList>
          <ButtonContainer>
            <StyledButton
              onClick={() => history.push(genericPage)}
              appearance="tertiary"
              id="generic-support-printer-not-found"
              small={true}
            >
              {not_my_printer_button_text}
            </StyledButton>
          </ButtonContainer>
        </Container>
      );
    }

    return null;
  }
  return null;
};

export default DeviceSupportList;
