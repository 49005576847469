import {
  SideMenuItem,
} from '@veneer/core';
import styled from 'styled-components';

export const Container = styled.div`
  && {
    .vn-side-menu__logo {
      border-right: 0;
    }
    .vn-side-menu__logo-image {
       width: unset;
    }
    .vn-side-menu__toggle {
      svg {
        fill: ${props => props.theme.main.hp.color};
      }
    }
    .vn-side-menu__menu {
      &::before {
        width: 100%;
      }
    }

    ${props => props.theme.hostIsDesktopApp
      && `.vn-side-menu__menu {
        width: 260px;
        top: 0;
      }
      .vn-side-menu__logo {
        visibility: hidden;
      }
      .vn-side-menu__toggle {
        display: none;
      }
      .vn-side-menu__overlay {
        display: none;
      }`}
    }
`;

export const Separator = styled.hr`
  border: none;
  border-bottom: 1px solid #EBEBEB;
  width: calc(100% - 2 * 8px);
`;

export const ChatMenuItem = styled(SideMenuItem)`
  * {
    color: #027AAE;
  }
`;
