import react from 'react';
import React from 'react';
import { IconCheckmark, IconMinus, IconInfo } from '@veneer/core';
import { isEmpty, isNil } from 'ramda';
import {
  MobileConatiner,
  MobCols,
  HeaderBonus,
  MobileHeader,
  FeatureTitle,
  MobileSection,
  Tooltip,
  TooltipText,
  ColHeader
} from './SACompareStylesMob';

const SACompareTable = (props) => {
  let tableData = props.featureRow.map((data, key) => {
    const { features } = data;

    const {
      feature_title,
      coachmark,
      hpplus_feature_text,
      ii_feature_text,
      with_acc_feature_text,
      no_acc_feature_text,
      visibility_limitation,
      _metadata
    } = features;

    const { uid } = _metadata;
    const isEmpty = (str) => !str || str.trim() === '';

    if (visibility_limitation === 'Desktop only') {
      return <react.Fragment key={uid}>{''}</react.Fragment>;
    } else {
      return (
        <react.Fragment key={uid}>
          <FeatureTitle id="Feature-Title">
            <span dangerouslySetInnerHTML={{ __html: feature_title }} />
            {visibility_limitation === 'mobileOnly' || isEmpty(coachmark) ? (
              ''
            ) : (
              <Tooltip id="Tooltip-Icon">
                <IconInfo disabled />
                <TooltipText id="TooltipText-Icon-Text" dangerouslySetInnerHTML={{ __html: coachmark }} />
              </Tooltip>
            )}
          </FeatureTitle>

          <MobileSection id="Mobile-Section">
            {hpplus_feature_text === '' ? (
              <MobCols id="Mob-Cols">
                <IconCheckmark color="colorOrange6" filled />
              </MobCols>
            ) : (
              <react.Fragment>
                <MobCols
                  id="Mob-Cols"
                  dangerouslySetInnerHTML={{ __html: hpplus_feature_text }}
                ></MobCols>
              </react.Fragment>
            )}

            {ii_feature_text === '' ? (
              <MobCols id="Mob-Cols">
                {ii_feature_text === '' && hpplus_feature_text === '' ? (
                  <div>
                    <IconMinus />
                  </div>
                ) : (
                  <div>
                    <IconCheckmark filled />
                  </div>
                )}
              </MobCols>
            ) : (
              <react.Fragment>
                <MobCols
                  id="Mob-Cols"
                  dangerouslySetInnerHTML={{ __html: ii_feature_text }}
                ></MobCols>
              </react.Fragment>
            )}
            {with_acc_feature_text === '' ? (
              <MobCols id="Mob-Cols">
                {with_acc_feature_text === '' && hpplus_feature_text === '' ? (
                  <div>
                    <IconMinus />
                  </div>
                ) : (
                  <div>
                    <IconCheckmark filled />
                  </div>
                )}
              </MobCols>
            ) : (
              <react.Fragment>
                <MobCols
                  id="Mob-Cols"
                  dangerouslySetInnerHTML={{ __html: with_acc_feature_text }}
                ></MobCols>
              </react.Fragment>
            )}
            {no_acc_feature_text === '' ? (
              <MobCols id="Mob-Cols">
                <IconMinus />
              </MobCols>
            ) : (
              <react.Fragment>
                <MobCols
                  id="Mob-Cols"
                  dangerouslySetInnerHTML={{ __html: no_acc_feature_text }}
                ></MobCols>
              </react.Fragment>
            )}
          </MobileSection>
        </react.Fragment>
      );
    }
  });
  let tableHeadings = props.headerRow;
  let tableHeadingsDataTrimmed = tableHeadings.slice(Math.max(tableHeadings.length - 4, 1));
  let tableHeadingsData = tableHeadingsDataTrimmed.map((data, key) => {
    const { header_title } = data;
    const { column_title, _metadata } = header_title;
    const { uid } = _metadata;
    return (
      <react.Fragment key={uid}>
        <MobCols id="Mob-Cols">
          <ColHeader>
            <p>{column_title}</p>
          </ColHeader>
        </MobCols>
      </react.Fragment>
    );
  });
  let columnTitleBonus = tableHeadingsDataTrimmed.map((data, key) => {
    const { header_title } = data;
    const { column_title_bonus, _metadata } = header_title;
    const { uid } = _metadata;
    return (
      <react.Fragment key={uid}>
        {column_title_bonus ? <HeaderBonus>{column_title_bonus}</HeaderBonus> : ''}
      </react.Fragment>
    );
  });
  return (
    <react.Fragment>
      <MobileConatiner id="Mobile-Conatiner">
        {columnTitleBonus}
        <MobileHeader id="MobileHeader">{tableHeadingsData}</MobileHeader>
        {tableData}
      </MobileConatiner>
    </react.Fragment>
  );
};

export default SACompareTable;
