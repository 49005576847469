let _GLOBAL_TIMEOUT = 9; // necessary for debounce

export const deBounce = (func, wait, immediate = false) => {
    return function () {
        let context = this,
            args = arguments;
        let later = function () {
            _GLOBAL_TIMEOUT = null;
            if (!immediate) func.apply(context, args);
        };
        clearTimeout(_GLOBAL_TIMEOUT);
        _GLOBAL_TIMEOUT = setTimeout(later, wait);
    };
} 