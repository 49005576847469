import React from 'react';
import { useEffect } from 'react';
import Hls from 'hls.js';
import styled from 'styled-components';
const Video = styled.video`
  width: 100%;
  object-fit: cover;
  margin-bottom: 1.25rem;
`;
const VideoBanner = ({ src, poster, videoRef }) => {
  useEffect(() => {
    if (Hls.isSupported()) {
      let hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        videoRef.current.muted = true;
        videoRef.current.play();
      });
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.muted = true;
      videoRef.current.src = src;
      videoRef.current.play();
    }
    return () => {
      videoRef.current.pause();
    };
  }, []);

  return <Video preload="none" ref={videoRef} controls poster={poster} />;
};

export default VideoBanner;
