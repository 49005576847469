import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  IconPlus,
  IconTrash,
  TextBox,
  useToast,
} from '@veneer/core';
import PropTypes from 'prop-types';
import {
  Modal,
} from '../../shared-components/ModalDefault';
import {
  EmailItem,
  EmailItemContainer,
  FormInline,
  Header,
  HeaderUsers,
} from './styles';
import i18n from '../../../utils/i18n';
import { inviteUser } from '../../../api/UCDEGateway';
import { EMAIL_REGEX, INVITES_MAX_LENGTH } from '../../../utils/constants';

const validateUser = email => EMAIL_REGEX.test(email);

const EmailToAdd = ({ email, onDelete, disabled }) => (
  <EmailItemContainer
    id={`${email}-container`}
  >
    <EmailItem
      id={`${email}-add`}
      key={email}
      readOnly
      value={email}
      trailingIcon={(
        <Button
          leadingIcon={<IconTrash />}
          negative
          disabled={disabled}
          onClick={() => {
            onDelete(email);
          }}
        />
      )}
    />
  </EmailItemContainer>
);

const callInviteAllUsers = ({
  id,
  emails,
  onFinish,
  onSuccess,
  onError,
}) => {
  /*
    We keep track of the number of remaining unresolved invites instead of
     using the array index because the requests might resolve out of order.
  */
  let remainingInvites = emails.length;
  const finishIfLast = () => {
    remainingInvites -= 1;
    if (remainingInvites === 0) {
      onFinish();
    }
  };

  emails.forEach(async email => {
    inviteUser({ id, email })
      .then(() => {
        onSuccess(email);
        finishIfLast();
      }).catch(() => {
        onError(email);
        finishIfLast();
      });
  });
};

const InviteUsers = ({
  show, users, onClose, onFinish,
}) => {
  const [inputUser, setInputUser] = useState({ error: false, value: '' });
  const [usersMailList, setUsersMailList] = useState({ list: [], isSendingInvites: false, disableConfirm: true });
  const [trashDisabled, setTrashDisabled] = useState(false);
  const user = useSelector(state => state.ucdeUser);
  const { addToast } = useToast();
  const openToast = (id, type, text) => {
    addToast({ id: `${id}-toast`, type, text });
  };

  const onCloseModal = () => {
    onClose();
    setInputUser({ value: '' });
    setUsersMailList({ list: [], isSendingInvites: false, disableConfirm: true });
  };

  const sendUserInvitations = () => {
    setUsersMailList({
      list: usersMailList.list,
      disableConfirm: false,
      isSendingInvites: true,
    });

    setTrashDisabled(true);

    callInviteAllUsers({
      id: user.tenantResourceId,
      emails: usersMailList.list,
      onSuccess: email => {
        openToast(email, 'positive', i18n.t('manageUsers.inviteOverlay.successMessage', { email }));
      },
      onFinish: () => {
        setUsersMailList({
          disableConfirm: true,
          isSendingInvites: false,
          list: [],
        });
        onCloseModal();
        onFinish();
      },
      onError: email => {
        openToast(email, 'negative', i18n.t('manageUsers.inviteOverlay.errorMessage', { email }));
      },
    });
  };

  const addEmailToInviteList = () => {
    const emailExistsMessage = address => {
      const wasAddedBefore = users.some(item => item.email.toLowerCase() === address.toLowerCase());
      const inTheList = usersMailList.list.some(item => item.toLowerCase() === address.toLowerCase());

      return (wasAddedBefore || inTheList) ? 'manageUsers.inviteOverlay.alreadyInvitedError' : false;
    };

    const listMaxReachedMessage = list => (
      list.length >= INVITES_MAX_LENGTH
        ? 'manageUsers.inviteOverlay.invitesLimitError'
        : false
    );

    const invalidEmailMessage = email => (!validateUser(email) ? 'manageUsers.inviteOverlay.emailError' : false);

    const hasError = invalidEmailMessage(inputUser.value)
      || emailExistsMessage(inputUser.value)
      || listMaxReachedMessage(usersMailList.list.concat(users));

    setInputUser({
      ...inputUser,
      error: hasError,
    });

    if (!hasError) {
      setTrashDisabled(false);
      setUsersMailList({
        ...usersMailList,
        list: [...usersMailList.list, inputUser.value],
        disableConfirm: false,
      });

      setInputUser({
        ...inputUser,
        value: '',
        error: hasError,
      });
    }
  };

  return (
    <Modal
      id="regular-modal"
      onClose={onCloseModal}
      show={show}
      title={i18n.t('manageUsers.inviteOverlay.header')}
      footer={(
        <div className="vn-button-group--responsive">
          <Button
            appearance="secondary"
            disabled={usersMailList.isSendingInvites}
            onClick={onCloseModal}
          >
            {i18n.t('manageUsers.inviteOverlay.cancel')}
          </Button>
          <Button
            disabled={usersMailList.disableConfirm}
            loading={usersMailList.isSendingInvites}
            onClick={sendUserInvitations}
          >
            {i18n.t('manageUsers.inviteOverlay.confirm')}
          </Button>
        </div>
      )}
    >
      <Header>{i18n.t('manageUsers.inviteOverlay.copy')}</Header>
      <FormInline data-testid="invite-users">
        <TextBox
          label={i18n.t('manageUsers.inviteOverlay.entryField')}
          id="user-email"
          data-testid="user-email"
          type="text"
          error={inputUser.error}
          helperText={inputUser.error ? i18n.t(inputUser.error) : null}
          value={inputUser.value}
          onChange={value => setInputUser({ ...inputUser, value })}
          disabled={usersMailList.isSendingInvites}
          trailingIcon={(
            <Button
              data-testid="add-button"
              leadingIcon={<IconPlus />}
              disabled={usersMailList.isSendingInvites}
              negative
              onClick={addEmailToInviteList}
            />
          )}
        />
      </FormInline>
      <div id="users-list">
        {usersMailList.list.length > 0
          && <HeaderUsers>{i18n.t('manageUsers.inviteOverlay.label')}</HeaderUsers>}
        {usersMailList.list.map(email => (
          <EmailToAdd
            key={email}
            email={email}
            disabled={trashDisabled}
            onDelete={emailToDelete => {
              setUsersMailList({
                ...usersMailList,
                list: usersMailList.list.filter(item => item !== emailToDelete),
                disableConfirm: (usersMailList.list.length - 1) === 0,
              });
            }}
          />
        ))}
      </div>
    </Modal>
  );
};

InviteUsers.propTypes = {
  show: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

InviteUsers.defaultProps = {
  onError: () => { },
};

export default InviteUsers;
