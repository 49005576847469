export const GLOBAL_NAV_RESOURCE_NAME = 'global_navigation';
export const GLOBAL_NAV_BAR_RESOURCE_NAME = 'global_nav_bar';
export const NEW_LOCALE_SELECTOR_RESOURCE_NAME = 'locale_selector';
export const GLOBAL_FOOTER_RESOURCE_NAME = 'global_footer';

export const GLOBALS = [
  GLOBAL_NAV_RESOURCE_NAME,
  GLOBAL_NAV_BAR_RESOURCE_NAME,
  NEW_LOCALE_SELECTOR_RESOURCE_NAME,
  GLOBAL_FOOTER_RESOURCE_NAME
];

import {
  SLUG_3M,
  SLUG_TACCOLA_FB,
  SLUG_TACCOLA_ADF,
  SLUG_VASARI_FB,
  SLUG_VASARI_ADF,
  SLUG_SKYREACH_FB,
  SLUG_SKYREACH_ADF,
  SLUG_EYRIE,
  SLUG_STORM,
  SLUG_HULK,
  SLUG_YOSHINO,
  SLUG_LOCHSA,
  SLUG_ULYSSES,
  SLUG_SELENE,
  SLUG_BEAM,
  SLUG_JUPITER,
  SLUG_BEAM_MFP,
  SLUG_PIXIU,
  SLUG_TASSEL,
  SLUG_EUTHENIA,
  SLUG_LEBI,
  SLUG_MARCONI,
  SLUG_MORETO,
  SLUG_VICTORIA,
  SLUG_KEBIN,
  SLUG_DRAGONFLY_BASE,
  SLUG_DRAGONFLY_WL,
  SLUG_DRAGONFLY_ADF,
  SLUG_TASSEL_BASE,
  ENTRYID_3M,
  ENTRYID_TACCOLA_FB,
  ENTRYID_TACCOLA_ADF,
  ENTRYID_VASARI_FB,
  ENTRYID_VASARI_ADF,
  ENTRYID_SKYREACH_FB,
  ENTRYID_SKYREACH_ADF,
  ENTRYID_EYRIE,
  ENTRYID_STORM,
  ENTRYID_HULK,
  ENTRYID_YOSHINO,
  ENTRYID_LOCHSA,
  ENTRYID_ULYSSES,
  ENTRYID_SELENE,
  ENTRYID_BEAM,
  ENTRYID_JUPITER,
  ENTRYID_BEAM_MFP,
  ENTRYID_PIXIU,
  ENTRYID_TASSEL,
  ENTRYID_EUTHENIA,
  ENTRYID_LEBI,
  ENTRYID_MARCONI,
  ENTRYID_MORETO,
  ENTRYID_VICTORIA,
  ENTRYID_KEBIN,
  ENTRYID_DRAGONFLY_BASE,
  ENTRYID_DRAGONFLY_WL,
  ENTRYID_DRAGONFLY_ADF,
  SKU_TO_ENTRY_ID_MAPPING
} from './printerConnection';
import Config from '../config/Config';
import { RESOURCES as DCN } from './dataCollectionNotices';
import {
  CS_HPAPP_TOU_ENTRY_ID,
  CS_ADMIN_TOU_ENTRY_ID,
  CS_TOU_ENTRY_ID,
  ERROR_AUTHZ,
  ERROR_AUTHZ_ENTRYID,
  ERROR_INVITATION,
  ERROR_INVITATION_ENTRYID,
  CS_PS_TELEMETRY_ENTRY_ID
} from './common';

const config = new Config();

/**
 * In case there is entry Id/resource Id is present from which you have to fetch the data specifically, Use following approach
 * entry Id/resource Id will come from AppConfig
 * Will add Resource Name and entry Id in Key Value format. Below is the example:
 *
 * export const PIPL_CONSENT_RESOURCE_NAME = 'consents';
 * export const PIPL_CONSENT = [{ [PIPL_CONSENT_RESOURCE_NAME]: PIPL_CONSENT_RESOURCE_ENTRY }];
 */

export const PAPER_SUBSCRIPTION = 'paper_subscription';

export const LIBRARY_LANDING = 'library_landing';
export const LIBRARY_SECTION = 'library_section';
export const LIBRARY_TOPIC = 'library_topic';
export const LIBRARY_SUBJECT = 'library_subject';
export const LIBRARY_LINK = 'library_link';
export const LIBRARY_VISUAL_NAV = 'library_visual_nav';

export const MY_PRINTER = 'printer_details';
export const EPRINT_ACCESS_SETTINGS = 'eprint_access_settings';
export const EPRINT_PREFERENCE_SETTINGS = 'eprint_preferences_settings';
export const EPRINT_ADDRESS_SETTINGS = 'eprint_address_settings';
export const EPRINT_MISCELLANEOUS = 'eprint_miscellaneous';
export const MY_PRINTER_REMOVE_PRINTER = 'my_printers_remove_printer_modal';
export const MY_PRINTER_UNREGISTERED_PRINTER = 'my_printers_unregistered_printer_details';
export const MY_PRINTER_SUBSCRIPTION = 'my_printers_subscription_management';
export const PRINT_ANYWHERE = 'print_anywhere';

// Use this resource name whenever App Badges are required on any page
export const APP_BADGES_RESOURCE_NAME = 'app_badges';

export const SMART_HELP_RESOURCE_NAME = 'smart_help';
export const SMART_HELP_SECTIONS_RESOURCE_NAME = 'smart_help_sections';
export const SMART_HELP = [SMART_HELP_RESOURCE_NAME, SMART_HELP_SECTIONS_RESOURCE_NAME];
export const IN_APP_HELP = SMART_HELP;

export const SMART_ADVANCE_RESOURCE_NAME = 'hp_smart_advance';
export const SMART_ADVANCE = [SMART_ADVANCE_RESOURCE_NAME, APP_BADGES_RESOURCE_NAME];

export const UPGRADE_SUPPORT_RESOURCE_NAME = 'support_upgrade';
export const UPGRADE_SUPPORT = [UPGRADE_SUPPORT_RESOURCE_NAME, APP_BADGES_RESOURCE_NAME];

export const CONTACT_RESOURCE_NAME = 'contact';
export const CONTACT_ENTRIES_RESOURCE_NAME = 'contact_entries';
export const TOU_RESOURCE_ENTRY = CS_TOU_ENTRY_ID;
export const TOU_RESOURCE_NAME = 'terms_of_use';
export const TOU = [{ [TOU_RESOURCE_NAME]: TOU_RESOURCE_ENTRY }, CONTACT_RESOURCE_NAME, CONTACT_ENTRIES_RESOURCE_NAME];

export const SMB_ADMIN_TOU_RESOURCE_ENTRY = CS_ADMIN_TOU_ENTRY_ID;
export const SMB_ADMIN_TOU_RESOURCE_NAME = 'terms_of_use';
export const SMB_ADMIN_TOU = [{ [SMB_ADMIN_TOU_RESOURCE_NAME]: SMB_ADMIN_TOU_RESOURCE_ENTRY }];

export const HPAPP_TOU_RESOURCE_ENTRY = CS_HPAPP_TOU_ENTRY_ID;
export const HPAPP_TOU_RESOURCE_NAME = 'terms_of_use';
export const HPAPP_TOU = [{ [HPAPP_TOU_RESOURCE_NAME]: HPAPP_TOU_RESOURCE_ENTRY }];

export const PIPL_CONSENT_RESOURCE_ENTRY = config.Get('CS_PIPL_CONSENT_ENTRY_ID');
export const PIPL_CONSENT_RESOURCE_NAME = 'consents';
export const PIPL_CONSENT = [{ [PIPL_CONSENT_RESOURCE_NAME]: PIPL_CONSENT_RESOURCE_ENTRY }];

export const PS_TELEMETRY_RESOURCE_ENTRY = CS_PS_TELEMETRY_ENTRY_ID;
export const PS_TELEMETRY_RESOURCE_NAME = 'hp_telemetry';
export const PS_TELEMETRY = [{ [PS_TELEMETRY_RESOURCE_NAME]: PS_TELEMETRY_RESOURCE_ENTRY }];

export const TONER_REPLACE_CONSENTS_RESOURCE_NAME = 'toner_recall';
export const TONER_REPLACE_CONSENTS = [TONER_REPLACE_CONSENTS_RESOURCE_NAME];

export const DATA_SHARING_NOTICE_RESOURCE_NAME = 'data_sharing_notice';
export const DATA_SHARING_NOTICE = [DATA_SHARING_NOTICE_RESOURCE_NAME];

export const DATA_COLLECTION_RESOURCE_NAME = 'data_collection_notice';
export const DATA_COLLECTION_NOTICE = (match, pathname) => {
  const DATA_COLLECTION_RESOURCE_ENTRY = DCN[match];
  return [{ [DATA_COLLECTION_RESOURCE_NAME]: DATA_COLLECTION_RESOURCE_ENTRY }];
};

export const HOME_RESOURCE_NAME = 'home';
export const HOME_SMART_ADVANCE_RESOURCE_NAME = 'smart_advance_home';
export const NEW_HOME = [HOME_RESOURCE_NAME, HOME_SMART_ADVANCE_RESOURCE_NAME, APP_BADGES_RESOURCE_NAME];

export const SMB_DOWNLOAD_RESOURCE_NAME = 'download';
export const SMB_DOWNLOAD = [SMB_DOWNLOAD_RESOURCE_NAME, APP_BADGES_RESOURCE_NAME];
export const GET_SOFTWARE = [SMB_DOWNLOAD_RESOURCE_NAME, APP_BADGES_RESOURCE_NAME];

export const ERROR_PAGE_RESOURCE_NAME = 'error_pages';
export const ERROR_PAGE = (match, pathname) => {
  const resource = pathname.replace('/error/', '').toLowerCase();
  let ERROR_RESOURCE_ENTRY = '';
  switch (resource) {
    case ERROR_AUTHZ:
      ERROR_RESOURCE_ENTRY = ERROR_AUTHZ_ENTRYID;
      break;
    case ERROR_INVITATION:
      ERROR_RESOURCE_ENTRY = ERROR_INVITATION_ENTRYID;
      break;
  }
  return [{ [ERROR_PAGE_RESOURCE_NAME]: ERROR_RESOURCE_ENTRY }];
}

export const PHOTO_PAPER_RESOURCE_NAME = 'photo_paper';
export const PHOTO_PAPER = [PHOTO_PAPER_RESOURCE_NAME];

export const GENERIC_SUPPORT_RESOURCE_NAME = 'generic_printer_support';
export const GENERIC_SUPPORT = [GENERIC_SUPPORT_RESOURCE_NAME];

export const PRINTER_LIST_RESOURCE_NAME = 'support_pages';
export const PRINTER_LIST_TEXT_RESOURCE_NAME = 'printer_listing_pages';
export const PRINTER_LIST_PAGE = [PRINTER_LIST_RESOURCE_NAME, PRINTER_LIST_TEXT_RESOURCE_NAME];
export const DEVICE_LIST_PAGE = [PRINTER_LIST_TEXT_RESOURCE_NAME];

export const MOBILE_FAX_RESOURCE_NAME = 'mobile_fax';
export const MOBILE_FAX = [MOBILE_FAX_RESOURCE_NAME, APP_BADGES_RESOURCE_NAME];

export const PRINTER_CONNECTION_RESOURCE_NAME = 'support_pages';
export const PRINTER_CONNECTION_PAGE = (match, pathname) => {
  const resource = pathname.replace('/support/printer-connection/', '').toLowerCase();
  let PRINTER_CONNECTION_RESOURCE_ENTRY = '';
  switch (resource) {
    case SLUG_3M: // 3M
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_3M;
      break;
    case SLUG_TACCOLA_FB: // Taccola FB
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_TACCOLA_FB;
      break;
    case SLUG_TACCOLA_ADF: // Taccola ADF
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_TACCOLA_ADF;
      break;
    case SLUG_VASARI_FB: // Vasari FB
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_VASARI_FB;
      break;
    case SLUG_VASARI_ADF: // Vasari ADF
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_VASARI_ADF;
      break;
    case SLUG_SKYREACH_FB: // Skyreach FB
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_SKYREACH_FB;
      break;
    case SLUG_SKYREACH_ADF: // Skyreach ADF
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_SKYREACH_ADF;
      break;
    case SLUG_EYRIE: // Eyrie
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_EYRIE;
      break;
    case SLUG_STORM: // Storm
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_STORM;
      break;
    case SLUG_HULK: // Hulk
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_HULK;
      break;
    case SLUG_YOSHINO: // Yoshino
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_YOSHINO;
      break;
    case SLUG_LOCHSA: // Lochsa
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_LOCHSA;
      break;
    case SLUG_ULYSSES: // Ulysses
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_ULYSSES;
      break;
    case SLUG_SELENE: // Selene
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_SELENE;
      break;
    case SLUG_BEAM: // Beam
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_BEAM;
      break;
    case SLUG_JUPITER: // Jupiter
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_JUPITER;
      break;
    case SLUG_BEAM_MFP: // Beam mfp"
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_BEAM_MFP;
      break;
    case SLUG_PIXIU: // PIXIU
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_PIXIU;
      break;
    case SLUG_TASSEL: // Tassel
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_TASSEL;
      break;
    case SLUG_EUTHENIA: // Euthenia
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_EUTHENIA;
      break;
    case SLUG_LEBI: // LEBI
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_LEBI;
      break;
    case SLUG_MARCONI: // Marconi
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_MARCONI;
      break;
    case SLUG_MORETO: // Moreto
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_MORETO;
      break;
    case SLUG_VICTORIA: // Victoria
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_VICTORIA;
      break;
    case SLUG_KEBIN: // Kebin
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_KEBIN;
      break;
    case SLUG_DRAGONFLY_BASE: // Dragonfly Base
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_DRAGONFLY_BASE;
      break;
    case SLUG_DRAGONFLY_WL: // Dragonfly WL
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_DRAGONFLY_WL;
      break;
    case SLUG_DRAGONFLY_ADF: // Dragonfly ADF
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_DRAGONFLY_ADF;
      break;
    case SLUG_TASSEL_BASE: // Tassel BASE
      PRINTER_CONNECTION_RESOURCE_ENTRY = ENTRYID_TASSEL;
    default:
      const entryId = SKU_TO_ENTRY_ID_MAPPING[resource?.toUpperCase()];

      if (entryId) {
        PRINTER_CONNECTION_RESOURCE_ENTRY = entryId;
      }

      break;
  }

  return [{ [PRINTER_CONNECTION_RESOURCE_NAME]: PRINTER_CONNECTION_RESOURCE_ENTRY }];
};

export const SMB_NO_SETUP_CHOICE_RESOURCE_ENTRY = 'blta36909c1c3981e83';
export const SMB_NO_SETUP_CHOICE_RESOURCE_NAME = 'smb_setup';
export const SMB_NO_SETUP = [{ [SMB_NO_SETUP_CHOICE_RESOURCE_NAME]: SMB_NO_SETUP_CHOICE_RESOURCE_ENTRY }];

export const SMB_SETUP_CHOICE_RESOURCE_ENTRY = 'bltf4010563bbc7d442';
export const SMB_SETUP_CHOICE_RESOURCE_NAME = 'smb_setup';
export const SMB_SETUP_CHOICE = [{ [SMB_SETUP_CHOICE_RESOURCE_NAME]: SMB_SETUP_CHOICE_RESOURCE_ENTRY }];

export const SURE_SUPPLY_LEARN_RESOURCE_NAME = 'sure_supply_learn';
export const SURE_SUPPLY_LEARN = [SURE_SUPPLY_LEARN_RESOURCE_NAME];

export const PRINTERS_COMMON_RESOURCE_NAME = 'printers_common';
export const PRINTERS_INFO = () => [PRINTERS_COMMON_RESOURCE_NAME, APP_BADGES_RESOURCE_NAME];

export const PRINTERS_LIST_RESOURCE_NAME = 'printer_listing';
