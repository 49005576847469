import ActionTypes from '../constants/ActionTypes';
import fetchStatus from './fetchStatus';

const printerCommonDefault = {
  ...fetchStatus(),
  data: [],
};

/**
 * The reducer controls the state of the axios or ajax call.
 * returning the current state with any changes from the
 * actions dispatchers
 *
 *
 * @param state
 * @param action
 * @returns {*}
 */
function printerCommon(state = printerCommonDefault, action) {
  switch (action.type) {
    case ActionTypes.START_PRINTERCOMMON_FETCH:
      return { ...fetchStatus(state, { ...action, type: ActionTypes.START_FETCH }) };
    case ActionTypes.COMPLETE_PRINTERCOMMON_FETCH:
      return {
        ...fetchStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }),
        data: action.printerCommon,
      };
    case ActionTypes.COMPLETE_PRINTERCOMMON_WITH_ERROR:
      return {
        ...fetchStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH_WITH_ERROR }),
        errorMsg: action.err.message,
      };
    case ActionTypes.CLEAR_CLAIM_ERROR:
      return {
        ...state,
        ...{ isError: false },
      };
    case ActionTypes.START_CLAIM_PRINTER:
      return { ...fetchStatus(state, { ...action, type: ActionTypes.START_FETCH }) };
    case ActionTypes.COMPLETE_CLAIM_PRINTER:// FIXME: Not setting isComplete true so that the loader stays visible
      return { ...fetchStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH }) };
    case ActionTypes.CLAIM_PRINTER_ERROR:
      return {
        ...fetchStatus(state, { ...action, type: ActionTypes.COMPLETE_FETCH_WITH_ERROR }),
        errorMsg: action.err,
        errorDetail: action.errorDetail,
      };
  }
  return state;
}

export default printerCommon;
// usage: this.props.printerCommon
