import React from 'react';
import styled from 'styled-components';
import { useSelector } from '../../../hooks';
import { selectAppBadgesContentStackData } from '../../../selectors/craft';

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  order: 3;
  margin-top: 2rem;
  width: 100%;
`;

const BadgeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 20rem;
  @media screen and (min-width: 38rem) {
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 100%;
  }
  max-width: 50rem;
  a {
    margin-bottom: 1rem;
    flex: 0 1 50%;
    width: 50%;
    max-height: 2.75rem;
    max-width: 11.25rem;
    &:last-child { order: -1; }
    @media screen and (min-width: 38rem) {
      flex: 0 0 33.3333%;
      width: 33.3333%;
      max-height: 3.5rem;
      &:last-child { order: 0; }
    }
    img {
      height: 100%;
      width: 100%
    }
  }
`;

export default function Badges({ footerText }) {
  const appBadgesContent = useSelector(selectAppBadgesContentStackData)?.data?.entries[0];
  const { landing_page_app_badges } = appBadgesContent ? appBadgesContent : {};
  if (landing_page_app_badges) {
    const [ios, google, microsoft] = landing_page_app_badges;
    return (
      <Container>
        <BadgeWrapper>
          {
            [ios, microsoft, google].map(appBadges => (
              <a
                href={appBadges.app_badge?.app_badge_link}
                key={appBadges.app_badge?.app_badge_link}
                id={appBadges.app_badge?.app_badge_id}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appBadges.app_badge?.app_badge_graphic?.url}
                  alt={appBadges.app_badge?.app_badge_graphic?.title}
                />
              </a>
            ))
          }
        </BadgeWrapper>
        {
          footerText && (
            <div dangerouslySetInnerHTML={{ __html: footerText }} />
          )
        }
      </Container>
    );
  } else {
    return null;
  }
}
