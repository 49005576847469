/* global JWeb */
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { getExchangeToken } from '../api/HPCSession';
import { getCookie } from '../../../utils/globals';
import { STRATUS_ACCESS_TOKEN, STRATUS_ID_TOKEN } from '../../../constants/cookieNames';
import Config from '../../../config/Config';
import { encodeAuthState } from './routing';

const accountProviderOptions = {
  allowUserInteraction: true,
  requireFreshToken: false,
};

export const isJWebAuthPluginValid = () => JWeb && JWeb.Plugins && JWeb.Plugins.Auth;
export const getStratusAccessToken = () => getCookie(STRATUS_ACCESS_TOKEN);
export const getStratusIdToken = () => getCookie(STRATUS_ID_TOKEN);

export const getDecodedStratusAccessToken = () => {
  const token = getStratusAccessToken();
  if (!token) {
    return null;
  }

  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (err) {
    return null;
  }
};

export const isStratusAccessTokenExpired = (token = getDecodedStratusAccessToken()) => {
  if (!token) {
    return false;
  }

  const decodedExpiration = token.exp;
  const decodedExpirationInMilli = decodedExpiration * 1000;
  const exp = new Date(decodedExpirationInMilli);
  return new Date() > exp;
};

export const isStratusAccessTokenRefreshable = (token = getDecodedStratusAccessToken()) => {
  if (!token) {
    return false;
  }

  const decodedExpiration = token.exp;
  const decodedExpirationInMilli = decodedExpiration * 1000;
  const refreshThreshold = 40000; // 40 seconds
  const minimumToRefresh = new Date(decodedExpirationInMilli - refreshThreshold);
  return new Date() > minimumToRefresh;
};

// Function that lets you detect the existance of the JWeb bridge,
// and trigger callbacks when its determined the bridge exists or not.
export const ifJWebAvailable = (jWebReadyCallback, jWebMissingCallback) => {
  // This first check is opportunistic,
  // where the timing implies that the Bridge has already injected
  // and fired the 'jWebReady' event.
  // In this case, JWeb should be available in the global scope..
  if (isJWebAuthPluginValid()) {
    jWebReadyCallback();
    return;
  }
  // If JWeb is not in the global scope yet,
  // Add a listener for the 'jWebReady' event that is fired during bridge injection.
  if (window) {
    window.addEventListener('jWebReady', jWebReadyCallback);
  }

  // Set a timeout if the bridge does not appear.
  setTimeout(() => {
    // Last chance to check for the bridge after time elapses..
    if (!isJWebAuthPluginValid()) {
      window.removeEventListener('jWebReady', jWebReadyCallback, false);
      jWebMissingCallback();
    }
  }, 1500);
};

export const getJWebAccessToken = async () => {
  const { Plugins } = JWeb;
  const { Auth } = Plugins;

  const accessToken = await Auth.getToken({ accountProviderOptions });
  return accessToken;
};

export const exchangeToken = async accessToken => {
  await getExchangeToken(accessToken);
};

export const jwebSignIn = async () => {
  if (!getStratusAccessToken() || isStratusAccessTokenExpired()) {
    const accessToken = await getJWebAccessToken();
    const { tokenValue } = accessToken;
    await exchangeToken(tokenValue);
  }
};

// When we are calling it we already made the async check for jweb availability,
// so we assume that JWeb is available or not
export const isJWebDesktopApp = () => !isMobile && isJWebAuthPluginValid() && JWeb.isNative;

export const isJWebiOSApp = () => isIOS && isJWebAuthPluginValid() && JWeb.isNative;

export const isJWebAndroidApp = () => isAndroid && isJWebAuthPluginValid() && JWeb.isNative;

export const dispatchJWebCloseEvent = () => {
  const isJWebEventingPluginValid = () => JWeb && JWeb.Plugins && JWeb.Plugins.Eventing;

  if (!isJWebEventingPluginValid()) {
    console.error('JWeb.Plugins.Eventing is not valid.');
    return;
  }

  try {
    const { Eventing } = JWeb.Plugins;
    Eventing.dispatchEvent({ name: 'Close', data: {} });
  } catch (err) {
    console.error(`Error dispatching Close event to JWeb:\n${err}`);
  }
};

export const loginUrlLBuilder = async (shouldExchangeToken, shouldForceLogin, baseUrl, redirectUrl = '') => {
  const handshake = String(Math.random());
  const config = new Config();
  const state = encodeAuthState(handshake, `${baseUrl}${redirectUrl}`);
  let signinUrl = `${config.Get('AUTHZ_OPENID_LOGIN')}${state}`;
  signinUrl = (shouldForceLogin) ? `${signinUrl}&prompt=login` : signinUrl;
  if (shouldExchangeToken) {
    const token = getStratusAccessToken();
    await getExchangeToken(token);
  }

  return signinUrl;
};
