import ActionTypes from '../constants/ActionTypes';
import { getContentStackData } from '../services/craft';
import { PAPER_SUBSCRIPTION } from '../constants/contentStackResources';

export function getOrigamiPageData(language) {
  let start = () => ({
      type: ActionTypes.START_ORIGAMI_FETCH
    }),
    complete = (response) => ({
      type: ActionTypes.COMPLETE_ORIGAMI_FETCH_SUCCESS,
      response
    }),
    error = (err) => ({
      type: ActionTypes.COMPLETE_ORIGAMI_FETCH_WITH_ERROR,
      err
    });

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(start());

      getContentStackData(PAPER_SUBSCRIPTION, language)
        .then((res) => {
          dispatch(complete(res.data.entries[0]));
          return resolve(res.data.entries[0]);
        })
        .catch((err) => {
          dispatch(error(err));
          reject(err);
        });
    });
  };
}
