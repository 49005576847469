import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.a`
  border: ${props => props.borderOverride ? props.borderOverride : "2px"} solid;
  text-decoration: none;
  ${props => (((props.buttonType == 'secondary') && ('background: transparent; color: #0171AD; border-color: #0171AD;')) ||
    ((props.buttonType == 'primaryDarkBg') && 'background: white; color: #0171AD; border-color: white;') ||
    ((props.buttonType == 'primary') || (props.buttonType == undefined)) && 'background: #0171AD; color: #FFF; border-color: #0171AD;')
  }
  ${props => (props.theme.boldFontFamilyAndWeight)}
  &:hover, &:focus { 
    cursor: pointer;
    ${props => (((props.buttonType == 'secondary') && ('background: transparent; color: #0171AD; border-color: #0171AD;')) ||
      ((props.buttonType == 'primaryDarkBg') && 'background: white; color: #0171AD; border-color: white;') ||
      ((props.buttonType == 'primary') || (props.buttonType == undefined)) && 'background: #0171AD; color: #FFF; border-color: #0171AD;')
    }
    text-decoration: none;
  }
  border-radius: ${props => (props.softButton ? '1rem' : '0.1875rem')};
  padding: ${props => props.vertPadOverride ? props.vertPadOverride : "0.5rem" } 0;
  width: ${props => props.widthOverride || "13rem"};
  font-size: ${props => props.fontSizeOverride ? props.fontSizeOverride : "1.25rem" };
  span {
    display: inline-block;
    width: 1rem;
    margin-left: 1rem;
    img { width: 100% }
  }
  @media screen and (min-width:48rem) {
    width: ${props => props.widthOverride || "14.5rem"};
    font-size: ${props => props.fontSizeOverride ? props.fontSizeOverride : "1.25rem" };
  }
  @media screen and (min-width:60rem) {
    width: ${props => props.widthOverride || "16rem"};
    font-size: ${props => props.fontSizeOverride ? props.fontSizeOverride : "1.375rem" };
  }
  text-align: center;
`;
export default function Button(props) {
  return (
    <StyledButton
      buttonType={props.buttonType}
      vertPadOverride={props.vertPadOverride}
      widthOverride={props.widthOverride}
      fontSizeOverride={props.fontSizeOverride}
      borderOverride={props.borderOverride}
      href={props.onClick ? null : props.buttonLink}
      onClick={props.onClick ? props.onClick : null}
      data-tracking={props.datatracking}
      id={props.buttonID} 
      target={props.newTab ? "_blank" : "_self"}
      tabIndex="0"
      softButton={props.softButton}
    >
      {props.buttonText}
      {props.buttonIcon ?
        <span><img src={props.buttonIcon} alt="" /></span>
        : undefined}
    </StyledButton>
  )
}
