import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import OrigamiValueProp from '../OrigamiValueProp';
import SkeletonValueProp from '../Skeleton/SkeletonValueProp';
import { StyledSection } from '../styles/StyleOrigami';
import { SkeletonContainer } from '../styles/StyleSkeleton';

const propTypes = {
  valuePropSection: PropTypes.array.isRequired,
  valuePropText: PropTypes.string.isRequired,
  valuePropFreeMonthText: PropTypes.string,
  valuePropTextField: PropTypes.string,
  addPaperButtonText: PropTypes.string.isRequired,
  enrollLinkText: PropTypes.string.isRequired,
  enrollLinkChevronTitle: PropTypes.string.isRequired,
  enrollLinkChevronUrl: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleEnrollLink: PropTypes.func.isRequired
};

const defaultProps = {
  valuePropSection: [],
  valuePropText: '',
  valuePropFreeMonthText: '',
  valuePropTextField: '',
  addPaperButtonText: '',
  enrollLinkText: '',
  enrollLinkChevronTitle: '',
  enrollLinkChevronUrl: ''
};

const OrigamiValuePropContent = (props) => {
  const {
    valuePropSection,
    valuePropText,
    valuePropFreeMonthText,
    valuePropTextField,
    addPaperButtonText,
    enrollLinkText,
    enrollLinkChevronTitle,
    enrollLinkChevronUrl,
    handleClick,
    handleEnrollLink
  } = props;
  return (
    <StyledSection>
      <OrigamiValueProp
        valuePropSection={valuePropSection}
        valuePropText={valuePropText}
        valuePropFreeMonthText={valuePropFreeMonthText}
        valuePropTextField={valuePropTextField}
        addPaperButtonText={addPaperButtonText}
        enrollLinkText={enrollLinkText}
        enrollLinkChevronTitle={enrollLinkChevronTitle}
        enrollLinkChevronUrl={enrollLinkChevronUrl}
        handleClick={handleClick}
        handleEnrollLink={handleEnrollLink}
      />
    </StyledSection>
  );
};

const OrigamiValuePropPromise = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          default: (props) => {
            const {
              valuePropSection,
              valuePropText,
              valuePropFreeMonthText,
              valuePropTextField,
              addPaperButtonText,
              enrollLinkText,
              enrollLinkChevronTitle,
              enrollLinkChevronUrl,
              handleClick,
              handleEnrollLink
            } = props;
            return (
              <OrigamiValuePropContent
                valuePropSection={valuePropSection}
                valuePropText={valuePropText}
                valuePropFreeMonthText={valuePropFreeMonthText}
                valuePropTextField={valuePropTextField}
                addPaperButtonText={addPaperButtonText}
                enrollLinkText={enrollLinkText}
                enrollLinkChevronTitle={enrollLinkChevronTitle}
                enrollLinkChevronUrl={enrollLinkChevronUrl}
                handleClick={handleClick}
                handleEnrollLink={handleEnrollLink}
              />
            );
          }
        });
      }, 1000);
    })
);

const OrigamiLazyValueProp = (props) => {
  const {
    valuePropSection,
    valuePropText,
    valuePropFreeMonthText,
    valuePropTextField,
    addPaperButtonText,
    enrollLinkText,
    enrollLinkChevronTitle,
    enrollLinkChevronUrl,
    handleClick,
    handleEnrollLink
  } = props;
  return (
    <Suspense
      fallback={
        <SkeletonContainer>
          <SkeletonValueProp />
        </SkeletonContainer>
      }
    >
      <OrigamiValuePropPromise
        valuePropSection={valuePropSection}
        valuePropText={valuePropText}
        valuePropFreeMonthText={valuePropFreeMonthText}
        valuePropTextField={valuePropTextField}
        addPaperButtonText={addPaperButtonText}
        enrollLinkText={enrollLinkText}
        enrollLinkChevronTitle={enrollLinkChevronTitle}
        enrollLinkChevronUrl={enrollLinkChevronUrl}
        handleClick={handleClick}
        handleEnrollLink={handleEnrollLink}
      />
    </Suspense>
  );
};

OrigamiLazyValueProp.propTypes = propTypes;

OrigamiLazyValueProp.defaultProps = defaultProps;

export default OrigamiLazyValueProp;
