/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router';
import { ThemeProvider } from 'styled-components';
import {
  ToastProvider,
} from '@veneer/core';
import { push } from 'connected-react-router';
import { pathOr } from 'ramda';
import { Provider as KeepAliveProvider } from 'react-keep-alive';
import Portal from './Portal';
import MyPrinters from './Dashboard/pages/MyPrinters';
import HelpCenter from './Dashboard/pages/HelpCenter';
import Home from './Dashboard/pages/Dashboard/Home';
import ManageUsers from './Dashboard/pages/Dashboard/ManageUsers';
import Profile from './Dashboard/pages/Dashboard/AccountDetails/Profile';
import PrivacySettings from './Dashboard/pages/Dashboard/AccountDetails/PrivacySettings';
import InstantSupplies from './Dashboard/pages/Dashboard/InstantSupplies';
import ServicesSummary from './Dashboard/pages/Dashboard/Services/Summary';
import SmartSecurityPage from './Dashboard/pages/Dashboard/Services/SmartSecurity';
import { Content, UCDEContainer, UCDEMainTheme } from './Dashboard/pages/Dashboard/styles';
import SignIn from './Dashboard/components/SignIn';
import ProtectRoute from './Dashboard/components/ProtectRoute';
import { createRelativePath } from '../utils/routing';
import {
  selectUcdeUserApiError,
  selectUcdeUserLoading,
  selectUcdeUserLocale,
} from './Dashboard/store/modules/ucdeUser/selectors';
import selectUcdePortalElements from './Dashboard/store/modules/portalElements/selectors';
import { selectPathnameWithoutLocale } from '../selectors/routing';
import './Dashboard/assets/globals/styles/globals.scss';
import './Dashboard/assets/globals/styles/dashboard.scss';
import Loader from './Dashboard/components/shared-components/Loader';
import Onboarding from './Dashboard/pages/Onboarding';
import PrintOnTheGo from './Dashboard/pages/Dashboard/Services/PrintOnTheGo';
import AtlasInboxNotificationsPage from './Dashboard/pages/Notifications';
import NotificationSettingsPage from './Dashboard/pages/NotificationSettings/index';
import i18n from './Dashboard/utils/i18n';
import {
  gatewayRouteEnabledForBaseUser,
  signInOrOnboardingRoute,
} from './Dashboard/utils/routing';
import { isJWebDesktopApp } from './Dashboard/utils/auth';
import { renderErrorModal } from './Dashboard/components/ErrorModal';
import ScrollToTop from './Dashboard/components/shared-components/ScrollToTop';
import { useHandlePageViewTracking } from '../hooks';
import { serviceEnabled } from './Dashboard/utils/portalElements';
import {
  HOME_PAGE,
  PATHS,
  SERVICES,
} from './Dashboard/utils/constants';
import { USER_TYPE, HPC3_SESSION_TYPE } from '../constants/cookieNames';
import { getCookie } from '../utils/globals';
import { initializePortalInterface } from './Dashboard/interface';

const UCDERoutes = ({ match, gateway }) => {
  const dispatch = useDispatch();
  const pathnameWithoutLocale = useSelector(selectPathnameWithoutLocale);
  const ucdeUserApiError = useSelector(selectUcdeUserApiError);
  const isUcdeUserLoading = useSelector(selectUcdeUserLoading);
  const ucdeUserLocale = useSelector(selectUcdeUserLocale);
  const ucdePortalElements = useSelector(selectUcdePortalElements());

  const {
    params: {
      country,
      language,
    },
  } = match;

  useHandlePageViewTracking();

  useEffect(() => {
    initializePortalInterface();
  }, []);

  useEffect(() => {
    if (ucdeUserApiError || ucdePortalElements.apiError) {
      renderErrorModal({
        title: i18n.t('home.error.retrieveData.title'),
        errorMessage: i18n.t('home.error.retrieveData.message'),
        actionLabel: i18n.t('home.error.confirmLabel'),
        actionCallback: () => window.location.reload(),
        dismissLabel: i18n.t('home.error.cancelLabel'),
        dismissCallback: () => dispatch(push(`/${country}/${language}/ucde${PATHS.SIGNOUT}`)),
      });
    }
  }, [ucdeUserApiError, ucdePortalElements.apiError]);

  const baseUser = (getCookie(USER_TYPE) || '').toLowerCase() === 'base';

  if (baseUser) {
    if (!gatewayRouteEnabledForBaseUser(pathnameWithoutLocale)) {
      const userLevel = getCookie(HPC3_SESSION_TYPE);
      window.location.href = userLevel === 'user' ? '/myprinters' : '/';
      return <Loader fullScreen />;
    }

    if (!gateway && !signInOrOnboardingRoute(pathnameWithoutLocale)) {
      const toGateway = pathnameWithoutLocale.replace('ucde', 'gateway');
      dispatch(push(`/${country}/${language}${toGateway}`));
    }
  }

  UCDEMainTheme.gateway = gateway;
  UCDEMainTheme.hostIsDesktopApp = isJWebDesktopApp();

  if (isUcdeUserLoading || ucdePortalElements.loading) {
    return (
      <ThemeProvider theme={UCDEMainTheme}>
        <Loader fullScreen />
      </ThemeProvider>
    );
  }

  if (i18n.locale() !== `${language}_${country.toUpperCase()}`) {
    i18n.setSupportedLocale(country, language, ucdeUserLocale);
    const toPath = `/${i18n.country.toLowerCase()}/${i18n.language}${pathnameWithoutLocale}`;
    dispatch(push(toPath));
  }

  const homePage = () => pathOr([], ['home', 'tags', 0], ucdePortalElements);

  const RootPage = () => { 
    /**
     * Commented for testing purpose on pie. Will revisit on this.
     */

    // if (homePage() === HOME_PAGE.ACCOUNT_PROFILE) {
    //   return dispatch(push(`/${country}/${language}/ucde${PATHS.ACCOUNT_DETAILS.PROFILE}`));
    // }

    // return <Home />;

    return dispatch(push(`/${country}/${language}/ucde${PATHS.ACCOUNT_DETAILS.PROFILE}`));
  };

  return (
    <ThemeProvider theme={UCDEMainTheme}>
      <KeepAliveProvider>
        <UCDEContainer>
          <ScrollToTop />
          <ToastProvider
            position="top"
          >
            <Switch>
              <Route path={createRelativePath(match, PATHS.SIGNIN)}>
                <SignIn url={match.url} />
              </Route>
              <Route path={createRelativePath(match, PATHS.ONBOARDING)}>
                <Onboarding />
              </Route>
              <Route path={createRelativePath(match, '/')}>
                <ProtectRoute
                  path={createRelativePath(match, '/')}
                  component={
                    gateway
                      ? null
                      : <Portal match={match} />
                  }
                />
                <Content>
                  <Switch>
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.HOME)}
                      component={<Home />}
                      disabled={homePage() !== HOME_PAGE.SMART_DASHBOARD}
                    />
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.MY_PRINTERS)}
                      component={<MyPrinters />}
                      disabled={!ucdePortalElements.myPrinters}
                    />
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.MANAGE_USERS)}
                      component={<ManageUsers />}
                      disabled={!ucdePortalElements.manageUsers}
                    />
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.SERVICES.SUMMARY)}
                      component={<ServicesSummary />}
                      disabled={!ucdePortalElements.services}
                    />
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.SERVICES.PRINT_ON_THE_GO)}
                      component={props => <PrintOnTheGo {...props} />}
                      disabled={!serviceEnabled(ucdePortalElements, SERVICES.PRINT_ON_THE_GO)}
                    />
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.SERVICES.SMART_SECURITY)}
                      component={props => <SmartSecurityPage {...props} gateway={gateway} />}
                      disabled={!serviceEnabled(ucdePortalElements, SERVICES.SMART_SECURITY)}
                    />
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.ACCOUNT_DETAILS.PROFILE)}
                      component={<Profile />}
                    />
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.ACCOUNT_DETAILS.VIEW_NOTIFICATIONS)}
                      component={<AtlasInboxNotificationsPage />}
                    />
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.ACCOUNT_DETAILS.NOTIFICATIONS_SETTINGS)}
                      component={<NotificationSettingsPage />}
                    />
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.ACCOUNT_DETAILS.PRIVACY_SETTINGS)}
                      component={<PrivacySettings />}
                    />
                    {/* Instant supplies micro frontend */}
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.PRINT_PLANS)}
                      component={props => <InstantSupplies {...props} />}
                      disabled={!ucdePortalElements.printPlans}
                    />
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.ACCOUNT_DETAILS.PRINT_PLANS)}
                      component={props => <InstantSupplies {...props} />}
                      disabled={
                        !ucdePortalElements.printPlans
                          || !pathOr([], ['accountDetails', 'tags'], ucdePortalElements)
                            .includes('FULL_EDIT_AND_BILLING_SHIPPING_PAGES')
                      }
                    />
                    <ProtectRoute
                      path={createRelativePath(match, PATHS.HELP_CENTER)}
                      component={routeProps => <HelpCenter {...routeProps} />}
                    />
                    <ProtectRoute
                      path={createRelativePath(match, '/')}
                      component={RootPage}
                    />
                  </Switch>
                </Content>
              </Route>
            </Switch>
          </ToastProvider>
        </UCDEContainer>
      </KeepAliveProvider>
    </ThemeProvider>
  );
};

export default UCDERoutes;
