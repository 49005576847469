import React from 'react';
import styled from 'styled-components';
import {
  path,
} from 'ramda';
import PrinterHero from './PrinterHero';
import PrinterInk from './PrinterInk';
import GetInk from './GetInk';
import GetReadyToPrint from './GetReadyToPrint';
import EdgeLighting from './EdgeLighting';
import ProductTour from './ProductTour';
import InterfaceTour from './InterfaceTour';
import GetSupport from './GetSupport';
import InPageMenu from '../shared-components/organisms/InPageMenu';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';
import { useSelector } from '../../hooks';
import { selectProductPageCraftData } from '../../selectors/products';
import { selectRootMatch } from '../../selectors/routing';
import ProductSection from './ProductSection';
import CreateAccountSection from './CreateAccountSection';

const GreyBackgroundWrapper = styled.div`
  background-color: #f9f9f9;
`;
const FootNote = styled.div`
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding: 0 20px 20px;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;

const ProductPage = () => {
  const {
    device,
    printer_common,
    setUpSectionProps,
    useAppSectionProps,
    footnoteText,
    isTango,
  } = useSelector(selectProductPageCraftData);
  const match = useSelector(selectRootMatch);
  if (!device || printer_common.isError) {
    return (
      <div className="contentLoading">
        <LoaderBlock />
      </div>
    );
  }

  const sectionList = [
    { sectionID: 'hp-smart-app', sectionTitle: printer_common.app_promotion_title },
    { sectionID: 'get-ink', sectionTitle: printer_common.ink_section_title },
    { sectionID: 'learn-about', sectionTitle: device.tourTitle },
    { sectionID: 'get-support', sectionTitle: printer_common.support_title },
  ];

  const deeplinkList = [
    { sectionID: 'hp-smart-app', sectionTitle: 'appPromoSection' },
    { sectionID: 'get-ink', sectionTitle: 'inkSection' },
    { sectionID: 'learn-about', sectionTitle: 'productTourSection' },
    { sectionID: 'get-ready', sectionTitle: 'getReadySection' },
    { sectionID: 'display', sectionTitle: 'displaySection' },
    { sectionID: 'print-anywhere', sectionTitle: 'printAnywhere' },
    { sectionID: 'wifi-reset', sectionTitle: 'directionsSectionA' },
    { sectionID: 'get-support', sectionTitle: 'supportSection' },
    { sectionID: 'get-started', sectionTitle: 'finalCTASection' },
  ];

  const newSectionList = [
    { sectionID: 'my-printer', sectionTitle: printer_common.tour_title },
    { sectionID: 'hp-smart-app', sectionTitle: printer_common.my_printer_section_title_text },
    { sectionID: 'get-ink', sectionTitle: printer_common.ink_section_title },
    { sectionID: 'get-support', sectionTitle: printer_common.support_title },
  ];

  const newDeeplinkList = [
    { sectionID: 'my-printer', sectionTitle: 'myPrinter' },
    { sectionID: 'hp-smart-app', sectionTitle: 'hpSmartApp' },
    { sectionID: 'get-ink', section: 'inkSection' },
    { sectionID: 'get-support', sectionTitle: 'supportSection' },
  ];

  const isProductTour = !!device.tourSliderPanels;
  const isInterfaceTour = !!device.interfaceTourSelector;
  const isCartridgeSelector = !!device.cartridgeSelector;

  return (
    <div className="content">
      {isInterfaceTour ? (
        <InPageMenu
          sectionList={newSectionList} deeplinkList={newDeeplinkList}
          deeplinkURLSegment="4"
        />
      ) : (
        <InPageMenu
          sectionList={sectionList} deeplinkList={deeplinkList}
          deeplinkURLSegment="4"
        />
      )}
      <PrinterHero
        bannerImages={device.printerPrimaryImage}
        printerName={device.title}
      />
      {isInterfaceTour && <InterfaceTour device={device} printer_common={printer_common} />}
      {isProductTour && <ProductTour device={device} />}
      <ProductSection {...setUpSectionProps} />
      {
        !isTango
          ? (
            <GreyBackgroundWrapper>
              <ProductSection {...useAppSectionProps} />
            </GreyBackgroundWrapper>
          ) : (
            <React.Fragment>
              <ProductSection {...useAppSectionProps} />
              <GetReadyToPrint device={device} />
              <EdgeLighting device={device} />
            </React.Fragment>
          )
      }
      <CreateAccountSection />
      {
        isCartridgeSelector
          ? <GetInk />
          : (
            <PrinterInk
              countryCode={path(['params', 'country'], match)}
              device={device}
              printer_common={printer_common}
            />
          )
      }
      <GetSupport device={device} printer_common={printer_common} />
      {
        footnoteText && (
          <GreyBackgroundWrapper>
            <FootNote>
              {footnoteText}
            </FootNote>
          </GreyBackgroundWrapper>
        )
      }
    </div>
  );
};

export default ProductPage;
