import React, { useEffect } from 'react';
import {
  isEmpty,
  not,
} from 'ramda';
import {
  IconPlus,
  IconReload,
} from '@veneer/core';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../../hooks';
import ServerError from '../../components/shared-components/ServerError';
import UserDevice from '../../components/UserDevices/UserDevice';
import { getDetailedDevices } from '../../store/modules/devices/actions';
import { selectMyPrintersProps } from '../../store/modules/devices/selectors';
import {
  Container,
  Description,
  Grid,
  GridSeparator,
  MainContent,
  SideContent,
  Title,
} from '../../components/shared-components/styles';
import {
  DefaultButton,
  LinkButton,
  NoPrinters,
  PrinterCard,
  SubContent,
  SubDescription,
  SubGroup,
} from './styles';
import i18n from '../../utils/i18n';
import Loader from '../../components/shared-components/Loader';

const MyPrintersPage = () => {
  const {
    devices,
    lastFetch,
    isFetching,
    isError,
  } = useSelector(selectMyPrintersProps);
  const dispatch = useDispatch();

  const refreshDevices = () => {
    dispatch(getDetailedDevices());
  };

  useEffect(() => {
    if ((!devices || isEmpty(devices)) && !lastFetch) {
      refreshDevices();
    }
  }, [devices]);

  const handleAdd = () => window.alert('this will take you to add printers');
  const addButton = (
    <DefaultButton
      leadingIcon={<IconPlus />}
      onClick={handleAdd}
    >
      {i18n.t('myPrinters.headers.button.addPrinter')}
    </DefaultButton>
  );
  const fetchText = (
    <SubDescription>{lastFetch && i18n.t('myPrinters.headers.lastUpdate', { timestamp: lastFetch })}</SubDescription>
  );
  const refreshButton = (
    <LinkButton onClick={refreshDevices}>
      <IconReload />
      {i18n.t('myPrinters.headers.button.refresh')}
    </LinkButton>
  );

  const hasPrinters = devices && not(isEmpty(devices));

  let content = null;

  if (isFetching) {
    content = <Loader />;
  } else if (isError) {
    content = (
      <Grid>
        <MainContent>
          <PrinterCard
            appearance="dropShadow"
            content={<ServerError direction="column" largerText />}
          />
        </MainContent>
      </Grid>
    );
  } else if (hasPrinters) {
    content = (
      <Grid>
        <MainContent>
          {
            devices.map(device => (
              <PrinterCard
                appearance="dropShadow"
                key={`${device.serialNumber}status${device.modelName}`}
                content={<UserDevice device={device} printersPage />}
              />
            ))
          }
        </MainContent>
      </Grid>
    );
  } else {
    content = (
      <Grid>
        <MainContent>
          <PrinterCard
            appearance="dropShadow"
            content={(
              <NoPrinters>
                <img
                  src="/assets/img/dashboard/generic-printer.svg"
                  alt={i18n.t('myPrinters.noPrintersInList.message')}
                />
                {i18n.t('myPrinters.noPrintersInList.message')}
              </NoPrinters>
            )}
          />
        </MainContent>
      </Grid>
    );
  }

  return (
    <Container>
      <GridSeparator noMargin>
        <Title>{i18n.t('myPrinters.headers.mainHeader')}</Title>
      </GridSeparator>
      <Grid noMargin>
        <MainContent>
          <Description noMargin>{i18n.t('myPrinters.headers.description')}</Description>
        </MainContent>
        {
          hasPrinters && (
            <>
              <SideContent>
                <SubGroup>
                  {/* addButton removed for now */}
                  {!isFetching && (
                    <>
                      &nbsp;
                      <SubGroup nested>
                        {refreshButton}
                        {fetchText}
                      </SubGroup>
                    </>
                  )}
                </SubGroup>
              </SideContent>
              {!isFetching && (
                <SubContent>
                  <SubGroup>
                    {fetchText}
                    {refreshButton}
                  </SubGroup>
                </SubContent>
              )}
            </>
          )
        }
      </Grid>
      {content}
    </Container>
  );
};

export default MyPrintersPage;
