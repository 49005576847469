import styled from 'styled-components';
import { theme, venBreaks } from '../../../../utils/styleHelpers';

export const HeaderContainer = styled.div`
  background-image: url(${(props) => props.background});
  margin-bottom: 10px;
  h1 {
    color: #ffffff;
  }

  button {
    color: #027aae;
    border: 1px solid #027aae;
    box-sizing: border-box;
    border-radius: 12px;
    font-family: ${theme.fonts.family};
    height: 48px;
    mix-blend-mode: normal;
    width: auto;
    ${(props) => (props.isRTL ? 'margin: 25px 162px 50px auto' : 'margin: 25px auto 50px 162px')};
  }
`;

export const HeaderText = styled.h1`
  font-size: 58px;
  font-family: ${theme.fonts.familyLight};
  height: auto;
  max-width: 899px;
  line-height: 67.22px;
  ${(props) => (props.isRTL ? 'margin: 110px 118px 25px auto' : 'margin: 110px auto 25px 118px')};

  @media (max-width: ${venBreaks.desktop}) {
    max-width: 699px;
    ${(props) => (props.isRTL ? 'margin: 110px 118px 22px auto' : 'margin: 110px auto 22px 118px')};
  }

  @media (max-width: 589px) {
    max-width: 599px;
    ${(props) => (props.isRTL ? 'margin: 110px 118px 30px auto' : 'margin: 110px auto 30px 118px')};
  }
`;

export const Feature = styled.h1`
  font-family: ${theme.fonts.familyLight};
  font-size: 24px;
  font-weight: 300;
  height: 62px;
  line-height: 28px;
  max-width: 942px;
  ${(props) => (props.isRTL ? 'margin: 0 118px 10px auto' : 'margin: 0 auto 10px 118px')};

  @media (max-width: 678px) {
    max-width: 599px;
    ${(props) => (props.isRTL ? 'margin: 0 118px 45px auto' : 'margin: 0 auto 45px 118px')};
  }
`;

export const Steps = styled.div`
  color: #ffffff;
  font-family: ${theme.fonts.familyLight};
  font-weight: 300;
  line-height: 35px;
  width: auto;
  ${(props) => (props.isRTL ? 'margin: 20px 118px 10px auto' : 'margin: 20px auto 10px 118px')};
`;

export const StepNumber = styled.span`
  font-size: 40px;
  ${(props) => (props.isRTL ? 'margin-left: 25px' : 'margin-right: 25px')};
`;

export const StepDescription = styled.p`
  display: inline;
  font-size: 30px;

  p {
    display: inline;
  }
`;
