import styled from 'styled-components';

export const HpPlusBackground = styled.div`
  ${props => props.isHpPlus && `background-image: url("${props.hpMosaicBackground}")`};
  padding: 0 32px 8px 32px;
  margin: 0 -32px -64px -32px;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  @media (max-width: ${props => props.theme.breakPoints.xg}) {
    ${props => (props.theme.hostIsDesktopApp ? '' : 'left: 0;')};
  }
`;

export default HpPlusBackground;
