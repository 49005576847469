import React, { useEffect } from 'react';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';

const SignOut = ({ loc }) => {
  useEffect(() => {
    window.location.href = loc;
  }, []);

  return (
    <div className="contentLoading">
      <LoaderBlock />
    </div>
  );
};

export default SignOut;
