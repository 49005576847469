import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  font-family: ${props => props.theme.main.hp.fontFamily};
  font-size: ${props => props.theme.main.hp.fontSize}; 
  justify-content: space-between;

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    max-width: 975px;
    width: 100%;
  }
`;

export const Title = styled.h4`
  flex-basis: 100%;
  font-size: 40px;
  font-family: ${props => props.theme.main.hp.fontFamilyLight};
  margin-bottom: 10px;
  padding-top: 20px;
`;

export const Description = styled.div`
  font-family: ${props => props.theme.main.hp.fontFamilyLight};
  font-size: 16px;
  margin-bottom: 32px;
  max-width: 660px;
`;

export const HelpContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 100%;
  padding: 50px 64px 70px 12px;
  max-width: 500px;

  > div {
    border-bottom: 1px solid #EBEBEB;

    p {
      margin-bottom: 15px;
      font-family: ${props => props.theme.main.hp.fontFamilyLight};
      font-size: ${props => props.theme.main.hp.fontSize}; 
      color: #212121;
    }
  }

  > a {
    margin-top: 25px;
    text-decoration: none;

    p {
      font-family: ${props => props.theme.main.hp.fontFamily};
      font-size: 12px;
      font-weight: 700;
      color: #027AAE;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;
