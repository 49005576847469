import React from 'react';
import Config from '../../../../config/Config';
import SignOut from '../../../global/signout';

const config = new Config();

export default () => {
  const signOutUrl = `${config.Get('SESSION_SERVICE_BASE_URL')}/logout`;

  return <SignOut loc={signOutUrl} />;
};
