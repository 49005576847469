import styled from 'styled-components';
import { breakpoints,theme } from '../../../utils/styleHelpers';

export const OrigamiFaqSection = styled.div`
  && {
    background-color: #f8f8f8;
    justify-content: center;
    width: 100%;
    @media (min-width: ${breakpoints.bigtablet}) {
      display: flex;
    }
    @media (max-width: ${breakpoints.bigmobile}){
      padding: 20px;
    }
    .vn-animated-height__container {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: height 0.5s ease;
      visibility: hidden;
    }
    .vn-animated-height__container--entering,
    .vn-animated-height__container--exiting {
      opacity: 1;
      visibility: inherit;
    }
    .vn-animated-height__container--entered {
      height: auto;
      opacity: 1;
      overflow: visible;
      visibility: inherit;
    }
  }
`;

export const OrigamiFaqContent = styled.div`
  && {
    width: 80vw;
    margin: 0px auto;

    .title {
      font-family: 'Forma DJR', sans-serif;
      font-size: 56px;
      line-height: 64px;
      font-weight: 400;
      color: #212121;
      margin-bottom: 20px;
      margin-top: 75px;
      @media (min-width: ${breakpoints.bigtablet}) {
        margin-left: 0;
      }
      @media (max-width: 768px) {
        font-size: 40px;
        line-height: 48px;
        margin-left: 0;
      }
      @media (max-width : ${breakpoints.bigmobile}){
        font-size: 28px;
        font-family: 'Forma DJR', sans-serif;
        line-height: 36px;
        margin-top: 50px;
        margin-bottom: 35px;
      }
    }
    .titleContainer div div {
      outline: -webkit-focus-ring-color none 0px;
      outline-color: -webkit-focus-ring-color;
      outline-style: none !important; 
      outline-width: 0px !important;
    }
    .control-bar {
      display: flex;
      justify-content: right;
      margin-top: 35px;
      margin-bottom: 72px;
      margin-left: 5px;
      @media (max-width: ${breakpoints.small}){
        .show-more {
          width: 100%;
        }
        .show-less {
          width: 100%;
        }
      }
    }
  }
`;

export const AccordionBody = styled.div`
  > p {
    line-height: 1.5;
    margin-top: 1em;
  }
`;

export const AccordionHeader = styled.span`
  color: #4e4e4e;
  font-weight: 400;
  font-size: 1rem;
  @media (max-width: ${breakpoints.bigmobile}){
    font-family: 'Forma DJR', sans-serif;
  }
`;
