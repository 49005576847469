/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import {
  path,
  pick,
} from 'ramda';

const SELECTORS_BASE = {
  selectMyPrinterWPP: path(['myPrinter', 'data', 'WPPData']),
};

export const selectPrinterIdentificationMeta = createSelector(
  SELECTORS_BASE.selectMyPrinterWPP,
  pick(['ownershipId', 'printerId', 'generation']),
);
