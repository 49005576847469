import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import OrigamiFaqs from '../OrigamiFaqs';
import SkeletonFaqs from '../Skeleton/SkeletonFaqs';
import { StyledSection } from '../styles/StyleOrigami';
import { SkeletonContainer } from '../styles/StyleSkeleton';

const propTypes = {
  faqHeading: PropTypes.string.isRequired,
  faqSection: PropTypes.array.isRequired,
  showMoreButtonText: PropTypes.string.isRequired,
  showLessButtonText: PropTypes.string.isRequired
};

const defaultProps = {
  faqHeading: '',
  faqSection: [],
  showMoreButtonText: '',
  showLessButtonText: ''
};

const OrigamiFaqsContent = (props) => {
  const { faqHeading, faqSection, showMoreButtonText, showLessButtonText } = props;
  return (
    <StyledSection>
      <OrigamiFaqs
        faqHeading={faqHeading}
        faqSection={faqSection}
        showMoreButtonText={showMoreButtonText}
        showLessButtonText={showLessButtonText}
      />
    </StyledSection>
  );
};

const OrigamiFaqsPromise = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          default: (props) => {
            const { faqHeading, faqSection, showMoreButtonText, showLessButtonText } = props;
            return (
              <OrigamiFaqsContent
                faqHeading={faqHeading}
                faqSection={faqSection}
                showMoreButtonText={showMoreButtonText}
                showLessButtonText={showLessButtonText}
              />
            );
          }
        });
      }, 1000);
    })
);

const OrigamiLazyFaqs = (props) => {
  const { faqHeading, faqSection, showMoreButtonText, showLessButtonText } = props;
  return (
    <Suspense
      fallback={
        <SkeletonContainer>
          <SkeletonFaqs />
        </SkeletonContainer>
      }
    >
      <OrigamiFaqsPromise
        faqHeading={faqHeading}
        faqSection={faqSection}
        showMoreButtonText={showMoreButtonText}
        showLessButtonText={showLessButtonText}
      />
    </Suspense>
  );
};

OrigamiLazyFaqs.propTypes = propTypes;

OrigamiLazyFaqs.defaultProps = defaultProps;

export default OrigamiLazyFaqs;
