import axios from 'axios';
import { getCookie } from '../lib/manageCookies';
import ActionTypes from '../constants/ActionTypes';
import CraftApiModel from '../models/CraftApiModel';
import { getApiPathWithLocale } from '../lib/urlUtils';
import Config from '../config/Config';
import { makeApiCall } from './application';
import { makeActionCreator } from '../utils/redux';
import {
  putPrintAnywhereMode,
} from '../services/printAnywhere';
import * as ERROR_TYPES from '../constants/errorTypes';
import { getContentStackData } from '../services/craft';
import { PRINT_ANYWHERE } from '../constants/contentStackResources';

export const printAnywhereModeChanged = makeActionCreator(ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_MODE, 'payload');

export const changePrintAnywhereMode = (printerId, newMode, callback) => makeApiCall({
  asyncFn: putPrintAnywhereMode,
  args: {
    printerId,
    newMode,
  },
  callback,
  successActionCreator: printAnywhereModeChanged,
});

const determinePrinterStatusIcon = ({ printerStatus, printerOnline, isPrinterRegistered }) => {
  if (printerOnline) {
    if (
      !isPrinterRegistered
      || !['reeady', 'online'].includes(printerStatus.toLowerCase())
    ) {
      return '/assets/img/common/icon_printer_problem.svg';
    }
    return '/assets/img/common/icon_printer_connected.svg';
  }
  return '/assets/img/common/icon_printer_disconnected.svg';
}

const processPrintAnywhereData = (csData, wpp) => ({
  csData,
  WPPData: {
    ...wpp,
    printerStatusIcon: determinePrinterStatusIcon(wpp),
  },
});

export function getMyPrintAnywherePrinter(id = '6ba9680b-4b5e-43ea-89ea-b0a041b1cad8', gen = '2', currentLocale = '') {
  const start = () => ({
    type: ActionTypes.START_PRINTANYWHERE_FETCH,
  });
  const complete = myPrinter => ({
    type: ActionTypes.COMPLETE_PRINTANYWHERE_FETCH,
    myPrinter,
  });
  const error = err => ({
    type: ActionTypes.COMPLETE_PRINTANYWHERE_WITH_ERROR,
    isError: true,
    errorMsg: err.message,
  });

  return dispatch => new Promise((resolve, reject) => {
    const config = new Config();
    dispatch(start());

    const axium = [];
    const csrfToken = getCookie('hpc3-csrf-token');

    if (!(typeof id === 'string' && id.length > 0)) {
      id = '';
    }


    const MyPrinterRequests = [
      {
        url: `${config.Get('HPC_GATEWAY_URL')}/print-anywhere/ownerships/${id}?generation=${gen}`,
        config: {
          withCredentials: true,
          headers: {
            'x-hpc3-csrf-token': csrfToken,
          },
        },
      },
    ];

    axium.push(getContentStackData(PRINT_ANYWHERE, currentLocale.replace('_', '-').toLowerCase()));

    MyPrinterRequests.forEach(request => {
      axium.push(axios.get(request.url, request.config));
    });
    axios.all(axium)
      .then(responses => {
        const processed = processPrintAnywhereData(
          responses[0]?.data?.entries.length > 0
            ? responses[0]?.data?.entries[0]
            : {},
          responses[1].data,
        );
        if (
          processed.WPPData.printerId === ''
          && processed.WPPData.printerModelNumber === ''
          && processed.WPPData.printerSerialNumber === ''
        ) {
          processed.WPPData.printerName = 'HP Printer';
        }
        dispatch(complete(processed));
        return resolve(complete(processed));
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }

        dispatch(error(err));
        reject(err);
      });
  });
}

export function updateMyPrintAnywherePrinterAddress(ownershipId, printerID, newAddress, printerGeneration, printerEmailDomain, callback) {
  const start = () => ({
    type: ActionTypes.START_PRINTANYWHERE_UPDATE_EMAIL,
  });
  const complete = data => ({
    type: ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_EMAIL,
    data,
    ownershipId,
  });
  const error = err => ({
    type: ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_EMAIL_WITH_ERROR,
    err,
  });
  return dispatch => {
    dispatch(start());
    return new Promise((resolve, reject) => {
      const config = new Config();
      const csrfToken = getCookie('hpc3-csrf-token');
      const postBody = {
        emailAddress: newAddress,
        generation: printerGeneration,
      };
      axios.put(
        `${config.Get('HPC_GATEWAY_URL')}/printers/${printerID}/email-address`,
        postBody,
        {
          withCredentials: true,
          headers: {
            'x-hpc3-csrf-token': csrfToken,
          },
        },
      )
        .then(result => {
          let printerEmailResponse = { error: 'not 200' };
          if (result.status === 200) {
            printerEmailResponse = { printerEmail: (`${newAddress}@${printerEmailDomain}`).toLowerCase() };
          }
          setTimeout(() => {
            callback();
            dispatch(complete(printerEmailResponse));
            return resolve(printerEmailResponse);
          }, 2000);
        })
        .catch(err => {
          if (err.response && err.response.status === 403) {
            dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
          }
          callback(err);
          dispatch(error(err));
          reject(error(err));
        });
    });
  };
}

export function changePrintAnywherePermissions(printerID, postBodyArg, callback) {
  const start = () => ({
    type: ActionTypes.START_PRINTANYWHERE_UPDATE_PERMISSIONS,
  });
  const complete = data => ({
    type: ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_PERMISSIONS,
    data,
  });
  const error = err => ({
    type: ActionTypes.COMPLETE_PRINTANYWHERE_UPDATE_PERMISSIONS_WITH_ERROR,
    err,
  });
  return dispatch => {
    dispatch(start());
    return new Promise((resolve, reject) => {
      const config = new Config();
      const csrfToken = getCookie('hpc3-csrf-token');
      const postBody = postBodyArg;
      axios.put(`${config.Get('HPC_GATEWAY_URL')}/print-anywhere/printers/${printerID}/permissions`, postBody, {
        withCredentials: true,
        headers: {
          'x-hpc3-csrf-token': csrfToken,
        },
      })
        .then(result => {
          let response = { error: 'not 200' };
          if (result.status === 200) {
            response = {
              userID: (postBody.resourceType === 'hpid') ? postBody.resource : null,
              userEmail: (postBody.resourceType === 'email') ? postBody.resource : null,
              roles: [postBody.role],
            };
          }

          setTimeout(() => {
            dispatch(complete(response));
            return resolve(response);
          }, 2000);
        })
        .catch(err => {
          if (err.response && err.response.status === 403) {
            dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
          }

          dispatch(error(err));
          reject(error(err));
        })
        .finally(() => { return callback && callback() });
    });
  };
}

export function addPrintAnywherePermissions(printerID = '', postBodyArg, callback) {
  const start = () => ({
    type: ActionTypes.START_PRINTANYWHERE_ADD_PERMISSIONS,
  });
  const complete = data => ({
    type: ActionTypes.COMPLETE_PRINTANYWHERE_ADD_PERMISSIONS,
    data,
  });
  const error = err => ({
    type: ActionTypes.COMPLETE_PRINTANYWHERE_ADD_PERMISSIONS_WITH_ERROR,
    err,
  });
  return (dispatch, getState) => {
    dispatch(start());
    return new Promise((resolve, reject) => {
      const config = new Config();
      const csrfToken = getCookie('hpc3-csrf-token');
      const postBody = postBodyArg;
      const accessListData = getState().printAnywhere.data.WPPData.accessList;
      let duplicateTest = accessListData.map((item) => {
        return item.userEmail.toLowerCase() === postBodyArg.resource.toLowerCase();
      });

      if (duplicateTest.includes(true)) {
        dispatch(error({}));
        callback(ERROR_TYPES.DUPLICATE);
        return;
      }
      axios.put(`${config.Get('HPC_GATEWAY_URL')}/print-anywhere/printers/${printerID}/permissions`, postBody, {
        withCredentials: true,
        headers: {
          'x-hpc3-csrf-token': csrfToken,
        },
      })
        .then(result => {
          let response = { error: 'not 200' };
          if (result.status === 200) {
            response = {
              userEmail: postBody.resource,
              roles: [postBody.role],
            };
          }
          callback();

          setTimeout(() => {
            dispatch(complete(response));
            return resolve(response);
          }, 2000);
        })
        .catch(err => {
          if (err.response && err.response.status === 403) {
            dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
          }

          dispatch(error(err));
          callback(ERROR_TYPES.ERROR);
          reject(error(err));
        });
    });
  };
}

export function addPrintAnywhereEmail(printerId, email = '', callback) {
  return addPrintAnywherePermissions(
    printerId,
    {
      resourceType: 'email',
      resource: email.toLowerCase(),
      action: 'update_permissions',
      role: 'eprintuser',
    },
    callback,
  );
}

export function removePrintAnywherePermissions(printerID, postBodyArg) {
  const start = () => ({
    type: ActionTypes.START_PRINTANYWHERE_REMOVE_PERMISSIONS,
  });
  const complete = data => ({
    type: ActionTypes.COMPLETE_PRINTANYWHERE_REMOVE_PERMISSIONS,
    data,
  });
  const error = err => ({
    type: ActionTypes.COMPLETE_PRINTANYWHERE_REMOVE_PERMISSIONS_WITH_ERROR,
    err,
  });
  return dispatch => {
    dispatch(start());
    return new Promise((resolve, reject) => {
      const config = new Config();
      const csrfToken = getCookie('hpc3-csrf-token');
      const postBody = postBodyArg;

      axios.put(`${config.Get('HPC_GATEWAY_URL')}/print-anywhere/printers/${printerID}/permissions`, postBody, {
        withCredentials: true,
        headers: {
          'x-hpc3-csrf-token': csrfToken,
        },
      })
        .then(result => {
          let response = { error: 'not 200' };
          if (result.status === 200) {
            response = {
              userEmail: postBody.resource,
              action: postBody.action,
            };
          }

          setTimeout(() => {
            dispatch(complete(response));
            return resolve(response);
          }, 2000);
        })
        .catch(err => {
          if (err.response && err.response.status === 403) {
            dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
          }

          dispatch(error(err));
          reject(error(err));
        });
    });
  };
}
