import React from 'react';
import PropTypes from 'prop-types';
import UsageImage from '../../../assets/img/document-copy.svg';
import i18n from '../../../utils/i18n';
import {
  UsageContainer,
  UsageDescription,
  UsageDetails,
  UsagePages,
} from './styles';

const UsageStatus = ({ pages }) => (
  <UsageContainer>
    <UsageDetails>
      <img src={UsageImage} alt={i18n.t('home.status.usage.pagesPrinted')} />
      { pages && pages > 0 ? (
        <>
          <UsagePages>{pages}</UsagePages>
          <UsageDescription>{i18n.t('home.status.usage.pagesPrinted')}</UsageDescription>
        </>
      ) : (
        <UsageDescription>{i18n.t('home.status.usage.noPrints')}</UsageDescription>
      )}
    </UsageDetails>
  </UsageContainer>
);

UsageStatus.propTypes = {
  pages: PropTypes.number.isRequired,
};

export default UsageStatus;
