import React from 'react'
import {
  IframeVideo
} from './styles/StyleOrigamiHowItWorksYoutubeVideo';

const OrigamiHowItWorksYoutubeVideo = ({width, height, youtubeVideoCode}) => {
  return (
    <IframeVideo width={width || '100%'} height={height || '720px'}
        src={`https://www.youtube.com/embed/${youtubeVideoCode}`}>
    </IframeVideo>
  );
}

export default OrigamiHowItWorksYoutubeVideo