import axios from 'axios';
import Config from '../config/Config';
import { getCookie } from './globals';
import { HOME, AU } from '../constants/common';

const config = new Config();
const instance = axios.create();

export async function api(requestObj) {
  const { url, method = 'GET', ...options } = requestObj;

  try {
    const response = await instance.request({ url, method, ...options });
    return response;
  } catch (error) {
    return false;
  }
}

const ucdeBase = config.Get('UCDE_GATEWAY_URL');

export async function ucde(requestObj) {
  const { url, method = 'GET', ...options } = requestObj;
  const response = await instance.request({
    url: `${ucdeBase}${url}`,
    method,
    ...options
  });
  return response;
}

const base = config.Get('HPC_GATEWAY_URL');

export async function gateway(requestObj) {
  const { url, method = 'GET', ...options } = requestObj;

  const response = await instance.request({
    url: `${base}${url}`,
    method,
    withCredentials: true,
    headers: {
      'x-hpc3-csrf-token': getCookie('hpc3-csrf-token')
    },
    xsrfCookieName: 'hpc3-csrf-token',
    xsrfHeaderName: 'x-hpc3-csrf-token',
    ...options
  });
  return response;
}

const sessionBase = config.Get('SESSION_SERVICE_BASE_URL');

export async function session(requestObj) {
  const { url, method = 'GET', ...options } = requestObj;

  const response = await instance.request({
    url: `${sessionBase}${url}`,
    method,
    withCredentials: true,
    ...options
  });
  return response;
}

// hacky work around until AppConfig can be refined
const consentBase = base.replace('1', '2');

export async function consents(requestObj) {
  const { url, method = 'GET', ...options } = requestObj;

  const fullUrl = `${consentBase}/consents${url}`;
  try {
    const response = await instance.request({
      url: fullUrl,
      method,
      withCredentials: true,
      ...options
    });

    return response;
  } catch (e) {
    e.method = method;
    e.url = fullUrl;
    throw e;
  }
}

export async function contentStack(resourceName, contentStackLocaleCode, uuid) {
  const stack = config.Get('CONTENT_STACK_ENV');
  const apiKey = config.Get('CONTENT_STACK_API_KEY');
  const accessToken = config.Get('CONTENT_STACK_ACCESS_TOKEN');

  if(resourceName === HOME) {
    const [ , country, language] = window.location.pathname.split('/');
    if(country === AU) contentStackLocaleCode = `${language}-${country}`;
  }

  const fullUrl = uuid
    ? `/content/v3/content_types/${resourceName}/entries/${uuid}?locale=${contentStackLocaleCode}&environment=${stack}`
    : `/content/v3/content_types/${resourceName}/entries?locale=${contentStackLocaleCode}&environment=${stack}`;

  try {
    const response = await instance.request({
      url: fullUrl,
      method: 'GET',
      withCredentials: true,
      headers: {
        api_key: apiKey,
        access_token: accessToken
      }
    });
    return response;
  } catch (e) {
    return e;
  }
}

export async function util(requestObj) {
  const { url, method = 'GET', ...options } = requestObj;

  const response = await instance.request({
    url: `${consentBase}${url}`,
    method,
    withCredentials: true,
    ...options
  });
  return response;
}
