import styled from 'styled-components';
import { breakpoints } from '../../../utils/styleHelpers';

export const AdditionalFeaturesSection = styled.section`
  width: 100%;
  height: ${(props) => (props.isPaperOptimizedSection ? '428px' : '444px')};
  display: flex;
  background-color: ${(props) => (props.isPaperOptimizedSection ? '#F8F8F8' : '#F2EBDC')};
  flex-direction: ${(props) => (props.isPaperOptimizedSection ? 'row-reverse' : 'row')};
  margin-top: 64px;

  @media (max-width: ${breakpoints.wide}) {
    height: ${(props) => (props.isPaperOptimizedSection ? '380px' : '408px')};
  }

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    height: fit-content;
    margin-top: 32px;
  }
`;

export const ImageContainer = styled.div`
  width: 50%;
  height: 100%;
  padding: ${(props) => (props.isPaperOptimizedSection ? '64px 182px 64px 20px' : '0')};

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: ${(props) => (props.isPaperOptimizedSection ? '0 -80px' : 'inherit')};
  }

  @media (min-width: ${breakpoints.immense}) {
    > img {
      object-position: ${(props) => (props.isPaperOptimizedSection ? 'bottom' : 'inherit')};
    }
  }

  @media (max-width: ${breakpoints.large}) {
    padding: ${(props) => (props.isPaperOptimizedSection ? '64px 32px 64px 20px' : '0')};
  }

  @media (max-width: ${breakpoints.wide}) {
    > img {
      object-position: ${(props) => (props.isPaperOptimizedSection ? 'center' : 'inherit')};
    }
  }

  @media (max-width: ${breakpoints.medium}) {
    width: 100%;
    padding: ${(props) => (props.isPaperOptimizedSection ? '32px 40px 0px 40px' : '0')};

    > img {
      height: 226px;
      object-position: ${(props) => (props.isPaperOptimizedSection ? 'bottom' : 'inherit')};
    }
  }

  @media (max-width: ${breakpoints.smalldesktop}) {
    padding: ${(props) => (props.isPaperOptimizedSection ? '32px 24px 0px 24px' : '0')};

    > img {
      height: 200px;
      object-position: inherit;
    }
  }
`;

export const AdditionalFeaturesContentBox = styled.div`
  width: 50%;
  height: 100%;
  align-content: center;
  padding: ${(props) => (props.isPaperOptimizedSection ? '0 20px 0 0' : '0 0 0 40px')};
  max-width: 630px;

  > img {
    margin-bottom: 20px;
  }

  @media (max-width: ${breakpoints.large}) {
    padding: ${(props) => (props.isPaperOptimizedSection ? '0 20px 0 32px' : '0 32px 0 40px')};
  }

  @media (max-width: ${breakpoints.medium}) {
    width: 100%;
    max-width: initial;
    padding: 32px 40px;
  }

  @media (max-width: ${breakpoints.smalldesktop}) {
    padding: 32px 24px;
  }
`;
