/* eslint-disable camelcase */
import en_US from './en_US.json';
import en_GB from './en_GB.json';
import en_AU from './en_AU.json';
import en_NZ from './en_NZ.json';
import de_DE from './de_DE.json';
import bg_BG from './bg_BG.json';
import cs_CZ from './cs_CZ.json';
import el_GR from './el_GR.json';
import et_EE from './et_EE.json';
import hr_HR from './hr_HR.json';
import hu_HU from './hu_HU.json';
import lt_LT from './lt_LT.json';
import lv_LV from './lv_LV.json';
import pl_PL from './pl_PL.json';
import ro_RO from './ro_RO.json';
import sk_SK from './sk_SK.json';

export default {
  en_US,
  en_GB,
  en_AU,
  en_NZ,
  de_DE,
  bg_BG,
  cs_CZ,
  el_GR,
  et_EE,
  hr_HR,
  hu_HU,
  lt_LT,
  lv_LV,
  pl_PL,
  ro_RO,
  sk_SK,
};
