import styled, { css } from 'styled-components';
import { Button } from '@veneer/core';
import { breakpoints, theme } from '../../../../utils/styleHelpers';

export const ContentContainer = styled.div`
  font-family: 'Forma DJR', sans-serif;
  ${(props) => props.flex && css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 400px;
    padding-left: 200px;

    @media (max-width: 1440px) {
      padding-left: 100px;
      padding-right: 40px;
    }

    @media (max-width: 1366px) {
      padding-right: 130px;
    }

    @media (max-width: 1280px) {
      padding-right: 70px;
    }

    @media (max-width: 768px) {
      padding-left: 50px;
      padding-right: 10px;
    }

    @media (max-width: 390px) {
      padding-left: 10px;
      flex-direction: column;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;
    }

    > * {
      margin-right: 10px;
    }

    > *:last-child {
      margin-right: 0;
    }
  `}
`;

export const AddPaperButton = styled(Button)`
  && {
    font-family: 'Forma DJR', sans-serif;
    min-width: auto;
    @media (max-width: ${breakpoints.bigmobile}) {
      border-radius: 8px;
      width: auto;
    }
    @media (max-width: 390px) {
      margin-bottom: 30px;
      width: 100%;
    }
    @media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      min-width: 9rem;
    }
  }
`;

export const EnrollContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EnrollLinkText = styled.div`
  font-family: 'Forma DJR', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #027aae;
  margin: 20px 0;
  cursor: pointer;
  p {
    margin: 0;
  }
`;

export const EnrollLinkImage = styled.div`
  display: flex;
  align-items: center;
`;

export const ChangeEnrollText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #16141d;
  margin: 20px 0;
`;
