import React from 'react';
import BlueCheckmark from '../../shared-components/atoms/BlueCheckmark';

const SubscriptionSection = props => {
  const {
    sectionHeader,
    showCheckmark,
    sectionSubTitle,
    sectionDescription,
    sectionActionId,
    onActionClicked,
    sectionActionText,
  } = props;
  return (
    <div className="printerCell--promotion">
      <div className="promoSection--title">
        <h4>
          {sectionHeader}
        </h4>
        {
          showCheckmark
            && (
              <span className="promoSection--animatedIcon">
                <BlueCheckmark />
              </span>
            )
        }
      </div>
      {
        sectionSubTitle
          && (
            <div className="promoSection--subTitle">
              {sectionSubTitle}
            </div>
          )
      }
      <div className="promoSection--contents promoSection--ink">
        <div
          className="promoSection--details"
          dangerouslySetInnerHTML={{ __html: sectionDescription }}
        />
        <button
          onClick={onActionClicked}
          type="button"
          id={sectionActionId}
          className="button hollow accordionContentInk--button promoSection--button"
        >
          {sectionActionText}
        </button>
      </div>
    </div>
  );
}

export default SubscriptionSection;
