import React, { Component } from 'react';
import styled from 'styled-components';
import {
  prop,
} from 'ramda';
import {
  TweenLite, Power2, Power4
} from 'gsap/all';
import bind from '../bind';
import { refreshToTop } from '../../lib/refreshToTop';
import { deBounce } from '../../lib/deBounce';
import { stylesIf } from '../../utils/styleHelpers';
import {
  MOBILE_FAX_RESOURCE_NAME,
} from '../../constants/contentStackResources';

class LandingInPageMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navIsVisible: false,
    };
    this.elementData = [];
    this.currentSection = 'none';
    this.currentDeepLinkSection = '';
    this.sectionList = [];
    this.deeplinkList = [];
    this.handleScroll = this.handleScroll.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.updateScroll = this.updateScroll.bind(this);
    this.checkChangeSelection = this.checkChangeSelection.bind(this);
    this.updateAddressBar = this.updateAddressBar.bind(this);
    this.deeplinkURLSegment = Number(this.props.deeplinkURLSegment);
    this.deeplink_destination = '';
    this.deeplinkingInProgress = false;
  }

  componentDidMount() {
    refreshToTop();
    const window_location_pathname = window.location.pathname.split('/');
    const deeplinkCondition = window_location_pathname.length === this.deeplinkURLSegment + 1 && window_location_pathname[this.deeplinkURLSegment] !== '';
    window.addEventListener('scroll', this.handleScroll);
    if (deeplinkCondition) {
      this.deeplink_destination = window_location_pathname[this.deeplinkURLSegment];
      setTimeout(this.deepLink, 540);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  checkChangeSelection() {
    this.sectionList = this.props.sectionList;
    if (this.sectionList.length > 0) {
      let newCurentSection = this.currentSection;
      let newIndex = null;
      const buffer = Math.round(window.innerHeight * 0.17);
      this.sectionList.map((section, i) => {
        const sectionElem = document.getElementById(section.sectionID);
        if (
          i === 0
          && !!sectionElem
          && (sectionElem.offsetTop > (this.scroll + buffer))
          && this.currentSection != 'none'
        ) {
          newCurentSection = 'none';
          this.setState({ navIsVisible: false });
        } else if (
          !!sectionElem
          && (sectionElem.offsetTop <= (this.scroll + buffer))
          && (sectionElem.offsetTop + sectionElem.offsetHeight) > (this.scroll + buffer)
          && (this.currentSection != section.sectionID)
        ) {
          newIndex = i;
          this.setState({ navIsVisible: true });
          newCurentSection = section.sectionID;
        } else {
        }
      });
      if (this.currentSection !== newCurentSection) {
        this.currentSection = newCurentSection;
      }
    }
  }

  updateAddressBar() {
    deBounce(this.performUpdateAddressBar, 411)();
  }

  performUpdateAddressBar = () => {
    this.deeplinkList = this.props.deeplinkList;
    if (this.deeplinkList.length > 0) {
      let newCurentSection = this.currentDeepLinkSection;
      const buffer = Math.round(window.innerHeight * 0.17);
      this.deeplinkList.map((section, i) => {
        const sectionElem = document.getElementById(section.sectionID);
        if (
          i === 0
          && !!sectionElem
          && (sectionElem.offsetTop > this.scroll + buffer)
          && this.currentDeepLinkSection != ''
        ) {
          newCurentSection = '';
          this.setState({ navIsVisible: false });
        } else if (
          !!sectionElem
          && (sectionElem.offsetTop <= this.scroll + buffer)
          && (sectionElem.offsetTop + sectionElem.offsetHeight) > (this.scroll + buffer)
          && this.currentDeepLinkSection != section.sectionID
        ) {
          newCurentSection = section.sectionID;
          this.setState({ navIsVisible: true });
        }
      });
      if (this.currentDeepLinkSection !== newCurentSection) {
        this.currentDeepLinkSection = newCurentSection;
        const search = window.location.search ? window.location.search : '';
        const window_location_array = window.location.href.split('?')[0].toLowerCase().split('/');
        window_location_array.length = this.deeplinkURLSegment + 2;
        window_location_array.push(this.currentDeepLinkSection);
        const strNewPath = window_location_array.join('/') + search;
        if (window.history.pushState) {
          window.history.replaceState({ foo: 'bar' }, 'page two', strNewPath);
        }
      }
    }
  }

  deepLink = () => {
    const anchor = this.deeplink_destination;
    if (document.getElementById(anchor)) {
      this.deeplinkingInProgress = true;
      const completeFn = () => {
        this.deeplinkingInProgress = false;
        this.checkChangeSelection();
        this.updateAddressBar();
      };
      const finalizeFn = () => {
        TweenLite.to(window, 1.8169275, {
          scrollTo: `#${anchor}`,
          ease: Power4.easeOut,
          autoKill: false,
          onComplete: completeFn,
        });
      };
      TweenLite.to(window, 2.1, {
        scrollTo: `#${anchor}`,
        ease: Power2.easeInOut,
        autoKill: false,
        onComplete: finalizeFn,
      });
    }
  };

  handleScroll = () => {
    this.scroll = window.pageYOffset;
    deBounce(this.updateScroll, 11)();
  };

  updateScroll() {
    this.checkChangeSelection();
    if (!this.deeplinkingInProgress) {
      this.updateAddressBar();
    }
  }

  // ///////////////////////////////////////////////////////////// TRACKING
  tracking = ID => {
    ID = ID.indexOf('#') === 0 ? ID.substr(1) : ID;
    document.getElementById(`mobilefax_tracking_inPageMenu_${ID}`).click();
  }
  // ///////////////////////////////////////////////////////////// TRACKING

  handleClick(ID) {
    this.tracking(ID);
    this.deeplinkingInProgress = true;
    const completeFn = () => {
      this.deeplinkingInProgress = false;
      this.checkChangeSelection();
      this.updateAddressBar();
    };
    TweenLite.to(window, 1.25, {
      scrollTo: ID,
      autoKill: false,
      ease: Power2.easeOut,
      onComplete: completeFn,
    });
  }

  clickHandler = (link, e) => {
    this.tracking(e.target.id);
    window.location.href = link;
  };

  render() {
    const {
      isInApp,
      contentStack,
      backLink,
      isQuerystringComplete,
      forwardLink,
      isMobile,
      mobileFaxDataSource,
      sectionList,
    } = this.props;
    const mobileFax = prop(MOBILE_FAX_RESOURCE_NAME, contentStack);
    const {
      navIsVisible,
    } = this.state;

    if (!mobileFax) {
      return null;
    }

    return (
      <MenuBackground navIsVisible={navIsVisible} isInApp={false}>
        <StyledMenu
          id="inPageNav"
          isInApp={false}
          closeLinkExists={backLink != null}
        >
          {
              isMobile
                ? (
                  <React.Fragment>
                    <MainButton
                      id="forwardButton"
                      closeLinkExists={backLink != null}
                      onClick={e => (
                        isQuerystringComplete && isInApp
                          ? this.clickHandler(forwardLink, e)
                          : this.handleClick(forwardLink)
                      )}
                      tabIndex="0"
                    >
                      {mobileFaxDataSource.forward_button_text}
                    </MainButton>
                    {(backLink != null) && (
                      <Textlink
                        id="backLink"
                        // href={backLink}
                        onClick={e => (
                          isQuerystringComplete
                            ? this.clickHandler(backLink, e)
                            : this.handleClick(backLink)
                        )}
                        tabIndex="0"
                      >
                        {mobileFaxDataSource.back_link_text}
                      </Textlink>
                    )}
                  </React.Fragment>
                )
                : (
                  <React.Fragment>
                    <InPageLinks>
                      {sectionList.map((section, i) => (
                        <li key={i}>
                          <a
                            id={`inPageMenu_${section.sectionID}`}
                            data-link-id={section.sectionID}
                            onClick={() => this.handleClick(`#${section.sectionID}`)}
                            ref={`#${section.sectionID}`}
                            tabIndex="0"
                          >
                            {section.sectionTitle}
                          </a>
                        </li>
                      ))}
                    </InPageLinks>
                    {(backLink != null) && (
                      <Textlink
                        id="backLink"
                        onClick={e => (
                          isQuerystringComplete
                            ? this.clickHandler(backLink, e)
                            : this.handleClick(backLink)
                        )}
                        tabIndex="0"
                      >
                        {mobileFaxDataSource.back_link_text}
                      </Textlink>
                    )}
                    <MainButton
                      id="forwardButton"
                      closeLinkExists={backLink != null}
                      onClick={e => (
                        (isQuerystringComplete && isInApp)
                          ? this.clickHandler(forwardLink, e)
                          : this.handleClick(forwardLink)
                      )}
                      tabIndex="0"
                    >
                      {mobileFaxDataSource.forward_button_text}
                    </MainButton>
                  </React.Fragment>
                )
              }
        </StyledMenu>

      </MenuBackground>
    );
  }
}

export default bind(LandingInPageMenu);


// let _GLOBAL_TIMEOUT = 9; // necessary for debounce

// navIsVisible
// position: fixed;
// top: 0;
export const MenuBackground = styled.div`
  position: ${({ navIsVisible, isInApp }) => ((navIsVisible || isInApp) ? 'fixed' : 'absolute')};
  top: ${({ navIsVisible, isInApp }) => ((navIsVisible || isInApp) ? '0' : '-271px')};
  background-color: ${({ isInApp, theme }) => (isInApp ? 'white' : theme.hpBlue)};
  z-index: 3;
  width: 100%;
  ${stylesIf('isInApp', 'border-bottom: 1px solid black;')}
`;
const StyledMenu = styled.menu`
  max-width: 100rem;
  height: ${props => (props.closeLinkExists ? '2.875rem' : '2.4375rem')};
  @media screen and (min-width:${props => props.theme.small}) {
    height: 3.4375rem;
  }
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.closeLinkExists ? 'space-between' : 'center')};
  @media screen and (min-width:${props => props.theme.small}) {
    justify-content: space-between;
  }
`;
const MainButton = styled.a`
  background-color: white;
  color: #0171AD;
  margin: 0 1rem;
  ${props => props.theme.boldFontFamilyAndWeight}
  border-radius: 0.1875rem;
  padding: ${props => (props.closeLinkExists ? '.1875rem' : '.125rem')} 2.25rem;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.hpBlue};
  }
  @media screen and (min-width:${props => props.theme.small}) {
    padding: 0.375rem 2rem;
  }
  @media screen and (min-width:${props => props.theme.medium}) {
    font-size: 1.125rem;
    padding: 0.375rem 4rem;
  }
`;
const Textlink = styled.a`
  ${props => props.theme.boldFontFamilyAndWeight} 
  color: white;
  margin: 0 1rem;
  text-decoration: none;
  @media screen and (min-width:${props => props.theme.small}) {
    margin-left: auto;
    padding-right: 1rem;
    margin-right: 1.25rem;
  }
  @media screen and (min-width:${props => props.theme.medium}) {
    margin-right: 1.5rem;
    font-size: 1.125rem;
  }
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;
const InPageLinks = styled.ul`
  display: flex;
  list-style-type: none;
  color: white;
  padding-left: 0;
  margin: 0;
  a {
    ${props => props.theme.boldFontFamilyAndWeight}
    color: white;
    padding: .1875rem 1.25rem;
    text-decoration: none;
    @media screen and (min-width:${props => props.theme.medium}) {
      font-size: 1.125rem;
      padding: .1875rem 1.675rem;
    }
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
`;
