import React from 'react';

const AccordionSubscriptionContent = props => {
  const {
    description,
    subscriptionLinkUrl,
    subscriptionLinkText,
    onActionClicked,
    actionText,
    ID,
    subscriptionLinkClassName,
  } = props;
  return (
    <div className="accordionContentInk">
      {
        description
          && (
            <p>
              {description}
            </p>
          )
      }
      {
        subscriptionLinkUrl !== ''
          && (
            <p className={subscriptionLinkClassName}>
              <a
                id="auto-replenishment-tou-link"
                href={subscriptionLinkUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                {subscriptionLinkText}
              </a>
            </p>
          )
      }
      <button
        type="button"
        id={ID}
        className="button hollow accordionContentInk--button"
        onClick={onActionClicked}
      >
        {actionText}
      </button>
    </div>
  );
};

export default AccordionSubscriptionContent;
