import styled from 'styled-components';
import Button from '@veneer/core/dist/scripts/button';
import IconChevronLeft from '@veneer/core/dist/scripts/icons/icon_chevron_left';
import IconChevronRight from '@veneer/core/dist/scripts/icons/icon_chevron_right';
import { breakpoints } from '../../../utils/styleHelpers';
import { RTL } from '../../../constants/i18n';
import { RIGHT, LEFT } from '../../../constants/common';

export const Container = styled.div`
  display: flex;
  padding: 2.125rem 3.5rem;
  width: 100%;
  @media only screen and (max-width: ${breakpoints.smalldesktop}) {
    padding: 1.25rem 2.25rem;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    overflow: hidden;
  }
  @media only screen and (max-width: ${breakpoints.wide}) {
    overflow: hidden;
  }
`;

export const StepperContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 1rem;
`;

export const Header = styled.div``;

export const Graphic = styled.img`
  width: auto;
  padding: 1rem 0;

  @media only screen and (min-width: 40.063rem) and (max-width: 50rem) {
    ${ props => props.direction === RTL ? 'margin-left: 2rem' : 'margin-right: 2rem' };
    width: 35%;
  }

  @media only screen and (min-width: 58rem) {
    ${ props => props.direction === RTL ? 'margin-left: 5rem' : 'margin-right: 5rem' };
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    max-width: 80%;
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: auto;
  }
`;

export const DropDownParent = styled.div`
  width:95%;
`;

export const Title = styled.h3`
  font-family: HPSimplified;
  font-size: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 0.5rem;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #4e4e4e;

  @media screen and (max-width: ${breakpoints.smalldesktop}) {
    font-size: 1.25rem;
    line-height: 1.2;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    text-align: ${ props => props.direction === RTL ? RIGHT : LEFT };
  }
`;

export const SubTitle = styled.p`
  font-family: HPSimplified;  
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;  
  letter-spacing: normal;
  text-align: ${ props => props.direction === RTL ? RIGHT : LEFT };
  color: #4e4e4e
  margin-bottom: 2rem;
  font-size: 1.125rem;
  line-height: 1.33;
  img {
    margin: 0 0.375rem;
  }
`;

export const IconChevronLeftContainer = styled(IconChevronLeft)`
  cursor: pointer;
`;

export const IconChevronRightContainer = styled(IconChevronRight)`
  cursor: pointer;
`;

export const FooterButton = styled(Button)``;

export const FooterButtonText = styled.span``;

export const Content = styled.div`
  flex-shrink: 2;
  text-align: center;
  padding: 1rem 0;
  height: 8rem;
  margin-bottom: 1.5rem;

  @media only screen and (min-width: 40.063rem) and (max-width: ${breakpoints.bigtablet}) {
    width: 14rem;
    ${ props => props.direction === RTL ? 'margin-right: 2%' : 'margin-left: 2%' };
    font-size: 0.9rem;
  }

  @media only screen and (min-width: ${breakpoints.bigtablet}) and (max-width: 50rem) {
    width: 16rem;
    ${ props => props.direction === RTL ? 'margin-right: 4%' : 'margin-left: 4%' };
  }

  @media only screen and (min-width: 50.063rem) {
    width: 22rem;
    ${ props => props.direction === RTL ? 'margin-right: 8%' : 'margin-left: 8%' };
  }

  @media only screen and (min-width: 58rem) {
    width: 30rem;
    ${ props => props.direction === RTL ? 'margin-right: 10%' : 'margin-left: 10%' };
  }

  @media only screen and (min-width: 40.063rem) {
    text-align: justify;
    height: auto;
    ${ props => props.direction === RTL ? 'margin-left: auto' : 'margin-right: auto' };
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: auto;
    height: 100%;
    overflow: auto;
  }

  > p {
    margin-bottom: 0;
  }
`;

export const Footer = styled.div`
  height: 6.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.5rem 1rem;
  margin-top: 6rem;
  & > div {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: ${breakpoints.tablet}) {
      justify-content: space-between;
      flex-direction: row;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      text-align: center;
      flex-direction: column;
      align-items: center;
      padding-left: 0;
    }

    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
      text-align: center;
      flex-direction: column;
      align-items: center;
      padding-left: 0;
    }

    & > button :first-child {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      @media only screen and (min-width: ${breakpoints.tablet}) {
        margin-bottom: 0;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
        margin-bottom: 0.5rem;
      }

      @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
        margin-bottom: 0.5rem;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.smalldesktop}) {
    height: 3.5rem;
  }

  @media screen and (max-height: 45rem) {
    margin-top: 2rem;
  }
`;

export const CarouselContainer = styled.div`
  height: 230px;
  width: 100%;

  @media only screen and (max-width: ${breakpoints.tablet}) {
    height 306px;
  }
  
  & .slider-control-bottomcenter {
    display: none;
  }
`;

export const CarouselItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 0px 45px 0px 45px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;

  @media only screen and (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`;
