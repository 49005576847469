import styled from 'styled-components';
import { UCDEMainTheme } from '../../../../../pages/Dashboard/styles';

// common constants
const normal = {
  barWidth: 10,
  barHeight: 80,
  borderOffset: 1,
  borderRadius: 6,
  gradHeight: 5,
  borderWidth: 1,
  marginTriple: 5,
  marginSingle: 3,
  tripleSpacing: 2,
};
const small = {
  barWidth: 7,
  barHeight: 40,
  borderOffset: 1,
  borderRadius: 6,
  gradHeight: 3,
  borderWidth: 1,
  marginTriple: 1,
  marginSingle: 1,
  tripleSpacing: 1,
};
// pre calculated constants
const getCartridgeHeight = source => source.barHeight + source.borderWidth * 2;
const getCartridgeSingleWidth = source => source.barWidth + source.borderWidth * 2;
const getCartridgeTripleWidth = source => source.barWidth * 3 + source.borderWidth * 2 + source.tripleSpacing * 2;
const getCartridgeTripleMagentaOffset = source => source.barWidth + source.tripleSpacing;
const getCartridgeTripleYellowOffset = source => source.barWidth * 2 + source.tripleSpacing * 2;
const getMinCartridgeHeight = source => source.gradHeight * 3;
const getMaxCartridgeHeight = source => source.barHeight + source.gradHeight;
// helper functions
const levelToPx = (source, level) => (getMaxCartridgeHeight(source) * level) / 100;
const getCartridgeLevelHeight = (source, level) => {
  return Math.min(getMaxCartridgeHeight(source), Math.max(getMinCartridgeHeight(source), levelToPx(source, level)));
};
const isBorderVisible = state => state && state !== 'OK';
const getBorderVisibleColor = state => (state === 'LOW' ? 'borderLow' : 'borderVeryLow');
const getBorderColor = state => {
  const color = (isBorderVisible(state) ? getBorderVisibleColor(state) : 'borderNone');
  return UCDEMainTheme.main.cartridge[color];
};
const getBorderWidth = (source, state) => {
  return (isBorderVisible(state) ? source.borderWidth + source.borderOffset : source.borderWidth);
};
const getCartridgeMargin = (source, tricolor) => (tricolor ? source.marginTriple : source.marginSingle);
const getCartridgeWidth = (source, tricolor) => {
  return (tricolor ? getCartridgeTripleWidth(source) : getCartridgeSingleWidth(source));
};
const getBarOffset = (source, offset, state) => (isBorderVisible(state) ? offset - source.borderOffset : offset);

export const CartridgeLevelGrad = styled.div`
  height: ${small.gradHeight}px;
  width: ${small.barWidth}px;
  opacity: ${({ grad }) => 1 - grad};
  background-color: #ffffff;

  @media (min-width: ${UCDEMainTheme.breakPoints.md}) {
    height: ${normal.gradHeight}px;
    width: ${normal.barWidth}px;
  }
`;

export const CartridgeLevel = styled.div`
  width: ${small.barWidth}px;
  position: absolute;
  bottom: 0;

  @media (min-width: ${UCDEMainTheme.breakPoints.md}) {
    width: ${normal.barWidth}px;
  }
`;

export const CartridgeBar = styled.div`
  display: block;
  position: absolute;
  background-color: #f3f3f3;
  width: ${small.barWidth}px;
  height: ${small.barHeight}px;

  @media (min-width: ${UCDEMainTheme.breakPoints.md}) {
    width: ${normal.barWidth}px;
    height: ${normal.barHeight}px;
  }

  ${CartridgeLevel} {
    background-color: ${({ type = 'black' }) => UCDEMainTheme.main.cartridge[type]};
  }
`;

export const CartridgeContainer = styled.div`
  display: inline-block;
  max-height: ${getCartridgeHeight(small)}px;

  @media (min-width: ${UCDEMainTheme.breakPoints.md}) {
    max-height: ${getCartridgeHeight(normal)}px;
  }
`;

export const CartridgeExtra = styled.div`
  display: inline-block;
  line-height: ${getCartridgeHeight(small)}px;
  font-size: 15px;
  font-weight: bold;
  color: #404040;
  position: absolute;
  top: 0;

  @media (min-width: ${UCDEMainTheme.breakPoints.md}) {
    line-height: ${getCartridgeHeight(normal)}px;
  }
`;

export const Cartridge = styled.div`
  display: inline-block;
  position: relative;
  overflow: hidden;

  margin-left: ${({ tricolor }) => getCartridgeMargin(small, tricolor)}px;
  margin-right: ${({ tricolor }) => getCartridgeMargin(small, tricolor)}px;
  height: ${getCartridgeHeight(small)}px;
  width: ${({ tricolor }) => getCartridgeWidth(small, tricolor)}px;
  border-radius: ${small.borderRadius}px;
  border: ${({ state }) => getBorderWidth(small, state)}px solid ${({ state }) => getBorderColor(state)};

  ${CartridgeBar} {
    top: ${({ state }) => getBarOffset(small, 0, state)}px;
    left: ${({ state }) => getBarOffset(small, 0, state)}px;
  }

  ${CartridgeBar}[type=magenta] {
    left: ${({ state, tricolor }) => getBarOffset(small, tricolor ? getCartridgeTripleMagentaOffset(small) : 0, state)}px;
  }

  ${CartridgeBar}[type=yellow] {
    left: ${({ state, tricolor }) => getBarOffset(small, tricolor ? getCartridgeTripleYellowOffset(small) : 0, state)}px;
  }

  ${CartridgeLevel} {
    height: ${({ level }) => getCartridgeLevelHeight(small, level)}px;
  }

  @media (min-width: ${UCDEMainTheme.breakPoints.md}) {
    margin-left: ${({ tricolor }) => getCartridgeMargin(normal, tricolor)}px;
    margin-right: ${({ tricolor }) => getCartridgeMargin(normal, tricolor)}px;
    height: ${getCartridgeHeight(normal)}px;
    width: ${({ tricolor }) => getCartridgeWidth(normal, tricolor)}px;
    border-radius: ${normal.borderRadius}px;
    border: ${({ state }) => getBorderWidth(normal, state)}px solid ${({ state }) => getBorderColor(state)};

    ${CartridgeBar} {
      top: ${({ state }) => getBarOffset(normal, 0, state)}px;
      left: ${({ state }) => getBarOffset(normal, 0, state)}px;
    }

    ${CartridgeBar}[type=magenta] {
      left: ${({ state, tricolor }) => getBarOffset(normal, tricolor ? getCartridgeTripleMagentaOffset(normal) : 0, state)}px;
    }

    ${CartridgeBar}[type=yellow] {
      left: ${({ state, tricolor }) => getBarOffset(normal, tricolor ? getCartridgeTripleYellowOffset(normal) : 0, state)}px;
    }

    ${CartridgeLevel} {
      height: ${({ level }) => getCartridgeLevelHeight(normal, level)}px;
    }
  }
`;
