import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '@veneer/core';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { getLocaleFromPath } from '../../../utils/routing';
import { localizeURL } from '../../../selectors/global';
import {
  paths,
  fontPacks,
} from '../../../utils/styleHelpers';

const Triangle = styled.div`
 border-style: solid;
 border-width: 0 20px 20px 20px;
 border-color: transparent transparent ${paths.colorsLightBackgroundGrey} transparent;
 position: absolute;
 flex: 1 1 auto;
 z-index: 1;
 height: 0;
 width: 0;
 transform: translate(-50%,-90%);
 top: 100%;
 left: 50%;
 @media screen and (min-width: 960px) {
  border-width: 20px 20px 20px 0;
  border-color: transparent ${paths.colorsLightBackgroundGrey} transparent transparent;
  transform: translateX(-90%);
  top: 50%;
  left: 100%;
 }
`;

const Title = styled.h3`
 width: 100%;
 height: 100%;
 color: ${paths.colorsNearDark};
 -ms-line-break: normal;
 margin-top: 16px;
 margin-bottom: 16px;
 width:100%;
 height:100%;
 ${fontPacks.titleRegularLight}
 @media (min-width: ${paths.breakImmense}) {
  margin-top: 0;
 }
`;
const Body = styled.span`
  width:100%;
  height:100%;
 *{
   max-width: 100%;
   -ms-line-break: normal;
   ${fontPacks.bodyLarge}
 }
`;
const Footer = styled.span`
 width: 100%;
 height: 100%;

 *{
  max-width: 100%;
  width:100%;
  height:100%;
  -ms-line-break: normal;
  ${fontPacks.bodyLarge}
 }
`;

const ContentContainer = styled.div`
 width:100%;
 height:100%;
 min-height: 360px;

 display: flex;
 display: -ms-flexbox;
 flex:1 1 auto;
 -ms-flex:1 1 auto;
 flex-direction: column;
 justify-content: center;
 -ms-flex-direction: column;
 -ms-flex-pack: center;
 -ms-flex-align: center;
 -ms-flex-item-align: center;
 align-items: center;
 align-self: center;
 justify-self:center;
 padding: 24px;
 position:relative;
 > * {
   flex: 1 1 auto;
 }
 @media screen and (min-width: 960px) {
  padding: 1rem 4rem;
  width:50%;
 }
`;
const ImageContainer = styled.div`
 width:100%;
 height:50%;
 display: flex;
 flex:1 1 auto;
 display: -ms-flexbox;
 -ms-flex:1 1 auto;
 position: relative;
 > img {
  width: 100%;
  height: 100%;
  object-fit:cover;
  -o-object-fit:cover;
 }
 @media screen and (min-width: 960px) {
  width:50%;
  > img {
    min-height:400px;
  }
 }
`;

const Container = styled.div`
 display:flex;
 display:-ms-flexbox;
 flex-direction: column;
 -ms-flex-direction: column;
 position: relative;
 width:100%;
 height:100%;
 > * {
  justify-self: center;
  -ms-grid-column-align: center;
  align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
 }

 @media screen and (min-width: 960px) {
  flex-direction: row;
  -ms-flex-direction: row;
  > * {
    width:50%;
  }

  &:nth-child(even){
    flex-direction: row-reverse;
    > ${ImageContainer} {
      >${Triangle}{
       border-width: 20px 0 20px 20px;
       border-color: transparent transparent transparent ${paths.colorsLightBackgroundGrey};
       left:0;
       transform: translateX(-10%);
      }
    }
  }
 }
`;
const StyledButton = styled(Button)`
&& {
  margin: 16px 0;
}
`;

const ServiceItem = ({
  body,
  buttonDestination,
  buttonText,
  footer,
  header,
  serviceImageTitle,
  serviceImageUrl,
}) => {
  const dispatch = useDispatch();
  const currentLocale = window.location.pathname;
  const handleClick = useCallback(() => {
    if (buttonDestination.includes('http') && !buttonDestination.includes('hpsmart')) {
      const { country, language } = getLocaleFromPath();
      const localizedDestination = localizeURL(buttonDestination, country, language);
      window.open(localizedDestination, '_blank');
      return;
    }
    dispatch(push(currentLocale+buttonDestination));
  }, [buttonDestination]);
  return (
    <Container>
      <ImageContainer>
        <img
          alt={serviceImageTitle}
          src={serviceImageUrl}
        />
        <Triangle />
      </ImageContainer>
      <ContentContainer>
        <Title>{header}</Title>
        <Body dangerouslySetInnerHTML={{ __html: body }} />
        <StyledButton onClick={handleClick}>{buttonText}</StyledButton>
        <Footer dangerouslySetInnerHTML={{ __html: footer }} />
      </ContentContainer>
    </Container>
  );
};

export default ServiceItem;
