import React, { Component } from 'react';
import AccordionTitle from "./../../shared-components/molecules/AccordionTitle";
import AccordionSubscriptionContent from './AccordionSubscriptionContent';
import SubscriptionSection from './SubscriptionSection';
import classNames from 'classnames';
import bind from './../../bind';
import { getInstantInkUrl } from "./../../../lib/urlUtils";
import Accordion from "./../../shared-components/organisms/Accordion";

class InstantInkSubscription extends Component {
  constructor(props) {
    super(props);
    this.keyID = "II"
    this.onActionClicked = this.onActionClicked.bind(this);
  }
  onActionClicked() {
    const navLink = document.createElement("a");
    navLink.setAttribute('href', this.getIIUrl());
    navLink.setAttribute('target', '_blank');
    navLink.click();
  }
  callbackFn = (bool) => {
    const containerClass = classNames('accordion', 'inkSettingsInstant', 'myPrinterSetting', { 'accordion-active': bool });
    document.getElementById(`container_${this.keyID}`).className = containerClass;
  }
  getIIUrl() {
    return getInstantInkUrl(this.props.config.data.Get('II_BASE_URL'), this.props.myPrinter.data.WPPData.printerId, this.props.locales.alt3);
  }
  render() {
    const subscriptionData = this.props.myPrinter.data.subscriptionData;
    const containerClass = classNames('accordion', 'inkSettingsInstant', 'myPrinterSetting');
    return (<div className="printerGrid--printerCell promoSection--iiSubscription">
      <a name="tracking-button" id="II-manage-subscription"></a>

      {this.props.gnb.isTablet ||
        <SubscriptionSection
          sectionHeader={subscriptionData.instant_ink_management_title}
          showCheckmark={false}
          sectionDescription={subscriptionData.instant_ink_management_description}
          sectionActionText={subscriptionData.instant_ink_management_action_text}
          sectionActionId="II-manage-subscription"
          onActionClicked={this.onActionClicked}
        />
      }

      {this.props.gnb.isTablet &&
        <div id={`container_${this.keyID}`} className={containerClass}>
          <AccordionTitle
            loading={false}
            ID={this.keyID}
            accordionTitle={subscriptionData.instant_ink_eligibility_title}
          />
          <Accordion ID={this.keyID} actionID={this.keyID} groupID="InkSettings" groupFn={this.props.groupFn} controler={this.props.controler} callbackFn={this.callbackFn} >
            <AccordionSubscriptionContent
              ID="II-manage-subscription"
              description={subscriptionData.instant_ink_management_description}
              actionText={subscriptionData.instant_ink_management_action_text}
              onActionClicked={this.onActionClicked}
            />
          </Accordion>
        </div>
      }
    </div>);
  }
}

export default bind(InstantInkSubscription);