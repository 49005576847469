import styled from 'styled-components';
import {
  paths,
} from '../../utils/styleHelpers';

export const MainContent = styled.div`
  && {
    color: ${paths.colorsBlack};
    font-family: ${paths.fontsFamily};
    font-size: ${paths.fontsMd};
    > p, > ul, > ul > li {
      color: ${paths.colorsBlack};
      font-family: ${paths.fontsFamily};
      font-size: ${paths.fontsMd};
    }
  }
`;

export const BackButtonContainer = styled.div`
  text-align: center;
`;

export const StyledContainer = styled.div`
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-family: sans-serif;
  color: black;
  font-size: .75rem;
  line-height: 1.3;
  a { color: #0171AD; }
  p { line-height: 1.3; }
  em { 
    font-style: normal;
    text-decoration: underline;
  }
  ol {
    margin: 0 0 1rem 1rem;
    line-height: 1.3;
  }
  ol.letters {
    list-style-type: lower-latin;
    margin-left: 1rem;
  }
  ol.roman {
    list-style-type: lower-roman;
    margin-left: 1rem;
  }
  ul {
    margin: 0 0 1rem 1rem;
    line-height: 1.3;
  }
  small {
    margin-left: 1rem;
  }
`;
export const StyledContent = styled.div`
  margin: .5rem;
  color: black;
`;
export const StyledTitle = styled.h2`
  text-align: center;
  font-size: 1.1875rem;
  margin: 0 auto;
  font-family: sans-serif;
  color: black;
  font-weight: bold;
`;
export const StyledDate = styled.p`
  text-align: center;
  font-size: .75rem;
  font-family: sans-serif;
  color: black;
  text-decoration: underline;
  margin-top: .5rem;
  font-weight: bold;
`;
export const StyledIndex = styled.ol`
  margin-left: 2rem !important;
`;
export const StyledSections = styled.ol`
   margin-left: 1rem;
   li:nth-child(22) {
      p:nth-child(2) {
        margin-left: 1rem;
      }
    }
`;
export const SectionTitle = styled.h3`
  font-size: 1rem;
  font-family: sans-serif;
  color: black;
  margin-left: 1rem;
  font-weight: bold;
`;

export const ListContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 1rem;
`;
export const ContactEntry = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  div { 
    display: flex;
    flex: 0 1 auto;
    span:first-child {
      flex: 0 0 25%;
      padding-right: 1rem;
      white-space: nowrap;
    }
    span:last-child {
      flex: 1 1 75%;
    }
  }
`;
