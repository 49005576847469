// This file is responsible for handling all configuration constants related to Beacon Reset / Printer Connection.
// SKU = SLUG (both are the same thing)

// SLUGS for Printer Connection

export const SLUG_3M = '3uc58d';
export const SLUG_TACCOLA_FB = '25r66a';
export const SLUG_TACCOLA_ADF = '4ws37b';
export const SLUG_VASARI_FB = '5se22b';
export const SLUG_VASARI_ADF = '6wd15a';
export const SLUG_SKYREACH_FB = '6gx04a';
export const SLUG_SKYREACH_ADF = '6gx05a';
export const SLUG_EYRIE = '6gw64a';
export const SLUG_BEAM = '2y9h0a';
export const SLUG_JUPITER = '7qr88a';
export const SLUG_BEAM_MFP = '2y9h2a';
export const SLUG_PIXIU = '2y9h4a';
export const SLUG_TASSEL = '60k57d';
export const SLUG_LEBI = '3d4l3a';
export const SLUG_MARCONI = '403x0a';
export const SLUG_MORETO = '405w1c';
export const SLUG_VICTORIA = '714l5a';
export const SLUG_KEBIN = '537p6a';
export const SLUG_DRAGONFLY_BASE = '53x74a';
export const SLUG_DRAGONFLY_WL = '53x75a';
export const SLUG_DRAGONFLY_ADF = '53x76a';
export const SLUG_TASSEL_BASE = '51p94a';

const SLUGS_YOSHINO = [
  '2Z599A',
  '2Z600A',
  '2Z601A',
  '2Z602A',
  '2Z604A',
  '2Z605A',
  '2Z606A',
  '2Z607A',
  '2Z609A',
  '2Z610A',
  '2Z611A',
  '2Z612A',
  '2Z613A',
  '2Z614A',
  '2Z615A'
];

const SLUGS_LOCHSA = [
  '2Z618A',
  '2Z619A',
  '2Z620A',
  '2Z622A',
  '2Z623A',
  '2Z624A',
  '2Z625A',
  '2Z627A',
  '2Z628A',
  '2Z629A',
  '2Z630A',
  '2Z632A',
  '2Z633A',
  '2Z634A',
  '2Z635A',
  '2Z636A'
];

const SLUGS_STORM = [
  '3G649A',
  '3G650A',
  '3G651A',
  '3G652A',
  '3G653A',
  '3G654A',
  '3G655A',
  '3G656A',
  '3G657A',
  '3G658A',
  '3G659A',
  '3G660A',
  '3G661A',
  '3G662A',
  '3H667A',
  '3H668A'
];

const SLUGS_TRILLIUM = [
  'A24JGA',
  'AJ4Y5A',
  'AJ4Y4A',
  'A24J2C',
  'B18K0C',
  'B3ZG8C',
  'A24J6B',
  'A24J8B',
  'A24JCB',
  'A24JFB',
  '399P2B',
  'AJ4X0B',
  'A24J7B',
  'AJ4W4B',
  '399P1A',
  '89G05C',
  'AJ4W7B',
  'AJ4W8B',
  'AJ4W9B',
  'A24HSA',
  'A24HTA',
  'AJ4Y0A',
  'A24JKB',
  'A24HPB',
  'A24HMB',
  'A24HNB',
  'AJ4X9B',
  'A24JLC',
  'AJ4X7C',
  'B3ZF3A',
  'A24HFA',
  'A24HGA',
  'A24HHA',
  'A24HJA',
  'A24HKA',
  '89F97B',
  '89F98B',
  'A24J4B',
  'A24HWB',
  '89F99B',
  'A24HVB',
  '38Q66C',
  '399P0C',
  '4D047C',
  '89F86C',
  '89F87C',
  '89G03C',
  'A24HXC',
  'A24HYC',
  'A24J1C',
  'AJ4V7C',
  'A24JJC',
  'AJ4V8C',
  'AJ4W3C',
  '89F94A',
  'AJ4X1A',
  '89F88A',
  '89F89A',
  '89F91A',
  '89F93A',
  'AJ4V9A',
  'AJ4W1A',
  'AJ4W2A',
  'AJ4X2A',
  '89F95A',
  'A24J9A',
  'AJ4X3A',
  'B3ZF5A'
];

const SLUGS_CHERRY_2_4 = [
  '8X3D6A',
  '8X3E0A',
  '8X3E7A'
];

const SLUGS_LOTUS = [
  '8X3F4A',
  '8X3F0A',
  '8X3F2A',
  '8X3F6A',
  '8X3F8A',
  '8X3G0A',
  '8X3G4A',
  '8X3G5A',
  '8X3G6A',
  'B09S0A',
  'B09RYA',
  'B09RZA'
];

const SLUGS_ULYSSES = [
  '4RA85A',
  '4RA86A',
  '4RA87A',
  '4RA88A',
  '4RA89A',
  '5HH48A',
  '5HH49A',
  '5HH50A',
  '5HH51A',
  '5HH52A',
  '5HH53A',
  '5HH54A'
];

const SLUGS_CHERRY_2_0 = [
  '8X3D2A',
  '8X3D4A',
  '8X3D8A',
  '8X3E1A',
  '8X3E2A',
  '8X3E6A',
  '8X3E8A'
];


const SLUGS_HULK = [
  '3G627A',
  '3G628A',
  '3G629A',
  '3G630A',
  '3G631A',
  '3G632A',
  '3G633A',
  '3G634A',
  '3G635A',
  '3G636A',
  '3G637A',
  '3G638A',
  '3G639A',
  '3G640A',
  '3G641A',
  '3G642A',
  '3G643A',
  '3G644A',
  '3G645A',
  '3G646A',
  '3G647A',
  '3G648A',
  '3H670A',
  '3H671A',
  '3H672A',
  '3H673A',
  '9D2X4A'
];



const SLUGS_SELENE = [
  '4RA80A',
  '4RA81A',
  '4RA82A',
  '4RA83A',
  '4RA84A',
  '5HH64A',
  '5HH65A',
  '5HH66A',
  '5HH67A',
  '5HH68A',
  '5HH69A',
  '5HH70A',
  '5HH71A',
  '5HH72A',
  '5HH73A',
  '5HH74A',
  '5HH75A',
  '5HH76A'
];

const SLUGS_EUTHENIA = [
  '499M7A',
  '499Q8A',
  '74T92A',
  '499Q5A',
  '74P27A',
  '499M8A',
  '759V2A',
  '499Q6A',
  '499M6A',
  '74P26A',
  '499Q3A',
  '759V1A',
  '499Q4A',
  '74P28A',
  '499Q7A',
  '759V0A'
];

const SLUGS_ZELUS = [
  '499Q9A',
  '499R0A',
  '499N4A',
  '74P25A'
];

const SLUGS_NARITA_FB = [
  'B63JSA',
  'B63JTA',
  'B63JVB',
  'B63JWD',
  'B63JXD',
  'B63JYD',
  'B63JZA',
  'B63K0B',
  'B63K1A',
  'B6JW3B',
  'B6JW4D',
  'B6JW5D',
  'B6JW6D'
];

const SLUGS_NARITA_ADF = [
  'B63K3A',
  'B63K4A',
  'B63K5B',
  'B63K6D',
  'B63K7D',
  'B63K8D',
  'B63K9B',
  'B6JX3B',
  'B6JX4D',
  'B6JX5D',
  'B6JX6D'
];

// Used by the beacon reset pages to check if a printer model is supported.

export const BEACON_RESET_SUPPORTED_SKUS = [
  '2Y9H0A',
  '2Y9H2A',
  '2Y9H4A',
  '3D4L3A',
  '403W3B',
  '403X0A',
  '405W1C',
  '4U555A',
  '4V2N1C',
  '51P94A',
  '537P6A',
  '53X75A',
  '53X76A',
  '53x74A',
  '60K57D',
  '6GW64A',
  '6GX04A',
  '6GX05A',
  '6WD15A',
  '714L5A',
  '7QR88A',
  '3UC58D',
  '25R66A',
  '4WS37B',
  '5SE22B',
  ...SLUGS_TRILLIUM,
  ...SLUGS_SELENE,
  ...SLUGS_HULK,
  ...SLUGS_LOCHSA,
  ...SLUGS_LOTUS,
  ...SLUGS_CHERRY_2_4,
  ...SLUGS_ULYSSES,
  ...SLUGS_CHERRY_2_0,
  ...SLUGS_EUTHENIA,
  ...SLUGS_ZELUS,
  ...SLUGS_YOSHINO,
  ...SLUGS_STORM,
  ...SLUGS_NARITA_FB,
  ...SLUGS_NARITA_ADF
];

// ContentStack Entry IDS for Printer Connection

export const ENTRYID_3M = 'blt17852eefeb567454';
export const ENTRYID_TACCOLA_FB = 'bltbff2a91204f4f7b1';
export const ENTRYID_TACCOLA_ADF = 'bltfc1debe2f44be33d';
export const ENTRYID_VASARI_FB = 'bltc834475d35549749';
export const ENTRYID_VASARI_ADF = 'blte244c749a6e94457';
export const ENTRYID_SKYREACH_FB = 'blte371deb4f601da7e';
export const ENTRYID_SKYREACH_ADF = 'blt6f725c20afa36a3f';
export const ENTRYID_EYRIE = 'bltd1d400899c00ee51';
export const ENTRYID_BEAM = 'blt943033e3c848127b';
export const ENTRYID_JUPITER = 'blt7758a18f4171b9c5';
export const ENTRYID_BEAM_MFP = 'bltaa8e0ea01053683f';
export const ENTRYID_PIXIU = 'bltbc6e5d0e142c685a';
export const ENTRYID_TASSEL = 'bltb34e4a1771e054f8';
export const ENTRYID_LEBI = 'blt8f077a041a1bbc0d';
export const ENTRYID_MARCONI = 'blt4b75cdb0264940ef';
export const ENTRYID_MORETO = 'bltf3cc542b93d0d966';
export const ENTRYID_VICTORIA = 'blt6bdd7b023a55923b';
export const ENTRYID_KEBIN = 'blt0be4b42bfa2e4fc8';
export const ENTRYID_DRAGONFLY_BASE = 'blt292fb20f6c07844a';
export const ENTRYID_DRAGONFLY_WL = 'blt2447ecc7a8f76ffe';
export const ENTRYID_DRAGONFLY_ADF = 'bltcbf96840ea259209';
export const ENTRYID_TRILLIUM = 'blt845401b5fd66bc44'
export const ENTRYID_ULYSSES = 'blt31ac02e29d03268b';
export const ENTRYID_CHERRY_2_0 = 'bltbe7580e43fcd2a0b'; // (FLOWERS)
export const ENTRYID_EUTHENIA = 'bltf954640540435a09';
export const ENTRYID_ZELUS = 'blt2f6af9ac3272189d';
export const ENTRYID_NARITA_FB = 'blt2e4388e66c561112';
export const ENTRYID_NARITA_ADF = 'blt7c4f1c61437f94b7';


// Mappings that lead to thesame page: YOSHINO / STORM
// USE SAME ENTRYID FOR ALL
export const ENTRYID_YOSHINO = 'blt3f55ccc8dafdf990';
export const ENTRYID_STORM = 'blt3f55ccc8dafdf990';

// Mappings that lead to the same page: SELENE / HULK / LOCHSA
// USE SAME ENTRYID FOR ALL
export const ENTRYID_SELENE = 'blt8eba40fe95941ea0';
export const ENTRYID_HULK = 'blt8eba40fe95941ea0';
export const ENTRYID_LOCHSA = 'blt8eba40fe95941ea0';

// Mappings that lead to the same page: LOTUS / CHERRY_2_4 (FLOWERS)
// USE SAME ENTRYID FOR ALL

export const ENTRYID_LOTUS = 'blt04c822915a662ed4';
export const ENTRYID_CHERRY_2_4 = 'blt04c822915a662ed4';

const YOSHINO_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_YOSHINO.map((slug) => [slug, ENTRYID_YOSHINO])
);

const STORM_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_STORM.map((slug) => [slug, ENTRYID_STORM])
);

const TRILLIUM_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_TRILLIUM.map((slug) => [slug, ENTRYID_TRILLIUM])
);

const LOTUS_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_LOTUS.map((slug) => [slug, ENTRYID_LOTUS])
);

const HULK_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_HULK.map((slug) => [slug, ENTRYID_HULK])
);

const LOCHSA_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_LOCHSA.map((slug) => [slug, ENTRYID_LOCHSA])
);

const SELENE_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_SELENE.map((slug) => [slug, ENTRYID_SELENE])
);

const CHERRY_2_4_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_CHERRY_2_4.map((slug) => [slug, ENTRYID_CHERRY_2_4])
);

const EUTHENIA_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_EUTHENIA.map((slug) => [slug, ENTRYID_EUTHENIA])
);

const ZELUS_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_ZELUS.map((slug) => [slug, ENTRYID_ZELUS])
);

const CHERRY_2_0_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_CHERRY_2_0.map((slug) => [slug, ENTRYID_CHERRY_2_0])
);

const ULYSSES_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_ULYSSES.map((slug) => [slug, ENTRYID_ULYSSES])
);

const NARITA_FB_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_NARITA_FB.map((slug) => [slug, ENTRYID_NARITA_FB])
);

const NARITA_ADF_SKU_TO_ENTRY_ID_MAPPING = Object.fromEntries(
  SLUGS_NARITA_ADF.map((slug) => [slug, ENTRYID_NARITA_ADF])
);

export const SKU_TO_ENTRY_ID_MAPPING = {
  ...YOSHINO_SKU_TO_ENTRY_ID_MAPPING,
  ...STORM_SKU_TO_ENTRY_ID_MAPPING,
  ...TRILLIUM_SKU_TO_ENTRY_ID_MAPPING,
  ...HULK_SKU_TO_ENTRY_ID_MAPPING,
  ...SELENE_SKU_TO_ENTRY_ID_MAPPING,
  ...LOTUS_SKU_TO_ENTRY_ID_MAPPING,
  ...LOCHSA_SKU_TO_ENTRY_ID_MAPPING,
  ...CHERRY_2_4_SKU_TO_ENTRY_ID_MAPPING,
  ...EUTHENIA_SKU_TO_ENTRY_ID_MAPPING,
  ...ZELUS_SKU_TO_ENTRY_ID_MAPPING,
  ...CHERRY_2_0_SKU_TO_ENTRY_ID_MAPPING,
  ...ULYSSES_SKU_TO_ENTRY_ID_MAPPING,
  ...NARITA_FB_SKU_TO_ENTRY_ID_MAPPING,
  ...NARITA_ADF_SKU_TO_ENTRY_ID_MAPPING
};
