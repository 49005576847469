import React from 'react'
import styled from 'styled-components'

const SectionHead = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0;
  padding: 3.5625rem 1.375rem 1.375rem;
  @media screen and (min-width:${props => props.theme.small}) {
    padding-top: 4rem;
  }
  div {
    width: 3.75rem;
    @media screen and (min-width:${props => props.theme.small}) {
      width: 5rem;
    }
  }
  img {
    width: 100%;
  }
  h2 {
    padding: 0;
    max-width: 100%;
    @media screen and (max-width:${props => props.theme.small}) {
      margin: 0.375rem 0 0.75rem 0;
      font-size: 2rem;
      line-height: 1.125;
    }
    margin: 1.5rem 0 0.75rem 0;
    @media screen and (min-width:${props => props.theme.large}) {
      margin: 1.25rem 0 0.5rem 0;
    }
  }
  p {
    max-width: 100%;
    font-size: 1.25rem;
    @media screen and (min-width:${props => props.theme.small}) {
      font-size: 1.5rem;
    }
    margin: 0 0 0.75rem;
  }
`
export default function LandingPageSectionHead(props) {
  return (
    <SectionHead isMobile={props.isMobile}>
      <div>
      {(props.sectionIconMobile && props.isMobile) ? <img src={props.sectionIconMobile} /> : <img src={props.sectionIcon} />  }
      </div>
      <h2>{props.headline}</h2>
      {props.description && <p>{props.description}</p>}
    </SectionHead>
  )
}