import { takeEvery, put, call } from 'redux-saga/effects';
import ActionTypes from '../constants/ActionTypes';
import { saveUDLParams } from '../actions/analytics';
import { encrypt } from '../services/encryption';

function* handleManageDeviceID({ eventInfo, id }) {
  try {
    const { data: { deviceID } } = yield call(encrypt, { deviceID: id });
    window.dataLayer.push({ ...eventInfo, deviceID });
    yield put(saveUDLParams({ deviceID, rawDeviceID: id }));
  } catch (e) {
    console.error('Could not encrypt parameter');
    console.error(e);
  }
}

export default function* watchEncryptThenPageView() {
  yield takeEvery(ActionTypes.ENCRYPT_THEN_PAGEVIEW, handleManageDeviceID);
}
