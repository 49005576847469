import React from 'react';
import { SupportContainer, SupportText } from './styles';

const Support = (props) => {
  return (
    <SupportContainer isRTL={props.isRTL}>
      <img alt={props.alt} src={props.icon} />
      <SupportText dangerouslySetInnerHTML={{ __html: props.text }} isRTL={props.isRTL} />
    </SupportContainer>
  );
};

export default Support;
