 import styled from 'styled-components';
 import {breakpoints} from '../../../src/utils/styleHelpers';

 export const Graph = styled.img`
   width: 4.375rem;
   margin-top:3rem;
   @media screen and (max-width:${breakpoints.mobile}) {
     width:4.875rem;
   }
  `;

  export const MarketingPartnersContainer = styled.div`
   display:flex;
   flex-direction: column;
   justify-content:center;
   align-items:center;
   font-family:"HP Simplified", Arial, sans-serif;
   #data-sharing-notice-accordion {
    max-width:43.5rem;
    margin:0 1.25rem;
  }
   && {
   .vn-animated-height__container {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 0.5s ease;
    visibility: hidden; }
    .vn-animated-height__container--entering, .vn-animated-height__container--exiting {
      opacity: 1;
      visibility: inherit; }
    .vn-animated-height__container--entered {
      height: auto;
      opacity: 1;
      overflow: visible;
      visibility: inherit; }
  
   .vn-animated-height__wrapper {
    display: flex; }
  
   .vn-animated-height__wrapper-inner {
     width: 100%; }
   }
`;

export const Title = styled.h4`
  text-align:center;
  margin-top:1rem;
  font-size:1.75rem;
  color:#4E4E4E;
  font-weight:bold;
  font-family:"HP Simplified Light", Arial, sans-serif;
  @media screen and (max-width:${breakpoints.mobile}) {
    font-size:1.375rem;
  }
`;

export const Body = styled.div`
  font-family:"HP Simplified", Arial, sans-serif;
  color:#4E4E4E;
  text-align:center;
  max-width:44.5rem;
  font-size:1rem;
  line-height: 1.25;
  margin:0 2rem;
  @media screen and (max-width:${breakpoints.mobile}) {
    font-size:0.9375rem;
  }
  a {
    text-decoration:underline; 
    color: #0096D6;
    :hover {
      color: #006691;
    }
    :active {
      color: #004F74;
    }
  }
`;

export const AccordionBody = styled.div`
  >p{
  font-family:"HP Simplified", Arial, sans-serif;
  color:#4E4E4E;
  font-size:1rem;
  display:block;
  line-height: 1.25;
  @media screen and (max-width:${breakpoints.mobile}) {
    font-size:0.875rem;
  }
  a {
    text-decoration:underline; 
    color: #0096D6;
    :hover {
      color: #006691;
    }
    :active {
      color: #004F74;
    }
  }
}
`;
export const AccordionHeader = styled.span`
  font-family:"HP Simplified Light", Arial, sans-serif;
  color:#4E4E4E;
  font-weight:bold;
  font-size:1rem;
 `;