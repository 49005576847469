import { pathOr } from 'ramda';
import Actions, {
  getUcdePortalElementsRequest,
} from './actions';
import { createReducer } from '../../../../../utils/redux';

const initialState = {};

export const ucdeUserPortalElementsRequest = (state = initialState) => (
  {
    ...state,
    loading: true,
  }
);

export const ucdeUserPortalElementsSuccess = (state = initialState, action) => (
  {
    ...state,
    ...pathOr({}, ['payload', 'data'], action),
    loading: false,
  }
);

export const ucdeUserPortalElementsFailed = (state = initialState, action) => (
  {
    ...state,
    ...pathOr({}, ['payload', 'data'], action),
    apiError: true,
  }
);

export const ucdeUserPortalElementsReset = () => initialState;

const handlers = {
  [getUcdePortalElementsRequest]: ucdeUserPortalElementsRequest,
  [Actions.getUcdePortalElementsSuccess]: ucdeUserPortalElementsSuccess,
  [Actions.getUcdePortalElementsFailed]: ucdeUserPortalElementsFailed,
};

const ucdePortalElementsReducer = createReducer(initialState, handlers);

export default ucdePortalElementsReducer;
