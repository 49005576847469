import React from 'react';
import {
  GetStartedContainer,
  GetStartedContent,
  ImageTile,
  ContentTile,
  SocialMedia
} from './styles';
import { getHtmlDir } from '../../../utils/globals';
import { BADGE_ID } from '../../Dashboard/utils/constants';

const GetStarted = (props) => {
  const { smartAdvAppPromoData, appBadges } = props;
  const { mobile, tablet, widescreen, app_promo_body, app_promo_heading } =
    smartAdvAppPromoData[0]?.app_promo;

  const appPromoHeadingSplit = app_promo_heading.split(' ');
  const appPromoHeading1 = appPromoHeadingSplit.slice(0, 2).join(' ');
  const appPromoHeading2 = appPromoHeadingSplit.slice(2, 4).join(' ');

  const iosBadgeData = appBadges[0].app_badge;
  const googleBadgeData = appBadges[1].app_badge;
  const microsoftBadgeData = appBadges[2].app_badge;
  const macBadgeData = appBadges[3].app_badge;

  const direction = getHtmlDir();

  return (
    <GetStartedContainer>
      <GetStartedContent>
        <ImageTile>
          <img alt={mobile.title} src={mobile.url} className="feature-image feature-image_375" />
          <img alt={tablet.title} src={tablet.url} className="feature-image feature-image_720" />
          <img
            alt={widescreen.title}
            src={widescreen.url}
            className="feature-image feature-image_1280"
          />
        </ImageTile>
        <ContentTile direction={direction}>
          <div className="title">
            <span className="green-text">{`${appPromoHeading1} `}</span>
            <span className="yellow-text">{appPromoHeading2}</span>
          </div>
          <div className="description" dangerouslySetInnerHTML={{ __html: app_promo_body }} />
          <SocialMedia>
            <a id={iosBadgeData.app_badge_id} href={iosBadgeData.app_badge_link}>
              <img
                alt={iosBadgeData.app_badge_graphic.title}
                src={iosBadgeData.app_badge_graphic.url}
                className="store app-store"
              />
            </a>
            {googleBadgeData.app_badge_id !== BADGE_ID ?
              (
                <a id={googleBadgeData.app_badge_id} href={googleBadgeData.app_badge_link}>
                  <img
                    alt={googleBadgeData.app_badge_graphic.title}
                    src={googleBadgeData.app_badge_graphic.url}
                    className="store google-play"
                  />
                </a>
              ) : (
                <a id={googleBadgeData.app_badge_id} href={googleBadgeData.app_badge_link}>
                  <img
                    alt={googleBadgeData.app_badge_graphic.title}
                    src={googleBadgeData.app_badge_graphic.url}
                    className="store baidu-badge"
                  />
                </a>
              )
            }
            <a id={microsoftBadgeData.app_badge_id} href={microsoftBadgeData.app_badge_link}>
              <img
                alt={microsoftBadgeData.app_badge_graphic.title}
                src={microsoftBadgeData.app_badge_graphic.url}
                className="store ms"
              />
            </a>
            <a id={macBadgeData.app_badge_id} href={macBadgeData.app_badge_link}>
              <img
                alt={macBadgeData.app_badge_graphic.title}
                src={macBadgeData.app_badge_graphic.url}
                className="store mac-app-store"
              />
            </a>
          </SocialMedia>
        </ContentTile>
      </GetStartedContent>
    </GetStartedContainer>
  );
};

export default GetStarted;
