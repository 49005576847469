import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../utils/styleHelpers';
import {
  useSelector,
} from 'react-redux';
import {
  Footer as VeneerFooter,
} from '@veneer/core';
import {
  selectNewFooterContent,
  selectLocaleSelectorProps,
} from '../../../selectors/global';
import { Link } from '../../routing';
import {
  paths,
} from '../../../utils/styleHelpers';

import Config from '../../../config/Config';
import {
  STACK_STAGE,
  STACK_DEV,
  STACK_PIE,
  STACK_PROD,
  YOUR_PRIVACY_CHOICES,
  HP_PRIVACY,
  HP_DOT_COM
} from '../../../constants/common';
import { DEFAULT_LOCALE } from '../../../constants/defaults';

const StyledFooter = styled(VeneerFooter)`
  && {
    box-shadow: 0px -2px 4px #2121210D;
    z-index: 998;
    position: absolute;
    bottom: 0;
    width: 100vw;
    max-width: 100%;
    font-family: ${paths.fontsFamily};
    font-size: ${paths.fontsSm};
    color: ${paths.colorsDarkGrey};
    > div {
      flex-direction: column;
      padding-top: 0;
      padding-bottom: 0;

      > div {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding-top: 20px;
        padding-bottom: 20px;
        min-height: 62px;
        flex-wrap: wrap;
        align-items: baseline;
        text-align: center;
        justify-content: center;
        > ul:last-child, > ul:nth-child(4) {
          margin-top: 20px;
        }
      }
    }
    @media (max-width : ${breakpoints.large}){
      position: relative;
    }
    @media (min-width: 120rem) {
      padding-right: calc((100vw - 120rem) / 2);
      padding-left: calc((100vw - 120rem) / 2);
    }
    > div > div:nth-child(2) {
      > ul {
        > li {
          padding: 0 8px;
        }
      }
    }
    @media all and (min-width: ${paths.breakTablet}) {
      font-size: ${paths.fontsMd};
      > div {
        flex-direction: row;
        padding-top: unset;
        padding-bottom: unset;
        > div {
          padding-top: unset;
          padding-bottom: unset;
          align-items: center;
          > ul {
            line-height: 1.5;
            :last-child, :nth-child(4) {
              margin-top: inherit;
            }
            > li {
              padding: 0;
            }
          }
        }
      }
    }
    @media all and (-ms-high-contrast: active) and (max-width: 800px), (-ms-high-contrast: none) and (max-width: 800px) {
      > div {
        flex-direction: column;
        > div {
          max-width: 100%;
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
`;

const StyledLink = styled(Link)`
  &&&& {
    font-family: ${paths.fontsFamily};
    font-size: ${paths.fontsMd};
    color: ${paths.colorsDarkGrey};
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: auto; 
  @media (min-width: ${breakpoints.wide}) {
    height: 64px;
  }
`;

const date = new Date();

const noLinkAdmin = [];

const getLinks = (links, isAdminPage) => (
  isAdminPage ? noLinkAdmin : links.slice(3)
);

const LICENSE = 'license';

const getICPLicenseLink = ({ href, id, key, label, currentStack }) => {
  if (id.indexOf(LICENSE) > -1) {
    if (currentStack === STACK_STAGE && id.includes(STACK_STAGE)) {
      return (
        <StyledLink to={href} id={id} key={key}>
          {label}
        </StyledLink>
      );
    } else if (currentStack === STACK_PROD && !id.includes(STACK_STAGE)) {
      return (
        <StyledLink to={href} id={id} key={key}>
          {label}
        </StyledLink>
      );
    }
    else if ((currentStack === STACK_DEV || currentStack === STACK_PIE) && !id.includes(STACK_STAGE)) {
      return (
        <StyledLink to={href} id={id} key={key}>
          {label}
        </StyledLink>
      );
    }
  } else {
    return (
      <StyledLink to={href} id={id} key={key}>
        {label}
      </StyledLink>
    );
  }
};

const Footer = ({ minimal, userInfoData, isAdminPage, isDataNotice }) => {
  const getPersonalizedLinks = () => {
    const links = useSelector(selectNewFooterContent);
    if (isDataNotice) {
      const desiredIds = [YOUR_PRIVACY_CHOICES, HP_PRIVACY, HP_DOT_COM];
      return links.filter(link => desiredIds.some(id => id === link.id));
    }
    return links;
  };
  const links = getPersonalizedLinks();
  const minimalLinks = minimal ? getLinks(links, isAdminPage) : links;
  const loggedIn = !!(userInfoData);
  const {
    currentLanguage,
  } = useSelector(selectLocaleSelectorProps);

  const [ , country, language ] = window.location.pathname.split('/');
  const filteredLinks = minimalLinks.filter( ({id}) => (
    id !== YOUR_PRIVACY_CHOICES || `/${country}/${language}` === DEFAULT_LOCALE));

  const config = new Config();
  const currentStack = config.Get('CURRENT_STACK');

  return (
    <React.Fragment>
      <Spacer />
      <StyledFooter
        mainContent={{
          leftArea: (
            <span>
              {`© Copyright ${date.getFullYear()} HP Development Company, L.P. `}
            </span>
          ),
          links: window.location.hostname.includes('.com') && !isDataNotice ?
          filteredLinks.slice(0,-2).map(({
            label, to, href, id,
          }) => {
            const key = `${label}-${id}`;
            return {
              label,
              key,
              element: to
                ? (
                  <StyledLink
                    to={to}
                    id={id}
                    key={key}
                  >
                    {label}
                  </StyledLink>
                )
                : (
                  <StyledLink
                    to={href}
                    id={id}
                    key={key}
                  >
                    {label}
                  </StyledLink>
                ),
            };
            }) :
            filteredLinks.map(({
              label, to, href, id,
            }) => {
              const key = `${label}-${id}`;
              return {
                label,
                key,
                element: to
                  ? (
                    <StyledLink
                      to={to}
                      id={id}
                      key={key}
                    >
                      {label}
                    </StyledLink>
                  )
                  : (
                    getICPLicenseLink({ href, id, key, label, currentStack })
                  ),
              };
            }),
          rightArea: (
            (loggedIn && (
            <span>
              {currentLanguage}
            </span>
            )
            )
          ),
        }}
        appearance="ultraminimal"
      />
    </React.Fragment>
  );
};

export default Footer;
