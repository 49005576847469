import actionTypes from '../constants/ActionTypes';
import { makeActionCreator } from '../utils/redux';
import { makeApiCall } from './application';
import { getCurrentUserLegacy } from '../services/user';

export const initializeUser = makeActionCreator(
    actionTypes.INITIALIZE_USER,
    'userType'
);

export const completeUserInfo = makeActionCreator(
    actionTypes.COMPLETE_USER_INFO,
    'payload'
);

export const getUser = (callback) =>
    makeApiCall({
        asyncFn: getCurrentUserLegacy,
        successActionCreator: completeUserInfo,
        callback
    });

export function changeUserPrinterModel(model) {
    return {
        type: actionTypes.SET_USER_PRINTER_MODEL,
        model
    };
}
