import React from 'react';
import { Redirect as RRDomRedirect } from 'react-router';
import { usePathCorrection } from '../../hooks/routeCorrections';
import { not, isNil } from 'ramda';

const Redirect = props => {
    const { to, from, ...restProps } = props;
    const correctedTo = usePathCorrection(to);
    const correctedFrom = usePathCorrection(from);
    return <RRDomRedirect
        to={correctedTo}
        from={not(isNil(from)) ? correctedFrom : undefined}
        {...restProps}
    />
}

export default Redirect;