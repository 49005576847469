import styled from 'styled-components';
import { breakpoints } from '../../../utils/styleHelpers';

export const AdvancedScanContainer = styled.div`
  && {
    font-family: 'HP Simplified';
    margin-top: 40px;
    @media (min-width: ${breakpoints.desktop}) {
      margin-top: 64px;
    }
    @media (min-width: ${breakpoints.bigtablet}) {
      margin-top: 112px;
    }
  }
`;

export const AdvancedScanContent = styled.div`
  && {
    @media (min-width: ${breakpoints.large}) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .image-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media (min-width: ${breakpoints.bigtablet}) {
        justify-content: start;
        width: 1280px;
      }
      @media (min-width: ${breakpoints.wide}) {
        width: 1460px;
      }
    }
    .scan-features {
      @media (min-width: ${breakpoints.wide}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    .review-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 20px 0;
      height: 120px;
      @media (min-width: ${breakpoints.desktop}) {
        margin: 40px 0;
      }
      @media (min-width: ${breakpoints.bigtablet}) {
        margin: 64px 0;
      }
      @media (min-width: ${breakpoints.wide}) {
        margin: 112px 0;
      }

      .review-tile {
        position: relative;
        width: 318px;
        @media (min-width: ${breakpoints.desktop}) {
          width: 370px;
        }
        .reviewer-image-background {
          width: 100%;
          position: absolute;
        }
        .reviewer-thumbnail {
          position: absolute;
          left: 1px;
          top: 9px;
          width: 73px;
        }
      }
    }
  }
`;

export const ImageTile = styled.div`
  && {
    img {
      width: 100%;
      @media (min-width: ${breakpoints.desktop}) {
        width: 370px;
      }
    }
    .feature-image_375 {
      display: block;
    }
    .feature-image_540 {
      display: none;
    }
    .feature-image_720 {
      display: none;
    }
    .feature-image_1280 {
      display: none;
    }
    @media (min-width: ${breakpoints.desktop}) {
      .feature-image_375 {
        display: none;
      }
      .feature-image_540 {
        display: block;
      }
      .feature-image_720 {
        display: none;
      }
      .feature-image_1280 {
        display: none;
      }
    }

    @media (min-width: ${breakpoints.bigtablet}) {
      .feature-image_375 {
        display: none;
      }
      .feature-image_540 {
        display: none;
      }
      .feature-image_720 {
        display: block;
        width: 268px;
        margin-right: 44px;
        margin-left: ${props => props.isRTLSupport ? '1.25rem' : 0};
      }
      .feature-image_1280 {
        display: none;
      }
    }

    @media (min-width: ${breakpoints.wide}) {
      .feature-image_375 {
        display: none;
      }
      .feature-image_540 {
        display: none;
      }
      .feature-image_720 {
        display: none;
      }
      .feature-image_1280 {
        display: block;
        width: 577px;
        margin-right: 44px;
        margin-left: ${props => props.isRTLSupport ? '1.25rem' : 0};
      }
    }
  }
`;

export const ContentTile = styled.div`
  && {
    padding: 10px 0;
    width: 318px;
    @media (min-width: ${breakpoints.desktop}) {
      width: 370px;
    }
    @media (min-width: ${breakpoints.wide}) {
      padding: 30px 0;
      width: 595px;
    }
    .title {
      color: #a1cddf;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 15px;
      font-weight: 400;
    }
    .description {
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 15px;
      @media (min-width: ${breakpoints.wide}) {
        font-size: 32px;
        line-height: 36px;
      }
    }
    .text-content {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
`;

export const ScanFeature = styled.div`
  && {
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
    margin-bottom: 23px;
    border-bottom: 0.5px solid #a6a6a6;
    @media (min-width: ${breakpoints.wide}) {
      width: 273px;
    }
    &&.last-feature {
      border-bottom: none;
    }
    img {
      width: 30px;
      margin-right: 24px;
      margin-top: 2px;
    }
    .feature-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
    }
    .feature-description {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #404040;
      padding-right: 10px;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .feature-logo {
        width:auto;
      }
    }
  }
`;
