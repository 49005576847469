import {
  propOr,
  path,
  compose,
  join,
  values,
  identity,
  map,
  pick,
  prop,
} from 'ramda';
import base64url from 'base64url';
import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import { selectSupportURLDictionary } from './config';
import { constructEventInfo } from '../utils/analytics';
import printerSKUs from '../constants/printerSKUs.json';
import * as PRINTERIDS from '../constants/printerIds';

const makeOptionRegex = (suffix, collection) => compose(
  join('|'),
  map(str => `${str}${suffix}`),
  values,
)(collection);

const printerIdRegex = makeOptionRegex('-info', PRINTERIDS);

const SELECTORS_BASE = {
  selectLocation: path(['router', 'location']),
  selectPathname: path(['router', 'location', 'pathname']),
  selectQuery: path(['router', 'location', 'query']),
  selectSearch: path(['router', 'location', 'search']),
};

export const selectRootMatch = createMatchSelector('/:country/:language/:resource?');

export const selectSmartWelcomeMatch = createMatchSelector('/:country/:language/in-app/:platform(ios|android|mac|windows)/:message(welcome|printer-data)');

export const selectDataCollectionNoticeMatch = createMatchSelector('/:country/:language/plain/:type(data-collection-notice|services-data-collection-notice|printer-data-collection-notice|windows-printer-data-collection-notice)/:version?');

export const selectPathname = createSelector(
  SELECTORS_BASE.selectPathname,
  identity,
);

export const selectLocation = createSelector(
  SELECTORS_BASE.selectLocation,
  identity,
);

export const selectQueryParameters = createSelector(
  SELECTORS_BASE.selectQuery,
  identity,
);

export const selectUDLQueryParams = createSelector(
  SELECTORS_BASE.selectQuery,
  query => {
    if (query.state) {
      const stateDecoded = JSON.parse(base64url.decode(query.state));
      return pick(['appInstanceID', 'deviceID', 'userID', 'companyID', 'deviceSKU'], stateDecoded);
    }
    return pick(['appInstanceID', 'deviceID', 'userID', 'companyID', 'deviceSKU'], query);
  },
);

export const selectQueryString = createSelector(
  SELECTORS_BASE.selectSearch,
  identity,
);

export const selectPathnameWithQuery = createSelector(
  selectPathname,
  selectQueryString,
  (pathname, query) => `${pathname}${query}`,
);

export const selectLocaleParamsFromRoute = createSelector(
  selectRootMatch,
  match => ({
    language: path(['params', 'language'], match),
    country: path(['params', 'country'], match),
  }),
);

export const selectPrinterEssentialsMatch = createMatchSelector(`/:country/:language/:id(${printerIdRegex})`);

export const selectErrorPageMatch = createMatchSelector('/:country/:language/error/:id');

export const selectPrinterConnectionErrorPageMatch = createMatchSelector('/:country/:language/support/printer-connection/:sku');

const getLocaleProps = compose(
  pick(['country', 'language']),
  propOr({}, 'params'),
);

export const selectOnboardingRouteProps = createSelector(
  SELECTORS_BASE.selectQuery,
  selectRootMatch,
  (query, match) => ({
    encodedState: propOr('', 'state', query),
    ...getLocaleProps(match),
  }),
);

export const selectEssentialsProps = createSelector(
  selectRootMatch,
  selectQueryParameters,
  selectSupportURLDictionary,
  (match, queryParams, urlDictionary) => {
    const resource = path(['params', 'resource'], match);
    const url = resource && urlDictionary[resource];
    const sku = prop('sku', queryParams);
    const printerEntry = Object.entries(printerSKUs).find(([_, value]) => value.includes(sku));
    return {
      ...getLocaleProps(match),
      printerId: printerEntry ? printerEntry[0] : undefined,
      sku,
      url,
    };
  },
);

const selectResources = createMatchSelector('/:country/:language/:primary?/:secondary?/:tertiary?/:quaternary?');

const selectUDLPrinterIDQueryParams = createSelector(
  SELECTORS_BASE.selectQuery,
  pick(['deviceID', 'unEncryptedDeviceID']),
);

export const selectPageViewPathInfo = createSelector(
  selectPathname,
  selectResources,
  selectUDLPrinterIDQueryParams,
  (pathname, match = {}, queryParams = {}) => {
    const {
      params,
    } = match;
    return {
      pathname,
      eventInfo: constructEventInfo(params),
      ...queryParams,
    };
  },
);

export const selectPathnameWithoutLocale = createSelector(
  selectPathname,
  selectLocaleParamsFromRoute,
  (pathname, { country, language }) => pathname.replace(`/${country}/${language}`, ''),
);

export const selectPathnameAndQueryWithoutLocale = createSelector(
  selectPathname,
  selectQueryString,
  selectLocaleParamsFromRoute,
  (pathname, queryString, { country, language }) => `${pathname.replace(`/${country}/${language}`, '')}${queryString}`,
);
