/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { selectDataCollectionNoticeMatch } from './routing';
import { selectContentStackDictionary } from './craft';
import { DATA_COLLECTION_RESOURCE_NAME } from '../constants/contentStackResources';
import { path } from 'ramda';

export const selectDataCollectionNoticeProps = createSelector(
    selectDataCollectionNoticeMatch,
    selectContentStackDictionary,
    (match = {}, csDictionary) => {
        const {
            url,
            params: { type, version }
        } = match;

        let content = path([DATA_COLLECTION_RESOURCE_NAME, 'data', 'entry'], csDictionary);

        if (!content) {
            return {};
        }

        const urlWithoutVersion =
            typeof version !== 'undefined'
                ? url.substring(0, url.lastIndexOf('/'))
                : url;
        const correctedPath = content.version_number
            ? `${urlWithoutVersion}/${content.version_number}`
            : null;

        const actualCorrectedPath = typeof version === 'undefined'
            ? correctedPath
            : null;

        return {
            content,
            actualCorrectedPath
        };
    }
);