import React from 'react';
import { InstallHPSmartButton } from '../../AppBadges/AppBadges';
import { HeaderContainer, HeaderText, Feature, Steps, StepNumber, StepDescription } from './styles';

const Header = (props) => {
  const stepNumberOne = props.stepNumbers[0].number_block.number;
  const stepNumberTwo = props.stepNumbers[1].number_block.number;

  const stepDescriptionOne = props.stepDescription[0].install_step.step;
  const stepDescriptionTwo = props.stepDescription[1].install_step.step;

  return (
    <HeaderContainer
      alt={props.desktopHeaderImageTitle}
      background={props.imageURL}
      isRTL={props.isRTL}
    >
      <HeaderText isRTL={props.isRTL}>{props.header}</HeaderText>
      <Feature isRTL={props.isRTL}>{props.subHeader}</Feature>
      <Steps isRTL={props.isRTL}>
        <StepNumber dangerouslySetInnerHTML={{ __html: stepNumberOne }} isRTL={props.isRTL} />
        <StepDescription dangerouslySetInnerHTML={{ __html: stepDescriptionOne }} />
      </Steps>
      <Steps isRTL={props.isRTL}>
        <StepNumber dangerouslySetInnerHTML={{ __html: stepNumberTwo }} isRTL={props.isRTL} />
        <StepDescription dangerouslySetInnerHTML={{ __html: stepDescriptionTwo }} />
      </Steps>
      <InstallHPSmartButton
        buttonText={props.buttonText}
        buttonDestination={props.buttonDestination}
      />
    </HeaderContainer>
  );
};

export default Header;
