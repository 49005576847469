import React, { Component, Fragment } from 'react';
import { path, prop } from 'ramda';
import styled, { ThemeProvider } from 'styled-components';
import bind from '../bind';
import LandingInPageMenu from './LandingInPageMenu';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';
import LandingPageHero from './LandingPageHero';
import LandingPageBenefits from './LandingPageBenefits';
import LandingPageFeatures from './LandingPageFeatures';
import LandingPageAppSection from './LandingPageAppSection';
import LandingPageFAQ from './LandingPageFAQ';
import LandingPageFootnotes from './LandingPageFootnotes';
import MobileFaxSpecialOffer from './MobileFaxSpecialOffer';
import Testimonials from './Testimonials';
import { APP_BADGES_RESOURCE_NAME, MOBILE_FAX_RESOURCE_NAME } from '../../constants/contentStackResources';

// mobile-fax in app nav/behavior should be tested or developed against at this endpoint:
// /in-app/mobile-fax?offer=24month&session_id=cIcozZTKIMnsufXsDZlwsnluTxYm48PY&callback=https://sws.pie.hp8.us/sws/faxlaunch? session_id=cIcozZTKIMnsufXsDZlwsnluTxYm48PY&fax_plan_id=d5c24fb9-e401-4f0b-b535-01dd0801af20&callback_schema=hpsmartwindows://softfax

const mobileFaxTheme = {
  mobileFontStack: '-apple-system, Roboto-Medium, DroidSans-Bold, sans-serif',
  hpNormalFontStack:
    "'hp-simplified-light', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'",
  // TODO: Add logic to change based on platform
  boldFontFamilyAndWeight:
    "font-family: 'hp-simplified', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'; font-weight: 500;",
  normalFontWeight: '300',
  boldFontWeight: '500',
  hpBlue: '#0171AD',
  h3: '1.375rem',
  h4: '1.125rem',
  p: '1rem',
  tiny: '40rem',
  small: '48rem',
  medium: '60rem',
  large: '74rem',
  xlarge: '96rem',
  standardSectionMargins: '0 1rem 2.5rem',
  maxContentWidth: '80rem',
  maxBackgroundWidth: '100rem',
  pagingDotSize: '0.8125rem',
  pagingDotPadding: '0.375rem',
};
const SoftFaxContent = styled.div`
  /* Add logic to show this on iOS and Android: */
  /* font-family: ${props => props.theme.mobileFontStack}; */
  /* And this one only for web and desktop: */
  font-family: ${props => props.theme.hpNormalFontStack};
  font-weight: ${props => props.theme.normalFontWeight}
  h1, h2, h3, h4, h5, h6, p, lighter {
    font-weight: ${props => props.theme.normalFontWeight}
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    @media screen and (min-width:${props => props.theme.small}) {
      font-size: 2.25rem;
      line-height: 1.18;
    }
    @media screen and (min-width:${props => props.theme.medium}) {
      font-size: 2.75rem;
    }
    @media screen and (min-width:${props => props.theme.large}) {
      font-size: 3rem;
      line-height: 1.125;
    }
  }
  p { line-height: 1.25; }
`;
const StyledSection = styled.section`
  height: 100%;
  width: 100%;
  background-color: ${props => (props.bgColor ? '#f5f5f5' : 'transparent')};
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: center;
  max-width: ${props => (props.customMaxWidth ? props.customMaxWidth : props.theme.maxBackgroundWidth)};
  align-self: center;
`;
class MobileFax extends Component {
  constructor(props) {
    super(props);
    this.breakpoint = 768;
    this.state = {
      isMobile: window.innerWidth < this.breakpoint,
      heroLoaded: false,
    };
    this.isInApp = window.location.href.indexOf('/in-app/') !== -1;
    this.resizeLayout = this.resizeLayout.bind(this);
    window.addEventListener('resize', this.resizeLayout);
  }

  resizeLayout = () => {
    if (this.state.isMobile !== window.innerWidth < this.breakpoint) {
      this.setState({
        isMobile: window.innerWidth < this.breakpoint,
      });
    }
  }

  heroImageLoaded = () => {
    this.setState({
      heroLoaded: true,
    });
  }

  tracking = ID => {
    ID = ID.indexOf('#') === 0 ? ID.substr(1) : ID;
    document.getElementById(`${ID}`).click();
  }

  render() {
    const { contentStack } = this.props;
    
    if (prop(MOBILE_FAX_RESOURCE_NAME, contentStack) && prop(APP_BADGES_RESOURCE_NAME, contentStack)) {
      const mobileFaxData = path([MOBILE_FAX_RESOURCE_NAME], contentStack);
      const appBadges = path([APP_BADGES_RESOURCE_NAME, 'data', 'entries', 0], contentStack);

      const { menu_item_1_text, menu_item_2_text, menu_item_3_text, forward_button_text, back_link_text } = mobileFaxData;
      const mobileFaxDataSource = { menu_item_1_text, menu_item_2_text, menu_item_3_text, forward_button_text, back_link_text };

      mobileFaxData.landing_page_hero_images = {
        heroImageSmallUrl : mobileFaxData.hero_image_small?.url,
        heroImageSmall2xUrl : mobileFaxData.hero_image_small_2x?.url,
        heroImageMediumUrl : mobileFaxData.hero_image_medium?.url,
        heroImageMedium2xUrl : mobileFaxData.hero_image_medium_2x?.url,
        heroImageLargeUrl : mobileFaxData.hero_image_large?.url,
        heroImageLarge2xUrl : mobileFaxData.hero_image_large_2x?.url
       }

      const sectionList = [
        { sectionID: 'benefits', sectionTitle: mobileFaxDataSource.menu_item_1_text },
        { sectionID: 'features', sectionTitle: mobileFaxDataSource.menu_item_2_text },
        { sectionID: 'faq', sectionTitle: mobileFaxDataSource.menu_item_3_text },
      ];
      const deeplinkList = [
        { sectionID: 'benefits', sectionTitle: mobileFaxDataSource.menu_item_1_text },
        { sectionID: 'features', sectionTitle: mobileFaxDataSource.menu_item_2_text },
        { sectionID: 'faq', sectionTitle: mobileFaxDataSource.menu_item_3_text },
        { sectionID: 'get-the-app', sectionTitle: mobileFaxData.landing_page_app_section.app_section_headline_text },
      ];

      let newPath;
      let backPath;
      let querystringComplete;
      if (!!this.isInApp && (location.search.includes('callback=') && location.search.includes('&callback_schema='))) {
        querystringComplete = true;
        newPath = decodeURIComponent(location.search.split('callback=')[1].split('&callback_schema=')[0]);
        let callbackSchema = location.search.split('callback=')[1].split('&callback_schema=')[1].split('&')[0];
        callbackSchema = callbackSchema.substr(-1) !== '/' ? callbackSchema : callbackSchema.substring(0, callbackSchema.length - 1);
        backPath = decodeURIComponent(`${callbackSchema}/action=goBackSmartHome`);
      } else {
        querystringComplete = false;
        newPath = '#get-the-app';
        backPath = null;
      }

      const [ios, google, microsoft] = appBadges?.landing_page_app_badges;
      const AppBadges = [ios, microsoft, google];

      return (
        <ThemeProvider theme={mobileFaxTheme}>
          <SoftFaxContent>
            {/* TRACKING HOOKS FOR ANALYTICS */}
            {mobileFaxData.landing_faq_entries.map(({ faq_entry }, i) => (
              <a
                id={`mobilefax_faq_accordion_${i}`} name="tracking-hook"
                data-tracking={faq_entry.faq_question_text} key={i}
              />
            ))}
            <a id="mobilefax_tracking_inPageMenu_get-the-app" name="tracking-hook" />
            <a id="mobilefax_tracking_inPageMenu_benefits" name="tracking-hook" />
            <a id="mobilefax_tracking_inPageMenu_features" name="tracking-hook" />
            <a id="mobilefax_tracking_inPageMenu_faq" name="tracking-hook" />
            <a id="mobilefax_tracking_inPageMenu_backLink" name="tracking-hook" />
            <a id="mobilefax_tracking_inPageMenu_forwardButton" name="tracking-hook" />
            <a id="mobilefax_tracking_inPageMenu_no-querystring-in-url" name="tracking-hook" />
            <a id="mobilefax_tracking_hero_noThanks" name="tracking-hook" />
            <a id="mobilefax_tracking_hero_getStarted" name="tracking-hook" />
            <a id="mobilefax_feature_slide_0" name="tracking-hook" />
            <a id="mobilefax_feature_slide_1" name="tracking-hook" />
            <a id="mobilefax_feature_slide_2" name="tracking-hook" />
            {/* TRACKING HOOKS FOR ANALYTICS */}

            {this.state.heroLoaded
              && (
              <LandingInPageMenu
                sectionList={sectionList}
                deeplinkList={deeplinkList}
                mobileFaxDataSource={mobileFaxDataSource}
                isInApp={this.isInApp}
                isMobile={this.state.isMobile}
                isQuerystringComplete={querystringComplete}
                forwardLink={newPath}
                backLink={backPath}
                deeplinkURLSegment={this.isInApp ? '5' : '4'}
              />
              )}
            <StyledSection customMaxWidth="100rem">
              <LandingPageHero
                heroImages={[mobileFaxData.landing_page_hero_images]}
                heroText={mobileFaxData.landing_page_hero_content}
                isQuerystringComplete={querystringComplete}
                buttonDestination={newPath}
                backLink={backPath}
                isMobile={this.state.isMobile}
                isInApp={this.isInApp}
                onReadyFn={this.heroImageLoaded}
              />
            </StyledSection>
            {this.state.heroLoaded
              && (
              <Fragment>

                <StyledSection>
                  <LandingPageBenefits
                    sectionID="benefits"
                    benefits={mobileFaxData.landing_page_benefits}
                    benefitsSection={mobileFaxData.landing_benefits_section}
                    isMobile={this.state.isMobile}
                  />
                </StyledSection>

                <StyledSection id="features">
                  <LandingPageFeatures
                    tracking={this.tracking}
                    backgroundSmall={mobileFaxData.featureBackgroundSmallUrl}
                    backgroundMedium={mobileFaxData.featureBackgroundMediumUrl}
                    backgroundLarge={mobileFaxData.featureBackgroundLargeUrl}
                    featuresMeta={mobileFaxData.landing_features_section}
                    features={mobileFaxData.landing_features}
                  />
                </StyledSection>
                <StyledSection id="special-offer">
                  <MobileFaxSpecialOffer />
                </StyledSection>
                <StyledSection bgColor="gray" id="testimonials">
                  <Testimonials />
                </StyledSection>
                <StyledSection id="faq">
                  <LandingPageFAQ
                    tracking={this.tracking}
                    faqContent={mobileFaxData.landing_faq_entries}
                    faqMeta={mobileFaxData.landing_faq_section}
                    isMobile={this.state.isMobile}
                  />
                </StyledSection>

                <StyledSection id="get-the-app">
                  <LandingPageAppSection
                    sectionData={mobileFaxData.landing_page_app_section[0].app_section}
                    appBadges={AppBadges}
                    buttonText={mobileFaxData.landing_page_hero_content.trial.hero_button_text}
                    isInApp={this.isInApp}
                    isMobile={this.state.isMobile}
                    buttonDestination={newPath}
                  />
                </StyledSection>

                <StyledSection>
                  <LandingPageFootnotes footnotes={mobileFaxData.landing_page_footnotes} />
                </StyledSection>

              </Fragment>
              )}
          </SoftFaxContent>
        </ThemeProvider>
      );
    }
    return (
      <div className="contentLoading">
        <LoaderBlock />
      </div>
    );
  }
}

export default bind(MobileFax);