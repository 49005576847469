import React from 'react';
import PropTypes from 'prop-types';
import {
  LargeWrapper,
  LargeRow,
  LargeDot,
} from './styles';

const DeviceLarge = ({ level }) => {
  return (
    <LargeWrapper level={level}>
      <LargeRow>
        <LargeDot type="cyan" />
        <LargeDot type="magenta" />
        <LargeDot type="yellow" />
      </LargeRow>
      <LargeRow>
        <LargeDot type="black" />
        <LargeDot type="lightGray" />
        <LargeDot type="darkGray" />
      </LargeRow>
      <LargeRow>
        <LargeDot type="red" />
        <LargeDot type="green" />
        <LargeDot type="white" />
      </LargeRow>
    </LargeWrapper>
  );
};

DeviceLarge.propTypes = {
  level: PropTypes.number.isRequired,
};

export default DeviceLarge;
