import axios from "axios";
import CraftApiModel from "../models/CraftApiModel";
import { getApiPathWithLocale } from "../lib/urlUtils";
import ActionTypes from "../constants/ActionTypes";

/**
 * This function uses axios to get printer list.
 *
 * Since axios returns promises, we can dispatch our start event and
 * update it to complete or complete with error when the promise returns.
 *
 * @returns {function(*)}
 */
export function getDevices(deviceNo, currentLocale = "") {
    let start = () => ({
        type: ActionTypes.START_DEVICE_FETCH
    }),
        complete = device => ({
            type: ActionTypes.COMPLETE_DEVICE_FETCH,
            device
        }),
        error = err => ({
            type: ActionTypes.COMPLETE_DEVICE_WITH_ERROR,
            err
        });
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(start());
            axios.get(getApiPathWithLocale(`/api/printers/${deviceNo}.json`, currentLocale)
                // , { timeout: 30000 }
            )
                .then((res) => {
                    if (res.data.data.length == 0) {
                        dispatch(error("Product not found."));
                        return reject("Product not found.");
                    }
                    let devices = new CraftApiModel(res.data.data[0]);
                    dispatch(complete(devices));
                    return resolve(devices);
                })
                .catch((err) => {
                    if (err.response && err.response.status === 403) {
                        dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT })
                    }
                    dispatch(error(err));
                    reject(err);
                });
        });
    }
}
