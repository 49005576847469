import { take, select } from 'redux-saga/effects';
import { pathOr } from 'ramda';
import { UPDATE_LOCALE } from '../actions/location';
import {
  getHtmlDir,
  setHtmlDirRtl,
  setHtmlDirLtr,
  setHtmlLang,
  setCookie,
  getHtmlLang,
  getMetaTagByName,
  setTagAttribute,
} from '../utils/globals';
import {
  validateLocale,
} from '../utils/routing';
import { LANGUAGES, RTL, LTR } from '../constants/i18n';
import { selectRootMatch, selectPathname } from '../selectors/routing';

function prepareCookieLocale(locale) {
  switch (locale) {
    case 'en_UK':
      return 'en_GB';
    default:
      return locale;
  }
}

function* handleLocaleEffects({ locale, country, language }) {
  const pathname = yield select(selectPathname);
  const localeIsValid = yield validateLocale(locale, pathname);
  if (!localeIsValid) {
    return null;
  }
  const htmlDir = getHtmlDir();
  const htmlLang = getHtmlLang();

  if (!htmlLang || htmlLang !== locale) {
    yield setHtmlLang(locale);
    const metaTag = yield getMetaTagByName('target_country');
    yield setTagAttribute(metaTag, 'content', country);
  }

  if ((!htmlDir || htmlDir === LTR) && [LANGUAGES.AR, LANGUAGES.HE].includes(language)) {
    yield setHtmlDirRtl();
  }
  if ((!htmlDir || htmlDir === RTL) && (language !== LANGUAGES.AR && language !== LANGUAGES.HE)) {
    yield setHtmlDirLtr();
  }

  yield setCookie('gnb_locale', prepareCookieLocale(locale));
}

function* confirmLocale({ locale, country, language }) {

  const match = yield select(selectRootMatch);
  if (!locale || !language || !country) {
    const lang = pathOr('en', ['params', 'language'], match);
    const coun = pathOr('us', ['params', 'country'], match);

    return yield handleLocaleEffects({
      locale: `${lang}_${coun.toUpperCase()}`,
      country: coun,
      language: lang,
    });
  }
  return yield handleLocaleEffects({
    locale,
    country,
    language,
  });
}

export default function* watchSetLocale() {
  let task;
  while (true) {
    const action = yield take(UPDATE_LOCALE);
    if (!task) {
      task = yield confirmLocale(action);
    }
  }
}
