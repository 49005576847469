/* eslint-disable */
import { createSelector } from 'reselect';
import {
  prop,
  map,
  pick,
  compose,
  path,
  cond,
  equals,
  complement,
  concat,
  T,
  propOr,
  always,
  isNil,
  __
} from 'ramda';
import {
  selectMobileFaxContentStackData,
} from './craft';
import {
  selectQueryParameters,
} from './routing';
import {
  getCookie,
} from '../utils/globals';
import { selectSignInURL } from './config';

const deriveBackLink = compose(
  cond([
    [
      complement(isNil),
      compose(
        concat(__, '/action=goBackSmartHome'),
        decodeURIComponent,
      ),
    ],
    [T, always('')],
  ]),
  prop('callback_schema'),
);

const deriveButtonDestination = compose(
  cond([
    [complement(isNil), decodeURIComponent],
    [T, always('')],
  ]),
  prop('callback'),
);

const showSignInLink = compose(
  complement(equals('user')),
  () => getCookie('hpc3-session-type'),
);

const selectMobileFaxLinks = createSelector(
  selectQueryParameters,
  selectSignInURL,
  (queryParameters, signInURL) => {
    if (!queryParameters) {
      return {};
    }
    return {
      // signinLink: deriveBackLink(queryParameters),
      backLink: deriveBackLink(queryParameters),
      signinLink: showSignInLink() && signInURL,
      buttonDestination: deriveButtonDestination(queryParameters),
    };
  },
);

export const selectMobileFaxTestimonialData = createSelector(
  selectMobileFaxContentStackData,
  mobileFax => {
    if (!mobileFax) {
      return null;
    }
    const testimonials = compose(
      map(pick(['testimonials', 'testimonial_author'])),
      prop('landing_testimonials')
    )(mobileFax);

    return testimonials
  }
)

export const selectInAppMenuProps = createSelector(
  selectMobileFaxContentStackData,
  selectMobileFaxLinks,
  (mobileFax, { backLink }) => {
    if (!mobileFax) {
      return null;
    }
    return {
      backLink,
      text: path(['title'], mobileFax),
    }
  }
);

export const selectMobileFaxSpecialOfferData = createSelector(
  selectMobileFaxContentStackData,
  selectQueryParameters,
  selectMobileFaxLinks,
  (csData, queryParameters, mobileFaxLinks) => {
    if (!csData || !queryParameters) {
      return null;
    }
    const trialKey = prop('offer', queryParameters);
    if (!trialKey || trialKey !== '24month') {
      return null;
    }

    return {
      ...csData.landingPagePromotion,
      ...mobileFaxLinks,
    };
  },
);


export const selectMobileFaxHeroMessageData = createSelector(
  selectMobileFaxContentStackData,
  selectQueryParameters,
  selectMobileFaxLinks,
  (csData, queryParameters = {}, mobileFaxLinks) => {
    if (!csData) {
      return null;
    }
    const trialKey = propOr('trial', 'offer', queryParameters);
    const heroContent = path(['landing_page_hero_content', trialKey], csData);
    return {
      ...heroContent,
      ...mobileFaxLinks,
       // where 'trial' is the default display, the element that uses the `signinLink` says "No Thanks" and is meant as a sort of back button or opt out,
       // so reassign the derived `backLink` value to the `signinLink` key
      ...(trialKey === 'trial' && { signinLink: mobileFaxLinks.backLink })
    };
  },
);