import React from 'react';
import PropTypes from 'prop-types';
import { getStatusWithId } from './statusDictionary';
import { PrinterStatus } from './styles';
import i18n from '../../../../utils/i18n';

const UserDeviceStatus = ({
  status = 'printer-error',
  printersPage,
  id,
}) => {
  const { Icon, title } = getStatusWithId(status);
  return (
    <PrinterStatus printersPage={printersPage} data-testid={id}>
      <Icon filled />
      {i18n.t(title)}
    </PrinterStatus>
  );
};

UserDeviceStatus.defaultProps = {
  printersPage: false,
  id: '',
};

UserDeviceStatus.propTypes = {
  status: PropTypes.string.isRequired,
  /** Bool prop that defines style behaviors, essentially differentiates between landing page and myprinters page behavior */
  printersPage: PropTypes.bool,
  id: PropTypes.string,
};

export default UserDeviceStatus;
