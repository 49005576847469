import React from 'react';
import {
  Container,
  GridSeparator,
} from '../../../components/shared-components/styles';
import ManagerUsersComponent from '../../../components/ManageUsers';


const ManageUsers = () => (
  <Container>
    <GridSeparator>
      <ManagerUsersComponent />
    </GridSeparator>
  </Container>
);

export default ManageUsers;
