import React from 'react';
import { RTL } from '../../constants/i18n';
import { Helmet } from 'react-helmet';
import { getHtmlDir } from '../../utils/globals';
import { useSelector } from '../../hooks';
import { selectSMBNoSetupContentStackData } from '../../selectors/craft';
import styled from 'styled-components';
import NavBar from '../revised/Global/Navbar';
import Header from './Header/Header';
import Setup from './Setup/Setup';
import Pairing from './Pairing/Pairing';
import Support from './Support/Support';
import Footer from '../revised/Global/Footer';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SMBNoSetup = (props) => {
  const { geoCountry } = props;
  const direction = getHtmlDir();
  const isRTL = direction === RTL.toLowerCase();
  const contentStackContent = useSelector(selectSMBNoSetupContentStackData)?.data?.entry;

  if (contentStackContent) {
    const {
      title,
      unsupportedos_header,
      setup_text,
      setup_image,
      support_link,
      pairing_code_link,
      pairing_code_image,
      hp_support_image,
    } = contentStackContent;

    return (
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <NavBar geoCountry={geoCountry} />
        <Container>
          <Header text={unsupportedos_header} isRTL={isRTL} />
          <Setup text={setup_text} image={setup_image?.url} altText={setup_image?.title} isRTL={isRTL} />
          <Pairing
            title={pairing_code_image?.title}
            image={pairing_code_image?.url}
            URL={pairing_code_link}
            isRTL={isRTL}
          />
          <Support
            title={hp_support_image?.title}
            image={hp_support_image?.url}
            URL={support_link}
            isRTL={isRTL}
          />
        </Container>
        <Footer />
      </div>
    );
  }
  return null;
};

export default SMBNoSetup;
