require('react-dom');
import React, { useEffect, useState } from 'react';
import Carousel from 'nuka-carousel';
import { isEmpty, isNil } from 'ramda';
import { DirectionProvider } from '@veneer/core';
import {
  Container,
  Header,
  Title,
  SubTitle,
  Content,
  CarouselContainer,
  Footer,
  Graphic,
  FooterButton,
  FooterButtonText,
  StepperContainer,
  IconChevronLeftContainer,
  IconChevronRightContainer,
  CarouselItems
} from './styles/SupportStyles';

import { IconCheckmark, Stepper } from '@veneer/core';
import { selectPrinterConnetionErrorPageContentStackData, selectContentStackDataByResource } from '../../selectors/craft';
import { selectQueryParameters } from '../../selectors/routing';
import { useSelector } from '../../hooks';
import { Redirect, Route, Switch } from 'react-router';
import { getHtmlDir } from '../../utils/globals';
import { RTL_LANGUAGES, RTL, LTR } from '../../constants/i18n';
import { PRINTER_CONNECTION_PAGE } from '../../constants/contentStackResources';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';

const isCarouselDragging = false;

const renderButtons = ({
  change_printer_button_text,
  continue_button_text,
  queryParams,
  currentIndex,
  stepsLength,
  isListAvailable,
  platform
}) => {
  const { primary = 'hpsmart://reset/continue', secondary = 'hpsmart://reset/back' } = queryParams;

  return (
    <div>
      {
        <FooterButton
          appearance="secondary"
          small
          onClick={async () => {
            isListAvailable
              ? window.history.back()
              : window.location.assign(`/support/generic?platform=${platform}`);
          }}
          id="change-printer-button"
        >
          <FooterButtonText
            dangerouslySetInnerHTML={{ __html: change_printer_button_text }}
          />
        </FooterButton>
      }
      <FooterButton
        small
        onClick={async () => {
          window.location.assign(primary);
        }}
        id="continue-button"
        disabled={currentIndex === stepsLength - 1 ? false : true}
      >
        <FooterButtonText
            dangerouslySetInnerHTML={{ __html: continue_button_text }}
          />
      </FooterButton>
    </div>
  );
};

const Support = (props) => {
  const queryParams = useSelector(selectQueryParameters);
  const { base64DecodedData, deviceModels } = props;
  const { deviceid, platform = 'windows', showChangeButton } = queryParams;
  const isListAvailable = showChangeButton === 'true' ? true : false;
  const breakpoint = 640;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > breakpoint);
  const [stepItems, setStepItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [wasContentUpdated, setWasContentUpdated] = useState(false);
  const direction = getHtmlDir();
  const { csData, isFetching } = useSelector(selectPrinterConnetionErrorPageContentStackData) || {};

  const resizeLayout = () => {
    return setIsDesktop(window.innerWidth > breakpoint);
  };

  useEffect(() => {
    let steps = [];
    if (!isEmpty(csData) && !isNil(csData)) {
      steps = csData.support_steps.map((step, index) => {
        const setIndex = index + 1;
        return {
          status: index === 0 ? 'complete' : 'incomplete',
          step: index === 0 ? <IconCheckmark filled /> : setIndex.toString()
        };
      });

      const language = window.location.pathname.split('/')[2];
      const country = window.location.pathname.split('/')[1];
      const treatedPathname = window.location.pathname.replace(`/${country}/${language}`, '').replace('?', '').split('?')[0];
      const entryId = PRINTER_CONNECTION_PAGE("", treatedPathname)[0].support_pages

      if(csData.uid === entryId) setWasContentUpdated(true);
    }
    setStepItems(steps);
  }, [csData]);

  useEffect(() => {
    window.addEventListener('resize', resizeLayout);
  }, [window]);

  if (base64DecodedData) {
    if (deviceModels.length === 1) {
      return <Redirect to={`/support/printer-connection/${deviceModels[0]}`} />;
    }

    if (deviceModels.length > 1) {
      return (
        <Redirect
          to={{
            pathname: `/support/list?deviceid=${deviceid}`
          }}
        />
      );
    }

    return <Redirect to={`/support/generic?platform=${platform}`} />;
  }

  const handleOnChange = (event, index) => {
    const newStepItems = [...stepItems];
    const newSelectedItem = newStepItems[index];
    const oldSelectedItem = newStepItems[currentIndex];
    newSelectedItem.status = 'current';
    oldSelectedItem.status = 'complete';
    oldSelectedItem.step = <IconCheckmark filled />;

    setCurrentIndex(index);
    setStepItems(newStepItems);
    setSlideIndex(index);
  };

  const handleOnChangeChevronRight = (nextSlide) => {
    const newStepItems = [...stepItems];
    const newSelectedItem = newStepItems[currentIndex + 1];
    const oldSelectedItem = newStepItems[currentIndex];
    newSelectedItem.status = 'current';
    oldSelectedItem.status = 'complete';
    oldSelectedItem.step = <IconCheckmark filled />;
    setCurrentIndex(currentIndex + 1);
    setStepItems(newStepItems);
    nextSlide();
  };

  const handleOnChangeChevronLeft = (prevSlide) => {
    const newStepItems = [...stepItems];
    const newSelectedItem = newStepItems[currentIndex - 1];
    const oldSelectedItem = newStepItems[currentIndex];
    newSelectedItem.status = 'current';
    oldSelectedItem.status = 'complete';
    oldSelectedItem.step = <IconCheckmark filled />;
    setCurrentIndex(currentIndex - 1);
    setStepItems(newStepItems);
    prevSlide();
  };

  if (!isEmpty(csData) && !isNil(csData)) {
    const {
      support_headline = '',
      support_subhead = '',
      change_printer_button_text,
      support_steps = [],
      continue_button_text
    } = csData || {};
    const stepsLength = support_steps && support_steps.length;
    const language = window.location.pathname.split('/')[2];
    const isRTLSupport = RTL_LANGUAGES.includes(language);
  
    return wasContentUpdated? (
      <Switch>
        <Route path="/:sku">
          <DirectionProvider direction={isRTLSupport ? RTL : LTR}>
            <Container>
              <Header>
                <Title
                  dangerouslySetInnerHTML={{ __html: support_headline }}
                  direction={direction}
                />
                <SubTitle
                  dangerouslySetInnerHTML={{ __html: support_subhead }}
                  direction={direction}
                />
              </Header>
              {isDesktop && (
                <StepperContainer>
                  <Stepper interactive onChange={handleOnChange} steps={stepItems} />
                </StepperContainer>
              )}
              {!isDesktop && (
                <Content
                  dangerouslySetInnerHTML={{
                    __html: support_steps[currentIndex].supportText
                  }}
                  direction={direction}
                />
              )}
              <CarouselContainer>
                <Carousel
                  id="supportCarousel"
                  slideIndex={slideIndex}
                  afterSlide={(slideIndex) => {
                    setSlideIndex(slideIndex);
                  }}
                  swiping={isCarouselDragging}
                  dragging={isCarouselDragging}
                  getControlsContainerStyles={(key) => {
                    switch (key) {
                      case 'BottomCenter':
                        return {
                          display: 'none'
                        };
                      default:
                        // will apply all other keys
                        return {};
                    }
                  }}
                  renderCenterLeftControls={({
                    nextSlide,
                    previousSlide,
                    currentSlide,
                    slideCount
                  }) =>
                    isRTLSupport ? (
                      <IconChevronRightContainer
                      id="chev-right"
                      color="colorHpBlue6"
                      size={36}
                      filled
                      onClick={() =>
                        !(currentSlide == slideCount - 1) && handleOnChangeChevronRight(nextSlide)
                      }
                        disabled={currentSlide == slideCount - 1}
                        />
                      ) : (
                        <IconChevronLeftContainer
                        id="chev-left"
                        color="colorHpBlue6"
                        size={36}
                        filled
                        onClick={() =>
                          !(currentSlide === 0) && handleOnChangeChevronLeft(previousSlide)
                        }
                        disabled={currentSlide === 0}
                        />
                      )
                    }
                  renderCenterRightControls={({
                    nextSlide,
                    previousSlide,
                    currentSlide,
                    slideCount
                  }) =>
                    isRTLSupport ? (
                      <IconChevronLeftContainer
                        id="chev-left"
                        color="colorHpBlue6"
                        size={36}
                        filled
                        onClick={() =>
                          !(currentSlide === 0) && handleOnChangeChevronLeft(previousSlide)
                        }
                        disabled={currentSlide === 0}
                      />
                    ) : (
                      <IconChevronRightContainer
                        id="chev-right"
                        color="colorHpBlue6"
                        size={36}
                        filled
                        onClick={() =>
                          !(currentSlide == slideCount - 1) && handleOnChangeChevronRight(nextSlide)
                        }
                        disabled={currentSlide == slideCount - 1}
                      />
                    )
                  }
                >
                  {support_steps.map((step, id) => {
                    const { setup_step } = step;
                    const { support_graphic, support_graphic_title, support_text } = setup_step;
                    const { url } = support_graphic;

                    return (
                      <CarouselItems key={id}>
                        <Graphic
                          id="graphic"
                          src={url}
                          alt={support_graphic_title}
                          direction={direction}
                          />
                        <Content
                          dangerouslySetInnerHTML={{ __html: support_text }}
                          direction={direction}
                          />
                      </CarouselItems>
                    );
                  })}
                </Carousel>
              </CarouselContainer>
              <Footer>
                {renderButtons({
                  change_printer_button_text,
                  continue_button_text,
                  queryParams,
                  currentIndex,
                  stepsLength,
                  isListAvailable,
                  platform
                })}
              </Footer>
            </Container>
          </DirectionProvider>
        </Route>
      </Switch>
    ) : <LoaderBlock />;
  }
  {
    return !isFetching ? (
      <Redirect to={`/support/generic?platform=${platform}`} />
    ) : (
      <LoaderBlock />
    );
  }
};

export default Support;
