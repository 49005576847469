import React from 'react';
import PropTypes from 'prop-types';
import DeviceCartridgeBar from './CartridgeBar';
import DeviceLarge from './Plotter';
import DevicePaperBar from './PaperBar';
import DevicePaperGauge from './PaperGauge';
import { SupplyNames } from './supplyNames';

const DeviceSupply = ({ supply }) => {
  if (supply.supplyName === SupplyNames.LARGE) {
    return (
      <DeviceLarge level={supply.level} />
    );
  }
  if (supply.supplyName === SupplyNames.PAGES) {
    return (
      <DevicePaperGauge level={supply.level} maxLevel={supply.maxLevel} />
    );
  }
  if (supply.supplyName === SupplyNames.PAPER) {
    return (
      <DevicePaperBar level={supply.level} />
    );
  }
  return (
    <DeviceCartridgeBar
      type={supply.supplyName}
      level={supply.level}
      state={supply.levelState}
      extra={supply.extra}
    />
  );
};

DeviceSupply.propTypes = {
  supply: PropTypes.shape({
    supplyName: PropTypes.oneOf(Object.values(SupplyNames)),
    level: PropTypes.number,
    levelState: PropTypes.oneOf(['OK', 'LOW', 'VERY_LOW']),
    deviceAssessment: PropTypes.string,
    maxLevel: PropTypes.number,
    extra: PropTypes.string,
  }).isRequired,
};

export default DeviceSupply;
