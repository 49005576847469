import React, { useState, useEffect } from 'react';
import Carousel from '../wrapped/Carousel';
import styled from 'styled-components';
import PagingDots from '../shared-components/molecules/PagingDots';
import { ArrowButtonLeft, ArrowButtonRight } from '../shared-components/atoms/ArrowButtons';
import { getHtmlDir } from '../../utils/globals';
import { RTL } from '../../constants/i18n';
import { RIGHT, LEFT } from '../../constants/common';

const Features = styled.div`
  background: url(${props => props.smallBackground}) no-repeat;
  background-size: auto 26rem;
  margin-bottom: 6rem;
  @media (max-width: ${props => props.theme.large}) {
    margin-bottom: 0;
  }
  @media screen and (min-width:${props => props.theme.small}) {
    background: url(${props => props.mediumBackground}) no-repeat;
    background-size: auto 100%;
    min-height: 39.125rem;
  }
  @media screen and (min-width:${props => props.theme.medium}) {
    background: url(${props => props.largeBackground}) no-repeat;
  }
  @media screen and (min-width:${props => props.theme.large}) {
    min-height: 52rem;
  }
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  ul, ol { padding-left: 0; } 
  .slider {
    padding-bottom: 2.625rem;
  }
`;
const FeaturesMobile = styled.div`
  margin: ${props => props.theme.standardSectionMargins};
  margin-bottom: 0;
  padding-top: 2.5rem;
  width: 100%;
  .slider-control-centerleft { 
    top: 8.625rem !important;
    left: 1.375rem !important;
  }
  .slider-control-centerright { 
    top: 8.625rem !important;
    right: 1.375rem !important;
  }
  p { margin-top: 0 }
`;
const FeaturesWidescreen = styled.div`
  display: flex;
  margin: 0 auto;
  padding-top: 5.5rem;
  max-width: 46rem;
  @media screen and (min-width:${props => props.theme.medium}) {
    max-width: 48rem;
  }
  @media screen and (min-width:${props => props.theme.large}) {
    padding-top: 7.25rem;
    max-width: 70rem;
  }
`;
const CarouselContainer = styled.div`
  height: auto;
  width: 100%;

  & .slider-list {
    display: flex;
    align-items: center;
  }

  
  & .slider-control-bottomcenter {
    bottom: auto !important;
    position: relative !important;
  }

`;
const StyledCarousel = styled(Carousel)`
  && {
    max-width: 100%;
    margin: 0 auto;
    .carousel.carousel-slider {
      height: 26rem;
      @media (min-width: 64rem) {
        height: 28rem;
      }
    }
    @media (min-width: 64rem) {
      max-width: 80%;
    }
  }
`;
const Summary = styled.div`
  color: white;
  margin: 0 auto;
  max-width: 12rem;
  @media screen and (min-width:${props => props.theme.small}) {
    margin: 0;
    max-width: 100%;
  }
  div { 
    width: 2.875rem;
    margin: 0 auto;
    @media screen and (min-width:${props => props.theme.small}) {
      margin: ${ props => props.direction === RTL ? `0 0 0 auto` : `0 auto 0 0` };
    }
    @media screen and (min-width:${props => props.theme.large}) {
      width: 3.875rem;
    }
  }
  img {
    width: 100%;
  }
  h2 { 
    color: white;
    margin: 0.375rem 0 1rem;
    @media screen and (max-width:${props => props.theme.small}) {
      font-size: 1.375rem;
      line-height: 1.25;
    }
    @media screen and (min-width:${props => props.theme.small}) {
      margin: 0.625rem 0 2.5rem;
    }
    @media screen and (min-width:${props => props.theme.large}) {
      margin: 0.875rem 0 3.8125rem;
    }
  }
`;
const FeatureIllustration = styled.div`
  flex: 1 0 auto;
  width: 16.5rem;
  ${ props => props.direction === RTL ? `margin-left: 4rem` : `margin-right: 4rem` };
  @media screen and (min-width:${props => props.theme.large}) {
    width: 25.5rem;
    ${ props => props.direction === RTL ? `margin-left: 5rem` : `margin-right: 5rem` };
  }
  position: relative;
  img {
    z-index: 2;
    width: 100%;
    top: 4.75rem;
    @media screen and (min-width:${props => props.theme.large}) {
      top: 0;
    }
    position: absolute;
    left: 0;
  }
`;
const FeatureDetails = styled.div`
  flex: 1 1 auto;
  width: 100%;
  color: white;
  text-align: ${ props => props.direction === RTL ? RIGHT : LEFT };
  max-width: 30rem;
  @media screen and (min-width:${props => props.theme.large}) {
    max-width: 36rem;
  }
  margin-bottom: 4rem;
  ul {
    font-size: 1.25rem;
    @media screen and (min-width:${props => props.theme.large}) {
      font-size: 1.5rem;
    }
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 0;
    * + * {
      margin-top: 1.25rem;
      @media screen and (min-width:${props => props.theme.large}) {
        margin-top: 2.625rem;
      }
    }
  }
  a {
    color: white;
    text-decoration: none;
    align-items: flex-start;
  }
`;
const ImageWrap = styled.div`
  margin: 0 auto 1rem;
  width: 9rem;
  height: 100%;
  @media (min-width: ${props => props.theme.tiny}) {
    margin-bottom: 1rem;
    flex: 1 1 auto;
  }
  img {
    width: 100%;
  }
`;
const DescriptionWrap = styled.p`
  padding: 0 1.25rem 1rem
`;
const FeatureNumber = styled.div`
  flex: 0 0 3.125rem;
  ${ props => props.direction === RTL ? `margin-left: 1.1875rem` : `margin-right: 1.1875rem` };
  font-size: 1.5rem;
  display: flex;
  color: ${props => props.numberColor};
  height: 3.125rem;
  width: 3.125rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid white;
  background-color: ${props => props.fill};
  @media (min-width: 1200px) {
    flex: 0 0 4.375rem;
    ${ props => props.direction === RTL ? `margin-left: 2.25rem` : `margin-right: 2.25rem` };
    font-size: 2.125rem;
    height: 4.375rem;
    width: 4.375rem;
  }
`;
const FeatureDescription = styled.a`
  display: flex;
  &:hover { cursor: pointer; }
`;
export default function LandingPageFeatures(props) {
  const firstFeature = props.features[0].feature?.feature_image?.url;
  const breakpoint = 768;
  const direction = getHtmlDir();
  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);
  const [featureImgUrl, setFeatureImgUrl] = useState(firstFeature);
  const [activeFeature, setActiveFeature] = useState(0);

  const resizeLayout = () => {
    if (isMobile !== window.innerWidth < breakpoint) {
      return setIsMobile(window.innerWidth < breakpoint);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeLayout);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 610);
  }, [isMobile]);

  let smallBackground;
  let mediumBackground;
  let largeBackground;
  props.featuresMeta.map(({feature_meta}) => (
    smallBackground = feature_meta.feature_background_small?.url,
    mediumBackground = feature_meta.feature_background_medium?.url,
    largeBackground = feature_meta.feature_background_large?.url
  ));

  function slidedFn(arg) {
    props.tracking(`mobilefax_feature_slide_${arg}`);
  }

  return (
    <Features
      smallBackground={smallBackground}
      mediumBackground={mediumBackground}
      largeBackground={largeBackground}
    >
      {isMobile
        && (
        <FeaturesMobile>
          <Summary>
            {props.featuresMeta.map(({ feature_meta }, e) => (
              <React.Fragment key={e}>
                <div>
                  <img src={feature_meta.feature_icon?.url} alt="" />
                </div>
                <h2>{feature_meta.feature_headline_text}</h2>
              </React.Fragment>
            ))}
          </Summary>
          <CarouselContainer>
            <StyledCarousel
              id="featureCarousel"
              afterSlide={slidedFn}
              heightMode="max"
              transitionMode="fade"
              renderCenterLeftControls={({ previousSlide, currentSlide }) => (
                <ArrowButtonLeft
                  id="featureLeftArrow"
                  disabled={currentSlide === 0}
                  onClick={previousSlide}
                >
                  <img src="/assets/landing-page/arrow_left.svg" alt="" />
                </ArrowButtonLeft>
              )}
              renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => (
                <ArrowButtonRight
                  id="featureRightArrow"
                  disabled={currentSlide === (slideCount - 1)}
                  className={slideCount}
                  onClick={nextSlide}
                >
                  <img src="/assets/landing-page/arrow_right.svg" alt="" />
                </ArrowButtonRight>
              )}
              renderBottomCenterControls={bottomControlProps => <PagingDots prefix="feature" {...bottomControlProps} />}
              autoplay
              autoplayInterval={10000}
              pauseOnHover
              wrapAround
            >
              {props.features.map(({ feature }, i) => (
                <div key={feature.feature_description_text}>
                  <ImageWrap>
                    <img src={feature.feature_image.url} alt="" />
                  </ImageWrap>
                  <DescriptionWrap>
                    {feature.feature_description_text}
                    </DescriptionWrap>
                </div>
              ))}
            </StyledCarousel>
          </CarouselContainer>
        </FeaturesMobile>
        )}
      {!isMobile
         && (
        <FeaturesWidescreen>
          <FeatureIllustration direction={direction}>
            <img src={featureImgUrl} />
          </FeatureIllustration>
          <FeatureDetails direction={direction}>
            <Summary direction={direction}>
              <div>
                <img src={props.featuresMeta[0].feature_meta.feature_icon?.url} />
              </div>
              <h2>{props.featuresMeta[0].feature_meta.feature_headline_text}</h2>
            </Summary>
            <ul>
              {props.features.map(({feature}, k) => (
                <li key={k}>
                  <FeatureDescription
                    onClick={() => (setFeatureImgUrl(feature.feature_image?.url), setActiveFeature(k))}
                    id={`mobilefax_feature_number_${k}`}
                    data-tracking={`: ${feature.feature_image.title}`}
                    tabIndex="0"
                  >
                    <FeatureNumber
                      id={`mobilefax_feature_${k}`}
                      active={activeFeature == k}
                      fill={activeFeature == k ? '#FFF' : 'transparent'}
                      numberColor={activeFeature == k ? '#0171AD' : '#FFF'}
                      direction={direction}
                    >
                      {k + 1}
                    </FeatureNumber>
                    {feature.feature_description_text}
                  </FeatureDescription>
                </li>
              ))}
            </ul>
          </FeatureDetails>
        </FeaturesWidescreen>
        )}
    </Features>
  );
}