export const HOME_PAGE = {
  ACCOUNT_PROFILE: 'SEE_ACCOUNT_PROFILE',
  SMART_DASHBOARD: 'SMART_DASHBOARD',
};

export const PATHS = {
  ACCOUNT_DETAILS: {
    NOTIFICATIONS_SETTINGS: '/account-details/notification-settings',
    PRINT_PLANS: '/account-details/(shipping-information|billing-information)',
    PRIVACY_SETTINGS: '/account-details/privacy-settings',
    PROFILE: '/account-details/profile',
    VIEW_NOTIFICATIONS: '/account-details/view-notifications',
  },
  HELP_CENTER: '/help/:topic?/:subtopic?',
  HOME: '/home',
  MANAGE_USERS: '/manage-users',
  MY_PRINTERS: '/my-printers',
  ONBOARDING: '/loggedin',
  PRINT_PLANS: '/print_plans',
  SERVICES: {
    PRINT_ON_THE_GO: '/services/print-on-the-go/:component?',
    SMART_SECURITY: '/services/smart-security/:deviceID?',
    SUMMARY: '/services/summary',
  },
  SIGNIN: '/signin',
  SIGNOUT: '/signout',
};

export const GATEWAY_PATHS_ENABLED_FOR_BASE_USERS = [
  PATHS.ACCOUNT_DETAILS.NOTIFICATIONS_SETTINGS,
  PATHS.ACCOUNT_DETAILS.PRIVACY_SETTINGS,
  PATHS.ACCOUNT_DETAILS.VIEW_NOTIFICATIONS,
  PATHS.ONBOARDING,
  PATHS.SIGNIN,
];

export const SERVICES = {
  PRINT_ON_THE_GO: 'printOnTheGo',
  SMART_SECURITY: 'smartSecurity',
  KEY_EPRINT: 'ePrint',
  STATE_PUBLISHED: 'Published',
  HPPLUS_PROGRAM_LEVEL: 'HpPlus',
};

export const NOTIFICATION_ACTIONS_LINK_MAP = {
  // Home
  ucde: '',
  // HP+ Print Plans
  print_plans: '/print_plans',
  print_plans_my_account: '/print_plans/my_account',
  print_plans_print_history: '/print_plans/print_history',
  print_plans_ink_shipment: '/print_plans/ink_shipment',
  print_plans_account_history: '/print_plans/account_history',
  my_printers: '/my-printers',
  manage_users: '/manage-users',
  // Manage HP Benefits
  services_summary: '/services/summary',
  services_print_on_the_go: '/services/print-on-the-go',
  services_smart_security: '/services/smart-security',
  // My Account
  account_details_billing_information: '/account-details/billing-information',
  account_details_shipping_information: '/account-details/shipping-information',
  account_details_profile: '/account-details/profile',
  account_details_privacy_settings: '/account-details/privacy-settings',
  account_details_notification_settings: '/account-details/notification-settings',
  // Help Center
  help_about_hp_smart: '/help/about-hp-smart',
  help_printer_and_connection_information: '/help/printer-and-connection-information',
  help_printing_and_scanning: '/help/printing-and-scanning',
  help_additional_help_and_support: '/help/additional-help-and-support',
};

export const BROWSER_TYPE = {
  CHROME: 'chrome',
  EDGE : 'edge',
  EDG : 'edg/'
}
/*
  * Definitions over this doc: https://emailregex.com/
  */
export const EMAIL_REGEX = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line

export const INVITES_MAX_LENGTH = 50;

//Slider Badge
export const BADGE_ID = "BaiduBadge";