import React from 'react';
import { useSelector } from 'react-redux';
import { JarvisBannerNotifications } from '@jarvis/react-atlas-notifications';
import Benefits from '../../../components/Benefits';
import DashboardUserPanel from '../../../components/UserPanel';
import { HpPlusBackground } from './styles';
import DashboardUserDevices from '../../../components/UserDevices';
import ForestFriendlyBanner from '../../../components/ForestFriendlyBanner';
import PrinterStatus from '../../../components/PrinterStatus';
import ServicesCard from '../../../components/Services/ServiceCard';
import ReferAFriend from '../../../components/ReferAFriend';
import {
  Container,
  Grid,
  GridSeparator,
  MainContent,
  NotificationContent,
  SideContent,
  Title,
} from '../../../components/shared-components/styles';
import ucdePortalElementsSelector from '../../../store/modules/portalElements/selectors';
import { getStratusAccessToken } from '../../../utils/auth';
import { getExchangeToken } from '../../../api/HPCSession';
import { selectNotificationProps } from '../../../store/modules/notification/selector';
import { selectRootMatch } from '../../../../../selectors/routing';
import FeatureFlag from '../../../components/FeatureFlag';
import {
  onClickActionFunction,
  shouldShowAction,
} from '../../../components/shared-components/NotificationActionComponent';
import { getCookie } from '../../../../../utils/globals';
import { USER_TYPE } from '../../../../../constants/cookieNames';

const Home = () => {
  const ucdePortalElements = useSelector(ucdePortalElementsSelector());
  const {
    country,
    language,
    baseUrl,
    currentStack,
  } = useSelector(selectNotificationProps);
  const rootMatch = useSelector(selectRootMatch);

  const authProvider = {
    getAccessToken: async forceRefresh => {
      const token = getStratusAccessToken();
      if (forceRefresh) {
        await getExchangeToken(token);
        return getStratusAccessToken();
      }
      return token;
    },
  };

  if (ucdePortalElements.loading) {
    return null;
  }

  if (ucdePortalElements.home && !ucdePortalElements.home.cards) {
    return (
      <Container>
        <Title> Marketing page placeholder</Title>
      </Container>
    );
  }

  const { cards } = ucdePortalElements.home;
  const isHpPlus = getCookie(USER_TYPE) === 'HP+';
  const hpPlusAssets = ucdePortalElements.info.bannerLogoAssets;
  const { href: hpMosaicLogo } = hpPlusAssets.find(asset => asset.rel === 'logo/svg');
  const { href: hpMosaicBackground } = hpPlusAssets.find(asset => asset.rel === 'banner/png/desktop');

  const handleBannerNotificationsError = error => {
    // TODO: log errors the right way will be addressed by https://jira.cso-hp.com/browse/SDASH-410
    if (currentStack === 'dev') { // throwing errors to console in dev/local
      console.error('Error retrieving banner notifications');
      console.error({ ...error });
    }
  };

  return (
    <Container>
      <HpPlusBackground
        isHpPlus={isHpPlus}
        hpMosaicBackground={hpMosaicBackground}
      >
        <GridSeparator>
          <DashboardUserPanel hpMosaicLogo={hpMosaicLogo} />
        </GridSeparator>

        <Grid>
          <FeatureFlag featureFlagKey="ff_ucde_portal_notifications">
            <NotificationContent>
              <JarvisBannerNotifications
                country={country.toUpperCase()}
                language={language}
                authProvider={authProvider}
                notificationCount={1}
                showOnData
                baseUrl={baseUrl}
                shouldShowAction={shouldShowAction()}
                onClickActionFunction={onClickActionFunction()}
                showDebug={currentStack === 'dev'}
                onError={handleBannerNotificationsError}
              />
            </NotificationContent>
          </FeatureFlag>
        </Grid>
      </HpPlusBackground>

      <Grid>
        <MainContent>
          {cards.status && <PrinterStatus />}
        </MainContent>

        <SideContent>
          {cards.eco && <ForestFriendlyBanner />}
        </SideContent>
      </Grid>

      <GridSeparator>
        {cards.benefits && <Benefits match={rootMatch} />}
      </GridSeparator>

      <Grid>
        <MainContent>
          {
            cards.services
            && (
              <FeatureFlag featureFlagKey="ff_ucde_portal_services">
                <ServicesCard />
              </FeatureFlag>
            )
          }
          {cards.myPrinters && <DashboardUserDevices />}
        </MainContent>

        <SideContent>
          {cards.referAFriend && <ReferAFriend />}
        </SideContent>
      </Grid>
    </Container>
  );
};

export default Home;
