import axios from 'axios';
import { pathEq } from 'ramda';
import { getCookie } from '../lib/manageCookies';
import CraftApiModel from '../models/CraftApiModel';
import Config from '../config/Config';
import ActionTypes from '../constants/ActionTypes';
import { getApiPathWithLocale } from '../lib/urlUtils';
import { getHistory } from '../index';
import { YETI_CLAIM_ERROR } from '../constants/errorTypes';
import { PRINTERS_COMMON_RESOURCE_NAME } from '../constants/contentStackResources';
import { getContentStackData } from '../services/craft';
/**
 * This function uses axios to get printer list.
 *
 * Since axios returns promises, we can dispatch our start event and
 * update it to complete or complete with error whe the promise returns.
 *
 * @returns {function(*)}
 */
export function getPrinterCommon(currentLocale = '') {
  const start = () => ({
    type: ActionTypes.START_PRINTERCOMMON_FETCH,
  });
  const complete = printerCommon => ({
    type: ActionTypes.COMPLETE_PRINTERCOMMON_FETCH,
    printerCommon,
  });
  const error = err => ({
    type: ActionTypes.COMPLETE_PRINTERCOMMON_WITH_ERROR,
    err,
  });
  return dispatch => new Promise((resolve, reject) => {
    dispatch(start());
    getContentStackData(PRINTERS_COMMON_RESOURCE_NAME, currentLocale)
      .then(res => {
        const pinfo = (res.data && res.data.entries) ? res.data.entries.length > 0 ? res.data.entries[0] : {} : {};
        dispatch(complete(pinfo));
        return resolve(pinfo);
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
        }
        dispatch(error(err));
        reject(err);
      });
  });
}

function validateClaimNumber(claim) {
  if (claim.length === 0) {
    return false;
  }
  if (claim.length > 16) {
    return false;
  }
  return !(/[^A-Z0-9a-z]/.test(claim));
}

export function claimPrinter(claimNumber, callback) {
  claimNumber = claimNumber.replace(/\s/g, '').trim();
  const start = () => ({
    type: ActionTypes.START_CLAIM_PRINTER,
  });
  const complete = printer => ({
    type: ActionTypes.COMPLETE_CLAIM_PRINTER,
    printer,
  });
  const error = (err, errorDetail) => ({
    type: ActionTypes.CLAIM_PRINTER_ERROR,
    err,
    errorDetail,
  });
  return (dispatch, getState) => {
    dispatch(start());
    return new Promise((resolve, reject) => {
      const myPrintersCS = getState().myPrinters.data.csData;
      if (!validateClaimNumber(claimNumber)) {
        dispatch(error(myPrintersCS.add_printer.claim_code_error_invalid_format));
        reject(myPrintersCS.add_printer.claim_code_error_invalid_format);
        return;
      }
      const config = new Config();
      const csrfToken = getCookie('hpc3-csrf-token');
      const postData = {
        claimNumber,
      };
      axios.post(`${config.Get('HPC_GATEWAY_URL')}/printers/claim`, postData, {
        withCredentials: true,
        headers: {
          'x-hpc3-csrf-token': csrfToken,
        },
      }).then(result => {
        if (!result.data.hasOwnProperty('printerId') || result.data.error) {
          if (result.data.error) {
            // Logger.Log("Claim error: " + ((result.data.message) ? result.data.message : "server error"));
          }
          dispatch(error(myPrintersCS.add_printer.claim_code_error_invalid_format));
          reject(myPrintersCS.add_printer.claim_code_error_invalid_format);
          return;
        }
        dispatch(complete(result.data));
        callback(result.data);
        return resolve(result.data);
      }).catch(err => {
        if (err.response && err.response.status) {
          if (err.response.status === 403) {
            dispatch({ type: ActionTypes.REDIRECT_TO_SIGNOUT });
            return;
          }
          if (err.response.status === 409) {
            dispatch(error(myPrintersCS.add_printer.claim_code_error));
            return reject(myPrintersCS.add_printer.claim_code_error);
          }
          if (err.response.status === 423) {
            dispatch(error(myPrintersCS.add_printer.claim_code_error_already_claimed));
            return reject(myPrintersCS.add_printer.claim_code_error_already_claimed);
          }
          if (err.response.status === 400 || err.response.status === 404) {
            // Yeti Manual claim error
            if (pathEq(['response', 'data', 'error_id'], YETI_CLAIM_ERROR, err)) {
              dispatch(error('', YETI_CLAIM_ERROR));
              return;
            }
            dispatch(error(`${myPrintersCS.add_printer.claim_code_error_invalid_format}.`));
            return reject(`${myPrintersCS.add_printer.claim_code_error_invalid_format}.`);
          }
          if (err.response.status === 401) {
            // 401 ERRORS SHOULD CAUSE A RETURN TO LOGIN
            const newPath = '/signin'; // "/signin"
            const history = getHistory();
            history.push(newPath);
            return;
          }
        }
        dispatch(error(err.message));
        return reject(err.message);
      });
    });
  };
}

export function clearClaimError() {
  return {
    type: ActionTypes.CLEAR_CLAIM_ERROR,
  };
}
