import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Card,
} from '@veneer/core';

import { pathOr } from 'ramda';
import StatusItem from './StatusItem';
import UsageStatus from './UsageStatus';
import {
  getAllDevicesStatus,
  getDevicesTelemetry,
} from '../../api/UCDEGateway';
import { makePathRelative } from '../../../../utils/routing';
import { selectRootMatch } from '../../../../selectors/routing';
import i18n from '../../utils/i18n';
import Loader from '../shared-components/Loader';
import { CardHeaderStatus } from '../shared-components/styles';
import {
  Container,
  Content,
  ContentStatus,
  ContentUsage,
  LoaderContent,
  Refresh,
} from './styles';
import FeatureFlag from '../FeatureFlag';
import ImageHpPlus from '../../assets/img/hp-plus.svg';
import ImagePrintPhoto from '../../assets/img/print-photo.svg';
import ImageSmartSecurity from '../../assets/img/smart-security.svg';
import selectUcdePortalElements from '../../store/modules/portalElements/selectors';
import { selectFeatureFlagEnabled } from '../../../../selectors/config';
import { SERVICES } from '../../utils/constants';
import { serviceEnabled } from '../../utils/portalElements';
import {
  hpPlusStatus,
  printerStatus,
  smartSecurityStatus,
} from './status';
import ServerError from '../shared-components/ServerError';
import { selectUserTenantId } from '../../store/modules/ucdeUser/selectors';

const PrinterStatus = () => {
  const [statuses, setStatuses] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const match = useSelector(selectRootMatch);

  const [usageData, setUsageData] = useState({});
  const [isLoadingUsage, setIsLoadingUsage] = useState(true);
  const [usageApiError, setUsageApiError] = useState(false);

  const relPath = makePathRelative(match);

  const ucdePortalElements = useSelector(selectUcdePortalElements());

  const usageEnabledKey = 'ff_ucde_portal_usage_card';
  const usageEnabled = useSelector(state => selectFeatureFlagEnabled(state, usageEnabledKey));

  const accountId = useSelector(selectUserTenantId);

  async function loadStatus() {
    setIsLoading(true);

    try {
      setApiError(false);
      const responseStatuses = await getAllDevicesStatus();

      setStatuses(responseStatuses);
    } catch (error) {
      setApiError(true);
    }

    setIsLoading(false);
  }

  async function loadUsage() {
    setIsLoadingUsage(true);

    try {
      setUsageApiError(false);
      const response = await getDevicesTelemetry(accountId);

      setUsageData(response);
    } catch (error) {
      setUsageApiError(true);
    }

    setIsLoadingUsage(false);
  }

  const loadAll = () => {
    loadStatus();

    if (usageEnabled) {
      loadUsage();
    }
  };

  useEffect(loadAll, []);

  const buildStatusItem = () => {
    let hpPlusDetails = hpPlusStatus.UNKNOWN;
    let printersDetails = printerStatus.UNKNOWN;
    let smartSecurityDetails = smartSecurityStatus.UNKNOWN;
    let pagesPrinted = 0;

    if (!isLoading && !apiError) {
      const { deviceConnectivityHealth, deviceStatusHealth } = statuses;
      const hpPlusCurrentState = deviceConnectivityHealth.status || deviceConnectivityHealth.state;
      const printersCurrentState = deviceStatusHealth.state;

      hpPlusDetails = pathOr(hpPlusStatus.UNKNOWN, hpPlusCurrentState, hpPlusStatus);
      printersDetails = pathOr(printerStatus.UNKNOWN, printersCurrentState, printerStatus);

      if (statuses.deviceSecurityHealth) {
        const smartSecurityCurrentState = statuses.deviceSecurityHealth.state;
        smartSecurityDetails = pathOr(smartSecurityStatus.UNKNOWN, smartSecurityCurrentState, smartSecurityStatus);
      }
    }

    if (!isLoadingUsage && !usageApiError) {
      pagesPrinted = usageData.pagesCompleted;
    }

    return (
      <Content gridMode={usageEnabled}>
        <ContentStatus>
          <StatusItem
            description={i18n.t('home.status.item1.text')}
            status={isLoading ? i18n.t('home.status.item.refreshing') : i18n.t(hpPlusDetails.statusLabelId)}
            Icon={hpPlusDetails.Icon}
            Image={ImageHpPlus}
            iconColor={hpPlusDetails.iconColor}
            redirect={relPath('/my-printers')}
          />
          {serviceEnabled(ucdePortalElements, SERVICES.SMART_SECURITY) && statuses.deviceSecurityHealth
          && (
          <StatusItem
            description={i18n.t('home.status.item2.text')}
            status={isLoading ? i18n.t('home.status.item.refreshing') : i18n.t(smartSecurityDetails.statusLabelId)}
            Icon={smartSecurityDetails.Icon}
            Image={ImageSmartSecurity}
            iconColor={smartSecurityDetails.iconColor}
            redirect={relPath('/services/smart-security')}
          />
          )}
          <StatusItem
            description={i18n.t('home.status.item3.text')}
            status={isLoading ? i18n.t('home.status.item.refreshing') : i18n.t(printersDetails.statusLabelId)}
            Icon={printersDetails.Icon}
            Image={ImagePrintPhoto}
            iconColor={printersDetails.iconColor}
            redirect={relPath('/my-printers')}
          />
        </ContentStatus>
        <FeatureFlag featureFlagKey={usageEnabledKey}>
          <ContentUsage>
            {usageApiError
              ? <ServerError title="home.status.usage.errorMessage" />
              : <UsageStatus pages={pagesPrinted} />
            }
          </ContentUsage>
        </FeatureFlag>
      </Content>
    );
  };

  const CardContent = () => (
    <Container data-testid="status-card">
      <CardHeaderStatus>
        <h5>{i18n.t('home.status.header')}</h5>
        <Refresh
          onClick={loadAll}
          disabled={isLoading || (usageEnabled && isLoadingUsage)}
        >
          {i18n.t('home.status.button.unknown')}
        </Refresh>
      </CardHeaderStatus>
      {isLoading ? (
        <LoaderContent>
          <Loader />
        </LoaderContent>
      ) : buildStatusItem()}
    </Container>
  );

  return (
    <Card
      appearance="dropShadow"
      content={CardContent()}
    />
  );
};

export default PrinterStatus;
