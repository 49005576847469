import styled from 'styled-components';

export const IllustratedDirections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  @media (min-width: 640px) {
    flex-direction: row;
  }
`;
export const Directions = styled.div`
  flex: 1 1 auto;
  padding-right: 1.25rem;
`;
export const ImageContainer = styled.div`
  width: 100%;
  max-width: 15rem;
  margin: 0 auto;
  flex: 1 0 auto;
  @media (min-width: 640px) {
    flex: 1 0 13rem;
  }
  img {
    width: 100%;
  }
`;

export const TemplateSection = styled.section`
  max-width: 52rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  padding: 5.5rem 1.25rem 0;
  @media screen and (min-width: 40rem) {
    padding: 9rem 1.25rem 0;
  }
  min-width: initial;
  .sectionHeader {
    margin-bottom: 0;
    padding-bottom: 2.25rem;
    @media screen and (min-width: 40rem) {
      margin-bottom: 0;
      padding-bottom: 2.5rem;
    }
  }
`;

export const GrayTemplateSection = styled(TemplateSection)`
  max-width: none;
  background-color: #f9f9f9;
  padding: 2.5rem 1.25rem 0;
  @media screen and (min-width: 40rem) {
    padding: 4.5rem 1.25rem 0;
  }
`
export const ContentWrapper = styled.div`
width: 100%;
max-width: 52rem;
margin-right: auto;
margin-left: auto;
`