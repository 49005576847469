import styled from 'styled-components';

export const Container = styled.div`
  min-height: 60px;
  font-size: 16px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e2e2e2;

  &:last-child {
    align-items: flex-start;
  }
`;

export const LeftSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  line-height: 30px;
  
  a {
    color: ${props => props.theme.main.hp.color};
    font-family: ${props => props.theme.main.hp.fontFamily};
    font-size: 14px;
    text-align: right;
  }

  > div {
    width: 100%;
  }
`;
