import React, { Component } from 'react';
import { is, complement } from 'ramda';
import { TemplateSection } from '../shared-components/shared-styles/TemplateStyles';
import SectionHeadIcon from '../shared-components/molecules/sectionHeadIcon';
import HollowButton from '../shared-components/atoms/hollowButton';
import { filter_ii_countries } from '../../lib/filter_ii_countries';

class PrinterInk extends Component {

  isLocaleFiltered_ii = () => filter_ii_countries(this.props.countryCode)

  generateInkOptions() {
    const { printer_common } = this.props;
    if (printer_common) {
      let inkOptions = printer_common.purchase_options;
      if (inkOptions === undefined) {
        return null;
      }
      if (is(Object, inkOptions) && complement(is(Array))(inkOptions)) {
        inkOptions = Array(inkOptions);
      }
      if (this.isLocaleFiltered_ii()) {
        const new_list = inkOptions.filter(a => a.optionButtonID.indexOf('Instant Ink') === -1);
        inkOptions = new_list;
      }
      return inkOptions.map((inkPurchaseOptions, index) => (

        <div key={`inkOption${index}`} className="purchaseOptions--option">
          <p>{inkPurchaseOptions.options_block?.option_title}</p>
          <HollowButton
            buttonText={inkPurchaseOptions.options_block?.option_button_text}
            buttonID={inkPurchaseOptions.options_block?.option_button_id}
            buttonLink={inkPurchaseOptions.options_block?.option_button_link}
          />
        </div>

      ));
    }
  }

  render() {
    const {
      printer_common,
      device,
    } = this.props;

    return (
      <TemplateSection id="get-ink">
        <SectionHeadIcon
          sectionHeading={printer_common.ink_section_title}
          sectionIcon={printer_common.ink_section_icon?.url}
          sectionIconAlt={printer_common.ink_section_icon?.title}
        />
        <div className="cartridges">
          {
            device.ink && device.ink.map((ink, index) => (
              <div key={`cartridge${index}`} className="cartridge">
                <span className="cartridge-number">
                  <img src={ink.numberGraphicUrl} alt={ink.numberGraphicTitle} />
                </span>
                <p>{ink.cartridgeDescription}</p>
              </div>
            ))
          }
        </div>
        <div className="purchaseOptions">
          {this.generateInkOptions()}
        </div>
      </TemplateSection>
    );
  }
}

export default PrinterInk;
