import React from 'react';
import { FAQsContainer, FAQsContent, AccordionHeader, AccordionBody } from './styles';
import { Accordion } from '@veneer/core';

const FAQs = (props) => {
  const accordionItems = [];
  props.faqs.forEach((acc, i) => {
    accordionItems.push({
      content: (
        <AccordionBody
          dangerouslySetInnerHTML={{
            __html: acc?.faqdetails.answer
          }}
        ></AccordionBody>
      ),
      expanded: i === 0 ? true : false,
      header: {
        centralArea: <AccordionHeader>{acc?.faqdetails.question}</AccordionHeader>
      },
      id: acc.id
    });
  });

  const [data, setData] = React.useState(accordionItems.slice(0, 4));
 

  const handleExpand = (event, index) => {
    const updatedData = [...data];
    updatedData[index].expanded = true;
    setData(updatedData);
  };

  const handleCollapse = (event, index) => {
    const updatedData = [...data];
    updatedData[index].expanded = false;
    setData(updatedData);
  };

  return (
    <FAQsContainer>
      <FAQsContent>
        <div className="title">{props.heading}</div>
        {accordionItems.length > 0 && (
          <Accordion items={data} onExpand={handleExpand} onCollapse={handleCollapse} />
        )}
      </FAQsContent>
    </FAQsContainer>
  );
};

export default FAQs;
