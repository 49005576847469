import React from 'react';
import Carousel from 'nuka-carousel';
import SliderPanel from '../shared-components/molecules/SliderPanel';
import PagingDots from '../shared-components/molecules/PagingDots';
import SubsectionHead from './../shared-components/molecules/SubsectionHead';
import { TemplateSection } from '../shared-components/shared-styles/TemplateStyles';
import { ArrowButtonLeft, ArrowButtonRight } from '../shared-components/atoms/ArrowButtons';
import { sliderPanelTracking } from '../../lib/analyticsTracking';

class ProductTour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0
    }
    this.panelTracking = this.panelTracking.bind(this)
    this.analyticsKey = []
  }

  componentDidUpdate(prevProps, prevState) {
    let erroneous_nuka_element = document.getElementById("omit_parent_1").parentNode
    const { device } = this.props;
    if (!!erroneous_nuka_element) {
      erroneous_nuka_element.style.display="none"
    }

    if (device && device.tourSliderPanels && this.analyticsKey.length === 0) {
      const { tourSliderPanels } = device;
      for (let i = 0; i < tourSliderPanels.length; i++) {
        this.analyticsKey[i] = tourSliderPanels[i].tourPanelTitle;
      }
    }
    if (this.state.slideIndex !== prevState.slideIndex) {
      this.panelTracking()
    }
  }

  panelTracking() {
    sliderPanelTracking(`Tango Tour: slide(${this.state.slideIndex}): ` + this.analyticsKey[this.state.slideIndex])
  }

  handleStateChange = (preSlide, slideIndex) => {
    this.setState({ slideIndex: slideIndex })
  }

  render() {
    const { device } = this.props;
    if (!device) {
      return null;
    }

    const { tourTitle, tourSliderPanels } = device;
    return (
      <TemplateSection id="learn-about">
        <SubsectionHead text={tourTitle} />
        <div dir="ltr" >
          <Carousel id="productTourCarousel"
            renderAnnounceSlideMessage={()=><span id="omit_parent_1"></span>}
            slideIndex={this.state.slideIndex}
            beforeSlide={this.handleStateChange}
            renderCenterLeftControls={({ previousSlide, currentSlide }) => (
              <ArrowButtonLeft id="productTourLeftArrow" disabled={currentSlide === 0} onClick={previousSlide}>&lt;</ArrowButtonLeft>
            )}
            renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => (
              <ArrowButtonRight id="productTourRightArrow" disabled={currentSlide === (slideCount - 1)} className={slideCount} onClick={nextSlide}>&gt;</ArrowButtonRight>
            )}
            renderBottomCenterControls={props => <PagingDots prefix="tour" {...props} />}
          >
            {
              tourSliderPanels && tourSliderPanels.map(panel => (
                <SliderPanel
                  key={panel.id}
                  imgSrc={panel.tourPanelImageUrl}
                  imgAlt={panel.tourPanelImageTitle}
                  header={panel.tourPanelTitle}
                  desc={panel.tourPanelDescription}
                />
              ))
            }
          </Carousel>
        </div>
      </TemplateSection>
    );
  }
}

export default ProductTour;
