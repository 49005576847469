import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './splunk-instrumentation';
import React from 'react';
import ReactDom from 'react-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import createRootReducer from './reducers';
import rootSaga from './sagas';
import Config from './config/Config';
import DetermineLocale from './components/DetermineLocale';

const config = new Config();

const ldClientId = config.Get('LAUNCH_DARKLY_CLIENT_ID');

const sagaMiddleware = createSagaMiddleware();

const history = createBrowserHistory({ basename: '/' });

export function getHistory() {
  return history;
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composedEnhancers = composeEnhancers(
  applyMiddleware(thunk, sagaMiddleware, routerMiddleware(history))
);
export const store = createStore(createRootReducer(history), composedEnhancers);

export function getStore() {
  return store;
}

sagaMiddleware.run(rootSaga);

//Initializing LaunchDarkly
(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: ldClientId,
    user: {
      key: 'hpc3-user',
      anonymous: true
    }
  });

  ReactDom.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <LDProvider>
          <DetermineLocale />
        </LDProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('view')
  );
})();
