import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  prop,
  not,
  isEmpty,
} from 'ramda';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import {
  Accordion,
  Button,
} from '@veneer/core';

import svg from '../../../assets/home/icon-faq.svg';

import { selectFAQProps } from '../../../selectors/home';

import { useSelector } from '../../../hooks';

import {
  paths,
  breakpoints,
  fontPacks,
} from '../../../utils/styleHelpers';

const Container = styled.div`
&& {
  background: top center / calc(100vw - 3rem) 100% no-repeat url("../../assets/home/bg-sm3.jpg");
  background-color: #0171AD;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  color: ${paths.colorsWhite};
  width: 100%;
  margin-bottom: 3rem;
  @media (min-width: ${breakpoints.mobile}) {
    padding: 2rem 1.5rem;
  }
  @media (min-width: ${breakpoints.tablet}) {
    background: top center / 100% no-repeat url("../../assets/home/bg-md3.jpg");
    background-color: #0171AD;
  }
  @media (min-width: ${breakpoints.wide}) {
    background: top center / 100% no-repeat url("../../assets/home/bg-lg3.jpg");
    background-color: #0171AD;
  }
  @media (min-width: 64rem) {
    padding: 3rem;
    margin-top: 3rem;
    height: 100%;
  }
}
`;

const Header = styled.h3`
  color: ${paths.colorsWhite};
  ${fontPacks.titleRegularLight}
`;

const StyledAccordion = styled(Accordion)`
  && {
    color: ${paths.colorsWhite};
    width: 100%;
    max-width: 39rem;
    > * {
      background-color: transparent;
    }
    > div {
      > div:first-of-type {
        > div {
          text-align: left;
          margin-left: 0;
        }
        > svg {
          fill: ${paths.colorsWhite};
        }
      }
      ${({ contents = [] }) => contents
    .map(idx => `> div:nth-of-type(${(idx + 1) * 2}) {
        height: 0;
      }`)
    .join('')
}
    }
  }
`;

const ItemTitle = styled.span`
  ${fontPacks.subtitleLarge}
`;

const Content = styled.span`
  text-align: left;
  color: ${paths.colorsWhite};
  ${fontPacks.bodyLarge}
  > p, > li {
    ${fontPacks.bodyLarge}
    height: auto;
    overflow: hidden;
    color: ${paths.colorsWhite};
  }
`;

const Image = styled.img`
  margin-bottom: 1rem;
  width: 4rem;
`;

const WrappedAccordion = memo(({ handleToggleExpand = () => {}, contents = [], items = [] }) => (
  <StyledAccordion
    onCollapse={handleToggleExpand}
    onExpand={handleToggleExpand}
    behavior="singleExpand"
    contents={contents}
    items={items}
  />
));

const FAQ = ({ geoCountry }) => {
  const dispatch = useDispatch();
  const props = useSelector(selectFAQProps, geoCountry);

  const dest = prop('faq_button_destination', props);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const handleClick = useCallback(() => {
    if (dest.includes('http')) {
      if (dest.includes('/openid/v1/authorize?client_id=')) {
        window.location.href = dest;
        return;
      }
      window.open(dest, '_blank');
      return;
    }
    dispatch(push(dest));
  }, [dest]);

  const toggleExpand = (event, index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const {
    faq_button_text,
    faq_header,
    faq_entry,
  } = props;

  const items = useMemo(() => faq_entry.map((entry, index) => {
    const { faq } = entry;
    const { faq_title = '', faq_contents } = faq;
    const id = `faq-entry-${index}`;
    const expanded = expandedIndex === index;
    return {
      content: <Content dangerouslySetInnerHTML={{ __html: faq_contents }} />,
      expanded,
      header: {
        startArea: <span>{faq_title}</span>,
      },
      id,
      key: id,
    };
  }), [faq_entry]);

  return (
    <Container>
      <Image src={svg} alt="speech-bubble-line-art" />
      <Header>
        {faq_header}
      </Header>
      {
        faq_entry && not(isEmpty(faq_entry)) && (
          <WrappedAccordion
            handleToggleExpand={toggleExpand}
            contents={faq_entry.map((item, index) => index)}
            items={items}
          />
        )
      }
      <Button
        onClick={handleClick}
        negative
      >
        {faq_button_text}
      </Button>
    </Container>
  );
};

export default FAQ;
