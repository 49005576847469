import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Route } from 'react-router';
import Config from '../../config/Config';
import LoaderBlock from '../shared-components/molecules/LoaderBlock';
import { determineDest } from '../../selectors/global';
import { isUserLoggedIn } from '../../lib/userUtils';
import { getQueryParams } from '../../utils/globals';
import { getDecodedStratusAccessToken } from "../Dashboard/utils/auth";

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
  width: 100vw;
`;

const ProtectedRoute = props => {
  const isLoggedOut = !isUserLoggedIn();
  const search = getQueryParams();
  const decodedToken = getDecodedStratusAccessToken()
  const tenantIdFromToken = decodedToken?.tenant_id?.trim();

  const getUrlSearchParams = (search) => {
    const urlSearchParams = new URLSearchParams(search);
    return Object.fromEntries(urlSearchParams.entries());
  };

  const { tenantId: tenantIdFromURL } = getUrlSearchParams(search)
  const shouldRedirectToLogin = isLoggedOut || !tenantIdFromToken || (tenantIdFromToken && tenantIdFromURL && tenantIdFromToken !== tenantIdFromURL)

  useEffect(() => {
    if (shouldRedirectToLogin) {
      const config = new Config();

      const loginAuthzURL = determineDest({
        signInURL: config.Get('AUTHZ_OPENID_LOGIN'),
        createUrl: config.Get('AUTHZ_OPENID_CREATE'),
        redirectTo: props.computedMatch.url,
        destination: 'signin',
        tenantId: tenantIdFromURL
      })

      window.location.href = loginAuthzURL;
    }
  }, [shouldRedirectToLogin]);

  if (shouldRedirectToLogin) {
    return (
      <LoaderContainer>
        <LoaderBlock />
      </LoaderContainer>
    );
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
