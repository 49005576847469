import React from 'react';
import { getHtmlDir } from '../../utils/globals';
import { useSelector } from '../../hooks';
import { selectSMBSetupChoiceContentStackData } from '../../selectors/craft';
import Navbar from '../revised/Global/Navbar';
import Header from './Header/Header';
import Setup from './Setup/Setup';
import Support from './Support/Support';
import Footer from '../revised/Global/Footer';

const SMBSetupChoice = (props) => {
  const { geoCountry } = props;
  const direction = getHtmlDir();
  const contentStackContent = useSelector(selectSMBSetupChoiceContentStackData)?.data?.entry

  if(contentStackContent){
    const {
      setup_header,
      setup_sub_header,
      setup_text,
      setup_image,
      setup_button_text,
      setup_button_destination,
      setup_path_text,
      network_printer_text,
      download_button_text,
      download_button_destination,
      pairing_code_image,
      pairing_code_link,
      support_link
    } = contentStackContent;

    return (
      <div>
        <Navbar
          geoCountry={geoCountry}
          minimal={true}
          smbDownloadHeader={true}
        />
        <Header text={setup_header} />
        <Setup
          setupHeader={setup_sub_header}
          printerImageAlt={setup_image?.title}
          printerImage={setup_image?.url}
          features={setup_text}
          beginSetupButtonText={setup_button_text}
          buttonDestination={setup_button_destination}
          driverHeader={network_printer_text}
          driverbuttonText={download_button_text}
          driverButtonDestination={download_button_destination}
          choice={setup_path_text}
          accessPrinterText={network_printer_text}
          accessPrinterButtonText={download_button_text}
        />
        <Support
          pairingImageAlt={pairing_code_image?.title}
          pairingImage={pairing_code_image?.url}
          pairingURL={pairing_code_link}
          supportText={support_link}
        />
        <Footer />
      </div>
    );
  }

  return null;
};

export default SMBSetupChoice;
