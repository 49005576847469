import React, { useEffect, memo } from 'react'
 
const ExternalRedirect = memo(({ path, baseUrl, queryParams }) => {
  useEffect(() => {
    window.location.assign(`${baseUrl}${path}${queryParams || ''}`)
  }, [])
 
  return <></>
});

export default ExternalRedirect;