import React from 'react';
import { SetupContainer, SetupContent, ImageContent, TextContent, Line } from './styles';

const Setup = (props) => {
  return (
    <SetupContainer isRTL={props.isRTL}>
      <SetupContent isRTL={props.isRTL}>
        <ImageContent>
          <img alt={props.altText} src={props.image} />
        </ImageContent>
        <TextContent isRTL={props.isRTL}>
          <h3
            dangerouslySetInnerHTML={{
              __html: props.text
            }}
          ></h3>
        </TextContent>
      </SetupContent>
      <Line />
    </SetupContainer>
  );
};

export default Setup;
